import CreateCoaches from 'components/coaches/CreateCoaches'
import ImagePicker from 'components/elements/ImagePicker'
import CoachesDetail from 'components/home/CustomersDetail/CoachesDetail'
import EditCustomerDetail from 'components/home/CustomersDetail/EditCustomerDetail'
import InstituteEquipments from 'components/home/CustomersDetail/InstituteEquipments'
import Libraries from 'components/home/CustomersDetail/Libraries'
import { constants } from 'constant'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { updateGymCustomer } from 'store/userSlice/userActions'

const adminMenus = ['INSTITUTE_DETAILS', 'INSTITUTE_EQUIPMENTS', 'COACHES', 'COLLECTIONS']
const coachMenus = ['INSTITUTE_DETAILS', 'INSTITUTE_EQUIPMENTS']
export default function GymDetail() {
  const { role } = useSelector((state) => state.auth.user)
  const Menus = role === 'coach' ? coachMenus : adminMenus
  const { t } = useTranslation()
  const [imageURL, setImageURL] = useState(null)
  const [imageError, setImageError] = useState(false)
  const [file, setFile] = useState(null)
  const [activeUser, setActiveUser] = useState()
  const [regStatus, setRegStatus] = useState()
  const [selectedLanguage, setSelectedLanguage] = useState(constants.APP_LANGUAGES_LIST[0])
  const [workoutTime, setWorkoutTime] = useState(constants.STANDARD_TIME[2])

  const dispatch = useDispatch()
  const { customerDetail } = useSelector((state) => state.user)

  const { coaches } = useSelector((state) => state.gymAdmin)

  const [active, setActive] = useState(Menus[0])
  const { direction } = useSelector((state) => state.language)
  const [show, setShow] = useState(false)
  const [coachID, setCoachID] = useState(null)

  const action = () => {}

  useEffect(() => {
    if (role === 'coach') {
      if (customerDetail?.User?.avatar) {
        setImageURL(customerDetail?.User?.avatar)
      }
    } else {
      if (customerDetail?.logo) setImageURL(customerDetail?.logo)
    }
    // eslint-disable-next-line
  }, [customerDetail])

  const updateGym = (values) => {
    let data = {
      ...values,
      userId: customerDetail?.User?.id,
      gymId: customerDetail?.id,
      lang: selectedLanguage?.code,
      standardTime: workoutTime,
    }
    const formData = new FormData()
    if (file) {
      formData.append('file', file)
    }
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key])
    })

    dispatch(updateGymCustomer(formData, action, t, direction, customerDetail?.User?.id))
  }
  const navigation = useNavigate()

  return (
    <>
      <div className="flex sticky top-0 flex-col bg-borderActive ">
        <div className="flex flex-row w-full justify-between px-8 py-2 h-16">
          <div className="flex gap-x-1 h-5">
            <p
              className={`text-white text-[12px] cursor-pointer  hover:border-white hover:border-b-2 ${
                direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
              }`}
              onClick={() => {
                navigation('/admin/dashboard')
              }}
            >
              {t('MAIN')}
            </p>
            <p className="text-white text-[12px] ">/</p>
            <p className={`text-white text-[12px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>
              {t('CUSTOMER_TAB')} - {customerDetail?.gymName}
            </p>
            <p className="text-white text-[12px] ">/</p>
            <p className={`text-breadCrumb text-[12px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>{t(active)}</p>
          </div>
        </div>
        <div className="flex w-full justify-center  ">
          <ImagePicker
            placeholder={t('INSTITUTE_LOGO')}
            imageURL={imageURL}
            setImageURL={setImageURL}
            imageError={imageError}
            errorMessage={t('IMAGE_REQUIRED')}
            setImageError={setImageError}
            setSelectedFile={setFile}
            selectedFile={file}
            styles={`w-[140px] h-[140px] items-center  justify-center  rounded-[50%] ${!imageURL && 'border-dashed border-[1px]'}`}
          />
        </div>
        <div className="flex w-full justify-center mt-1">
          <p className={`text-white text-[32px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-center`}>
            {role !== 'coach' ? customerDetail?.gymName : customerDetail?.User?.firstName}
          </p>
        </div>
        <div className="flex w-full self-center flex-row justify-center lg:w-[80%]">
          {Menus.map((item, index) => {
            return (
              <div
                className={`text-white text-[14px] ${
                  direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                } text-center py-2 cursor-pointer px-9   ${item === active ? ' border-b-2 border-white' : ''} `}
                onClick={() => setActive(item)}
              >
                {t(item)}
              </div>
            )
          })}
        </div>
      </div>
      <div className=" flex w-full items-center justify-center">
        {active === 'INSTITUTE_DETAILS' && (
          <EditCustomerDetail
            data={customerDetail}
            file={file}
            onSubmit={updateGym}
            setActiveUser={setActiveUser}
            activeUser={activeUser}
            regStatus={regStatus}
            setRegStatus={setRegStatus}
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
            workoutTime={workoutTime}
            setWorkoutTime={setWorkoutTime}
            role={role}
          />
        )}
        {role !== 'coach' && active === 'INSTITUTE_EQUIPMENTS' && <InstituteEquipments direction={direction} />}

        {role !== 'coach' && active === 'COACHES' && (
          <CoachesDetail data={coaches} show={show} setShow={setShow} coachID={coachID} setCoachID={setCoachID} />
        )}
        {active === 'COLLECTIONS' && <Libraries direction={direction} />}
      </div>
      {show && <CreateCoaches show={show} setShow={setShow} coachID={coachID} setCoachID={setCoachID} />}
    </>
  )
}
