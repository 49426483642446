import axiosInstance from 'api'
import { Images } from 'assets'
import CustomToastify from 'components/compounds/Toast'
import { workoutActions } from './workoutReducer'

/* Workout Actions */
export const getCoachDailyWorkouts = (coachId, action, t, direction) => async (dispatch) => {
  try {
    dispatch(workoutActions.setLoader(true))
    dispatch(workoutActions.resetWorkoutDetails())
    const response1 = await axiosInstance.get(`/getWorkoutByCoach/${coachId}`)
    const response2 = await axiosInstance.get(`/getAllActiveWorkout/${coachId}`)
    dispatch(workoutActions.setCoachWorkouts(response1.result.newdata))
    dispatch(workoutActions.setActiveWorkouts(response2.result))
    action && action()
    dispatch(workoutActions.setLoader(false))
  } catch (e) {
    dispatch(workoutActions.setLoader(false))
    dispatch(workoutActions.resetWorkoutDetails())
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    action && action()
    return console.error(e.message)
  }
}

export const addActiveWorkout = (workoutId, t, direction) => async (dispatch) => {
  try {
    dispatch(workoutActions.addActiveWorkout({ loading: true }))
    dispatch(workoutActions.setLoader(true))
    await axiosInstance.get(`/makeWorkoutActive/${workoutId}`).then((response) => {
      dispatch(
        workoutActions.updateCoachWorkoutStatus({
          workoutId,
          status: 'active',
        })
      )
      dispatch(workoutActions.updateLoadingWorkout(response.result))

      CustomToastify(t('WORKOUT_STATUS_UPDATED_SUCCESSFULLY'), '', '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
      dispatch(workoutActions.setLoader(false))
    })
  } catch (e) {
    dispatch(workoutActions.setLoader(false))
    dispatch(workoutActions.removeLastActiveWorkout())
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}

export const removeActiveWorkout = (workoutId, coachId, action, t, direction) => async (dispatch) => {
  try {
    dispatch(
      workoutActions.removeWorkout({
        workoutId: workoutId,
      })
    )
    action && action()

    await axiosInstance.get(`/removeWorkout/${workoutId}`)

    CustomToastify(t('WORKOUT_REMOVED_SUCCESSFULLY'), '', '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)

    const response1 = await axiosInstance.get(`/getWorkoutByCoach/${coachId}`)
    dispatch(workoutActions.setCoachWorkouts(response1.result.newdata))
  } catch (e) {
    dispatch(workoutActions.setLoader(false))
    action && action()
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}

export const completeWorkout = (payload, workoutId, action, t, direction) => async (dispatch) => {
  try {
    dispatch(workoutActions.setLoader(true))
    await axiosInstance.put(`/completeWorkout/${workoutId}`, payload)
    const response = await axiosInstance.get(`/getWorkoutDetail/${workoutId}`)
    const workoutDetails = response.result
    dispatch(
      workoutActions.updateCoachWorkoutStatus({
        workoutId,
        status: 'completed',
      })
    )
    dispatch(
      workoutActions.updateWorkout({
        workoutDetail: workoutDetails,
        workoutId,
      })
    )
    action && action()
    dispatch(workoutActions.setLoader(false))
  } catch (e) {
    dispatch(workoutActions.setLoader(false))
    action && action()
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}

/* Exercise Actions */
export const removeExerciseWorkout = (workoutExerciseId, workoutId, action, t, direction) => async (dispatch) => {
  try {
    dispatch(workoutActions.setLoader(true))
    await axiosInstance.get(`/removeWorkoutExercise/${workoutExerciseId}`)
    const response = await axiosInstance.get(`/getWorkoutDetail/${workoutId}`)
    const workoutDetails = response.result

    dispatch(
      workoutActions.removeWorkoutExercise({
        workoutDetail: workoutDetails,
        workoutId,
        WorkoutExerciseId: workoutExerciseId,
      })
    )
    action && action()
    dispatch(workoutActions.setLoader(false))
  } catch (e) {
    dispatch(workoutActions.setLoader(false))
    action && action()
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}

export const playExercise = (payload, workoutId, action, t, direction) => async (dispatch) => {
  try {
    dispatch(workoutActions.setLoader(true))
    const res = await axiosInstance.post(`/updateWorkoutExerciseStatus`, payload)

    const response = await axiosInstance.get(`/getWorkoutDetail/${workoutId}`)
    const workoutDetails = response.result
    CustomToastify(t('EXERCISE_PLAYED_SUCCESSFULLLY'), '', '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
    dispatch(
      workoutActions.updateWorkoutExercise({
        workoutDetail: workoutDetails,
        workoutId,
        workoutExerciseId: res.result.data.workoutExerciseId,
      })
    )
    action && action()
    dispatch(workoutActions.setLoader(false))
  } catch (e) {
    dispatch(workoutActions.setLoader(false))
    action && action()
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}

export const completeExercise = (payload, workoutId, action, t, direction, setBestRecordId, bestRecordId) => async (dispatch) => {
  try {
    dispatch(
      workoutActions.tempUpdateWorkoutExercise({
        workoutExerciseId: payload.workoutExerciseId,
        workoutId,
        status: 'completed',
      })
    )
    const res = await axiosInstance.post(`/completeWorkoutExercise`, payload)
    const response = await axiosInstance.get(`/getWorkoutDetail/${workoutId}`)
    const workoutDetails = response.result
    const isNewPersonalBest = res.result.newPersonalBest

    if (isNewPersonalBest && setBestRecordId) {
      setBestRecordId((pre) => [
        ...pre,
        {
          workoutId: res.result.data.workoutId,
          exerciseName: res.result.exerciseName,
          isResistanceRecord: res?.result?.newBestSet?.resistanceRecord,
          resistanceValue: res?.result?.newBestSet?.resistance_value,
          trackingValue: res?.result?.newBestSet?.tracking_value,
          resistanceName: res?.result?.data?.updatedSets[0]?.resistancename,
          trackingName: res?.result?.data?.updatedSets[0]?.trackingname,
        },
      ])
      setTimeout(() => {
        const temp = bestRecordId.filter((x) => x !== res.result.data.workoutId)
        setBestRecordId(temp)
      }, 5000)
    }
    dispatch(
      workoutActions.updateWorkoutExercise({
        workoutDetail: workoutDetails,
        workoutId,
        workoutExerciseId: res.result.data.workoutExerciseId,
      })
    )
    action && action()
    dispatch(workoutActions.setLoader(false))
  } catch (e) {
    dispatch(workoutActions.setLoader(false))
    dispatch(
      workoutActions.tempUpdateWorkoutExercise({
        workoutExerciseId: payload.workoutExerciseId,
        workoutId,
        status: 'open',
      })
    )
    action && action()
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}

export const reindexExercises = (payload, workoutId, action, t, direction) => async (dispatch) => {
  try {
    dispatch(workoutActions.setLoader(true))
    await axiosInstance.post(`/reIndexExercise`, payload)
    const response = await axiosInstance.get(`/getWorkoutDetail/${workoutId}`)
    const workoutDetails = response.result
    CustomToastify(t('EXERCISE_ACTION'), t('EXERCISE_REORDERED_SUCCESSFULLLY'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
    dispatch(
      workoutActions.updateWorkout({
        workoutDetail: workoutDetails,
        workoutId,
      })
    )
    action && action()
    dispatch(workoutActions.setLoader(false))
  } catch (e) {
    dispatch(workoutActions.setLoader(false))
    action && action()
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}

export const searchTrainee = (gymId, name, action, t, direction) => async (dispatch) => {
  try {
    dispatch(workoutActions.setLoader(true))
    const response = await axiosInstance.get(`/searchTrainee/${gymId}`, { params: { search: name } })
    action && action(response.result.trainees)
    dispatch(workoutActions.setLoader(false))
  } catch (e) {
    dispatch(workoutActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}

export const getWorkoutByTraineeID = (traineeId, action, t, direction) => async (dispatch) => {
  try {
    dispatch(workoutActions.setLoader(true))
    const response = await axiosInstance.get(`/getWorkoutsByTrainee/${traineeId}`)
    if (response?.result && response?.result.workouts) {
      // Sort the workouts array based on Event.date in ascending order
      response.result.workouts[0].Workouts.sort((a, b) => new Date(a.Event.date) - new Date(b.Event.date))

      action && action(response.result)
    }

    dispatch(workoutActions.setLoader(false))
  } catch (e) {
    dispatch(workoutActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}

export const getExercisesForTrainnee =
  (gymId, traineeId, exerciseId, action, t, direction, filter = 'all') =>
  async (dispatch) => {
    try {
      dispatch(workoutActions.setLoader(true))
      const response = await axiosInstance.get(`/allExerciseWithCount/${gymId}`, { params: { query: filter, traineeId: traineeId } })
      dispatch(workoutActions.setTraineeExercises(response?.result?.exercises || []))
      dispatch(workoutActions.setTraineeRecommendedExercises(response?.result?.recommendedExercises || []))
      action && action()
      dispatch(workoutActions.setLoader(false))
    } catch (e) {
      dispatch(workoutActions.setLoader(false))
      CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return console.error(e.message)
    }
  }

export const getRecommendedExercisesForTrainnee =
  (gymId, traineeId, exerciseId, action, t, direction, filter = 'all') =>
  async (dispatch) => {
    try {
      dispatch(workoutActions.setLoader(true))
      const response = await axiosInstance.get(`/allExerciseWithCount/${gymId}`, {
        params: { traineeId: traineeId, exerciseId: exerciseId, query: filter },
      })
      dispatch(workoutActions.setTraineeExercises(response?.result?.exercises || []))
      // eslint-disable-next-line
      const filterRecommended = response?.result?.recommendedExercises?.filter((exercise) => exercise.id != exerciseId)
      dispatch(workoutActions.setTraineeRecommendedExercises(filterRecommended || []))
      action && action()
      dispatch(workoutActions.setLoader(false))
    } catch (e) {
      dispatch(workoutActions.setLoader(false))
      CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return console.error(e.message)
    }
  }

export const addNewWorkout = (data, coachId, action, t, direction) => async (dispatch) => {
  try {
    dispatch(workoutActions.setLoader(true))
    await axiosInstance.post(`/updateEventDate`, data)
    const response1 = await axiosInstance.get(`/getWorkoutByCoach/${coachId}`)
    dispatch(workoutActions.setCoachWorkouts(response1.result.newdata))
    CustomToastify(t('WORKOUT_ADDED_SUCCESSFULLLY'), '', '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
    action && action()
    dispatch(workoutActions.setLoader(false))
  } catch (error) {
    dispatch(workoutActions.setLoader(false))
    action && action()

    CustomToastify(
      t('SOMETHING_WENT_WRONG'),
      error?.code === 501 ? t('ALREADY_HAS_WORKOUT_TODAY') : t('PLEASE_TRY_AGAIN'),
      '',
      'bg-toastFaildbg',
      direction,
      Images.toastFail,
      false
    )
  }
}

export const addExerciseInWorkout = (payload, workoutId, action, t, direction) => async (dispatch) => {
  try {
    await axiosInstance.post(`/addExerciseInWorkout`, payload)
    const response = await axiosInstance.get(`/getWorkoutDetail/${workoutId}`)
    const workoutDetails = response.result

    dispatch(
      workoutActions.addWorkoutExercise({
        workoutDetail: workoutDetails,
        workoutId,
      })
    )
    action && action()
  } catch (error) {
    action && action()

    CustomToastify(
      t('SOMETHING_WENT_WRONG'),
      error?.code === 401 ? t('ALREADY_HAS_EXERCISE') : t('PLEASE_TRY_AGAIN'),
      '',
      'bg-toastFaildbg',
      direction,
      Images.toastFail,
      false
    )
  }
}

export const addExerciseComment = (data, action, t, direction) => async (dispatch) => {
  try {
    dispatch(workoutActions.setLoader(true))
    await axiosInstance.post(`/addCommentToWorkoutExercise`, data)
    const response = await axiosInstance.get(`/allcommentsByWorkoutExercise`, {
      params: { exerciseId: data?.exerciseId, traineeId: data?.traineeId },
    })
    dispatch(workoutActions.setComments(response.result.comments))

    action && action()
    dispatch(workoutActions.setLoader(false))
  } catch (error) {
    dispatch(workoutActions.setLoader(false))
    action && action()

    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
  }
}

export const getAllExerciseComments = (exerciseId, traineeId, t, direction) => async (dispatch) => {
  try {
    dispatch(workoutActions.setLoader(true))
    const response = await axiosInstance.get(`/allcommentsByWorkoutExercise`, { params: { exerciseId: exerciseId, traineeId: traineeId } })
    dispatch(workoutActions.setComments(response.result.comments))
    dispatch(workoutActions.setLoader(false))
  } catch (error) {
    dispatch(workoutActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
  }
}

export const deleteExerciseComment = (id, data, t, direction) => async (dispatch) => {
  try {
    dispatch(workoutActions.setLoader(true))
    await axiosInstance.post(`/removeExerciseComment/${id}`)
    const response = await axiosInstance.get(`/allcommentsByWorkoutExercise`, {
      params: { exerciseId: data?.exerciseId, traineeId: data?.traineeId },
    })
    dispatch(workoutActions.setComments(response.result.comments))
    CustomToastify(t('COMMENT_DELETED_SUCCESSFULLY'), '', '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)

    dispatch(workoutActions.setLoader(false))
  } catch (error) {
    dispatch(workoutActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
  }
}

export const SwapExerciseInWorkoutExercise = (data, t, action, direction) => async (dispatch) => {
  try {
    dispatch(workoutActions.setLoader(true))

    await axiosInstance.post(`/swapExercise`, data)
    const response = await axiosInstance.get(`/getWorkoutDetail/${data?.workoutId}`)
    const workoutDetails = response.result
    dispatch(
      workoutActions.swapWorkoutExercise({
        workoutDetail: workoutDetails,
        workoutId: data?.workoutId,
        WorkoutExerciseId: data.WorkoutExerciseId,
      })
    )

    action && action()
    dispatch(workoutActions.setLoader(false))
  } catch (error) {
    dispatch(workoutActions.setLoader(false))
    action && action()

    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
  }
}

export const SwapExerciseForAllWorkouts = (data, t, action, direction) => async (dispatch) => {
  try {
    dispatch(workoutActions.setLoader(true))
    await axiosInstance.post(`/swapAllExercise`, data)
    const response = await axiosInstance.get(`/getWorkoutDetail/${data?.workoutId}`)
    const workoutDetails = response.result

    dispatch(
      workoutActions.updateWorkout({
        workoutDetail: workoutDetails,
        workoutId: data?.workoutId,
      })
    )

    action && action()

    dispatch(workoutActions.setLoader(false))
  } catch (error) {
    dispatch(workoutActions.setLoader(false))

    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
  }
}

export const ExerciseHistory = (data, t, action, direction) => async (dispatch) => {
  try {
    dispatch(workoutActions.setHitoryLoader(true))
    const response = await axiosInstance.get(`/getExerciseHistory`, {
      params: { exerciseId: data?.exerciseId, traineeId: data?.traineeId, workoutExerciseId: data?.workoutExerciseId },
    })
    const exerciseHistory = response.result
    dispatch(workoutActions.setExerciseHistory(exerciseHistory))
    if (exerciseHistory?.exercises?.length > 0) {
      action && action(data)
    } else {
      CustomToastify(t('FIRST_TIME_PERFORMED'), '', '', 'bg-lightBlue', direction, Images.InfoBlue, false, true)
    }
    dispatch(workoutActions.setHitoryLoader(false))
  } catch (error) {
    dispatch(workoutActions.setHitoryLoader(false))

    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
  }
}

export const gettingTraineeWorkoutPattern = (workoutId, patternIndex, t, direction) => async (dispatch) => {
  try {
    dispatch(workoutActions.setLoader(true))

    const response = await axiosInstance.get(`/getAllPatternOfActiveProgram/${workoutId}`)
    const workoutPattern = response.result
    const filterWorkouts = workoutPattern.filter((workout) => workout.PatternIndex !== patternIndex)
    dispatch(workoutActions.setWorkoutPattern(filterWorkouts))
    dispatch(workoutActions.setLoader(false))
  } catch (error) {
    dispatch(workoutActions.setLoader(false))

    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
  }
}

export const swapWorkout = (data, action, t, direction) => async (dispatch) => {
  try {
    dispatch(workoutActions.setLoader(true))
    await axiosInstance.post(`/swapWorkout`, data)
    const response = await axiosInstance.get(`/getWorkoutDetail/${data?.workoutId}`)
    const workoutDetails = response.result
    dispatch(
      workoutActions.updateWorkout({
        workoutDetail: workoutDetails,
        workoutId: data?.workoutId,
      })
    )
    CustomToastify(t('WORKOUT_SWAP_SUCCESSFULLY'), '', '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
    dispatch(workoutActions.setLoader(false))
  } catch (error) {
    dispatch(workoutActions.setLoader(false))
    CustomToastify(
      t('SOMETHING_WENT_WRONG'),
      error?.code === 501 ? t('NO_WORKOUT_THIS_WEEK') : t('PLEASE_TRY_AGAIN'),
      '',
      'bg-toastFaildbg',
      direction,
      Images.toastFail,
      false
    )
  }
}

export const getLastPerformedExerciseDetail = (data) => async (dispatch) => {
  try {
    dispatch(workoutActions.setLoader(true))
    const response = await axiosInstance.post(`/getBestSetValuesForExercise`, data)
    dispatch(workoutActions.setLoader(false))
    return response.result
  } catch (error) {
    dispatch(workoutActions.setLoader(false))
    return null
  }
}

export const getExercisesInfo = (traineeId, exerciseId, t, direction, data, action, setLoader) => async (dispatch) => {
  try {
    setLoader({
      isloading: true,
      itemId: data.id,
    })
    const response = await axiosInstance.get(`/exerciseInfo`, { params: { traineeId: traineeId, exerciseId: exerciseId } })
    if (response.result) {
      const oldData = {
        ...data,
        lastExercise: response?.result?.lastExercise,
      }

      action && action(oldData)
    }
  } catch (e) {
    setLoader({
      isloading: false,
      itemId: data.id,
    })

    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}

export const startWorkoutEnableWorkoutExercises = (workoutId, t, direction, isTrainee, eventId) => async (dispatch) => {
  try {
    await axiosInstance
      .get(`/enableWorkoutExercisesByWorkoutId/${workoutId}`, { params: { isTrainee: isTrainee, eventId: eventId ? eventId : 'null' } })
      .then((response) => {
        dispatch(workoutActions.setAllWorkoutExerciseActive(workoutId))
      })
  } catch (e) {
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}

export const templateExerciseInfo = (data) => async (dispatch) => {
  try {
    dispatch(workoutActions.setLoader(true))
    const response = await axiosInstance.post(`/templateExerciseHistory`, data)

    return response.result
  } catch (error) {
    return null
  }
}

export const exerciseComments = (data) => async (dispatch) => {
  try {
    const response = await axiosInstance.get(`/exerciseComments`, { params: { ...data } })
    return response.result
  } catch (error) {
    return null
  }
}
export const addCommentDuringProgram = (data) => async (dispatch) => {
  try {
    const response = await axiosInstance.post(`/addCommentDuringProgram`, data)
    return response.result
  } catch (error) {
    return null
  }
}
export const musclesDetail = (data) => async (dispatch) => {
  try {
    const response = await axiosInstance.get(`/musclesExericseInfo`, { params: { ...data } })

    return response?.result?.exerciseDataByMuscle
  } catch (error) {
    return null
  }
}
export const WorkoutSummaryExerciseHistory = (data, t, action, direction) => async (dispatch) => {
  try {
    const response = await axiosInstance.get(`/workoutExerciseDetail`, {
      params: { exerciseId: data?.exerciseId, workoutExerciseId: data?.workoutExerciseId },
    })

    action && action()
    return response?.result?.workoutExerciseDetail
  } catch (error) {
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
  }
}

export const getWorkoutComments = (data) => async (dispatch) => {
  try {
    const response = await axiosInstance.get(`/workoutComments/${data}`)
    dispatch(workoutActions.setComments(response.result?.workoutComment
      ))
  } catch (error) {
    return null
  }
}
