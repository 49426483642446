import { Images } from 'assets'
import React, { useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

export default function DatePickerField({
  selectedDate,
  setSelectedDate,
  showDatePicker,
  setShowDatePicker,
  required,
  dir,
  t,
  error,
  setError,
  disabled,
  title,
  filter,
  handleSelect,
}) {
  useEffect(() => {
    if (filter && !selectedDate) {
      const today = new Date()
      const day = today.getDay()
      const daysUntilPreviousSunday = (day + 7 - 0) % 7
      const previousSunday = new Date(today.getTime() - daysUntilPreviousSunday * 24 * 60 * 60 * 1000)
      setSelectedDate(previousSunday)
    }
  }, [filter, selectedDate, setSelectedDate])
  const datepickerRef = useRef(null)
  function handleClickDatepickerIcon() {
    const datepickerElement = datepickerRef.current

    datepickerElement.setFocus(true)
  }
  function filterDate(date) {
    const today = new Date()
    const day = date.getDay()
    const sevenDaysAgo = new Date(today)
    sevenDaysAgo.setDate(today.getDate() - 7)
    // Disable non-Sunday dates
    if (filter && day !== 0) {
      return false
    }
    // Disable dates before today if filter is tru
    // commenting for open previous sunday?
    if (filter && date < sevenDaysAgo) {
      return false
    }
    return true
  }
  const maxDate = filter ? undefined : new Date()

  const [isInputFocused, setIsInputFocused] = useState(false)

  const handleInputFocus = () => {
    setIsInputFocused(true)
  }

  const handleInputBlur = () => {
    setIsInputFocused(false)
  }
  return (
    <>
      <div
        className={`flex flex-col w-full h-[57px]  my-1  rounded-[12px] ${disabled && 'opacity-50 bg-[#5A5F66]'} ${
          isInputFocused && !error ? 'border-2 border-[#006684]' : ''
        } ${error ? 'border-2 border-error' : ''} group`}
      >
        <label
          className={`flex flex-row !text-white ${
            dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
          } rounded-t-[12px]  text-[12px] bg-textFieldColor  px-3 group-hover:bg-borderColor `}
        >
          <span>{t(title)}</span>
          {required === true && <span className="text-[red] px-1">*</span>}
        </label>
        <div className="flex flex-row w-full items-center justify-between  rounded-b-[12px] bg-textFieldColor group-hover:bg-borderColor">
          <input
            value={selectedDate ? selectedDate.toLocaleDateString() : ''}
            className={` w-full h-[35px] rounded-b-[12px] bg-textFieldColor !text-white  px-2 ${
              dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
            } group-hover:bg-borderColor`}
            type={'text'}
            placeholder={'00/00/00'}
            disabled={false}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            style={{ color: 'white !important' }}
            readOnly={true}
            onChange={() => {}}
          />

          <div className=" " style={{ zIndex: 2000 }}>
            <DatePicker
              selected={selectedDate}
              onChange={(date) => {
                setError && setError(false)
                setSelectedDate(date)
                setShowDatePicker(false)
                handleSelect && handleSelect()
              }}
              filterDate={filterDate}
              showYearDropdown
              yearDropdownItemNumber={50}
              scrollableYearDropdown
              ref={datepickerRef}
              style={{ width: '10px' }}
              maxDate={maxDate}
              disabled={disabled}
              dateFormat="dd/MM/yyyy"
              className="text-white"
              customInput={
                <img
                  src={Images.CalendarIcon}
                  alt=""
                  height={40}
                  width={45}
                  className={`flex justify-end px-2 items-center`}
                  onClick={() => handleClickDatepickerIcon()}
                />
              }
            />
          </div>
        </div>
      </div>
      {required && error && !filter && (
        <p className={`form-errors flex flex-col px-1 ${dir === 'rtl' ? 'text-right font-primary-he-rg' : 'text-left font-primary-rg'}  `}>
          {t('DOB_REQUIRED')}
        </p>
      )}
    </>
  )
}
