import { Fragment, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'

export default function Tags({ options, title, value, setValue, disable, t, dir, isRequired, placeHolder }) {
  const [isInputFocused, setIsInputFocused] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const handleInputFocus = () => {
    setIsInputFocused(true)
    setIsDropdownOpen(true)
  }

  const handleInputBlur = () => {
    setIsInputFocused(false)
  }
  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter' && query !== '') {
      event.preventDefault()
      if (value.length === 0 || !value.includes(query)) {
        // Add the new value to the options array

        setValue([...value, query])
        setQuery('')
        setIsDropdownOpen(false)
        setIsInputFocused(false)
      }
    }
  }
  const [query, setQuery] = useState('')

  const filteredOption =
    query === '' ? options : options.filter((person) => person.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')))

  return (
    <div className={`flex flex-col w-full h-[56px] rounded-[12px] mb-2 mt-1 ${disable && 'opacity-50'} `}>
      <Combobox value={value}>
        <div className="bg-textFieldColor rounded-[12px]" style={{ zIndex: 10 }}>
          <div className={`bg-textFieldColor   z-10 ${isInputFocused ? 'border-2 border-[#006684] rounded-t-[12px]' : 'rounded-[12px]'}`}>
            <div
              className={`flex flex-row  justify-start ${
                dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
              } px-2 text-textLightGrey text-[11px] `}
            >
              {title}
              {isRequired && <span className="text-[red] px-1">*</span>}
            </div>
            <div className="flex flex-row cursor-default overflow-hidden rounded-[12px] bg-textFieldColor ">
              {!disable ? (
                <Combobox.Input
                  className={`inline-flex w-full text-[12px] items-center  ${
                    !disable ? 'bg-textFieldColor' : 'bg-textFieldColor'
                  } px-2 py-2  text-textLightGrey ${dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
                  onChange={(event) => setQuery(event.target.value)}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  placeholder={placeHolder}
                  onKeyDown={handleInputKeyDown}
                />
              ) : (
                <Combobox.Input
                  className={`inline-flex w-full text-[12px] items-center  ${
                    !disable ? 'bg-textFieldColor' : 'bg-textFieldColor'
                  } px-2 py-2  text-textLightGrey ${dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
                  onChange={(event) => setQuery(event.target.value)}
                  placeholder={placeHolder}
                  readOnly
                />
              )}
            </div>
          </div>
          {isDropdownOpen && (
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
              afterLeave={() => setQuery('')}
            >
              <Combobox.Options
                className={`w-full ${
                  options?.length > 3 ? 'h-44' : ' h-28'
                } overflow-y-auto  origin-top-right divide-y divide-[#374349] rounded-b-[12px] bg-textFieldColor  ring-black ring-opacity-5 focus:outline-none `}
              >
                {filteredOption.length === 0 && query !== '' ? (
                  <div
                    className={`flex w-full h-full justify-center items-center ${
                      dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                    } text-[16px]`}
                  >
                    {t('NOTHING_FOUND')}
                  </div>
                ) : (
                  filteredOption.map((val) => (
                    <div className=" ">
                      <Combobox.Option
                        className={({ active }) => `select-none py-2 px-2 ${active ? 'bg-borderActive text-white' : 'text-gray-900'} cursor-pointer`}
                        value={val}
                        onClick={() => {
                          setValue([...value, val])
                          setQuery('') // Clear the query after selecting an option
                          setIsInputFocused(false)
                        }}
                      >
                        <span className={`${dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-white text-[14px]`}>{val}</span>
                      </Combobox.Option>
                    </div>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          )}
        </div>
      </Combobox>
    </div>
  )
}
