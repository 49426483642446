import React, { useState } from 'react'

import { AllBodyMapContainer, FrontBackContainer } from './styles'
import { ReactComponent as SVGBodyMapFront } from '../../../assets/images/bodyMapSvg.svg'
import { ReactComponent as SVGBodyMapBack } from '../../../assets/images/bodyMapBackSvg.svg'

const BodyMapPercentage = ({ frontids, backIds, musclePercentages, hoverable = true, h, w, data, showModal, showPercentage }) => {
  const [focusedItem, setFocusedItem] = useState(false)
  let labelPositionFrontIndex = {
    9: 'top-[156px] right-16 z-10',
    1: 'top-[120px] right-[60px]',
    8: 'top-48 right-7 rtl:right-7 z-10',
    3: 'top-[244px] -right-1',
    14: 'bottom-40 right-10',
    12: 'bottom-[260px] right-[46px]',
    4: 'bottom-[300px] right-[33px]',

    //left muscles
    11: 'top-[215px] left-9',
    5: 'bottom-32 left-[63px]',
    13: 'bottom-64 left-9',
    10: 'top-[247px] left-24',
    7: 'top-[166px] left-4 z-20',
    2: 'top-[128px] left-9 z-10',
    6: 'top-[85px] left-[70px]',
  }
  let labelPositionFrontDimensions = {
    9: 'w-[80px] h-[49px]',
    1: 'w-[70px] h-[32px]',
    8: 'w-[38px] h-[25px]',
    3: 'w-[60px] h-[15px]',
    14: 'w-[65px] h-[28px]',
    12: 'w-[90px] h-[10px]',
    4: 'w-[65px] h-[10px]',

    //left muscles
    11: 'w-[85px] h-[40px]',
    5: 'w-[65px] h-[32px]',
    13: 'w-[80px] h-[32px]',
    10: 'w-[55px] h-[25px]',
    7: 'w-[80px] h-[18px]',
    2: 'w-[50px] h-[40px]',
    6: 'w-[60px] h-[90px]',
  }
  let labelPositionBackIndex = {
    23: 'bottom-28 right-14 z-10',
    5: 'bottom-44 right-[80px]',
    22: 'bottom-60 right-14  z-10',
    4: 'bottom-[260px] left-[50px]',
    18: 'top-[253px] right-36',
    12: 'bottom-[275px] right-[65px]',
    24: 'top-[175px] right-[36px] z-10',
    19: 'top-[215px] right-[36px]',
    21: 'bottom-[290px] left-[45px]',
    20: 'bottom-[330px] right-14',
    3: 'top-[160px] left-5',
    17: 'top-[210px] left-8 z-20',
    2: 'top-[128px] right-12 ',
    16: 'top-[85px] left-[65px]',
    1: 'top-[90px] right-[90px]',
    15: 'top-[128px] left-[85px]',
  }
  let labelPositionBackDimensions = {
    23: 'w-[65px] h-[49px]',
    5: 'w-[50px] h-[32px]',
    22: 'w-[54px] h-[6px]',
    4: 'w-[50px] h-[10px]',
    18: 'w-[45px] h-[14px]',
    12: 'w-[78px] h-[24px]',
    24: 'w-[80px] h-[10px]',
    19: 'w-[85px] h-[10px]',
    21: 'w-[95px] h-[10px]',
    20: 'w-[65px] h-[16px]',
    3: 'w-[40px] h-[100px]',
    17: 'w-[50px] h-[18px]',
    2: 'w-[45px] h-[40px]',
    16: 'w-[45px] h-[90px]',
    1: 'w-[50px] h-[50px]',
    15: 'w-[50px] h-[50px]',
  }

  let frontleftMuscle = [11, 5, 13, 10, 7, 2, 6]
  let backleftMuscle = [4, 18, 21, 3, 17, 16, 15, 18]

  const handleMouseEnter = (item) => {
    setFocusedItem(item)
  }
  const handleMouseLeave = () => {
    setFocusedItem(false)
  }
  //18
  return (
    <AllBodyMapContainer>
      <FrontBackContainer $selectedValue={frontids} $hoverEnabled={hoverable} $h={h} $w={w}>
        <div className="relative w-full h-full">
          <SVGBodyMapFront />

          {showModal &&
            frontids?.map((item) => {
              return !frontleftMuscle.includes(item) ? (
                <>
                  {labelPositionFrontIndex[item] && (
                    <div key={item} className={`absolute ${labelPositionFrontIndex[item]}`}>
                      <div
                        className={`${
                          labelPositionFrontDimensions[item]
                        } bg-transparent border-b-2  border-r-2 rounded-br-md relative cursor-pointer ${
                          focusedItem === item ? 'border-r-lightGreen border-b-lightGreen' : 'border-r-bodyActiveColor border-b-bodyActiveColor'
                        } `}
                        onMouseEnter={() => handleMouseEnter(item)}
                        onMouseLeave={handleMouseLeave}
                      >
                        <div
                          className={`w-2 h-2  rounded-full absolute -bottom-[4.5px] -left-1 cursor-pointer ${
                            focusedItem === item ? 'bg-lightGreen' : 'bg-bodyActiveColor'
                          }`}
                        ></div>
                        <div
                          className={`h-[31px]  rounded-[3.51px] py-2 px-2 absolute -top-7 -right-12 hover:cursor-pointer ${
                            focusedItem === item ? 'bg-lightGreen' : 'bg-bodyActiveColor'
                          }`}
                        >
                          <div className="flex text-[#333333] text-xs ltr:font-primary-rg rtl:font-primary-he-rg font-bold  w-full gap-x-[1px]">
                            <p className=" truncate text-[#333333] text-xs ltr:font-primary-rg rtl:font-primary-he-rg font-bold ">
                              {data?.find((val) => val?.muscle_Modal == item)?.muscleName}
                            </p>
                            {showPercentage && (
                              <p className="text-[#333333] text-xs ltr:font-primary-rg rtl:font-primary-he-rg font-bold ">
                                {musclePercentages[item]}%
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {labelPositionFrontIndex[item] && (
                    <div key={item} className={`absolute ${labelPositionFrontIndex[item]}`}>
                      <div
                        className={`${labelPositionFrontDimensions[item]} bg-transparent border-b-2 border-l-2  rounded-bl-md relative  ${
                          focusedItem === item ? 'border-l-lightGreen border-b-lightGreen' : 'border-l-bodyActiveColor border-b-bodyActiveColor'
                        }`}
                        onMouseEnter={() => handleMouseEnter(item)}
                        onMouseLeave={handleMouseLeave}
                      >
                        <div
                          className={`w-2 h-2  rounded-full absolute -bottom-[4.5px] -right-1 cursor-pointer ${
                            focusedItem === item ? 'bg-lightGreen' : 'bg-bodyActiveColor'
                          }`}
                        ></div>
                        <div
                          className={`h-[31px] rounded-[3.51px] py-2 px-2 absolute -top-7 -left-12 cursor-pointer  ${
                            focusedItem === item ? 'bg-lightGreen' : 'bg-bodyActiveColor'
                          }`}
                        >
                          <div className="flex text-[#333333] text-xs ltr:font-primary-rg rtl:font-primary-he-rg font-bold  w-full gap-x-[1px]">
                            <p className=" truncate text-[#333333] text-xs ltr:font-primary-rg rtl:font-primary-he-rg font-bold ">
                              {data?.find((val) => val?.muscle_Modal == item)?.muscleName}
                            </p>
                            {showPercentage && (
                              <p className="text-[#333333] text-xs ltr:font-primary-rg rtl:font-primary-he-rg font-bold ">
                                {musclePercentages[item]}%
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )
            })}
        </div>
      </FrontBackContainer>
      <FrontBackContainer $selectedValue={backIds} $hoverEnabled={hoverable} $h={h} $w={w}>
        <div className="relative w-full h-full">
          <SVGBodyMapBack />
          {showModal &&
            backIds?.map((item) => {
              return !backleftMuscle.includes(item) ? (
                <>
                  {labelPositionBackIndex[item] && (
                    <div key={item} className={`absolute ${labelPositionBackIndex[item]} `}>
                      <div
                        className={`${labelPositionBackDimensions[item]} bg-transparent border-b-2  border-r-2  rounded-br-md relative   ${
                          focusedItem === item ? 'border-r-lightGreen border-b-lightGreen' : 'border-r-bodyActiveColor border-b-bodyActiveColor'
                        } `}
                        onMouseEnter={() => handleMouseEnter(item)}
                        onMouseLeave={handleMouseLeave}
                      >
                        <div
                          className={`w-2 h-2  rounded-full absolute -bottom-[4.5px] -left-1 cursor-pointer ${
                            focusedItem === item ? 'bg-lightGreen' : 'bg-bodyActiveColor'
                          }`}
                        ></div>
                        <div
                          className={` h-[31px] rounded-[3.51px] py-2 px-2 absolute -top-7 -right-12 cursor-pointer ${
                            focusedItem === item ? 'bg-lightGreen' : 'bg-bodyActiveColor'
                          }`}
                        >
                          <div className="flex text-[#333333] text-xs ltr:font-primary-rg rtl:font-primary-he-rg font-bold  w-full gap-x-[1px]">
                            <p className=" truncate text-[#333333] text-xs ltr:font-primary-rg rtl:font-primary-he-rg font-bold ">
                              {data?.find((val) => val?.muscle_Modal == item)?.muscleName}
                            </p>
                            {showPercentage && (
                              <p className="text-[#333333] text-xs ltr:font-primary-rg rtl:font-primary-he-rg font-bold ">
                                {musclePercentages[item]}%
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {labelPositionBackIndex[item] && (
                    <div key={item} className={`absolute ${labelPositionBackIndex[item]}`}>
                      <div
                        className={`${
                          labelPositionBackDimensions[item]
                        } bg-transparent border-b-2   border-l-2  rounded-bl-md relative hover:cursor-pointer  ${
                          focusedItem === item ? 'border-l-lightGreen border-b-lightGreen' : 'border-l-bodyActiveColor border-b-bodyActiveColor'
                        } `}
                        onMouseEnter={() => handleMouseEnter(item)}
                        onMouseLeave={handleMouseLeave}
                      >
                        <div
                          className={`w-2 h-2  rounded-full absolute -bottom-[4.5px] -right-1 cursor-pointer ${
                            focusedItem === item ? 'bg-lightGreen' : 'bg-bodyActiveColor'
                          }`}
                        ></div>
                        <div
                          className={`  items-center  rounded-[3.51px] ${
                            item != 18 ? 'py-2 px-2 h-[31px] -top-7' : ' h-[25px] px-1 py-1 -top-5'
                          } absolute  -left-12 hover:cursor-pointer ${focusedItem === item ? 'bg-lightGreen' : 'bg-bodyActiveColor'}`}
                        >
                          <div className="flex text-[#333333] text-xs ltr:font-primary-rg rtl:font-primary-he-rg font-bold  w-full gap-x-[1px]">
                            <p className=" truncate text-[#333333] text-xs ltr:font-primary-rg rtl:font-primary-he-rg font-bold ">
                              {data?.find((val) => val?.muscle_Modal == item)?.muscleName}
                            </p>
                            {showPercentage && (
                              <p className="text-[#333333] text-xs ltr:font-primary-rg rtl:font-primary-he-rg font-bold ">
                                {musclePercentages[item]}%
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )
            })}
        </div>
      </FrontBackContainer>
    </AllBodyMapContainer>
  )
}

export { BodyMapPercentage }
