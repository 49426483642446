import React, { useState, useEffect, useContext } from 'react'
import Assets from 'assets/images'
import { ProfileAvatar } from 'components/PlayBoard/ProfileAvatar'
import { TimeClock } from 'components/PlayBoard/TimeClock'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { WorkoutStatus } from 'components/PlayBoard/WorkoutStatus'
import { getMonitorData } from 'store/monitorSlice/monitorAction'
import Confetti from 'react-confetti'
import io from "socket.io-client";
import { SocketContext } from 'socketContex'
import { Loader } from 'components/compounds/Loader'
export default function WorkoutMonitor() {
  const { customerDetail } = useSelector((state) => state.user)
  const [isCollapsed, setCollapsed] = useState(false)
  const [gymTimeLine, setGymTimeLine] = useState([])
  const [monitorData, setMonitorData] = useState()
  const [bestRecordId, setBestRecordId] = useState([])
  const [loader, setLoader] = useState(false)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { direction } = useSelector((state) => state.language)
  const isRTL = direction === 'rtl'
  const toggleCollapse = () => setCollapsed(!isCollapsed)

  useEffect(() => {
    const getData = async () => {
      const id = customerDetail.id
      dispatch(getMonitorData(action, id, t, direction, setWorkoutTimeLine, setLoader))
    }
    getData()
    // eslint-disable-next-line
  }, []);

  const [socket, setSocket] = useState(null);

  const { user } = useSelector((state) => state.auth);
  let userId = user?.role === "gym_admin" ? user?.id.toString() : "";

  useEffect(() => {
    const socketUrl = process.env.REACT_APP_SOCKET_URL;

    // Only initiate Socket.IO if userId is present
    if (userId) {
      const socketInstance = io(socketUrl);
      setSocket(socketInstance);

      socketInstance.on("connect", () => {
        console.log("Connected to WebSocket");
      });

      socketInstance.on("connect_error", (err) => {
        console.error("Connection Error: ", err);
      });

      // Emit to join room after connection is established
      socketInstance.emit("joinRoom", userId);

      // Cleanup function: leave room, remove listeners, and disconnect on unmount
      return () => {
        socketInstance.emit("leaveRoom", userId);
        socketInstance.removeAllListeners();
        socketInstance.disconnect();
        console.log("Disconnected from WebSocket");
      };
    }

    // Optional: cleanup any existing socket instance if userId becomes empty
    return () => {
      if (socket) {
        socket.emit("leaveRoom", userId);
        socket.removeAllListeners();
        socket.disconnect();
      }
    };
  }, [userId]); // Re-run the effect when userId changes

  useEffect(() => {
    // Connect to the WebSocket server
    if (socket) {
      socket.on('monitorBoardData', (data) => {
        setMonitorData(data)
      })
      socket.on('newBestRecord', (data) => {
        setBestRecordId((pre) => [...pre, data.workoutId])
        setTimeout(() => {
          const temp = bestRecordId.filter((x) => x !== data.workoutId)
          setBestRecordId(temp)
        }, 8000)
      })
      socket.on('coachData', (data) => {
        setGymTimeLine(data)
      })
    }
    return () => {
      if (socket) {
        console.log("Removing All Socket Listeners")
        socket?.removeAllListeners()
      }
    }
    // eslint-disable-next-line
  }, [socket ])

  const action = (data) => {
    setMonitorData(data)
  }

  const setWorkoutTimeLine = (data) => {
    setGymTimeLine(data.newdata)
  }

  const isOneCoach = monitorData?.result?.length === 1
  const isTwoCoach = monitorData?.result?.length === 2

  const getNextExercise = (data) => {
    const filterParitalExercise = data.filter((x) => x.status !== 'completed')
    //sorting the index to get last completed index in Desc
    filterParitalExercise.sort((a, b) => a.index - b.index)

    let nextExericseName
    if (filterParitalExercise.length > 1) {
      //addindexing the 2 to get next exercise
      const findExercise = filterParitalExercise[1]
      nextExericseName = 'Next :' + findExercise?.name
    } else {
      nextExericseName = filterParitalExercise.length > 0 ? t('LAST_EXERCISE') : 'completed'
    }
    return nextExericseName
  }

  const getCurrentExercise = (data) => {
    const filterParitalExercise = data.filter((x) => x.status !== 'completed')
    //sorting the index to get last completed index in Desc
    filterParitalExercise.sort((a, b) => a.index - b.index)
    let currentExercise = filterParitalExercise.length > 0 ? filterParitalExercise[0].index : -1

    let nextExericseName
    if (filterParitalExercise.length >= 1) {
      const findExercise = filterParitalExercise.find((x) => x.index === currentExercise)
      nextExericseName = findExercise?.name
    } else {
      nextExericseName = 'completed'
    }
    return nextExericseName
  }

  const getCurrentExerciseValues = (data) => {
    const filterParitalExercise = data.filter((x) => x.status !== 'completed')
    //sorting the index to get last completed index in Desc
    filterParitalExercise.sort((a, b) => a.index - b.index)
    let currentExercise = filterParitalExercise.length > 0 ? filterParitalExercise[0].index : -1

    let exercise
    if (filterParitalExercise.length >= 1) {
      const findExercise = filterParitalExercise.find((x) => x.index === currentExercise)
      exercise = findExercise
    } else {
      exercise = filterParitalExercise[0]
    }
    return exercise
  }

  return (
    <div className="p-4 space-y-4">
      <div className="flex justify-between">
        <h6 className={`text-[#BFE9FF] text-sm ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>{t('MONITOR')}</h6>
        <TimeClock />
      </div>
      <div>
        <div className="flex justify-between">
          <div>
            <h6 className="text-[#fff] text-[1.5rem]"> {`${t('MONITOR')} `}</h6>
          </div>
          <h6 className="text-[#fff] text-[1.5rem]"> {` ${monitorData?.total_completed} / ${monitorData?.total_exercise} ${t('COMPLETED')} `}</h6>
        </div>
      </div>
      <>
        {loader ? (
          <Loader className="" size="w-8 h-8" fill="#003353" />
        ) : (
          /* {left container} */
          <div className="flex flex-row">
            <div
              className={`h-full bg-secondaryBackground text-[#fff] rounded-lg p-4 relative transition-all duration-300`}
              style={{ minWidth: isCollapsed ? '80px' : '300px', maxWidth: isCollapsed ? '80px' : '300px' }}
            >
              <div className={`absolute top-12 ${isRTL ? '-left-3' : '-right-3'} z-50`}>
                <div className="cursor-pointer h-6 w-6 shadow-lg bg-statusBg rounded-full flex justify-center items-center" onClick={toggleCollapse}>
                  <img
                    src={
                      !isRTL
                        ? isCollapsed
                          ? Assets.WorkoutSidebarRightArrow
                          : Assets.WorkoutSidebarLeftArrow
                        : isCollapsed
                        ? Assets.WorkoutSidebarLeftArrow
                        : Assets.WorkoutSidebarRightArrow
                    }
                    alt="profile"
                    className="w-2 h-3 rounded-full bg-cover text-center shadow-xl"
                  />
                </div>
              </div>
              <div className="space-y-4 h-full overflow-y-auto scrollbar-none no-scrollbar">
                {!isCollapsed && (
                  <div className="flex justify-between">
                    <div></div>
                  </div>
                )}
                {gymTimeLine?.map((item, index) => (
                  <div key={index}>
                    {isCollapsed ? (
                      <div className="space-y-2">
                        <h1 className={`text-xs text-center ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>{item?.startHour}</h1>
                        <div className="border border-secondaryBackgroundContrast rounded-xl" />
                      </div>
                    ) : (
                      <div className="flex items-center w-full">
                        <h1 className={`text-xs w-3/12 ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>{item?.startHour}</h1>
                        <div className="border border-secondaryBackgroundContrast rounded-xl w-9/12" />
                      </div>
                    )}
                    <div className="space-y-2 py-3">
                      {item.workouts.map((workout) => (
                        <div key={workout.id}>
                          {isCollapsed ? (
                            <div className="flex justify-center">
                              <ProfileAvatar image={workout?.Trainee?.User?.avatar} name={workout?.Trainee?.User?.firstName} />
                            </div>
                          ) : (
                            <div className="flex justify-between space-x-2 rtl:space-x-reverse">
                              <div className="flex items-center space-x-2 rtl:space-x-reverse">
                                <ProfileAvatar image={workout?.Trainee?.User?.avatar} name={workout?.Trainee?.User?.firstName} />
                                <h6 className={`text-xs truncate ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>
                                  {workout?.Trainee?.User?.firstName}
                                </h6>
                              </div>
                              <WorkoutStatus status={workout?.Workout?.status} />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* right container */}
            <div className="text-white space-y-4">
              {monitorData?.result?.map((item, index) => (
                <div className="w-full flex space-x-2">
                  <div className="p-4 flex flex-col items-center space-y-2">
                    <div className="h-20 w-20">
                      <ProfileAvatar image={item?.avatar} name={item?.name} color={'#E5A772'} size={'xxlarge'} />
                    </div>
                    <h6 className="text-sm text-center">{item?.name} </h6>
                  </div>

                  <div
                    className={`flex items-center   w-full min-h-[14.5rem]  ${
                      index === 0
                        ? isOneCoach
                          ? 'flex-wrap'
                          : 'flex-nowrap'
                        : index === 1
                        ? isTwoCoach
                          ? 'flex-wrap'
                          : 'flex-nowrap'
                        : 'flex-nowrap'
                    }`}
                  >
                    {item?.workout?.map((workout) => {
                      const percentage =
                        workout.total_completed_exercise === 0 ? '10%' : `${(workout.total_completed_exercise / workout.total_exercises) * 100}%`
                      return (
                        <div
                          className={`p-4 bg-secondaryBackground rounded-lg shadow-lg  space-y-2  w-[30rem] min-h-[14.5rem] ${
                            isOneCoach ? `my-2` : 'my-0'
                          } mx-2  `}
                          key={workout?.id}
                        >
                          {bestRecordId?.includes(workout?.id) ? (
                            <div className=" min-h-[13.5rem]  min-w-[23.5rem] flex relative justify-center items-center ">
                              <Confetti width={370} height={200} />
                              <div className="flex flex-col space-y-2">
                                <ProfileAvatar image={workout?.userAvatar} name={workout?.traineeName} color={'#E5A772'} size={'xxlarge'} />
                                <p className="text-fuchsia-50"> New record 👏 🏆 </p>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="flex items-center">
                                <ProfileAvatar image={workout?.userAvatar} name={workout.traineeName} color={'#E5A772'} size={'large'} />
                                <h6 className="text-sm font-Activ-Grotesk-Rg text-monitorSecondary px-4 capitalize-first ">
                                  {workout?.traineeName}{' '}
                                </h6>
                              </div>
                              <div className="flex rtl:flex-row-reverse flex-row-reverse items-center space-x-2 w-full min-w-[24.5rem]">
                                <div className="w-10/12 h-7 rounded-full bg-[#28343A]">
                                  <div className="h-full rounded-full bg-green-500" style={{ width: percentage }}></div>
                                </div>
                                <h6 className="text-sm w-2/12">{`${workout.total_completed_exercise}/${workout.total_exercises}`} </h6>
                              </div>
                              <div className="flex  space-x-6 items-center justify-between ">
                                <h6 className="text-sm text-monitorSecondary  font-Activ-Grotesk-Rg w-1/2">
                                  {getCurrentExercise(workout?.Exercise)}{' '}
                                </h6>
                                <div className="flex flex-row">
                                  <div className="flex flex-col space-4 mx-2">
                                    <h6 className="text-[1rem] text-center  text-monitorSecondary  font-Activ-Grotesk-Rg capitalize-first ">
                                      {getCurrentExerciseValues(workout?.Exercise)?.resistancename}{' '}
                                    </h6>
                                    <h2 className="text-[2rem] text-center text-monitorSecondary  font-primary-he-md">
                                      {getCurrentExerciseValues(workout?.Exercise)?.bestSet?.resistance_value}{' '}
                                    </h2>
                                  </div>
                                  <div className="flex flex-col space-4 mx-2 ">
                                    <h6 className="text-[1rem] text-center text-monitorSecondary font-Activ-Grotesk-Rg capitalize-first">
                                      {getCurrentExerciseValues(workout?.Exercise)?.trackingname}{' '}
                                    </h6>
                                    <h2 className="text-[2rem] text-center text-monitorSecondary  font-primary-he-md">
                                      {getCurrentExerciseValues(workout?.Exercise)?.bestSet?.tracking_value}{' '}
                                    </h2>
                                  </div>
                                </div>
                              </div>
                              <div className="h-[1px] bg-[#505C62] w-full" />
                              <div className="py-2 w-full">
                                <h6 className="text-[1rem]  rtl:text-right text-monitorSecondary  font-primary-he-md ">
                                  {`${getNextExercise(workout?.Exercise)}`}
                                </h6>
                              </div>
                            </>
                          )}
                        </div>
                      )
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </>
    </div>
  )
}
