import { createSlice } from '@reduxjs/toolkit'

import { useSelector } from 'react-redux'

const initialState = {
  loader: false,
}

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload
    },
  },
})

export const useEventSelector = () => useSelector((state) => state.eventReducer)

export const eventActions = eventSlice.actions
export const eventReducer = eventSlice.reducer
