import { Images } from 'assets'
import { Modal } from 'components/compounds/Modal'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Loader } from 'components/compounds/Loader'
import CustomToastify from 'components/compounds/Toast'

import { constants } from 'constant'
import {
  createExercise,
  getAllMainExercises,
  getAllTags,
  getExerciseById,
  getGymAllExerciseNames,
  updateExercise,
} from 'store/exerciseSlice/exerciseActions'
import { getSingleGymInstruments } from 'store/userSlice/userActions'
import ExerciseFooter from './ExerciseFooter'
import AdvancedExercise from './components/AdvancedExercise'
import BasicExercise from './components/BasicExercise'

const Steps = [
  { id: 1, name: 'Basic' },
  { id: 2, name: 'Advanced' },
]

export default function CreateExercise({ show, setShow, exerciseID, setExerciseID, isCopy, setIsCopy, isView, setIsView }) {
  const { loader, currentExercise } = useSelector((state) => state?.exercise)
  // eslint-disable-next-line
  const [disable, setDisable] = useState(isView)

  const [stepActive, setStepActive] = useState(1)
  // eslint-disable-next-line
  const [openModal, setOpenModal] = useState(false)

  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { direction } = useSelector((state) => state.language)
  const [selectedMuscle, setSelectedMuscle] = useState([{ id: null, name: null, type: 'Primary' }])
  const [resistanceName, setResistanceName] = useState(constants.RESISTANCE[1])
  const [trackingName, setTrackingName] = useState(constants.TRACKING_TYPE[0])
  const [incline, setIncline] = useState(constants.INCLINE[0])
  const [position, setPosition] = useState(constants.POSITION[0])
  const [difficultyLevel, setDifficultyLevel] = useState(constants.DIFFICULTY_LEVEL[0])
  const [isSingleBodyPart, setIsSingleBodyPart] = useState(true)
  const [tools, setTools] = useState(null)
  const [exerciseName, setExerciseName] = useState('')
  const [machine, setMachine] = useState(null)
  const [mainExercise, setMainExercise] = useState(null)
  const [selectedTags, setSelectedTags] = useState([])
  const [category, setCategory] = useState(constants.CATEGORY[0])
  const [description, setDescription] = useState('')

  const { customerDetail } = useSelector((state) => state.user)
  const handleClose = () => {
    setSelectedMuscle([{ id: null, name: null, type: 'Primary' }])
    setResistanceName(constants.TYPE_OF_RESISTANCE[0])
    setTrackingName(constants.TRACKING_TYPE[0])
    setDifficultyLevel(constants.DIFFICULTY_LEVEL[0])
    setIsSingleBodyPart(constants.SINGLE_BODY_PART[0])
    setTools(constants.TOOLS[0])
    setExerciseName('')
    setMachine(null)
    setMainExercise(null)
    setSelectedTags(null)
    setShow && setShow(false)
    setExerciseID && setExerciseID(null)
    setIsCopy && setIsCopy(false)
    setIsView && setIsView(false)
  }
  const action = () => {
    handleClose()
  }
  const toggleOpen = () => setOpenModal(true)

  const onSubmit = async (values) => {
    if (resistanceName === 'Machine' && machine === null) {
      CustomToastify(t('ENTER_MACHINE'), '', '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return
    }
    let tagsString
    if (selectedTags != null && selectedTags.length > 0) {
      tagsString = selectedTags.join(',')
    } else {
      tagsString = null
    }

    let data = {
      gymId: customerDetail?.id,
      exerciseName: exerciseName,
      muscles: selectedMuscle,
      resistanceName: resistanceName,
      trackingName: trackingName,
      difficultyLevel: difficultyLevel,
      isSingleBodyPart: isSingleBodyPart,
      mainExercise: mainExercise,
      tags: tagsString,
      machineID: resistanceName === 'machine' ? machine?.id : null,
      tool: tools,
      position: position,
      positionIncline: incline === 'none' ? null : incline,
      category: category,
      description: description,
    }

    if (exerciseID === null || (exerciseID && isCopy)) {
      dispatch(createExercise(data, t, direction, customerDetail?.id, action))
    } else {
      let updated = {
        ...data,
        exerciseID: exerciseID,
      }

      dispatch(updateExercise(updated, t, direction, customerDetail?.id, action))
    }
  }

  useEffect(() => {
    dispatch(getAllMainExercises(customerDetail?.id, t, direction))
    dispatch(getSingleGymInstruments(customerDetail?.id, t, direction))
    dispatch(getAllTags(customerDetail?.id, t, direction))
    dispatch(getGymAllExerciseNames(customerDetail?.id, t, direction))
    // eslint-disable-next-line
  }, [])

  const notify = () => {
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
  }
  const HandleValidation = () => {
    if (exerciseName.length <= 0) {
      CustomToastify(t('ENTER_EXERCISE_NAME'), '', '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return
    }
    if (selectedMuscle.length === 0) {
      CustomToastify(t('SELECT_ONE_MUSCLE'), '', '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return
    }
    let isDuplicate = false
    const idSet = new Set()
    for (const obj of selectedMuscle) {
      if (idSet.has(obj.id)) {
        isDuplicate = true // Duplicate data found
      }
      idSet.add(obj.id)
    }
    if (isDuplicate === true) {
      CustomToastify(t('DUPLICATE_MUSCLE'), '', '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return
    }

    const hasPrimaryMuscle = selectedMuscle.some((muscle) => muscle.type === 'Primary')

    if (!hasPrimaryMuscle) {
      CustomToastify(t('ATLEAST_ONE_PM'), '', '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return
    }

    const hasValidMuscleNames = selectedMuscle.every((muscle) => muscle.name !== null)

    if (!hasValidMuscleNames) {
      CustomToastify(t('SELECT_ALL_MUSCLE_NAME'), '', '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return
    }
    setStepActive(2)
  }

  useEffect(() => {
    if (exerciseID) {
      dispatch(getExerciseById(exerciseID, customerDetail?.id, t, direction))
    }
    // eslint-disable-next-line
  }, [exerciseID])
  useEffect(() => {
    if (currentExercise && currentExercise != null && exerciseID) {
      setSelectedMuscle(currentExercise?.muscles)
      constants.TYPE_OF_RESISTANCE.forEach((item) => {
        if (item.toLowerCase() === currentExercise?.resistancename) {
          setResistanceName(item)
        }
      })
      constants.TRACKING_TYPE.forEach((item) => {
        if (item === currentExercise?.trackingname) {
          setTrackingName(item)
        }
      })
      constants.DIFFICULTY_LEVEL.forEach((item) => {
        if (item.toLowerCase() === currentExercise?.difficultyLevel) {
          setDifficultyLevel(item)
        }
      })
      constants.RESISTANCE.forEach((item) => {
        if (item === currentExercise?.resistancename) {
          setResistanceName(item)
        }
      })

      if (currentExercise?.tool != null) {
        constants.TOOLS.forEach((item) => {
          if (item === currentExercise?.tool) {
            setTools(item)
          }
        })
      }
      constants.POSITION.forEach((item) => {
        if (item === currentExercise?.position) {
          setPosition(item)
        }
      })
      if (currentExercise?.incline != null) {
        constants.INCLINE.forEach((item) => {
          if (item === currentExercise?.incline) {
            setIncline(item)
          }
        })
      } else {
        setIncline(constants.INCLINE[0])
      }
      constants.CATEGORY.forEach((item) => {
        if (item === currentExercise?.category) {
          setCategory(item)
        }
      })
      setDescription(currentExercise?.description)
      setIsSingleBodyPart(currentExercise?.isSingleBodyPart)

      setExerciseName(!isCopy ? currentExercise?.exerciseName : `${currentExercise?.exerciseName}-1`)
      setMachine(currentExercise?.machine)
      setMainExercise(currentExercise?.mainExercise)
      setSelectedTags(currentExercise?.tags === undefined || currentExercise?.tags === null ? [] : currentExercise?.tags)
    }
    // eslint-disable-next-line
  }, [exerciseID, currentExercise])

  return (
    <Modal
      className={`h-full  ${direction === 'ltr' ? 'rounded-l-3xl' : 'rounded-r-3xl'} w-[360px]  lg:w-[480px]`}
      childrenClass="pt-0 bp-4 mt-2 px-4  overflow-auto "
      open={show}
      title={exerciseID ? (!isView ? t('EDIT_EXERCISE') : t('VIEW_EXERCISE')) : t('ADD_A_EXERCISE')}
      handleClose={handleClose}
      handleSubmit={onSubmit}
      handleNotify={notify}
      loader={loader}
      isEdited={exerciseID != null ? true : false}
      toggleOpen={toggleOpen}
      disabled={disable}
      direction={direction}
      footer={
        <ExerciseFooter
          handleSubmit={onSubmit}
          handleCheck={() => {
            HandleValidation()
          }}
          id={exerciseID}
          active={stepActive}
          setActive={setStepActive}
          disable={isView}
        />
      }
    >
      {loader ? (
        <Loader className="" size="w-8 h-8" fill="#003353" />
      ) : (
        <>
          <div className="sticky top-0 z-10 flex w-full bg-sidebar" style={{ zIndex: 20 }}>
            {Steps.map((item, index) => {
              return (
                <div 
                key={item?.id}
                className="flex flex-row justify-between py-2 text-center">
                  <div className={`rounded-full w-6 h-6 flex items-center justify-center  bg-borderActive ${stepActive < item?.id && 'opacity-50'}`}>
                    <div className="text-gray-dark rounded-full flex justify-center items-center">
                      <p className={` text-[12px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} `}>{item?.id}</p>
                    </div>
                  </div>
                  <div
                    className={`px-2 ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-[14px] ${
                      stepActive < item?.id && 'opacity-50'
                    }`}
                  >
                    {t(item?.name)}
                  </div>
                  {item?.id < 2 && <div className={`px-4 ${stepActive < item?.id && 'opacity-50'}`}>-</div>}
                </div>
              )
            })}
          </div>
          <div className="">
            {stepActive === 1 && (
              <BasicExercise
                disable={disable}
                setSelectedMuscle={setSelectedMuscle}
                selectedMuscle={selectedMuscle}
                setExerciseName={setExerciseName}
                exerciseName={exerciseName}
                setIsCopy={setIsCopy}
                isCopy={isCopy}
                setExerciseID={setExerciseID}
              />
            )}
            {stepActive === 2 && (
              <AdvancedExercise
                setResistanceName={setResistanceName}
                resistanceName={resistanceName}
                setTrackingName={setTrackingName}
                trackingName={trackingName}
                setDifficultyLevel={setDifficultyLevel}
                difficultyLevel={difficultyLevel}
                setIsSingleBodyPart={setIsSingleBodyPart}
                isSingleBodyPart={isSingleBodyPart}
                setMainExercise={setMainExercise}
                mainExercise={mainExercise}
                machine={machine}
                setMachine={setMachine}
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                exerciseID={exerciseID}
                setPosition={setPosition}
                position={position}
                setIncline={setIncline}
                incline={incline}
                setTools={setTools}
                tools={tools}
                setCategory={setCategory}
                category={category}
                setDescription={setDescription}
                description={description}
                isCopy={isCopy}
                disable={disable}
              />
            )}
          </div>
        </>
      )}
    </Modal>
  )
}
