import { yupResolver } from '@hookform/resolvers/yup'
import { Images } from 'assets'
import { LabelInputFocused } from 'components/compounds/LabelInputFocused'
import PrimaryButton from 'components/elements/PrimaryButton'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import { ProfileAvatar } from 'components/PlayBoard/ProfileAvatar'
import PrimaryLoader from 'components/PrimaryLoader'
import { LabelInput } from 'components/compounds/LabelInput'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getGymDetailsById } from 'store/authSlice/authActions'
import { createTraineeBySignUP } from 'store/gymAdminSlice/gymAdminActions'
import { signUpTraineeSchema } from 'validations/formsValidations'
const Homepage = () => {
  const { t } = useTranslation()
  const { language } = useSelector((state) => state.language)
  const [searchParams] = useSearchParams()
  const { isLoading } = useSelector((state) => state.auth)
  const { gymLoading } = useSelector((state) => state.gymAdmin)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [gymDetail, setGymDetail] = useState(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const action = (data) => {
    setGymDetail(data)
  }
  const gymDetails = async (id) => {
    dispatch(getGymDetailsById(id, direction, t, action))
  }
  React.useEffect(() => {
    const gymId = searchParams.get('gymid')
    if (gymId) {
      const newgymId = parseInt(gymId)
      gymDetails(newgymId, action)
    }
    // eslint-disable-next-line
  }, [searchParams])
  const methods = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(signUpTraineeSchema),
    defaultValues: {
      traineeName: '',
      phoneNo: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
  })
  const { control, handleSubmit, formState } = methods
  const { errors } = formState
  const { direction } = useSelector((state) => state.language)

  const onSubmit = (data) => {
    data.gender = 'male'
    data.gymId = gymDetail?.id || null
    dispatch(createTraineeBySignUP(data, action, t, direction, null))
    navigate(`/login`)
  }

  const isLTR = language === 'en'
  return (
    <>
      {gymLoading ? (
        <PrimaryLoader text={t('LOADING')} />
      ) : (
        <div className="grid grid-cols-1 text-white" dir={isLTR ? 'rtl' : 'ltr'}>
          <div className="relative">
            <div className="w-[55%] h-screen hidden md:block">
              <img src={Images.banner} alt="loginBanner" className="hidden md:block w-full h-[100vh] object-cover" />
            </div>
            <div className={`absolute top-0 ${isLTR ? 'left-0' : 'right-0'} h-full w-full md:w-1/2 `}>
              <div
                className={`relative bg-primary flex flex-col items-center md:justify-center min-h-screen space-y-12 ${
                  isLTR ? 'md:rounded-r-[24px]' : 'md:rounded-l-[24px]'
                }`}
              >
                <div className="w-full h-[200px] block   md:hidden relative">
                  <div className="absolute w-full right-0 left-0 top-0 bottom-0 z-20">
                    <div className="flex justify-center items-center flex-col w-full">
                      {gymDetail?.logo && <ProfileAvatar image={gymDetail?.logo} name={gymDetail?.gymName} size={'branding'} color={'red'} />}
                      <span className="font-primary-bd text-[43.5px] font-weight: 900">{gymDetail?.gymName}</span>
                    </div>
                  </div>

                  <img
                    src={Images.banner}
                    alt="loginBanner"
                    className="block rounded-b-3xl  md:hidden w-full h-[200px] object-cover opacity-[0.2]  blur-sm "
                  />
                </div>
                <div className="justify-center items-center pt-5 flex-col hidden md:flex w-full  border-red-500">
                  {gymDetail?.logo && <ProfileAvatar image={gymDetail?.logo} name={gymDetail?.gymName} size={'branding'} color={'red'} />}
                  <span className="font-primary-bd text-[43.5px] text-center	font-weight: 900">{gymDetail?.gymName}</span>
                </div>
                <div className={`flex flex-col items-center w-full px-4 `}>
                  <p className={`text-[24px] text-white mb-2 md:my-4 ${direction === 'ltr' ? 'font-primary-md' : 'font-primary-he-md'}`}>
                    {t('REGISTER')}
                  </p>
                  <div className="flex flex-col items-center w-full">
                    <div className="flex flex-col w-full md:w-[400px] space-y-3" dir={isLTR ? 'ltr' : 'rtl'}>
                      <div className="space-y-3">
                        <LabelInput
                          name="traineeName"
                          type="text"
                          label={t('TRAINEE_NAME')}
                          placeholderValue={t('FULL_NAME')}
                          control={control}
                          required
                          fullWidth
                          error={errors?.traineeName ? true : false}
                          errorMessage={errors?.traineeName?.message}
                          dir={direction}
                          disabled={false}
                          isRequired={true}
                        />
                        <LabelInput
                          name="phoneNo"
                          type="number"
                          label={t('MOBILE_NO')}
                          control={control}
                          placeholderValue={'0520000000'}
                          required
                          fullWidth
                          error={errors?.phoneNo ? true : false}
                          errorMessage={errors?.phoneNo?.message}
                          dir={direction}
                          disabled={false}
                          isRequired={true}
                        />

                        <LabelInput
                          name="email"
                          type="text"
                          label={t('EMAIL_ADDRESS_AS_USERNAME')}
                          placeholderValue={'aaa@gmail.com'}
                          control={control}
                          required
                          fullWidth
                          error={errors?.email ? true : false}
                          errorMessage={errors?.email?.message}
                          dir={direction}
                          disabled={false}
                        />

                        <LabelInputFocused
                          name="password"
                          type={showPassword ? 'text' : 'password'}
                          label={t('PASSWORD_PLACEHOLDER')}
                          placeholderValue={t('PASSWORD_PLACEHOLDER')}
                          control={control}
                          required
                          fullWidth
                          dir={direction}
                          icon={showPassword ? Images.Eye : Images.EyeClosed}
                          error={Object.keys(errors).length > 0 ? true : false}
                          errorMessage={errors?.password?.message}
                          setPasswordShown={setShowPassword}
                          passwordShown={showPassword}
                        />
                        <LabelInputFocused
                          name="confirmPassword"
                          type={showConfirmPassword ? 'text' : 'password'}
                          label={t('CONFIRM_PASSWORD')}
                          placeholderValue={t('CONFIRM_PASSWORD')}
                          control={control}
                          required
                          fullWidth
                          dir={direction}
                          icon={showPassword ? Images.Eye : Images.EyeClosed}
                          error={Object.keys(errors).length > 0 ? true : false}
                          errorMessage={errors?.confirmPassword?.message}
                          setPasswordShown={setShowConfirmPassword}
                          passwordShown={showConfirmPassword}
                        />
                      </div>
                      <PrimaryButton
                        text={t('REGISTER')}
                        type="submit"
                        disabled={false}
                        isIcon={false}
                        onClick={handleSubmit(onSubmit)}
                        className="w-full h-[56px] rounded-[12px] shadow-2xl"
                        bgColor="bg-prussianBlue hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed"
                        textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
                        Loader={isLoading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Homepage
