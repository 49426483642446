import React, { useEffect, useRef, useState } from 'react'
import QRCode from 'react-qr-code'
import moment from 'moment'
import { Images } from 'assets'
import { useOnClickOutside } from 'hooks'
import ReactApexChart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Loader } from 'components/compounds/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from 'store/authSlice/authReducers'
import PrimaryButton from 'components/elements/PrimaryButton'
import { ProfileAvatar } from 'components/PlayBoard/ProfileAvatar'
import { dasboardActions, useDashboard } from 'store/dashboardSlice/dashboardReducers'
import { gymAdminActions } from 'store/gymAdminSlice/gymAdminReducers'
import {
  getStatistics,
  getChartData,
  getCompletedWorkoutsByTrainee,
  getConsecutiveCancelledWorkoutsByTrainee,
  getEventsByDay,
} from 'store/dashboardSlice/dashboardActions'
import { Modal } from 'components/elements/Modal'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const Dropdown = ({ label, options, value, onChange, t, disabled, direction }) => {
  const [isOpen, setOpen] = useState(false)

  const divRef = useRef()
  const toggleOpener = () => setOpen(disabled ? false : !isOpen)
  useOnClickOutside(divRef, () => setOpen(false))

  const selectedItem = options.find((item) => item.id === value.id)

  return (
    <div
      className="flex items-center space-x-4 rtl:space-x-reverse relative bg-textFieldColor px-4 py-2 rounded-lg"
      ref={divRef}
      onClick={toggleOpener}
    >
      {label && <label className={`text-xs ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>{label}</label>}
      <div className={`flex items-center space-x-6 rtl:space-x-reverse cursor-pointer opacity-100 ${disabled && 'opacity-50'}`}>
        <img alt="dropdown" src={Images.DropdownArrow} className="h-2" />
        <h6 className={`text-sm ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} ${selectedItem.label && 'font-bold'}`}>
          {value.label || t('SELECT_OPTION')}
        </h6>
      </div>
      {isOpen && (
        <div className={`absolute ${direction === 'ltr' ? 'top-10 right-0' : 'top-10 left-0'}  rounded-lg shadow-2xl bg-borderColor z-50`}>
          {options?.map((item) => (
            <div
              className={`w-48 px-6 py-2 bg-borderColor rounded-lg hover:bg-statusBg cursor-pointer text-xs  ${
                direction === 'ltr' ? 'font-primary-rg text-right' : 'font-primary-he-rg text-left'
              }`}
              key={item.id}
              onClick={() => onChange && onChange(item)}
            >
              {item.label}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

const CoachesDropdown = ({ label, options, value, onChange, t, disabled, direction }) => {
  const [isOpen, setOpen] = useState(false)
  const divRef = useRef()
  const toggleOpener = () => setOpen(disabled ? false : !isOpen)
  useOnClickOutside(divRef, () => setOpen(false))

  const isOnlyOneSelected = options.length - value.length - 1 === options.length - 2

  return (
    <div
      className="flex items-center space-x-4 rtl:space-x-reverse relative bg-textFieldColor px-4 py-2 rounded-lg"
      ref={divRef}
      onClick={toggleOpener}
    >
      {label && <label className={`text-xs ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>{label}</label>}
      <div className={`flex items-center space-x-6 rtl:space-x-reverse cursor-pointer opacity-100 ${disabled && 'opacity-50'}`}>
        <img alt="dropdown" src={Images.DropdownArrow} className="h-2" />
        <h6 className={`text-sm ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} ${value.label && 'font-bold'}`}>
          {value.label || t('SELECT_OPTION')}
        </h6>
      </div>
      {isOpen && (
        <div className={`absolute ${direction === 'ltr' ? 'top-10 right-0' : 'top-10 left-0'}  rounded-lg shadow-2xl bg-borderColor z-50`}>
          {options?.map((item) => (
            <>
              {item.id === 0 ? (
                <div
                  className={`w-48 px-4 py-2 bg-borderColor rounded-lg hover:bg-statusBg cursor-pointer text-xs justify-end ${
                    direction === 'ltr' ? 'font-primary-rg text-right' : 'font-primary-he-rg text-left'
                  }`}
                  key={item.id}
                >
                  <h1>{item.label}</h1>
                </div>
              ) : (
                <div
                  className={`flex justify-end items-center space-x-2 w-48 px-2 py-2 bg-borderColor rounded-lg hover:bg-statusBg cursor-pointer text-xs  ${
                    direction === 'ltr' ? 'font-primary-rg text-right' : 'font-primary-he-rg text-left'
                  }`}
                  key={item.id}
                  onClick={(e) => {
                    onChange && onChange(item)
                  }}
                >
                  <div className="flex space-x-2 items-center">
                    <h1>{item.label}</h1>
                    <ProfileAvatar image={item?.avatar} name={item.label} color={item?.color === 'BLUE' ? '#E5A772' : item.color} size={'small'} />
                  </div>

                  <div className="flex">
                    <input
                      type="checkbox"
                      checked={value.includes(item.id)}
                      className="custom_checkbox_calendar flex h-5 w-5 items-center  form-check-input"
                      onChange={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        onChange && onChange()
                      }}
                      disabled={isOnlyOneSelected}
                    />
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
      )}
    </div>
  )
}

export default function Main() {
  const { isSuperAdmin } = useSelector((state) => state.auth)
  const { direction } = useSelector((state) => state.language)
  const [currenteDate, setCurrentDate] = useState(moment().toDate())
  const { customerDetail } = useSelector((state) => state.user)
  const { selectedGym } = useSelector((state) => state.auth)
  const [shareUrl, setShareUrl] = useState(null)
  const [openShareModal, setOpenShareModal] = useState(null)
  // eslint-disable-next-line
  const [copied, setCopied] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { statistics, completedWorkoutsByTrainee, eventsByDay, consecutiveCancelledWorkoutsByTrainee, completedWorkoutsByCoach, lastfetched } =
    useDashboard()

  const durationOptions = [
    { id: 1, label: t('LAST_SEVEN_DAYS'), value: 'last_7_days' },
    { id: 2, label: t('LAST_FOURTEEN_DAYS'), value: 'last_14_days' },
    { id: 3, label: t('THIS_MONTH'), value: 'this_month' },
    { id: 4, label: t('LAST_MONTH'), value: 'last_month' },
  ]

  const isGymAdmin = customerDetail?.User?.role === 'gym_admin'
  const payloadId = isGymAdmin ? customerDetail?.id : customerDetail?.User?.Coach?.id

  const durationFilter = durationOptions.find((item) => item.value === completedWorkoutsByCoach?.filterDuration)

  const options = {
    chart: {
      type: 'area',
      stacked: true,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: 'white',
        },
      },
    },
    xaxis: {
      categories: completedWorkoutsByCoach?.data?.labels || [],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        style: {
          colors: 'white',
        },
      },
    },
    fill: {
      opacity: 0.5,
    },
    stroke: {
      show: false,
      curve: 'smooth',
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      theme: 'dark',
    },
  }

  const coachesOptions = [
    {
      id: 0,
      label: t('SELECT_COACH_DROPDOWN'),
      value: 'select coach',
    },
    ...completedWorkoutsByCoach.data.gymCoaches.map((item) => {
      return {
        id: item.id,
        label: item.name,
        value: item.color,
        avatar: item.avatar,
      }
    }),
  ]

  const fetchData = () => {
    dispatch(getStatistics(payloadId, isGymAdmin))
    dispatch(getCompletedWorkoutsByTrainee(payloadId, isGymAdmin))
    dispatch(getConsecutiveCancelledWorkoutsByTrainee(payloadId, isGymAdmin))
    dispatch(getEventsByDay(payloadId, moment(currenteDate).format('DD-MM-YYYY'), isGymAdmin))
    dispatch(getChartData(payloadId, durationFilter?.value, isGymAdmin))
    dispatch(dasboardActions.setLastFetched())
  }
  const onRefresh = () => {
    fetchData()
  }

  useEffect(() => {
    const lastFetchedDate = moment(lastfetched).format('DD-MM-YYYY')
    const currentDate = moment().format('DD-MM-YYYY')
    if (payloadId && (lastFetchedDate !== currentDate || !lastfetched) && (selectedGym === payloadId || !selectedGym)) {
      fetchData()
    }
    // eslint-disable-next-line
  }, [payloadId])

  const goBack = () => {
    const updatedDate = moment(currenteDate).subtract(1, 'days').toDate()
    setCurrentDate(updatedDate)
    dispatch(getEventsByDay(payloadId, moment(updatedDate).format('DD-MM-YYYY'), isGymAdmin))
  }

  const goForward = () => {
    const updatedDate = moment(currenteDate).add(1, 'days').toDate()
    setCurrentDate(updatedDate)
    dispatch(getEventsByDay(payloadId, moment(updatedDate).format('DD-MM-YYYY'), isGymAdmin))
  }

  const onChangeDurationFilter = (item) => {
    dispatch(getChartData(payloadId, item?.value, isGymAdmin))
  }

  const onClickCheckbox = (item) => {
    dispatch(dasboardActions.addCoachToFilter(item?.id))
  }

  const filterCoaches = completedWorkoutsByCoach?.data?.gymCoaches.filter((item) => completedWorkoutsByCoach?.coachesFilter?.includes(item.id))
  const onImageCownload = () => {
    const svg = document.getElementById('QRCode')
    const svgData = new XMLSerializer().serializeToString(svg)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const img = new Image()
    img.onload = () => {
      canvas.width = img.width
      canvas.height = img.height
      ctx.drawImage(img, 0, 0)
      const pngFile = canvas.toDataURL('image/png')
      const downloadLink = document.createElement('a')
      downloadLink.download = 'QRCode'
      downloadLink.href = `${pngFile}`
      downloadLink.click()
    }
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`
  }
  const onCopy = React.useCallback(() => {
    setCopied(true)
  }, [])
  return (
    <div className="flex w-full h-auto flex-col px-4 md:px-8 text-textLightGray">
      <div className="flex flex-row items-center space-x-2 rtl:space-x-reverse justify-start md:justify-end my-[24px]">
        <PrimaryButton
          text={t('SHARE')}
          disabled={false}
          onClick={() => {
            setOpenShareModal(true)
            if (isSuperAdmin) {
              const url = `${window.location.origin}/signup?gymid=${selectedGym}`

              setShareUrl(url)
            } else {
              const gymId = customerDetail?.User.gym_Id
              const url = `${window.location.origin}/signup?gymid=${gymId}`
              setShareUrl(url)
            }
          }}
          className="w-[177px] h-[44px] rounded-[12px] shadow-2xl"
          bgColor="bg-sidebarhover hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed"
          textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
          wid={15}
          ht={15}
        />

        {isSuperAdmin && (
          <PrimaryButton
            text={t('Back to Admin')}
            disabled={false}
            onClick={async () => {
              dispatch(authActions.setSuperAdmin({ isAdmin: false, selectedGym: null }))
              dispatch(gymAdminActions.setAllCoaches([]))
              dispatch(dasboardActions.reset())
              navigate('/home')
            }}
            className="w-[177px] h-[44px] rounded-[12px] shadow-2xl"
            bgColor="bg-sidebarhover hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed"
            textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
            wid={15}
            ht={15}
          />
        )}
        <img alt="refresh" src={Images.Refresh} className="w-8 cursor-pointer" onClick={onRefresh} />
      </div>
      <div className="h-full space-y-3 pb-4 ltr:font-primary-rg rtl:font-primary-he-rg">
        {statistics?.loading ? (
          <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
            {new Array(4).fill(0)?.map((item, index) => (
              <div key={index} className="flex items-center justify-between w-full space-y-2 shadow animate-pulse">
                <div className="w-full h-28 bg-gray-400 rounded-lg dark:bg-secondaryBackground"></div>
              </div>
            ))}
          </div>
        ) : (
          <div className="justify-between w-full flex gap-x-4 ">
            <div className="flex items-center justify-center bg-playCard rounded-xl p-4 space-x-2 rtl:space-x-reverse text-white w-1/4 h-[164px]">
              <div className="flex flex-col items-center justify-center space-y-2 text-xs sm:text-md gap-4">
                <h1 className="text-center text-textLightGray text-base leading-5 font-normal">{t('TRAINEES')}</h1>
                <h1 className="text-[32px] font-bold">{statistics?.data?.trainees.active.currentMonth}</h1>
                <div
                  className={`px-1 rounded py-[2px] space-x-[2px] rtl:space-x-reverse flex justify-around items-center w-[47px] h-[24px] leading-9 text-textLightGray ${
                    statistics.data.trainees.active.currentMonth - statistics.data.trainees.active.lastmonth > 0 ? 'bg-[#0E290E]' : 'bg-[#331F1E]'
                  }`}
                >
                  <img
                    src={
                      statistics.data.trainees.active.currentMonth - statistics.data.trainees.active.lastmonth > 0
                        ? Images.IncreasedIcon
                        : Images.DecreasedIcon
                    }
                    className="w-[9px]"
                    alt="increased"
                  />
                  <h1 className="text-base leading-5 text-white font-medium">
                    {Math.abs(statistics.data.trainees.active.currentMonth - statistics.data.trainees.active.lastmonth)}
                  </h1>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center bg-playCard rounded-xl p-4 space-x-2 rtl:space-x-reverse text-white w-1/4 h-[164px]">
              <div className="flex flex-col items-center justify-center space-y-2 text-xs sm:text-md gap-4">
                <h1 className="text-center text-textLightGray text-base leading-5 font-normal">{t('TOTAL_WORKOUTS_IN_MONTH')}</h1>
                <h1 className="text-[32px] font-bold">{statistics.data.workouts.currentMonth}</h1>
                <div
                  className={`px-1 rounded py-[2px] space-x-[2px] rtl:space-x-reverse flex justify-around items-center w-[47px] h-[24px] leading-9 text-textLightGray ${
                    statistics.data.workouts.currentMonth - statistics.data.workouts.lastmonth > 0 ? 'bg-[#0E290E]' : 'bg-[#331F1E]'
                  }`}
                >
                  <img
                    src={statistics.data.workouts.currentMonth - statistics.data.workouts.lastmonth > 0 ? Images.IncreasedIcon : Images.DecreasedIcon}
                    className="w-[9px]"
                    alt="increased"
                  />
                  <h1 className="text-base leading-5 text-white font-medium">
                    {Math.abs(statistics.data.workouts.currentMonth - statistics.data.workouts.lastmonth)}
                  </h1>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center bg-playCard rounded-xl p-4 space-x-2 rtl:space-x-reverse text-white w-1/4 h-[164px]">
              <div className="flex flex-col items-center justify-center space-y-2 text-xs sm:text-md gap-4">
                <h1 className="text-center text-textLightGray text-base leading-5 font-normal">{t('TOTAL_OPEN_HOURS_IN_MONTH')}</h1>
                <h1 className="text-[32px] font-bold">{`${statistics.data.openhours.availableHours.currentMonth}/${statistics.data.openhours.workingHours}`}</h1>
                <div
                  className={`px-1 rounded py-[2px] space-x-[2px] rtl:space-x-reverse flex justify-around items-center w-[47px] h-[24px] leading-9 text-textLightGray ${
                    statistics.data.openhours.availableHours.currentMonth - statistics.data.openhours.availableHours.lastmonth > 0
                      ? 'bg-[#0E290E]'
                      : 'bg-[#331F1E]'
                  }`}
                >
                  <img
                    src={
                      statistics.data.openhours.availableHours.currentMonth - statistics.data.openhours.availableHours.lastmonth > 0
                        ? Images.IncreasedIcon
                        : Images.DecreasedIcon
                    }
                    className="w-[9px]"
                    alt="increased"
                  />
                  <h1 className="text-base leading-5 text-white font-medium">
                    {Math.abs(statistics.data.openhours.availableHours.currentMonth - statistics.data.openhours.availableHours.lastmonth)}
                  </h1>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center bg-playCard rounded-xl p-4 space-x-2 rtl:space-x-reverse text-white w-1/4 h-[164px]">
              <div className="flex flex-col items-center justify-center space-y-2 text-xs sm:text-md gap-4">
                <h1 className="text-center text-textLightGray text-base leading-5 font-normal">{t('PROGRAM_TO_CREATE')}</h1>
                <h1 className="text-[32px] font-bold">{statistics.data.trainees.inactive}</h1>
              </div>
            </div>
          </div>
        )}
        <div className="flex flex-col md:flex-row space-x-0 md:space-x-3 rtl:space-x-reverse space-y-3 md:space-y-0 h-auto md:h-full w-full">
          {eventsByDay.loading ? (
            <div className="h-full w-full md:w-3/12 bg-playCard rounded-xl p-4 space-y-1 overflow-scroll scroll-hidden">
              <Loader className="" size="w-8 h-8" fill="#003353" />
            </div>
          ) : (
            <div className="h-full w-full md:w-3/12 bg-playCard rounded-xl p-4 space-y-1 overflow-scroll scroll-hidden">
              <div className="flex justify-between items-center">
                <h1 className="text-sm">{`${
                  moment().format('DD-MM-YYYY') === moment(currenteDate).format('DD-MM-YYYY') ? t('TODAY_EVENTS') : t('DAY_EVENTS')
                }`}</h1>
                <div className="px-2 py-1 rounded-md text-xs bg-textFieldColor cursor-pointer" onClick={() => navigate('/admin/adminCalendar')}>
                  {t('FULL_WEEK')}
                </div>
              </div>
              <div className="flex justify-between items-center py-2">
                <h1 className="text-sm">{moment(currenteDate).format('dddd D MMM')}</h1>
                <div className="flex items-center space-x-2 rtl:space-x-reverse">
                  <img src={Images.LeftArrow} className="h-6 cursor-pointer rtl:rotate-180" onClick={goBack} alt="increased" />
                  <img src={Images.RightArrow} className="h-6 cursor-pointer rtl:rotate-180" onClick={goForward} alt="increased" />
                </div>
              </div>
              <div className="py-2">
                {eventsByDay?.data?.map((item, index) => (
                  <div key={index}>
                    <div className="flex items-center flex-row-reverse w-full">
                      <h1 className={`text-xs w-2/12 ${direction === 'ltr' ? 'font-primary-rg text-right' : 'font-primary-he-rg text-left'}`}>
                        {item?.startHour}
                      </h1>
                      <div className="border border-secondaryBackgroundContrast rounded-xl w-10/12" />
                    </div>
                    <div className="flex space-x-2 rtl:space-x-reverse items-center py-2 w-10/12">
                      {item.workouts.map((workout) => (
                        <h1 key={workout.id} className="px-2 py-3 rounded-md text-xs bg-textFieldColor w-full whitespace-nowrap truncate">
                          {workout?.Trainee?.User?.firstName}
                        </h1>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="w-full md:w-9/12 space-y-3 flex flex-col">
            {completedWorkoutsByCoach.loading ? (
              <div className="h-full w-full bg-playCard rounded-xl p-4 space-y-1 overflow-scroll scroll-hidden">
                <Loader className="" size="w-8 h-8" fill="#003353" />
              </div>
            ) : (
              <div className="bg-playCard h-fit rounded-xl p-4 flex-auto">
                <div className="flex justify-between items-center">
                  <div className="space-y-1">
                    <h4 className="text-xs">{t('MONTHLY_STATISTICS')}</h4>
                    <h4 className="font-bold text-sm">{t('COMPLETED_WORKOUTS')}</h4>
                  </div>
                  <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 space-x-0 md:space-x-2 rtl:space-x-reverse">
                    <CoachesDropdown
                      disabled={false}
                      value={completedWorkoutsByCoach.coachesFilter}
                      options={coachesOptions}
                      onChange={onClickCheckbox}
                      t={t}
                      direction={direction}
                    />
                    <Dropdown
                      disabled={false}
                      value={durationFilter}
                      options={durationOptions}
                      onChange={onChangeDurationFilter}
                      t={t}
                      direction={direction}
                    />
                  </div>
                </div>
                <div className="relative h-9">
                  <div className="flex space-x-2 rtl:space-x-reverse items-center mt-6 w-full overflow-x-auto scroll-hidden">
                    {filterCoaches?.map((item) => {
                      return (
                        <div
                          key={item.id}
                          className="rounded-md shadow-md px-3 py-2 flex space-x-2 rtl:space-x-reverse items-center cursor-pointer"
                          style={{
                            backgroundColor: `rgba(${parseInt(item?.color.slice(1, 3), 16)}, ${parseInt(item?.color.slice(3, 5), 16)}, ${parseInt(
                              item?.color.slice(5, 7),
                              16
                            )}, 0.7)`,
                          }}
                        >
                          <ProfileAvatar size={'xsmall'} name={item?.name} image={item?.avatar} />
                          <h5 className="text-xs truncate w-32">{item?.name}</h5>
                        </div>
                      )
                    })}
                  </div>
                  <div
                    className={`absolute top-0 ${direction === 'ltr' ? 'right-0' : 'left-0'} h-full w-10 bg-gradient-to-r from-transparent`}
                    style={{
                      background:
                        direction === 'ltr'
                          ? 'linear-gradient(to left, rgba(50, 50, 50, 0.7), transparent)'
                          : 'linear-gradient(to right, rgba(50, 50, 50, 0.7), transparent)',
                    }}
                  ></div>
                </div>
                <div className="pt-2 text-black">
                  <ReactApexChart options={options} series={filterCoaches} type="area" height={380} />
                </div>
              </div>
            )}
            <div className="flex-none flex flex-col md:flex-row w-full space-x-0 md:space-x-3 rtl:space-x-reverse space-y-3 md:space-y-0">
              {consecutiveCancelledWorkoutsByTrainee.loading ? (
                <div className="md:w-1/2 bg-playCard rounded-xl p-4 flex items-center justify-between w-full space-y-2 shadow animate-pulse">
                  <div className="w-full h-56 bg-gray-400 rounded-lg dark:bg-secondaryBackground"></div>
                </div>
              ) : (
                <div className="w-full md:w-1/2 bg-playCard rounded-xl p-4">
                  <div className="flex justify-between items-center">
                    <div className="px-2 py-1 rounded-md text-xs bg-textFieldColor cursor-pointer">{t('VIEW_ALL')}</div>
                    <h1 className="text-sm">{t('CANCELLED_TRAINEES')}</h1>
                  </div>
                  <div className="pt-6 space-y-4">
                    {consecutiveCancelledWorkoutsByTrainee.data.slice(0, 5).map((item) => (
                      <div
                        key={item.traineeId}
                        className="flex items-center justify-between cursor-pointer"
                        onClick={() => navigate(`/admin/trainees/traineeDetail/${item.traineeId}`)}
                      >
                        <div
                          className={`rounded-md px-2 py-1 space-x-2 rtl:space-x-reverse flex justify-between items-center bg-[#331F1E] text-xs`}
                        >{`${item.counts} sequence`}</div>
                        <h1 className="text-sm">{item.traineeName}</h1>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {completedWorkoutsByTrainee.loading ? (
                <div className="md:w-1/2 bg-playCard rounded-xl p-4 flex items-center justify-between w-full space-y-2 shadow animate-pulse">
                  <div className="w-full h-56 bg-gray-400 rounded-lg dark:bg-secondaryBackground"></div>
                </div>
              ) : (
                <div className="w-full md:w-1/2 bg-playCard rounded-xl p-4">
                  <div className="flex justify-between items-center">
                    <div className="px-2 py-1 rounded-md text-xs bg-textFieldColor cursor-pointer">{t('VIEW_ALL')}</div>
                    <div className="flex items-center space-x-2 rtl:space-x-reverse">
                      <h1 className="text-sm">{t('MONTHLY_BESTS')}</h1>
                      <img src={Images.CupBest} className="h-6 cursor-pointer" alt="increased" />
                    </div>
                  </div>
                  <div className="pt-6 space-y-4">
                    {completedWorkoutsByTrainee.data.slice(0, 5).map((item) => (
                      <div
                        key={item.id}
                        className="flex items-center justify-between cursor-pointer"
                        onClick={() => navigate(`/admin/trainees/traineeDetail/${item.id}`)}
                      >
                        <div
                          className={`rounded-md px-2 py-1 space-x-2 rtl:space-x-reverse flex justify-between items-center bg-[#0E290E] text-xs`}
                        >{`${item.completedWorkouts} workouts`}</div>
                        <h1 className="text-sm">{item.traineeName}</h1>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={openShareModal}
        handleClose={() => {
          setOpenShareModal(false)
        }}
        notCrossIcon={false}
      >
        <div className="flex justify-center items-center h-[420px] w-[420px] flex-col ">
          <QRCode id="QRCode" size={256} style={{ maxWidth: '90%', width: '90%' }} value={shareUrl && shareUrl} viewBox={`0 0 256 256`} />
          <div className="flex  flex-row gap-4 mt-4">
            <CopyToClipboard onCopy={onCopy} text={shareUrl}>
              <PrimaryButton
                text={t('COPY')}
                disabled={false}
                className="w-[177px] h-[44px] rounded-[12px] shadow-2xl"
                bgColor="bg-sidebarhover hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed"
                textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
                wid={15}
                ht={15}
              />
            </CopyToClipboard>
            <PrimaryButton
              text={t('DOWNLOAD')}
              disabled={false}
              onClick={() => {
                onImageCownload()
              }}
              className="w-[177px] h-[44px] rounded-[12px] shadow-2xl"
              bgColor="bg-sidebarhover hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed"
              textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
              wid={15}
              ht={15}
            />
          </div>
        </div>
      </Modal>
    </div>
  )
}
