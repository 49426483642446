import { Menu, Transition } from '@headlessui/react'
import { Images } from 'assets'
import { ProfileAvatar } from 'components/PlayBoard/ProfileAvatar'

import { Fragment } from 'react'

const CoachesDropdown = ({ value, setValue, options, title, disable, t, dir, className }) => {
  return (
    <div className={`flex flex-col h-[56px] rounded-[12px] mb-2 mt-1 ${className ? className : 'w-full'} `}>
      <Menu as="div" className="relative inline-block text-left ">
        <div className=" bg-textFieldColor rounded-[12px]">
          <div
            className={`flex flex-row  justify-start ${
              dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
            } px-2 pt-1  text-textLightGrey text-[11px] `}
          >
            {title}
          </div>
          <Menu.Button
            className={`inline-flex w-full text-[12px] text-center items-center rounded-[12px] ${
              !disable ? 'bg-textFieldColor' : 'bg-textFieldColor'
            } px-2 py-2  text-textLightGrey ${dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
            disabled={disable}
          >
            {value && t(value)}

            <img src={Images.DROPDOWNICON} alt="" height={20} width={20} className={`${dir === 'ltr' ? 'float-right ml-auto' : 'mr-auto'} `} />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute overflow-hidden w-full z-10 mt-2  origin-top-right divide-y divide-[#374349] rounded-md bg-textFieldColor shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none  ">
            {options?.map((opt) => {
              return (
                <div className="">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          setValue(opt)
                        }}
                        className={`${
                          active ? 'bg-[#444d53] text-textLightGrey' : 'text-textLightGrey'
                        } group flex w-full font-inter-regular items-center   px-2 py-2 text-[12px] ${
                          dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                        } gap-x-2`}
                      >
                        <ProfileAvatar
                          image={opt?.avatar}
                          name={opt?.firstName}
                          color={opt?.Coach?.color === 'BLUE' ? '#E5A772' : opt?.Coach?.color}
                          size={'small'}
                        />
                        {opt?.firstName}
                      </button>
                    )}
                  </Menu.Item>
                </div>
              )
            })}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}

export default CoachesDropdown
