import { createSlice } from '@reduxjs/toolkit'

import { useSelector } from 'react-redux'

const initialState = {
  language: 'he',
  locale: {},
  direction: 'rtl',
}

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, { payload }) => {
      state.language = payload
      if (payload === 'en') {
        state.direction = 'ltr'
      } else {
        state.direction = 'rtl'
      }
    },
    setLocale: (state, { payload }) => {
      state.locale = payload
    },
  },
})

export const useLanguageSelector = () => useSelector((state) => state.language)

export const languageActions = languageSlice.actions
export const languageReducer = languageSlice.reducer
