import { Menu, Transition } from '@headlessui/react'
import { Images } from 'assets'

import { Fragment } from 'react'

const LanguageDropDown = ({ value, setValue, options, title, disable, t, dir, className }) => {
  return (
    <div className={`flex flex-col h-[56px] rounded-[12px] mb-2 mt-1 ${className ? className : 'w-full'} `}>
      <Menu as="div" className="relative inline-block text-left ">
        <div className=" bg-textFieldColor rounded-[12px]">
          <div
            className={`flex flex-row  justify-start ${
              dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
            } px-2 pt-1  text-textLightGrey text-[11px] `}
          >
            {title}
          </div>
          <Menu.Button
            className={`inline-flex w-full text-[12px] text-center items-center rounded-[12px] ${
              !disable ? 'bg-textFieldColor' : 'bg-textFieldColor'
            } px-2 py-2 font-medium text-textLightGrey ${dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
            disabled={disable}
          >
            {value && t(value.name)}

            <img src={Images.DROPDOWNICON} alt="" height={20} width={20} className={`${dir === 'ltr' ? 'float-right ml-auto' : 'mr-auto'} `} />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute w-full z-10 mt-2  origin-top-right divide-y divide-[#374349] rounded-md bg-textFieldColor shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none  max-h-[400px] overflow-auto ">
            {options?.map((opt) => {
              return (
                <div className="px-1 py-1  ">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          setValue(opt)
                        }}
                        className={`${active ? 'bg-borderActive text-textLightGrey' : 'text-textLightGrey'} group flex gap-x-2 w-full ${
                          dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                        } items-center rounded-md px-2 py-2 text-[12px]`}
                      >
                        <img src={opt?.code === 'en' ? Images.EnglishFlag : Images.IsraelFlag} alt="" height={20} width={20} />
                        <div> {t(opt?.name)}</div>
                      </button>
                    )}
                  </Menu.Item>
                </div>
              )
            })}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}

export default LanguageDropDown
