import axiosInstance from 'api'
import { Images } from 'assets'
import CustomToastify from 'components/compounds/Toast'
import { authActions } from './authReducers'
import { languageActions } from 'store/languageSlice'
import i18n from 'i18n'
import { gymAdminActions } from 'store/gymAdminSlice/gymAdminReducers'

export const addGoogleAccount = (callbackUrl) => async (dispatch) => {
  try {
    await axiosInstance
      .post(`googleAuth`, { callbackUrl })
      .then((response) => {
        if (window) {
          window.open(response, '_self')
        }
      })
      .finally(() => {})
  } catch (e) {
    return console.error(e.message)
  }
}

export const emailVerify = (verificationId, direction, t, action) => async (dispatch) => {
  try {
    await axiosInstance
      .post(`emailVerify`, verificationId)
      .then((response) => {
        action && action()
        CustomToastify(t('VERFIFIED_EMAIL'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
      })
      .finally(() => {})
  } catch (error) {
    if (error.code === 502) {
      CustomToastify(t('EMAIL_ALREADY_VERIFIED'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    } else {
      CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    }
  }
}
export const login = (logins, direction, t, action) => async (dispatch) => {
  try {
    dispatch(authActions.setLoader(true))
    await axiosInstance.post('login', logins).then(async (response) => {
      window.localStorage.setItem('access_token', response?.result.token)
      dispatch(authActions.setAccessTokenAction(response.result.token))
      dispatch(authActions.setUserInfoAction(response?.result.rest))
      if (response.result.rest.role !== 'admin') {
        const lang = response.result.rest.Gym.lang

        i18n.changeLanguage(lang)
        document.body.setAttribute('dir', lang === 'en' ? 'ltr' : 'rtl')
        i18n.reloadResources()
        dispatch(languageActions.setLanguage(lang))
        dispatch(authActions.setLanguage(lang))
      }
      CustomToastify(t('LOGIN_SUCCESSFULLY'), '', '', 'bg-toastSuccessbg', direction === 'ltr' ? 'rtl' : 'ltr', Images.toastSucess, true)
      dispatch(authActions.setLoader(false))
      action && action(response?.result.rest)
    })
  } catch (e) {
    dispatch(authActions.setLoader(false))

    CustomToastify(t('ERROR_CONNECTION'), '', t('RERY'), 'bg-toastFaildbg', direction === 'ltr' ? 'rtl' : 'ltr', Images.toastFail, false)
  }
}

export const getGymDetailsById = (id, direction, t, action) => async (dispatch) => {
  try {
    dispatch(gymAdminActions.setGymLoading(true))
    await axiosInstance
      .get(`GymDetailsById/${id}`)
      .then((response) => {
        action && action(response?.result?.gymDetail)
        dispatch(gymAdminActions.setGymLoading(false))
      })
      .finally(() => {
        dispatch(gymAdminActions.setGymLoading(false))
      })
  } catch (error) {
    if (error.code === 502) {
      dispatch(gymAdminActions.setGymLoading(false))
      CustomToastify(t('EMAIL_ALREADY_VERIFIED'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    } else {
      CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    }
  }
}
