import { yupResolver } from "@hookform/resolvers/yup";
import { Images } from "assets";
import { LabelInputFocused } from "components/compounds/LabelInputFocused";
import PrimaryButton from "components/elements/PrimaryButton";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  // addGoogleAccount,
  login,
} from "store/authSlice/authActions";
import { loginSchema } from "validations/formsValidations";

const Login = () => {
  const { t } = useTranslation();
  const { language } = useSelector((state) => state.language);
  const [searchParams] = useSearchParams();
  const { isLoading, user } = useSelector((state) => state.auth);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  React.useMemo(() => {
    const code = searchParams.get("code");

    return { code };
  }, [searchParams]);

  // const onGoogleClick = async () => {
  //   let callbackUrl = 'http://localhost:3000/login'
  //   dispatch(addGoogleAccount(callbackUrl))
  // }

  const methods = useForm({
    mode: "onSubmit",
    resolver: yupResolver(loginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const { control, handleSubmit, formState } = methods;
  const { errors } = formState;
  const { direction } = useSelector((state) => state.language);

  const action = (data) => {
    if (data?.role === "gym_admin") {
      navigate(`/admin/dashboard`);
    } else if (data?.role === "coach") {
      navigate(`/admin/dashboard`);
    } else if (data?.role === "trainee") {
      navigate(`/trainee/dashboard`);
    } else {
      navigate(`/home`);
    }
  };

  useEffect(() => {
    if (user) {
      action(user);
    }
  }, []);
  const onSubmit = (data) => {
    dispatch(login(data, direction, t, action));
  };

  const isLTR = language === "en";
  return (
    <div className="grid grid-cols-1 text-white" dir={isLTR ? "rtl" : "ltr"}>
      <div className="relative">
        <div className="w-[55%] h-screen hidden md:block">
          <img
            src={Images.banner}
            alt="loginBanner"
            className="hidden md:block w-full h-[100vh] object-cover"
          />
        </div>
        <div
          className={`absolute top-0 ${isLTR ? "left-0" : "right-0"} h-full w-full md:w-1/2 `}
        >
          <div
            className={`relative bg-primary flex flex-col items-center md:justify-center min-h-screen space-y-12 ${
              isLTR ? "md:rounded-r-[24px]" : "md:rounded-l-[24px]"
            }`}
          >
            <div className="w-full h-[200px] block   md:hidden">
              <img
                src={Images.banner}
                alt="loginBanner"
                className="block rounded-b-3xl  md:hidden w-full h-[200px] object-cover"
              />
            </div>
            {/* <p className="text-[3.125rem] hidden md:block md:text-[43.5px] font-secondary-bd leading-7 text-[#FFF] tracking-[1.0875px]">
              MY TRAINING APP
            </p> */}
            <div className={`flex flex-col items-center w-full px-4 `}>
              <p
                className={`text-[24px] text-white mb-2 md:my-4 ${direction === "ltr" ? "font-primary-md" : "font-primary-he-md"}`}
              >
                {t("LOGIN_SYSTEM")}
              </p>
              <div className="flex flex-col items-center w-full">
                <div
                  className="flex flex-col w-full md:w-[400px] space-y-3"
                  dir={isLTR ? "ltr" : "rtl"}
                >
                  <div className="space-y-3">
                    <LabelInputFocused
                      name="email"
                      type="text"
                      label={t("MAIL_PLACEHOLDER")}
                      placeholderValue={t("MAIL_PLACEHOLDER")}
                      control={control}
                      required
                      fullWidth
                      error={Object.keys(errors).length > 0 ? true : false}
                      errorMessage={errors?.email?.message}
                      dir={direction}
                    />
                    <LabelInputFocused
                      name="password"
                      type={showPassword ? "text" : "password"}
                      label={t("PASSWORD_PLACEHOLDER")}
                      placeholderValue={t("PASSWORD_PLACEHOLDER")}
                      control={control}
                      required
                      fullWidth
                      dir={direction}
                      icon={showPassword ? Images.Eye : Images.EyeClosed}
                      error={Object.keys(errors).length > 0 ? true : false}
                      errorMessage={errors?.password?.message}
                      setPasswordShown={setShowPassword}
                      passwordShown={showPassword}
                    />
                  </div>
                  <PrimaryButton
                    text={t("LOGIN")}
                    type="submit"
                    disabled={false}
                    isIcon={false}
                    onClick={handleSubmit(onSubmit)}
                    className="w-full h-[56px] rounded-[12px] shadow-2xl"
                    bgColor="bg-prussianBlue hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed"
                    textClass={`text-white ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                    Loader={isLoading}
                  />
                </div>
              </div>
            </div>
            <p className=" login-ios-position text-[24px] block md:hidden  font-secondary-bd leading-7 text-[#FFF] tracking-[1.0875px]">
              MY TRAINING APP
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

// const Card = ({ text, logo, onClick, direction }) => {
//   return (
//     <div
//       className="rounded-[12px] flex flex-row items-center h-[54px]  border-textFieldColor border-[1.5px] px-2 py-4 w-[80%] sm:w-[50%] md:w-[90%] justify-center gap-4"
//       onClick={onClick}
//     >
//       <p className={`text-[14px] text-white ${direction === 'ltr' ? 'font-primary-md' : 'font-primary-he-md'} whitespace-nowrap`}>{text}</p>
//       <img src={logo} height={24} width={24} alt="loginBanner" className="py-2" />
//     </div>
//   )
// }
export default Login;
