import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from 'hooks'
import { Images } from 'assets'
import { SearchInput } from 'components/compounds/SearchInput'
import PrimaryButton from 'components/elements/PrimaryButton'
import { constants } from 'constant'
import { useOutsideClick } from 'hooks'
import { getAllTrainees, getTraineeFilter } from 'store/gymAdminSlice/gymAdminActions'
import { handleFilterSelection } from 'utils'
import { useNavigate } from 'react-router-dom'

export default function TraineesListHeader(props) {
  const { traineefilterOptions } = constants

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { toggelModal } = props
  const { direction } = useSelector((state) => state.language)
  const { customerDetail } = useSelector((state) => state.user)
  const [searchInput, setSearchInput] = useState('')
  const filtersRef = useRef()
  const [filtersMenu, setFiltersMenu] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState('')
  const [activeFilter, setActiveFilter] = useState()
  const [selectedFilterMenu, setSelectedFilterMenu] = useState(false)
  const { traineeFilter } = useSelector((state) => state.gymAdmin)
  const debouncedSearchTerm = useDebounce(searchInput, 500)

  const [temp1, setTemp1] = useState({
    COACHES: [],
    ACTIVE_PROGRAM: [],
    ACTIVE_TRAINEE: [],
  })

  const setSearch = (event) => {
    setSearchInput(event.target.value)
  }
  useEffect(() => {
    const getData = async () => {
      dispatch(getTraineeFilter(customerDetail?.id))
    }
    getData()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const getTrainees = async () => {
      if (temp1.ACTIVE_PROGRAM.length > 0 || temp1.ACTIVE_TRAINEE.length > 0) {
        dispatch(getAllTrainees(customerDetail.id, t, direction, { search: debouncedSearchTerm, filter: temp1 }))
      } else if (debouncedSearchTerm) {
        dispatch(getAllTrainees(customerDetail.id, t, direction, { search: debouncedSearchTerm, filter: temp1 }))
      } else {
        dispatch(getAllTrainees(customerDetail.id, t, direction))
      }
    }
    getTrainees()
    // eslint-disable-next-line
  }, [temp1, debouncedSearchTerm])

  const onClickOutsideFiltersDiv = () => {
    setFiltersMenu(false)
    setSelectedFilterMenu(false)
  }

  useOutsideClick(filtersRef, onClickOutsideFiltersDiv)
  const navigation = useNavigate()

  return (
    <div className="flex w-full flex-col">
      <div className="flex flex-row w-full px-8 py-5 justify-between items-center ">
        <div className="flex gap-1 text-white">
          <p
            className={`text-white text-[14px] cursor-pointer  hover:border-white hover:border-b-2 ${
              direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
            } `}
            onClick={() => {
              navigation('/admin/dashboard')
            }}
          >
            {' '}
            {t('MAIN')}
          </p>
          <p>/</p>{' '}
          <p className={`text-breadCrumb ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} font-extrabold text-[14px] `}>
            {t('TRAINEES')}
          </p>
        </div>

        <PrimaryButton
          text={t('ADD_A_TRAINEES')}
          type="submit"
          disabled={false}
          isIcon={true}
          icon={Images.Plus}
          onClick={() => {
            toggelModal()
          }}
          className="w-[177px] h-[44px] rounded-[12px] shadow-2xl "
          bgColor="bg-sidebarhover hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
          textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
          wid={15}
          ht={15}
        />
      </div>
      <div className="flex flex-row w-full px-8 py-2 justify-between items-center">
        <div className={`text-white ${direction === 'ltr' ? 'font-primary-bd' : 'font-primary-he-bd'} text-[24px]`}>{t('TRAINEES')}</div>
      </div>
      <div className="flex flex-row w-full px-8 py-2 gap-4 items-center">
        <SearchInput
          radius={'rounded-[50px]'}
          placeholder={t('SEARCH')}
          className="flex flex-1"
          disableUnderline
          fullWidth
          value={searchInput}
          inputProps={{
            'aria-label': 'Search',
          }}
          onChange={(ev) => setSearch(ev)}
          icon={Images.Search}
        />
        <div ref={filtersRef} className="relative box-border">
          <div
            className={`flex flex-row w-[156px] h-[40px] items-center rounded-[12px] bg-primary
            border-borderActive border-[1.5px]`}
            onClick={() => {
              setFiltersMenu(!filtersMenu)
              setSelectedFilterMenu(false)
            }}
          >
            <img src={Images.Filter} alt="" height={40} width={45} className={`p-3 items-center `} onClick={() => {}} />
            <p className={`text-white text-center text-[14px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>
              {t('ADDING_FILTER')}
            </p>
          </div>
          {filtersMenu ? (
            <div
              className={`absolute top-11 py-3 bg-statusBg rounded-[12px] w-[156px] max-h-[200px] overflow-auto shadow-xl ${
                filtersMenu ? 'border-borderActive border-[2px]' : ''
              }`}
            >
              {traineefilterOptions.map((opt, i) => (
                <p
                  key={i}
                  className={`px-2 py-1 text-textGrey text-[12px] ${
                    direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                  } hover:bg-checkoutHover cursor-pointer`}
                  onClick={() => {
                    if (opt === 'COACHES') {
                      setActiveFilter(traineeFilter?.COACHES)
                    } else if (opt === 'ACTIVE_PROGRAM') {
                      setActiveFilter(traineeFilter?.ACTIVE_PROGRAM)
                    } else if (opt === 'ACTIVE_TRAINEE') {
                      setActiveFilter(traineeFilter?.ACTIVE_TRAINEE)
                    }
                    setSelectedFilter(opt)
                    setFiltersMenu(false)
                    setSelectedFilterMenu(true)
                  }}
                >
                  {t(opt)}
                </p>
              ))}
            </div>
          ) : null}

          {/* Main Selected Filter */}
          {selectedFilterMenu ? (
            <div
              className={`absolute top-11 py-3 bg-statusBg rounded-[12px] w-[156px] max-h-[200px] overflow-auto shadow-xl ${
                selectedFilterMenu ? 'border-borderActive border-[2px]' : ''
              }`}
            >
              <div
                className="flex flex-row hover:bg-checkoutHover cursor-pointer"
                onClick={() => {
                  setSelectedFilterMenu(false)
                  setFiltersMenu(true)
                }}
              >
                <img src={direction === 'rtl' ? Images.backArrow : Images.backLessArrow} alt="" height={8} width={25} className={'p-1'} dir="ltr" />
                <p className={` py-1 text-textGrey text-[12px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} `}>
                  {t(selectedFilter)}
                </p>
              </div>
              {activeFilter.map((opt, i) => (
                <div
                  key={i}
                  className={`flex flex-row px-2 py-1  justify-between ${
                    direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                  } hover:bg-checkoutHover cursor-pointer`}
                  dir={direction}
                  onClick={() => {
                    handleFilterSelection(selectedFilter, opt, temp1, setTemp1)
                  }}
                >
                  <p
                    className={`text-[12px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} ${
                      temp1.COACHES.includes(opt.label) || temp1.ACTIVE_PROGRAM.includes(opt.label) || temp1.ACTIVE_TRAINEE.includes(opt.label)
                        ? 'text-activeText'
                        : 'text-textGrey'
                    } `}
                  >
                    {t(opt.label)}
                  </p>
                  <p
                    className={`text-[12px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} ${
                      temp1.COACHES.includes(opt.label) || temp1.ACTIVE_PROGRAM.includes(opt.label) || temp1.ACTIVE_TRAINEE.includes(opt.label)
                        ? 'text-activeText'
                        : 'text-textGrey'
                    } `}
                  >
                    {t(opt.count)}
                  </p>
                </div>
              ))}
            </div>
          ) : null}
        </div>
        {Object.keys(temp1).map((value, index) => {
          return (
            temp1[value] &&
            temp1[value].length > 0 &&
            temp1[value].map((item) => {
              return (
                <div className="flex flex-row items-center  gap-1 bg-statusBg px-3 py-1 rounded-[16px] hover:border-borderActive hover:border-[2px]">
                  <p className={`text-[14px] text-textGrey  ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>{t(item)}</p>
                  <img
                    src={Images.CloseCircle}
                    alt=""
                    height={10}
                    width={15}
                    className={`ml-1 mr-2 cursor-pointer lg:mr-0 lg:ml-0 xl:mr-0 xl:ml-0 `}
                    onClick={() => {
                      if (index === 0) {
                        const updatedItems = temp1['COACHES'].filter((i) => i !== item)
                        setTemp1({
                          ...temp1,
                          COACHES: updatedItems,
                        })
                      } else if (index === 1) {
                        const updatedItems = temp1['ACTIVE_PROGRAM'].filter((i) => i !== item)
                        setTemp1({
                          ...temp1,
                          ACTIVE_PROGRAM: updatedItems,
                        })
                      } else if (index === 2) {
                        const updatedItems = temp1['ACTIVE_TRAINEE'].filter((i) => i !== item)
                        setTemp1({
                          ...temp1,
                          ACTIVE_TRAINEE: updatedItems,
                        })
                      }
                    }}
                  />
                </div>
              )
            })
          )
        })}
      </div>
    </div>
  )
}
