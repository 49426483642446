import axiosInstance from 'api'
import { eventActions } from './eventReducers'
import CustomToastify from 'components/compounds/Toast'
import { Images } from 'assets'

export const getGymEvents = async (gymId, coachId, searchText) => {
  const response = await axiosInstance.post(`getGymEvents/${gymId}`, {
    coach: coachId,
    searchText,
  })

  return response.result.gymEvents
}

export const updateEvent = (payload, t, direction, action) => async (dispatch) => {
  try {
    dispatch(eventActions.setLoader(true))
    await axiosInstance
      .put(`event`, payload)
      .then((response) => {
        action && action()
      })
      .finally(() => {
        dispatch(eventActions.setLoader(false))
      })
  } catch (e) {
    dispatch(eventActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}

export const deleteEvent = (eventId, t, direction, action) => async (dispatch) => {
  try {
    dispatch(eventActions.setLoader(true))
    await axiosInstance
      .delete(`event/${eventId}`)
      .then((response) => {
        action && action()
      })
      .finally(() => {
        dispatch(eventActions.setLoader(false))
      })
  } catch (e) {
    dispatch(eventActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}

export const cancelEvent = (eventId, t, direction, action) => async (dispatch) => {
  try {
    dispatch(eventActions.setLoader(true))
    await axiosInstance
      .get(`cancelEvent/${eventId}`)
      .then((response) => {
        action && action()
      })
      .finally(() => {
        dispatch(eventActions.setLoader(false))
      })
  } catch (e) {
    dispatch(eventActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}
