const constants = {
  APP_LANGUAGES_LIST: [
    {
      name: 'English',
      nameInEnglish: 'English',
      code: 'en',
      codes: ['en', 'en_US', 'en-US'],
      direction: 'ltr',
      flag: '🇺🇸',
    },
    {
      name: 'Hebrew',
      nameInEnglish: 'Hebrew',
      code: 'he',
      codes: ['he'],
      direction: 'rtl',
      flag: '🇮🇱',
    },
  ],
  LANGUAGES: {
    HEBREW: 'he',
    ENGLISH: 'en',
  },
  COLORS: [{ id: 1, value: 'BLUE', color: '#00639B' }],
  GENDER: [
    { id: 1, value: 'male' },
    { id: 2, value: 'female' },
    { id: 3, value: 'other' },
  ],
  filterOptions: ['ACTIVE', 'REGISTERATION_STATUS'],
  ACTIVE: ['Yes', 'No'],
  REGISTERATION_STATUS: ['draft', 'trail', 'monthlypayment', 'suspended'],
  ACTIVITY_LEVEL: ['Beginner', 'Medium', 'Advanced'],
  traineefilterOptions: ['ACTIVE_PROGRAM', 'ACTIVE_TRAINEE'],
  PROGRAMFILTEROPTIONS: ['MUSCLES', 'RESISTANCE', 'TRACKING', 'MACHINE', 'MAIN_EXERCISE', 'TAGS'],
  COACHES: [],
  ACTIVE_PROGRAM: ['No Program', 'Has Program'],
  ACTIVE_TRAINEE: ['Active', 'InActive'],
  MUSCLE_TYPE: ['Primary', 'Assistent'],
  TYPE_OF_RESISTANCE: ['plate', 'kg', 'km', 'c', 'level'],
  RESISTANCE: ['machine', 'weight', 'distance', 'incline', 'rubberband'],
  TRACKING_TYPE: ['repeat', 'time'],
  DIFFICULTY_LEVEL: ['low', 'medium', 'hard'],
  SINGLE_BODY_PART: ['Yes', 'No'],
  exercisefilterOptions: ['MUSCLES', 'RESISTANCE', 'TRACKING', 'MACHINE', 'MAIN_EXERCISE', 'TAGS'],
  CALENDAR_FILTER: ['day', 'week', 'month'],
  REPEAT_OPTIONS: [
    { label: 'No Repetitions', value: 'noRepeat' },
    { label: 'Every week', value: 'everyWeek' },
    { label: 'Every month', value: 'everyMonth' },
    { label: 'All day', value: 'allDay' },
  ],
  WEEK_DAYS: [
    { day: 0, label: 'S', value: 'su', label_he: 'א’' },
    { day: 1, label: 'M', value: 'mo', label_he: 'ב’' },
    { day: 2, label: 'T', value: 'tu', label_he: 'ג’' },
    { day: 3, label: 'W', value: 'we', label_he: 'ד’' },
    { day: 4, label: 'T', value: 'th', label_he: 'ה’' },
    { day: 5, label: 'F', value: 'fr', label_he: 'ו’' },
    { day: 6, label: 'S', value: 'sa', label_he: 'ש’' },
  ],
  COACH_OPTIONS: [
    { label: 'Coach 1', value: 'coach1' },
    { label: 'Coach 2', value: 'coach2' },
  ],
  // create array of object with time from 06:00 to 21:00 with 15 min interval
  TIME_OPTIONS: Array.from({ length: 61 }, (_, i) => `${Math.floor(i / 4) + 6}:${(i % 4) * 15 || '00'}`.padStart(5, '0')).map((time) => ({
    value: time,
    label: time,
  })),
  HOURLY_TIME_OPTIONS: Array.from({ length: 16 }, (_, i) => `${i + 6}:00`.padStart(5, '0')).map((time) => ({
    value: time,
    label: time,
  })),
  WORKOUT_TYPE_OPTIONS: [
    { label: 'Full Body', value: 'FullBody' },
    { label: 'Strength', value: 'Strength' },
    { label: 'Cardio', value: 'Cardio' },
  ],
  TRAINEE_OPTIONS: [
    { label: 'Trainee 1', value: 'trainee1' },
    { label: 'Trainee 2', value: 'trainee2' },
    { label: 'Trainee 3', value: 'trainee3' },
    { label: 'Trainee 4', value: 'trainee4' },
  ],
  WORKOUT_REPETITION: [
    { label: 'Every Week', value: 'Week' },
    { label: 'Every Month', value: 'Month' },
  ],

  WORKOUT_TYPE: ['personal', 'online', 'openGym'],
  TOTAL_WORKOUT_DEFAULT: 10,
  MAX_WORKOUT_VALUE: 50,
  RUBBER_BAND: 'rubberband',
  STANDARD_TIME: [30, 45, 60, 75, 90],
  FRONT_MUSCLE_IDS: [9, 11, 1, 3, 14, 5, 12, 4, 13, 10, 8, 7, 2, 6],
  TOOLS: [
    'body-weight',
    'kettlebell',
    'dumbbell',
    'rod',
    'rodW',
    'cables',
    'rope',
    'triangular',
    'handles',
    'barbel',
    'straps',
    'reformer-ball',
    'trx',
    'stability-ball',
    'jump-rope',
    'bosu-ball',
    'box',
    'sofa',
    'step',
    'mats',
    'bar',
    'w-bar',
    't-bar',
    'trap-bar',
    'open-trap-bar',
    'roman-chair',
    'pritcher',
  ],
  POSITION: ['standing', 'supine', 'seating'],
  INCLINE: ['none', 'positive', 'negative'],
  CATEGORY: ['strength', 'aerobic', 'flexibility', 'core', 'balance', 'plyometric', 'body-weight', 'functional', 'mobility'],
  COLOR_MAP: [
    { bgColor: '#E27068', textColor: '#FFFFFF' },
    { bgColor: '#E5A772', textColor: '#333333' },
    { bgColor: '#70ACBF', textColor: '#333333' },
    { bgColor: '#9E9DDD', textColor: '#333333' },
    { bgColor: '#1F547D', textColor: '#FFFFFF' },
    { bgColor: '#5E616D', textColor: '#FFFFFF' },
    { bgColor: '#B4CA9B', textColor: '#333333' },
    { bgColor: '#FFFCEC', textColor: '#333333' },
    { bgColor: '#EAEAEA', textColor: '#333333' },
    { bgColor: '#353A40', textColor: '#FFFFFF' },
  ],
  WORKOUT_STATUSES: [
    { status: 'draft', bgColor: '#5E616D', textColor: '#FFFFFF' },
    { status: 'active', bgColor: '#E5A772', textColor: '#333333' },
    { status: 'open', bgColor: '#70ACBF', textColor: '#333333' },
    { status: 'completed', bgColor: '#B4CA9B', textColor: '#333333' },
    { status: 'cancelled', bgColor: '#E27068', textColor: '#FFFFFF' },
    { status: 'closed', bgColor: '#5E616D', textColor: '#FFFFFF' },
  ],
  PROGRAM_STATUSES: [
    { status: 'draft', bgColor: '#5E616D', textColor: '#FFFFFF' },
    { status: 'active', bgColor: '#70ACBF', textColor: '#333333' },
    { status: 'open', bgColor: '#5E616D', textColor: '#FFFFFF' },
    { status: 'completed', bgColor: '#B4CA9B', textColor: '#333333' },
    { status: 'cancelled', bgColor: '#E27068', textColor: '#FFFFFF' },
    { status: 'closed', bgColor: '#5E616D', textColor: '#FFFFFF' },
  ],
  templateOptions: ['MUSCLES', 'COACHES'],
  IMPROVEMENT_CHIPS: [
    { status: 'Stable', bgColor: '#2e3035', textColor: '#56ABD6' },
    { status: 'Improvment', bgColor: '#223530', textColor: '#53C22B' },
    { status: 'SlowDown', bgColor: '#313236', textColor: '#F54B49' },
    { status: 'Cancelled', bgColor: '#3e4c52', textColor: '#FFFFFF' },
    { status: 'FirstTime', bgColor: '#56ABD6', textColor: '#333333' },
  ],
}

export default constants
