import React, { useEffect, useState } from 'react'
import ProgramListHeader from './ProgramListHeader'

import ExerciseSection from './ExerciseSection'
import ProgramSection from './ProgramSection'
import ProgramWorkout from './ProgramWorkout'
import WorkoutTimeline from './WorkoutTimeline'
import { constants } from 'constant'
import CustomToastify from 'components/compounds/Toast'
import { Images } from 'assets'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { setProgram, editProgram } from 'store/programSlice/programActions'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { getProgramById } from 'store/programSlice/programActions'
import { createTemplate } from 'store/templateSlice/templateActions'
export default function CreateProgram() {
  const [searchText, setSearchText] = useState()
  const [programName, setProgramName] = useState('')
  const [isLoadingExercise, setLoadingExercise] = useState(true)
  const [stateData, setStateData] = useState(null)
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const location = useLocation()
  const { direction } = useSelector((state) => state.language)
  const { customerDetail } = useSelector((state) => state.user)

  const [workoutlist, setWorkoutlist] = useState([{ id: 0, workoutName: 'Workout-1', isOpen: true, exercises: [], isTemplateCreated: false }])
  const { currentTrainee } = useSelector((state) => state.gymAdmin)

  const [totalWorkouts, setTotalWorkouts] = useState(constants.TOTAL_WORKOUT_DEFAULT)
  const [workoutContinuety, setWorkoutContinuty] = useState(constants.WORKOUT_REPETITION[0])
  const [modifiedStateInitialized, setModifiedStateInitialized] = useState(false)
  const { programs } = useSelector((state) => state.trainee)
  const [selectedDate, setSelectedDate] = useState(null)
  const [showDatePicker, setShowDatePicker] = useState(false)

  const [modifiedState, setModifiedState] = useState(() => {
    if (modifiedStateInitialized && Object.keys(modifiedState).length > 0) {
      return modifiedState
    } else {
      return {}
    }
  })
  const [isActive, setActive] = useState(0)
  // eslint-disable-next-line
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (Object.keys(modifiedState).length > 0) {
      setModifiedStateInitialized(true)
    }
  }, [modifiedState])

  useEffect(() => {
    const programId = searchParams?.get('programId')

    if (location?.state) {
      setStateData(location?.state)
    } else {
      setStateData(Object.fromEntries(searchParams))
    }

    if (programId) {
      dispatch(getProgramById(programId, setWorkoutlist, t, direction, setModifiedState, setProgramName))
    }
    // eslint-disable-next-line
  }, [searchParams, location])
  useEffect(() => {
    if (programName != null) {
      if (programs.length > 0) {
        let index = programs[programs.length - 1].traineeProgramIndex
        const programId = searchParams?.get('programId')
        if (programId && modifiedState && programName != null) {
        } else {
          setProgramName(`Program-${index + 1}`)
        }
      } else {
        setProgramName('Program-1')
      }
    }
  
    // eslint-disable-next-line
  }, [modifiedState])

  useEffect(()=>{
    const totalwokout = searchParams?.get('totalworkout')
    if (totalwokout) {
      setTotalWorkouts(totalwokout)
    }
  },[])
  const [stage2, setStage2] = useState(false)

  const handleDragOver = (event) => {
    event.preventDefault()
  }
  const action = () => {
    navigate(`/admin/trainees/traineeDetail/${id}`, { search: `?program=true`, state: { active: stateData?.active } })
  }
  const handleSaveProgram = () => {
    let isValid = true

    for (const key in modifiedState) {
      const workouts = modifiedState[key]

      for (const workout of workouts) {
        if (workout.type !== 'online' && workout.type !== 'openGym' && (workout.dayAndTime.day === null || workout.dayAndTime.time === null)) {
          isValid = false
          break
        }
      }

      if (!isValid) {
        break
      }
    }

    if (!isValid) {
      CustomToastify(t('TIME_SLOT_MISSING'), '', '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return
    }
    if (Object.values(modifiedState).reduce((count, workoutList) => count + workoutList.length, 0) <= totalWorkouts) {
      const workoutData = Object.entries(modifiedState).reduce((acc, [workoutType, workouts]) => {
        const formattedWorkouts = workouts.map((workout) => ({
          CoachId: workout.CoachId,
          type: workout.type,
          duration: workout.duration,
          dayAndTime: {
            day: workout.dayAndTime.day,
            time: workout.dayAndTime.time != null ? workout.dayAndTime.time : '00:00:00',
          },
          Exercises: workout.Exercises.map((exercise) => ({
            ExerciseId: exercise?.ExerciseId,
            Status: exercise.Status,
            Resistance_value: exercise.Resistance_value,
            Tracking_value: exercise.Tracking_value,
            isband: exercise.isband,
            set: exercise.set,
            Sets: exercise.sets,
            intensityTracking: exercise?.intensityTracking,
          })),
        }))

        acc[workoutType] = formattedWorkouts
        return acc
      }, {})

      const data = {
        Program: {
          startDate: selectedDate,
          progamName: programName,
          TraineeId: currentTrainee?.id,

          totalWorkout: totalWorkouts,
          every: 'week',
          TraineeName: currentTrainee?.User?.firstName,
          gymId: currentTrainee?.User?.gym_Id,
          WorkoutDetial: {
            totalworkout: totalWorkouts,
            workouts: workoutData,
          },
        },
      }

      const programId = searchParams?.get('programId')
      const programStatus = searchParams?.get('status')
      if (programId) {
        data.Program.id = programId
        data.Program.Status = programStatus

        dispatch(editProgram(data, t, direction, action))
      } else {
        data.Program.Status = 'Draft'

        dispatch(setProgram(data, t, direction, action))
      }
      //here we will do process
    } else {
      CustomToastify(t('TOTAL_WORKOUT_ISSUE'), '', '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return
    }
  }

  const templateAction = (id) => {
    const updatedWorkoutList = workoutlist.map((workout) => {
      if (workout.id === id) {
        return {
          ...workout,
          isTemplateCreated: true,
        }
      }
      return workout
    })
    setWorkoutlist(updatedWorkoutList)
  }
  const handleSaveAsTemplate = (workoutId) => {
    const isPropertyEmpty = (value) => value === undefined || value === ''

    // Function to check if any exercises have empty properties
    const hasEmptyProperties = (exercise) => {
      return isPropertyEmpty(exercise.set) || isPropertyEmpty(exercise.Resistance_value) || isPropertyEmpty(exercise.Tracking_value)
    }
    let flag = false
    // Iterate through workout data
    for (const workout of workoutlist) {
      for (const exercise of workout.exercises) {
        if (hasEmptyProperties(exercise)) {
          flag = true
          break

          // You can customize the error message or take further actions here
        }
      }
    }
    if (flag) {
      CustomToastify(t('EMPTY_FIELD'), '', '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return
    }
    const workout = workoutlist.find((item) => item.id === workoutId)
    const exerciseId = workout?.exercises.map((item) => item?.id)
    const exerciseRecord = workout?.exercises?.map((item) => {
      return { id: item?.id, Resistance_value: item?.Resistance_value, Tracking_value: item?.Tracking_value, sets: item?.set }
    })

    const data = {
      gymId: customerDetail?.id,
      createdBy: customerDetail?.User?.id,
      name: workout?.workoutName,
      exerciseRecord: exerciseRecord,
      exerciseId: exerciseId,
    }
    dispatch(createTemplate(data, t, direction, customerDetail?.id, templateAction, currentTrainee?.id, workoutId))
  }

  return (
    <div className="h-full overflow-hidden">
      <>
        <ProgramListHeader setSearchText={setSearchText} searchText={searchText} stage2={stage2} stateData={stateData} traineeId={id} />

        {stage2 === false ? (
          <div className="flex w-full flex-row gap-x-4  px-8 h-[73%] xxl:h-[80%]">
            <ExerciseSection
              dragOver={handleDragOver}
              workoutlist={workoutlist}
              setWorkoutlist={setWorkoutlist}
              isActive={isActive}
              setActive={setActive}
              setLoadingExercise={setLoadingExercise}
            />
            <ProgramSection
              dragOver={handleDragOver}
              programName={programName}
              setProgramName={setProgramName}
              workoutlist={workoutlist}
              setWorkoutlist={setWorkoutlist}
              stage2={stage2}
              setStage2={setStage2}
              modifiedState={modifiedState}
              setModifiedState={setModifiedState}
              isActive={isActive}
              setActive={setActive}
              saveTemplate={handleSaveAsTemplate}
              isLoadingExercise={isLoadingExercise}
              setLoadingExercise={setLoadingExercise}
            />
          </div>
        ) : (
          <div className="flex  w-full mt-5 flex-row gap-x-4 px-8 h-[75%] xxl:h-[80%]">
            <ProgramWorkout
              workoutlist={workoutlist}
              setStage2={setStage2}
              totalWorkouts={totalWorkouts}
              setTotalWorkouts={setTotalWorkouts}
              workoutContinuety={workoutContinuety}
              setWorkoutContinuty={setWorkoutContinuty}
              programName={programName}
              modifiedState={modifiedState}
              setModifiedState={setModifiedState}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              showDatePicker={showDatePicker}
              setShowDatePicker={setShowDatePicker}
            />
            <WorkoutTimeline
              setStage2={setStage2}
              stage2={stage2}
              modifiedState={modifiedState}
              setModifiedState={setModifiedState}
              handleSubmit={handleSaveProgram}
            />
          </div>
        )}
      </>
    </div>
  )
}
