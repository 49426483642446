import { yupResolver } from '@hookform/resolvers/yup'
import { Images } from 'assets'
import BodyMap from 'components/bodyMap'
import { SearchLabelInput } from 'components/compounds/SearchLabelInput'
import CustomToastify from 'components/compounds/Toast'
import CreateMuscle from 'components/mucleLibrary/CreateMuscle'
import { useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { muscleSchema } from 'validations/formsValidations'
import MuscleSelectionDropDown from './MuscleSelectionDropDown'

export default function BasicExercise({
  disable,
  setSelectedMuscle,
  selectedMuscle,
  setExerciseName,
  exerciseName,
  setIsCopy,
  isCopy,
  setExerciseID,
}) {
  const { t } = useTranslation()
  const { direction } = useSelector((state) => state.language)
  const { exercisesName } = useSelector((state) => state.exercise)

  const [mucleID, setMucleID] = useState(null)

  const { muscles } = useSelector((state) => state.muscle)

  const [showCreateMuscle, setShowCreateMuscle] = useState(false)

  const handleMuscleSelect = (muscle) => {
    setSelectedMuscle([...selectedMuscle, muscle])
  }
  const handleRemove = (id) => {
    let temp = [...selectedMuscle]

    let data = temp.filter((x, i) => i !== id)
    setSelectedMuscle(data)
  }
  const selectedMuscleIds = useMemo(() => {
    return selectedMuscle?.map((x) => x.bodyPartId)
  }, [selectedMuscle])

  const methods = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(muscleSchema),
    defaultValues: {
      muscleName: '',
    },
  })

  const handleSelectedMuscle = (id) => {
    if (selectedMuscleIds.includes(id)) {
      CustomToastify(t('MUSCLE_ALREADY_ADD'), '', '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    } else {
      // eslint-disable-next-line
      const foundObject = muscles?.find((obj) => obj?.muscle_model_id == id)

      if (foundObject) {
        setSelectedMuscle((prevSelectedMuscle) =>
          prevSelectedMuscle.map((m, i) => {
            // eslint-disable-next-line
            return i == prevSelectedMuscle.length - 1 ? { id: foundObject?.id, name: foundObject?.muscleName, type: m.type, bodyPartId: +id } : m
          })
        )
      }
    }
  }

  return (
    <div className="w-full">
      <SearchLabelInput
        name="exerciseName"
        type="text"
        label={t('EXERCISE_NAME')}
        placeholderValue={t('EXERCISE_NAME')}
        required
        fullWidth
        dir={direction}
        disabled={disable}
        isRequired={true}
        value={exerciseName}
        setValue={setExerciseName}
        options={exercisesName}
        setIsCopy={setIsCopy}
        isCopy={isCopy}
        setExerciseID={setExerciseID}
        limit={55}
      />
      {selectedMuscle &&
        selectedMuscle?.map((muscle, index) => {
          return (
            <MuscleSelectionDropDown
              id={index}
              data={selectedMuscle}
              options={muscles}
              className="my-2"
              title={t('MUSCLE')}
              value={muscle}
              setValue={(data) => {
                if (!data[0]?.new) {
                  setSelectedMuscle(data)
                } else {
                  setShowCreateMuscle(true)
                }
              }}
              disable={disable}
              t={t}
              dir={direction}
              handleRemove={handleRemove}
            />
          )
        })}
      {selectedMuscle && selectedMuscle?.length < 4 && selectedMuscle[selectedMuscle.length - 1].id != null && (
        <div
          className="flex flex-row w-48 px-2 cursor-pointer"
          onClick={() => {
            handleMuscleSelect({ id: null, name: null, type: 'Primary' })
          }}
        >
          <p className={`text-[16px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-white`}>{t('ADD_MUSCLES')}</p>
          <div className="py-2 px-2">
            <img src={Images.Plus} alt="" height={10} width={10} />
          </div>
        </div>
      )}
      <div className="my-5">
        <BodyMap
          selectedValue={selectedMuscleIds}
          hoverEnabled={!disable}
          fillColor="#65B1F4"
          setSelectedValue={handleSelectedMuscle}
          isCustomHandler={true}
          w={450}
          h={500}
        />
      </div>

      {showCreateMuscle && (
        <CreateMuscle
          show={showCreateMuscle}
          setShow={setShowCreateMuscle}
          Controller={Controller}
          methods={methods}
          muscleID={mucleID}
          setMucleID={setMucleID}
        />
      )}
    </div>
  )
}
