import moment from 'moment'
import React from 'react' // { useEffect, useState }

const TimeClock = () => {
  return (
    <h3 className="text-[#C2C7CF] text-md ltr:font-primary-rg rtl:font-primary-he-rg text-xl xl:text-2xl not-italic font-medium leading-7 ">{`${moment().format(
      'dddd, DD MMM . hh:mm a'
    )}`}</h3>
  )
}

export { TimeClock }
