import styled from 'styled-components'

export const AllBodyMapContainer = styled.div`
  position: relative;
  border-radius: 10px;

  display: flex;
`

export const FrontBackContainer = styled.div`
  position: relative;
  perspective: 1000px;
  transition: transform 0.6s;
  transform-style: preserve-3d;

  width: ${(props) => (props.$w ? `${props.$w}px` : '200px')};
  height: ${(props) => (props.$h ? `${props.$h}px` : '200px')};

  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    &:nth-child(2) {
      transform: rotateY(180deg);
    }

    g {
      cursor: pointer;

      &.hoverable-muscle {
        /* path {
          fill: #ffdad6;
        } */
      }

      &.body-map__model,
      &.body-map__model:hover {
        path {
          fill: #000;
        }
      }

      ${(props) =>
        props.$hoverEnabled &&
        `
          &:hover {
            path {
              fill: #65B1F4;
            }
          }
      `}

      ${(props) =>
        Array.isArray(props?.$selectedValue)
          ? props?.$selectedValue.map(
              (value) => `&[id='${value}'] { path {
          fill: #65B1F4;
        } }`
            )
          : `&[id='${props?.$selectedValue}'] { path {
          fill: #65B1F4;
        } }`}
    }
  }
`
