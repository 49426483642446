import { Images } from 'assets'
import CustomToastify from 'components/compounds/Toast'
import OutlineButton from 'components/elements/OutlineButton'
import PrimaryButton from 'components/elements/PrimaryButton'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getTraineeById, getbandByGymId } from 'store/gymAdminSlice/gymAdminActions'
import ProgramSectionHeader from './components/programSectionHeader'
import { ExerciseCommentInput } from '../PlayBoard/components/ExerciseCommentInput'
import { constants } from 'constant'
import { gymAdminActions } from 'store/gymAdminSlice/gymAdminReducers'
import { setProgramAsDraft } from 'store/programSlice/programActions'
import { getLastPerformedExerciseDetail, exerciseComments, addCommentDuringProgram } from 'store/workoutSlice/workoutActions'
import { Modal } from 'components/compounds/Modal'
import { ExerciseDetail } from './components/exerciseDetail'
import { getResistanceLabel } from 'utils'
import { ProfileAvatar } from 'components/PlayBoard/ProfileAvatar'
import { Loader } from 'components/compounds/Loader'
import { formatDateAndTimeForComments } from 'utils'

export default function ProgramSection({
  dragOver,
  programName,
  setProgramName,
  workoutlist,
  setWorkoutlist,
  stage2,
  setStage2,
  modifiedState,
  setModifiedState,
  isActive,
  setActive,
  saveTemplate,
  setLoadingExercise,
  isLoadingExercise,
}) {
  const { id } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { direction } = useSelector((state) => state.language)
  const { currentTrainee, exercisesInfo, bands } = useSelector((state) => state.gymAdmin)
  const { loader } = useSelector((state) => state.program)
  const { customerDetail } = useSelector((state) => state.user)

  const [isDrag, setIsDrag] = useState(false)

  const dispatch = useDispatch()
  const [scrollToId, setScrollToId] = useState(null)
  const [openDetailModal, setOpenDetailModal] = useState(false)
  const [selectedExercise, setSelectedExercise] = useState([])
  const [selectedWorkoutAndExercise, setSelectedWorkoutAndExercise] = useState({
    workoutId: '',
    exerciseId: '',
  })
  const [comment, setComment] = useState('')
  const [commentList, setCommentList] = useState([])
  const [commentsLoading, setCommentsLoading] = useState(false)

  useEffect(() => {
    if (scrollToId || isActive) {
      const element = document.getElementById(`workout-${scrollToId}`)

      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }

      setScrollToId(null)
    }
  }, [scrollToId, isActive])

  useEffect(() => {
    const handleDragEnd = () => {
      // Remove the CSS class from all elements with the 'dragging' class
      const draggingElements = document.querySelectorAll('.dragging')
      draggingElements.forEach((element) => element.classList.remove('dragging'))
    }

    document.addEventListener('dragend', handleDragEnd)

    return () => {
      document.removeEventListener('dragend', handleDragEnd)
    }
  }, [])

  useEffect(() => {
    if (id) {
      dispatch(getTraineeById(id, t, direction))
      dispatch(getbandByGymId(customerDetail?.id, t, direction))
    }
    // eslint-disable-next-line
  }, [id])

  const handleAddWorkout = () => {
    if (workoutlist.length < 7) {
      const lastId = workoutlist[workoutlist.length - 1].id

      let newWorkout = {
        id: workoutlist.length,
        isOpen: true,
        exercises: [],
        isTemplateCreated: false,
      }
      const exectName = workoutlist.find((item) => item?.workoutName === `Workout-${lastId + 2}`)
      if (exectName) {
        newWorkout = {
          ...newWorkout,
          workoutName: `${exectName?.workoutName}-1`,
        }
      } else {
        newWorkout = {
          ...newWorkout,
          workoutName: `Workout-${lastId + 2}`,
        }
      }

      setActive(newWorkout.id)
      setScrollToId(newWorkout.id)

      setWorkoutlist([...workoutlist, newWorkout])
    }
  }
  const handleToggleOpen = (id) => {
    const updatedList = workoutlist.map((workout) => {
      if (workout.id === id) {
        setActive(id)

        setScrollToId(id)
        return { ...workout, isOpen: !workout?.isOpen }
      } else {
        return { ...workout }
      }
    })

    setWorkoutlist(updatedList)
  }

  const checkDropEvent = (event, dropIndexID) => {
    if (isDrag) return

    if (!event.target.id || event.target.id === 'right-container') {
      handleDrop(event, dropIndexID)
      return
    } else {
      const number = event?.target?.id?.match(/\d+/)[0]
      const itemId = event.dataTransfer.getData('text/plain')
      // eslint-disable-next-line
      const draggedItem = exercisesInfo.find((item) => item.id == itemId)
      const findWorkoutlistIndex = workoutlist.findIndex((workout) => workout.id === isActive)

      if (workoutlist[findWorkoutlistIndex]?.exercises?.find((x) => x.id === itemId)?.length > 0) {
        return
      }
      handleDrop(event, dropIndexID, true)

      const updatedWorkoutlist = workoutlist.map((workout) => {
        if (workout.id === findWorkoutlistIndex) {
          const exercises = [...workout.exercises]

          exercises.splice(number, 0, draggedItem) // Insert exercise at destination position

          return { ...workout, exercises }
        }
        return workout
      })

      setWorkoutlist(updatedWorkoutlist)
      return
    }
  }
  //Drop from exercise section to program section
  const handleDrop = async (event, dropIndexID, isResort = false) => {
    event.preventDefault()
    const itemId = event.dataTransfer.getData('text/plain')
    // eslint-disable-next-line
    const draggedItem = exercisesInfo.find((item) => item.id == itemId)

    if (dropIndexID !== isActive) {
      return
    }
    if (draggedItem) {
      const selectedWorkout = workoutlist.find((workout) => workout.id === dropIndexID)

      if (selectedWorkout) {
        const isAlreadyAdded = selectedWorkout.exercises.some((exercise) => exercise.id === draggedItem.id)
        if (!isAlreadyAdded) {
          let updatedDraggedItem
          dispatch(
            gymAdminActions.setWorkoutNameInExercise({
              WorkoutId: selectedWorkout?.id,
              ExerciseId: draggedItem?.id,
              WorkoutName: selectedWorkout?.workoutName,
              Remove: true,
            })
          )
          if (draggedItem?.resistancename === constants.RUBBER_BAND) {
            updatedDraggedItem = {
              exerciseName: draggedItem.exerciseName,

              id: draggedItem.id,
              Resistance_value: bands?.length > 0 ? bands[0]?.bandLevel : null,
              Tracking_value: 10,
              set: 3,
              isband: true,
              boostWeight: false,
              machineName: draggedItem?.machine_name || null,
              resistancetype: draggedItem?.resistancetype,
              resistancename: draggedItem?.resistancename,
              trackingname: draggedItem?.trackingname,
              trackingunit: draggedItem?.trackingunit,
              intensityTracking: false,
              mobility_technique: false,
              muscle_technique: false,
              control_technique: false,
              advanceTechnique: null,
            }
          } else {
            const data = {
              traineeId: currentTrainee?.id,
              exerciseId: draggedItem?.id,
            }
            setLoadingExercise(true)
            const response = await dispatch(getLastPerformedExerciseDetail(data))
            if (response && response?.isLastPerformedExercise) {
              updatedDraggedItem = {
                exerciseName: draggedItem.exerciseName,
                resistancename: draggedItem.resistancename,
                trackingname: draggedItem.trackingname,
                id: draggedItem.id,
                Resistance_value: response.bestSet.resistance_value,
                Tracking_value: response.bestSet.tracking_value,
                set: response.totalSets,
                isband: false,
                bandColor: null,
                Sets: response.Sets,
                intensityTracking: response.intensityTracking,
                mobility_technique: response.mobilityTechnique,
                muscle_technique: response.muscleTechnique,
                control_technique: response.controlTechnique,
                boostWeight: response.Sets[0].isBoost,
                machineName: draggedItem?.machine_name || null,
                resistancetype: response?.Exercise?.resistancetype,
                resistancename: response?.Exercise?.resistancename,
                trackingname: response?.Exercise?.trackingname,
                trackingunit: response?.Exercise?.trackingunit,
                advanceTechnique: response.advanceTechnique,
              }
            } else {
              updatedDraggedItem = {
                exerciseName: draggedItem.exerciseName,
                resistancename: draggedItem.resistancename,
                trackingname: draggedItem.trackingname,
                id: draggedItem.id,
                Resistance_value: 10,
                Tracking_value: 10,
                set: 1,
                Sets: [
                  {
                    index: 0,
                    isBoost: false,
                    resistance_value: 10,
                    tracking_value: 10,
                    intensity_level: -1,
                  },
                ],
                isband: false,
                bandColor: null,

                bandColor: null,
                intensityTracking: false,
                mobility_technique: false,
                muscle_technique: false,
                control_technique: false,
                boostWeight: false,
                machineName: draggedItem?.machine_name || null,
                resistancetype: draggedItem?.resistancetype,
                resistancename: draggedItem?.resistancename,
                trackingname: draggedItem?.trackingname,
                trackingunit: draggedItem?.trackingunit,
                advanceTechnique: null,
              }
            }
            setLoadingExercise(false)
          }

          const updatedExercises = [...selectedWorkout.exercises, updatedDraggedItem]
          const updatedWorkoutlist = workoutlist.map((workout) => {
            if (workout.id === dropIndexID) {
              return { ...workout, exercises: updatedExercises }
            }
            return workout
          })
          if (!isResort) {
            setWorkoutlist(updatedWorkoutlist)
            return
          }
        } else {
          CustomToastify(t('EXERCISED_ADDED'), '', '', 'bg-toastFaildbg', direction, Images.toastFail, false)
        }
      }
    }
  }

  const handleExerciseDragStart = (event, workoutId, exerciseIndex) => {
    setIsDrag(true)
    event.dataTransfer.effectAllowed = 'move'
    event.dataTransfer.setData('text/plain', JSON.stringify({ workoutId, exerciseIndex }))
    event.currentTarget.classList.add('dragging')
  }

  const handleExerciseDragOver = (event, workoutId, exerciseIndex) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }

  const handleExerciseDrop = (event, destinationWorkoutId, destinationIndex) => {
    if (!isDrag) return
    setIsDrag(false)
    event.currentTarget.classList.remove('dragging')
    const source = JSON.parse(event.dataTransfer.getData('text/plain'))
    const { exerciseIndex: sourceIndex } = source
    const updatedWorkoutlist = workoutlist.map((workout) => {
      if (workout.id === destinationWorkoutId) {
        const exercises = [...workout.exercises]
        const sourceExercise = exercises[sourceIndex]

        exercises.splice(sourceIndex, 1) // Remove exercise from source position

        exercises.splice(destinationIndex, 0, sourceExercise) // Insert exercise at destination position

        return { ...workout, exercises }
      }
      return workout
    })

    setWorkoutlist(updatedWorkoutlist)
  }

  const removeExercise = (exerciseID, workoutID) => {
    const updatedWorkoutlist = workoutlist.map((workout) => {
      if (workout.id === workoutID) {
        const updatedExercises = workout.exercises.filter((exercise) => exercise?.id !== exerciseID)
        return { ...workout, exercises: updatedExercises }
      }
      return workout
    })

    setWorkoutlist(updatedWorkoutlist)
  }

  function deepClone(obj) {
    if (obj === null || typeof obj !== 'object') {
      return obj
    }

    const clone = Array.isArray(obj) ? [] : {}

    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        clone[key] = deepClone(obj[key])
      }
    }

    return clone
  }

  const updateWorkoutName = (value, id) => {
    setScrollToId(id)
    if (Object.keys(modifiedState)?.length !== 0) {
      const newCloneModifiedState = deepClone(modifiedState)
      let deepClonedWorklist = [...workoutlist]
      const oldName = deepClonedWorklist.find((item) => item.id === id)?.workoutName
      const item = deepClonedWorklist.find((obj) => obj.workoutName === oldName)
      if (item) {
        deepClonedWorklist = deepClonedWorklist.map((obj) => (obj.id === item.id ? { ...obj, workoutName: value } : { ...obj }))
      }
      setWorkoutlist(deepClonedWorklist)
      setModifiedState(newCloneModifiedState)
    } else {
      setWorkoutlist((prevWorkoutlist) => {
        const updatedWorkoutlist = prevWorkoutlist.map((workout) => {
          if (workout.id === id) {
            return { ...workout, workoutName: value }
          }
          return workout
        })

        return updatedWorkoutlist
      })
    }
  }

  const updateData = (value, workoutId, exerciseId, field) => {
    const updatedWorkoutlist = workoutlist.map((workout) => {
      if (workout.id === workoutId) {
        const updatedExercises = workout.exercises.map((exercise) => {
          if (exercise.id === exerciseId) {
            if (field !== 'set') {
              const sets = exercise?.Sets?.map((set) => {
                return {
                  ...set,
                  [field.toLowerCase()]: value,
                }
              })

              return { ...exercise, Sets: sets, [field]: value }
            } else {
              if (exercise.Sets.length < value) {
                const difference = value - exercise.Sets.length
                const lastSet = exercise.Sets[exercise.Sets.length - 1]
                const duplicatedSets = Array.from({ length: difference }, (_, index) => ({
                  ...lastSet,
                  id: index + 1,
                  index: index + 1, // Assuming you want to start index from 1
                  isBoost: false,
                }))
                const newSets = exercise.Sets.concat(duplicatedSets)
                return { ...exercise, Sets: newSets, [field]: value }
              } else if (exercise.Sets.length > value) {
                const difference = exercise.Sets.length - value

                if (exercise.Sets.length !== difference) {
                  const newSets = exercise.Sets.splice(difference)
                  return { ...exercise, Sets: newSets, [field]: value }
                } else {
                  return { ...exercise, Sets: exercise.Sets, [field]: value }
                }
              } else {
                return { ...exercise, [field]: value }
              }
            }
          }
          return exercise
        })
        return { ...workout, exercises: updatedExercises }
      }
      return workout
    })

    setWorkoutlist(updatedWorkoutlist)
  }
  const handleSelectBandColor = (selectedColor, workoutId, exerciseId) => {
    // Find the workout and exercise based on workout id and exercise id
    const updatedWorkoutList = workoutlist.map((workout) => {
      if (workout.id === workoutId) {
        const updatedExercises = workout.exercises.map((exercise) => {
          if (exercise.id === exerciseId) {
            // Update the bandColor property
            const selectedBand = bands.find((band) => band.machineName === selectedColor)

            const resistanceValue = selectedBand ? selectedBand.bandLevel : null

            // Update the Resistance_value property
            return { ...exercise, Resistance_value: resistanceValue }
          }
          return exercise
        })

        return { ...workout, exercises: updatedExercises }
      }
      return workout
    })

    setWorkoutlist(updatedWorkoutList)
  }

  const draftAction = () => {
    navigate({ pathname: `/admin/trainees/traineeDetail/${id}`, search: `?program=true` })
  }

  const handleSaveAsDraft = () => {
    if (workoutlist.length === 0 || workoutlist.some((workout) => workout.exercises.length === 0)) {
      CustomToastify(t('MUST_HAVE_ONE_EXERCISE'), '', '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return
    }
    if (!programName.trim()) {
      CustomToastify(t('PROGRAM_NAME_REQUIRED'), '', '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return
    }

    if (workoutlist.some((workout) => workout.workoutName.trim() === '')) {
      CustomToastify(t('WORKOUTNAME_PROGRAM'), '', '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return
    }
    const isPropertyEmpty = (value) => value === undefined || value === ''
    // Function to check if any exercises have empty properties
    const hasEmptyProperties = (exercise) => {
      return isPropertyEmpty(exercise.set) || isPropertyEmpty(exercise.Resistance_value) || isPropertyEmpty(exercise.Tracking_value)
    }
    let flag = false
    // Iterate through workout data
    for (const workout of workoutlist) {
      for (const exercise of workout.exercises) {
        if (hasEmptyProperties(exercise)) {
          flag = true
          break

          // You can customize the error message or take further actions here
        }
      }
    }
    if (flag) {
      CustomToastify(t('EMPTY_FIELD'), '', '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return
    }

    // All validations passed, proceed with saving the draft
    let workouts = {}
    workoutlist.forEach((workout, index) => {
      const exercises = workout.exercises.map((exercise) => ({
        ExerciseId: exercise.id,
        Status: 'Draft',
        Resistance_value: exercise.Resistance_value,
        Tracking_value: exercise.Tracking_value,
        isband: exercise.isband,
        set: exercise.set,
      }))

      workouts[workout.workoutName] = { Exercises: exercises }
    })

    let data = {
      Program: {
        programName: programName,
        TraineeId: currentTrainee?.id,
        TraineeName: currentTrainee?.User?.firstName,
        Status: 'Draft',
        gymId: customerDetail?.id,
        WorkoutDetial: {
          workouts: workouts,
        },
      },
    }
    dispatch(setProgramAsDraft(data, t, direction, draftAction))
  }
  const handleContinue = () => {
    if (workoutlist.length === 0 || workoutlist.some((workout) => workout.exercises.length === 0)) {
      CustomToastify(t('MUST_HAVE_ONE_EXERCISE'), '', '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return
    }
    if (!programName?.trim()) {
      CustomToastify(t('PROGRAM_NAME_REQUIRED'), '', '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return
    }

    if (workoutlist.some((workout) => workout.workoutName.trim() === '')) {
      CustomToastify(t('WORKOUTNAME_PROGRAM'), '', '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return
    }
    const isPropertyEmpty = (value) => value === undefined || value === ''

    // Function to check if any exercises have empty properties
    const hasEmptyProperties = (exercise) => {
      return isPropertyEmpty(exercise.set) || isPropertyEmpty(exercise.Resistance_value) || isPropertyEmpty(exercise.Tracking_value)
    }
    let flag = false
    // Iterate through workout data
    for (const workout of workoutlist) {
      for (const exercise of workout.exercises) {
        if (hasEmptyProperties(exercise)) {
          flag = true
          break

          // You can customize the error message or take further actions here
        }
      }
    }
    if (flag) {
      CustomToastify(t('EMPTY_FIELD'), '', '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return
    } else {
      setStage2(true)
    }
    // All validations passed, proceed with saving the draft
  }
  const removeWork = async (id) => {
    if (workoutlist?.length <= 1) {
      CustomToastify(t('PROGRAM_WORKOUT_ERROR'), '', '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return
    }

    const updatedWorkoutList = await workoutlist.filter((workout) => workout.id !== id)
    const updatedWorkoutListWithNewIds = await updatedWorkoutList.map((workout, index) => ({
      ...workout,
      id: index,
    }))

    setWorkoutlist(updatedWorkoutListWithNewIds)

    if (id === isActive) {
      const newActiveWorkout = updatedWorkoutList[0]
      setActive(newActiveWorkout.id)

      // Set the scrollToId to the new active workout's ID
      setScrollToId(newActiveWorkout.id)

      // Use requestAnimationFrame to scroll after the DOM updates
      requestAnimationFrame(() => {
        const element = document.getElementById(`workout-${newActiveWorkout.id}`)

        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      })
    } else {
      setActive(updatedWorkoutListWithNewIds[updatedWorkoutListWithNewIds.length - 1].id)
      setScrollToId(updatedWorkoutListWithNewIds[updatedWorkoutListWithNewIds.length - 1].id)

      // Use requestAnimationFrame to scroll after the DOM updates
      requestAnimationFrame(() => {
        const element = document.getElementById(`workout-${updatedWorkoutListWithNewIds[updatedWorkoutListWithNewIds.length - 1].id}`)

        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      })
    }
  }
  const FooterComponent = () => {
    return (
      <div className="flex flex-row h-16 items-center px-4 justify-end xl:px-6 2xl:px-12 border-t-[1px] border-borderColor ios-position w-[360px] lg:w-[595px] bg-sidebar">
        <OutlineButton
          text={t('CLOSE')}
          type="submit"
          className=" w-[80px] h-[38px] rounded-[12px] shadow-2xl  "
          bgColor=" hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed rounded-[12px] cursor-pointer bg-sidebar
            border-borderActive border-[1.5px]"
          textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
          wid={20}
          ht={20}
          onClick={() => setOpenDetailModal(false)}
        />
      </div>
    )
  }
  const capitalizeFirstLetter = (word) => {
    return word?.charAt(0)?.toUpperCase() + word?.slice(1)
  }

  const sendComment = async () => {
    const payload = {
      exerciseId: selectedWorkoutAndExercise?.exerciseId,
      traineeId: currentTrainee?.id,
      text: comment,
    }
    const response = await dispatch(addCommentDuringProgram(payload))
    setCommentList(response.comments)
    setComment('')
  }
  return (
    <>
      <div className="flex h-full flex-col w-[55%] bg-secondaryBackground rounded-[12px] py-[16px] px-[24px] relative ">
        {/* ---------------------- Header -------------------------  */}
        <ProgramSectionHeader
          traineeInfo={currentTrainee}
          handleWorkout={handleAddWorkout}
          setData={setWorkoutlist}
          setName={setProgramName}
          pName={programName}
          t={t}
        />
        <div className="flex flex-col-reverse gap-2 w-full max-h-[70%] lg:max-h-[80%] xl:max-h-[88%] overflow-y-scroll">
          {workoutlist?.map((item, index) => {
            return (
              <div
                id={`workout-${item.id}`}
                className="h-full flex flex-col items-start gap-2"
                itemId={item?.id}
                key={item?.id}
                onDragOver={dragOver}
                onDrop={(event) => checkDropEvent(event, item?.id)}
              >
                <div className="flex flex-row justify-between py-2 w-full">
                  <div
                    className={`text-[16px] text-textLightGray leading-5 font-medium relative cursor-pointer`}
                    onClick={() => {
                      setActive(item?.id)
                      handleToggleOpen(item?.id)
                    }}
                  >
                    <div className="flex items-center">
                      {direction === 'rtl' && isActive === item?.id && <div className="bg-green-500 w-3 h-3 rounded-full ml-3"></div>}
                      {direction === 'ltr' && isActive === item?.id && <div className="bg-green-500 w-3 h-3 rounded-full "></div>}
                      <input
                        className={`bg-secondaryBackground text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} ${
                          isActive === item?.id && 'ml-5'
                        } border-b-[1px] border-white`}
                        type="text"
                        onChange={(e) => {
                          updateWorkoutName(e.target.value, item?.id)
                        }}
                        value={item?.workoutName}
                        maxLength={20}
                      />
                    </div>
                  </div>
                  <div className="flex items-center gap-x-2">
                    {item?.exercises?.length > 0 && (
                      <p
                        className={`ltr:font-primary-rg rtl:font-primary-he-rg text-[10px] leading-4  font-normal ${
                          item?.isTemplateCreated === true
                            ? 'text-[#5a5f66] opacity-50 cursor-not-allowed'
                            : 'cursor-pointer text-white hover:text-[#97CBFF]'
                        }`}
                        onClick={() => {
                          saveTemplate(item?.id)
                        }}
                      >
                        {t('SAVE_AS_TEMPLATE')}
                      </p>
                    )}
                    <div>
                      <img
                        src={item?.isOpen ? Images.ArrowUp : Images.DROPDOWNICON}
                        alt=""
                        height={18}
                        width={18}
                        className="items-center cursor-pointer"
                        onClick={() => {
                          handleToggleOpen(item?.id)
                        }}
                      />
                    </div>
                  </div>
                </div>
                {item?.isOpen && (
                  <>
                    {item?.exercises?.length > 0 ? (
                      <>
                        <div
                          id="right-container"
                          className={`w-full  gap-2 overflow-y-auto rounded-b-[12px] ${
                            workoutlist.length > 3 ? ' h-[300px] xl:h-[600px]' : 'h-[300px] xl:h-[600px]'
                          } max-h-[400px] xl:max-h-[600px]`}
                        >
                          {item?.exercises?.map((val, index) => (
                            <div
                              className="flex h-14 justify-between items-center py-2 px-2 my-2 rounded-[12px] cursor-pointer "
                              key={index}
                              id={'right-container' + index}
                              draggable
                              onDragStart={(event) => handleExerciseDragStart(event, item.id, index)}
                              onDragOver={(event) => handleExerciseDragOver(event, val.id, index)}
                              onDrop={(event) => {
                                if (isDrag) {
                                  handleExerciseDrop(event, item.id, index)
                                } else {
                                  // checkDropEvent(event, item?.id)
                                }
                              }}
                            >
                              <div className="flex gap-1 items-center">
                                <img src={Images.Dragging} alt="" height={2} width={24} className="items-center cursor-pointer" />
                                <p
                                  className={`${
                                    direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                                  } text-white leading-[18px] text-[14px] font-normal  w-44 px-1`}
                                >
                                  {t(val?.exerciseName)}
                                </p>
                              </div>
                              <div className="flex flex-row gap-[10px] justify-center items-center">
                                <div
                                  className={`flex flex-col justify-center items-center w-[76px] h-14 bg-textFieldColor rounded-[12px]
                                ${val?.Resistance_value !== null && val?.Resistance_value?.length !== 0 ? 'border-0 ' : 'border-[2px] border-error ]'}
                                `}
                                >
                                  <p
                                    className={`${
                                      direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                                    } font-normal text-[12px]  leading-4 px-1 ${
                                      val?.Resistance_value !== null && val?.Resistance_value?.length !== 0 ? ' text-textLightGrey' : ' text-error'
                                    }`}
                                  >
                                    {t(val?.resistancename)}
                                  </p>
                                  <p
                                    className={`${
                                      direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                                    } font-normal text-[16px] text-textLightGrey leading-5`}
                                  >
                                    {val?.resistancename === constants.RUBBER_BAND ? (
                                      <select
                                        className={`bg-textFieldColor outline-none ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
                                        onChange={(e) => handleSelectBandColor(e.target.value, item?.id, val?.id)}
                                      >
                                        {bands?.map((band, index) => (
                                          <option key={band?.id} value={band?.machineName}>
                                            {band?.machineName}
                                          </option>
                                        ))}
                                      </select>
                                    ) : (
                                      <input
                                        className={`bg-textFieldColor text-white w-[70px] text-center ${
                                          direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                                        }`}
                                        type="number"
                                        step="any"
                                        onChange={(e) => {
                                          updateData(e.target.value, item.id, val.id, 'Resistance_value')
                                        }}
                                        value={val?.Resistance_value}
                                      />
                                    )}
                                  </p>
                                </div>

                                <div
                                  className={`flex flex-col justify-center items-center w-[76px] h-14 bg-textFieldColor rounded-[12px]  
                                ${val?.Tracking_value !== null && val?.Tracking_value?.length !== 0 ? 'border-0' : 'border-[2px] border-error'}`}
                                >
                                  <p
                                    className={`${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-[12px] leading-4 ${
                                      val?.Tracking_value !== null && val?.Tracking_value?.length !== 0 ? ' text-textLightGrey' : ' text-error'
                                    }`}
                                  >
                                    {t(val?.trackingname)}
                                  </p>
                                  <p
                                    className={`${
                                      direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                                    } text-[16px] text-textLightGrey leading-5`}
                                  >
                                    <input
                                      className={`bg-textFieldColor text-white w-[70px] text-center`}
                                      type="number"
                                      step="any"
                                      onChange={(e) => {
                                        updateData(e.target.value, item.id, val.id, 'Tracking_value')
                                      }}
                                      value={val?.Tracking_value}
                                    />
                                  </p>
                                </div>
                                <div
                                  className={`flex flex-col justify-center items-center w-[76px] h-14 bg-textFieldColor rounded-[12px]
                                ${val?.set !== null && val?.set.length !== 0 ? 'border-0' : 'border-[2px] border-error'}`}
                                >
                                  <p
                                    className={`${
                                      direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                                    } px-2 font-normal text-[12px]  leading-4 ${
                                      val?.set !== null && val?.set.length !== 0 ? ' text-textLightGrey' : ' text-error'
                                    }`}
                                  >
                                    {t('SETS')}
                                  </p>
                                  <p
                                    className={`${
                                      direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                                    } font-normal text-[16px]  text-textLightGrey leading-5`}
                                  >
                                    <input
                                      className={`w-[70px] bg-textFieldColor text-white text-center px-2 ${
                                        direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                                      }`}
                                      type="number"
                                      onChange={(e) => {
                                        updateData(e.target.value, item.id, val.id, 'set')
                                      }}
                                      value={val?.set}
                                    />
                                  </p>
                                </div>

                                <div className="flex flex-row-reverse">
                                  <img
                                    src={Images.Remove}
                                    alt=""
                                    height={2}
                                    width={24}
                                    className="items-center cursor-pointer"
                                    onClick={() => {
                                      removeExercise(val?.id, item?.id)
                                    }}
                                  />

                                  <img
                                    src={Images.EditIcon}
                                    alt=""
                                    height={2}
                                    width={24}
                                    className="items-center cursor-pointer"
                                    onClick={async () => {
                                      let selectedExercise
                                      workoutlist.map((workout) => {
                                        if (workout.id === item?.id) {
                                          const updatedExercises = workout.exercises.filter((exercise) => exercise?.id === val?.id)
                                          selectedExercise = updatedExercises
                                        }
                                      })
                                      if (selectedExercise) {
                                        setSelectedExercise(selectedExercise)
                                        setSelectedWorkoutAndExercise({
                                          workoutId: item?.id,
                                          exerciseId: val?.id,
                                        })
                                        setOpenDetailModal(true)
                                        const data = {
                                          traineeId: currentTrainee?.id,
                                          exerciseId: val?.id,
                                        }
                                        setCommentsLoading(true)
                                        const response = await dispatch(exerciseComments(data))

                                        setCommentsLoading(false)
                                        if (response?.comments) {
                                          setCommentList(response.comments)
                                        }
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div
                          className="flex w-full justify-end cursor-pointer text-sm text-templateText ltr:font-primary-rg rtl:font-primary-he-rg"
                          onClick={() => removeWork(item?.id)}
                        >
                          {t('REMOVE_WORKOUT')}
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className={`w-full flex flex-col rounded-[12px] mt-2 px-4 py-2 justify-center items-center  ${
                            workoutlist.length > 3 ? ' h-[300px] xl:h-[500px] xxl:h-[600px]' : 'h-[300px] xl:h-[500px] xxl:h-[600px]'
                          } max-h-[300px] xl:max-h-[500px] xxl:max-h-[600px] `}
                        >
                          <img src={Images.EmptyWorkout} alt="" height={100} width={100} className="items-center cursor-pointer" />
                          <p className={`${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-[white] text-[24px] leading-7`}>
                            {t('WORKOUT_EMPTY')}
                          </p>
                        </div>
                        <div
                          className="flex w-full justify-end cursor-pointer text-sm text-templateText ltr:font-primary-rg rtl:font-primary-he-rg"
                          onClick={() => removeWork(item?.id)}
                        >
                          {t('REMOVE_WORKOUT')}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            )
          })}
        </div>
        <div className={` absolute flex bottom-4 ${direction === 'ltr' ? 'right-2' : 'left-2'} gap-x-2  `}>
          <OutlineButton
            text={t('SAVE_AS_DRAFT')}
            type="submit"
            disabled={!workoutlist.some((workout) => workout.exercises.length > 0)}
            isIcon={false}
            onClick={handleSaveAsDraft}
            className="w-[144px] h-[44px] rounded-[12px] border-borderActive border-[1.5px] shadow-2xl "
            bgColor="hover:bg-darkGray focus:bg-lightGray active:bg-lightGray"
            textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
            Loader={loader}
          />
          <PrimaryButton
            text={t('CONTINUE')}
            type="submit"
            disabled={!workoutlist.some((workout) => workout.exercises.length > 0)}
            isIcon={false}
            onClick={handleContinue}
            className="h-[44px] px-4 rounded-[12px] shadow-2xl"
            bgColor="bg-borderActive hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
            textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
          />
        </div>
      </div>
      {/* Exercise detail modal */}

      <Modal
        open={openDetailModal}
        className={`h-[100vh] w-[360px] relative right-0 lg:w-[595px] ${direction === 'ltr' ? 'rounded-l-3xl' : 'rounded-r-3xl'} `}
        handleClose={() => {
          setOpenDetailModal(false)
        }}
        isEdited={false}
        disabled={false}
        direction={direction}
        notCrossIcon={false}
        footer={<FooterComponent />}
      >
        <div className=" p-2 w-[99%] rounded-[16px]  sm:p-3 overflow-scroll no-scrollbar">
          {selectedExercise?.map((item, index) => {
            let subtitle = `${item?.sets?.length || 0} ${t('SETS')} `
            const isRubberBand = item?.sets && item?.sets[0]?.resistancename === 'rubberband'

            if (item?.bestSet) {
              // Localize numbers and units for Hebrew
              const localizedTrackingValue = item?.bestSet?.tracking_value || 0
              const localizedResistanceValue = isRubberBand ? item?.bandLevel : item.bestSet.resistance_value || 0
              const resistanceLabel = getResistanceLabel(item.sets && item.sets[0].resistancename)

              // Check the language and set the subtitle accordingly
              if (direction === 'rtl') {
                subtitle = `${item?.sets?.length || 0} סטים - ${resistanceLabel} ${localizedResistanceValue} x ${localizedTrackingValue}`
              } else {
                subtitle = `${item?.sets?.length || 0} ${t('SETS')} ${localizedTrackingValue} x ${localizedResistanceValue} ${resistanceLabel}`
              }
            }

            const machineName = item?.isSingleBodyPart ? 'single' : item?.machineName

            return (
              <>
                <ExerciseDetail
                  key={index}
                  indexKey={item?.index}
                  isIntensityTracking={item?.intensityTracking}
                  exerciseName={item?.exerciseName}
                  isBoostWeight={item?.boostWeight}
                  traineeId={currentTrainee?.id}
                  workoutId={null}
                  workoutName={''}
                  exerciseId={item?.exercisesId}
                  workoutExerciseId={item?.workoutExerciseId}
                  status={item?.workoutExerciseStatus}
                  name={item?.exerciseName}
                  subtitle={subtitle}
                  machineName={machineName}
                  sets={item?.Sets || []}
                  onRemoveExercise={() => {}}
                  onCommentsClick={() => {}}
                  isWorkoutHasOneExercise={1}
                  isFirstExercisePlayed={false}
                  isWorkoutSubmitting={loader}
                  advanceTechniqueValue={item?.Sets[0]?.advancedTechniques}
                  mobility_technique={item?.mobility_technique}
                  muscle_technique={item?.muscle_technique}
                  control_technique={item?.control_technique}
                  isTrainee={false}
                  refresh={() => {}}
                  data={[]}
                  setData={() => {}}
                  onClickExerciseInfo={() => {}}
                  setBestRecordId={() => {}}
                  bestRecordId={[]}
                  hasLastComment={false}
                  resistancename={item?.resistancename}
                  resistancetype={item?.resistancetype}
                  trackingname={item?.trackingname}
                  trackingunit={item?.trackingunit}
                  workoutList={workoutlist}
                  workoutDetails={selectedWorkoutAndExercise}
                  setWorkoutlist={setWorkoutlist}
                  handleClose={() => {
                    setOpenDetailModal(false)
                  }}
                />
                <div className="w-full border-2 border-extralightGray mt-1" />
                <div className="flex flex-col ">
                  <ExerciseCommentInput
                    name={t('ADD_COMMENT')}
                    type="text"
                    value={comment}
                    setValue={setComment}
                    label={t('ADD_COMMENT')}
                    placeholderValue={t('COMMENT')}
                    required
                    fullWidth
                    dir={direction}
                    icon={direction === 'rtl' ? Images?.CommentSend : Images.CommentSend_ltr}
                    handleComment={sendComment}
                  />
                  {commentsLoading ? (
                    <Loader className="" size="w-8 h-8" fill="#003353" />
                  ) : (
                    <div className="flex flex-col gap-6 mt-3 ">
                      {commentList?.length > 0 ? (
                        commentList?.map((item) => (
                          <div
                            className="flex flex-row gap-2 items-center cursor-pointer"
                            key={item?.id}
                            // onMouseEnter={() => handleMouseEnter(item?.id)}
                            // onMouseLeave={handleMouseLeave}
                          >
                            <ProfileAvatar image={item?.User?.avatar} name={item?.User?.first_name} size={'large'} />
                            <div className="flex flex-col w-[87%]">
                              <div className="flex flex-row gap-x-2 items-center">
                                <p className="font-primary-rg font-medium text-white text-sm leading-5">
                                  {capitalizeFirstLetter(item?.User?.first_name || '')}
                                </p>
                                <p className="font-primary-rg font-medium text-[#C2C7CF] text-xs leading-4">
                                  {formatDateAndTimeForComments(item?.createdAt)}
                                </p>
                              </div>
                              <div className="flex flex-wrap font-primary-rg font-medium text-white text-xs leading-4">{item?.text}</div>
                            </div>
                            <div className={`flex w-5 h-5 ${1 === item?.id ? 'block' : 'hidden'}`}>
                              <img
                                src={Images.Delete}
                                alt="Del"
                                height={32}
                                width={32}
                                className={`items-center cursor-pointer`}
                                onClick={() => {
                                  // handleDelete(item?.id)
                                }}
                              />
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="flex  text-white justify-center items-center text-2xl">{t('NO_COMMENTS')}</div>
                      )}
                    </div>
                  )}
                </div>
              </>
            )
          })}
        </div>
      </Modal>
    </>
  )
}
