import { Images } from 'assets'
import { Modal } from 'components/compounds/Modal'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Loader } from 'components/compounds/Loader'

import OutlineButton from 'components/elements/OutlineButton'

import { useDispatch } from 'react-redux'
import { addExerciseComment, deleteExerciseComment, getAllExerciseComments } from 'store/workoutSlice/workoutActions'
import { formatDateAndTimeForComments } from 'utils'
import { ProfileAvatar } from './ProfileAvatar'
import { ExerciseCommentInput } from './components/ExerciseCommentInput'
import { addCommentOnExercise, deleteComment } from 'store/traineeSlice/traineeActions'
import CustomToastify from 'components/compounds/Toast'

export default function PlayWorkoutExerciseComments({ show, setShow, isTrainee, refresh }) {
  const { t } = useTranslation()

  const { direction } = useSelector((state) => state.language)
  const { comments, loading } = useSelector((state) => state.workout)

  const [comment, setComment] = useState('')
  const [hoveredItemId, setHoveredItemId] = useState(null)
  const dispatch = useDispatch()
  const handleClose = () => {
    setShow(false)
  }
  const handleMouseEnter = (itemId) => {
    setHoveredItemId(itemId)
  }

  const handleMouseLeave = () => {
    setHoveredItemId(null)
  }

  const FooterComponent = () => {
    return (
      <div className="flex flex-row h-16 items-center px-4 justify-end xl:px-6 2xl:px-12 border-t-[1px] border-borderColor ios-position w-[360px] lg:w-[595px] bg-sidebar">
        <OutlineButton
          text={t('CLOSE')}
          type="submit"
          className=" w-[80px] h-[38px] rounded-[12px] shadow-2xl  "
          bgColor=" hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed rounded-[12px] cursor-pointer bg-sidebar
            border-borderActive border-[1.5px]"
          textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
          wid={20}
          ht={20}
          onClick={handleClose}
        />
      </div>
    )
  }
  const action = () => {
    setComment('')
  }

  const sendComment = async () => {
    const payload = {
      exerciseId: show?.exerciseId,
      workoutExerciseId: show?.workoutExerciseId,
      traineeId: show?.traineeId,
      coachId: show?.coachId,
      text: comment,
      isTrainee,
    }
    if (isTrainee) {
      await addCommentOnExercise(payload)
      action && action()

      dispatch(getAllExerciseComments(show?.exerciseId, show?.traineeId, t, direction))
    } else {
      dispatch(addExerciseComment(payload, action, t, direction))
    }
  }
  const handleDelete = async (id) => {
    const data = {
      exerciseId: show?.exerciseId,
      traineeId: show?.traineeId,
    }
    if (isTrainee) {
      await deleteComment(id)
      refresh && refresh()
      dispatch(getAllExerciseComments(show?.exerciseId, show?.traineeId, t, direction))
      CustomToastify(t('COMMENT_DELETED_SUCCESSFULLY'), '', '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
    } else {
      dispatch(deleteExerciseComment(id, data, t, direction))
    }
  }

  const capitalizeFirstLetter = (word) => {
    return word?.charAt(0)?.toUpperCase() + word?.slice(1)
  }
 

  return (
    <Modal
      className={`h-full w-[360px] lg:w-[595px] ${direction === 'ltr' ? 'rounded-l-3xl' : 'rounded-r-3xl'}    `}
      childrenClass=""
      open={show}
      title={t('COMMENT')}
      handleClose={handleClose}
      handleSubmit={() => {}}
      loader={loading}
      isEdited={false}
      disabled={false}
      direction={direction}
      footer={<FooterComponent />}
    >
      <div className="h-full overflow-hidden py-4 px-2">
        <div className="flex flex-col h-full">
          <ExerciseCommentInput
            name={t('ADD_COMMENT')}
            type="text"
            value={comment}
            setValue={setComment}
            label={t('ADD_COMMENT')}
            placeholderValue={t('COMMENT')}
            required
            fullWidth
            dir={direction}
            icon={direction === 'rtl' ? Images?.CommentSend : Images.CommentSend_ltr}
            handleComment={sendComment}
          />
          {loading ? (
            <Loader className="" size="w-8 h-8" fill="#003353" />
          ) : (
            <div className="flex flex-col gap-6 mt-3 h-full overflow-auto">
              {comments?.length > 0 ? (
                comments?.map((item) => (
                  <div
                    className="flex flex-row gap-2 items-center cursor-pointer"
                    key={item?.id}
                    onMouseEnter={() => handleMouseEnter(item?.id)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <ProfileAvatar image={item?.User?.avatar} name={item?.User?.firstName} size={'large'} />
                    <div className="flex flex-col w-[87%]">
                      <div className="flex flex-row gap-x-2 items-center">
                        <p className="font-primary-rg font-medium text-white text-sm leading-5">
                          {capitalizeFirstLetter(item?.User?.firstName || '')}
                        </p>
                        <p className="font-primary-rg font-medium text-[#C2C7CF] text-xs leading-4">
                          {formatDateAndTimeForComments(item?.createdAt)}
                        </p>
                      </div>
                      <div className="flex flex-wrap font-primary-rg font-medium text-white text-xs leading-4">{item?.text}</div>
                    </div>
                    <div
                      className={`flex w-5 h-5 ${
                        hoveredItemId === item?.id && (isTrainee ? item?.CreatedBy === show?.userId : item?.CreatedBy === show?.UserId)
                          ? 'block'
                          : 'hidden'
                      }`}
                    >
                      <img
                        src={Images.Delete}
                        alt="Del"
                        height={32}
                        width={32}
                        className={`items-center cursor-pointer`}
                        onClick={() => {
                          handleDelete(item?.id)
                        }}
                      />
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex h-full text-white justify-center items-center text-2xl">{t('NO_COMMENTS')}</div>
              )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}
