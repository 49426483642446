import PrimaryButton from 'components/elements/PrimaryButton'

import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Images } from 'assets'
import { useDispatch, useSelector } from 'react-redux'
import { uploadLibraryFile } from 'store/userSlice/userActions'
import { Loader } from 'components/compounds/Loader'

export default function Libraries({ direction }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { loader, customerDetail } = useSelector((state) => state.user)

  const inputRef = useRef()
  const [uploadFile, setUploadedFile] = useState(null)

  const handleDownload = async () => {
    try {
      const response = await fetch('https://storage.googleapis.com/coachable/coachable_template.xlsx')
      const blob = await response.blob()
      const fileName = 'template.xlsx'
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = fileName
      link.click()
    } catch (error) {
      console.error(error)
    } finally {
    }
  }

  const onFileChange = async (event) => {
    setUploadedFile(event.target.files[0])
    const formData = new FormData()
    formData.append('file', event.target.files[0])
    const id = customerDetail.id
    dispatch(uploadLibraryFile(id, formData, t, direction))
  }
  return (
    <div className="flex flex-col max-w-[612px]  py-9 justify-center items-center">
      {loader ? (
        <div className="flex flex-col justify-center items-center h-[400px]">
          <Loader className="" size="w-6 h-6" fill="#003353" />
        </div>
      ) : (
        <>
          <div className="flex w-full  justify-between items-center align-middle ">
            <p className={`flex text-[24px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-white text-center `}>
              {t('CSV_UPLOAD')}
            </p>
            <PrimaryButton
              text={t('DOWNLOAD_TEMPLATES')}
              type="submit"
              disabled={false}
              isIcon={true}
              icon={Images.Download}
              onClick={handleDownload}
              className=" h-[44px] rounded-[12px] shadow-2xl  "
              bgColor="bg-[#0c3354] hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
              textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
              wid={20}
              ht={20}
            />
          </div>
          <div className="flex w-full gap-x-4 items-center py-4 ">
            <div className="w-[220px] lg:w-[254px]">
              <div className="flex flex-col justify-center items-center w-[220px] lg:w-[254px] h-[166px] lg:h-[166px] rounded-[12px] bg-secondaryBackground hover:bg-borderColor">
                <input
                  ref={inputRef}
                  type={'file'}
                  accept=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onChange={onFileChange}
                  style={{ display: 'none' }}
                />
                &nbsp;
                <img
                  className=" cursor-pointer"
                  src={Images.DumbellPlus}
                  alt=""
                  width={'60px'}
                  style={{ paddingBottom: 10 }}
                  onClick={() => inputRef.current.click()}
                />
                <p className={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-[12px] text-center pb-4 pt-1`}>
                  {t('A_DATABASE_OF_EXERCISES')}
                </p>
              </div>
              {uploadFile && (
                <div className="flex flex-row justify-center items-center py-1 overflow-auto">
                  <img
                    className=" cursor-pointer "
                    src={Images.Check}
                    alt=""
                    width={20}
                    height={20}
                    style={{}}
                    onClick={() => inputRef.current.click()}
                  />
                  <p className={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-[12px] px-1 text-center`}>
                    {uploadFile?.name}
                  </p>
                </div>
              )}
            </div>
            <div className="flex flex-col justify-center items-center w-[220px] lg:w-[254px] h-[166px] lg:h-[166px] rounded-[12px] bg-secondaryBackground hover:bg-borderColor">
              <img className=" cursor-pointer" src={Images.UserPlus} alt="" width={'60px'} style={{ paddingTop: 15, paddingBottom: 10 }} />
              <p className={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-[12px] text-center pb-4 pt-1`}>
                {t('A_POOL_OF_TRAINEES')}
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
