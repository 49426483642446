import { Images } from "assets";
import ListTable from "components/List/ListTable";
import { ProfileAvatar } from "components/PlayBoard/ProfileAvatar";
import { WorkoutSummary } from "components/PlayBoard/WorkoutSummary";
import DeleteModal from "components/compounds/DeleteModal";
import { Loader } from "components/compounds/Loader";
import CustomToastify from "components/compounds/Toast";
import PrimaryButton from "components/elements/PrimaryButton";
import { constants } from "constant";
import moment from "moment";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { deleteProgram } from "store/traineeSlice/traineeActions";
import { getWorkout, removeWorkout } from "store/traineeSlice/traineeActions";
import { useTraineeSelector } from "store/traineeSlice/traineeReducer";
import { getTranslatedDate } from "utils";

export default function ProgramsDetail({ refreshData, active, name }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const query = useParams();
  const { t } = useTranslation();
  const [programID, setProgramID] = useState(null);
  const [openModal, setOpenModal] = useState();
  const { direction } = useSelector((state) => state.language);
  const { loader, programs } = useSelector((state) => state.trainee);
  const [isWorkoutSummary, setWorkoutSummary] = useState(null);
  const [isWorkoutCancelModal, setWorkoutCancelModal] = useState(false);
  const completedWorkoutList = useRef();

  const [isLoading, setLoading] = useState(false);

  const workoutHeaderRows = {
    data: [
      {
        id: "status",
        align: direction === "rtl" ? "right" : "left",
        type: "custom",
        disablePadding: false,
        label: "STATUS",
        sort: true,
        CustomComponent: ({ item }) => {
          const bgColor = constants.WORKOUT_STATUSES.find(
            (val) => val.status === item
          ).bgColor;
          const textColor = constants.WORKOUT_STATUSES.find(
            (val) => val.status === item
          ).textColor;

          return (
            <span
              className={`p-1 px-2 py-1 rounded-md ${
                direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"
              } text-[${textColor}] bg-[${bgColor}]`}
              style={{ backgroundColor: bgColor, color: textColor }}
            >
              {t(item.charAt(0).toLowerCase() + item.slice(1))}
            </span>
          );
        },
      },
      {
        id: "workoutName",
        align: direction === "rtl" ? "right" : "left",
        type: "text",
        disablePadding: false,
        label: "WORKOUT_NAME",
        sort: true,
      },
      {
        id: "dateTime",
        align: direction === "rtl" ? "right" : "left",
        type: "custom",
        disablePadding: false,
        label: "DATE_AND_TIME",
        sort: true,
        CustomComponent: ({ data }) => {
          return (
            <p
              className={`${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
            >
              {data?.Event?.endDate &&
                getTranslatedDate(data?.Event?.date, t, moment) + " • "}
              <span className="text-textGrey">{data?.Event?.startHour}</span>
            </p>
          );
        },
      },
      {
        id: "week",
        align: direction === "rtl" ? "right" : "left",
        type: "custom",
        disablePadding: false,
        label: "week",
        sort: true,
        CustomComponent: ({ item }) => {
          return (
            <p
              className={`${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
            >
              <span className="text-white">{parseInt(item) + 1}</span>
            </p>
          );
        },
      },
      {
        id: "workoutType",
        align: direction === "rtl" ? "right" : "left",
        type: "text",
        disablePadding: false,
        label: "TYPE",
        sort: true,
      },
      {
        id: "coach_Id",
        align: direction === "rtl" ? "right" : "left",
        type: "custom",
        disablePadding: false,
        label: "COACHES",
        sort: true,
        CustomComponent: ({ data }) => {
          return (
            <div className="flex items-center space-x-2 rtl:space-x-reverse">
              <ProfileAvatar
                image={data?.Coach?.User?.avatar}
                name={data?.Coach?.User?.firstName}
                color={
                  data?.Coach?.color === "BLUE" ? "#E5A772" : data?.Coach?.color
                }
                size={"small"}
              />
              <h6 className="text-xs whitespace-nowrap truncate">
                {data?.Coach?.User?.firstName}
              </h6>
            </div>
          );
        },
      },
      {
        id: "action",
        align: direction === "rtl" ? "right" : "left",
        type: "custom",
        disablePadding: false,
        label: "",
        sort: true,
        CustomComponent: ({ data }) => {
          return (
            <>
              {data.status === "active" && (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setWorkoutCancelModal(data.id);
                  }}
                >
                  {t("CANCEL")}
                </div>
              )}
              {data.status === "completed" && (
                <div
                  className="bg-prussianBlueFocused rounded-xl p-1 shadow-md text-center"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onClickView(data.id);
                  }}
                >
                  {t("VIEW")}
                </div>
              )}
            </>
          );
        },
      },
    ],
    clickable: true,
  };

  const headerRows = {
    data: [
      {
        id: "status",
        align: direction === "rtl" ? "right" : "left",
        type: "custom",
        disablePadding: false,
        label: "STATUS",
        sort: true,
        CustomComponent: ({ item }) => {
          const bgColor = constants.PROGRAM_STATUSES.find(
            (val) => val.status === item
          ).bgColor;
          const textColor = constants.PROGRAM_STATUSES.find(
            (val) => val.status === item
          ).textColor;
          return (
            <span
              className={`p-1 px-2 py-1 rounded-md ${
                direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"
              } text-[${textColor}] bg-[${bgColor}]`}
              style={{ backgroundColor: bgColor, color: textColor }}
            >
              {t(item.charAt(0).toUpperCase() + item.slice(1))}
            </span>
          );
        },
      },
      {
        id: "id",
        align: direction === "rtl" ? "right" : "left",
        type: "text",
        disablePadding: false,
        label: "#",
        sort: true,
      },
      {
        id: "programName",
        align: direction === "rtl" ? "right" : "left",
        type: "text",
        disablePadding: false,
        label: "Program_Name",
        sort: true,
      },
      {
        id: "data",
        align: direction === "rtl" ? "right" : "left",
        type: "custom",
        disablePadding: false,
        label: "WorkoutLength",
        sort: true,
        CustomComponent: ({ data }) => {
          return (
            <p
              className={`${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
            >{`${data?.workoutLength} ( ${
              data.completedWorkout
            } ${t("completed")})`}</p>
          );
        },
      },
      {
        id: "startDate",
        align: direction === "rtl" ? "right" : "left",
        type: "custom",
        disablePadding: false,
        label: "StartDate",
        sort: true,
        CustomComponent: ({ item }) => {
          return (
            <p
              className={`${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
            >
              {(item && moment(item).format("DD.MM.YYYY")) || "-"}
            </p>
          );
        },
      },
      {
        id: "endDate",
        align: direction === "rtl" ? "right" : "left",
        type: "custom",
        disablePadding: false,
        label: "EndDate",
        sort: true,
        CustomComponent: ({ item }) => {
          return (
            <p
              className={`${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
            >
              {(item && moment(item).format("DD.MM.YYYY")) || "-"}
            </p>
          );
        },
      },
    ],
    clickable: true,
    isDeleting: true,
    isCustomDropdown: true,
    isEditing: true,
    dropdownView: ({ row }) => {
      const completedWorkouts = row?.workouts
        ?.filter((item) => item.status === "completed")
        .map((item) => item.id);
      completedWorkoutList.current = completedWorkouts;

      return (
        <tr className="bg-borderColor rounded-xl">
          <td colSpan={headerRows.data.length + 2} className="rounded-xl">
            <ListTable
              headerRows={workoutHeaderRows}
              data={row?.workouts || []}
            />
          </td>
        </tr>
      );
    },

    isConditionalDelete: true,
  };
  const clickHandler = (item) => {
    navigate({
      pathname: `/admin/trainees/programInfo/${item.traineeId}`,
      search: `?programId=${item.id}&status=${item?.status}&active=${active}&traineeName=${name}`,
      state: { active: active, traineeName: name },
    });
  };
  const editHandler = (item) => {
    if (!["completed", "cancelled", "closed"].includes(item.status)) {
      navigate({
        pathname: `/admin/createProgram/${item.traineeId}`,
        search: `?programId=${item.id}&status=${item?.status}&active=${active}&traineeName=${name}&totalworkout=${item?.workouts?.length || 10}`,
      });
    }
  };

  const deleteHandler = (id, _, item) => {
    if (!["completed", "cancelled"].includes(item.status)) {
      setProgramID(id);
      setOpenModal(true);
    }
  };
  const action = () => {
    refreshData();
  };
  const _deleteprogram = () => {
    dispatch(deleteProgram(programID, t, direction, null));
    setOpenModal(false);
  };

  const onCancelWorkout = async () => {
    try {
      setLoading(true);
      const workoutId = isWorkoutCancelModal;
      await removeWorkout(workoutId);
      refreshData();
      setLoading(false);
      setWorkoutCancelModal(false);
      CustomToastify(
        t("WORKOUT_CANCELLED_SUCCESFULLY"),
        t(""),
        "",
        "bg-toastSuccessbg",
        direction,
        Images.toastSucess,
        true
      );
    } catch (error) {
      console.log("error", error);
      setWorkoutCancelModal(false);
      CustomToastify(
        t("ERROR_OCCURED"),
        t("ERROR_OCCURED_WHILE_CANCELING_WORKOUT"),
        "",
        "bg-toastFaildbg",
        direction,
        Images.toastFail,
        false
      );
      setLoading(false);
    }
  };

  const onClickView = async (workoutId) => {
    try {
      setWorkoutSummary(workoutId);
      setLoading(true);
      const workoutDetail = await getWorkout(workoutId);
      setWorkoutSummary(workoutDetail);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setWorkoutSummary(null);
      CustomToastify(
        t("ERROR_OCCURED"),
        t("ERROR_OCCURED_WHILE_CANCELING_WORKOUT"),
        "",
        "bg-toastFaildbg",
        direction,
        Images.toastFail,
        false
      );
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex flex-col w-[80%] max-w-[1051px] pb-10 ">
        <div className="flex flex-row justify-between pt-6 w-[91%] mx-auto">
          <div className=" text-xl text-white ltr:font-primary-rg rtl:font-primary-he-rg font-medium">
            {t("MY_PROGRAMS")}
          </div>
          <PrimaryButton
            text={t("CREATE_PROGRAM")}
            type="submit"
            className=" w-[200px] h-[44px] rounded-[12px] shadow-2xl  "
            bgColor="bg-[#00639B] hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed rounded-[12px] cursor-pointer 
           "
            isIcon={true}
            icon={Images.Plus}
            textClass={`text-white ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
            wid={14}
            ht={14}
            onClick={() =>
              navigate(`/admin/createProgram/${query?.id}`, {
                state: { active: active, traineeName: name },
              })
            }
          />
        </div>
        {loader ? (
          <div className="flex flex-col justify-center items-center h-[400px]">
            <Loader className="" size="w-6 h-6" fill="#003353" />
          </div>
        ) : (
          <>
            <ListTable
              headerRows={headerRows}
              data={programs}
              totalData={40}
              loading={false}
              searchText={""}
              clickHandler={clickHandler}
              tableClassName="w-[91%] mx-auto"
              editHandle={editHandler}
              deleteHandler={deleteHandler}
              editViewHandler={editHandler}
            />
          </>
        )}
      </div>
      <DeleteModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        t={t}
        direction={direction}
        des={t("MUSCLE_DELETE_DES")}
        title={t("PROGRAM_DELETE_TITLE")}
        action={() => {}}
        handleSubmit={_deleteprogram}
        showPass={false}
      />
      <DeleteModal
        setOpenModal={setWorkoutCancelModal}
        openModal={isWorkoutCancelModal}
        t={t}
        direction={direction}
        des={t("CANCEL_WORKOUT")}
        title={t("cancel")}
        action={() => {}}
        handleSubmit={onCancelWorkout}
        showPass={false}
        loading={isLoading}
      />
      {isWorkoutSummary && (
        <div className="absolute w-full h-full bg-[rgb(10, 21, 28,0.3)] top-0 right-0">
          <div
            style={{ zIndex: 100 }}
            className={`text-textLightGrey absolute top-0 ${
              direction === "ltr"
                ? "right-0 rounded-l-lg"
                : "left-0 rounded-r-lg"
            } h-screen w-96 bg-[rgb(10,21,28,1)]`}
          >
            {isLoading ? (
              <Loader className="" size="w-6 h-6" fill="#003353" />
            ) : (
              <div className="p-6 overflow-scroll h-full has-scrollbar relative">
                <WorkoutSummary
                  hideCancelButton
                  summary={isWorkoutSummary?.workoutSummary}
                  workoutId={isWorkoutSummary?.workoutData?.id}
                  muscleIds={isWorkoutSummary?.muscleIds}
                  traineeId={isWorkoutSummary?.workoutData?.traineeId}
                  tranieeImage={isWorkoutSummary?.workoutData?.avatar}
                  traineeName={isWorkoutSummary?.workoutData?.traineeName}
                  workoutName={isWorkoutSummary?.workoutData?.workoutName}
                  exercises={isWorkoutSummary?.workoutData?.exercises}
                  onCloseWorkout={() => setWorkoutSummary(null)}
                  nextWorkoutList={completedWorkoutList.current}
                  handleNextWorkout={onClickView}
                  showNext={true}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
