import { useEffect, useState } from 'react'

import { Images } from 'assets'
import { Loader } from 'components/compounds/Loader'
import { Modal } from 'components/compounds/Modal'
import OutlineButton from 'components/elements/OutlineButton'
import PrimaryButton from 'components/elements/PrimaryButton'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import CustomToastify from 'components/compounds/Toast'
import { constants } from 'constant'
import { useDispatch } from 'react-redux'
import { swapAllExerciseFromTrainee, swapExerciseFromTrainee } from 'store/traineeSlice/traineeActions'
import {
  SwapExerciseForAllWorkouts,
  SwapExerciseInWorkoutExercise,
  getExercisesInfo,
  getRecommendedExercisesForTrainnee,
} from 'store/workoutSlice/workoutActions'
import { useWorkoutSelector } from 'store/workoutSlice/workoutReducer'
import { getBestResistanceValue, getBestTrackingValue, lastPerformedDate } from 'utils'
import PlayWorkoutSwapExerciseHeader from './components/PlayWorkoutSwapExerciseHeader'
import RecommendedExercise from './components/RecommendedExercise'

export default function PlayWorkoutSwapExercises({ show, setShow, isTrainee, refresh }) {
  const { t } = useTranslation()
  const [loadingState, setLoadingState] = useState({
    isloading: false,
    itemId: '',
  })
  const [isLoading, setloading] = useState(false)
  const { direction } = useSelector((state) => state.language)
  const [searchText, setSearchText] = useState('')
  const [selectedExercise, setSelectedExercise] = useState(null)
  const { loading, traineeExercises, traineeRecomendedExercises } = useWorkoutSelector()
  const dispatch = useDispatch()
  const { bands } = useSelector((state) => state.gymAdmin)

  const { customerDetail } = useSelector((state) => state.user)

  const gymId = customerDetail.id
  const traineeId = show?.traineeId
  const exerciseId = show?.exerciseId

  const handleClose = () => {
    setSelectedExercise(null)
    setShow(false)
    setSearchText('')
  }

  useEffect(() => {
    if (show) {
      const action = () => {}
      dispatch(getRecommendedExercisesForTrainnee(gymId, traineeId, exerciseId, action, t, direction))
    }
    return () => {
      setSelectedExercise(null)
    }
    //eslint-disable-next-line
  }, [show])

  const swapAction = () => {
    setloading(false)
    setSelectedExercise(null)
    setShow(false)
  }
  const handleSwap = async () => {
    try {
      setloading(true)
      let payload = {
        WorkoutExerciseId: show?.workoutExerciseId,
        excerciseId: selectedExercise?.id,
        traineeId: show?.traineeId,
        workoutId: show?.workoutId,
        isAllPartial: show?.isAllPartial,
        gymId: gymId,
      }

      if (isTrainee) {
        const response = await swapExerciseFromTrainee(payload)
        if (response) {
          // CustomToastify(t('EXERCISE_SWAP_SUCCESSFULLY'), '', '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
          swapAction && swapAction()
          refresh && refresh(show?.workoutExerciseId)
          setShow(false)
        } else {
          setloading(false)
          swapAction && swapAction()
          CustomToastify(t('SOMETHING_WENT_WRONG'), t('EXERCISED_ADDED'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
        }
      } else {
        dispatch(SwapExerciseInWorkoutExercise(payload, t, swapAction, direction))
      }
    } catch (error) {
      setloading(false)
    }
  }
  const handleSwapForAll = async () => {
    try {
      setloading(true)
      let payload = {
        WorkoutExerciseId: show?.workoutExerciseId,
        excerciseId: selectedExercise?.id,
        traineeId: show?.traineeId,
        gymId: gymId,
        workoutId: show?.workoutId,
        isAllPartial: show?.isAllPartial,
      }

      if (isTrainee) {
        const response = await swapAllExerciseFromTrainee(payload)
        if (response) {
          // CustomToastify(t('EXERCISE_SWAP_SUCCESSFULLY'), '', '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
          setloading(false)
          refresh && refresh(show?.workoutExerciseId)
          setShow(false)
        } else {
          setloading(false)
          CustomToastify(t('SOMETHING_WENT_WRONG'), t('EXERCISED_ADDED'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
        }
      } else {
        dispatch(SwapExerciseForAllWorkouts(payload, t, swapAction, direction))
      }
    } catch (error) {
      setloading(false)
    }
  }

  const FooterComponent = () => {
    return (
      <div className="flex flex-row h-16 items-center px-4 justify-between xl:px-6 2xl:px-12 border-t-[1px] border-borderColor absolute bottom-0 w-full bg-sidebar">
        <OutlineButton
          text={t('CLOSE')}
          type="submit"
          className=" w-[80px] h-[38px] rounded-[12px] shadow-2xl  "
          bgColor=" hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed rounded-[12px] cursor-pointer bg-sidebar"
          textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
          wid={20}
          ht={20}
          onClick={handleClose}
        />
        <div className="flex gap-2">
          <PrimaryButton
            text={t('SWAP')}
            type="submit"
            disabled={selectedExercise != null ? (loading ? true : false) : true} //apply condition if some exercise is selected than make it disabled false
            onClick={() => {
              handleSwap()
            }}
            className="h-[38px] rounded-[12px] shadow-2xl "
            bgColor="bg-sidebarhover hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
            textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
            wid={15}
            ht={15}
            Loader={isLoading}
          />
          {!isLoading && (
            <OutlineButton
              text={t('SWAP_FOR_ALL_WORKOUTS')}
              type="submit"
              disabled={selectedExercise != null ? (loading ? true : false) : true}
              isIcon={false}
              onClick={() => {
                handleSwapForAll()
              }}
              className="h-[44px] rounded-[12px] border-borderActive border-[1.5px] shadow-2xl "
              bgColor="hover:bg-darkGray focus:bg-lightGray active:bg-lightGray"
              textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
              Loader={isLoading}
            />
          )}
        </div>
      </div>
    )
  }

  const setExerciseInfo = (data) => {
    setLoadingState({
      ...loadingState,
      isloading: false,
      itemId: data.id,
    })
    setSelectedExercise(data)
  }

  const handleSelectExercise = (data) => {
    dispatch(getExercisesInfo(traineeId, data?.id, t, direction, data, setExerciseInfo, setLoadingState))
  }

  const updatedExercise = traineeExercises?.filter((item) => {
    return item.exercise_name.toLowerCase().includes(searchText?.toLowerCase())
  })

  const getResistanceValue = (data) => {
    if (data?.resistance_name === constants.RUBBER_BAND) {
      return bands[0].machineName
    } else {
      if (data?.lastExercise) {
        return getBestResistanceValue(data?.lastExercise?.Sets)
      } else {
        return 10
      }
    }
  }

  return (
    <Modal
      className={` h-full w-[595px] ${direction === 'ltr' ? 'rounded-l-3xl' : 'rounded-r-3xl'}    `}
      childrenClass="overflow-hidden"
      open={show}
      title={`${t('SWAP')}:  ${show?.exerciseName}`}
      handleClose={handleClose}
      handleSubmit={() => {}}
      loader={loading}
      isEdited={false}
      disabled={false}
      direction={direction}
      footer={<FooterComponent />}
      padding={false}
    >
      <div className="h-full relative overflow-auto">
        {/* ----------------- Header --------------------- */}
        {traineeRecomendedExercises.length > 0 && (
          <>
            <RecommendedExercise
              recommendedExercise={traineeRecomendedExercises}
              selectedExercise={selectedExercise}
              handleSelectExercise={handleSelectExercise}
              t={t}
              direction={direction}
            />

            {/* ----------------- Divider --------------------- */}
            <div className="flex w-full h-[1px] bg-[#505C62] my-2"></div>
          </>
        )}
        {/* ----------------- Search Bar --------------------- */}
        <div className="flex flex-col w-full  items-center ">
          <PlayWorkoutSwapExerciseHeader gymId={gymId} traineeId={traineeId} exerciseId={exerciseId} />
        </div>
        {loading ? (
          <Loader className="" size="w-8 h-8" fill="#003353" />
        ) : (
          <>
            {/* ----------------- Show Exercise List --------------------- */}
            <div className="flex flex-row my-2">
              <p className="font-primary-rg font-medium text-[14px] text-white">
                {t('EXERCISES')} ({updatedExercise?.length})
              </p>
            </div>
            <div className="flex overflow-auto  flex-col gap-2 ">
              {updatedExercise?.map((item, index) => (
                <div key={index}>
                  <div
                    className="flex h-[54px] rounded-lg p-4 bg-playCard justify-between cursor-pointer"
                    onClick={() => {
                      handleSelectExercise(item)
                    }}
                  >
                    <p className="font-primary-rg font-medium text-[14px] text-white leading-5">{item?.exercise_name}</p>
                    {/* eslint-disable-next-line */}
                    {item?.exerciseCount != 0 && (
                      <p className=" font-primary-rg font-medium text-[12px] text-black rounded px-[7px] bg-lightGreen">
                        {t('DONE')} {item?.exerciseCount}
                      </p>
                    )}
                  </div>
                  {/* eslint-disable-next-line */}
                  {((selectedExercise != null && selectedExercise?.id === item?.id) || (loadingState.isloading && loadingState.itemId === item.id)) &&
                    (loadingState?.isloading && loadingState.itemId === item.id ? (
                      <Loader className=" top-5" size="w-8 h-8" fill="#003353" />
                    ) : (
                      <div className="flex flex-col bg-textFieldColor w-full rounded-lg p-4 gap-4 mt-2">
                        <div className="flex flex-row w-full justify-between">
                          <p className=" font-primary-rg font-medium text-[14px] text-white">{selectedExercise?.exercise_name}</p>
                          {/* eslint-disable-next-line */}
                          {selectedExercise?.exerciseCount != 0 && (
                            <p className=" font-primary-rg font-medium text-[12px] text-black rounded px-[7px] bg-lightGreen">
                              {t('DONE')} {selectedExercise?.exerciseCount}
                            </p>
                          )}
                        </div>
                        <div className="flex space-x-3 rtl:space-x-reverse justify-between ">
                          <div className="flex flex-col bg-[#505C62] rounded-xl w-full px-4 py-3 space-y-1">
                            <p className="font-primary-rg text-[12px] leading-4 text-textLightGrey">{t('SETS')}</p>
                            <p className="font-primary-rg text-[12px] leading-4 text-textLightGrey">
                              {selectedExercise?.lastExercise?.setsCount || 3}
                            </p>
                          </div>
                          <div className="flex flex-col bg-[#505C62] rounded-xl w-full px-4 py-3 space-y-1">
                            <p className=" font-primary-rg text-[12px] leading-4 text-textLightGrey">{selectedExercise?.resistance_name}</p>
                            <p className=" font-primary-rg text-[12px] leading-4 text-textLightGrey">{getResistanceValue(selectedExercise)}</p>
                          </div>
                          <div className="flex flex-col bg-[#505C62] rounded-xl w-full px-4 py-3 space-y-1">
                            <p className="font-primary-rg text-[12px] leading-4 text-textLightGrey">{selectedExercise?.trackingname}</p>
                            <p className="font-primary-rg text-[12px] leading-4 text-textLightGrey">
                              {' '}
                              {selectedExercise?.lastExercise ? getBestTrackingValue(selectedExercise?.lastExercise?.Sets) : 8}
                            </p>
                          </div>
                        </div>
                        {/* ----------------- Divider --------------------- */}
                        <div className="flex w-full h-[1px] bg-[#505C62]"></div>
                        <div className="flex flex-col gap-2">
                          <p className=" font-primary-rg text-[14px] text-white leading-4">{t('EXERCISE_INFO')}</p>
                          <div className="flex w-full flex-row gap-2 ">
                            <div className="flex flex-col w-1/2 rounded-lg p-2 gap-2 bg-[#2e5168]">
                              <div className="flex justify-between gap-2">
                                <p className=" font-primary-rg text-[12px] text-white leading-4">{t('TRACKING_TYPE')}</p>
                                <p className=" font-primary-rg text-[12px] text-white leading-4">{selectedExercise?.trackingname}</p>
                              </div>
                              <div className="flex justify-between gap-2">
                                <p className=" font-primary-rg text-[12px] text-white leading-4">{t('RESISTANCE_TYPE')}</p>
                                <p className=" font-primary-rg text-[12px] text-white leading-4">{selectedExercise?.resistance_type}</p>
                              </div>
                              <div className="flex justify-between gap-2">
                                <p className=" font-primary-rg text-[12px] text-white leading-4">{t('MUSCLES')}</p>
                                <div className="flex flex-row flex-wrap">
                                  {selectedExercise?.ExerciseMuscles?.map((item) => (
                                    <p className=" font-primary-rg text-[12px] text-white leading-4">
                                      {direction === 'rtl' ? item?.Muscle?.hebrewName : item?.Muscle?.muscleName},
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-col w-1/2 rounded-lg p-2 gap-2 ">
                              {selectedExercise?.lastExericse && (
                                <div className="flex bg-[#41565F] rounded-lg gap-2 text-[12px] font-primary-rg text-white font-thin p-2">
                                  {' '}
                                  {t('LAST_PERFORMED')}: {lastPerformedDate(selectedExercise?.lastExercise?.date)}
                                </div>
                              )}
                              {selectedExercise?.tags && (
                                <div className="flex flex-row bg-[#41565F] rounded-lg gap-2 p-2 flex-wrap">
                                  <p className="text-[12px] font-primary-rg text-white font-thin">{t('TAGS')}:</p>
                                  {selectedExercise?.tags != null && (
                                    <p className="text-[12px] font-primary-rg text-white font-thin" key={index}>
                                      {item}
                                    </p>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}
