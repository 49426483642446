import { Images } from "assets";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "store/authSlice/authReducers";
import { useTranslation } from "react-i18next";
import { format_Date, format_Date_to_Days } from "utils";

function RowComponent(props) {
  const { t } = useTranslation();
  const { direction } = useSelector((state) => state.language);
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const {
    isSelected,
    headerRows,
    handleClick,
    n,
    handleEdit,
    keyIndex,
    onDeleteItemsClick,
    action,
    setSelectedRow,
    selectedRow,

    handleCopy,
    isSubItem,
    editViewHandler,
  } = props;

  const { language } = useSelector((state) => state.language);
  // eslint-disable-next-line
  const [showDelete, setShowDelete] = useState(false);
  // eslint-disable-next-line
  const [showEdit, setShowEdit] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  return (
    <>
      <tr
        className={` cursor-pointer rounded-[10px] ${isSubItem ? "bg-[#465258] hover:bg-[#5a666c]" : "bg-tableRow "}  hover:bg-statusBg`}
        // hover={headerRows.clickable}
        role="checkbox"
        id="editable-row"
        aria-checked={isSelected}
        tabIndex={-1}
        selected={isSelected}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onClick={(e) => {
          // e.stopPropagation()
          if (headerRows.isDeleting) {
            setShowDelete(true);
          }
          if (headerRows.isEditable) {
            setShowEdit(true);
          }

          headerRows.clickable && handleClick(n);
        }}
        style={{ marginBottom: "16px" }}
      >
        {(headerRows.isDropDown || headerRows.isCustomDropdown) && (
          <td
            className={`${
              language === "en"
                ? "first:rounded-[10px_0_0_10px] last:rounded-[0_10px_10px_0] font-primary-rg"
                : "first:rounded-[0_10px_10px_0] last:rounded-[10px_0_0_10px] font-primary-he-rg"
            } tableText ${headerRows.isWidth && "w-[20px]"}  `}
          >
            {headerRows.isCustomDropdown && (
              <>
                {headerRows?.hasSubData && n?.subExerciseList?.length > 0 ? (
                  <div>
                    <img
                      className=" cursor-pointer"
                      src={
                        direction === "ltr"
                          ? selectedRow && selectedRow?.id === n?.id
                            ? Images.DROPDOWNICON
                            : Images.backArrow
                          : selectedRow && selectedRow?.id === n?.id
                            ? Images.DROPDOWNICON
                            : Images.backLessArrow
                      }
                      alt=""
                      width={20}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (selectedRow === n) {
                          setSelectedRow(null);
                        } else {
                          setSelectedRow(n);
                        }
                      }}
                    />
                  </div>
                ) : (
                  <>
                    {headerRows?.hasSubData === undefined && (
                      <div>
                        <img
                          className=" cursor-pointer"
                          src={
                            direction === "ltr"
                              ? selectedRow && selectedRow?.id === n?.id
                                ? Images.DROPDOWNICON
                                : Images.backArrow
                              : selectedRow && selectedRow?.id === n?.id
                                ? Images.DROPDOWNICON
                                : Images.backLessArrow
                          }
                          alt=""
                          width={20}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (selectedRow === n) {
                              setSelectedRow(null);
                            } else {
                              setSelectedRow(n);
                            }
                          }}
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </td>
        )}
        {headerRows.data.map((obj, index) => {
          switch (obj.type) {
            case "text":
              return (
                <td
                  className={`${
                    language === "en"
                      ? "first:rounded-[10px_0_0_10px] last:rounded-[0_10px_10px_0] font-primary-rg"
                      : "first:rounded-[0_10px_10px_0] last:rounded-[10px_0_0_10px] font-primary-he-rg"
                  } tableText  ${headerRows.isWidth && "w-[30px]"} ${obj?.className && obj.className} tableText   `}
                  contentEditable={headerRows?.isEditable ? "true" : "false"}
                  suppressContentEditableWarning={true}
                  key={index}
                  onClick={(e) => {}}
                >
                  <p className={`${obj.className && "truncate w-32"} `}>
                    {typeof n[obj.id] !== "object" && t(n[obj.id])}
                  </p>
                </td>
              );
            case "textCustom":
              return (
                <td
                  className={`${
                    language === "en"
                      ? "first:rounded-[10px_0_0_10px] last:rounded-[0_10px_10px_0] font-primary-rg"
                      : "first:rounded-[0_10px_10px_0] last:rounded-[10px_0_0_10px] font-primary-he-rg"
                  } tableText  ${obj?.className && obj.className} tableText   `}
                  key={index}
                  contentEditable={headerRows?.isEditable ? "true" : "false"}
                  suppressContentEditableWarning={true}
                  onClick={(e) => {
                    if (headerRows?.isEditable) {
                      e.stopPropagation();
                    }
                  }}
                >
                  {typeof n[obj.id] !== "object" && t(n[obj.id]?.toString())}
                </td>
              );

            case "Objtext":
              const objKeys = obj?.id?.split(".");

              let objectToString = JSON.parse(JSON.stringify(n));

              objKeys &&
                objKeys?.forEach((key) => {
                  if (
                    typeof objectToString === "object" &&
                    key in objectToString
                  ) {
                    objectToString = objectToString[key];
                  } else {
                    objectToString = " ";
                  }
                });
              return (
                <td
                  className={`${
                    language === "en"
                      ? "first:rounded-[10px_0_0_10px] last:rounded-[0_10px_10px_0] font-primary-rg"
                      : "first:rounded-[0_10px_10px_0] last:rounded-[10px_0_0_10px] font-primary-he-rg"
                  } tableText  ${obj?.className && obj.className}  `}
                  component="th"
                  // eslint-disable-next-line
                  scope="row"
                  key={index}
                  align={obj.align}
                  contentEditable={headerRows?.isEditable ? "true" : "false"}
                  suppressContentEditableWarning={true}
                  onClick={(e) => {
                    if (headerRows?.isEditable) {
                      e.stopPropagation();
                    }
                  }}
                >
                  <p className={`${obj.className && "truncate w-28"} `}>
                    {typeof objectToString !== "object" &&
                      t(objectToString.toString())}
                  </p>
                </td>
              );
            case "ObjtextCustom":
              const objKeysCustom = obj?.id?.split(".");

              let objectToStringCustom = JSON.parse(JSON.stringify(n));

              objKeysCustom.forEach((key) => {
                if (
                  typeof objectToStringCustom === "object" &&
                  key in objectToStringCustom
                ) {
                  objectToStringCustom = objectToStringCustom[key];
                } else {
                  objectToStringCustom = " ";
                }
              });
              return (
                <td
                  className={`${
                    language === "en"
                      ? "first:rounded-[10px_0_0_10px] last:rounded-[0_10px_10px_0] font-primary-rg"
                      : "first:rounded-[0_10px_10px_0] last:rounded-[10px_0_0_10px] font-primary-he-rg"
                  } tableText  ${obj?.className && obj.className} tableText   `}
                  component="th"
                  // eslint-disable-next-line
                  scope="row"
                  key={index}
                  contentEditable={headerRows?.isEditable ? "true" : "false"}
                  suppressContentEditableWarning={true}
                  onClick={(e) => {
                    if (headerRows?.isEditable) {
                      e.stopPropagation();
                    }
                  }}
                >
                  <div className="flex items-center justify-center">
                    <p className="bg-statusBg w-20 text-center rounded-[4px] font-primary-rg text-[14px] py-[1px]">
                      {typeof objectToStringCustom !== "object" &&
                        objectToStringCustom.toString()}
                    </p>
                  </div>
                </td>
              );
            case "ObjtextStatus":
              const objKeysStatus = obj?.id?.split(".");

              let objectToStringStatus = JSON.parse(JSON.stringify(n));

              objKeysStatus &&
                objKeysStatus?.forEach((key) => {
                  if (
                    typeof objectToStringStatus === "object" &&
                    key in objectToStringStatus
                  ) {
                    objectToStringStatus = objectToStringStatus[key];
                  } else {
                    objectToStringStatus = " ";
                  }
                });
              const status = objectToStringStatus.toString();

              return (
                <td
                  className={`${
                    language === "en"
                      ? "first:rounded-[10px_0_0_10px] last:rounded-[0_10px_10px_0] font-primary-rg"
                      : "first:rounded-[0_10px_10px_0] last:rounded-[10px_0_0_10px] font-primary-he-rg"
                  } tableText  ${obj?.className && obj.className} tableText  `}
                  component="th"
                  // eslint-disable-next-line
                  scope="row"
                  key={index}
                  contentEditable={headerRows?.isEditable ? "true" : "false"}
                  suppressContentEditableWarning={true}
                  onClick={(e) => {
                    if (headerRows?.isEditable) {
                      e.stopPropagation();
                    }
                  }}
                >
                  <p
                    className={`${
                      status === "Experience"
                        ? "bg-[#1B262D]"
                        : status === "Trail"
                          ? "bg-trail"
                          : status === "Suspended"
                            ? "bg-suspend"
                            : status === "MonthlyPayment"
                              ? "bg-monthlyPayment"
                              : status === "Deleted"
                                ? "bg-suspend"
                                : ""
                    } px-1 w-20 text-center rounded-[4px]`}
                  >
                    {" "}
                    {typeof objectToStringStatus !== "object" &&
                      t(objectToStringStatus.toString())}
                  </p>
                </td>
              );

            case "date":
              return (
                <td
                  className={`${
                    language === "en"
                      ? "first:rounded-[10px_0_0_10px] last:rounded-[0_10px_10px_0] font-primary-rg"
                      : "first:rounded-[0_10px_10px_0] last:rounded-[10px_0_0_10px] font-primary-he-rg"
                  } tableText  ${obj?.className && obj.className} tableText   `}
                  component="th"
                  contentEditable={headerRows?.isEditable ? "true" : "false"}
                  suppressContentEditableWarning={true}
                  // eslint-disable-next-line
                  scope="row"
                  key={index}
                  onClick={(e) => {
                    if (headerRows?.isEditable) {
                      e.stopPropagation();
                    }
                  }}
                >
                  {typeof n[obj.id] !== "object" && format_Date(n[obj.id])}
                </td>
              );
            case "date_and_days":
              return (
                <td
                  className={`${
                    language === "en"
                      ? "first:rounded-[10px_0_0_10px] last:rounded-[0_10px_10px_0] font-primary-rg"
                      : "first:rounded-[0_10px_10px_0] last:rounded-[10px_0_0_10px] font-primary-he-rg"
                  } tableText  ${obj?.className && obj.className} tableText   `}
                  component="th"
                  contentEditable={headerRows?.isEditable ? "true" : "false"}
                  suppressContentEditableWarning={true}
                  // eslint-disable-next-line
                  scope="row"
                  key={index}
                  onClick={(e) => {
                    if (headerRows?.isEditable) {
                      e.stopPropagation();
                    }
                  }}
                >
                  {typeof n[obj.id] !== "object" && (
                    <p
                      className={`${language === "en" ? "font-primary-rg" : "font-primary-he-rg"}`}
                    >
                      {format_Date_to_Days(n[obj.id], t)}
                    </p>
                  )}
                </td>
              );
            case "daysfromDays":
              return (
                <td
                  className={`${
                    language === "en"
                      ? "first:rounded-[10px_0_0_10px] last:rounded-[0_10px_10px_0] font-primary-rg"
                      : "first:rounded-[0_10px_10px_0] last:rounded-[10px_0_0_10px] font-primary-he-rg"
                  } tableText  ${obj?.className && obj.className} tableText  `}
                  component="th"
                  contentEditable={headerRows?.isEditable ? "true" : "false"}
                  suppressContentEditableWarning={true}
                  // eslint-disable-next-line
                  scope="row"
                  key={index}
                  onClick={(e) => {
                    if (headerRows?.isEditable) {
                      e.stopPropagation();
                    }
                  }}
                >
                  {typeof n[obj.id] !== "object" &&
                    format_Date_to_Days(n[obj.id], t)}
                </td>
              );
            case "action":
              return (
                <td
                  className={`${
                    language === "en"
                      ? "first:rounded-[10px_0_0_10px] last:rounded-[0_10px_10px_0] font-primary-rg"
                      : "first:rounded-[0_10px_10px_0] last:rounded-[10px_0_0_10px] font-primary-he-rg"
                  } tableText  ${obj?.className && obj.className} tableText   `}
                  key={index}
                  contentEditable={headerRows?.isEditable ? "true" : "false"}
                  suppressContentEditableWarning={true}
                  onClick={async (e) => {
                    if (obj?.actionText) {
                      action(n?.Event?.id, n?.Event?.Coach?.id);
                    } else {
                      dispatch(
                        authActions.setSuperAdmin({
                          isAdmin: true,
                          selectedGym: n.gym_Id,
                        })
                      );
                      navigation("/admin/dashboard");
                      if (headerRows?.isEditable) {
                        e.stopPropagation();
                      } else {
                      }
                    }
                  }}
                >
                  <p
                    className={`text-[#4796D7] text-[16px] ${language === "en" ? "font-primary-rg" : "font-primary-he-rg"}`}
                  >
                    {obj?.actionText ? t(obj?.actionText) : t("ENTER")}
                  </p>
                </td>
              );
            case "custom":
              const CustomComponent = obj.CustomComponent;
              return (
                <td
                  className={`${
                    language === "en"
                      ? "first:rounded-[10px_0_0_10px] last:rounded-[0_10px_10px_0] font-primary-rg"
                      : "first:rounded-[0_10px_10px_0] last:rounded-[10px_0_0_10px] font-primary-he-rg"
                  } ${obj?.className && obj.className} tableText  `}
                  component="th"
                  // eslint-disable-next-line
                  scope="row"
                  align={obj.align}
                  key={index}
                  contentEditable={headerRows?.isEditable ? "true" : "false"}
                  suppressContentEditableWarning={true}
                >
                  <CustomComponent data={n} item={n[obj?.id]} />
                </td>
              );

            default:
              return null;
          }
        })}

        {(headerRows.isDeleting ||
          headerRows.isEditable ||
          headerRows.isCopy ||
          headerRows.isClickedEditable) && (
          <td
            className={`${
              language === "en"
                ? "first:rounded-[10px_0_0_10px] last:rounded-[0_10px_10px_0] font-primary-rg"
                : "first:rounded-[0_10px_10px_0] last:rounded-[10px_0_0_10px] font-primary-he-rg"
            } tableText  ${headerRows.isWidth && "w-[20px]"}  `}
            contentEditable={headerRows?.isEditable ? "true" : "false"}
            suppressContentEditableWarning={true}
          >
            <div
              className={`flex flex-row justify-center gap-2 items-center ${headerRows.isWidth ? "w-[20px]" : "w-20"} h-full`}
            >
              {(headerRows.isEditable || headerRows.isClickedEditable) &&
                isHovering && (
                  <>
                    {!n?.master_Id && (
                      <div>
                        <img
                          className=" cursor-pointer"
                          src={
                            headerRows.isClickedEditable
                              ? Images.Pen
                              : Images.EditSave
                          }
                          alt=""
                          width={20}
                          onClick={(e) => {
                            e.stopPropagation();

                            headerRows.isClickedEditable
                              ? editViewHandler(n)
                              : handleEdit(n, keyIndex);
                          }}
                        />
                      </div>
                    )}
                  </>
                )}
              {headerRows.isDeleting && isHovering && (
                <>
                  {headerRows?.isConditionalDelete &&
                  headerRows?.isConditionalDelete !== undefined &&
                  n?.status !== "closed" ? (
                    <div>
                      <img
                        className=" cursor-pointer"
                        src={Images.Delete}
                        alt=""
                        width={20}
                        onClick={(e) => {
                          e.stopPropagation();
                          onDeleteItemsClick(n, keyIndex);
                        }}
                      />
                    </div>
                  ) : (
                    <>
                      {headerRows?.isConditionalDelete === undefined && (
                        <div>
                          <img
                            className=" cursor-pointer"
                            src={Images.Delete}
                            alt=""
                            width={20}
                            onClick={(e) => {
                              e.stopPropagation();
                              onDeleteItemsClick(n, keyIndex);
                            }}
                          />
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
              {headerRows.isCopy && isHovering && (
                <div>
                  <img
                    className=" cursor-pointer"
                    src={Images.Copy}
                    alt=""
                    width={20}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCopy(n, keyIndex);
                    }}
                  />
                </div>
              )}
              {headerRows.isEditing && isHovering && (
                <>
                  {!["completed", "cancelled", "closed"].includes(n.status) && (
                    <div>
                      <img
                        className=" cursor-pointer"
                        src={Images.Pen}
                        alt=""
                        width={20}
                        onClick={(e) => {
                          e.stopPropagation();
                          editViewHandler(n);
                        }}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </td>
        )}
      </tr>
    </>
  );
}

export default RowComponent;
