import { Modal } from 'components/compounds/Modal'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Loader } from 'components/compounds/Loader'

import OutlineButton from 'components/elements/OutlineButton'

import { useDispatch } from 'react-redux'
import { formatDateAndTimeForComments } from 'utils'
import { ProfileAvatar } from '../ProfileAvatar'

export default function CommentsHistory({ show, setShow }) {
  const { t } = useTranslation()

  const { direction } = useSelector((state) => state.language)
  const { comments, loading } = useSelector((state) => state.workout)
  const dispatch = useDispatch()
  const handleClose = () => {
    setShow(false)
  }
console.log({comments})
  const FooterComponent = () => {
    return (
      <div className="flex flex-row h-16 items-center px-4 justify-end xl:px-6 2xl:px-12 border-t-[1px] border-borderColor ios-position w-[360px] lg:w-[595px] bg-sidebar">
        <OutlineButton
          text={t('CLOSE')}
          type="submit"
          className=" w-[80px] h-[38px] rounded-[12px] shadow-2xl  "
          bgColor=" hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed rounded-[12px] cursor-pointer bg-sidebar
            border-borderActive border-[1.5px]"
          textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
          wid={20}
          ht={20}
          onClick={handleClose}
        />
      </div>
    )
  }

  const capitalizeFirstLetter = (word) => {
    return word?.charAt(0)?.toUpperCase() + word?.slice(1)
  }

  return (
    <Modal
      className={`h-full w-[360px] lg:w-[595px] ${direction === 'ltr' ? 'rounded-l-3xl' : 'rounded-r-3xl'}    `}
      childrenClass=""
      open={show}
      title={t('COMMENT')}
      handleClose={handleClose}
      handleSubmit={() => {}}
      loader={loading}
      isEdited={false}
      disabled={false}
      direction={direction}
      footer={<FooterComponent />}
    >
      <div className="h-full overflow-hidden py-4 px-2">
        <div className="flex flex-col h-full">
          {loading ? (
            <Loader className="" size="w-8 h-8" fill="#003353" />
          ) : (
            <div className="flex flex-col gap-6 mt-3 h-full overflow-auto">
              {comments?.length > 0 ? (
                comments?.map((item) => (
                  <div className="flex flex-row gap-2 items-center cursor-pointer" key={item?.id}>
                    <ProfileAvatar image={item?.User?.avatar} name={item?.User?.firstName} size={'large'} />
                    <div className="flex flex-col w-[87%]">
                      <div className="flex flex-row gap-x-2 items-center">
                        <p className="font-primary-rg font-medium text-white text-sm leading-5">
                          {capitalizeFirstLetter(item?.User?.firstName || '')}
                        </p>
                        <p className="font-primary-rg font-medium text-[#C2C7CF] text-xs leading-4">
                          {formatDateAndTimeForComments(item?.createdAt)}
                        </p>
                      </div>
                      <div className="flex flex-wrap font-primary-rg font-medium text-white text-xs leading-4">{item?.text}</div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex h-full text-white justify-center items-center text-2xl">{t('NO_COMMENTS')}</div>
              )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}
