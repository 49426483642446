import React from 'react'

function InfoIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_15_8040)">
        <circle cx="7.99992" cy="8.00004" r="6.66667" stroke="#BFE9FF" />
        <path d="M8 4.66663V8.66663" stroke="#BFE9FF" strokeLinecap="round" />
        <circle cx="7.99992" cy="10.6667" r="0.666667" fill="#BFE9FF" />
      </g>
      <defs>
        <clipPath id="clip0_15_8040">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default InfoIcon
