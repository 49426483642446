import React, { useEffect } from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { persister, store } from 'store'

import { Provider } from 'react-redux'
import MainRoutes from 'routes'
import './styles/global.css'
import LanguageSelector from 'components/compounds/LanguageSelector'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { env } from 'constant'




const App = () => {

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function(registrations) {
        for(let registration of registrations) {
          console.log("Found Service worker registrations, removing")
          registration.unregister();
        }
      });
    }
    
  }, [])

  // Stopped Using Socket Context at Root Level because we don't actually need socket on all pages.
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persister}>
        <MainRoutes />
        <LanguageSelector />
      </PersistGate>
      <ToastContainer
        hideProgressBar={true}
        newestOnTop={false}
        rtl={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        autoClose={1000}
      />
    </Provider>
  );
};

export default App;
