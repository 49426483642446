import { useOnClickOutside } from 'hooks/useOnClickOutside'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

const SearchLabelInput = ({
  name,
  error,
  errorMessage,
  required,
  fullWidth,
  className,
  multiline,
  rows,
  onChange,
  disabled,
  label,
  type,
  icon,
  placeholderValue,
  isTypePassword,
  setPasswordShown,
  passwordShown,
  dir,
  isRequired,
  value,
  setValue,
  showLable = true,
  options,
  setIsCopy,
  isCopy,
  setExerciseID,
  limit,
}) => {
  const { t } = useTranslation()
  const divRef = useRef()
  const [query, setQuery] = useState('')

  useOnClickOutside(divRef, () => setQuery(''))

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur()

    e.stopPropagation()
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  const [isInputFocused, setIsInputFocused] = useState(false)

  const handleInputFocus = () => {
    setIsInputFocused(true)
  }

  const handleInputBlur = () => {
    setIsInputFocused(false)
  }

  const handleChange = (event) => {
    setQuery(event.target.value)
    setValue(event.target.value)
  }
  const filteredOption =
    query === ''
      ? options
      : options.filter((person) => person?.name?.toLowerCase().replace(/\s+/g, '').includes(query?.toLowerCase().replace(/\s+/g, '')))
  const handleSelect = (item) => {
    setQuery('')
    setValue(`${item?.name}-1`)
    setExerciseID(item?.id)
    setIsCopy(true)
  }
  return (
    <>
      <div
        className={`flex flex-col  h-[56px] w-full   my-1  rounded-[12px] ${disabled && 'opacity-50'} ${
          isInputFocused ? 'border-2 border-[#006684]' : ''
        } `}
      >
        <label
          className={`flex  ${dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} rounded-t-[12px]  text-[12px] bg-textFieldColor  px-3 ${
            isInputFocused ? 'text-[#65b1f4]' : 'text-white'
          }  `}
        >
          {label && showLable === true && (
            <>
              {label}
              {isRequired && <span className="text-[red] px-1">*</span>}
            </>
          )}
        </label>
        <div
          className={`flex flex-row h-full w-full  items-center justify-between rounded-b-[12px] ${showLable === false && 'rounded-t-[12px]'}
          bg-textFieldColor px-1`}
        >
          <input
            name={name}
            onChange={handleChange}
            className={`${className} w-full bg-textFieldColor text-white px-2 text-[16px] ${
              dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
            }  `}
            value={value}
            label={label}
            type={type ? type : 'text'}
            placeholder={placeholderValue ? placeholderValue : ''}
            required={required}
            fullWidth={fullWidth}
            disabled={disabled}
            onWheel={numberInputOnWheelPreventChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            maxLength={limit ? limit : undefined}
          />
          {icon && <img src={icon} alt="" height={40} width={45} className={`p-3 items-center `} />}
        </div>
      </div>
      {error && (
        <p
          className={`form-errors ${dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} flex flex-col px-1 ${
            dir === 'rtl' ? 'text-right' : 'text-left'
          } `}
        >
          {t(errorMessage)}
        </p>
      )}

      <div className="flex w-full  items-center space-x-4 rtl:space-x-reverse relative" ref={divRef}>
        {query !== '' && filteredOption.length > 0 && (
          <div className="absolute w-full  top-0  rounded-lg shadow-2xl bg-borderColor z-50">
            {filteredOption?.map((item, index) => (
              <div
                className={`w-full  px-6 py-2 bg-borderColor rounded-lg hover:bg-statusBg cursor-pointer text-xs ${
                  dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                }`}
                key={index}
                onClick={() => handleSelect(item)}
              >
                {item?.name}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  )
}

export { SearchLabelInput }
