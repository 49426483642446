import { Images } from 'assets'

import { Loader } from 'components/compounds/Loader'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useState } from 'react'

import { MuscleSummary } from 'components/PlayBoard/components'
import { useDispatch } from 'react-redux'
import { gymAdminActions } from 'store/gymAdminSlice/gymAdminReducers'
import { templateExerciseInfo } from 'store/workoutSlice/workoutActions'

export default function TemplateDropDown({ toggleDropdown, isOpen, direction, data, dragOver, workoutlist, setWorkoutlist, isActive, setActive }) {
  const { t } = useTranslation()
  const { loader, currentTrainee } = useSelector((state) => state.gymAdmin)
  const [activeTemplate, setActiveTemplate] = useState([])
  const { templatesList } = useSelector((state) => state?.template)
  const { exercisesInfo } = useSelector((state) => state.gymAdmin)
  const dispatch = useDispatch()
  const toggleTemplate = (id) => {
    if (activeTemplate.includes(id)) {
      setActiveTemplate(activeTemplate.filter((item) => item !== id))
    } else {
      setActiveTemplate([...activeTemplate, id])
    }
  }

  const getSortedMuscle = (muscleSummary) => {
    const sortedMuscles = [...muscleSummary].sort((a, b) => parseFloat(b.percentage) - parseFloat(a.percentage))

    return sortedMuscles
  }

  const handleClick = async (template) => {
    // Create an array to store all the exercises to be added
    const data = {
      traineeId: currentTrainee?.id,
      templateId: template?.id,
    }
    const response = await dispatch(templateExerciseInfo(data))
    const exercisesToAdd = []
    if (response && response.length > 0) {
      response.forEach((item) => {
        const draggedItem = exercisesInfo.find((exercise) => exercise.id === item.id)
        if (draggedItem) {
          const selectedWorkout = workoutlist.find((workout) => workout.id === isActive)

          if (selectedWorkout) {
            const isAlreadyAdded = selectedWorkout.exercises.some((exercise) => exercise.id === draggedItem.id)

            if (!isAlreadyAdded) {
              dispatch(
                gymAdminActions.setWorkoutNameInExercise({
                  WorkoutId: selectedWorkout?.id,
                  ExerciseId: draggedItem?.id,
                  WorkoutName: selectedWorkout?.workoutName,
                })
              )
              exercisesToAdd.push(item)
            }
          }
        }
      })

      if (exercisesToAdd.length > 0) {
        const updatedWorkoutlist = workoutlist.map((workout) => {
          if (workout.id === isActive) {
            return {
              ...workout,
              exercises: [...workout.exercises, ...exercisesToAdd],
            }
          }
          return workout
        })
        setWorkoutlist(updatedWorkoutlist)
      }
      if (activeTemplate.includes(template?.id)) {
        setActiveTemplate(activeTemplate.filter((item) => item !== template?.id))
      }
    }
  }

  return (
    <>
      {loader ? (
        <div className="flex flex-col justify-center items-center h-[400px]">
          <Loader className="" size="w-6 h-6" fill="#003353" />
        </div>
      ) : (
        <div className={` flex flex-col w-full ${isOpen ? 'h-[87%]' : 'h-14'}`}>
          <div
            className={`flex items-center justify-between px-4 py-2 text-sm ${
              isOpen ? 'bg-borderColor' : 'bg-textFieldColor'
            }  text-white  w-full h-14 ${isOpen ? 'rounded-t-[12px]' : 'rounded-[12px]'}`}
            onClick={toggleDropdown}
          >
            <div className="flex">
              <img
                src={direction === 'ltr' ? (isOpen ? Images.DROPDOWNICON : Images.backArrow) : isOpen ? Images.DROPDOWNICON : Images.DropDown_Left}
                alt=""
                height={20}
                width={20}
                className={`items-center cursor-pointer`}
                onClick={() => {}}
              />
              <span className={`mx-2 ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-[16px] text-white leading-5`}>
                {t('TEMPLATES')}
              </span>
            </div>
            <div className="flex">
              <span className={`${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-[12px] font-normal text-white leading-5`}>
                {t('RESULT')} ({templatesList?.length})
              </span>
            </div>
          </div>

          {isOpen && (
            <>
              {templatesList && templatesList?.length > 0 ? (
                <div className="flex flex-col  gap-4 p-4 w-full h-full overflow-y-scroll bg-textFieldColor rounded-b-[12px] has-scrollbar">
                  {templatesList?.map((item) => {
                    return (
                      <div className="flex flex-col   rounded-[12px] gap-2 p-2 bg-statusBg">
                        <div className="w-full h-4 flex justify-around">
                          <div
                            className="w-full  flex items-center space-x-2 rtl:space-x-reverse cursor-pointer relative"
                            onClick={() => handleClick(item)}
                          >
                            <div>
                              <img src={Images.Plus} alt="add" className={`w-3 h-3   text-center `} />
                            </div>
                            <h6
                              className={`text-sm text-white text-center  leading-4 font-medium ${
                                direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                              }`}
                            >
                              {item?.name}
                            </h6>
                          </div>
                          <div
                            className="w-full flex items-center justify-end space-x-2 rtl:space-x-reverse cursor-pointer relative"
                            onClick={() => toggleTemplate(item?.id)}
                          >
                            <img
                              src={activeTemplate?.includes(item?.id) ? Images.InfoColored : Images.InfoCircle}
                              alt="profile"
                              className={`w-4 h-4  rounded-full bg-cover text-center shadow-xl `}
                            />
                          </div>
                        </div>
                        <MuscleSummary muscleSummary={getSortedMuscle(item.percentage)} t={t} />
                        {activeTemplate?.includes(item?.id) && (
                          <div className="flex w-full  rounded-lg p-1">
                            <div className="flex flex-col w-full  gap-y-2 relative">
                              <p className="text-white ltr:font-primary-rg rtl:font-primary-he-rg text-sm ">
                                {t('EXERCISES')} {item?.exercises?.length}
                              </p>
                              <div className="flex w-full flex-col gap-y-2 cursor-pointer">
                                {item?.exercises?.map((exercise) => (
                                  <div className="flex w-full flex-row justify-between">
                                    <p className="text-white w-2/3 ltr:font-primary-rg rtl:font-primary-he-rg text-xs truncate">
                                      {exercise?.exerciseName}
                                    </p>
                                    <div className="flex w-1/3 justify-end">
                                      {exercise?.count !== 0 && (
                                        <div className={`flex py-1 w-14  items-start px-2 rounded-lg bg-lightGreen`}>
                                          <p className={`text-textFieldColor text-xs  ltr:font-primary-rg rtl:font-primary-he-rg`}>
                                            {t('DONE')}
                                            {exercise?.count}{' '}
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
              ) : (
                <div className="h-[50%] justify-center items-center ">
                  <p className={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-[24px] text-center `}>
                    {t('NO_DATA')}
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  )
}
