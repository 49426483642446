import { Images } from 'assets'

import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { getCustomerById } from 'store/userSlice/userActions'

import { handleLogout } from 'utils'
import { ProfileAvatar } from 'components/PlayBoard/ProfileAvatar'
import i18n from 'i18n'

const routes = [
  { title: 'MAIN', src: Images.Widget, url: '/trainee/dashboard' },
  { title: 'CALENDER', src: Images.CalendarIcon, url: '/trainee/calender' },
  { title: 'PROFILE', src: Images.MobileProfile, url: '/trainee/profile' },
]

const TraineeLayout = () => {
  const dispatch = useDispatch()
  const sidebarRef = useRef(null)
  const [isSidebarOpen, setSidebarOpen] = useState(false)
  const { user } = useSelector((state) => state.auth)

  const navigate = useNavigate()
  const location = useLocation()

  const { language, direction } = useSelector((state) => state.language)
  const { customerDetail } = useSelector((state) => state.user)

  const { t } = useTranslation()
  const [open, setOpen] = useState(true)
  const toggleSidebar = (e) => {
    e.stopPropagation()
    setSidebarOpen(!isSidebarOpen)
  }
  useEffect(() => {
    if (user) {
      dispatch(getCustomerById(user?.gym_Id, user?.id, user?.role, t, direction))
    }

    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    i18n.changeLanguage(language)
    document.body.setAttribute('dir', language === 'en' ? 'ltr' : 'rtl')
    i18n.reloadResources()
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (isSidebarOpen && sidebarRef.current && !sidebarRef.current.contains(e.target)) {
        setSidebarOpen(false)
      }
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [isSidebarOpen])

  const activeTab = async (URL) => {
    navigate(URL)
  }

  if (user?.role !== 'trainee') {
    return <Navigate to="/login" />
  }

  // Update CSS variables based on the direction
  if (direction === 'rtl') {
    document.documentElement.style.setProperty('--position-left', '0px')
    document.documentElement.style.setProperty('--position-right', 'auto')
  } else {
    document.documentElement.style.setProperty('--position-left', 'auto')
    document.documentElement.style.setProperty('--position-right', '0px')
  }
  return (
    <div className={`flex w-screen h-screen bg-primary relative ${isSidebarOpen ? 'scrim-behind-menu open' : ''}`}>
      {isSidebarOpen ? (
        <div
          className={`block sm:hidden transition-all ${isSidebarOpen ? 'block' : '-translate-x-full'} absolute h-full w-[248px] ${
            language === 'he' ? 'top-0 right-0' : 'top-0 left-0'
          } bg-checkBoxGray  shadow-2xl px-4 py-6 flex flex-col justify-between ltr:rounded-tr-[24px] rtl:rounded-tl-[24px]`}
          style={{ zIndex: 100 }}
          ref={sidebarRef}
        >
          <div className="">
            {open ? (
              <>
                <div className="flex flex-col space-y-2 py-2  items-center  pt-3 ">
                  <ProfileAvatar
                    image={customerDetail?.logo}
                    name={customerDetail?.gymName}
                    size={open ? 'xxxlarge' : 'xlarge'}
                    color={customerDetail?.User?.Coach?.color === 'BLUE' ? '#E5A772' : customerDetail?.User?.Coach?.color}
                    className={open && '!text-3xl'}
                  />
                  <h1 className={`${!open && 'hidden'} font-secondary-bd leading-4 text-[1.125rem] font-black text-[#C2C7CF] duration-200 mb-1 `}>
                    {customerDetail?.gymName}
                  </h1>
                  <div className={`flex justify-center mt-2`}>
                    <span
                      className={`${!open && 'hidden'} duration-200 text-[#C2C7CF] ${
                        direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                      }   text-[#C2C7CF] text-[10px] px-3`}
                    >
                      {t('POWEREDBY')}
                    </span>
                  </div>
                </div>
                <div className="w-full h-[1px] bg-[#32445F] mb-2 "></div>
              </>
            ) : (
              <>
                <div className={`${!open && ' flex  py-2  items-center bg-sidebarhover pt-3 justify-center'}`}>
                  <ProfileAvatar size={'large'} name={customerDetail?.gymName} image={customerDetail?.logo} />
                </div>
                <div className="w-full h-[1px] bg-[#32445F] mb-2 "></div>
              </>
            )}

            <ul className="pt-4">
              {routes.map((route, index) => (
                <li
                  key={index}
                  className={`flex h-14 p-2 cursor-pointer  rounded-xl hover:bg-[#006684] hover:rounded-md text-gray-300 text-sm items-center space-x-4 rtl:space-x-reverse ${
                    route.url === location.pathname && 'bg-[#006684]'
                  } `}
                  onClick={(e) => {
                    activeTab(route.url)
                    toggleSidebar(e)
                  }}
                >
                  <img alt="new-profile" src={route.src} className="h-6 w-6" />
                  {open && (
                    <span className={`${!open && 'hidden'} origin-left duration-200 text-[#C2C7CF] ltr:font-primary-rg rtl:font-primary-he-rg`}>
                      {t(route.title)}
                    </span>
                  )}
                </li>
              ))}
            </ul>
          </div>

          <div
            className={`flex flex-col w-[210px]    space-y-2  absolute  bottom-0  cursor-pointer z-50 overflow-hidden items-center justify-center duration-200 ease-in-out ${
              open && ' rounded-t-lg h-16 xl:h-16'
            }`}
          >
            <div className={`flex w-full  cursor-pointer  ${open ? ` justify-between flex-row ` : ' justify-center items-center flex-col'}  `}>
              <div className={`flex items-center space-x-2 rtl:space-x-reverse ${open && ` rounded-[12px] `}  `}>
                <ProfileAvatar
                  image={customerDetail?.User?.avatar}
                  name={customerDetail?.User?.firstName}
                  size={'large'}
                  color={customerDetail?.User?.Coach?.color === 'BLUE' ? '#E5A772' : customerDetail?.User?.Coach?.color}
                />

                <div className={`flex flex-col justify-center ${!open && 'hidden'} `}>
                  <span className={` origin-left duration-200 text-[14px] text-[#C2C7CF]`}>{customerDetail?.User?.firstName}</span>
                </div>
              </div>
              <div
                className={`flex  cursor-pointer h-[3rem] text-gray-300 text-sm items-center  duration-200 ease-in-out `}
                onClick={() => {
                  handleLogout(dispatch, navigate)
                }}
              >
                <img src={Images.logout} width={20} height={20} alt="" className=" ltr:rotate-180" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={`block sm:hidden absolute ${language === 'he' ? 'top-9 right-4' : 'top-9 left-4'}`} style={{ zIndex: 90 }}>
          {!location.pathname.includes('/trainee/workout') && !location.pathname.includes('/trainee/profile') && (
            <img src={Images.MenuIcon} alt="" height={24} width={24} className={`items-center cursor-pointer`} onClick={(e) => toggleSidebar(e)} />
          )}
        </div>
      )}
      <div className={`hidden sm:flex ${open ? 'w-64' : 'w-20'} bg-sidebar h-screen   relative duration-300 z-10 space-y-6 flex-col justify-between`}>
        <div>
          <img
            src={Images.rightArrow}
            alt="new-profile"
            className={`absolute cursor-pointer ${language === 'he' ? '-left-3' : '-right-3'} top-14 w-6 border-dark-purple border-2 rounded-full ${
              open ? 'rotate-180 rtl:rotate-0' : 'rotate-0 rtl:rotate-180'
            }`}
            onClick={() => setOpen(!open)}
          />

          {open ? (
            <>
              <div className="flex flex-col space-y-2 py-2  items-center  pt-3 ">
                <ProfileAvatar
                  image={customerDetail?.logo}
                  name={customerDetail?.gymName}
                  size={open ? 'xxxlarge' : 'xlarge'}
                  color={customerDetail?.User?.Coach?.color === 'BLUE' ? '#E5A772' : customerDetail?.User?.Coach?.color}
                  className={open && '!text-3xl'}
                />
                <h1 className={`${!open && 'hidden'} font-secondary-bd leading-4 text-[1.125rem] font-black text-[#C2C7CF] duration-200 mb-1 `}>
                  {customerDetail?.gymName}
                </h1>
                <div className={`flex justify-center mt-2`}>
                  <span
                    className={`${!open && 'hidden'} duration-200 text-[#C2C7CF] ${
                      direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                    }   text-[#C2C7CF] text-[10px] px-3`}
                  >
                    {t('POWEREDBY')}
                  </span>
                </div>
              </div>
              <div className="w-full h-[1px] bg-[#32445F] mb-2 "></div>
            </>
          ) : (
            <>
              <div className={`${!open && ' flex  py-2  items-center bg-sidebarhover pt-3 justify-center'}`}>
                <ProfileAvatar size={'large'} name={customerDetail?.gymName} image={customerDetail?.logo} />
              </div>
              <div className="w-full h-[1px] bg-[#32445F] mb-2 "></div>
            </>
          )}

          <ul className="pt-4 p-5">
            {routes.map((route, index) => (
              <li
                key={index}
                className={`flex mt-2 p-2 cursor-pointer rounded-md hover:bg-sidebarhover hover:rounded-md text-gray-300 text-sm items-center space-x-4 rtl:space-x-reverse ${
                  route.url === location.pathname && 'bg-sidebarhover'
                } `}
                onClick={() => activeTab(route.url)}
              >
                <img alt="new-profile" src={route.src} className="h-6 w-6" />
                {open && <span className={`${!open && 'hidden'} origin-left duration-200`}>{t(route.title)}</span>}
              </li>
            ))}
          </ul>
        </div>

        <div
          className={`flex flex-col   w-full  absolute  bottom-0  cursor-pointer z-50 overflow-hidden items-center justify-center duration-200 ease-in-out ${
            open && ' rounded-t-lg h-16 xl:h-16'
          }  p-2`}
        >
          <div className={`flex  w-full cursor-pointer  ${open ? ` justify-between flex-row ` : ' justify-center items-center flex-col'}  `}>
            <div className={`flex items-center space-x-2 rtl:space-x-reverse ${open && ` rounded-[12px] `}  `}>
              <ProfileAvatar
                image={customerDetail?.User?.avatar}
                name={customerDetail?.User?.firstName}
                size={'large'}
                color={customerDetail?.User?.Coach?.color === 'BLUE' ? '#E5A772' : customerDetail?.User?.Coach?.color}
              />

              <div className={`flex flex-col justify-center ${!open && 'hidden'} `}>
                <span className={` origin-left duration-200 text-[14px] text-[#C2C7CF]`}>{customerDetail?.User?.firstName}</span>
              </div>
            </div>
            <div
              className={`flex  cursor-pointer h-[3rem] text-gray-300 text-sm items-center  duration-200 ease-in-out `}
              onClick={() => {
                handleLogout(dispatch, navigate)
              }}
            >
              <img src={Images.logout} width={20} height={20} alt="" className=" ltr:rotate-180" />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full overflow-auto ">
        <Outlet />
      </div>
    </div>
  )
}

export default TraineeLayout
