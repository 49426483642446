import { useEffect, useState, useRef } from 'react'
import { Images } from 'assets'
import { SearchInput } from 'components/compounds/SearchInput'
import PrimaryButton from 'components/elements/PrimaryButton'
import { useDebounce } from 'hooks'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getAllTemplate, getTemplateFilter } from 'store/templateSlice/templateActions'
import { useOutsideClick } from 'hooks'
import { handleFilterSelection } from 'utils'
import { constants } from 'constant'
export default function TemplatesLibraryListHeader({ active }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { templateOptions } = constants
  const { direction } = useSelector((state) => state.language)
  const { customerDetail } = useSelector((state) => state.user)
  const [searchInput, setSearchInput] = useState('')

  const filtersRef = useRef()
  const [filtersMenu, setFiltersMenu] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState('')
  const [activeFilter, setActiveFilter] = useState()
  const [selectedFilterMenu, setSelectedFilterMenu] = useState(false)
  const { templateFilter } = useSelector((state) => state?.template)
  const [isMuscles, setIsMuscle] = useState(false)
  const navigate = useNavigate()
  const [temp1, setTemp1] = useState({
    MUSCLES: [],
    COACHES: [],
    MUSCLE_TYPE: [],
  })

  const debouncedSearchTerm = useDebounce(searchInput, 500)

  const setSearch = (event) => {
    setSearchInput(event.target.value)
  }
  useEffect(() => {
    const getData = async () => {
      dispatch(getTemplateFilter(customerDetail?.id))
    }
    getData()
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    const getTemplates = async () => {
      if (temp1?.MUSCLES?.length > 0 || temp1?.COACHES?.length > 0 || temp1?.MUSCLE_TYPE?.length > 0) {
        dispatch(getAllTemplate(customerDetail.id, null, t, direction, { search: debouncedSearchTerm, filter: temp1 }))
      } else if (debouncedSearchTerm) {
        dispatch(getAllTemplate(customerDetail.id, null, t, direction, { search: debouncedSearchTerm, filter: temp1 }))
      } else {
        dispatch(getAllTemplate(customerDetail.id, null, t, direction))
      }
    }
    getTemplates()
    // eslint-disable-next-line
  }, [debouncedSearchTerm, temp1])
  const onClickOutsideFiltersDiv = () => {
    setFiltersMenu(false)
    setSelectedFilterMenu(false)
    setIsMuscle(false)
  }
  useOutsideClick(filtersRef, onClickOutsideFiltersDiv)
  return (
    <div className="flex w-full flex-col">
      <div className="flex flex-row w-full px-8 py-3 justify-between items-center">
        <div className="text-white text-[24px]">{t('TEMPLATE_LIBRARY')}</div>
        <PrimaryButton
          text={t('CREATE_NEW')}
          type="submit"
          disabled={false}
          isIcon={true}
          icon={Images.Plus}
          onClick={() => navigate({ pathname: `/admin/createTemplate`, search: `?active=${active}` })}
          className="w-[177px] h-[44px] rounded-[12px] shadow-2xl "
          bgColor="bg-sidebarhover hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
          textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
          wid={15}
          ht={15}
        />
      </div>
      <div className="flex flex-row w-full px-8 py-2 gap-4 items-center z-20">
        <SearchInput
          radius={'rounded-[50px]'}
          placeholder={t('SEARCH')}
          className="flex flex-1"
          disableUnderline
          fullWidth
          value={searchInput}
          inputProps={{
            'aria-label': 'Search',
          }}
          onChange={(ev) => setSearch(ev)}
          icon={Images.Search}
        />
        <div ref={filtersRef} className="relative box-border flex-row flex space-x-2">
          <div
            className={`flex flex-row w-[156px] h-[40px] items-center rounded-[12px] bg-primary
            border-borderActive border-[1.5px]`}
            onClick={() => {
              setFiltersMenu(!filtersMenu)
              setSelectedFilterMenu(false)
            }}
          >
            <img src={Images.Filter} alt="" height={40} width={45} className={`p-3 items-center `} onClick={() => {}} />
            <p className={`text-white text-center text-[14px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>
              {t('ADDING_FILTER')}
            </p>
          </div>
          {filtersMenu ? (
            <div
              className={`absolute top-11 py-3 bg-statusBg rounded-[12px] w-[156px] max-h-[200px] overflow-auto shadow-xl ${
                filtersMenu ? 'border-borderActive border-[2px]' : ''
              }`}
            >
              {templateOptions?.map((opt, i) => (
                <p
                  key={i}
                  className={`px-2 py-1 text-textGrey text-[12px] ${
                    direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                  } hover:bg-checkoutHover cursor-pointer`}
                  onClick={() => {
                    if (opt === 'COACHES') {
                      setActiveFilter(templateFilter?.COACHES)
                    } else if (opt === 'MUSCLES') {
                      setActiveFilter(templateFilter?.MUSCLES)
                    }
                    setSelectedFilter(opt)
                    setFiltersMenu(false)
                    setSelectedFilterMenu(true)
                  }}
                >
                  {t(opt)}
                </p>
              ))}
            </div>
          ) : null}

          {/* Main Selected Filter */}
          {selectedFilterMenu ? (
            <div
              className={`absolute top-11 py-3 bg-statusBg rounded-[12px] w-[156px] max-h-[200px] overflow-auto shadow-xl ${
                selectedFilterMenu ? 'border-borderActive border-[2px]' : ''
              }`}
            >
              <div
                className="flex flex-row hover:bg-checkoutHover cursor-pointer"
                onClick={() => {
                  setSelectedFilterMenu(false)
                  setFiltersMenu(true)
                }}
              >
                <img src={direction === 'rtl' ? Images.backArrow : Images.backLessArrow} alt="" height={8} width={25} className={'p-1'} dir="ltr" />
                <p className={` py-1 text-textGrey text-[12px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} `}>
                  {t(selectedFilter)}
                </p>
              </div>
              {activeFilter?.map((opt, i) => (
                <div
                  key={i}
                  className={`flex flex-row px-2 py-1  justify-between ${
                    direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                  } hover:bg-checkoutHover cursor-pointer`}
                  dir={direction}
                  onClick={() => {
                    if (selectedFilter === 'MUSCLES') {
                      if (!temp1['MUSCLES'].includes(opt.label)) {
                        setSelectedFilterMenu(false)
                        setIsMuscle(opt)
                      }
                    }
                    handleFilterSelection(selectedFilter, opt, temp1, setTemp1)
                  }}
                >
                  <p
                    className={`text-[12px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} ${
                      temp1?.COACHES?.includes(opt.label) || temp1?.MUSCLES?.includes(opt.label) ? 'text-activeText' : 'text-textGrey'
                    } `}
                  >
                    {t(opt.label)}
                  </p>
                  <p
                    className={`text-[12px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} ${
                      temp1?.COACHES.includes(opt.label) || temp1?.MUSCLES.includes(opt.label) ? 'text-activeText' : 'text-textGrey'
                    } `}
                  >
                    {t(opt.count)}
                  </p>
                </div>
              ))}
            </div>
          ) : null}

          {!selectedFilterMenu && isMuscles ? (
            <div
              className={`absolute top-11 py-3 bg-statusBg rounded-[12px] w-[156px] max-h-[200px] overflow-auto shadow-xl ${
                selectedFilterMenu ? 'border-borderActive border-[2px]' : ''
              }`}
            >
              <div
                className="flex flex-row hover:bg-checkoutHover cursor-pointer"
                onClick={() => {
                  setSelectedFilterMenu(true)
                  setIsMuscle(false)
                }}
              >
                <img src={direction === 'rtl' ? Images.backArrow : Images.backLessArrow} alt="" height={8} width={25} className={'p-1'} dir="ltr" />
                <p className={`py-1 text-textGrey text-[12px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} `}>
                  {t(isMuscles?.label)}
                </p>
              </div>

              <div className="flex flex-col ">
                <div
                  className={`flex flex-row px-2 py-1  justify-between ${
                    direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                  } hover:bg-checkoutHover cursor-pointer `}
                  dir={direction}
                  onClick={() => {
                    handleFilterSelection('MUSCLE_TYPE', isMuscles, temp1, setTemp1, 'primary')
                  }}
                >
                  <p
                    className={`text-[12px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} ${
                      temp1.MUSCLE_TYPE.some((obj) => obj.label === isMuscles.label && obj.isPrimary === true) ? 'text-activeText' : 'text-textGrey'
                    } `}
                  >
                    {t('Primary')}
                  </p>
                  <p
                    className={`text-[12px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} ${
                      temp1.MUSCLE_TYPE.some((obj) => obj.label === isMuscles.label && obj.isPrimary === true) ? 'text-activeText' : 'text-textGrey'
                    } `}
                  >
                    {isMuscles.primary}
                  </p>
                </div>
                <div
                  className={`flex flex-row px-2 py-1  justify-between ${
                    direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                  } hover:bg-checkoutHover cursor-pointer `}
                  dir={direction}
                  onClick={() => {
                    handleFilterSelection('MUSCLE_TYPE', isMuscles, temp1, setTemp1, 'assitant')
                  }}
                >
                  <p
                    className={`text-[12px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} ${
                      temp1.MUSCLE_TYPE.some((obj) => obj.label === isMuscles.label && obj.isAssistance === true)
                        ? 'text-activeText'
                        : 'text-textGrey'
                    } `}
                  >
                    {t('Assistent')}
                  </p>
                  <p
                    className={`text-[12px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} ${
                      temp1.MUSCLE_TYPE.some((obj) => obj.label === isMuscles.label && obj.isAssistance === true)
                        ? 'text-activeText'
                        : 'text-textGrey'
                    } `}
                  >
                    {isMuscles.isAssistance}
                  </p>
                </div>
              </div>
            </div>
          ) : null}
          {/* eslint-disable-next-line */}
          {Object.keys(temp1).map((value, index) => {
            if (value !== 'MUSCLE_TYPE') {
              return (
                temp1[value] &&
                temp1[value].length > 0 &&
                temp1[value].map((item) => {
                  return (
                    <div className="flex flex-row items-center  gap-1 bg-statusBg px-3 py-1 rounded-[16px] hover:border-borderActive hover:border-[2px]">
                      <p className={`text-[14px] text-textGrey  ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>{t(item)}</p>
                      <img
                        src={Images.CloseCircle}
                        alt=""
                        height={10}
                        width={15}
                        className={`ml-1 mr-2 cursor-pointer lg:mr-0 lg:ml-0 xl:mr-0 xl:ml-0 `}
                        onClick={() => {
                          if (index === 0) {
                            const updatedItems = temp1['MUSCLES'].filter((i) => i !== item)
                            setTemp1({
                              ...temp1,
                              MUSCLES: updatedItems,
                            })
                          } else if (index === 1) {
                            const updatedItems = temp1['COACHES'].filter((i) => i !== item)
                            setTemp1({
                              ...temp1,
                              COACHES: updatedItems,
                            })
                          }
                        }}
                      />
                    </div>
                  )
                })
              )
            }
          })}
        </div>
      </div>
    </div>
  )
}
