import React, { useState } from 'react'
import { useSelector } from 'react-redux'

const MuscleSummary = ({ muscleSummary, t }) => {
  const [showMusclesToolTip, setShowMusclesToolTip] = useState(false)
  const { direction } = useSelector((state) => state.language)
  const toggleMuscleToolTip = () => setShowMusclesToolTip(!showMusclesToolTip)

  const isMusclesAreMoreThanThree = muscleSummary?.length > 3
  const isThreeMuscles = muscleSummary?.length === 3
  const isTwoMuscles = muscleSummary?.length === 2
  const isNoMuscles = muscleSummary?.length === 0

  if (isNoMuscles) {
    return (
      <div className="flex w-full h-12">
        <div className={`w-full h-full flex flex-col justify-center px-2 bg-[#70ACBF] rounded-lg`}>
          <p className={`text-[#001f2a] text-xs font-semibold truncate ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>
            {t('NO_MUSCLE_TO_SHOW')}
          </p>
        </div>
      </div>
    )
  }
  if (isMusclesAreMoreThanThree) {
    return (
      <div className="flex w-full h-12">
        <div
          className={`w-1/3 h-full flex flex-col justify-center px-2 bg-[#70ACBF] rounded-l-lg rounded-r-none rtl:rounded-l-none rtl:rounded-r-lg`}
        >
          <p className={`text-[#001f2a] text-xs font-semibold truncate ${direction === 'ltr' ? 'font-primary-md' : 'font-primary-he-md'}`}>
            {muscleSummary[0]?.muscleName}
          </p>
          <p
            className={`text-[#001f2a] text-xs  ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
          >{`${muscleSummary[0]?.percentage}%`}</p>
        </div>
        <div className={`w-1/3 h-full flex flex-col justify-center px-2 bg-[#E5A772]`}>
          <p className={`text-[#001f2a] text-xs  font-semibold truncate ${direction === 'ltr' ? 'font-primary-md' : 'font-primary-he-md'}`}>
            {muscleSummary[1]?.muscleName}
          </p>
          <p
            className={`text-[#001f2a] text-xs ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
          >{`${muscleSummary[1]?.percentage}%`}</p>
        </div>
        <div
          className={`w-1/3 h-full flex flex-col justify-center px-2 bg-[#8C9198] rounded-l-none rounded-r-lg rtl:rounded-l-lg rtl:rounded-r-none cursor-pointer relative`}
          onMouseEnter={toggleMuscleToolTip}
          onMouseLeave={toggleMuscleToolTip}
        >
          <p className={`text-[#001f2a]  font-semibold text-xs ${direction === 'ltr' ? 'font-primary-md' : 'font-primary-he-md'}`}>{`${t(
            'MUSCLES'
          )} ${muscleSummary?.length - 2}+`}</p>
          <p className={`text-[#001f2a] text-xs ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>38.6%</p>

          {showMusclesToolTip && (
            <div className="absolute top-12 left-1/2 transform -translate-x-1/2 p-2 bg-templateText text-black rounded-lg z-50">
              <div className="tooltip-pointer-bottom absolute w-0 h-0 border-transparent border-4 pointer-events-none" />
              {muscleSummary.slice(2).map((item, index) => (
                <h6 key={index} className={`text-xs whitespace-nowrap ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>
                  {`${item.percentage}% ${item.muscleName} `}
                </h6>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  } else if (isThreeMuscles) {
    return (
      <div className="flex w-full h-12">
        <div
          className={`w-1/3 h-full flex flex-col justify-center px-2 bg-[#70ACBF] rounded-l-lg rounded-r-none rtl:rounded-l-none rtl:rounded-r-lg`}
        >
          <p className={`text-[#001f2a] text-xs  font-semibold ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} truncate`}>
            {muscleSummary[0]?.muscleName}
          </p>
          <p
            className={`text-[#001f2a] text-xs ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
          >{`${muscleSummary[0]?.percentage}%`}</p>
        </div>
        <div className={`w-1/3 h-full flex flex-col justify-center px-2 bg-[#E5A772]`}>
          <p className={`text-[#001f2a] text-xs  font-semibold ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} truncate`}>
            {muscleSummary[1]?.muscleName}
          </p>
          <p
            className={`text-[#001f2a] text-xs ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
          >{`${muscleSummary[1]?.percentage}%`}</p>
        </div>
        <div
          className={`w-1/3 h-full flex flex-col  font-semibold justify-center px-2 bg-[#8C9198] rounded-l-none rounded-r-lg rtl:rounded-l-lg rtl:rounded-r-none`}
        >
          <p className={`text-[#001f2a] text-xs ${direction === 'ltr' ? 'font-primary-md' : 'font-primary-he-md'} truncate`}>
            {muscleSummary[2]?.muscleName}
          </p>
          <p
            className={`text-[#001f2a] text-xs ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
          >{`${muscleSummary[2]?.percentage}%`}</p>
        </div>
      </div>
    )
  } else if (isTwoMuscles) {
    return (
      <div className="flex w-full h-12">
        <div
          className={`w-1/2 h-full flex flex-col justify-center px-2 bg-[#70ACBF] rounded-l-lg rounded-r-none rtl:rounded-l-none rtl:rounded-r-lg`}
        >
          <p className={`text-[#001f2a] text-xs  font-semibold ${direction === 'ltr' ? 'font-primary-md' : 'font-primary-he-md'} truncate`}>
            {muscleSummary[0]?.muscleName}
          </p>
          <p
            className={`text-[#001f2a] text-xs ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
          >{`${muscleSummary[0]?.percentage}%`}</p>
        </div>
        <div
          className={`w-1/2 h-full flex flex-col justify-center  px-2 bg-[#E5A772] rounded-l-none rounded-r-lg rtl:rounded-l-lg rtl:rounded-r-none`}
        >
          <p className={`text-[#001f2a] text-xs   font-semibold ${direction === 'ltr' ? 'font-primary-md' : 'font-primary-he-md'} truncate`}>
            {muscleSummary[1]?.muscleName}
          </p>
          <p
            className={`text-[#001f2a] text-xs ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
          >{`${muscleSummary[1]?.percentage}%`}</p>
        </div>
      </div>
    )
  } else {
    return (
      <div className="flex w-full h-12">
        {muscleSummary?.length > 0 && (
          <div className={`w-full h-full flex flex-col justify-center px-2 bg-[#70ACBF] rounded-lg`}>
            <p className={`text-[#001f2a]  font-semibold text-xs ${direction === 'ltr' ? 'font-primary-md' : 'font-primary-he-md'} truncate`}>
              {muscleSummary[0]?.muscleName}
            </p>
            <p
              className={`text-[#001f2a] text-xs ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
            >{`${muscleSummary[0]?.percentage}%`}</p>
          </div>
        )}
      </div>
    )
  }
}

export { MuscleSummary }
