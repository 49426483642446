import { Images } from 'assets'
import { Modal } from 'components/compounds/Modal'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ListTable from 'components/List/ListTable'
import { Loader } from 'components/compounds/Loader'
import { SearchInput } from 'components/compounds/SearchInput'
import OutlineButton from 'components/elements/OutlineButton'
import { useDebounce, useOnClickOutside } from 'hooks'
import { addNewWorkout, searchTrainee } from 'store/workoutSlice/workoutActions'
import { useDispatch } from 'react-redux'
import { getWorkoutByTraineeID } from 'store/workoutSlice/workoutActions'
import Avatar from 'components/compounds/Avatar'
import { ProfileAvatar } from './ProfileAvatar'
import moment from 'moment'
import DeleteModal from 'components/compounds/DeleteModal'

export default function PlayWorkoutAddEvent({ show, setShow }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const divRef = useRef()

  useOnClickOutside(divRef, () => setShow(false))
  const [searchInput, setSearchInput] = useState('')
  const debouncedSearchTerm = useDebounce(searchInput, 500)
  const { direction } = useSelector((state) => state.language)
  const { loading } = useSelector((state) => state.workout)

  const [selectedTrainee, setSelectedTrainee] = useState(null)
  const [traineeDetail, setTraineeDetail] = useState(null)
  const [trainees, setTrainees] = useState([])
  const [addEventModal, setAddEventModal] = useState(false)

  const { customerDetail } = useSelector((state) => state.user)

  const gymId = customerDetail.id

  const handleClose = () => {
    setSearchInput('')
    setSelectedTrainee(null)
    setTraineeDetail(null)
    setTrainees([])
    setShow(false)
  }

  const setTraineeDetailAction = (data) => {
    setTraineeDetail(data)
  }

  useEffect(() => {
    const getTrainees = async () => {
      if (debouncedSearchTerm) {
        setSelectedTrainee(null)
        const action = (data) => {
          setTrainees(data)
        }
        dispatch(searchTrainee(gymId, debouncedSearchTerm, action, t, direction))
      } else {
        const action = (data) => {
          setTrainees(data)
        }
        dispatch(searchTrainee(gymId, null, action, t, direction))
      }
    }
    getTrainees()
    // eslint-disable-next-line
  }, [show, debouncedSearchTerm])

  const setSearch = (event) => {
    setSelectedTrainee(null)
    setTraineeDetail(null)
    setTrainees([])
    setSearchInput(event.target.value)
  }
  const action = () => {
    setSearchInput('')
    // setTrainees([])
    setTraineeDetail(null)
    setSelectedTrainee(null)
    setAddEventModal(false)
  }

  const handleAdd = (eventId, CoachId) => {
    // const payload = {
    //   traineeId: selectedTrainee?.Trainee?.id,
    //   coachId: customerDetail?.User?.Coach?.id,
    //   eventId: eventId,
    // }
    const payload = {
      traineeId: selectedTrainee?.Trainee?.id,
      loggedCoachId: customerDetail?.User?.Coach?.id,
      coachId: CoachId,
      eventId: eventId,
    }
    if (CoachId === customerDetail?.User?.Coach?.id) {
      dispatch(addNewWorkout(payload, customerDetail?.User?.Coach?.id, action, t, direction))
    } else {
      setAddEventModal(payload)
    }

    // const action = () => {
    //   setSearchInput('')
    //   // setSelectedTrainee(null)
    //   // setTraineeDetail(null)
    //   // setTrainees([])
    //   // setShow(false)
    // }
    // dispatch(addNewWorkout(payload, customerDetail?.User?.Coach?.id, action, t, direction))
  }

  const headerRows = {
    data: [
      {
        id: 'Event',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'custom',
        disablePadding: false,
        label: 'NEXT_WORKOUTS',
        sort: true,
        CustomComponent: ({ item }) => {
          return (
            <div className="flex justify-start items-center text-white text-[12px] leading-5 font-primary-rg gap-1">
              <p>{t(moment(item?.date).format('ddd'))}</p>{' '}
              <p className="text-[#686f77] ">
                {' '}
                {moment(item?.date).format('DD')} {t(moment(item?.date).format('MMM'))} • {item?.startHour.slice(0, -3)}
              </p>
            </div>
          )
        },
      },
      {
        id: 'workoutName',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'custom',
        disablePadding: false,
        label: 'WORKOUT_NAME',
        sort: true,
        CustomComponent: ({ item }) => {
          return (
            <div className="flex items-center text-white text-[12px] leading-5 font-primary-rg">
              {item}-{traineeDetail?.workouts[0].programName}
            </div>
          )
        },
      },
      {
        id: 'Event',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'custom',
        disablePadding: false,
        label: 'COACH',
        sort: true,
        CustomComponent: ({ item }) => {
          return (
            <div className="flex rounded-[50px] w-7 h-7 justify-center items-center overflow-hidden">
              {item?.Coach?.User?.avatar ? (
                <img src={item?.Coach?.User?.avatar} alt="" height={32} width={32} className={`items-center cursor-pointer`} onClick={() => {}} />
              ) : (
                <Avatar name={item?.Coach?.User?.first_name} width={20} height={20} size={10} />
              )}
            </div>
          )
        },
      },
      {
        id: '',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'action',
        disablePadding: false,
        label: '',
        sort: true,
        actionText: 'ADD',
      },
    ],
  }

  const FooterComponent = (direction) => {
    return (
      <div className="flex flex-row h-16 items-center px-4 justify-end xl:px-6 2xl:px-12 border-t-[1px] border-borderColor absolute bottom-0 w-full bg-sidebar">
        <OutlineButton
          text={t('CLOSE')}
          type="submit"
          className="w-[80px] h-[38px] rounded-[12px] shadow-2xl"
          bgColor="hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed rounded-[12px] cursor-pointer bg-sidebar border-borderActive border-[1.5px]"
          textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
          wid={20}
          ht={20}
          onClick={handleClose}
        />
      </div>
    )
  }

  const handleTraineeSelect = (data) => {
    setSelectedTrainee(data)
    dispatch(getWorkoutByTraineeID(data?.Trainee?.id, setTraineeDetailAction, t, direction))
  }
  const getCount = () => {
    let totalCount = 0
    let completedCount = 0
    traineeDetail?.statusCounts?.forEach((item) => {
      if (item.status !== 'completed') {
        totalCount += parseInt(item.count, 10)
      } else {
        completedCount = parseInt(item.count, 10)
        totalCount += parseInt(item.count, 10)
      }
    })
    return `${completedCount} / ${totalCount}`
  }

  return (
    <>
      <Modal
        className={`h-full w-[480px] ${direction === 'ltr' ? 'rounded-l-3xl' : 'rounded-r-3xl'} xl:w-[710px]    `}
        childrenClass=""
        open={show}
        title={t('ADD_EVENT')}
        handleClose={handleClose}
        handleSubmit={() => {}}
        loader={loading}
        // isEdited={false}
        // disabled={false}
        direction={direction}
        footer={<FooterComponent direction={direction} />}
      >
        <div className="h-full overflow-hidden">
          {/* ---------------- Search Bar --------------------- */}
          <div className="flex flex-row w-full  items-center pt-5 xl:pt-7">
            <SearchInput
              radius={'rounded-[50px]'}
              placeholder={t('SEARCH')}
              className="flex flex-1 w-full"
              area="w-full h-[35px]"
              disableUnderline
              fullWidth
              value={searchInput}
              inputProps={{
                'aria-label': 'Search',
              }}
              onChange={(ev) => setSearch(ev)}
              icon={Images.Search}
            />
          </div>
          {loading ? (
            <Loader className="" size="w-8 h-8" fill="#003353" />
          ) : (
            <>
              {/* ---------------- Trainee Data --------------------- */}
              {selectedTrainee === null ? (
                <div className="flex flex-col w-full bg-secondaryBackgroundContrast rounded-xl cursor-pointer overflow-hidden my-2">
                  {trainees.length > 0 &&
                    trainees?.map((item) => (
                      <div
                        className="flex w-full p-2 gap-2 items-center hover:bg-[#3a4952]"
                        key={item?.Trainee?.id}
                        onClick={() => {
                          handleTraineeSelect(item)
                        }}
                      >
                        <ProfileAvatar image={item?.avatar ? item?.avatar : Images.Avatar} name={item?.first_name} />
                        <div className="flex flex-col bg-red">
                          <p className="font-primary-rg text-[16px] leading-5 font-normal text-white">{item?.first_name}</p>
                          <p className="font-primary-rg text-[12px] leading-4 font-thin text-white">{item?.email}</p>
                        </div>
                      </div>
                    ))}
                </div>
              ) : (
                <>
                  {!traineeDetail !== null && (
                    <div className="flex flex-col h-full w-full my-2 ">
                      <div className="flex flex-row w-full justify-between items-center">
                        <div className="flex items-center gap-1">
                          <div className="flex rounded-[50px] w-7 h-7 justify-center items-center overflow-hidden">
                            <img
                              src={selectedTrainee?.avatar ? selectedTrainee?.avatar : Images.Avatar}
                              alt=""
                              height={27}
                              width={27}
                              className={`items-center cursor-pointer`}
                              onClick={() => {}}
                            />
                          </div>
                          <p className="font-primary-rg text-[16px] leading-5 font-normal text-white">{selectedTrainee?.first_name}</p>
                        </div>
                        <p className=" font-primary-rg text-[14px] leading-5 font-normal text-[#C2C7CF]">
                          {t('WORKOUTS')} {getCount()}
                        </p>
                      </div>
                      <div className="flex h-[76%] xl:h-[85%] overflow-scroll justify-center">
                        {traineeDetail && traineeDetail?.workouts?.length > 0 ? (
                          <ListTable
                            headerRows={headerRows}
                            data={traineeDetail?.workouts[0]?.Workouts}
                            loading={false}
                            tableClassName={'pb-32'}
                            padding={false}
                            action={handleAdd}
                          />
                        ) : (
                          <div className="flex h-full justify-center items-center ">
                            <p className="text-white font-primary-rg text-[14px] md:text-[24px] text-center ">{t('NO_TRIANEE_EVENT')}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </Modal>
      <DeleteModal
        setOpenModal={setAddEventModal}
        openModal={addEventModal}
        t={t}
        direction={direction}
        des={t('ADD_EVENT_DESCRIPTION')}
        title={t('ADD_EVENT_TITLE')}
        handleSubmit={() => {
          const payload = {
            ...addEventModal,
            justDisplay: true,
          }

          dispatch(addNewWorkout(payload, customerDetail?.User?.Coach?.id, action, t, direction))
        }}
        handleCancel={() => {
          const payload = {
            ...addEventModal,
            justDisplay: false,
          }

          dispatch(addNewWorkout(payload, customerDetail?.User?.Coach?.id, action, t, direction))
        }}
        showPass={false}
        confirmText={t('NO_JUST_DISPLAY')}
        cancelText={t('Yes')}
        primary={true}
      />
    </>
  )
}
