import { useEffect, useRef, useState } from 'react'

const NotesInput = ({ required, value, setValue, fullWidth, className, disable, label, placeholderValue }) => {
  const passwordRef = useRef(null)

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur()

    e.stopPropagation()
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  const [isInputFocused, setIsInputFocused] = useState(false)

  const handleInputFocus = () => {
    setIsInputFocused(true)
  }

  const handleInputBlur = () => {
    setIsInputFocused(false)
  }
  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (passwordRef.current && !passwordRef.current.contains(event.target)) {
        setIsInputFocused(false)
      }
    }
    document.addEventListener('click', handleDocumentClick)
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])

  return (
    <>
      <div
        className={`flex flex-row items-center h-[56px] w-full my-[0.40rem] rounded-[12px] bg-textFieldColor ${disable && 'opacity-50'} ${
          isInputFocused && 'border-[1px] border-[#006684]'
        }`}
      >
        <div className={`flex flex-col w-full h-full rounded-[12px] bg-textFieldColor`}>
          <label
            className={`flex font-primary-rg rounded-t-[12px] text-[12px] bg-textFieldColor px-2 pt-1 ${
              isInputFocused ? 'text-[#65B1F4] pt-1' : 'text-white'
            }`}
          >
            {label}
          </label>
          {!disable ? (
            <input
              onChange={(event) => {
                setValue(event.target.value)
              }}
              value={value}
              className={`w-full bg-textFieldColor text-white px-2 text-[12px] rounded-b-[12px] ${className} `}
              label={label}
              type={'text'}
              placeholder={placeholderValue && !isInputFocused ? placeholderValue : ''}
              required={required}
              fullWidth={fullWidth}
              disabled={disable}
              onWheel={numberInputOnWheelPreventChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
            />
          ) : (
            <input
              onChange={(event) => {
                setValue(event.target.value)
              }}
              value={value}
              className={`w-full bg-textFieldColor text-white px-2 text-[12px] rounded-b-[12px] ${className} `}
              label={label}
              type={'text'}
              placeholder={placeholderValue && !isInputFocused ? placeholderValue : ''}
              required={required}
              fullWidth={fullWidth}
              readOnly
            />
          )}
        </div>
      </div>
    </>
  )
}

export { NotesInput }
