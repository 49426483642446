import { Loader } from 'components/compounds/Loader'
import { Modal } from 'components/compounds/Modal'
import OutlineButton from 'components/elements/OutlineButton'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { exerciseDoneDate, getResistanceLabel } from 'utils'

export default function ProgramStatisticExercisePlayedHistory({ show, setShow }) {
  const { t } = useTranslation()
  const { direction } = useSelector((state) => state.language)
  const { loader, selectedExerciseHistory } = useSelector((state) => state.program)
  const { firstRecord, workouts, bestSetFromAllProgramOfTarinee, firstBestSet } = selectedExerciseHistory
  const handleClose = () => {
    setShow(false)
  }

  const FooterComponent = (direction) => {
    return (
      <div className="flex flex-row h-14 items-center px-4 justify-end xl:px-6 2xl:px-12 border-t-[1px] border-borderColor  bg-sidebar ios-position w-[360px] lg:w-[940px]">
        <OutlineButton
          text={t('CLOSE')}
          type="submit"
          className=" w-[80px] h-[38px] rounded-[12px] shadow-2xl"
          bgColor=" hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed rounded-[12px] cursor-pointer bg-sidebar"
          textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
          wid={20}
          ht={20}
          onClick={handleClose}
        />
      </div>
    )
  }

  return (
    <Modal
      className={`h-full  ${direction === 'ltr' ? 'rounded-l-3xl' : 'rounded-r-3xl'} w-[360px] lg:w-[940px]`}
      childrenClass="overflow-hidden"
      open={show}
      title={`${t('Program Performance')}: ${show?.exerciseName}`}
      handleClose={handleClose}
      handleSubmit={() => {}}
      loader={loader}
      isEdited={false}
      disabled={false}
      direction={direction}
      footer={<FooterComponent direction={direction} />}
      padding={false}
    >
      {loader ? (
        <Loader className="" size="w-8 h-8" fill="#003353" />
      ) : (
        <div className=" h-full overflow-hidden relative ">
          {/* ---------------- Performance Bar --------------------- */}
          <div className="flex flex-col w-full justify-between gap-4 pt-4">
            <p className="flex text-textLightGray text-xl ltr:font-primary-rg rtl:font-primary-he-rg ">{t('STATISTICS')}</p>
            <div className="flex justify-between flex-row ">
              <div className="flex flex-col h-[92px] justify-center items-center bg-tableRow rounded-lg gap-1 w-52 ">
                <div className="flex flex-row items-center gap-1 justify-center">
                  <p className="ltr:font-primary-rg rtl:font-primary-he-rg font-medium text-2xl leading-7 text-white">
                    {bestSetFromAllProgramOfTarinee?.resistance_value}
                  </p>
                  <p className="ltr:font-primary-rg rtl:font-primary-he-rg font-normal text-sm leading-5  text-white">x</p>
                  <p className="ltr:font-primary-rg rtl:font-primary-he-rg font-medium text-2xl leading-7  text-white">
                    {bestSetFromAllProgramOfTarinee?.tracking_value}
                  </p>
                  <p className="ltr:font-primary-rg rtl:font-primary-he-rg font-normal text-[14px] leading-5  text-white">{t('kg')}</p>
                </div>
                <div className="flex ltr:font-primary-rg rtl:font-primary-he-rg text-sm items-center justify-center">{t('BEST_PERFORMANCE')}</div>
              </div>
              <div className="flex flex-row h-[92px] justify-center items-center bg-tableRow rounded-lg gap-[2px] min-w-52 w-auto p-4 ">
                <div className="flex flex-row items-center gap-1 justify-center">
                  <p className="ltr:font-primary-rg rtl:font-primary-he-rg font-medium text-sm leading-7 text-white">{t('Start')}</p>
                  <p className="ltr:font-primary-bd rtl:font-primary-he-bd font-normal text-sm leading-5  text-white">{firstBestSet}</p>
                  <p className="ltr:font-primary-bd rtl:font-primary-he-bd font-normal text-sm leading-5  text-white">kg -</p>
                </div>

                <div className="flex flex-row items-center gap-1 justify-center">
                  <p className="ltr:font-primary-rg rtl:font-primary-he-rg font-medium text-sm leading-7 text-white">{t('Program Best')}</p>
                  <p className="ltr:font-primary-bd rtl:font-primary-he-bd font-normal text-sm leading-5  text-white">
                    {selectedExerciseHistory?.bestSetinAllProgramInKg}
                  </p>
                  <p className="ltr:font-primary-bd rtl:font-primary-he-bd font-normal text-sm leading-5  text-white">{t('kg')}</p>
                </div>
              </div>
              <div className="flex flex-row h-[92px] items-center justify-center  bg-tableRow rounded-lg gap-1 w-52 ">
                <p className="ltr:font-primary-rg rtl:font-primary-he-rg font-medium text-sm leading-7 text-white">{t('DONE')}</p>
                <p className="ltr:font-primary-bd rtl:font-primary-he-bd font-normal text-sm leading-5  text-white">
                  {selectedExerciseHistory?.completedWorkoutsExercise}
                </p>
                <p className="ltr:font-primary-rg rtl:font-primary-he-rg font-medium text-sm leading-7  text-white">{t('times in program')}</p>
              </div>
              <div className="flex flex-row h-[92px] items-center justify-center  bg-tableRow rounded-lg gap-1 w-52 ">
                <p className="ltr:font-primary-rg rtl:font-primary-he-rg font-medium text-sm leading-7 text-white">{t('Canceled')}</p>
                <p className="ltr:font-primary-bd rtl:font-primary-he-bd font-normal text-sm leading-5  text-white">
                  {selectedExerciseHistory?.cancelledWorkoutsExercise}
                </p>
                <p className="ltr:font-primary-rg rtl:font-primary-he-rg font-medium text-sm leading-7  text-white">{t('TIMES')}</p>
              </div>
            </div>
          </div>

          {/* ---------------- Exercise Working History --------------------- */}

          <div className="flex flex-col h-full py-4 gap-2  ">
            <div className="flex ltr:font-primary-md rtl:font-primary-he-md text-xl text-textLightGray ">{t('History per program per workout')}</div>
            {workouts?.length > 1 && (
              <div className={`flex flex-col h-8/12 w-full gap-1 overflow-y-auto ${workouts?.length > 10 && 'pb-16'} `}>
                {workouts?.map((item, index) => (
                  <>
                    <div className="flex flex-row   items-center justify-between " key={index}>
                      <div className="flex flex-row gap-4  items-center w-2/12">
                        <div className="h-2 w-2 bg-textFieldColor rounded"></div>

                        <div className="flex flex-col">
                          <p className="font-primary-rg text-[16px] leading-5 font-medium"># {workouts?.length - index}</p>
                          <p className="font-primary-rg text-[12px] leading-4 font-normal">
                            {exerciseDoneDate(item?.WorkoutExercises[0]?.timeStamp)}
                          </p>
                        </div>
                      </div>
                      <div className="flex gap-x-6 gap-y-3 w-7/12 flex-wrap justify-start ">
                        {item?.WorkoutExercises[0]?.Sets?.length > 0 &&
                          item?.WorkoutExercises[0]?.Sets?.map((set, index) => (
                            <div className="flex flex-col " key={index}>
                              <p className="font-primary-rg text-[12px] leading-4 font-normal text-[#C2C7CF]">
                                {t('SET')} {set?.index + 1}
                              </p>
                              <p className="font-primary-rg text-[12px] leading-4 font-normal text-[#C2C7CF]">{`
                                ${getResistanceLabel(item?.WorkoutExercises[0]?.Exercise?.resistancename)}
                               ${set?.resistance_value} x ${set?.tracking_value}`}</p>
                            </div>
                          ))}
                      </div>
                      <div className="flex gap-1 w-3/12 justify-end items-center">
                        {item?.WorkoutExercises[0]?.resistanceImproved !== 0 && (
                          <div
                            className={`font-primary-rg text-[12px] leading-4 font-normal ${
                              item?.WorkoutExercises[0]?.resistanceImproved > 0 ? 'text-[#53c22b] bg-[#0c1d1a]' : 'text-[#f54b49] bg-[#18191e]'
                            }   py-[2px] px-1 rounded`}
                          >
                            {getResistanceLabel(item?.WorkoutExercises[0]?.Exercise?.resistancename)} {item?.WorkoutExercises[0]?.resistanceImproved}
                          </div>
                        )}
                        {item?.WorkoutExercises[0]?.trackingImproved !== 0 && (
                          <div
                            className={`font-primary-rg text-[12px] leading-4 font-normal ${
                              item?.WorkoutExercises[0]?.trackingImproved > 0 ? 'text-[#53c22b] bg-[#0c1d1a]' : 'text-[#f54b49] bg-[#18191e]'
                            }  py-[2px] px-1 rounded`}
                          >
                            {getResistanceLabel(item?.WorkoutExercises[0]?.Exercise?.trackingname)} {item?.WorkoutExercises[0]?.trackingImproved}
                          </div>
                        )}
                      </div>
                    </div>

                    {workouts?.length - 2 !== index && (
                      <div className=" mx-1 -my-3 h-7 w-[1px] bg-textFieldColor rounded z-10">
                        <p className="h-7 w-[2px] bg-textFieldColor rounded z-10"></p>
                      </div>
                    )}
                  </>
                ))}
              </div>
            )}
            {/* ---------------- Footer 1st index --------------------- */}
            <div
              className={`flex w-full h-4/12 bg-sidebar flex-row  items-center justify-between py-2 ${
                selectedExerciseHistory?.workouts?.length > 1 ? 'border-t-[1px] border-[#1f2f37] absolute bottom-0 ' : ''
              }  z-1 shadow-lg `}
              key={firstRecord?.id}
            >
              <div className="flex flex-row gap-4 items-center w-2/12">
                <div className="h-2 w-2 bg-textFieldColor rounded"></div>
                <div className="flex flex-col">
                  <p className="font-primary-rg text-[16px] leading-5 font-medium text-[#C2C7CF]">{t('FIRST_TIME')}</p>
                  <p className="font-primary-rg text-[12px] leading-4 font-normal  text-[#C2C7CF]">
                    {exerciseDoneDate(firstRecord?.WorkoutExercises[0]?.timeStamp)}
                  </p>
                </div>
              </div>
              <div className="flex px-4 gap-x-6 gap-y-3 w-7/12 flex-wrap justify-start">
                {firstRecord &&
                  firstRecord?.WorkoutExercises[0]?.Sets?.map((set, index) => (
                    <div className="flex flex-col" key={index}>
                      <p className="font-primary-rg text-[12px] leading-4 font-normal text-[#C2C7CF]">
                        {t('SETS')} {set?.index + 1}
                      </p>
                      <p className="font-primary-rg text-[12px] leading-4 font-normal text-[#C2C7CF]">{`${getResistanceLabel(
                        firstRecord?.WorkoutExercises[0]?.Exercise?.resistancename
                      )} ${set?.resistance_value} x ${set?.tracking_value}`}</p>
                    </div>
                  ))}
              </div>
              <div className="flex gap-1 w-3/12 justify-end items-center">
                {firstRecord?.WorkoutExercises[0]?.resistanceImproved !== 0 && (
                  <div
                    className={`font-primary-rg text-[12px] leading-4 font-normal ${
                      firstRecord?.WorkoutExercises[0]?.resistanceImproved > 0 ? 'text-[#53c22b] bg-[#0c1d1a]' : 'text-[#f54b49] bg-[#18191e]'
                    }   py-[2px] px-1 rounded`}
                  >
                    {getResistanceLabel(firstRecord?.WorkoutExercises[0]?.Exercise?.resistancename)}

                    {firstRecord?.WorkoutExercises[0]?.resistanceImproved}
                  </div>
                )}
                {firstRecord?.WorkoutExercises[0]?.trackingImproved !== 0 && (
                  <div
                    className={`font-primary-rg text-[12px] leading-4 font-normal ${
                      firstRecord?.WorkoutExercises[0]?.trackingImproved > 0 ? 'text-[#53c22b] bg-[#0c1d1a]' : 'text-[#f54b49] bg-[#18191e]'
                    }  py-[2px] px-1 rounded`}
                  >
                    {getResistanceLabel(firstRecord?.WorkoutExercises[0]?.Exercise?.trackingname)}
                    {firstRecord?.WorkoutExercises[0]?.trackingImproved}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  )
}
