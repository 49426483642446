import { Menu, Transition } from '@headlessui/react'
import { Images } from 'assets'

import { Fragment } from 'react'

const WeekDropDown = ({ value, setValue, options, title, disable, t, dir, className, height }) => {
  return (
    <div className={`flex flex-col h-[56px] rounded-[12px] mb-2 mt-1 ${className ? className : 'w-full'} ${disable && 'opacity-50'} group`}>
      <Menu as="div" className="relative inline-block text-left h-[56px] ">
        <div className="  rounded-[12px] ">
          <Menu.Button
            className={`inline-flex w-full text-[12px] text-center items-center rounded-[12px] px-2 py-2 font-medium text-textLightGrey ${
              dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
            } ${height && `h-[${height}]`} `}
            disabled={disable}
          >
            {value && t(value?.label)}
            <img src={Images.DROPDOWNICON} alt="" height={20} width={20} className={` ltr:ml-auto rtl:mr-auto cursor-pointer `} />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute w-full z-10 mt-2  origin-top-right divide-y divide-[#374349] rounded-xl bg-textFieldColor shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none  max-h-[400px] overflow-auto ">
            {options?.map((opt) => {
              return (
                <div className=" ">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          setValue(opt)
                        }}
                        className={`${active ? 'bg-[#444d53] text-textLightGrey' : 'text-textLightGrey'} group flex w-full ${
                          dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                        } items-center px-2 py-2 text-[12px]`}
                      >
                        {t(opt?.label)}
                      </button>
                    )}
                  </Menu.Item>
                </div>
              )
            })}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}

export default WeekDropDown
