import CalendarListHeader from 'components/calendar/CalendarListHeader'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FullCalendar from '@fullcalendar/react'
// dayGrid plugin is required for displaying grids of days
import dayGridPlugin from '@fullcalendar/daygrid'
// Use interaction plugin to enable interaction with calendar
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'

// RRule plugin is required for recurring events
import rrulePlugin from '@fullcalendar/rrule'

import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { useSelector } from 'react-redux'

import Container from 'styles/calendarStyles'
// import CalendarEvent from "../Event";
import CalendarEvent from 'components/calendar/Event'
import { Loader } from 'components/compounds/Loader'
import { toast } from 'react-toastify'
import { getGymEvents, updateEvent } from 'store/eventSlice/eventActions'
import { useDispatch } from 'react-redux'
import CustomToastify from 'components/compounds/Toast'
import { Images } from 'assets'
import { useDebounce } from 'hooks'
import { Modal } from 'components/elements/Modal'
import PrimaryButton from 'components/elements/PrimaryButton'
import { addDurationToTime, getCalendarEventsTime, isEventDatePassed, roundMinutes } from 'utils'
import { constants } from 'constant'
import EventEditor from 'components/calendar/EventEditor'

const TOOLBAR_BUTTONS = 'prev next'
const DEFAULT_VIEW = 'timeGridWeek'

export default function AdminCalendar() {
  const [setSearchText, searchText] = useState()
  const { coaches } = useSelector((state) => state.gymAdmin)
  const { t } = useTranslation()
  const { language, direction } = useSelector((state) => state.language)
  const [isLoading, setLoading] = useState(false)
  const { customerDetail } = useSelector((state) => state.user)
  // Holds list of events
  const [events, setEvents] = useState([])
  const [isEventOpen, setIsEventOpen] = useState(false)
  const [currentView, setCurrentView] = useState(DEFAULT_VIEW)
  const [isUpdateEventModal, setUpdateEventModal] = useState(null)
  const [draggedEvent, setDraggedEvent] = useState(null)
  const [showUpdateInfoBox, setShowUpdateInfoBox] = useState(false)
  const [currEvent, setCurrEvent] = useState()

  const calendarRef = useRef(null)
  const dispatch = useDispatch()

  const [selectedCoach, setSelectedCoach] = useState(null)
  const debouncedSearchTerm = useDebounce(setSearchText, 1000)

  // Attach event listeners for drag start and stop

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])
  const toggleInfoBox = (event) => {
    setCurrEvent(event)
    setShowUpdateInfoBox(true)
    setIsEventOpen(true)

    // eslint-disable-next-line
  }

  const handleCancel = useCallback(() => {
    setShowUpdateInfoBox(false)
    setIsEventOpen(false)

    if (!Boolean(currEvent.title)) {
      handleDelete(true)
    }
    // eslint-disable-next-line
  }, [currEvent])

  useEffect(() => {
    refreshWorkouts()
    // eslint-disable-next-line
  }, [selectedCoach, debouncedSearchTerm])

  const refreshWorkouts = async () => {
    const gymEvents = await getGymEvents(customerDetail?.id, selectedCoach?.Coach?.id, setSearchText)
    setEvents(gymEvents)
  }

  const getData = async () => {
    setLoading(true)
    try {
      const gymEvents = await getGymEvents(customerDetail?.id, selectedCoach?.Coach?.id, setSearchText)
      setEvents(gymEvents)
      setLoading(false)
    } catch (error) {
      console.log({ error })
      toast.error('Error fetching events information')
      setLoading(false)
    }
  }

  const handleClick = useCallback(
    (clickedDate) => {
      if (isEventOpen) return

      const date = clickedDate?.dateStr

      const end = new Date(date)
      end.setHours(end.getHours() + 1)

      // const newEvent = {
      //   id: uuidv4(),
      //   title: '',
      //   start: date,
      //   allDay: clickedDate?.allDay,
      //   end,
      //   classNames: ['customEvent'],
      //   duration: {
      //     hours: 1,
      //     minutes: 0,
      //   },
      //   extendedProps: {
      //     startTime,
      //     endTime,
      //   },
      // }

      // setEvents((o) => [...o, newEvent])
    },

    [isEventOpen]
  )

  const onDropEventUpdateLogic = (isAllFutureEvents, data) => {
    //20-nov
    const info = isUpdateEventModal?.data || data

    const date = moment(info.event.start)
    const offsetDifference = date.utcOffset()
    const hours = offsetDifference / 60

    let startDate = moment(info.event.start)
    let endDate = moment(info.event.end)

    const newDateStart = startDate.clone().subtract(hours, 'hours')
    startDate = newDateStart.utcOffset(0, true)

    const newDateEnd = endDate.clone().subtract(hours, 'hours')
    endDate = newDateEnd.utcOffset(0, true)
    roundMinutes(startDate)
    const changedStartTime = startDate.format('HH:mm')

    const newEndTime = addDurationToTime(changedStartTime, info?.oldEvent?.extendedProps?.eventDuration)

    const coachId = info.oldEvent.extendedProps.coach_Id

    const payload = {
      startTime: changedStartTime,
      endTime: newEndTime,
      endDate: date,
      dayIndex: moment(date).day(),
      dayName: moment(date).format('dddd').toLowerCase(),
      availableHour: coaches.find((item) => item?.Coach?.id === coachId)?.Coach?.availableHour,
      bookedTime: coaches.find((item) => item?.Coach?.id === coachId)?.Coach?.bookedTime || [],
    }
    const selectedDayAvailability = payload.availableHour[payload.dayIndex]
    const isStartTime = selectedDayAvailability?.find((item) => item.start === payload.startTime)
    const isEndTime = selectedDayAvailability?.find((item) => item.end === payload.endTime)

    if (isStartTime && isEndTime) {
      const action = async () => {
        const gymEvents = await getGymEvents(customerDetail?.id, selectedCoach?.Coach?.id, setSearchText)
        setEvents(gymEvents)
        return CustomToastify(t('EVENT_ACTION'), t('EVENT_UPDATED_SUCCESSFULLY'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
      }
      if (!isAllFutureEvents) {
        // onDropEventUpdateLogic(false, info)
        const updatedPayload = {
          id: info.oldEvent.id,
          title: info.oldEvent.title,
          description: info.oldEvent.extendedProps.title,
          endDate: date,
          start_hour: payload.startTime,
          end_hour: payload.endTime,
          coach_Id: info.oldEvent.extendedProps.coach_Id,
          duration: info.oldEvent.extendedProps.eventDuration,
          updateFutureEvents: isAllFutureEvents,
        }
        dispatch(updateEvent(updatedPayload, t, direction, action))
      } else {
        if (isUpdateEventModal?.data) {
          const updatedPayload = {
            id: info.oldEvent.id,
            title: info.oldEvent.title,
            description: info.oldEvent.extendedProps.title,
            endDate: date,
            start_hour: payload.startTime,
            end_hour: payload.endTime,
            coach_Id: info.oldEvent.extendedProps.coach_Id,
            duration: info.oldEvent.extendedProps.eventDuration,
            updateFutureEvents: isAllFutureEvents,
          }
          dispatch(updateEvent(updatedPayload, t, direction, action))
        }
        setUpdateEventModal({
          data: info,
          type: 'drop',
        })
      }

      // const updatedPayload = {
      //   id: info.oldEvent.id,
      //   title: info.oldEvent.title,
      //   description: info.oldEvent.extendedProps.title,
      //   endDate: date,
      //   start_hour: payload.startTime,
      //   end_hour: payload.endTime,
      //   coach_Id: info.oldEvent.extendedProps.coach_Id,
      //   duration:info.oldEvent.extendedProps.eventDuration,
      //   updateFutureEvents: isAllFutureEvents,
      // }

      // dispatch(updateEvent(updatedPayload, t, direction, action))
    } else {
      CustomToastify(t('EVENT_ACTION'), t('COACH_NOT_AVAILABLE'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      const clonedEvents = cloneDeep(events)
      const updatedEvents = clonedEvents.map((item) => (item.id === info.oldEvent.id ? info.oldEvent : item))
      setEvents(updatedEvents)
    }
  }

  const handleOnDrop = useCallback(
    (info) => {
      const eventDetail = events.find((event) => Number(event.id) === Number(info.oldEvent.id))
      const futureEvents = events.filter(
        (event) =>
          event.Workout.program_id === eventDetail.Workout.program_id &&
          eventDetail.status === 'approved' &&
          eventDetail.id !== event.id &&
          moment(event.date).isAfter(moment(eventDetail.date))
      )

      const isLastEvent = futureEvents.length === 0
      if (isLastEvent) {
        onDropEventUpdateLogic(false, info)
      } else {
        onDropEventUpdateLogic(true, info)
        // setUpdateEventModal({
        //   data: info,
        //   type: 'drop',
        // })
        const clonedEvents = cloneDeep(events)
        const updatedEvents = clonedEvents.map((item) => (item.id === info.oldEvent.id ? info.oldEvent : item))
        setEvents(updatedEvents)
      }
    },
    // eslint-disable-next-line
    [events]
  )

  const onResizeEventUpdateLogic = (isAllFutureEvents, data) => {
    const info = isUpdateEventModal?.data || data

    const date = moment(info.event.start)

    const offsetDifference = date.utcOffset()
    const hours = offsetDifference / 60

    let startDate = moment(info.event.start)
    let endDate = moment(info.event.end)

    const newDateStart = startDate.clone().subtract(hours, 'hours')
    startDate = newDateStart.utcOffset(0, true)

    const newDateEnd = endDate.clone().subtract(hours, 'hours')
    endDate = newDateEnd.utcOffset(0, true)

    const changedStartTime = startDate.format('HH:mm')
    roundMinutes(endDate)
    const changedEndTime = endDate.format('HH:mm')
    const endDateMinutes = endDate.minutes()
    if (endDateMinutes !== 0) {
      const clonedEvents = cloneDeep(events)
      const updatedEvents = clonedEvents.map((item) => (item.id === info.oldEvent.id ? info.oldEvent : item))
      setEvents(updatedEvents)
      return CustomToastify(t('EVENT_ACTION'), t('TIME_DURATION_CANNOT_BE_WITHIN_HOURS'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    }
    const coachId = info.oldEvent.extendedProps.coach_Id

    const payload = {
      startTime: changedStartTime,
      endTime: changedEndTime,
      endDate: info.oldEvent.extendedProps.endDate,
      dayIndex: moment(info.oldEvent.extendedProps.date).day(),
      dayName: moment(info.oldEvent.extendedProps.date).format('dddd').toLowerCase(),
      availableHour: coaches.find((item) => item?.Coach?.id === coachId)?.Coach?.availableHour,
      bookedTime: coaches.find((item) => item?.Coach?.id === coachId)?.Coach?.bookedTime || [],
    }

    const selectedDayAvailability = payload.availableHour[payload.dayIndex]
    const isStartTime = selectedDayAvailability?.find((item) => item.start === payload.startTime)
    const isEndTime = selectedDayAvailability?.find((item) => item.end === payload.endTime)
    if (isStartTime && isEndTime) {
      const action = async () => {
        const gymEvents = await getGymEvents(customerDetail?.id, selectedCoach?.Coach?.id, setSearchText)
        setEvents(gymEvents)
        return CustomToastify(t('EVENT_ACTION'), t('EVENT_UPDATED_SUCCESSFULLY'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
      }
      const updatedPayload = {
        id: info.oldEvent.id,
        title: info.oldEvent.title,
        description: info.oldEvent.extendedProps.title,
        endDate: info.oldEvent.extendedProps.endDate,
        start_hour: payload.startTime,
        end_hour: payload.endTime,
        coach_Id: info.oldEvent.extendedProps.coach_Id,
        updateFutureEvents: isAllFutureEvents,
      }
      dispatch(updateEvent(updatedPayload, t, direction, action))
    } else {
      CustomToastify(t('EVENT_ACTION'), t('COACH_NOT_AVAILABLE'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      const clonedEvents = cloneDeep(events)
      const updatedEvents = clonedEvents.map((item) => (item.id === info.oldEvent.id ? info.oldEvent : item))
      setEvents(updatedEvents)
    }
  }
  // eslint-disable-next-line
  const handleEventResize = useCallback(
    (info) => {
      const eventDetail = events.find((event) => Number(event.id) === Number(info.oldEvent.id))
      const futureEvents = events.filter(
        (event) =>
          event.Workout.program_id === eventDetail.Workout.program_id &&
          eventDetail.status === 'approved' &&
          eventDetail.id !== event.id &&
          moment(event.date).isAfter(moment(eventDetail.date))
      )

      const isLastEvent = futureEvents.length === 0

      // Calculate new start and end times dynamically based on the resize
      const newStartTime = moment(info.start).format('HH:mm')
      const newEndTime = moment(info.end).format('HH:mm')

      const updatedEvent = {
        ...info.oldEvent,
        start: newStartTime,
        end: newEndTime,
      }

      if (isLastEvent) {
        onResizeEventUpdateLogic(false, { ...info, oldEvent: updatedEvent })
      } else {
        setUpdateEventModal({
          data: { ...info, oldEvent: updatedEvent },
          type: 'resize',
        })

        // If needed, you can also update the local events state here
        const clonedEvents = cloneDeep(events)
        const updatedEvents = clonedEvents.map((item) => (item.id === info.oldEvent.id ? updatedEvent : item))
        setEvents(updatedEvents)
      }
    },
    [events, onResizeEventUpdateLogic]
  )

  const divRef = React.useRef(null)

  const onUpdateEventLogic = (isAllFutureEvents, data) => {
    const info = isUpdateEventModal?.data || data
    const action = async () => {
      const gymEvents = await getGymEvents(customerDetail?.id, selectedCoach?.Coach?.id, setSearchText)
      setEvents(gymEvents)
      return CustomToastify(t('EVENT_ACTION'), t('EVENT_UPDATED_SUCCESSFULLY'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
    }
    const { description, endDate, startTime, endTime, coach, eventDuration } = info.extendedProps
    const startTimeParts = startTime?.split(':')
    const newEndDate = new Date(endDate)
    newEndDate.setHours(parseInt(startTimeParts[0], 10))
    newEndDate.setMinutes(parseInt(startTimeParts[1], 10))
    newEndDate.setSeconds(0)

    if (!isAllFutureEvents && !isUpdateEventModal) {
      const payload = {
        id: info.id,
        title: info.title,
        description,
        endDate: newEndDate,
        start_hour: startTime + ':00',
        end_hour: endTime + ':00',
        coach_Id: coach,
        updateFutureEvents: isAllFutureEvents,
        duration: eventDuration,
      }
      dispatch(updateEvent(payload, t, direction, action))
    } else {
      if (isUpdateEventModal?.data) {
        const payload = {
          id: info.id,
          title: info.title,
          description,
          endDate: newEndDate,
          start_hour: startTime + ':00',
          end_hour: endTime + ':00',
          coach_Id: coach,
          updateFutureEvents: isAllFutureEvents,
          duration: eventDuration,
        }
        dispatch(updateEvent(payload, t, direction, action))
      }
      setUpdateEventModal({
        data: info,
        type: 'update',
      })
    }
  }

  const handleUpdateEvent = useCallback(
    (id, updatedEvent) => {
      const eventDetail = events.find((event) => Number(event.id) === Number(id))
      const futureEvents = events.filter(
        (event) =>
          event.Workout.program_id === eventDetail.Workout.program_id &&
          eventDetail.status === 'approved' &&
          eventDetail.id !== event.id &&
          moment(event.date).isAfter(moment(eventDetail.date))
      )
      const isLastEvent = futureEvents.length === 0
      if (isLastEvent) {
        onUpdateEventLogic(false, { ...updatedEvent, id })
      } else {
        onUpdateEventLogic(true, { ...updatedEvent, id })

        // setUpdateEventModal({
        //   data: { ...updatedEvent, id },
        //   type: 'update',
        // })
      }
    },
    // eslint-disable-next-line
    [events]
  )

  const handleChangeView = (view) => {
    if (calendarRef.current) {
      setCurrentView(view)
      calendarRef.current.getApi().changeView(view)
    }
  }
  const handleDelete = useCallback((autoConfirm = false) => {
    // eslint-disable-next-line no-restricted-globals, no-alert
    const confirmation = autoConfirm || confirm('Are you sure you want to delete this event?')
    setShowUpdateInfoBox(false)
    setIsEventOpen(false)
    // Removes event if user confirms
    if (confirmation) {
      setEvents((o) => {
        return o.filter((item) => item?.id !== currEvent?.id)
      })
      setShowUpdateInfoBox(false)
      setIsEventOpen(false)
    }
    // eslint-disable-next-line
  }, [])

  const renderEventContent = useCallback(
    (eventInfo, allEvents) => {
      if (divRef?.current) {
        // const height = divRef?.current?.offsetHeight
      }
      const oldEvent = allEvents.find((event) => Number(event.id) === Number(eventInfo.event.id)) || {}

      const { changedStartTime, newEndTime } = getCalendarEventsTime(eventInfo, oldEvent)
      const eventDurationMinutes = moment.duration(eventInfo.event.end - eventInfo.event.start).asMinutes()
      const eventHeight = Math.max(30, eventDurationMinutes)

      return (
        <CalendarEvent
          {...eventInfo}
          handleUpdateEvent={handleUpdateEvent}
          setEvents={setEvents}
          setIsEventOpen={setIsEventOpen}
          showUpdateInfoBox={showUpdateInfoBox}
          setShowUpdateInfoBox={setShowUpdateInfoBox}
          toggleInfoBox={toggleInfoBox}
          isEventOpen={isEventOpen}
          currentView={currentView}
          divheight={divRef?.current?.offsetHeight}
          refreshWorkouts={refreshWorkouts}
          setCurrEvent={setCurrEvent}
          handleDelete={handleDelete}
          customView={({ event, title }) => {
            const isPassed = isEventDatePassed(event?.endDate, event?.endTime)
            const color = event.Coach?.color === 'BLUE' ? '#E5A772' : event?.Coach?.color
            const textColor = constants.COLOR_MAP.find((item) => item?.bgColor === color)?.textColor || '#FFFFFF'
            const status = event?.Workout?.status
            const eventDuration = event?.eventDuration

            return (
              <div
                className={`flex ${eventDuration < 60 ? 'flex-row' : 'flex-col'} ${
                  eventDuration < 60 ? 'justify-evenly' : 'justify-center'
                } rounded-md ${isPassed && 'opacity-50'} px-2 border-[${status !== 'cancelled' ? color : 'null'}] border-[${
                  status === 'cancelled' ? '1px' : '0px'
                }]`}
                style={{
                  background: status === 'cancelled' ? 'transparent' : color,
                  height: `${eventHeight}px`,
                  paddingTop: eventDuration < 60 ? 5 : 0,
                }}
              >
                <span
                  className={`font-bold py-0 text-sm text-[${status !== 'cancelled' ? textColor : '#fff'}] ${
                    direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                  } ${isPassed && 'opacity-50'} truncate ${status === 'cancelled' ? 'text-decoration-line: line-through' : ''}`}
                >
                  {!title || title === 'null' ? 'Unknown Trainee' : title}
                </span>
                <div className="">
                  <span
                    className={`text-[11px] text-[${status !== 'cancelled' ? textColor : '#fff'}] ${isPassed && 'opacity-50'} font-normal truncate ${
                      direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                    }  ${status === 'cancelled' ? 'text-decoration-line: line-through' : ''}`}
                  >
                    {changedStartTime} - {newEndTime}
                  </span>
                </div>
              </div>
            )
          }}
        />
      )
    },
    // eslint-disable-next-line
    [handleUpdateEvent]
  )

  const formatDays = (day) => {
    const splitday = day.split('-')
    let newday = splitday[0].trim()

    const transDate = `${t(newday)} ${splitday[1]}`

    return transDate
  }

  return (
    <>
      {isLoading ? (
        <div className="flex flex-col justify-center items-center w-full h-screen">
          <Loader className="m10" size="w-6 h-6" fill="#003353" />
        </div>
      ) : (
        <div className="h-screen has-scrollbar">
          <CalendarListHeader
            selectedCoach={selectedCoach}
            setSelectedCoach={setSelectedCoach}
            setSearchText={setSearchText}
            searchText={searchText}
            handleChange={handleChangeView}
          />
          <Container className="min-h-[calc(100%-17rem)] has-scrollbar">
            <div ref={divRef}>
              <FullCalendar
                timeZone="UTC"
                ref={calendarRef}
                editable={!isEventOpen}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, rrulePlugin]}
                initialView={currentView}
                height={1000}
                dateClick={handleClick}
                events={events}
                eventDrop={handleOnDrop}
                dayMaxEvents={1}
                defaultAllDay={false}
                allDayText=""
                slotMinTime="06:00:00"
                slotMaxTime="22:00:00"
                headerToolbar={{
                  right: direction !== 'rtl' ? TOOLBAR_BUTTONS : 'title',
                  left: direction === 'rtl' ? TOOLBAR_BUTTONS : 'title',
                }}
                buttonText={{
                  day: t('day'),
                  week: t('week'),
                  month: t('month'),
                }}
                slotLabelFormat={(time) => moment(time.date).format('hh:mm')}
                locales={[direction]}
                locale={language}
                dayHeaderFormat={(date) => formatDays(moment(date?.date?.marker).format('ddd - DD'))}
                eventContent={(eventInfo) => renderEventContent(eventInfo, events)}
                direction={direction}
                eventDurationEditable={false}
                // eventResize={handleEventResize}
              />
            </div>
          </Container>
        </div>
      )}
      <Modal
        className={`h-auto w-[480px] ${direction === 'ltr' ? 'rounded-l-3xl' : 'rounded-r-3xl'} p-6`}
        childrenClass="overflow-auto "
        open={isUpdateEventModal}
        title={'Update Event'}
        handleClose={() => setUpdateEventModal(null)}
        direction={direction}
      >
        <div className="w-full">
          <div className="space-y-2 p-4">
            <h1 className="text-white text-xl">{t('Update Event')}</h1>
            <h6 className="text-textGrey text-md">{t('WANT_UPDATE_FUTURE_EVENT')}</h6>
          </div>
          <div className="flex items-center gap-x-2">
            <PrimaryButton
              text={t('Yes')}
              type="submit"
              disabled={false}
              isIcon={false}
              className="w-full h-10 rounded-[12px] shadow-2xl my-1"
              bgColor="bg-prussianBlue hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
              textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
              // Loader={loading}
              onClick={() => {
                if (isUpdateEventModal?.type === 'drop') {
                  onDropEventUpdateLogic(true)
                } else if (isUpdateEventModal?.type === 'resize') {
                  onResizeEventUpdateLogic(true)
                } else if (isUpdateEventModal?.type === 'update') {
                  onUpdateEventLogic(true)
                }
                setUpdateEventModal(null)
              }}
            />
            <PrimaryButton
              text={t('No, Only This Event')}
              type="submit"
              disabled={false}
              isIcon={false}
              className="w-full h-10 rounded-[12px] shadow-2xl my-1"
              bgColor="bg-prussianBlue hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
              textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
              onClick={() => {
                if (isUpdateEventModal?.type === 'drop') {
                  onDropEventUpdateLogic(false)
                } else if (isUpdateEventModal?.type === 'resize') {
                  onResizeEventUpdateLogic(false)
                } else if (isUpdateEventModal?.type === 'update') {
                  onUpdateEventLogic(false)
                }
                setUpdateEventModal(null)
              }}
            />
          </div>
        </div>
      </Modal>
      {showUpdateInfoBox && (
        <EventEditor
          event={currEvent}
          handleCancel={handleCancel}
          handleUpdateEvent={handleUpdateEvent}
          currentView={currentView}
          eventHasValidInfo={true}
          handleDelete={handleDelete}
          setShowUpdateInfoBox={setShowUpdateInfoBox}
          showUpdateInfoBox={showUpdateInfoBox}
          setIsEventOpen={setIsEventOpen}
          divheight={divRef?.current?.offsetHeight}
          refreshWorkouts={refreshWorkouts}
          isEventOpen={isEventOpen}
        />
      )}
    </>
  )
}
