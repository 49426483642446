const OutlineButton = ({ text, className, onClick, textClass, type, isLoading, isIcon, icon, wid, ht, bgColor, disabled, Loader, showNotify }) => {
  return (
    <div
      className={`${
        disabled ? 'text-white' : 'text-white'
      } text-sm lg:text-sm px-4 py-2 lg:px-4 flex items-center justify-center relative  ${className} ${
        disabled ? 'disabled:bg-disabledBtn disabled:border-extralightGray opacity-20 border-opacity-90' : bgColor
      } ${disabled ? ' cursor-not-allowed pointer-events-none' : 'cursor-pointer'}`}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {showNotify !== undefined && showNotify !== null && (
        <div className={`rounded-full w-2 h-2 ${showNotify === true ? 'bg-red-500 ' : 'bg-extralightGray'} absolute -top-1 left-0`}></div>
      )}
      <div className="flex items-center gap-2">
        {Loader ? (
          <div className="spinner-5"></div>
        ) : (
          <>
            {text && <p className={textClass}> {text}</p>}
            {isIcon && <img src={icon} alt="" height={wid ? wid : 10} width={ht ? ht : 10} />}
          </>
        )}
      </div>
    </div>
  )
}

export default OutlineButton
