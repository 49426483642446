import { Images } from 'assets'
import React from 'react'

export default function MusclePercentageInfo({ programStatistics, t, handleSelect }) {
  return (
    <>
      {programStatistics?.length > 0 ? (
        <>
          {programStatistics?.map((item) => (
            <div
              className="flex w-full h-12 bg-tableRow rounded-lg justify-between py-2 px-4 items-center cursor-pointer"
              onClick={() => handleSelect(item)}
            >
              <h5 className=" text-sm text-white ltr:font-primary-rg rtl:font-primary-he-rg font-normal">
                {item?.muscleName} ({item?.percentages}%)
              </h5>
              <h5 className=" text-xs text-extralightGray ltr:font-primary-rg rtl:font-primary-he-rg font-normal">
                {item?.setsNumber}/{item?.totalSetsNumber} {t('SETS')}
              </h5>
            </div>
          ))}
        </>
      ) : (
        <div className={`w-full h-full flex flex-col rounded-[12px] mt-2 px-4 py-2 justify-center items-center   `}>
          <img src={Images.EmptyWorkout} alt="" height={100} width={100} className="items-center cursor-pointer" />
          <p className={`ltr:font-primary-rg rtl:font-primary-he-rg text-[white] text-[24px] leading-7`}>{t('NO_DATA')}</p>
        </div>
      )}
    </>
  )
}
