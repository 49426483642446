import { Tooltip } from '@material-tailwind/react'
import React, { useState, useEffect, useRef } from 'react'

function TextWithEllipsis(props) {
  return (
    <Tooltip
      content={props.text}
      placement="top"
      className={`tooltipPopularExercise ${props?.direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
      offset={10}
    >
      <div className="text-container">{props.text}</div>
    </Tooltip>
  )
}

export default function PopularExercises(props) {
  const mainContainerRef = useRef(null)
  const [numContainers, setNumContainers] = useState(5)
  const [containerWidth, setContainerWidth] = useState(178)

  useEffect(() => {
    const mainContainerWidth = 550
    const maxNumContainers = Math.floor(mainContainerWidth / containerWidth)
    const actualNumContainers = Math.min(props.items.length, maxNumContainers)
    setNumContainers(actualNumContainers)
  }, [props.items, containerWidth])

  useEffect(() => {
    const containerMaxWidth = 178
    const longestExerciseLength = props.items.reduce((max, item) => Math.max(max, item.length), 0)
    const newContainerWidth = Math.min(containerMaxWidth, Math.ceil(5 * longestExerciseLength)) // Assuming 1 character takes about 8px width
    setContainerWidth(newContainerWidth)
  }, [props.items])

  return (
    <div className="main-container" ref={mainContainerRef}>
      {props.items.slice(0, numContainers).map((val) => {
        return (
          <div className="inner-container" key={val}>
            <TextWithEllipsis text={val} direction={props?.direction} />
          </div>
        )
      })}
      {numContainers < props.items.length && (
        <div className="inner-container more-container">
          <span>+{props.items.length - numContainers}</span>
        </div>
      )}
    </div>
  )
}
