const PrimaryButton = ({ text, className, onClick, textClass, type, isLoading, isIcon, icon, wid, ht, bgColor, disabled, Loader }) => {
  return (
    <div
      className={`${disabled ? 'text-white bg-[#2E373C] opacity-50 border-[1.5px] border-[#404b51]' : 'text-white'
        } text-sm lg:text-sm px-2 py-2 lg:px-4 flex items-center justify-center  ${className} ${disabled ? 'disabled:bg-disabledBtn  border-opacity-90 ' : bgColor
        } ${disabled ? ' cursor-not-allowed pointer-events-none' : 'cursor-pointer'}`}
      onClick={onClick}
      type={type}
      disabled={disabled}
      style={{ alignSelf: 'center' }}
    >
      <div className="flex items-center gap-2">
        {Loader ? (
          <div className="spinner-7"></div>
        ) : (
          <>
            <p className={textClass}> {text}</p>
            {isIcon && <img src={icon} alt="" height={wid ? wid : 10} width={ht ? ht : 10} />}
          </>
        )}
      </div>
    </div>
  )
}

export default PrimaryButton
