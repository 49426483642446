import { Tooltip } from '@material-tailwind/react'
import { Images } from 'assets'
import { Loader } from 'components/compounds/Loader'

import PrimaryButton from 'components/elements/PrimaryButton'
import React from 'react'
import { useSelector } from 'react-redux'
import { getResistanceLabel } from 'utils'

export default function MuscleExercises({
  t,
  selectedExercise,
  setSelectedExercise,
  exercisesOfMuscle,
  loading,
  selectedExerciseDetail,
  handleShow,
}) {
  const { direction } = useSelector((state) => state.language)
  return (
    <>
      {exercisesOfMuscle && exercisesOfMuscle?.length > 0 ? (
        <>
          {exercisesOfMuscle?.map((item) => (
            <div
              className={`flex w-full flex-col ${
                selectedExercise && selectedExercise.id === item.id ? '  max-h-96 ease-in-out duration-500' : ' max-h-12 ease-in-out duration-300'
              } bg-tableRow rounded-lg  cursor-pointer`}
            >
              <div className="flex w-full h-12  justify-between py-2 px-4 rounded-lg ">
                <div className="flex gap-x-1 items-center w-2/3">
                  <img
                    src={Images.backArrow}
                    alt=""
                    height={16}
                    width={16}
                    className={`items-center cursor-pointer ${
                      selectedExercise && selectedExercise?.id === item?.id ? ' rotate-90' : 'rtl:rotate-180'
                    }  `}
                    onClick={() => {
                      if (selectedExercise != null && item?.id === selectedExercise?.id) {
                        setSelectedExercise(null)
                      } else {
                        setSelectedExercise(item)
                      }
                    }}
                  />
                  <Tooltip
                    content={`${item?.exerciseName}`}
                    placement="top"
                    className={`tooltip ltr:font-primary-rg rtl:font-primary-he-rg z-[99999] `}
                  >
                    <h5 className=" text-sm text-white ltr:font-primary-rg rtl:font-primary-he-rg font-normal truncate">{item?.exerciseName}</h5>
                  </Tooltip>
                </div>
                <h5 className="flex items-center w-1/3 text-xs text-extralightGray ltr:font-primary-rg rtl:font-primary-he-rg font-normal justify-end">
                  {item?.isAssistent ? t('Assistent') : t('Primary')}
                </h5>
              </div>
              {loading && selectedExercise && item?.id === selectedExercise?.id ? (
                <div className="flex h-80 w-full justify-center items-center">
                  <Loader className="" size="w-6 h-6" fill="#003353" />
                </div>
              ) : (
                <>
                  {selectedExercise && item?.id === selectedExercise?.id && selectedExerciseDetail && (
                    <div className="flex flex-col gap-2 w-full justify-between py-2 px-4 rounded-lg ease-in-out duration-300 max-h-96 ">
                      <div className="flex flex-row flex-wrap w-full gap-2 items-center">
                        {selectedExerciseDetail?.workoutPatternList?.map((item) => (
                          <div
                            className={`flex flex-row py-1 px-2  rounded ltr:font-primary-rg rtl:font-primary-he-rg text-center ${
                              selectedExerciseDetail?.selectedWorkout?.PatternIndex === item?.PatternIndex
                                ? 'bg-[#E5A772] text-[#333333]'
                                : ' bg-[#5E616D] text-white'
                            }  text-xs text-center`}
                          >
                            {item?.workoutName}
                          </div>
                        ))}
                      </div>
                      {selectedExerciseDetail?.bestSet?.advancedTechniques != null && (
                        <div className="flex gap-x-1">
                          <p className="text-extralightGray font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-xs">
                            {t('ADVANCE_TECHNIQUE')} :{' '}
                          </p>
                          <p className="text-extralightGray font-bold ltr:font-primary-bd rtl:font-primary-he-bd text-xs">
                            {' '}
                            {t(selectedExerciseDetail?.bestSet?.advancedTechniques)}
                          </p>
                        </div>
                      )}
                      {(selectedExerciseDetail?.workout?.WorkoutExercises[0]?.controlTechnique ||
                        selectedExerciseDetail?.workout?.WorkoutExercises[0]?.mobilityTechnique ||
                        selectedExerciseDetail?.workout?.WorkoutExercises[0]?.muscleTechnique) && (
                        <div className="flex w-full gap-x-1">
                          <p className="text-extralightGray font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-xs w-1/4">
                            {t('Improved')} :{' '}
                          </p>
                          <div className="flex flex-row flex-wrap  gap-2 items-center w-3/4">
                            {selectedExerciseDetail?.workout?.WorkoutExercises[0]?.controlTechnique && (
                              <div className="flex px-1 py-[2px] rounded ltr:font-primary-rg rtl:font-primary-he-rg bg-[#373C42] text-white text-[10.52px] text-center">
                                {t('control')}
                              </div>
                            )}

                            {selectedExerciseDetail?.workout?.WorkoutExercises[0]?.mobilityTechnique && (
                              <div className="flex px-1 py-[2px] rounded ltr:font-primary-rg rtl:font-primary-he-rg bg-[#373C42] text-white text-[10.52px] text-center">
                                {t('mobility')}
                              </div>
                            )}
                            {selectedExerciseDetail?.workout?.WorkoutExercises[0]?.muscleTechnique && (
                              <div className="flex px-1 py-[2px] rounded ltr:font-primary-rg rtl:font-primary-he-rg bg-[#373C42] text-white text-[10.52px] text-center">
                                {t('muscle')}
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      <div className="flex gap-x-1">
                        <p className="text-extralightGray font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-sm">{t('Canceled')} : </p>
                        <p className="text-extralightGray font-bold ltr:font-primary-rg rtl:font-primary-he-rg text-sm">
                          {' '}
                          {selectedExerciseDetail?.cancelledSets} {t('SETS')}
                        </p>
                        <div className="flex gap-1 justify-end items-center">
                          {selectedExerciseDetail?.bestSet?.resistanceImproved !== 0 && (
                            <div
                              className={`font-primary-rg text-[12px] leading-4 font-normal ${
                                selectedExerciseDetail?.bestSet?.resistanceImproved > 0
                                  ? 'text-[#53c22b] bg-[#0c1d1a]'
                                  : 'text-[#f54b49] bg-[#18191e]'
                              }   py-[2px] px-1 rounded`}
                            >
                              {getResistanceLabel(selectedExerciseDetail?.workout?.WorkoutExercises[0]?.Exercise?.resistancename)}{' '}
                              {selectedExerciseDetail?.bestSet?.resistanceImproved}
                            </div>
                          )}
                          {selectedExerciseDetail?.bestSet?.trackingImproved !== 0 && (
                            <div
                              className={`font-primary-rg text-[12px] leading-4 font-normal ${
                                selectedExerciseDetail?.bestSet?.trackingImproved > 0 ? 'text-[#53c22b] bg-[#0c1d1a]' : 'text-[#f54b49] bg-[#18191e]'
                              }  py-[2px] px-1 rounded`}
                            >
                              {getResistanceLabel(selectedExerciseDetail?.workout?.WorkoutExercises[0]?.Exercise?.trackingname)}
                              {selectedExerciseDetail?.bestSet?.trackingImproved}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="flex flex-col w-full ">
                        <table className="w-full">
                          <thead className="w-full">
                            <tr>
                              <th
                                className="text-white font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-sm"
                                align={direction === 'ltr' ? 'left' : 'right'}
                              >
                                #
                              </th>
                              <th className="text-white font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-sm" align={'center'}>
                                {selectedExerciseDetail?.workout?.WorkoutExercises[0]?.Exercise?.resistancename}
                              </th>
                              <th className="text-white font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-sm" align={'center'}>
                                {selectedExerciseDetail?.workout?.WorkoutExercises[0]?.Exercise?.trackingname}
                              </th>
                              {selectedExerciseDetail?.workout?.WorkoutExercises[0]?.intensityTracking && (
                                <th className="text-white font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-sm" align={'center'}>
                                  RPE
                                </th>
                              )}
                              {selectedExerciseDetail?.workout?.WorkoutExercises[0]?.Exercise?.resistancename === 'machine' && (
                                <th
                                  className="text-white font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-sm"
                                  align={direction === 'ltr' ? 'left' : 'right'}
                                >
                                  Boost
                                </th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {selectedExerciseDetail?.workout?.WorkoutExercises[0]?.Sets.map((item, index) => (
                              <tr key={index} className="h-2">
                                <td
                                  className="text-white font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-sm !p-0 "
                                  align={direction === 'ltr' ? 'left' : 'right'}
                                >
                                  {t('SETS')} {index + 1}
                                </td>
                                <td className="text-white font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-base !p-0" align={'center'}>
                                  {item?.resistance_value}
                                </td>
                                <td className="text-white font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-base !p-0" align={'center'}>
                                  {item?.tracking_value}
                                </td>
                                {selectedExerciseDetail?.workout?.WorkoutExercises[0]?.intensityTracking && (
                                  <td className="text-white font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-base !p-0" align={'center'}>
                                    {item?.intensity_level}
                                  </td>
                                )}
                                {selectedExerciseDetail?.workout?.WorkoutExercises[0]?.Exercise?.resistancename === 'machine' && (
                                  <td className="!p-0" align={direction === 'ltr' ? 'left' : 'right'}>
                                    <label className="switch-toggle-summary">
                                      <input type="checkbox" checked={item?.isBoost} disabled={true} />
                                      <span className="switch"></span>
                                    </label>
                                  </td>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        <PrimaryButton
                          text={t('VIEW_ALL')}
                          type="submit"
                          disabled={false}
                          isIcon={false}
                          onClick={() => {
                            handleShow(selectedExercise)
                          }}
                          className="h-[44px] w-full p-4 my-4 rounded-[12px] shadow-2xl"
                          bgColor="bg-borderActive hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
                          textClass={`text-white ltr:font-primary-rg rtl:font-primary-he-rg}`}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          ))}
        </>
      ) : (
        <div className={`w-full h-full flex flex-col rounded-[12px] mt-2 px-4 py-2 justify-center items-center   `}>
          <img src={Images.EmptyWorkout} alt="" height={100} width={100} className="items-center cursor-pointer" />
          <p className={`ltr:font-primary-rg rtl:font-primary-he-rg text-[white] text-[24px] leading-7`}>{t('NO_DATA')}</p>
        </div>
      )}
    </>
  )
}
