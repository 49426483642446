import DeleteModal from "components/compounds/DeleteModal";
import _ from "lodash";
import { Loader } from "components/compounds/Loader";
import CreateExercise from "components/exerciseLibrary/CreateExercise";
import ExerciseLibraryListHeader from "components/exerciseLibrary/ExerciseLibraryListHeader";
import ListTable from "components/List/ListTable";
import { ProfileAvatar } from "components/PlayBoard/ProfileAvatar";
import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { deleteGymExercise } from "store/exerciseSlice/exerciseActions";
import { formatDateOnlyMonth } from "utils";
import { Images } from "assets";
import { Modal } from "components/elements/Modal";
import ReactPlayer from "react-player";

export default function ExerciseLibrary() {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState();
  const [exerciseID, setExerciseID] = useState(null);
  const [openModal, setOpenModal] = useState();
  const { direction } = useSelector((state) => state.language);
  const { loader, exercises } = useSelector((state) => state?.exercise);
  const { customerDetail } = useSelector((state) => state.user);
  const [isCopy, setIsCopy] = useState(false);
  const [isView, setIsView] = useState(false);
  const [exerciseList, setExerciseList] = useState(exercises);
  const { t } = useTranslation();

  const toggelModal = () => {
    setShow(true);
  };
  const subExerciseHeader = {
    data: [
      {
        id: "exerciseName",
        align: direction === "rtl" ? "right" : "left",
        type: "custom",
        disablePadding: false,
        label: "EXERCISE_NAME",
        sort: false,

        CustomComponent: (item) => {
          return (
            <p className=" ltr:font-primary-rg rtl:font-primary-he-rg text-white text-sm ">
              {item?.data?.subExerciseList?.length > 0 &&
                `(${item?.data?.subExerciseList?.length}) `}
              {item?.data?.exerciseName}
            </p>
          );
        },
      },
      {
        id: "count",
        align: direction === "rtl" ? "right" : "left",
        type: "custom",
        disablePadding: false,
        label: "COMPLETED",
        sort: true,
        CustomComponent: ({ item }) => {
          return (
            <p className=" ltr:font-primary-rg rtl:font-primary-he-rg text-white text-sm ">
              {item} {t("times")}{" "}
            </p>
          );
        },
      },
      {
        id: "muscles",
        align: direction === "rtl" ? "right" : "left",
        type: "custom",
        disablePadding: false,
        label: "MUSCLES",
        sort: false,
        className: ` w-56 text-white ${direction === "rtl" ? "text-right" : "text-left"} `,
        CustomComponent: (item) => {
          let name = "";

          // eslint-disable-next-line array-callback-return
          item?.data?.muscles?.map((value) => {
            if (item?.data?.muscles?.length > 1) {
              name += value + " | ";
            } else {
              name = value;
            }
          });

          return (
            <p
              className={`${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
            >
              {name}
            </p>
          );
        },
      },

      {
        id: "updatedAt",
        align: direction === "rtl" ? "right" : "left",
        type: "custom",
        disablePadding: false,
        label: "LAST_UPDATED",
        sort: true,
        CustomComponent: (item) => {
          return (
            <div className="flex flex-row items-center gap-x-1">
              {item?.data?.master_Id ? (
                <ProfileAvatar
                  image={null}
                  name={"MY TRAINING APP"}
                  color={"#E5A772"}
                />
              ) : (
                <ProfileAvatar
                  image={customerDetail?.logo}
                  name={customerDetail?.gymName}
                  color={
                    customerDetail?.User?.Coach?.color === "BLUE"
                      ? "#E5A772"
                      : customerDetail?.User?.Coach?.color
                  }
                />
              )}
              <p
                className={`${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
              >
                {formatDateOnlyMonth(t, item?.item)}
              </p>
              {item?.data?.master_Id ? (
                <p
                  className={`${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                >
                  , MY TRAINING APP
                </p>
              ) : (
                <p
                  className={`${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                >
                  , {customerDetail?.gymName}
                </p>
              )}
            </div>
          );
        },
      },
    ],

    isDeleting: true,
    isCopy: true,
    isClickedEditable: true,
    clickable: true,
    noShowHeader: true,
  };
  const headerRows = {
    data: [
      {
        id: "exerciseName",
        align: direction === "rtl" ? "right" : "left",
        type: "custom",
        disablePadding: false,
        label: "EXERCISE_NAME",
        sort: false,

        CustomComponent: (item) => {
          return (
            <p className=" ltr:font-primary-rg rtl:font-primary-he-rg text-white text-sm ">
              {item?.data?.subExerciseList?.length > 0 &&
                `(${item?.data?.subExerciseList?.length}) `}
              {item?.data?.exerciseName}
            </p>
          );
        },
      },
      {
        id: "count",
        align: direction === "rtl" ? "right" : "left",
        type: "custom",
        disablePadding: false,
        label: "COMPLETED",
        sort: true,
        CustomComponent: ({ item }) => {
          return (
            <p className=" ltr:font-primary-rg rtl:font-primary-he-rg text-white text-sm ">
              {item} {t("times")}{" "}
            </p>
          );
        },
      },
      {
        id: "muscles",
        align: direction === "rtl" ? "right" : "left",
        type: "custom",
        disablePadding: false,
        label: "MUSCLES",
        sort: false,
        className: ` w-56 text-white ${direction === "rtl" ? "text-right" : "text-left"} `,
        CustomComponent: (item) => {
          let name = "";

          // eslint-disable-next-line array-callback-return
          item?.data?.muscles?.map((value) => {
            if (item?.data?.muscles?.length > 1) {
              name += value + " | ";
            } else {
              name = value;
            }
          });

          return (
            <p
              className={`${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
            >
              {name}
            </p>
          );
        },
      },

      {
        id: "updatedAt",
        align: direction === "rtl" ? "right" : "left",
        type: "custom",
        disablePadding: false,
        label: "LAST_UPDATED",
        sort: true,
        CustomComponent: (item) => {
          return (
            <div className="flex flex-row items-center gap-x-1">
              {item?.data?.master_Id ? (
                <ProfileAvatar
                  image={null}
                  name={"MY TRAINING APP"}
                  color={"#E5A772"}
                />
              ) : (
                <ProfileAvatar
                  image={customerDetail?.logo}
                  name={customerDetail?.gymName}
                  color={
                    customerDetail?.User?.Coach?.color === "BLUE"
                      ? "#E5A772"
                      : customerDetail?.User?.Coach?.color
                  }
                />
              )}
              <p
                className={`${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
              >
                {formatDateOnlyMonth(t, item?.item)}
              </p>
              {item?.data?.master_Id ? (
                <p
                  className={`${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                >
                  ,{" "}
                </p>
              ) : (
                <p
                  className={`${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                >
                  , {customerDetail?.gymName}
                </p>
              )}
            </div>
          );
        },
      },
      {
        id: "actions",
        align: direction === "rtl" ? "right" : "left",
        type: "custom",
        disablePadding: false,
        label: "Video",
        sort: false,
        CustomComponent: (item) => {
          return (
            <div className="flex flex-row items-center gap-x-2">
                <img
                  src={Images.Youtube}
                  alt=""
                  height={22}
                  width={22}
                  className={`items-center cursor-pointer`}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleShowVideo(item?.data?.url);
                  }}
                />
            </div>
          );
        },
      },
    ],
    dropdownView: ({ row }) => {
      return (
        <tr className="bg-borderColor rounded-xl">
          <td colSpan={headerRows.data.length + 2} className="rounded-xl">
            <ListTable
              headerRows={subExerciseHeader}
              data={row?.subExerciseList || []}
              totalData={40}
              loading={false}
              searchText={searchText}
              clickHandler={clickHandler}
              deleteHandler={deleteHandler}
              copyHandler={copyHandler}
              editViewHandler={clickEditHandle}
            />
          </td>
        </tr>
      );
    },
    isDeleting: true,

    isCopy: true,
    isCustomDropdown: true,
    isClickedEditable: true,
    clickable: true,
    hasSubData: true,
  };
  useEffect(() => {
    setExerciseList(exercises);
  }, [exercises]);

  const onSort = (item, id, order) => {
    const newdata = _.orderBy(exerciseList, [id], order);
    setExerciseList(newdata);
  };

  const deleteHandler = (id) => {
    setExerciseID(id);
    setOpenModal(true);
  };
  const deleteExercise = () => {
    dispatch(deleteGymExercise(exerciseID, t, direction));
    setOpenModal(false);
  };
  const copyHandler = (id) => {
    setExerciseID(id);
    setShow(!show);
    setIsCopy(true);
  };
  const clickHandler = (item) => {
    setExerciseID(item?.id);
    setIsView(!isView);
    setShow(!show);
  };
  const clickEditHandle = (item) => {
    setExerciseID(item?.id);

    setShow(!show);
  };

  const [isOpenVideo, setIsOpenVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);

  const handleShowVideo = useCallback((url) => {
    setVideoUrl(url);
    setIsOpenVideo(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpenVideo(false);
  }, []);

  return (
    <div className="h-full w-full overflow-hidden">
      <Modal open={isOpenVideo} handleClose={closeModal}>
        {videoUrl && <ReactPlayer url={videoUrl} />}
      </Modal>
      <ExerciseLibraryListHeader
        toggelModal={toggelModal}
        setSearchText={setSearchText}
        searchText={searchText}
      />
      {loader ? (
        <div className="w-[100%] h-[calc(100vh-150px)]   ">
          <Loader className="" size="w-10 h-10" fill="#003353" />
        </div>
      ) : (
        <div className=" h-[76%] xl:h-[85%] w-full overflow-y-scroll has-scrollbar">
          {exerciseList && exerciseList?.length > 0 ? (
            <ListTable
              headerRows={headerRows}
              data={exerciseList}
              onSort={onSort}
              totalData={exercises?.length}
              loading={false}
              searchText={searchText}
              clickHandler={clickHandler}
              deleteHandler={deleteHandler}
              copyHandler={copyHandler}
              editViewHandler={clickEditHandle}
              tableClassName={"pb-32"}
            />
          ) : (
            <div className="h-[50%] justify-center items-center ">
              <p
                className={`text-white ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"} text-[24px] text-center `}
              >
                {t("NO_DATA")}
              </p>
            </div>
          )}
        </div>
      )}
      <DeleteModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        t={t}
        direction={direction}
        des={t("MUSCLE_DELETE_DES")}
        title={t("MUSCLE_DELETE_DES")}
        action={() => {}}
        handleSubmit={deleteExercise}
        hand
        showPass={false}
      />
      {show && (
        <CreateExercise
          show={show}
          setShow={setShow}
          exerciseID={exerciseID}
          setExerciseID={setExerciseID}
          isCopy={isCopy}
          setIsCopy={setIsCopy}
          isView={isView}
          setIsView={setIsView}
        />
      )}
    </div>
  );
}
