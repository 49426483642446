import { useTranslation } from "react-i18next";
import { Images } from "assets";
import { useState } from "react";

const configureSortingValues = (headerRows) => {
  const isDefaultColumnSort = headerRows?.defaultSortColumnName;
  const sortingDetails = [];
  for (let i = 0; i < headerRows?.data?.length; i++) {
    const rowDetail = headerRows?.data[i];
    if (rowDetail.id === isDefaultColumnSort) {
      sortingDetails.push("asc");
      continue;
    }
    sortingDetails.push("asc");
  }
  return sortingDetails;
};

function ListTableHead(props) {
  const { t } = useTranslation();

  const [sortInfo, setSortInfo] = useState(
    configureSortingValues(props?.headerRows)
  );
  const { headerRows, order } = props;

  const onClickSort = (index) => {
    const previousValue = sortInfo[index];
    let updatedValues = [...sortInfo];
    if (previousValue === "default") {
      // change value to asc
      updatedValues[index] = "asc";
    } else if (previousValue === "asc") {
      // change value to dsc
      updatedValues[index] = "desc";
    } else if (previousValue === "desc") {
      // change value to default
      updatedValues[index] = "asc";
    }
    setSortInfo([...updatedValues]);
  };

  return (
    <thead className="" id="editableHeader">
      <tr className="h-2  sm:h-5 ">
        {(headerRows.isDropDown || headerRows.isCustomDropdown) && (
          <td className="p-2 " align="center" padding={"normal"}>
            <p className="px-2 font-primary-rg text-center text-white capitalize text-[14px]"></p>
          </td>
        )}
        {headerRows.data.map((row, index) => {
          return (
            <td
              className={`p-2 ${order.id === row.id ? order.direction : ""}`}
              key={row.id}
              align={row.align}
              padding={row.disablePadding ? "none" : "normal"}
              onClick={() => {
                if (row.sort) {
                  onClickSort(index);
                  props?.onSort && props?.onSort(row, row.id, sortInfo[index]);
                }
              }}
            >
              <div
                className={`group ${row.sort ? "cursor-pointer" : ""} flex items-center space-x-2 rtl:space-x-reverse`}
              >
                <p
                  className={` font-primary-rg text-[${row.align}] text-white capitalize text-[14px]`}
                >
                  {t(row.label)}
                </p>
                {row.sort && (
                  <>
                    <img
                      className="cursor-pointer opacity-0 group-hover:opacity-100 w-4"
                      src={
                        sortInfo[index] === "asc"
                          ? Images.AscSort
                          : Images.DescSort
                      }
                      alt=""
                    />
                  </>
                )}
              </div>
            </td>
          );
        }, this)}
        {(headerRows.isDeleting || headerRows.isEditable) && (
          <td className="p-2 " align="center" padding={"normal"}>
            <p className="px-2 font-primary-rg text-center text-white capitalize text-[14px]"></p>
          </td>
        )}
      </tr>
    </thead>
  );
}

export default ListTableHead;
