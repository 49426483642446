import styled from 'styled-components'

export const EventContainer = styled.div`
  height: 100%;
  width: 100%;

  .event-small {
    p {
      color: #333333;
      border-radius: 8px;
      padding: 8px;
      font-size: 14px;
      font-weight: 500;
    }
  }
`
