import { createSlice } from '@reduxjs/toolkit'

import { useSelector } from 'react-redux'

const initialState = {
  loader: false,
  currentCoach: null,
  trainees: [],
  currentTrainee: null,
}
export const coachSlice = createSlice({
  name: 'coach',
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setAllTrainees: (state, action) => {
      state.trainees = action.payload
    },
    resetCoach: (state, { payload }) => {
      return {
        ...state,
        loader: false,
        currentCoach: null,
        trainees: [],
        currentTrainee: null,
      }
    },
  },
})

export const useSaleSelector = () => useSelector((state) => state.coachReducers)

export const coachActions = coachSlice.actions
export const coachReducer = coachSlice.reducer
