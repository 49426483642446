import axiosInstance from 'api'
import { Images } from 'assets'
import CustomToastify from 'components/compounds/Toast'

import { traineeActions } from './traineeReducer'


export const deleteProgram = (id, t, direction, action) => async (dispatch) => {
  try {
    dispatch(traineeActions.setLoader(true))
    await axiosInstance
      .delete(`deleteProgram/${id}`)
      .then((response) => {
        dispatch(traineeActions.setRemainingProgram(id))
        response.result?.activeNextProgram && dispatch(traineeActions.setActiveProgram(response.result.activeNextProgram)) 
      })
      .finally(() => {
        dispatch(traineeActions.setLoader(false))
        action && action()

        CustomToastify(t('PROGRAM_DELETED'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
      })
  } catch (e) {
    dispatch(traineeActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}


export const getProgramByTrainee = (id, t, direction) => async (dispatch) => {
  try {
    dispatch(traineeActions.setLoader(true))
    await axiosInstance
      .get(`getProgramByTrainee/${id}`)
      .then((response) => {
        dispatch(traineeActions.setProgram(response.result.formattedData))
      })
      .finally(() => {
        dispatch(traineeActions.setLoader(false))
      })
  } catch (e) {
    dispatch(traineeActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastSuccessbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}

export const getWorkoutByTrainee = (id, t, direction) => async (dispatch) => {
  try {
    await axiosInstance
      .get(`getWorkoutByTrainee/${id}`)
      .then((response) => {
        dispatch(traineeActions.setWorkouts(response.result))
      })
      .finally(() => {})
  } catch (e) {
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}

export const getTraineeById = async (traineeId) => {
  const response = await axiosInstance.get(`getTraineeById/${traineeId}`)
  return response?.result
}

export const updateTrainee = async (data) => {
  await axiosInstance.put(`UpdateTrainee`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const getTraineeDashboardStats = async (traineeId) => {
  const response = await axiosInstance.get(`/traineeInfo/${traineeId}`)

  return response.result
}

export const deleteTrainee = async (userId) => {
  const response = await axiosInstance.delete(`/trainee/${userId}`)
  return response.result
}

export const updateEventDate = async (data) => {
  await axiosInstance.post(`/updateEventDate`, data)
}

export const startWorkout = async (workoutId) => {
  await axiosInstance.get(`/makeWorkoutActive/${workoutId}`, { params: { isTrainee: true } })
}

export const getWorkout = async (workoutId) => {
  const response = await axiosInstance.get(`/getWorkoutDetail/${workoutId}`)
  return response.result
}
export const removeWorkout = async (workoutId) => {
  await axiosInstance.get(`/removeWorkout/${workoutId}`)
}

export const addExerciseToWorkout = async (payload) => {
  try {
    await axiosInstance.post(`/addExerciseInWorkout`, payload)
    return true
  } catch (error) {
    return false
  }
}

export const addCommentOnExercise = async (payload) => {
  await axiosInstance.post(`/addCommentToWorkoutExercise`, payload)
}

export const deleteComment = async (id) => {
  await axiosInstance.post(`/removeExerciseComment/${id}`)
}

export const reindexWorkoutExercises = async (payload) => {
  await axiosInstance.post(`/reIndexExercise`, payload)
}

export const playWorkoutExercise = async (payload) => {
  await axiosInstance.post(`/updateWorkoutExerciseStatus`, payload)
}

export const completeWorkoutExercise = async (payload, setBestRecordId, bestRecordId) => {
  const res = await axiosInstance.post(`/completeWorkoutExercise`, payload)
  const isNewPersonalBest = res.result.newPersonalBest
  if (isNewPersonalBest && setBestRecordId) {
    setBestRecordId((pre) => [
      ...pre,
      {
        workoutId: res.result.data.workoutId,
        exerciseName: res.result.exerciseName,
        isResistanceRecord: res?.result?.newBestSet?.resistanceRecord,
        resistanceValue: res?.result?.newBestSet?.resistance_value,
        trackingValue: res?.result?.newBestSet?.tracking_value,
        resistanceName: res?.result?.data?.updatedSets[0]?.resistancename,
        trackingName: res?.result?.data?.updatedSets[0]?.trackingname,
      },
    ])
    setTimeout(() => {
      const temp = bestRecordId.filter((x) => x !== res.result.data.workoutId)
      setBestRecordId(temp)
    }, 5000)
  }
}

export const completeWorkoutByTrainee = async (payload, workoutId) => {
  await axiosInstance.put(`/completeWorkout/${workoutId}`, payload)
}

export const getWorkoutPatternsForSwap = async (workoutId, patternIndex) => {
  const response = await axiosInstance.get(`/getAllPatternOfActiveProgram/${workoutId}`)
  return response?.result?.filter((workout) => workout.PatternIndex !== patternIndex) || []
}

export const swapWorkoutFromTrainee = async (payload) => {
  try {
    await axiosInstance.post(`/swapWorkout`, payload)
    return true
  } catch (error) {
    return false
  }
}

export const swapExerciseFromTrainee = async (payload) => {
  try {
    await axiosInstance.post(`/swapExercise`, payload)
    return true
  } catch (error) {
    return false
  }
}
export const swapAllExerciseFromTrainee = async (payload) => {
  try {
    await axiosInstance.post(`/swapAllExercise`, payload)
    return true
  } catch (error) {
    return false
  }
}

export const removeExercise = async (workoutExerciseId) => {
  await axiosInstance.get(`/removeWorkoutExercise/${workoutExerciseId}`)
}

export const changePassword = async (payload) => {
  await axiosInstance.put(`/changePassword`, payload)
}

export const getExercisesInfo = async (traineeId, exerciseId) => {
  try {
    const response = await axiosInstance.get(`/exerciseInfo`, { params: { traineeId: traineeId, exerciseId: exerciseId } })

    return response?.result?.lastExercise
  } catch (e) {
    return null
  }
}
