import { useOnClickOutside } from 'hooks/useOnClickOutside'
import React, { useRef, useState } from 'react'

const RightClickMenu = ({ children, disabled, menu, setIsSwaping }) => {
  const [isOpen, setOpen] = useState(false)
  const divRef = useRef()
  const toggleOpener = () => setOpen(!isOpen)
  useOnClickOutside(divRef, () => {
    setOpen(false)
  })

  const onRightClick = (e) => {
    e.preventDefault()
    toggleOpener()
    setIsSwaping && setIsSwaping(false)
  }

  const Menu = menu

  if (disabled) {
    return <>{children}</>
  }

  return (
    <div className="relative" onContextMenu={onRightClick} ref={divRef}>
      {children}
      {isOpen && <Menu onClose={toggleOpener} />}
    </div>
  )
}

export { RightClickMenu }
