import { Images } from 'assets'
import { Modal } from 'components/compounds/Modal'
import ImagePicker from 'components/elements/ImagePicker'
import ColorMenu from 'components/home/ColorMenu'
import GenderSelection from 'components/home/GenderSelection'
import { constants } from 'constant'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { DeleteGym, getGymDetailById, registerAccount, updateGymCustomer } from 'store/userSlice/userActions'

import DeleteModal from 'components/compounds/DeleteModal'
import { LabelInput } from 'components/compounds/LabelInput'
import LanguageDropDown from 'components/compounds/LanguageDropDown'
import { Loader } from 'components/compounds/Loader'
import Options from 'components/compounds/Options'
import SuspendModal from 'components/compounds/SuspendModal'
import CustomToastify from 'components/compounds/Toast'
import { useNavigate } from 'react-router-dom'

export default function CreateGym({ methods, show, setShow, gymID, setGymID, userID, setUserID }) {
  const { loader } = useSelector((state) => state.user)
  const [disable, setDisable] = useState(false)
  const { id } = useSelector((state) => state.auth.user)

  const [openModal, setOpenModal] = useState(false)
  const [openSuspendModal, setOpenSuspendModal] = useState(false)

  const [file, setFile] = useState(null)
  const { t } = useTranslation()
  const { reset, control, handleSubmit, formState, setValue } = methods
  const { errors } = formState

  const dispatch = useDispatch()
  const { direction } = useSelector((state) => state.language)
  const navigate = useNavigate()

  const [selectedColor, setSelectedColor] = useState(constants.COLORS[0])
  const [imageURL, setImageURL] = useState(null)
  const [imageError, setImageError] = useState(false)
  const [selectedGender, setSelectedGender] = useState(constants.GENDER[0])
  const { currGymDetail } = useSelector((state) => state.user)
  const [regStatus, setRegStatus] = useState(constants.REGISTERATION_STATUS[1])
  const [selectedLanguage, setSelectedLanguage] = useState(constants.APP_LANGUAGES_LIST[0])

  const [password, setPassword] = useState('')
  const [error, setError] = useState(false)
  const actionDeletion = () => {
    setShow(false)
    setOpenModal(false)
    navigate(`/home`)
  }
  const actionSuspention = () => {
    setShow(false)
    setOpenSuspendModal(false)
    navigate(`/home`)
  }

  const handleClose = () => {
    reset({
      instituteName: '',
      firstName: '',
      phoneNo: '',
      email: '',
      instagramId: '',
    })
    setSelectedGender(constants.GENDER[0])
    setSelectedColor(constants.COLORS[0])
    setGymID(null)
    setUserID(null)
    setShow(false)
  }
  const action = () => {
    setFile(null)
    handleClose()
  }
  const toggleOpen = () => setOpenModal(true)
  const toggleSuspendOpen = () => setOpenSuspendModal(true)
  const onSubmit = async (values) => {
    if (userID === null) {
      let data = {
        ...values,
        gender: selectedGender?.value,
        color: '#E5A772',
        lang: selectedLanguage.code,
      }
      const formData = new FormData()
      if (file) {
        formData.append('file', file)
      }
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key])
      })

      dispatch(registerAccount(formData, action, t, direction))
    } else {
      let data = {
        ...values,
        userId: currGymDetail?.User?.id,
        gymId: gymID,
        isActive: currGymDetail?.User?.isActive,
        lang: selectedLanguage.code,
        subscriptionStatus: regStatus,
      }

      const formData = new FormData()
      if (file) {
        formData.append('file', file)
      }
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key])
      })

      dispatch(updateGymCustomer(formData, action, t, direction, userID, gymID))
    }
  }
  useEffect(() => {
    if (gymID) {
      dispatch(getGymDetailById(gymID, 'gym_admin', t, direction))
    }
    // eslint-disable-next-line
  }, [gymID])
  useEffect(() => {
    if (currGymDetail && userID) {
      if (currGymDetail?.subscriptionStatus === 'Suspended' && currGymDetail?.User?.isActive === false) {
        setDisable(true)
      } else {
        setDisable(false)
      }
      setValue('instituteName', currGymDetail?.gymName)
      setValue('email', currGymDetail?.User?.email)
      setValue('phoneNo', currGymDetail?.User?.phone)
      setValue('instagramId', currGymDetail?.instagramId)
      setValue('firstName', currGymDetail?.User?.firstName)
      setImageURL(currGymDetail?.logo)

      constants.REGISTERATION_STATUS.forEach((item) => {
        if (item.toLowerCase() === currGymDetail?.subscriptionStatus.toLowerCase()) {
          setRegStatus(item)
        } else if (currGymDetail?.subscriptionStatus == null) {
          setRegStatus(constants.REGISTERATION_STATUS[1])
        }
      })
      constants.APP_LANGUAGES_LIST.forEach((item) => {
        if (item?.code === currGymDetail?.lang) {
          setSelectedLanguage(item)
        }
      })
    }
    // eslint-disable-next-line
  }, [currGymDetail])

  const notify = () => {
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
  }
  const deleteGym = () => {
    if (password.length > 0) {
      let data = {
        currentUserID: id,
        password: password,
      }
      dispatch(DeleteGym(currGymDetail?.User?.id, t, direction, action, data))
    } else {
      setError(true)
    }
  }
  return (
    <Modal
      className={`h-full w-[480px] ${direction === 'ltr' ? 'rounded-l-3xl' : 'rounded-r-3xl'}    `}
      childrenClass="overflow-auto "
      open={show}
      title={t('CREATE_INSTITUE')}
      handleClose={handleClose}
      handleSubmit={handleSubmit(onSubmit)}
      handleNotify={notify}
      loader={loader}
      isEdited={userID != null ? true : false}
      toggleOpen={toggleOpen}
      toggleSuspendOpen={toggleSuspendOpen}
      disabled={disable}
      direction={direction}
    >
      {loader ? (
        <Loader className="" size="w-8 h-8" fill="#003353" />
      ) : (
        <div>
          <ImagePicker
            placeholder={t('INSTITUTE_LOGO')}
            imageURL={imageURL}
            setImageURL={setImageURL}
            imageError={imageError}
            errorMessage={t('IMAGE_REQUIRED')}
            setImageError={setImageError}
            setSelectedFile={setFile}
            selectedFile={file}
            styles={`w-[140px] h-[140px] items-center  justify-center mt-4   rounded-[50%] ${disable && 'opacity-50'} ${
              !imageURL && 'border-dashed border-[1px]'
            }`}
            disabled={disable}
          />

          <LabelInput
            name="instituteName"
            type="text"
            label={t('INSTITUTE_NAME')}
            control={control}
            placeholderValue={t('INSTITUTE_PLACEHOLDER')}
            required
            fullWidth
            error={errors?.instituteName ? true : false}
            errorMessage={errors?.instituteName?.message}
            dir={direction}
            disabled={disable}
            showLable={userID ? true : false}
          />

          <LabelInput
            name="firstName"
            type="text"
            label={t('MANAGER_NAME')}
            placeholderValue={t('FULL_NAME')}
            control={control}
            required
            fullWidth
            error={errors?.firstName ? true : false}
            errorMessage={errors?.firstName?.message}
            dir={direction}
            disabled={disable}
            showLable={userID ? true : false}
          />
          <LabelInput
            name="phoneNo"
            type="number"
            label={t('MOBILE_NO')}
            control={control}
            placeholderValue={t('MOBILE_PLACEHOLDER')}
            required
            fullWidth
            error={errors?.phoneNo ? true : false}
            errorMessage={errors?.phoneNo?.message}
            dir={direction}
            disabled={disable}
            showLable={userID ? true : false}
          />

          <LabelInput
            name="email"
            type="text"
            label={t('EMAIL_ADDRESS_AS_USERNAME')}
            placeholderValue={'aaa@gmail.com'}
            control={control}
            required
            fullWidth
            error={errors?.email ? true : false}
            errorMessage={errors?.email?.message}
            dir={direction}
            disabled={disable}
          />
          <LabelInput
            name="instagramId"
            type="text"
            label={t('INSTAGRAM_PLACEHOLDER')}
            placeholderValue={t('INSTAGRAM_PLACEHOLDER')}
            control={control}
            required
            fullWidth
            error={errors?.instagramId ? true : false}
            errorMessage={errors?.instagramId?.message}
            dir={direction}
            disabled={disable}
          />
          <div className="mt-4">
            <LanguageDropDown
              options={constants.APP_LANGUAGES_LIST}
              className="my-2"
              title={t('LANGUAGE')}
              value={selectedLanguage}
              setValue={setSelectedLanguage}
              disable={userID ? true : false}
              t={t}
              dir={direction}
            />
          </div>
          <GenderSelection
            name="gender"
            data={constants.GENDER}
            selectedGender={selectedGender}
            setSelectedGender={setSelectedGender}
            disabled={disable}
            direction={direction}
          />

          <ColorMenu
            name="color"
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
            translate={t}
            disabled={disable}
            direction={direction}
          />

          {userID != null && (
            <Options
              label={t('REGISTERATION_STATUS')}
              name="Registeration Status"
              value={regStatus}
              setValue={setRegStatus}
              translate={t}
              data={constants.REGISTERATION_STATUS}
              disabled={disable}
            />
          )}
          <div className="mb-4" />
          <DeleteModal
            setOpenModal={setOpenModal}
            openModal={openModal}
            t={t}
            ID={currGymDetail?.User?.id}
            direction={direction}
            des={t('ENTER_PASSWORD_CONFIRMATION')}
            title={t('WANT_TO_DELETE_INSTITUTE')}
            action={actionDeletion}
            handleSubmit={deleteGym}
            showPass={true}
            error={error}
            setError={setError}
            password={password}
            setPassword={setPassword}
          />
          <SuspendModal
            setOpenModal={setOpenSuspendModal}
            openModal={openSuspendModal}
            showPass={true}
            t={t}
            ID={currGymDetail?.User?.id}
            direction={direction}
            des={t('GYM_SUSPENTION_DESCRIPTION')}
            title={t('GYM_SUSPENTION_TITLE')}
            action={actionSuspention}
          />
        </div>
      )}
    </Modal>
  )
}
