import React, { useCallback, useEffect, useLayoutEffect } from 'react'
import { AllBodyMapContainer, FrontBackContainer, RotateIconContainer } from './styles'

import { ReactComponent as SVGBodyMapFront } from '../../assets/images/bodyMapSvg.svg'
import { ReactComponent as SVGBodyMapBack } from '../../assets/images/bodyMapBackSvg.svg'
import { ReactComponent as RotateIcon } from '../../assets/images/rotateIcon.svg'
import { constants } from 'constant'

const BodyMap = ({ selectedValue, setSelectedValue, hoverEnabled = true, fillColor, isCustomHandler, w, h }) => {

  const [isFront, setIsFront] = React.useState(true)
  useEffect(() => {
    if (Array.isArray(selectedValue)) {
      if (selectedValue.length > 0 && constants.FRONT_MUSCLE_IDS.includes(selectedValue[selectedValue.length - 1])) {
        setIsFront(true)
      } else {
        if (selectedValue[selectedValue.length - 1] !== undefined) {
          setIsFront(false)
        } else {
          setIsFront(true)
        }
      }
    } else {
      if (selectedValue && constants.FRONT_MUSCLE_IDS.includes(selectedValue)) {
        setIsFront(true)
      } else {
        setIsFront(false)
      }
    }
  }, [selectedValue])

  const handleMuscleClick = useCallback(
    (e) => {
      if (!setSelectedValue || !hoverEnabled) return
      if (isCustomHandler) {
        setSelectedValue(+e.currentTarget.id)
      } else {
        if (Array.isArray(selectedValue)) {
          if (selectedValue.includes(+e.currentTarget.id)) {
            setSelectedValue(selectedValue.filter((value) => value !== +e.currentTarget.id))
          } else {
            setSelectedValue([...selectedValue, +e.currentTarget.id])
          }
        } else setSelectedValue(+e.currentTarget.id)
      }
    },
    // eslint-disable-next-line
    [setSelectedValue, selectedValue, hoverEnabled]
  )

  useLayoutEffect(() => {
    const bodyMap = document.querySelectorAll('.hoverable-muscle')

    bodyMap.forEach((muscle) => {
      muscle.addEventListener('click', handleMuscleClick)
    })

    return () => {
      bodyMap.forEach((muscle) => {
        muscle.removeEventListener('click', handleMuscleClick)
      })
    }
  }, [handleMuscleClick])

  return (
    <AllBodyMapContainer>
      <FrontBackContainer $isFront={isFront} $selectedValue={selectedValue} $hoverEnabled={hoverEnabled} fillColor={fillColor} w={w} h={h}>
        <SVGBodyMapFront />
        <SVGBodyMapBack />
      </FrontBackContainer>
      <RotateIconContainer onClick={() => setIsFront(!isFront)}>
        <RotateIcon />
      </RotateIconContainer>
    </AllBodyMapContainer>
  )
}

export default BodyMap
