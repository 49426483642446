import { useDebounce, useOutsideClick } from 'hooks'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getAllMuscles } from 'store/muscleSlice/muscleActions'

export default function MuscleLibraryListHeader(props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { data } = props
  const { direction } = useSelector((state) => state.language)
  const { customerDetail } = useSelector((state) => state.user)
  // eslint-disable-next-line
  const [searchInput, setSearchInput] = useState('')
  const filtersRef = useRef()

  const debouncedSearchTerm = useDebounce(searchInput, 500)

  useEffect(() => {
    const getMuscles = async () => {
      dispatch(getAllMuscles(customerDetail.id, t, direction))
    }
    getMuscles()
    // eslint-disable-next-line
  }, [debouncedSearchTerm])

  const onClickOutsideFiltersDiv = () => {}

  useOutsideClick(filtersRef, onClickOutsideFiltersDiv)

  return (
    <div className="flex w-full flex-col">
      <div className="flex flex-row w-full px-8 py-3 justify-between items-center">
        <div className={`text-white font-primary-rg text-[24px] ${direction === 'ltr' ? 'font-primary-bd' : 'font-primary-he-bd'}`}>
          {t('MUSCLE_LIBREARY')} ({data?.length})
        </div>
      </div>
    </div>
  )
}
