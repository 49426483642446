import { Layout } from 'components'
import AdminLayout from 'components/layouts/gymAdminLayout'
import { Error400, Error404, Error500, Home, Login, Signup, Verification } from 'pages'
import AdminDashboard from 'pages/GymAdmin/adminDashboard'
import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom'

import Calendar from 'pages/calendar'
import Equipments from 'pages/equipments'
import Exercises from 'pages/exercises'
import AdminCalendar from 'pages/GymAdmin/adminCalendar'
import ExerciseLibrary from 'pages/GymAdmin/exerciseLibrary'
import Main from 'pages/GymAdmin/main'
import Muscles from 'pages/muscles'

import CoachDashboardLayout from 'components/layouts/coachDashboardLayout'
import TraineeLayout from 'components/layouts/traineeLayout'
import CreateProgram from 'components/program/CreateProgram'
import CreateTemplates from 'components/templates/CreateTemplates'
import TraineeDetail from 'components/trainees/traineeProfile/traineeDetail'
import CoachCalendar from 'pages/Coach/coachCalendar'
import CoachDashboard from 'pages/Coach/coachDashboard'
import CoachExerciseLibrary from 'pages/Coach/coachExerciseLibrary'
import CoachTrainees from 'pages/Coach/coachTrainees'
import CoachWorkout from 'pages/Coach/coachWorkout'
import AdminLibrary from 'pages/GymAdmin/adminLibrary'
import MuscleLibrary from 'pages/GymAdmin/muscleLibrary'
import Trainees from 'pages/GymAdmin/trainees'
import WorkoutMonitor from 'pages/GymAdmin/workoutMonitor'
import Library from 'pages/library'

import TraineeCalender from 'pages/Trainee/traineeCalender'
import TraineeDashboard from 'pages/Trainee/traineeDashboard'
import TraineePassword from 'pages/Trainee/traineePassword'
import TraineeProfile from 'pages/Trainee/traineeProfile'
import TraineeWorkout from 'pages/Trainee/traineeWorkout'
import ProgramInfo from 'pages/GymAdmin/programInfo'

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<Layout />}>
        <Route index path="/home" element={<Home />} />
        <Route path="/equipments" element={<Equipments />} />
        <Route path="/muscles" element={<Muscles />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/exercises" element={<Exercises />} />
        <Route path="/library" element={<Library />} />
      </Route>
      <Route element={<AdminLayout />}>
        <Route path="/admin/gymprofile" element={<AdminDashboard />} />
        <Route index path="/admin/dashboard" element={<Main />} />
        <Route path="/admin/muscleLibrary" element={<MuscleLibrary />} />
        <Route path="/admin/trainees" element={<Trainees />} />
        <Route path="/admin/exerciseLibrary" element={<ExerciseLibrary />} />
        <Route path="/admin/adminCalendar" element={<AdminCalendar />} />
        <Route path="/admin/playBoard" element={<CoachWorkout />} />
        <Route path="/admin/workoutMonitor" element={<WorkoutMonitor />} />
        <Route path="/admin/trainees/traineeDetail/:id" element={<TraineeDetail />} />
        <Route path="/admin/createProgram/:id" element={<CreateProgram />} />
        <Route path="/admin/createTemplate" element={<CreateTemplates />} />
        <Route path="/admin/library" element={<AdminLibrary />} />
        <Route path="/admin/trainees/programInfo/:id" element={<ProgramInfo />} />
      </Route>
      <Route element={<CoachDashboardLayout />}>
        <Route index path="/coach/dashboard" element={<CoachDashboard />} />
        <Route index path="/coach/trainees" element={<CoachTrainees />} />
        <Route path="/coach/exerciseLibrary" element={<CoachExerciseLibrary />} />
        <Route path="/coach/Calendar" element={<CoachCalendar />} />
        <Route path="/coach/workoutPanel" element={<CoachWorkout />} />
      </Route>
      <Route element={<TraineeLayout />}>
        <Route index path="/trainee/dashboard" element={<TraineeDashboard />} />
        <Route path="/trainee/calender" element={<TraineeCalender />} />
        <Route path="/trainee/profile" element={<TraineeProfile />} />
        <Route path="/trainee/workout/:id" element={<TraineeWorkout />} />
        <Route path="/trainee/changePassword" element={<TraineePassword />} />
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/verification/:id" element={<Verification />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/400" element={<Error400 />} />
      <Route path="/404" element={<Error404 />} />
      <Route path="/500" element={<Error500 />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </>
  )
)

export default router
