import { Images } from 'assets'
import DatePickerField from 'components/compounds/DatePickerField'
import Dropdown from 'components/compounds/DropDown'
import CustomToastify from 'components/compounds/Toast'
import { constants } from 'constant'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getCoachAvailability } from 'store/programSlice/programActions'
import { getTime } from 'utils'
import CoachesDropdown from './components/coachesDropDown'
import ProgramWorkoutHeader from './components/programWorkoutHeader'
import RepetitionDropDown from './components/repetitionDropDown'

export default function ProgramWorkout({
  workoutlist,
  setStage2,
  totalWorkouts,
  setTotalWorkouts,
  workoutContinuety,
  setWorkoutContinuty,
  programName,
  modifiedState,
  setModifiedState,
  selectedDate,
  setSelectedDate,
  showDatePicker,
  setShowDatePicker,
}) {
  const { t } = useTranslation()
  const { direction } = useSelector((state) => state.language)
  const { coaches } = useSelector((state) => state.gymAdmin)
  const { customerDetail } = useSelector((state) => state.user)

  const { WEEK_DAYS, STANDARD_TIME } = constants
  const dispatch = useDispatch()
  useEffect(() => {
    const modifyData = () => {
      const updatedState = { ...modifiedState }

      workoutlist.forEach((workout, index) => {
        const { workoutName, exercises } = workout

        const existingWorkout = updatedState[workoutName]

        const modifiedExercises = exercises.map((exercise) => {
          const { id, Status, Resistance_value, Tracking_value, isband, set, Sets } = exercise
          return {
            ExerciseId: id,
            Status: Status || 'Draft',
            Resistance_value: Resistance_value || 0,
            Tracking_value: Tracking_value || 0,
            isband: isband || false,
            set: set || 0,
            sets: Sets,
            intensityTracking: exercise?.intensityTracking ? exercise?.intensityTracking : false,
          }
        })
        const coach = coaches.find((val) => val?.id === customerDetail?.User?.id)?.Coach?.id || null

        if (existingWorkout !== undefined) {
          const updatedWorkout = existingWorkout.map((workoutObj) => {
            return {
              ...workoutObj,

              Exercises: modifiedExercises,
            }
          })

          updatedState[workoutName] = updatedWorkout
        } else {
          const workoutObject = {
            CoachId: coach,
            type: 'personal',
            duration: 60,
            dayAndTime: { day: null, time: null },
            Exercises: modifiedExercises,
            availableHours: [],
          }

          updatedState[workoutName] = [workoutObject]
        }
      })

      Object.keys(updatedState).forEach((workoutName) => {
        if (!workoutlist.some((workout) => workout.workoutName === workoutName)) {
          delete updatedState[workoutName]
        }
      })

      setModifiedState(updatedState)
    }

    modifyData()
    // eslint-disable-next-line
  }, [workoutlist])

  const handleTypeChange = (key, value, index) => {
    setModifiedState((prevState) => {
      const updatedState = { ...prevState }
      if (updatedState.hasOwnProperty(key)) {
        updatedState[key][index].type = value
        if (value === 'openGym' || value === 'online') {
          updatedState[key][index].dayAndTime = { day: null, time: null }
          updatedState[key][index].isActive = false
        }
        if (value === 'personal' && !updatedState[key][index].duration) {
          updatedState[key][index].duration = 60
        }
      }

      return updatedState
    })
  }

  const addAnotherWorkoutTimeline = async (key, index) => {
    await setModifiedState((prevState) => {
      const updatedState = { ...prevState }

      if (updatedState.hasOwnProperty(key)) {
        const coach = coaches.find((val) => val?.id === customerDetail?.User?.id)?.Coach?.id || null

        // Set isActive to false for all existing workouts
        updatedState[key] = updatedState[key].map((workout) => ({
          ...workout,
          isActive: false,
        }))

        const defaultWorkout = {
          CoachId: coach,
          type: 'personal',
          duration: 60,
          dayAndTime: { day: null, time: null },
          Exercises: updatedState[key][0].Exercises,
          isActive: true,
          availableHours: updatedState[key][0].availableHours || [],
        }

        updatedState[key].push(defaultWorkout)
      }

      return updatedState
    })
  }

  const removeWorkoutTimeline = (key, idx) => {
    setModifiedState((prevState) => {
      const updatedState = { ...prevState }
      updatedState[key].splice(idx, 1)
      return updatedState
    })
  }

  const handleSelectedCoach = async (key, value, index) => {
    setModifiedState((prevState) => {
      const updatedState = { ...prevState }
      if (updatedState.hasOwnProperty(key)) {
        updatedState[key][index].CoachId = value.Coach.id
        updatedState[key][index].dayAndTime = { day: null, time: null }
        const duration = updatedState[key][index]?.duration
        dispatch(getCoachAvailability(value.Coach.id, selectedDate, duration, t, direction)).then((response) => {
          if (response && response.availableHour) {
            setModifiedState((prevState) => {
              const updatedState = { ...prevState }
              updatedState[key][index].availableHours = response.availableHour
              return updatedState
            })
          }
        })
      }

      return updatedState
    })
  }
  const handleSelectedDuration = async (key, value, index) => {
    setModifiedState((prevState) => {
      const updatedState = { ...prevState }
      if (updatedState.hasOwnProperty(key)) {
        updatedState[key][index].duration = value
        updatedState[key][index].dayAndTime = { day: null, time: null }
        const coachId = updatedState[key][index]?.CoachId
        dispatch(getCoachAvailability(coachId, selectedDate, value, t, direction)).then((response) => {
          if (response && response.availableHour) {
            setModifiedState((prevState) => {
              const updatedState = { ...prevState }
              updatedState[key][index].availableHours = response.availableHour
              return updatedState
            })
          }
        })
      }

      return updatedState
    })
  }
  const handleIsActive = async (key, index) => {
    let isCoachNull = false
    let copyState = { ...modifiedState }

    for (const workoutKey in copyState) {
      if (workoutKey === key) {
        // eslint-disable-next-line
        copyState[workoutKey].forEach((workout, i) => {
          if (i === index && workout.CoachId === null) {
            CustomToastify(t('SELECT_COACH'), '', '', 'bg-toastFaildbg', direction, Images.toastFail, false)
            isCoachNull = true
          }
        })
      }
    }

    if (!isCoachNull) {
      setModifiedState((prevState) => {
        const updatedState = { ...prevState }

        let showAlert = false
        for (const workoutKey in updatedState) {
          const activeWorkout = updatedState[workoutKey].find((workout) => workout.isActive)

          if (activeWorkout && activeWorkout.dayAndTime && activeWorkout.dayAndTime.day !== null && activeWorkout.dayAndTime.time === null) {
            showAlert = true
            break
          }
        }

        if (showAlert) {
          CustomToastify(t('SELECT_TIME_SLOT'), '', '', 'bg-toastFaildbg', direction, Images.toastFail, false)
          return prevState
        }

        for (const workoutKey in updatedState) {
          if (workoutKey === key) {
            updatedState[workoutKey] = updatedState[workoutKey].map((workout, i) => ({
              ...workout,
              isActive: i === index,
            }))
          } else {
            updatedState[workoutKey] = updatedState[workoutKey].map((workout) => ({
              ...workout,
              isActive: false,
            }))
          }
        }

        return updatedState
      })

      const coachId = modifiedState[key][index].CoachId
      const duration = modifiedState[key][index].duration

      const response = await dispatch(getCoachAvailability(coachId, selectedDate, duration, t, direction))

      if (response && response.availableHour) {
        setModifiedState((prevState) => {
          const updatedState = { ...prevState }
          updatedState[key][index].availableHours = response.availableHour
          return updatedState
        })
      }
    }
  }

  const UnselectCoach = () => {
    setModifiedState((prevState) => {
      const updatedState = { ...prevState }

      for (const key in updatedState) {
        updatedState[key] = updatedState[key].map((workout) => ({
          ...workout,

          dayAndTime: { day: null, time: null },
          availableHours: [],
          isActive: false,
        }))
      }

      return updatedState
    })
  }
  return (
    <div className="flex flex-col w-[55%]   rounded-[12px] gap-4">
      <div className="flex flex-col w-full h-32 py-4 px-6 bg-secondaryBackground  rounded-xl">
        <div className={` ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-[20px] font-medium text-textLightGray leading-6`}>
          {t('WORKOUTS')}
        </div>
        <div className="flex flex-row items-center gap-2 pt-2">
          <div className="flex flex-col w-40 h-14  rounded-xl bg-textFieldColor ">
            <p className={`${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-xs leading-4 text-textLightGrey px-4 pt-2`}>
              {t('TOTAL_WORKOUTS')}
            </p>
            <input
              className={`bg-textFieldColor text-white w-[76px]  rounded-xl px-4 ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
              type="number"
              onChange={(e) => {
                let value = parseInt(e.target.value, 10)

                if (isNaN(value) || value < 1 || value > constants.MAX_WORKOUT_VALUE) {
                  value = e.target.value === '' ? '' : totalWorkouts
                }

                setTotalWorkouts(value)
              }}
              value={totalWorkouts}
            />
          </div>

          <div className="flex flex-col w-52 rounded-xl ">
            <DatePickerField
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              showDatePicker={showDatePicker}
              setShowDatePicker={setShowDatePicker}
              required={true}
              dir={direction}
              t={t}
              title="StartDate"
              filter={true}
              handleSelect={UnselectCoach}
            />
          </div>
          <div className="flex flex-col w-48 h-14 justify-center items-center  rounded-xl bg-textFieldColor ">
            <RepetitionDropDown
              options={constants.WORKOUT_REPETITION}
              className=""
              value={workoutContinuety}
              setValue={setWorkoutContinuty}
              disable={false}
              t={t}
              dir={direction}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full h-full py-4 px-6 bg-secondaryBackground  rounded-xl overflow-scroll">
        <ProgramWorkoutHeader workoutlist={workoutlist} programName={programName} t={t} />
        <div className="flex flex-col h-full w-full py-6 ">
          {Object?.entries(modifiedState)?.map(([key, value], index) => {
            return (
              <div key={key} className="flex w-full flex-col ">
                <p
                  className={`${
                    direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                  } text-base font-medium leading-5 text-textLightGray pb-2`}
                >
                  {key}
                </p>
                {value.map((item, idx) => {
                  return (
                    <div className="flex flex-row w-full gap-2 items-center ">
                      <Dropdown
                        options={constants.WORKOUT_TYPE}
                        className={`my-2 w-[22%]`}
                        title={t('Workout Type')}
                        value={item?.type}
                        setValue={(val) => {
                          handleTypeChange(key, val, idx)
                        }}
                        disable={false}
                        t={t}
                        dir={direction}
                      />
                      <CoachesDropdown
                        options={coaches}
                        className={`my-2 w-[22%]`}
                        title={t('COACHES')}
                        value={coaches.find((val) => val?.Coach.id === item?.CoachId)?.firstName || ''}
                        setValue={(val) => {
                          handleSelectedCoach(key, val, idx)
                        }}
                        disable={false}
                        t={t}
                        dir={direction}
                      />

                      {item.type !== 'openGym' && item.type !== 'online' && (
                        <>
                          <Dropdown
                            options={STANDARD_TIME}
                            className="my-2 w-[22%] "
                            title={t('WORKOUT_TIME')}
                            value={item.duration}
                            setValue={(val) => {
                              handleSelectedDuration(key, val, idx)
                            }}
                            t={t}
                            dir={direction}
                            isTime={true}
                          />
                          <div
                            className={`flex flex-col w-[30%] h-[56px] rounded-[12px]  bg-textFieldColor py-2 px-2 cursor-pointer ${
                              item?.isActive === true && 'border-[1.5px] border-[#006684]'
                            }`}
                            onClick={() => {
                              handleIsActive(key, idx)
                            }}
                          >
                            <p
                              className={`${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-xs leading-4 font-normal ${
                                item?.isActive === true ? 'text-[#65B1F4]' : 'text-textLightGrey'
                              } `}
                            >
                              {t('DAY_AND_TIME')}
                            </p>
                            <div className="flex flex-row justify-between items-center">
                              <p
                                className={`${
                                  direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                                } font-normal text-base leading-6 tracking-wide text-textLightGrey`}
                              >
                                {item?.dayAndTime?.time != null ? getTime(item?.dayAndTime?.time) : ''}{' '}
                                {item?.dayAndTime?.day != null
                                  ? direction === 'ltr'
                                    ? WEEK_DAYS.find((days) => days.day === item?.dayAndTime?.day).label
                                    : WEEK_DAYS.find((days) => days.day === item?.dayAndTime?.day).label_he
                                  : ''}
                              </p>
                              {item?.dayAndTime?.time != null && item?.dayAndTime?.day != null && (
                                <div>
                                  <img src={Images.Info} alt="" height={35} width={35} className={` px-2 items-center`} />
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                      {
                        <>
                          {modifiedState &&
                          idx === 0 &&
                          Object.values(modifiedState).reduce((count, workoutList) => count + workoutList?.length, 0) < 7 ? (
                            <div
                              className="mx-2 cursor-pointer"
                              onClick={() => {
                                addAnotherWorkoutTimeline(key, idx)
                              }}
                            >
                              <img src={Images.AddCircle} alt="" height={24} width={24} className={`items-center`} />
                            </div>
                          ) : (
                            <div
                              className=" cursor-pointer "
                              onClick={() => {
                                // Add the logic to handle the minus icon functionality here
                                removeWorkoutTimeline(key, idx)
                              }}
                            >
                              <img src={Images.Minus} alt="" height={44} width={44} className={`items-center`} />
                            </div>
                          )}
                        </>
                      }
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
