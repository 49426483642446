import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const WorkoutStatus = ({ status, onChange,show_in_board=false }) => {
  const { t } = useTranslation()
  const { direction } = useSelector((state) => state.language)
  const { loading } = useSelector((state) => state.workout)

  if (status === 'completed') {
    return (

      <div
        className={`flex items-center space-x-1 rtl:space-x-reverse text-xs whitespace-nowrap ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
          }`}
      >{`⛳️ ${t('COMPLETED')}`}</div>
    )
  } else if (status === 'active' || show_in_board) {
    return (
      <div
        className={`flex items-center space-x-1 rtl:space-x-reverse text-xs whitespace-nowrap ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
          }`}
      >{`💪 ${t('IN_PROGRESS')}`}</div>
    )
  } else {
    return (
      <div className={`flex items-center space-x-1 rtl:space-x-reverse text-xs text-activeText  whitespace-nowrap ${loading ? ' cursor-not-allowed' : "cursor-pointer"} `} onClick={onChange}>
        {t('START_WORKOUT')}
      </div>
    )
  }
}

export { WorkoutStatus }
