import { useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const LabelInput = ({
  name,
  control,
  error,
  errorMessage,
  required,
  fullWidth,
  className,
  multiline,
  rows,
  onChange,
  disabled,
  label,
  type,
  icon,
  placeholderValue,
  isTypePassword,
  setPasswordShown,
  passwordShown,
  dir,
  isRequired,
  showLable = true,
  margin,
  limit,
}) => {
  const { t } = useTranslation()

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur()

    e.stopPropagation()
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  const [isInputFocused, setIsInputFocused] = useState(false)

  const handleInputFocus = () => {
    setIsInputFocused(true)
  }

  const handleInputBlur = () => {
    setIsInputFocused(false)
  }

  return (
    <div className="flex flex-col w-full">
      <div
        className={`flex flex-col  h-[56px] w-full ${margin != undefined ? margin : 'mt-4 '}     rounded-[12px] h ${
          disabled && 'opacity-50 bg-[#5A5F66]'
        } ${isInputFocused && !error ? 'border-2 border-[#006684]' : ''} ${error ? 'border-2 border-error' : ''} group `}
      >
        <label
          className={`flex  ${
            dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
          } rounded-t-[12px]  text-[12px] bg-textFieldColor  px-3  group-hover:bg-borderColor    `}
        >
          {label && showLable === true && (
            <p className={`${isInputFocused && !error ? '!text-[#65B1F4] pt-1' : 'text-white'} ${error ? '!text-error pt-1' : 'text-white'} `}>
              {label}
              {isRequired && <span className="text-[red] px-1">*</span>}
            </p>
          )}
        </label>
        <div
          className={`flex flex-row h-full w-full  items-center justify-between rounded-b-[12px] ${showLable === false && 'rounded-t-[12px]'}
          bg-textFieldColor px-1 group-hover:bg-borderColor`}
        >
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <input
              {...field}
              onChange={(event) => {
                onChange ? onChange(event, field) : field.onChange(event)
              }}
              value={field?.value?.label ? field.value.label : field.value}
              className={`${className} w-full bg-textFieldColor text-white px-2 text-[16px] ${
                dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
              } group-hover:bg-borderColor  ${fullWidth ? 'w-full' : ''}`}
              label={label}
              type={type ? type : 'text'}
              placeholder={placeholderValue ? placeholderValue : ''}
              required={required}
              disabled={disabled}
              onWheel={numberInputOnWheelPreventChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              maxLength={limit ?? undefined}
            />
            )}
          />
          {icon && <img src={icon} alt="" height={40} width={45} className={`p-3 items-center `} />}
        </div>
      </div>
      {error && <p className={`form-errors flex flex-col px-1 ${dir === 'rtl' ? 'text-right' : 'text-left'} `}>{t(errorMessage)}</p>}
    </div>
  )
}

export { LabelInput }
