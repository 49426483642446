import { Images } from 'assets'
import { SearchInput } from 'components/compounds/SearchInput'
import { constants } from 'constant'
import { useDebounce, useOutsideClick } from 'hooks'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getProgramExercise, getProgramFilter } from 'store/programSlice/programActions'
import { getAllTemplate } from 'store/templateSlice/templateActions'
import { handleFilterSelection } from 'utils'
export default function ProgramListHeader(props) {
  const { PROGRAMFILTEROPTIONS } = constants

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { stage2, stateData, traineeId } = props
  const { direction } = useSelector((state) => state.language)
  const { customerDetail } = useSelector((state) => state.user)
  const { currentTrainee } = useSelector((state) => state.gymAdmin)
  const [searchInput, setSearchInput] = useState('')
  const filtersRef = useRef()
  const [filtersMenu, setFiltersMenu] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState('')
  const [activeFilter, setActiveFilter] = useState()
  const [selectedFilterMenu, setSelectedFilterMenu] = useState(false)

  const { programFilters } = useSelector((state) => state.program)

  const debouncedSearchTerm = useDebounce(searchInput, 500)
  const [isMuscles, setIsMuscle] = useState(false)

  const [temp1, setTemp1] = useState({
    MUSCLES: [],
    RESISTANCE: [],
    TRACKING: [],
    MACHINE: [],
    MAIN_EXERCISE: [],
    MUSCLE_TYPE: [],
    TAGS: [],
  })

  const setSearch = (event) => {
    setSearchInput(event.target.value)
  }
  useEffect(() => {
    const getData = async () => {
      dispatch(getAllTemplate(customerDetail.id, currentTrainee?.id, t, direction))
      dispatch(getProgramFilter(customerDetail?.id))
    }
    getData()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const getProgramsExerciseData = async () => {
      if (
        temp1.MUSCLES.length > 0 ||
        temp1.RESISTANCE.length > 0 ||
        temp1.TRACKING.length > 0 ||
        temp1.MACHINE.length > 0 ||
        temp1.TAGS.length > 0 ||
        temp1.MAIN_EXERCISE.length > 0
      ) {
        if (temp1.MAIN_EXERCISE.length > 0) {
          const exerciseIds = programFilters?.MAIN_EXERCISE.filter((exercise) => temp1.MAIN_EXERCISE.includes(exercise.label)).map((exercise) =>
            parseInt(exercise.id)
          )

          let data = {
            MUSCLES: temp1.MUSCLES,
            RESISTANCE: temp1.RESISTANCE,
            TRACKING: temp1.TRACKING,
            MACHINE: temp1.MACHINE,
            MAIN_EXERCISE: exerciseIds,
            MUSCLE_TYPE: temp1.MUSCLE_TYPE,
            TAGS: temp1.TAGS,
          }

          dispatch(getProgramExercise(customerDetail.id, currentTrainee?.id, t, direction, { search: debouncedSearchTerm, filter: data }))
          return
        }

        dispatch(getProgramExercise(customerDetail.id, currentTrainee?.id, t, direction, { search: debouncedSearchTerm, filter: temp1 }))
        return
      } else if (debouncedSearchTerm && debouncedSearchTerm.length > 0) {
        dispatch(getProgramExercise(customerDetail.id, currentTrainee?.id, t, direction, { search: debouncedSearchTerm, filter: temp1 }))
        return
      } else {
        dispatch(getProgramExercise(customerDetail.id, currentTrainee?.id, t, direction))
        return
      }
    }
    getProgramsExerciseData()
    // eslint-disable-next-line
  }, [temp1, debouncedSearchTerm, currentTrainee])

  const onClickOutsideFiltersDiv = () => {
    setFiltersMenu(false)
    setSelectedFilterMenu(false)
    setIsMuscle(false)
  }

  useOutsideClick(filtersRef, onClickOutsideFiltersDiv)
  const navigation = useNavigate()

  return (
    <div className="flex w-full flex-col">
      <div className="flex flex-row w-full px-8 py-5 items-center ">
        <div className="flex gap-1 justify-center items-center text-white">
          <p
            className="text-[#C2C7CF] text-[12px] leading-4 text-center ltr:font-primary-rg rtl:font-primary-he-rg hover:border-white hover:border-b-2 cursor-pointer"
            onClick={() => {
              navigation('/admin/trainees')
            }}
          >
            {' '}
            {t('TRAINEES')}
          </p>
          <p>/</p>
          <p
            className={`text-white text-[12px] leading-4 font-normal ltr:font-primary-rg rtl:font-primary-he-rg 
              hover:border-white hover:border-b-2 cursor-pointer `}
            onClick={() => {
              navigation(`/admin/trainees/traineeDetail/${traineeId}`, { state: { active: `INFO` } })
            }}
          >
            {' '}
            {stateData?.traineeName}
          </p>
          <p>/</p>
          {stateData?.active != null && (
            <>
              <p
                className="text-white text-[12px] leading-4 font-normal ltr:font-primary-rg rtl:font-primary-he-rg hover:border-white hover:border-b-2 cursor-pointer  "
                onClick={() => {
                  navigation(`/admin/trainees/traineeDetail/${traineeId}`, { state: { active: stateData?.active } })
                }}
              >
                {' '}
                {t(stateData?.active)}
              </p>
              <p>/</p>{' '}
            </>
          )}
          <p className="text-breadCrumb font-primary-rg text-[12px] leading-4 font-bold ">
            {stateData?.programId ? t('Edit program') : t('CREATE_PROGRAM')}
          </p>
        </div>
      </div>
      <div className="flex flex-row w-full px-8 py-2 justify-between items-center">
        <div className="text-[#C2C7CF] leading-7 font-primary-rg text-[24px]">{t('CREATE_PROGRAM')}</div>
      </div>
      {!stage2 && (
        <div className="flex flex-row w-full px-8 py-2 gap-4 items-center">
          <SearchInput
            radius={'rounded-[50px]'}
            placeholder={t('SEARCH')}
            className="flex flex-1"
            disableUnderline
            fullWidth
            value={searchInput}
            inputProps={{
              'aria-label': 'Search',
            }}
            onChange={(ev) => setSearch(ev)}
            icon={Images.Search}
          />
          <div ref={filtersRef} className="relative box-border">
            <div
              className={`flex flex-row w-[156px] h-[40px] items-center rounded-[12px] bg-primary
            border-borderActive border-[1.5px]`}
              onClick={() => {
                setFiltersMenu(!filtersMenu)
                setSelectedFilterMenu(false)
              }}
            >
              <img src={Images.Filter} alt="" height={40} width={45} className={`p-3 items-center `} onClick={() => {}} />
              <p className="text-white text-center text-[14px] font-primary-rg">{t('ADDING_FILTER')}</p>
            </div>
            {filtersMenu ? (
              <div
                className={`absolute top-11 z-10 py-3 bg-statusBg rounded-[12px] w-[156px] max-h-[200px] overflow-auto shadow-xl ${
                  filtersMenu ? 'border-borderActive border-[2px]' : ''
                }`}
              >
                {PROGRAMFILTEROPTIONS.map((opt, i) => (
                  <p
                    key={i}
                    className="px-2 py-1 text-textGrey text-[12px] font-primary-rg hover:bg-checkoutHover cursor-pointer"
                    onClick={() => {
                      if (opt === 'MUSCLES') {
                        setActiveFilter(programFilters?.MUSCLES)
                      } else if (opt === 'MAIN_EXERCISE') {
                        setActiveFilter(programFilters?.MAIN_EXERCISE)
                      } else if (opt === 'MACHINE') {
                        setActiveFilter(programFilters?.MACHINE)
                      } else if (opt === 'TAGS') {
                        setActiveFilter(programFilters?.TAGS)
                      } else if (opt === 'RESISTANCE') {
                        setActiveFilter(programFilters?.RESISTANCE)
                      } else if (opt === 'TRACKING') {
                        setActiveFilter(programFilters?.TRACKING)
                      }
                      setSelectedFilter(opt)
                      setFiltersMenu(false)
                      setSelectedFilterMenu(true)
                    }}
                  >
                    {t(opt)}
                  </p>
                ))}
              </div>
            ) : null}

            {/* Main Selected Filter */}
            {selectedFilterMenu ? (
              <div
                className={`absolute top-11 z-10 py-3 bg-statusBg rounded-[12px] w-[156px] max-h-[200px] overflow-auto shadow-xl ${
                  selectedFilterMenu ? 'border-borderActive border-[2px]' : ''
                }`}
              >
                <div
                  className="flex flex-row hover:bg-checkoutHover cursor-pointer"
                  onClick={() => {
                    setSelectedFilterMenu(false)
                    setFiltersMenu(true)
                  }}
                >
                  <img src={direction === 'rtl' ? Images.backArrow : Images.backLessArrow} alt="" height={8} width={25} className={'p-1'} dir="ltr" />
                  <p className=" py-1 text-textGrey text-[12px] font-primary-rg ">{t(selectedFilter)}</p>
                </div>
                {activeFilter.map((opt, i) => (
                  <div
                    key={i}
                    className="flex flex-row px-2 py-1  justify-between font-primary-rg hover:bg-checkoutHover cursor-pointer"
                    dir={direction}
                    onClick={() => {
                      if (selectedFilter === 'MUSCLES') {
                        if (!temp1['MUSCLES'].includes(opt.label)) {
                          setSelectedFilterMenu(false)
                          setIsMuscle(opt)
                        }
                      }
                      handleFilterSelection(selectedFilter, opt, temp1, setTemp1)
                    }}
                  >
                    <p
                      className={`text-[12px] font-primary-rg ${
                        temp1.MUSCLES.includes(opt.label) ||
                        temp1.MAIN_EXERCISE.includes(opt.label) ||
                        temp1.MACHINE.includes(opt.label) ||
                        temp1.TAGS.includes(opt.label) ||
                        temp1.RESISTANCE.includes(opt.label) ||
                        temp1.TRACKING.includes(opt.label)
                          ? 'text-activeText'
                          : 'text-textGrey'
                      } `}
                    >
                      {t(opt.label)}
                    </p>
                    <p
                      className={`text-[12px] font-primary-rg ${
                        temp1.MUSCLES.includes(opt.label) ||
                        temp1.MAIN_EXERCISE.includes(opt.label) ||
                        temp1.MACHINE.includes(opt.label) ||
                        temp1.TAGS.includes(opt.label) ||
                        temp1.RESISTANCE.includes(opt.label) ||
                        temp1.TRACKING.includes(opt.label)
                          ? 'text-activeText'
                          : 'text-textGrey'
                      } `}
                    >
                      {t(opt.count)}
                    </p>
                  </div>
                ))}
              </div>
            ) : null}
            {!selectedFilterMenu && isMuscles ? (
              <div
                className={`absolute top-11 py-3 bg-statusBg rounded-[12px] w-[156px] max-h-[200px] overflow-auto shadow-xl ${
                  selectedFilterMenu ? 'border-borderActive border-[2px]' : ''
                }`}
              >
                <div
                  className="flex flex-row hover:bg-checkoutHover cursor-pointer"
                  onClick={() => {
                    setSelectedFilterMenu(true)
                    setIsMuscle(false)
                  }}
                >
                  <img src={direction === 'rtl' ? Images.backArrow : Images.backLessArrow} alt="" height={8} width={25} className={'p-1'} dir="ltr" />
                  <p className={`py-1 text-textGrey text-[12px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} `}>
                    {t(isMuscles?.label)}
                  </p>
                </div>

                <div className="flex flex-col ">
                  <div
                    className={`flex flex-row px-2 py-1  justify-between ${
                      direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                    } hover:bg-checkoutHover cursor-pointer `}
                    dir={direction}
                    onClick={() => {
                      handleFilterSelection('MUSCLE_TYPE', isMuscles, temp1, setTemp1, 'primary')
                    }}
                  >
                    <p
                      className={`text-[12px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} ${
                        temp1.MUSCLE_TYPE.some((obj) => obj.label === isMuscles.label && obj.isPrimary === true) ? 'text-activeText' : 'text-textGrey'
                      } `}
                    >
                      {t('Primary')}
                    </p>
                    <p
                      className={`text-[12px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} ${
                        temp1.MUSCLE_TYPE.some((obj) => obj.label === isMuscles.label && obj.isPrimary === true) ? 'text-activeText' : 'text-textGrey'
                      } `}
                    >
                      {isMuscles.primary}
                    </p>
                  </div>
                  <div
                    className={`flex flex-row px-2 py-1  justify-between ${
                      direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                    } hover:bg-checkoutHover cursor-pointer `}
                    dir={direction}
                    onClick={() => {
                      handleFilterSelection('MUSCLE_TYPE', isMuscles, temp1, setTemp1, 'assitant')
                    }}
                  >
                    <p
                      className={`text-[12px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} ${
                        temp1.MUSCLE_TYPE.some((obj) => obj.label === isMuscles.label && obj.isAssistance === true)
                          ? 'text-activeText'
                          : 'text-textGrey'
                      } `}
                    >
                      {t('Assistent')}
                    </p>
                    <p
                      className={`text-[12px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} ${
                        temp1.MUSCLE_TYPE.some((obj) => obj.label === isMuscles.label && obj.isAssistance === true)
                          ? 'text-activeText'
                          : 'text-textGrey'
                      } `}
                    >
                      {isMuscles.isAssistance}
                    </p>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          {/* eslint-disable-next-line */}
          {Object.keys(temp1).map((value, index) => {
            if (value !== 'MUSCLE_TYPE') {
              return (
                temp1[value] &&
                temp1[value].length > 0 &&
                temp1[value]?.map((item) => {
                  return (
                    <div className="flex flex-row items-center  gap-1 bg-statusBg px-3 py-1 rounded-[16px] hover:border-borderActive hover:border-[2px]">
                      <p className="text-[14px] text-textGrey  font-primary-rg">{t(item)}</p>
                      <img
                        src={Images.CloseCircle}
                        alt=""
                        height={10}
                        width={15}
                        className={`ml-1 mr-2 cursor-pointer lg:mr-0 lg:ml-0 xl:mr-0 xl:ml-0 `}
                        onClick={() => {
                          if (index === 0) {
                            const updatedItems = temp1['MUSCLES'].filter((i) => i !== item)
                            setTemp1({
                              ...temp1,
                              MUSCLES: updatedItems,
                              MUSCLE_TYPE: [],
                            })
                          } else if (index === 1) {
                            const updatedItems = temp1['RESISTANCE'].filter((i) => i !== item)
                            setTemp1({
                              ...temp1,
                              RESISTANCE: updatedItems,
                            })
                          } else if (index === 2) {
                            const updatedItems = temp1['TRACKING'].filter((i) => i !== item)
                            setTemp1({
                              ...temp1,
                              TRACKING: updatedItems,
                            })
                          } else if (index === 3) {
                            const updatedItems = temp1['MACHINE'].filter((i) => i !== item)
                            setTemp1({
                              ...temp1,
                              MACHINE: updatedItems,
                            })
                          } else if (index === 4) {
                            const updatedItems = temp1['MAIN_EXERCISE'].filter((i) => i !== item)
                            setTemp1({
                              ...temp1,
                              MAIN_EXERCISE: updatedItems,
                            })
                          } else if (index === 6) {
                            const updatedItems = temp1['TAGS'].filter((i) => i !== item)
                            setTemp1({
                              ...temp1,
                              TAGS: updatedItems,
                            })
                          }
                        }}
                      />
                    </div>
                  )
                })
              )
            }
          })}
        </div>
      )}
    </div>
  )
}
