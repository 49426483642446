import styled from 'styled-components'

const SelectContainer = styled.div`
  .select-container {
    width: 100%;
    height: 40px;
  }

  .select-control {
    background: #5a5f66;
    border-radius: 12px;
    border: none;
  }

  .select-indicator-separator {
    display: none;
  }

  .select-menu {
    background: #323e44;
  }

  .select-option {
    cursor: pointer;
    background-color: #323e44;
    color: #ffffff;

    &:hover {
      background-color: #29343a;
    }
  }

  .select-single-value {
    color: #ffffff;
  }

  .select-placeholder {
    color: #ffffff90;
  }
`

export default SelectContainer
