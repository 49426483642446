import { Images } from 'assets'
import { Modal } from 'components/compounds/Modal'
import PrimaryButton from 'components/elements/PrimaryButton'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ProfileAvatar } from './ProfileAvatar'

export default function MusclesDetail({ show, setShow, data, muscleData }) {
  const { t } = useTranslation()
  const { direction } = useSelector((state) => state.language)

  const handleClose = () => {
    setShow && setShow(false)
  }
  const handleNext = async () => {
    const keys = Object.keys(data)

    const currentIndex = keys.indexOf(`${show?.id}`)

    const nextIndex = (currentIndex + 1) % keys.length
    const newKey = keys[nextIndex]

    const selectedMuscle = muscleData?.find((val) => val?.id == newKey)

    setShow(selectedMuscle)
  }
  const handlePre = async () => {
    const keys = Object.keys(data)

    const currentIndex = keys.indexOf(`${show?.id}`)

    const nextIndex = (currentIndex - 1 + keys.length) % keys.length
    const newKey = keys[nextIndex]

    const selectedMuscle = muscleData?.find((val) => val?.id == newKey)

    setShow(selectedMuscle)
  }

  const FooterComponent = (direction) => {
    return (
      <div className="flex flex-row h-16 justify-between items-center px-4  xl:px-6 2xl:px-12 border-t-[1px] border-borderColor  bg-sidebar ios-position w-[400px] xl:w-[595px]">
        <PrimaryButton
          text={t('NEXT')}
          type="submit"
          disabled={false}
          isIcon={false}
          onClick={() => handleNext(show?.stage)}
          className="h-12 px-4 rounded-[12px] shadow-2xl"
          bgColor="bg-borderActive hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
          textClass={`text-white ltr:font-primary-rg rtl:font-primary-he-rg`}
        />
        <PrimaryButton
          text={t('PREVIOUS')}
          type="submit"
          disabled={false}
          isIcon={false}
          onClick={() => handlePre(show?.stage)}
          className=" h-12 px-4 rounded-[12px] shadow-2xl"
          textClass={`text-white ltr:font-primary-rg rtl:font-primary-he-rg`}
        />
      </div>
    )
  }

  return (
    <Modal
      className={`h-full  ${direction === 'ltr' ? 'rounded-l-3xl' : 'rounded-r-3xl'}  w-[400px] xl:w-[595px]`}
      childrenClass="overflow-hidden"
      open={show}
      title={`${show?.muscleName}`}
      handleClose={handleClose}
      handleSubmit={() => {}}
      loader={false}
      isEdited={false}
      disabled={false}
      direction={direction}
      footer={<FooterComponent direction={direction} />}
      padding={false}
    >
      <div className=" h-full overflow-hidden relative py-4 px-8 gap-4 space-y-4">
        {data[show?.id].map((item) => (
          <>
            {item?.improvement != null && (
              <p className="ltr:font-primary-rg rtl:font-primary-he-rg text-base text-[#9FF976]">
                {t('Exercise improved')} {`${item?.improvement?.numberOfSetsImproved}/ ${item?.improvement?.numberOfSetsInTotal}`}
              </p>
            )}
            <div className={`min-h-12 bg-[#1E2A30] rounded-lg p-4`}>
              <div className="flex flex-row justify-between">
                <p className="ltr:font-primary-rg rtl:font-primary-he-rg text-sm text-white truncate"> {item?.exerciseName}</p>
                <p className="ltr:font-primary-rg rtl:font-primary-he-rg text-xs text-[#8C9198]">
                  {item?.status === 'completed' ? `${item?.completedSets} ${t('SETS')}` : t('Cancelled')}
                </p>
              </div>

              {item?.achievement != null && (
                <div className="w-full flex flex-row gap-3 items-center pt-2">
                  <img src={Images.fire} alt="Achieve" className=" h-6 w-6 cursor-pointer " />
                  <p className=" text-xs ltr:font-primary-rg rtl:font-primary-he-rg text-white">{t('New achievements')}</p>
                  <p className=" text-base ltr:font-primary-rg rtl:font-primary-he-rg text-white">{`${item?.achievement?.bestSetResistanceValue} ${t(
                    item?.achievement?.bestSetResistanceLable
                  )} ${item?.achievement?.bestSetTrackingValue} ${t(item?.achievement?.trackingGapLable)}`}</p>
                </div>
              )}
              {item?.improvement != null && (
                <div className="w-full flex flex-row gap-3 items-center pt-2">
                  <img src={Images.Achieve} alt="Achieve" className=" h-6 w-6 cursor-pointer " />
                  <p className=" text-xs ltr:font-primary-rg rtl:font-primary-he-rg text-white">{t('Improved')}</p>
                  <p className=" text-base ltr:font-primary-rg rtl:font-primary-he-rg text-white">
                    {`${item?.improvement?.numberOfSetsImproved}/${item?.improvement?.numberOfSetsInTotal}`} {t('SETS')}
                  </p>
                </div>
              )}
              {(item?.control_technique || item?.intensityTracking || item?.mobility_technique) && (
                <div className="w-full flex flex-row gap-3 items-center pt-2">
                  <img src={Images.Direction} alt="Achieve" className="  w-6 cursor-pointer " />
                  <p className=" text-xs ltr:font-primary-rg rtl:font-primary-he-rg text-white">{t('TECHNIQUE')}</p>
                  <div className="w-full overflow-x-scroll no-scrollbar flex flex-row gap-2">
                    {item?.control_technique && (
                      <div className="p-2 bg-[#373C42] rounded">
                        <p className=" text-xs ltr:font-primary-rg rtl:font-primary-he-rg text-white">{t('control')}</p>
                      </div>
                    )}
                    {item?.intensityTracking && (
                      <div className="p-2 bg-[#373C42] rounded">
                        <p className=" text-xs ltr:font-primary-rg rtl:font-primary-he-rg text-white">{t('muscle')}</p>
                      </div>
                    )}
                    {item?.mobility_technique && (
                      <div className="p-2 bg-[#373C42] rounded">
                        <p className=" text-xs ltr:font-primary-rg rtl:font-primary-he-rg text-white">{t('movement')}</p>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {item?.comments?.length > 0 && (
                <div className="flex flex-col space-y-2 py-2  max-h-28 overflow-scroll no-scrollbar">
                  {item?.comments?.map((comment, index) => (
                    <div className="flex flex-row gap-2 items-center cursor-pointer" key={comment?.id}>
                      <ProfileAvatar image={comment?.User?.avatar} name={comment?.User?.first_name} size={'large'} />
                      <div className="flex flex-col w-[87%]">
                        <div className="flex flex-wrap font-primary-rg font-medium text-white text-xs leading-4">{comment?.text}</div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </>
        ))}
      </div>
    </Modal>
  )
}
