import { useEffect, useRef, useState } from "react";

import Assets from "assets/images";
import { ActiveWorkout } from "components/PlayBoard/ActiveWorkout";
import { EmptyWorkoutsContainer } from "components/PlayBoard/EmptyWorkoutContainer";
import PlayWorkoutAddEvent from "components/PlayBoard/PlayWorkoutAddEvent";
import PlayWorkoutAddExercise from "components/PlayBoard/PlayWorkoutAddExercise";
import PlayWorkoutExerciseComments from "components/PlayBoard/PlayWorkoutExerciseComments";
import PlayWorkoutExerciseHistory from "components/PlayBoard/PlayWorkoutExerciseHistory";
import PlayWorkoutSwapExercises from "components/PlayBoard/PlayWorkoutSwapExercises";
import { ProfileAvatar } from "components/PlayBoard/ProfileAvatar";
import { TimeClock } from "components/PlayBoard/TimeClock";
import { WorkoutStatus } from "components/PlayBoard/WorkoutStatus";
import DeleteModal from "components/compounds/DeleteModal";
import { Loader } from "components/compounds/Loader";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getbandByGymId } from "store/gymAdminSlice/gymAdminActions";
import {
  ExerciseHistory,
  addActiveWorkout,
  getAllExerciseComments,
  getCoachDailyWorkouts,
  removeActiveWorkout,
  removeExerciseWorkout,
} from "store/workoutSlice/workoutActions";
import { useWorkoutSelector } from "store/workoutSlice/workoutReducer";
import { getCurrentTranslatedDateAndTime } from "utils";
import CreateExercise from "components/exerciseLibrary/CreateExercise";

const totalWorkoutsAcrossWholeDay = (timelineData) => {
  return (
    timelineData?.reduce((accumulator, currentArray) => {
      return accumulator + currentArray.workouts.length;
    }, 0) || 0
  );
};
const totalCompletedWorkoutsAcrossWholeDay = (timelineData) => {
  return (
    timelineData?.reduce((accumulator, currentArray) => {
      return (
        accumulator +
        currentArray.workouts.filter(
          (workout) => workout?.Workout?.status === "completed"
        ).length
      );
    }, 0) || 0
  );
};

const CoachWorkout = () => {
  const scrollDivRef = useRef(null);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [isRemoving, setRemoving] = useState(false);
  const [isCollapsed, setCollapsed] = useState(false);
  const [addEventModal, setAddEventModal] = useState(false);
  const [exerciseCommentsModal, setExerciseCommentsModal] = useState(false);
  const [exerciseSwapModal, setExerciseSwapModal] = useState(false);
  const [exerciseHistoryModal, setExerciseHistoryModal] = useState(false);
  const [onRemoveWorkoutModal, setOnRemoveWorkoutModal] = useState(false);
  const [onRemoveExerciseModal, setOnRemoveExerciseModal] = useState(false);
  const [addExerciseModal, setAddExerciseModal] = useState(false);
  const { direction } = useSelector((state) => state.language);
  const { coachDailyWorkouts, activeWorkouts } = useWorkoutSelector();
  const [showExerciseModal, setShowExerciseModal] = useState(false);
  const [exerciseID, setExerciseID] = useState(null);
  const { historyLoader } = useSelector((state) => state.workout);
  const { customerDetail } = useSelector((state) => state.user);
  const [isModelopen, setIsModelopen] = useState(false);
  const { t } = useTranslation();
  const coachId = customerDetail.User.Coach.id;
  const userId = customerDetail.User.id;
  const gymId = customerDetail.id;

  const getData = async () => {
    setLoading(true);
    const action = () => {
      setLoading(false);
    };
    dispatch(getCoachDailyWorkouts(coachId, action, t, direction));
  };

  useEffect(() => {
    dispatch(getbandByGymId(gymId, t, direction));
    getData();
    // eslint-disable-next-line
  }, []);

  const toggleCollapse = () => setCollapsed(!isCollapsed);
  const toggleAddEventModal = () => {
    setAddEventModal(!addEventModal);
  };
  const onAddWorkout = (workoutId) => {
    dispatch(addActiveWorkout(workoutId, t, direction));
  };

  const onScrollClick = () => {
    const scrollDirection = direction === "rtl" ? -400 : 400;
    scrollDivRef.current.scrollLeft += scrollDirection;
  };

  const onRemoveWorkout = (workoutId) => {
    setOnRemoveWorkoutModal(workoutId);
  };

  const onRemoveExercise = (workoutExerciseId, workoutId) => {
    setOnRemoveExerciseModal({
      workoutExerciseId,
      workoutId,
    });
  };

  const onRemoveWorkoutSubmit = () => {
    setRemoving(true);
    const action = () => {
      setRemoving(false);
      setOnRemoveWorkoutModal(false);
    };
    dispatch(
      removeActiveWorkout(onRemoveWorkoutModal, coachId, action, t, direction)
    );
  };

  const onRemoveExerciseSubmit = () => {
    setRemoving(true);
    const action = () => {
      setRemoving(false);
      setOnRemoveExerciseModal(false);
    };
    dispatch(
      removeExerciseWorkout(
        onRemoveExerciseModal.workoutExerciseId,
        onRemoveExerciseModal.workoutId,
        action,
        t,
        direction
      )
    );
  };
  const actionHistory = (data) => {
    setExerciseHistoryModal(data);
  };
  const onHistoryClick = (
    exerciseId,
    workoutExerciseId,
    traineeId,
    exerciseName
  ) => {
    const payload = {
      exerciseId: exerciseId,
      traineeId: traineeId,
      workoutExerciseId: workoutExerciseId,
      exerciseName: exerciseName,
    };

    setIsModelopen(true);
    dispatch(ExerciseHistory(payload, t, actionHistory, direction));
  };

  const onSwapClick = (
    workoutExerciseId,
    workoutId,
    exerciseId,
    traineeId,
    exerciseName,
    isAllPartial
  ) => {
    setExerciseSwapModal({
      workoutExerciseId,
      workoutId,
      exerciseId,
      traineeId,
      exerciseName,
      isAllPartial,
    });
  };

  const onCommentsClick = (exerciseId, workoutExerciseId, traineeId) => {
    setExerciseCommentsModal({
      exerciseId,
      workoutExerciseId,
      traineeId,
      coachId,
      userId,
    });
    dispatch(getAllExerciseComments(exerciseId, traineeId, t, direction));
  };

  const onAddExerciseClick = (workoutId, traineeId, isAllPartial) => {
    setAddExerciseModal({ workoutId, traineeId, isAllPartial });
  };
  const onClickExerciseInfo = (exerciseID) => {
    setExerciseID(exerciseID);
    setShowExerciseModal(!showExerciseModal);
  };

  const noActiveWorkOut = activeWorkouts.length === 0;
  const isRTL = direction === "rtl";

  return (
    <div className="h-full px-8 py-4 xl:py-7">
      <div className="h-full space-y-4 flex flex-col">
        {isLoading ? (
          <Loader className="" size="w-10 h-10" fill="#003353" />
        ) : (
          <>
            <div className="w-full flex justify-between items-center z-0">
              <h6
                className={`text-breadCrumb text-xs not-italic leading-4 font-bold ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
              >
                {t("MAIN")}
              </h6>
              <TimeClock />
            </div>
            <div className="space-y-2 flex-grow flex flex-col">
              <div className="w-full flex justify-between items-center z-0">
                <h6
                  className={`text-[#C2C7CF] text-xl xl:text-2xl not-italic font-normal leading-7 ${
                    direction === "ltr"
                      ? "font-primary-rg"
                      : "font-primary-he-rg"
                  }`}
                >
                  {t("DAILY_WORKOUT_PANEL")}
                </h6>
                <h3
                  className={`text-[#C2C7CF] text-xl xl:text-2xl not-italic font-normal leading-7 ${
                    direction === "ltr"
                      ? "font-primary-rg"
                      : "font-primary-he-rg"
                  }`}
                >{`${totalCompletedWorkoutsAcrossWholeDay(coachDailyWorkouts)} ${t("WORKOUT_PERFORMED")} ${totalWorkoutsAcrossWholeDay(
                  coachDailyWorkouts
                )}`}</h3>
              </div>
              <div
                className="w-full flex space-x-4 rtl:space-x-reverse text-textLightGrey relative"
                style={{ height: "calc(100vh - 105px)" }}
              >
                {/* Left Side Container */}
                <div
                  className={`h-full bg-secondaryBackground rounded-lg p-4 relative transition-all duration-300`}
                  style={{
                    minWidth: isCollapsed ? "80px" : "300px",
                    maxWidth: isCollapsed ? "80px" : "300px",
                  }}
                >
                  <div
                    className={`absolute top-12 ${isRTL ? "-left-3" : "-right-3"} z-50`}
                  >
                    <div
                      className="cursor-pointer h-6 w-6 shadow-lg bg-statusBg rounded-full flex justify-center items-center"
                      onClick={toggleCollapse}
                    >
                      <img
                        src={
                          !isRTL
                            ? isCollapsed
                              ? Assets.WorkoutSidebarRightArrow
                              : Assets.WorkoutSidebarLeftArrow
                            : isCollapsed
                              ? Assets.WorkoutSidebarLeftArrow
                              : Assets.WorkoutSidebarRightArrow
                        }
                        alt="profile"
                        className="w-2 h-3 rounded-full bg-cover text-center shadow-xl"
                      />
                    </div>
                  </div>
                  <div className="space-y-4 h-full overflow-y-auto scrollbar-none no-scrollbar">
                    {!isCollapsed && (
                      <div className="flex justify-between">
                        <div>
                          <h6
                            className={`text-md text-textLightGray ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                          >{`${getCurrentTranslatedDateAndTime(t, moment)}`}</h6>
                          <h6
                            className={`text-xs text-textLightGray ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                          >{`${totalWorkoutsAcrossWholeDay(coachDailyWorkouts)} ${t("WORKOUT_TODAY")}`}</h6>
                        </div>
                        <div>
                          <div
                            onClick={toggleAddEventModal}
                            className={`px-3 py-1 text-xs rounded-2xl border-2 border-secondaryBackgroundContrast text-center cursor-pointer ${
                              direction === "ltr"
                                ? "font-primary-rg"
                                : "font-primary-he-rg"
                            }`}
                          >{`${t("ADD")}  +`}</div>
                        </div>
                      </div>
                    )}
                    {coachDailyWorkouts?.map((item, index) => (
                      <div key={`${item.id}-${index}`}>
                        {isCollapsed ? (
                          <div className="space-y-2">
                            <h1
                              className={`text-xs text-textLightGray text-center ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                            >
                              {item?.startHour}
                            </h1>
                            <div className="border border-secondaryBackgroundContrast rounded-xl" />
                          </div>
                        ) : (
                          <div className="flex items-center w-full">
                            <h1
                              className={`text-xs text-textLightGray w-3/12 ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                            >
                              {item?.startHour}
                            </h1>
                            <div className="border border-secondaryBackgroundContrast rounded-xl w-9/12" />
                          </div>
                        )}
                        <div className="space-y-2 py-3">
                          {item.workouts.map((workout, workoutIndex) => {
                            return (
                              <div key={`${workout.id}-${workoutIndex}`}>
                                {isCollapsed ? (
                                  <div className="flex justify-center">
                                    <ProfileAvatar
                                      image={workout?.Trainee?.User?.avatar}
                                      name={workout?.Trainee?.User?.firstName}
                                    />
                                  </div>
                                ) : (
                                  <div className="flex justify-between space-x-2 rtl:space-x-reverse">
                                    <div className="flex items-center space-x-2 rtl:space-x-reverse">
                                      <ProfileAvatar
                                        image={workout?.Trainee?.User?.avatar}
                                        name={workout?.Trainee?.User?.firstName}
                                      />
                                      <h6
                                        className={`text-xs truncate ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                                      >
                                        {workout?.Trainee?.User?.firstName}
                                      </h6>
                                    </div>
                                    <WorkoutStatus
                                      status={workout?.Workout?.status}
                                      onChange={() =>
                                        onAddWorkout(workout?.Workout?.id)
                                      }
                                      show_in_board={
                                        workout?.Workout?.show_in_board
                                      }
                                    />
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {/* Right Side Container */}
                {noActiveWorkOut ? (
                  <EmptyWorkoutsContainer workouts={activeWorkouts} />
                ) : (
                  <div
                    className="w-full h-full flex space-x-3 rtl:space-x-reverse overflow-scroll relative"
                    style={{
                      height: "calc(100vh - 90px)",
                      scrollBehavior: "smooth",
                    }}
                    ref={scrollDivRef}
                  >
                    {activeWorkouts.map((item) => {
                      return (
                        <div
                          key={item.workoutData?.id}
                          className={`h-full rounded-lg p-1 overflow-scroll no-scrollbar`}
                          style={{
                            minWidth: "368px",
                            maxWidth: "368px",
                            scrollBehavior: "smooth",
                          }}
                        >
                          <ActiveWorkout
                            isLoading={item.loading}
                            workoutId={item.workoutData?.id}
                            patternIndex={item.workoutData?.patternIndex}
                            tranieeImage={item?.workoutData?.avatar}
                            traineeName={item?.workoutData?.traineeName}
                            traineeId={item?.workoutData?.traineeId}
                            workoutName={item?.workoutData?.workoutName}
                            muscleSummary={item?.muscles_percentage}
                            exercises={item?.workoutData?.exercises}
                            workoutSummary={item?.workoutSummary}
                            muscleIds={item?.muscleIds}
                            onRemoveWorkout={onRemoveWorkout}
                            onRemoveExercise={onRemoveExercise}
                            onHistoryClick={onHistoryClick}
                            onCommentsClick={onCommentsClick}
                            onAddExerciseClick={onAddExerciseClick}
                            onSwapClick={onSwapClick}
                            onClickExerciseInfo={onClickExerciseInfo}
                            status={item?.workoutData?.status}
                            eventId={item?.workoutData?.eventId}
                          />
                        </div>
                      );
                    })}
                    {(activeWorkouts ? [1, 2, 3] : []).map((_, index) => (
                      <div
                        key={index}
                        className="h-full bg-secondaryBackground rounded-lg p-4 w-96 flex justify-center items-center opacity-60"
                        style={{ minWidth: "368px", maxWidth: "368px" }}
                      >
                        <img
                          src={Assets.EmptyState}
                          alt="empty-state"
                          className="bg-cover w-20 h-20"
                        />
                      </div>
                    ))}
                  </div>
                )}
                {!noActiveWorkOut && (
                  <div
                    className={`absolute top-1/2 ${isRTL ? "left-3" : "right-3"}`}
                  >
                    <div
                      className="cursor-pointer h-6 w-6 shadow-lg bg-statusBg rounded-full flex justify-center items-center"
                      onClick={onScrollClick}
                    >
                      <img
                        src={
                          isRTL
                            ? Assets.WorkoutSidebarLeftArrow
                            : Assets.WorkoutSidebarRightArrow
                        }
                        alt="profile"
                        className="w-2 h-3 rounded-full bg-cover text-center shadow-xl"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <DeleteModal
        setOpenModal={setOnRemoveWorkoutModal}
        openModal={onRemoveWorkoutModal}
        t={t}
        direction={direction}
        title={t("REMOVE_WORKOUT")}
        des={t("ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THE_WORKOUT")}
        loading={isRemoving}
        handleSubmit={onRemoveWorkoutSubmit}
        showPass={false}
      />
      <DeleteModal
        setOpenModal={setOnRemoveExerciseModal}
        openModal={onRemoveExerciseModal}
        t={t}
        direction={direction}
        title={t("REMOVE_EXERCISE")}
        des={t("ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THE_EXERCISE")}
        loading={isRemoving}
        handleSubmit={onRemoveExerciseSubmit}
        showPass={false}
      />
      {addEventModal && (
        <PlayWorkoutAddEvent show={addEventModal} setShow={setAddEventModal} />
      )}
      <PlayWorkoutExerciseHistory
        isopen={isModelopen}
        setIsOpen={setIsModelopen}
        show={exerciseHistoryModal}
        setShow={setIsModelopen}
        historyLoader={historyLoader}
      />
      <PlayWorkoutExerciseComments
        show={exerciseCommentsModal}
        setShow={setExerciseCommentsModal}
      />
      <PlayWorkoutSwapExercises
        show={exerciseSwapModal}
        setShow={setExerciseSwapModal}
      />
      <PlayWorkoutAddExercise
        show={addExerciseModal}
        setShow={setAddExerciseModal}
      />

      <CreateExercise
        show={showExerciseModal}
        setShow={setShowExerciseModal}
        exerciseID={exerciseID}
        setExerciseID={setExerciseID}
        isView={true}
      />
    </div>
  );
};

export default CoachWorkout;
