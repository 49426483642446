import { LabelInput } from 'components/compounds/LabelInput'

import { useSelector } from 'react-redux'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { changePasswordSchema } from 'validations/formsValidations'

import PrimaryButton from 'components/elements/PrimaryButton'

import CustomToastify from 'components/compounds/Toast'
import { Images } from 'assets'
import { useNavigate } from 'react-router-dom'
import { changePassword } from 'store/traineeSlice/traineeActions'

const TraineeProfile = () => {
  const { user } = useSelector((state) => state.auth)
  const [isSubmitting, setSubmitting] = useState(false)
  const navigate = useNavigate()

  const methods = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(changePasswordSchema),
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
  })

  // eslint-disable-next-line
  const [disable, setDisable] = useState(false)

  const { t } = useTranslation()
  const { control, handleSubmit, formState } = methods
  const { errors } = formState

  const { direction } = useSelector((state) => state.language)

  const onSubmit = async (values) => {
    try {
      setSubmitting(true)
      await changePassword({ ...values, userId: user.id })
      CustomToastify(
        t('TRAINEE_PROFILE_ACTION_TITLE'),
        t('PASSWORD_UPDATED_SUCCESSFULLY'),
        '',
        'bg-toastSuccessbg',
        direction,
        Images.toastSucess,
        true
      )
      navigate('/trainee/profile')
      setSubmitting(false)
    } catch (error) {
      console.log('error', error)
      CustomToastify(
        t('TRAINEE_PROFILE_ACTION'),
        error?.code === 501 ? t('INCORRECT_INFORMATION') : t('PROFILE_UPDATION_FAILDED'),
        '',
        'bg-toastFaildbg',
        direction,
        Images.toastFail,
        false
      )
      setSubmitting(false)
    }
  }

  return (
    <div className="m-6 space-y-6 text-white">
      <div className="mt-20 md:mt-10">
        <h1 className="font-bold text-2xl">{t('CHANGE_PASSWORD')}</h1>
      </div>
      <div className="space-y-2">
        <LabelInput
          name="newPassword"
          type="password"
          label={t('NEW_PASSWORD')}
          control={control}
          placeholderValue={t('NEW_PASSWORD')}
          required
          fullWidth
          error={Object.keys(errors).length > 0 ? true : false}
          errorMessage={errors?.newPassword?.message}
          dir={direction}
          disabled={disable}
          isRequired={true}
        />
        <LabelInput
          name="confirmPassword"
          type="password"
          label={t('CONFIRM_NEW_PASSWORD')}
          control={control}
          placeholderValue={t('CONFIRM_NEW_PASSWORD')}
          required
          fullWidth
          error={Object.keys(errors).length > 0 ? true : false}
          errorMessage={errors?.confirmPassword?.message}
          dir={direction}
          disabled={disable}
          isRequired={true}
        />
      </div>
      <div className="flex space-x-2 rtl:space-x-reverse">
        <PrimaryButton
          text={t('CHANGE_PASSWORD')}
          type="submit"
          disabled={false}
          isIcon={false}
          Loader={isSubmitting}
          onClick={handleSubmit(onSubmit)}
          className="h-[44px] px-4 rounded-[12px] shadow-2xl"
          bgColor="bg-[#00639B] hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
          textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
        />
      </div>
    </div>
  )
}

export default TraineeProfile
