import axiosInstance from 'api'
import { Images } from 'assets'
import CustomToastify from 'components/compounds/Toast'

export const getMonitorData = (action, id, t, direction, setWorkoutTimeLine, setLoader) => async (dispatch) => {
  try {
    setLoader(true)
    const [response1, response2] = await Promise.all([axiosInstance.get(`/gymWorkouts/${id}`), axiosInstance.get(`/allWorkoutsByGym/${id}`)])
    setWorkoutTimeLine && setWorkoutTimeLine(response2.result)
    setLoader(false)
    action && action(response1.result)
  } catch (e) {
    setLoader(false)

    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)

    return console.error(e.message)
  }
}
