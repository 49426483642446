import { useOnClickOutside } from 'hooks'
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Loader } from './Loader'
import Assets from 'assets/images'
import { useTranslation } from 'react-i18next'

const ActionSheet = ({ workoutId, open, onClose, options, workoutName, custom, customComponent }) => {
  const ref = useRef()
  useOnClickOutside(ref, onClose)
  const { direction } = useSelector((state) => state.language)
  const [isSubmenu, setSubmenu] = useState(null)
  const { t } = useTranslation()

  const isRTL = direction === 'rtl'

  const selectedSubmenu = options?.find((item) => item.id === isSubmenu)

  const CustomComponent = customComponent

  return (
    <>
      {open && (
        <div className={`fixed -top-0 ${isRTL ? 'left-0' : 'right-0'} w-screen h-screen z-40  `} style={{ zIndex: 200 }}>
          <div
            className={`absolute bottom-0 sm:top-0 z-50 ${isRTL ? 'left-0' : 'right-0'} ${
              custom ? 'min-h-[40%]' : 'min-h-[40%]'
            } sm:h-full w-full sm:w-80 bg-[#43474d] rounded-t-2xl sm:rounded-t-none ${
              isRTL ? '' : ''
            } flex flex-col justify-between py-4 duration-500 ease-in-out`}
            ref={ref}
          >
            {custom ? (
              <div className="space-y-3">
                <div className="pt-4 flex justify-center">
                  <div className="border-2 border-[#5A5F66] w-[15%] rounded-2xl"></div>
                </div>
                <CustomComponent />
              </div>
            ) : (
              <>
                <div className="space-y-3">
                  <div className="pt-4 flex justify-center">
                    <div className="border-2 border-[#5A5F66] w-[15%] rounded-2xl"></div>
                  </div>
                  {isSubmenu ? (
                    <div className="space-y-1">
                      <div>
                        <div className="flex space-x-2 rtl:space-x-reverse items-center px-2 cursor-pointer" onClick={() => setSubmenu(null)}>
                          <img
                            src={Assets.WorkoutSidebarLeftArrow}
                            alt="profile"
                            className={`w-3 h-3 rounded-full bg-cover text-center shadow-xl ${isRTL && 'rotate-180'}`}
                          />
                          <h1>{workoutName}</h1>
                        </div>
                        <div className="border-b border-gray-500 pt-2"></div>
                      </div>
                      {selectedSubmenu?.submenu?.loading ? (
                        <div className="h-48 w-full flex justify-center items-center">
                          <Loader className="" size="w-6 h-6" fill="#003353" />
                        </div>
                      ) : (
                        <>
                          {selectedSubmenu?.submenu?.suboptions.length === 0 && (
                            <div className="h-48 w-full flex justify-center items-center">No workout to swap</div>
                          )}
                          {selectedSubmenu?.submenu?.suboptions.map((item) => (
                            <div
                              key={item.id}
                              className="flex space-x-2 rtl:space-x-reverse items-center cursor-pointer p-2"
                              onClick={() => selectedSubmenu?.submenu?.action(workoutId, item?.details?.PatternIndex)}
                            >
                              {item?.image && <img src={item.image} alt="" className="w-5" />}
                              <h1>{item.label}</h1>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="space-y-1">
                      {options.map((item) => {
                        const isBreak = item?.type === 'br'
                        const isCustom = item?.type === 'custom'
                        const CustomComponent = item?.component
                        const isDisabled = item?.disabled
                        if (isBreak) {
                          return <div className="border-b border-gray-500 pt-2"></div>
                        }
                        if (isCustom) {
                          return <CustomComponent />
                        }
                        return (
                          <div
                            key={item.id}
                            className={`flex space-x-2 rtl:space-x-reverse items-center cursor-pointer p-3 ${
                              isDisabled && 'opacity-50'
                            } h-12 hover:bg-[#4e535a]`}
                            onClick={() => {
                              if (!isDisabled) {
                                if (item?.submenu?.show) {
                                  setSubmenu(item.id)
                                }
                                item?.action()
                              }
                            }}
                          >
                            <img src={item.image} alt="" className="w-5" />
                            <h1 className="text-sm">{item.label}</h1>
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div>
                <div>
                  <div className="border-b border-[#5a5f66] m-4"></div>
                  <h1 className="text-sm  h-14 px-4">{t('WORKOUT_NOTE')}</h1>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default ActionSheet
