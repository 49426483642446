import { Images } from 'assets'
import Avatar from 'components/compounds/Avatar'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { getWorkoutDate, handleFilterSelection } from 'utils'
import { useOutsideClick } from 'hooks'

import WeekDropDown from './components/weekDropdown'
import { getProgramInfo, getProgramStatistics, getProgramStatisticsFilters } from 'store/programSlice/programActions'
import moment from 'moment'
import PrimaryButton from 'components/elements/PrimaryButton'
import { programActions } from 'store/programSlice/programReducers'

export default function ProgramInfoHeader({
  data,
  setTemp1,
  temp1,
  setSelectedWeek,
  selectedWeek,
  setProgramfilterOptions,
  programfilterOptions,
  setSingleMuscle,
  setSelectedExercise,
}) {
  const { direction } = useSelector((state) => state.language)
  const { loader, programStatisticsFilters } = useSelector((state) => state.program)

  const { t } = useTranslation()
  const navigation = useNavigate()
  const query = useParams()
  const [searchParams] = useSearchParams()
  const { currentTrainee } = useSelector((state) => state.gymAdmin)
  const filtersRef = useRef()
  const [filtersMenu, setFiltersMenu] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState('WORKOUT_PATTERN')
  const [availableWeeks, setAvailableWeeks] = useState([])

  const dispatch = useDispatch()

  const onClickOutsideFiltersDiv = () => {
    setFiltersMenu(false)
  }
  useOutsideClick(filtersRef, onClickOutsideFiltersDiv)
  const location = useLocation()
  const { programId, active, status, traineeName } = Object.fromEntries(searchParams)

  useEffect(() => {
    if (query?.id) {
      dispatch(getProgramInfo(programId, query?.id, t, direction))

      dispatch(getProgramStatisticsFilters(programId, query?.id, t, direction))
    }
  }, [query])

  useEffect(() => {
    const getProgramInfo = async () => {
      if (selectedWeek) {
        setSingleMuscle(null)
        setSelectedExercise(null)
        let workoutPatternId = []

        if (temp1.WORKOUT_PATTERN.length > 0) {
          workoutPatternId = programfilterOptions?.filter((opt) => temp1.WORKOUT_PATTERN.includes(opt.label)).map((Workout) => Workout.PatternIndex)
        }

        const data = {
          patternIds: workoutPatternId,
          currentWeek: selectedWeek.value,
          programId: programId,
          traineeId: query?.id,
          totalWeeks: programStatisticsFilters?.ValidTimeOptions,
        }

        dispatch(getProgramStatistics(data, t, direction))
      }
    }

    getProgramInfo()
  }, [temp1])
  const unMountState = () => {
    emptyFiltersArray()
    setAvailableWeeks([])
    setSelectedWeek()
    setProgramfilterOptions([])
    dispatch(programActions.setProgramStatistics([]))
  }

  useEffect(() => {
    unMountState()
    if (programStatisticsFilters && programStatisticsFilters?.ValidTimeOptions?.length > 0) {
      assignInitialFilters()
    }
  }, [programStatisticsFilters])

  const assignInitialFilters = async () => {
    let weeks = programStatisticsFilters?.ValidTimeOptions?.map((item) => {
      return {
        id: item + 1,
        label: `Week-${item + 1}`,
        value: item,
      }
    })
    weeks = [
      {
        id: 0,
        label: 'All Weeks',
        value: -1,
      },
      ...weeks,
    ]
    setAvailableWeeks(weeks)
    const keyOneData = programStatisticsFilters.ValidWorkoutPatterns[programStatisticsFilters?.currentWeek]
    const modifiedData = keyOneData.map((item) => {
      return { ...item, label: item.workoutName }
    })
    setProgramfilterOptions(modifiedData)
    const currWeek = weeks.find((week) => week.value === programStatisticsFilters?.currentWeek)
    setSelectedWeek(currWeek)

    let mdata = {
      WORKOUT_PATTERN: [],
    }
    modifiedData.forEach((opt) => {
      handleFilterSelection(selectedFilter, opt, mdata, (d) => {
        if (d.WORKOUT_PATTERN) {
          mdata['WORKOUT_PATTERN'] = [...d.WORKOUT_PATTERN]
        }
      })
    })

    setTemp1(mdata)
  }

  const emptyFiltersArray = () => {
    setTemp1({
      WORKOUT_PATTERN: [],
    })
  }

  const handleWeek = (item) => {
    setSingleMuscle(null)
    setSelectedExercise(null)
    setSelectedWeek(item)
    emptyFiltersArray()
    let keyOneData = []
    if (item?.value === -1) {
      keyOneData = Object.values(programStatisticsFilters.ValidWorkoutPatterns) // Extract all pattern arrays
        .flat() // Flatten the arrays
        .reduce((acc, pattern) => {
          const existingPattern = acc.find((p) => p.PatternIndex === pattern.PatternIndex)
          if (!existingPattern) {
            acc.push(pattern)
          }
          return acc
        }, [])

      keyOneData.sort((a, b) => a.PatternIndex - b.PatternIndex)
    } else {
      keyOneData = programStatisticsFilters?.ValidWorkoutPatterns[item?.value]
    }

    const modifiedData = keyOneData?.map((item) => {
      return { ...item, label: item.workoutName }
    })
    setProgramfilterOptions(modifiedData)
    let mdata = {
      WORKOUT_PATTERN: [],
    }
    modifiedData.forEach((opt) => {
      handleFilterSelection(selectedFilter, opt, mdata, (d) => {
        if (d.WORKOUT_PATTERN) {
          mdata['WORKOUT_PATTERN'] = [...d.WORKOUT_PATTERN]
        }
      })
    })
    setTemp1(mdata)
  }

  return (
    <div className="flex w-full flex-col">
      <div className="flex flex-row w-full px-8 py-5 justify-between items-center ">
        <div className="flex gap-1 justify-center items-center text-white">
          <p
            className="text-[#C2C7CF] text-[12px] leading-4 text-center ltr:font-primary-rg rtl:font-primary-he-rg hover:border-white hover:border-b-2 cursor-pointer"
            onClick={() => {
              navigation('/admin/trainees')
            }}
          >
            {' '}
            {t('TRAINEES')}
          </p>
          <p>/</p>
          <p
            className="text-white text-[12px] leading-4 font-normal ltr:font-primary-rg rtl:font-primary-he-rg hover:border-white hover:border-b-2 cursor-pointer "
            onClick={() => {
              navigation(`/admin/trainees/traineeDetail/${query?.id}`, { state: { active: `INFO` } })
            }}
          >
            {' '}
            {traineeName}
          </p>
          <p>/</p>
          <p
            className="text-white text-[12px] leading-4 font-normal ltr:font-primary-rg rtl:font-primary-he-rg hover:border-white hover:border-b-2 cursor-pointer  "
            onClick={() => {
              navigation(`/admin/trainees/traineeDetail/${query?.id}`, { state: { active: active } })
            }}
          >
            {' '}
            {t(active)}
          </p>
          <p>/</p> <p className="text-breadCrumb font-primary-rg text-[12px] leading-4 font-bold ">{t('INFO')}</p>
        </div>
      </div>
      {/*-------------------------- Header -------------------- */}
      <div className="flex flex-row w-full px-8 py-2  items-center gap-x-4">
        <div className={`text-[#C2C7CF] ${direction === 'ltr' ? ' font-primary-md' : 'font-primary-he-md'} text-[24px] font-medium leading-7`}>
          {t('Program info')}
        </div>
        <div className="flex gap-2 flex-row justify-center items-center ">
          {currentTrainee?.User?.avatar != null ? (
            <div className="flex border rounded-[50px] w-7 h-7 justify-center items-center overflow-hidden">
              <img src={currentTrainee?.User?.avatar} alt="" height={28} width={28} className={`items-center cursor-pointer`} onClick={() => {}} />
            </div>
          ) : (
            <Avatar name={currentTrainee?.User?.firstName} width={25} height={25} size={10} />
          )}
          <div
            className={`${
              direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
            } text-[#C2C7CF] text-[14px] font-normal leading-5 text-center `}
          >
            {currentTrainee?.User?.firstName}
          </div>
        </div>
      </div>
      {/*-------------------------- Analytics -------------------- */}
      <div className="flex flex-row w-full justify-between px-8 py-2  items-center gap-x-4">
        {/*----------------------- Status ----------------------- */}
        <div className="flex flex-col h-[164px] w-full rounded-xl bg-secondaryBackground p-6 items-center gap-2">
          {!loader && (
            <>
              <p className=" text-base leading-5 font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-textLightGray">{t('STATUS')}</p>
              <div>
                <p className=" text-[32px] leading-9 font-bold ltr:font-primary-rg rtl:font-primary-he-rg text-textLightGray ">
                  {data?.Status?.completedWorkouts}/{data?.Status?.totalWorkouts}
                </p>
                <p className=" text-xs  font-bold ltr:font-primary-rg rtl:font-primary-he-rg text-white ">
                  {data?.Status?.cancelledWorkouts} {t('Canceled')}
                </p>
              </div>
              <div className="flex flex-row gap-2 items-center">
                <p className="text-xs  font-bold ltr:font-primary-rg rtl:font-primary-he-rg text-white ">
                  {t('Start')}: {getWorkoutDate(data?.Status?.startDate, t, moment)}
                </p>
                <p className="w-1 h-1 bg-[#72777F] rounded-lg">.</p>
                <p className="text-xs  font-bold ltr:font-primary-rg rtl:font-primary-he-rg text-[#FFA726] ">
                  {t('End')}: {getWorkoutDate(data?.Status?.endDate, t, moment)}
                </p>
              </div>
            </>
          )}
        </div>

        {/*----------------------- Avg Workout Duration ----------------------- */}
        <div className="flex flex-col h-[164px] w-full rounded-xl bg-secondaryBackground p-6 items-center gap-2">
          {!loader && (
            <>
              <p className=" text-base leading-5 font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-textLightGray">
                {t('AVG_WORKOUT_DURATION')}
              </p>
              <div className="flex flex-col justify-center items-center">
                <div className="flex flex-row items-end gap-1">
                  <p className=" text-[32px] leading-9 font-bold ltr:font-primary-rg rtl:font-primary-he-rg text-textLightGray ">
                    {Math.floor(moment.duration(data?.WorkoutDuration?.avgDuration, 'seconds').asMinutes())}
                  </p>
                  <p className=" text-xs  font-bold ltr:font-primary-rg rtl:font-primary-he-rg text-white mb-1">{t('min')}</p>
                </div>
                {data?.WorkoutDuration?.lastPlayedDate != null && (
                  <p className=" text-xs  font-bold ltr:font-primary-rg rtl:font-primary-he-rg text-white ">
                    {Math.floor(moment.duration(data?.WorkoutDuration?.lastWorkoutPlayedTime, 'seconds').asMinutes())} {t('min')} .{' '}
                    {t('LAST_WORKOUT')} {getWorkoutDate(data?.WorkoutDuration?.lastPlayedDate, t, moment)}
                  </p>
                )}
              </div>
              <div className="flex flex-row gap-2 items-center">
                <p className="text-xs  font-bold ltr:font-primary-rg rtl:font-primary-he-rg text-white ">
                  {t('MIN')}: {Math.floor(moment.duration(data?.WorkoutDuration?.minDuration, 'seconds').asMinutes())} {t('min')}
                </p>
                <p className="w-1 h-1 bg-[#72777F] rounded-lg">.</p>
                <p className="text-xs  font-bold ltr:font-primary-rg rtl:font-primary-he-rg text-white ">
                  {t('MAX')}: {Math.floor(moment.duration(data?.WorkoutDuration?.maxDuration, 'seconds').asMinutes())} {t('min')}
                </p>
              </div>
            </>
          )}
        </div>
        {/*----------------------- Program Name ----------------------- */}
        <div className="flex flex-col h-[164px] w-full rounded-xl bg-secondaryBackground p-6 items-center gap-5">
          {!loader && (
            <>
              <p className=" text-base leading-5 font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-textLightGray">
                {data?.programGeneralInfo?.programName}
              </p>
              <div className="flex w-[87px] h-6 rounded px-2 bg-[#E5A772] items-center justify-center">
                <p className=" text-sm  font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-[#333333] ">
                  {t(data?.programGeneralInfo?.programStatus)}
                </p>
              </div>
              <div className="flex flex-row gap-2 items-center">
                {data?.programGeneralInfo?.programWorkoutsStatus?.map((item, index) => (
                  <>
                    <p className="text-xs  font-bold ltr:font-primary-rg rtl:font-primary-he-rg text-white ">{t(item)}</p>
                    {data?.programGeneralInfo?.programWorkoutsStatus?.length < index && <p className="w-1 h-1 bg-[#72777F] rounded-lg">.</p>}
                  </>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      {/*-------------------------- Filters -------------------- */}
      <div className="flex flex-row w-full px-8  gap-4 items-start ">
        <div className="flex w-5/6  py-2 gap-4 items-start  ">
          <div ref={filtersRef} className="relative box-border">
            <div
              className={`flex flex-row w-[156px] h-[40px] items-center rounded-[12px] bg-primary
            border-borderActive border-[1.5px] cursor-pointer`}
              onClick={() => {
                setFiltersMenu(!filtersMenu)
              }}
            >
              <img src={Images.Filter} alt="" height={40} width={45} className={`p-3 items-center `} onClick={() => {}} />
              <p className={`text-white text-center text-[14px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>
                {t('ADDING_FILTER')}
              </p>
            </div>
            {filtersMenu ? (
              <div
                className={`absolute top-11 py-3 bg-statusBg rounded-[12px] w-[156px] max-h-[200px] overflow-auto shadow-xl ${
                  filtersMenu ? 'border-borderActive border-[2px]' : ''
                }`}
              >
                {programfilterOptions.map((opt, i) => (
                  <p
                    key={i}
                    className={`px-2 py-1 text-[12px] ${
                      direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                    } hover:bg-checkoutHover cursor-pointer ${temp1.WORKOUT_PATTERN.includes(opt.label) ? 'text-activeText' : 'text-textGrey'}`}
                    onClick={() => {
                      handleFilterSelection(selectedFilter, opt, temp1, setTemp1)
                    }}
                  >
                    {opt?.label}
                  </p>
                ))}
              </div>
            ) : null}
          </div>
          <div className="flex flex-wrap gap-4 ">
            {temp1 &&
              Object.keys(temp1).map((value, index) => {
                return (
                  temp1[value] &&
                  temp1[value].length > 0 &&
                  temp1[value].map((item) => {
                    return (
                      <div className="flex h-[40px] flex-row items-center  gap-1 bg-statusBg px-3 py-1 rounded-[16px] hover:border-borderActive hover:border-[2px]">
                        <p className={`text-[14px] text-textGrey  ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>{t(item)}</p>
                        <img
                          src={Images.CloseCircle}
                          alt=""
                          height={10}
                          width={15}
                          className={`ml-1 mr-2 cursor-pointer lg:mr-0 lg:ml-0 xl:mr-0 xl:ml-0 `}
                          onClick={() => {
                            if (index === 0) {
                              const updatedItems = temp1['WORKOUT_PATTERN'].filter((i) => i !== item)
                              setTemp1({
                                ...temp1,
                                WORKOUT_PATTERN: updatedItems,
                              })
                            }
                          }}
                        />
                      </div>
                    )
                  })
                )
              })}
          </div>
          {temp1.WORKOUT_PATTERN?.length > 0 && (
            <PrimaryButton
              text={t('Clear')}
              type="submit"
              disabled={false}
              isIcon={false}
              onClick={() => {
                emptyFiltersArray()
              }}
              className="px-4 py-2 rounded-[12px] shadow-2xl "
              bgColor="hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
              textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
              wid={15}
              ht={15}
            />
          )}
        </div>
        <div className="flex w-1/6  justify-end ">
          {availableWeeks.length > 0 && (
            <WeekDropDown
              className={'w-24'}
              disabled={false}
              value={selectedWeek}
              options={availableWeeks}
              setValue={(item) => handleWeek(item)}
              t={t}
              direction={direction}
            />
          )}
        </div>
      </div>
    </div>
  )
}
