import PlayWorkoutAddExercise from 'components/PlayBoard/PlayWorkoutAddExercise'
import PlayWorkoutExerciseComments from 'components/PlayBoard/PlayWorkoutExerciseComments'
import PlayWorkoutExerciseHistory from 'components/PlayBoard/PlayWorkoutExerciseHistory'
import { ActiveWorkout } from 'components/PlayBoard/ActiveWorkout'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getWorkout, removeExercise, removeWorkout } from 'store/traineeSlice/traineeActions'
import { ExerciseHistory, getAllExerciseComments } from 'store/workoutSlice/workoutActions'
import CustomToastify from 'components/compounds/Toast'
import { Images } from 'assets'
import PlayWorkoutSwapExercises from 'components/PlayBoard/PlayWorkoutSwapExercises'
import CreateExercise from 'components/exerciseLibrary/CreateExercise'

const TraineeWorkout = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { direction } = useSelector((state) => state.language)
  const { customerDetail } = useSelector((state) => state.user)
  const [isLoading, setLoading] = useState(true)
  const [workoutDetail, setWorkoutDetail] = useState(null)
  const [addExerciseModal, setAddExerciseModal] = useState(false)
  const [exerciseCommentsModal, setExerciseCommentsModal] = useState(false)
  const [exerciseHistoryModal, setExerciseHistoryModal] = useState(false)
  const [exerciseSwapModal, setExerciseSwapModal] = useState(false)
  const [showExerciseModal, setShowExerciseModal] = useState(false)
  const [exerciseID, setExerciseID] = useState(null)
  const [isModelopen, setIsModelopen] = useState(false)
  const { historyLoader } = useSelector((state) => state.workout)

  const getWorkoutDetail = async (shouldLoad) => {
    try {
      !shouldLoad && setLoading(true)
      const data = await getWorkout(id)

      setWorkoutDetail(data)
      !shouldLoad && setLoading(false)
    } catch (error) {
      console.log('error', error)
      !shouldLoad && setLoading(false)
    }
  }
  const addWorkoutExercise = async (shouldLoad) => {
    try {
      const data = await getWorkout(id)
      const newExercises = data.workoutData.exercises.filter((exercise) => {
        return !workoutDetail.workoutData.exercises.some((x) => x.workoutExerciseId === exercise.workoutExerciseId)
      })
      const updatedWorkoutDetail = {
        ...workoutDetail,
        workoutData: {
          ...workoutDetail.workoutData,
          exercises: [...workoutDetail.workoutData.exercises, newExercises[0]],
        },
      }
      setWorkoutDetail(updatedWorkoutDetail)
    } catch (error) {
      console.log('error', error)
    }
  }
  const swapWorkoutExercise = async (WorkoutExerciseId) => {
    try {
      const data = await getWorkout(id)
      const newExercises = data.workoutData.exercises.filter((exercise) => {
        return !workoutDetail.workoutData.exercises.some((x) => x.workoutExerciseId === exercise.workoutExerciseId)
      })

      const exerciseIndex = workoutDetail.workoutData.exercises.findIndex((exercise) => exercise.workoutExerciseId === WorkoutExerciseId)

      const updatedExercises = [...workoutDetail.workoutData.exercises]
      updatedExercises[exerciseIndex] = newExercises[0]

      const updatedWorkoutDetail = {
        ...workoutDetail,
        workoutData: {
          ...workoutDetail.workoutData,
          exercises: updatedExercises,
        },
      }
      setWorkoutDetail(updatedWorkoutDetail)
    } catch (error) {
      console.log('error', error)
    }
  }
  const removeWorkoutExercise = async (WorkoutExerciseId) => {
    try {
      const data = await getWorkout(id)
      const newExercises = data.workoutData.exercises.filter((exercise) => exercise.workoutExerciseId !== WorkoutExerciseId)
      const updatedWorkoutDetail = {
        ...workoutDetail,
        workoutData: {
          ...workoutDetail.workoutData,
          exercises: newExercises,
        },
      }
      setWorkoutDetail(updatedWorkoutDetail)
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    if (id) {
      getWorkoutDetail()
    }
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    // eslint-disable-next-line
  }, [workoutDetail])

  const onRemoveWorkout = async () => {
    try {
      setLoading(true)
      await removeWorkout(id)
      navigate('/trainee/dashboard')
      CustomToastify(t('WORKOUT_REMOVED_SUCCESSFULLY'), '', '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
      setLoading(false)
    } catch (error) {
      console.log('error')
      setLoading(false)
    }
  }

  const onAddExerciseClick = (workoutId, traineeId, isAllPartial) => {
    setAddExerciseModal({ workoutId, traineeId, isAllPartial })
  }

  const actionHistory = (data) => {
    setExerciseHistoryModal(data)
  }

  const onHistoryClick = (exerciseId, workoutExerciseId, traineeId, exerciseName) => {
    const payload = {
      exerciseId: exerciseId,
      traineeId: traineeId,
      workoutExerciseId: workoutExerciseId,
      exerciseName: exerciseName,
    }
    setIsModelopen(true)
    dispatch(ExerciseHistory(payload, t, actionHistory, direction))
  }

  const onCommentsClick = (exerciseId, workoutExerciseId, traineeId) => {
    setExerciseCommentsModal({ exerciseId, workoutExerciseId, traineeId, coachId: null, userId: customerDetail?.User?.id })
    dispatch(getAllExerciseComments(exerciseId, traineeId, t, direction))
  }

  const onSwapClick = (workoutExerciseId, workoutId, exerciseId, traineeId, exerciseName, isAllPartial) => {
    setExerciseSwapModal({
      workoutExerciseId,
      workoutId,
      exerciseId,
      traineeId,
      exerciseName,
      isAllPartial,
    })
  }

  const onRemoveExercise = async (workoutExerciseId) => {
    await removeExercise(workoutExerciseId)
    removeWorkoutExercise && removeWorkoutExercise(workoutExerciseId)
    CustomToastify(t('EXERCISE_REMOVED_SUCCESSFULLY'), '', '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
  }
  const onClickExerciseInfo = (exerciseID) => {
    setExerciseID(exerciseID)
    setShowExerciseModal(!showExerciseModal)
  }

  return (
    <div className="p-5 mt-10  md:p-6 w-full h-full flex justify-center text-textLightGrey ">
      <div className=" w-96 relative">
        <ActiveWorkout
          isLoading={isLoading}
          workoutId={workoutDetail?.workoutData?.id}
          patternIndex={workoutDetail?.workoutData?.patternIndex}
          tranieeImage={workoutDetail?.workoutData?.avatar}
          traineeName={workoutDetail?.workoutData?.traineeName}
          traineeId={workoutDetail?.workoutData?.traineeId}
          workoutName={workoutDetail?.workoutData?.workoutName}
          muscleSummary={workoutDetail?.muscles_percentage}
          exercises={workoutDetail?.workoutData?.exercises}
          workoutSummary={workoutDetail?.workoutSummary}
          muscleIds={workoutDetail?.muscleIds}
          status={workoutDetail?.workoutData?.status}
          onRemoveWorkout={onRemoveWorkout}
          onHistoryClick={onHistoryClick}
          onCommentsClick={onCommentsClick}
          onAddExerciseClick={onAddExerciseClick}
          onRemoveExercise={onRemoveExercise}
          onSwapClick={onSwapClick}
          isTrainee
          refresh={getWorkoutDetail}
          data={workoutDetail}
          setData={setWorkoutDetail}
          onClickExerciseInfo={onClickExerciseInfo}
          coachId={workoutDetail?.workoutData?.coachId}
          eventId={workoutDetail?.workoutData?.eventId}
        />
        <PlayWorkoutAddExercise show={addExerciseModal} setShow={setAddExerciseModal} isTrainee refresh={addWorkoutExercise} />
        <PlayWorkoutExerciseHistory
          isopen={isModelopen}
          setIsOpen={setIsModelopen}
          show={exerciseHistoryModal}
          setShow={setIsModelopen}
          isTrainee
          refresh={getWorkoutDetail}
          historyLoader={historyLoader}
        />
        <PlayWorkoutSwapExercises show={exerciseSwapModal} setShow={setExerciseSwapModal} isTrainee refresh={swapWorkoutExercise} />
        <PlayWorkoutExerciseComments show={exerciseCommentsModal} setShow={setExerciseCommentsModal} isTrainee refresh={getWorkoutDetail} />
        <CreateExercise show={showExerciseModal} setShow={setShowExerciseModal} exerciseID={exerciseID} setExerciseID={setExerciseID} isView={true} />
      </div>
    </div>
  )
}

export default TraineeWorkout
