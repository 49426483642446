import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { AllBodyMapContainer, FrontBackContainer, RotateIconContainer } from './styles'

import { ReactComponent as SVGBodyMapFront } from '../../assets/images/bodyMapSvg.svg'
import { ReactComponent as SVGBodyMapBack } from '../../assets/images/bodyMapBackSvg.svg'
import { ReactComponent as RotateIcon } from '../../assets/images/rotateIcon.svg'
import { constants } from 'constant'

const WorkoutSummaryMuscleModal = ({
  selectedValue,
  setSelectedValue,
  hoverEnabled = true,
  fillColor,
  w,
  h,
  frontids,
  backIds,
  musclePercentages,
  data,
  showModal,
  showPercentage,
  bgColor,
}) => {
  const [isFront, setIsFront] = useState(true)
  const [focusedItem, setFocusedItem] = useState(false)

  function countMatchingValues(arr1, arr2) {
    const uniqueValuesArr1 = [...new Set(arr1)] // Remove duplicates from arr1
    return uniqueValuesArr1.filter((value) => arr2.includes(value)).length
  }
  const handleMuscleClick = useCallback(
    (e) => {
      if (frontids?.includes(parseInt(e.currentTarget.id))) {
        const selectedMuscle = data?.find((val) => val?.muscle_model_id === parseInt(e.currentTarget.id))

        setSelectedValue(selectedMuscle)
      }
    },

    [setSelectedValue, selectedValue, hoverEnabled]
  )
  const handleClick = (item) => {
    if (frontids?.includes(parseInt(item))) {
      const selectedMuscle = data?.find((val) => val?.muscle_model_id === parseInt(item))

      setSelectedValue(selectedMuscle)
    }
  }
  useLayoutEffect(() => {
    const bodyMap = document.querySelectorAll('.hoverable-muscle')

    bodyMap.forEach((muscle) => {
      muscle.addEventListener('click', handleMuscleClick)
    })

    return () => {
      bodyMap.forEach((muscle) => {
        muscle.removeEventListener('click', handleMuscleClick)
      })
    }
  }, [handleMuscleClick])

  let labelPositionFrontIndex = {
    9: 'top-[105px] right-5 z-10',
    1: 'top-[70px] right-[55px]',
    8: 'top-36 right-5 rtl:right-5 z-10',
    3: 'top-[200px] right-3',
    14: 'bottom-32 right-10',
    12: 'bottom-[205px] right-[46px]',
    4: 'bottom-[245px] right-[30px]',

    //left muscles
    11: 'top-[170px] left-9',
    5: 'bottom-28 left-[65px]',
    13: 'bottom-60 left-9',
    10: 'top-[200px] left-24',
    7: 'top-[115px] left-4 z-20',
    2: 'top-[80px] left-9 z-10',
    6: 'top-[40px] left-[70px]',
  }
  let labelPositionFrontDimensions = {
    9: 'w-[80px] h-[49px]',
    1: 'w-[70px] h-[32px]',
    8: 'w-[38px] h-[25px]',
    3: 'w-[40px] h-[15px]',
    14: 'w-[65px] h-[28px]',
    12: 'w-[90px] h-[10px]',
    4: 'w-[65px] h-[10px]',

    //left muscles
    11: 'w-[85px] h-[40px]',
    5: 'w-[65px] h-[32px]',
    13: 'w-[80px] h-[32px]',
    10: 'w-[55px] h-[25px]',
    7: 'w-[80px] h-[18px]',
    2: 'w-[50px] h-[40px]',
    6: 'w-[60px] h-[90px]',
  }
  let labelPositionBackIndex = {
    //left
    4: 'bottom-[220px] left-[52px]',
    18: 'top-[215px] right-36',
    21: 'bottom-[240px] left-[30px] z-20',
    3: 'top-[100px] left-5',
    17: 'top-[150px] left-8 z-20',
    16: 'top-[62px] left-[60px]',
    15: 'top-[100px] left-[90px]',

    23: 'bottom-20 right-14 z-10',
    5: 'bottom-[130px] right-[70px]',
    22: 'bottom-48 right-14  z-10',
    12: 'bottom-[225px] right-[65px]',
    24: 'top-[125px] right-[36px] z-10',
    19: 'top-[170px] right-[36px]',
    20: 'bottom-[280px] right-14',
    2: 'top-[80px] right-12 ',
    1: 'top-[50px] right-[90px]',
  }
  let labelPositionBackDimensions = {
    23: 'w-[65px] h-[49px]',
    5: 'w-[50px] h-[32px]',
    22: 'w-[54px] h-[6px]',
    4: 'w-[50px] h-[10px]',
    18: 'w-[45px] h-[14px]',
    12: 'w-[78px] h-[24px]',
    24: 'w-[80px] h-[10px]',
    19: 'w-[85px] h-[10px]',
    21: 'w-[100px] h-[10px]',
    20: 'w-[65px] h-[16px]',
    3: 'w-[40px] h-[100px]',
    17: 'w-[50px] h-[18px]',
    2: 'w-[45px] h-[40px]',
    16: 'w-[45px] h-[60px]',
    1: 'w-[50px] h-[50px]',
    15: 'w-[50px] h-[50px]',
  }

  const frontleftMuscle = [11, 5, 13, 10, 7, 2, 6]
  const backleftMuscle = [4, 18, 21, 3, 17, 16, 15, 18]
  const frontMuscle = [11, 5, 13, 10, 7, 2, 6, 9, 1, 8, 3, 14, 12, 4]
  const backMuscle = [23, 5, 22, 4, 18, 12, 24, 19, 21, 20, 3, 17, 2, 16, 1, 15]

  const handleMouseEnter = (item) => {
    setFocusedItem(item)
  }
  const handleMouseLeave = () => {
    setFocusedItem(false)
  }

  return (
    <AllBodyMapContainer bgColor={bgColor}>
      {isFront ? (
        <FrontBackContainer $isFront={isFront} $selectedValue={frontids} $hoverEnabled={hoverEnabled} h={h} w={w} fillColor={'#65B1F4'}>
          <div className="relative w-full h-full">
            <SVGBodyMapFront />

            {showModal &&
              frontids?.map((item) => {
                return !frontleftMuscle.includes(item) ? (
                  <>
                    {labelPositionFrontIndex[item] && (
                      <div key={item} className={`absolute ${labelPositionFrontIndex[item]}`}>
                        <div
                          className={`${
                            labelPositionFrontDimensions[item]
                          } bg-transparent border-b-2  border-r-2 rounded-br-md relative cursor-pointer ${
                            focusedItem === item ? 'border-r-lightGreen border-b-lightGreen' : 'border-r-bodyActiveColor border-b-bodyActiveColor'
                          } `}
                          onMouseEnter={() => handleMouseEnter(item)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <div
                            className={`w-2 h-2  rounded-full absolute -bottom-[4.5px] -left-1 cursor-pointer ${
                              focusedItem === item ? 'bg-lightGreen' : 'bg-bodyActiveColor'
                            }`}
                          ></div>
                          <div
                            className={`h-[31px]  rounded-[3.51px] py-2 px-2 absolute -top-7 -right-12 hover:cursor-pointer ${
                              focusedItem === item ? 'bg-lightGreen' : 'bg-bodyActiveColor'
                            }`}
                            onClick={() => handleClick(item)}
                          >
                            <div className="flex text-[#333333] text-xs ltr:font-primary-rg rtl:font-primary-he-rg font-bold  w-full gap-x-[1px]">
                              <p className=" truncate text-[#333333] text-xs ltr:font-primary-rg rtl:font-primary-he-rg font-bold ">
                                {data?.find((val) => val?.muscle_model_id == item)?.muscleName}
                              </p>
                              {showPercentage && (
                                <p className="text-[#333333] text-xs ltr:font-primary-rg rtl:font-primary-he-rg font-bold ">
                                  {musclePercentages[item]}%
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {labelPositionFrontIndex[item] && (
                      <div key={item} className={`absolute ${labelPositionFrontIndex[item]}`}>
                        <div
                          className={`${labelPositionFrontDimensions[item]} bg-transparent border-b-2 border-l-2  rounded-bl-md relative  ${
                            focusedItem === item ? 'border-l-lightGreen border-b-lightGreen' : 'border-l-bodyActiveColor border-b-bodyActiveColor'
                          }`}
                          onMouseEnter={() => handleMouseEnter(item)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <div
                            className={`w-2 h-2  rounded-full absolute -bottom-[4.5px] -right-1 cursor-pointer ${
                              focusedItem === item ? 'bg-lightGreen' : 'bg-bodyActiveColor'
                            }`}
                          ></div>
                          <div
                            className={`h-[31px] rounded-[3.51px] py-2 px-2 absolute -top-7 -left-12 cursor-pointer  ${
                              focusedItem === item ? 'bg-lightGreen' : 'bg-bodyActiveColor'
                            }`}
                            onClick={() => handleClick(item)}
                          >
                            <div className="flex text-[#333333] text-xs ltr:font-primary-rg rtl:font-primary-he-rg font-bold  w-full gap-x-[1px]">
                              <p className=" truncate text-[#333333] text-xs ltr:font-primary-rg rtl:font-primary-he-rg font-bold ">
                                {data?.find((val) => val?.muscle_model_id == item)?.muscleName}
                              </p>
                              {showPercentage && (
                                <p className="text-[#333333] text-xs ltr:font-primary-rg rtl:font-primary-he-rg font-bold ">
                                  {musclePercentages[item]}%
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )
              })}
          </div>
        </FrontBackContainer>
      ) : (
        <FrontBackContainer $isFront={!isFront} $selectedValue={backIds} $hoverEnabled={hoverEnabled} h={h} w={w} fillColor={'#65B1F4'}>
          <div className="relative w-full h-full">
            <SVGBodyMapBack />
            {showModal &&
              backIds?.map((item) => {
                return !backleftMuscle.includes(item) ? (
                  <>
                    {labelPositionBackIndex[item] && (
                      <div key={item} className={`absolute ${labelPositionBackIndex[item]} `}>
                        <div
                          className={`${labelPositionBackDimensions[item]} bg-transparent border-b-2  border-r-2  rounded-br-md relative   ${
                            focusedItem === item ? 'border-r-lightGreen border-b-lightGreen' : 'border-r-bodyActiveColor border-b-bodyActiveColor'
                          } `}
                          onMouseEnter={() => handleMouseEnter(item)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <div
                            className={`w-2 h-2  rounded-full absolute -bottom-[4.5px] -left-1 cursor-pointer ${
                              focusedItem === item ? 'bg-lightGreen' : 'bg-bodyActiveColor'
                            }`}
                          ></div>
                          <div
                            className={` h-[31px] rounded-[3.51px] py-2 px-2 absolute -top-7 -right-12 cursor-pointer ${
                              focusedItem === item ? 'bg-lightGreen' : 'bg-bodyActiveColor'
                            }`}
                            onClick={() => handleClick(item)}
                          >
                            <div className="flex text-[#333333] text-xs ltr:font-primary-rg rtl:font-primary-he-rg font-bold  w-full gap-x-[1px]">
                              <p className=" truncate text-[#333333] text-xs ltr:font-primary-rg rtl:font-primary-he-rg font-bold ">
                                {data?.find((val) => val?.muscle_model_id == item)?.muscleName}
                              </p>
                              {showPercentage && (
                                <p className="text-[#333333] text-xs ltr:font-primary-rg rtl:font-primary-he-rg font-bold ">
                                  {musclePercentages[item]}%
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {labelPositionBackIndex[item] && (
                      <div key={item} className={`absolute ${labelPositionBackIndex[item]}`}>
                        <div
                          className={`${
                            labelPositionBackDimensions[item]
                          } bg-transparent border-b-2   border-l-2  rounded-bl-md relative hover:cursor-pointer  ${
                            focusedItem === item ? 'border-l-lightGreen border-b-lightGreen' : 'border-l-bodyActiveColor border-b-bodyActiveColor'
                          } `}
                          onMouseEnter={() => handleMouseEnter(item)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <div
                            className={`w-2 h-2  rounded-full absolute -bottom-[4.5px] -right-1 cursor-pointer ${
                              focusedItem === item ? 'bg-lightGreen' : 'bg-bodyActiveColor'
                            }`}
                          ></div>
                          <div
                            className={`  items-center  rounded-[3.51px] ${
                              item != 18 ? 'py-2 px-2 h-[31px] -top-7' : ' h-[25px] px-1 py-1 -top-5'
                            } absolute  -left-12 hover:cursor-pointer ${focusedItem === item ? 'bg-lightGreen' : 'bg-bodyActiveColor'}`}
                            onClick={() => handleClick(item)}
                          >
                            <div className="flex text-[#333333] text-xs ltr:font-primary-rg rtl:font-primary-he-rg font-bold  w-full gap-x-[1px]">
                              <p className=" truncate text-[#333333] text-xs ltr:font-primary-rg rtl:font-primary-he-rg font-bold ">
                                {data?.find((val) => val?.muscle_model_id == item)?.muscleName}
                              </p>
                              {showPercentage && (
                                <p className="text-[#333333] text-xs ltr:font-primary-rg rtl:font-primary-he-rg font-bold ">
                                  {musclePercentages[item]}%
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )
              })}
          </div>
        </FrontBackContainer>
      )}
      <RotateIconContainer onClick={() => setIsFront(!isFront)}>
        <div className=" bg-[#DE3730] w-5 h-5 rounded-full absolute -top-2 right-0 text-white text-center items-center flex justify-center">
          {!isFront ? countMatchingValues(frontMuscle, frontids) : countMatchingValues(backMuscle, backIds)}
        </div>
        <RotateIcon />
      </RotateIconContainer>
    </AllBodyMapContainer>
  )
}

export default WorkoutSummaryMuscleModal
