import { Images } from 'assets'
import CreateCustomerFooter from 'components/home/CustomersDetail/components/CreateCustomerFooter'
import EditCustomerFooter from 'components/home/CustomersDetail/components/EditCustomerFooter'

import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

const Modal = ({
  open,
  handleClose,
  children,
  title,
  className,
  notCrossIcon,
  style,
  childrenClass,
  handleSubmit,
  handleNotify,
  loader,
  isEdited,
  toggleOpen,
  toggleSuspendOpen,
  disabled,
  direction,
  footer,
  padding,
}) => {
  const { t } = useTranslation()
  const modalContainerRef = useRef()

  useEffect(() => {
    // 27 is the key code for escape
    function handleKeyDown(event) {
      if (event.keyCode === 27) {
        handleClose()
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
    // eslint-disable-next-line
  }, [])
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    // Add an event listener to detect when the window is resized
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768) // Adjust the width as needed
    }

    // Initial check for mobile view
    handleResize()

    // Attach the event listener
    window.addEventListener('resize', handleResize)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      {open && (
        <div className={`flex w-full h-full   ${disabled ? 'bg-[rgb(10, 21, 28,0.3)]' : ''}   `}>
          <div
            className={`w-screen h-screen  right-0 top-0 fixed shadow flex justify-end items-center`}
            style={{ backgroundColor: 'rgba(20, 32, 38, 0.7)', zIndex: 1000 }} // Apply opacity here
          >
            <div
              className={`relative text-white ${disabled ? 'bg-[rgb(10,21,28,1)]' : 'bg-sidebar'} ${
                direction === 'ltr' ? 'rounded-l-[28px]' : 'rounded-r-[28px]'
              }   ${className}`}
              ref={modalContainerRef}
            >
              <div className="flex flex-row items-center p-4  xl:px-6 2xl:px-12 border-b-[1px] border-borderColor  ">
                <p
                  className={`w-full text-base text-[#FFF] leading-5 md:leading-7 md:text-[32px] truncate  font-normal ${
                    direction === 'ltr' ? 'font-primary-bd' : 'font-primary-he-bd'
                  }  py-2`}
                >
                  {title}
                </p>
                <div>
                  <img
                    className="cursor-pointer"
                    src={isMobile ? Images.WorkoutSidebarRightArrow : Images.Close}
                    alt=""
                    width={isMobile ? '10px' : '30px'}
                    style={{ paddingTop: 10, paddingBottom: 10 }}
                    onClick={() => handleClose()}
                  />
                </div>
              </div>
              <div className={`flex flex-col w-full gap-4 ${padding ? 'p-4' : 'px-4 '} xl:px-6 2xl:px-12 h-[calc(100vh-150px)] ${childrenClass}`}>
                {children}
              </div>
              {!footer && (
                <>
                  {isEdited ? (
                    <EditCustomerFooter
                      loader={loader}
                      handleNotify={handleNotify}
                      handleSubmit={handleSubmit}
                      t={t}
                      toggleOpen={toggleOpen}
                      toggleSuspendOpen={toggleSuspendOpen}
                      disabled={disabled}
                      direction={direction}
                    />
                  ) : (
                    <CreateCustomerFooter loader={loader} handleNotify={handleNotify} handleSubmit={handleSubmit} t={t} direction={direction} />
                  )}
                </>
              )}

              {footer && <div className="">{footer}</div>}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export { Modal }
