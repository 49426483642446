import { Images } from 'assets'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getResistanceLabel } from 'utils'

export default function BestRecord({ data, summary }) {
  const { t } = useTranslation()
  return (
    <div className=" w-full h-full space-y-2 overflow-y-scroll no-scrollbar">
      <div className="flex w-full flex-col gap-4 justify-center items-center ">
        <p className=" text-2xl ltr:font-primary-rg rtl:font-primary-he-rg text-[#D9D9D9]">{`${summary?.achievementCard?.length} / ${summary?.PerformanceCard?.totalExercises}`}</p>
        <p className=" text-base ltr:font-primary-rg rtl:font-primary-he-rg text-[#D9D9D9]">{t('Exercises with new Achievement')}</p>
        <img src={Images.fire} alt="Achieve" className=" h-36 cursor-pointer " />
      </div>
      <div className="w-full  flex flex-col gap-2">
        {data?.map((item, index) => (
          <div className="flex flex-row justify-between w-full items-center" key={index}>
             <div className='w-[60%] overflow-ellipsis '>
            <p className=" text-sm ltr:font-primary-rg rtl:font-primary-he-rg text-white truncate w-1/2 ">{item?.exerciseName}</p>
            </div>
            <div className="flex gap-1 py-2 w-[35%] items-center justify-start">
              <p className=" text-xs ltr:font-primary-rg rtl:font-primary-he-rg text-[#A7ABB3]">
                {' '}
                ּ{`${item?.bestSetResistanceValue}*${item?.bestSetTrackingValue}`} {t(item?.bestSetResistanceLable)}
              </p>
              {item?.resistanceGapValue !== 0 && (
                <div
                  className={`font-primary-rg text-[12px] leading-4 font-normal ${
                    item?.resistanceGapValue > 0 ? 'text-[#53c22b] ' : 'text-[#f54b49] '
                  }   py-[2px] px-1 rounded`}
                >
                  {getResistanceLabel(item?.resistanceGapLable)} {item?.resistanceGapValue}
                </div>
              )}
              {item?.trackingGapValue !== 0 && (
                <div
                  className={`font-primary-rg text-[12px] leading-4 font-normal ${
                    item?.trackingGapValue > 0 ? 'text-[#53c22b] ' : 'text-[#f54b49] '
                  }  py-[2px] px-1 rounded`}
                >
                  {getResistanceLabel(item?.trackingGapLable)} {item?.trackingGapValue}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
