import styled from 'styled-components'
import ReactDatePicker from 'react-date-picker'

export const UpdateEventInfoBox = styled.div`
  border-radius: 16px;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  height: 500px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  form {
    width: 100%;
  }

  .error {
    color: #ff5449;
    font-size: 12px;
    font-weight: 400;
    margin: 4px 0;
  }

  .title-input {
    width: 100%;
    background: transparent;
    border: none;
    border-bottom: 1px solid #ffffff;
    padding: 4px 0;
    font-family: 'Poppins', sans-serif;
    color: #ffffff;

    &:focus {
      outline: none;
    }
  }
`

export const EventTypeListContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  margin: 20px 0;
  align-items: center;
  padding-bottom: 16px;
`

export const EventTypeList = styled.div`
  width: 100%;
  display: flex;
  overflow: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
  gap: 8px;

  button {
    background: rgba(36, 124, 187, 0.24);
    color: #ffffff;
    border: none;
    padding: 10px 24px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    border-radius: 14px;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &.active {
      border: 1.5px solid #65b1f4;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
`

export const EventInfoInputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 8px;

  .repeat-select {
    width: 100%;
  }

  .start-end-time {
    width: full;
    .select-menu-list {
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    .select-dropdown-indicator {
      display: none;
    }
  }

  .empty {
    width: 70px;
    .select-menu-list {
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
    .select-dropdown-indicator {
      display: none;
    }
  }
  .description-input {
    width: 100%;
    background: #5a5f66;
    border: none;
    height: 40px;
    border-radius: 12px;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    padding: 0 12px;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #ffffff90;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: #ffffff90;
    }

    &::-ms-input-placeholder {
      color: #ffffff90;
    }
  }
`

export const RepeatOptions = styled.div`
  width: 100%;
  display: flex;
  gap: 4px;
  margin-bottom: 8px;

  button {
    border-radius: 4px;
    padding: 6px 12px;
    background: rgba(36, 124, 187, 0.24);
    border: none;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;

    &.active {
      border: 1.5px solid #65b1f4;
    }
  }
`

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #bfe9ff32;

  button {
    border-radius: 12px;
    border: none;
    padding: 12px 16px;
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    cursor: pointer;

    &.cancel {
      background: transparent;
    }

    &.save {
      background: #00639b;
    }
  }
`

export const OpenEventFields = styled.div`
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid #bfe9ff32;
  margin-bottom: 16px;

  .note {
    display: flex;
    svg {
      margin-right: 8px;
      margin-top: 4px;
      width: 35px;
    }
  }
`

export const CloseEventFields = styled.div`
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid #bfe9ff32;
  margin-bottom: 16px;

  .select-value-container {
    height: 40px;
     display: flex !important;
       overflow: visible !important;
    flex-direction: column;
    -ms-overflow-style: none;
    scrollbar-width: none;
    justify-content: center;
    grid-template-columns: 1fr 1fr;

    &::-webkit-scrollbar {
      display: none;
    }
  }
.css-1fdsijx-ValueContainer:{
    display: flex !important;
       overflow: visible !important;
  }
  .select-disabled {
    .select-container {
      opacity: 0.6; /* Opacity for disabled state */
      pointer-events: none; /* Disable pointer events for disabled state */
    }

    .select-control {
      background-color: #3d434b; /* Background color for disabled state */
      cursor: not-allowed; /* Cursor style for disabled state */
    }

    .select-menu,
    .select-option,
    .select-single-value,
    .select-placeholder,
    .select-value-container {
      color: #a0a0a0; /* Text color for disabled state */
    }

    .select-menu-list {
      background-color: #f0f0f0; /* Background color for dropdown menu in disabled state */
    }

    .select-menu-list::-webkit-scrollbar {
      width: 8px; /* Scrollbar width for dropdown menu in disabled state */
    }

    .select-menu-list::-webkit-scrollbar-thumb {
      background-color: #a0a0a0; /* Scrollbar thumb color for dropdown menu in disabled state */
    }
  }
`

export const BlockEventFields = styled.div`
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid #bfe9ff32;
  margin-bottom: 16px;
`

export const TraineeLimitContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
`

export const OpenEventTypeContainer = styled.div`
  width: 100%;
  display: flex;
  overflow: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
  gap: 8px;
  margin-bottom: 8px;

  button {
    background: rgba(36, 124, 187, 0.24);
    color: #ffffff;
    border: none;
    padding: 10px 24px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    border-radius: 14px;

    &.active {
      border: 1.5px solid #65b1f4;
    }
  }
`

export const DatePicker = styled(ReactDatePicker)`
  span {
    color: #ffffff;
  }
  .react-date-picker__wrapper {
    background: #5a5f66;
    border: none;
    border-radius: 12px;
    padding: 0 12px;
    height: 40px;

    .react-date-picker__calendar-button {
      svg {
        width: 14px;
        stroke: #ffffff;
      }
    }
    .react-date-picker__inputGroup {
      direction: ltr !important;
    }
  }

  input {
    color: #ffffff;
    font-family: 'Poppins', sans-serif;

    &::placeholder {
      color: #ffffff90;
      opacity: 1;
    }
  }

  .react-calendar {
    background: #5a5f66;
    border: none;
    margin-bottom: 8px;
    margin-top: 8px;

    button {
      color: #ffffff;
      font-family: 'Poppins', sans-serif;

      &.react-calendar__tile--now {
        background: #00639b;
      }

      &.react-calendar__tile--active {
        background: #00639b;
      }

      &:hover {
        background: #00639b;
      }

      &:disabled {
        background: #5a5f66;
        color: #ffffff90;
      }
    }
  }
`
export const DatePickerBetween = styled(ReactDatePicker)`
  span {
    color: #ffffff;
  }
  .react-date-picker__wrapper {
    background: #5a5f66;
    border: none;
    border-radius: 12px;
    padding: 0px 4px;
    height: 40px;

    .react-date-picker__calendar-button {
      display: none;
    }
    .react-date-picker__inputGroup {
      direction: ltr !important;
    }
  }

  input {
    color: #ffffff;
    font-family: 'Poppins', sans-serif;

    &::placeholder {
      color: #ffffff90;
      opacity: 1;
    }
  }

  .react-calendar {
    background: #5a5f66;
    border: none;
    margin-bottom: 8px;
    margin-top: 8px;

    button {
      color: #ffffff;
      font-family: 'Poppins', sans-serif;

      &.react-calendar__tile--now {
        background: #00639b;
      }

      &.react-calendar__tile--active {
        background: #00639b;
      }

      &:hover {
        background: #00639b;
      }

      &:disabled {
        background: #5a5f66;
        color: #ffffff90;
      }
    }
  }
`

export const EndDateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  p {
    width: 30%;
  }
`

export const DateBetweenContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  p {
    width: 30%;
  }
`
export const OccuranceContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: between;
  flex-direction: row;
  height: 40px;

  background: #5a5f66;
  border: none;
  border-radius: 12px;
  padding: 0px 4px;
  p {
    width: 30%;
  }
`

// display: flex;
//     overflow: visible;