import * as yup from 'yup'

const gymSchema = yup.object().shape({
  email: yup.string().email('ENTER_EMAIL').required('MUST_EMAIL'),
  instituteName: yup
    .string()
    .required('INSTITUTE_REQUIRED')
    .matches(/^[A-Za-z\u05D0-\u05F4\d\s]+$/gi, 'INVALID_NAME'),

  firstName: yup
    .string()
    .required('MANAGER_NAME_REQUIRED')
    .matches(/^[A-Za-z\u05D0-\u05F4\d\s]+$/gi, 'INVALID_NAME'),
  phoneNo: yup
    .string()
    .required('PHONE_REQUIRED')
    .matches(/^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/, 'INVALID_PHONE'),
})
const instrumentSchema = yup.object().shape({
  machine: yup
    .string()
    .required('PLAYER_NAME_REQUIRED')
    .matches(/^[A-Za-z\u05D0-\u05F4\d\s]+$/gi, 'CONTAIN_ONLY_LETTERS'),
  plate: yup.number().typeError('PLATES_REQUIRED').required('PLATES_REQUIRED'),
  boost: yup.number().typeError('BOOST_REQUIRED').required('BOOST_REQUIRED'),
})
const rubberBandSchema = yup.object().shape({
  bandType: yup
    .string()
    .required('PLAYER_NAME_REQUIRED')
    .matches(/^[A-Za-z\u05D0-\u05F4\d\s]+$/gi, 'CONTAIN_ONLY_LETTERS'),

  resistance: yup.number().typeError('RESISTANCE_REQUIRED').required('RESISTANCE_REQUIRED').min(1, 'MIN_RESISTANCE').max(10, 'MAX_RESISTANCE'),
})
const loginSchema = yup.object().shape({
  email: yup.string().email('ENTER_EMAIL').required('MUST_EMAIL'),
  password: yup.string().required('PASSWORD_REQUIRED'),
})
const editCustomerSchema = yup.object().shape({
  email: yup.string().email('ENTER_EMAIL').required('MUST_EMAIL'),
  userName: yup
    .string()
    .required('REQUIRED')
    .matches(/^[A-Za-z\u05D0-\u05F4\d\s]+$/gi, 'INVALID_NAME'),
  phoneNo: yup
    .string()
    .required('PHONE_REQUIRED')
    .matches(/^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/, 'INVALID_PHONE'),
})
const editUserSchema = yup.object().shape({
  email: yup.string().email('ENTER_EMAIL').required('MUST_EMAIL'),

  phoneNo: yup
    .string()
    .required('PHONE_REQUIRED')
    .matches(/^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/, 'INVALID_PHONE'),
})
const coachSchema = yup.object().shape({
  email: yup.string().email('ENTER_EMAIL').required('MUST_EMAIL'),
  fullName: yup
    .string()
    .required('COACH_NAME_REQUIRED')
    .matches(/^[A-Za-z\u05D0-\u05F4\d\s]+$/gi, 'INVALID_NAME'),

  phoneNo: yup
    .string()
    .required('PHONE_REQUIRED')
    .matches(/^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/, 'INVALID_PHONE'),
})
const traineeSchema = yup.object().shape({
  email: yup.string().email('ENTER_EMAIL').required('MUST_EMAIL'),
  traineeName: yup
    .string()
    .required('TRAINEE_NAME_REQUIRED')
    .matches(/^[A-Za-z\u05D0-\u05F4\d\s]+$/gi, 'INVALID_NAME'),

  phoneNo: yup
    .string()
    .required('PHONE_REQUIRED')
    .matches(/^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/, 'INVALID_PHONE'),
})
const changePasswordSchema = yup.object().shape({
  newPassword: yup.string().required('PASSWORD_REQUIRED'),
  confirmPassword: yup.string().required('PASSWORD_REQUIRED'),
})
const muscleSchema = yup.object().shape({
  muscleName: yup
    .string()
    .required('MUSCLE_NAME_REQUIRED')
    .matches(/^[A-Za-z\u05D0-\u05F4\d\s]+$/gi, 'INVALID_NAME'),
})
const exerciseSchema = yup.object().shape({
  exerciseName: yup
    .string()
    .required('EXERCISE_NAME_REQUIRED')
    .matches(/^[A-Za-z\u05D0-\u05F4\d\s]+$/gi, 'INVALID_NAME'),
})
const eventValidation = (t) =>
  yup.object().shape({
    title: yup.string().required(t('validations.title.required')),
    extendedProps: yup.object().shape({
      startTime: yup.string().required(t('validations.startTime.required')),
      coach: yup.string().required(t('validations.coach.required')),

      minTrainees: yup.number().when('eventType', {
        is: 'open-workout',
        then: () => yup.number().required(t('validations.minTrainees.required')),
        otherwise: () => yup.number(),
      }),
      maxTrainees: yup.number().when('eventType', {
        is: 'open-workout',
        then: () =>
          yup.number().required(t('validations.maxTrainees.required')).min(yup.ref('minTrainees'), t('validations.maxTrainees.greaterThan')),
        otherwise: () => yup.number(),
      }),
      cancelationBuffer: yup.number().when('eventType', {
        is: 'open-workout',
        then: () => yup.number().required(t('validations.cancelationBuffer.required')),
        otherwise: () => yup.number(),
      }),
      noOfTraineesInTurn: yup.number().when('openEventType', {
        is: 'turns',
        then: () => yup.number().required(t('validations.noOfTraineesInTurn.required')),
        otherwise: () => yup.number(),
      }),
      workoutType: yup.string().when('openEventType', {
        is: 'turns',
        then: () => yup.string().required(t('validations.workoutType.required')),
        otherwise: () => yup.string(),
      }),

      weekDays: yup.array().when('repeat', {
        is: 'everyWeek',
        then: () => yup.array().min(1, t('validations.weekDays.required')),
        otherwise: () => yup.array(),
      }),
    }),
  })

const exerciseWorkoutSchema = yup.object().shape({
  sets: yup.array().of(
    yup.object().shape({
      resistance_value: yup.number().typeError('Must be number').required('Required'),
      tracking_value: yup.number().typeError('Must be number').required('Required'),
      intensity_level: yup.number().min(1, 'RPE > 1').max(10, 'RPE < 10').typeError('Must be number').required('Required'),
      isBoost: yup.string().required(),
    })
  ),
  advanceTechnique: yup.mixed().nullable(),
  intensityTracking: yup.mixed().nullable(),
  mobility_technique: yup.boolean(),
  muscle_technique: yup.boolean(),
  control_technique: yup.boolean(),
})

const signUpTraineeSchema = yup.object().shape({
  traineeName: yup
    .string()
    .required('TRAINEE_NAME_REQUIRED')
    .matches(/^[A-Za-z\u05D0-\u05F4\d\s]+$/gi, 'INVALID_NAME'),
  phoneNo: yup
    .string()
    .required('PHONE_REQUIRED')
    .matches(/^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/, 'INVALID_PHONE'),
  email: yup.string().email('ENTER_EMAIL').required('MUST_EMAIL'),
  password: yup.string().required('PASSWORD_REQUIRED'),
  confirmPassword: yup
    .string()
    .required()
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value
    }),
})

export {
  gymSchema,
  instrumentSchema,
  rubberBandSchema,
  loginSchema,
  editCustomerSchema,
  coachSchema,
  traineeSchema,
  muscleSchema,
  exerciseSchema,
  exerciseWorkoutSchema,
  eventValidation,
  changePasswordSchema,
  editUserSchema,
  signUpTraineeSchema,
}
