import ImagePicker from "components/elements/ImagePicker";
import { constants } from "constant";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ProgramsDetail from "./ProgramsDetail";

import {
  getTraineeById,
  updateGymTrainee,
} from "store/gymAdminSlice/gymAdminActions";
import { getProgramByTrainee } from "store/traineeSlice/traineeActions";
import PersonalInfo from "./PersonalInfo";

const Menus = ["INFO", "PROGRAMS"];
export default function TraineeDetail() {
  const { t } = useTranslation();
  const [imageURL, setImageURL] = useState(null);
  const [imageError, setImageError] = useState(false);
  const [file, setFile] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedGender, setSelectedGender] = useState(constants.GENDER[0]);
  const [activityLevel, setActivityLevel] = useState(
    constants.ACTIVITY_LEVEL[0]
  );
  const location = useLocation();
  const [active, setActive] = useState(Menus[0]);

  useEffect(() => {
    if (location?.state) {
      setActive(location?.state?.active);
    } else {
      setActive(Menus[0]);
    }
  }, [location]);

  const dispatch = useDispatch();
  const navigation = useNavigate();

  const { currentTrainee } = useSelector((state) => state.gymAdmin);

  const { direction } = useSelector((state) => state.language);

  const { id } = useParams();
  const refreshData = () => {
    dispatch(getTraineeById(id, t, direction));
    dispatch(getProgramByTrainee(id, t, direction));
  };
  useEffect(() => {
    if (id) {
      refreshData();
    }

    // eslint-disable-next-line
  }, [id]);

  const action = () => {};

  useEffect(() => {
    if (currentTrainee?.User?.avatar != null) {
      setImageURL(currentTrainee?.User?.avatar);
    } else {
      setImageURL(null);
    }
  }, [currentTrainee]);

  const updateTrainee = (values) => {
    let data = {
      ...values,
      gender: selectedGender?.value,
      birthday: selectedDate ? selectedDate : null,
      activityLevel: activityLevel,
      userId: currentTrainee?.User?.id,
      traineeId: currentTrainee?.id,
    };

    const formData = new FormData();
    if (file) {
      formData.append("file", file);
    }
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    dispatch(
      updateGymTrainee(formData, action, t, direction, currentTrainee?.id)
    );
  };

  return (
    <>
      <div
        className="flex sticky top-0 flex-col bg-borderActive "
        style={{ zIndex: 100 }}
      >
        <div className="flex flex-row w-full justify-between px-8 py-2">
          <div
            className={`flex gap-x-2 text-white text-[12px] font-bold h-5 ltr:font-primary-md rtl:font-primary-he-md`}
          >
            <span
              className=" cursor-pointer  hover:border-white hover:border-b-2"
              onClick={() => {
                navigation("/admin/trainees");
              }}
            >
              {t("TRAINEES")}
            </span>{" "}
            /{" "}
            <span className=" text-white text-[12px] ltr:font-primary-md rtl:font-primary-he-md ">
              {currentTrainee?.User?.firstName}
            </span>
            /{" "}
            <span className=" text-breadCrumb text-[12px] ltr:font-primary-md rtl:font-primary-he-md ">
              {t(active)}
            </span>
          </div>
          <div className="flex flex-col">
            <div className="flex w-[32px] h-[32px] bg-[#d5d5d5] rounded-[4px] justify-center items-center">
              <div className="flex bg-[#0A3D60] w-[23px] h-[23px] rounded-[50%]"></div>
            </div>
            <p
              className={`text-white text-[12px] ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"} text-center`}
            >
              {t("COLOR")}
            </p>
          </div>
        </div>

        <ImagePicker
          placeholder={t("Profile picture")}
          imageURL={imageURL}
          setImageURL={setImageURL}
          imageError={imageError}
          errorMessage={t("IMAGE_REQUIRED")}
          setImageError={setImageError}
          setSelectedFile={setFile}
          selectedFile={file}
          styles={`w-[140px] h-[140px] items-center  justify-center   rounded-[50%]  ${!imageURL && "border-dashed border-[1px]"}`}
        />

        <div className="flex w-full justify-center">
          <p
            className={`text-white text-[32px] ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"} text-center`}
          >
            {currentTrainee?.User?.firstName}
          </p>
        </div>
        <div className="flex w-full self-center flex-row justify-center lg:w-[80%]">
          {Menus.map((item, index) => (
            <div
              key={index}
              className={`text-white text-[14px] ${
                direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"
              } text-center py-2 cursor-pointer px-8   ${item === active ? " border-b-2 border-white" : ""} `}
              onClick={() => setActive(item)}
            >
              {t(item)}
            </div>
          ))}
        </div>
      </div>
      <div
        className="flex justify-center items-center "
        style={{ zIndex: 1000 }}
      >
        {active === "PROGRAMS" && (
          <ProgramsDetail
            refreshData={refreshData}
            active={active}
            name={currentTrainee?.User?.firstName}
          />
        )}

        {active === "STATISTICS" && <div>STATISTICS</div>}
        {active === "MY_TRAININGS" && <div>MY_TRAININGS</div>}
        {active === "MY_METRICES" && <div>MY_METRICES</div>}
        {active === "INFO" && (
          <PersonalInfo
            data={currentTrainee}
            file={file}
            onSubmit={updateTrainee}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            activityLevel={activityLevel}
            setActivityLevel={setActivityLevel}
            selectedGender={selectedGender}
            setSelectedGender={setSelectedGender}
          />
        )}
      </div>
    </>
  );
}
