import OutlineButton from 'components/elements/OutlineButton'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCustomers, getFilter } from 'store/userSlice/userActions'
import { useDebounce } from 'hooks'
import { Images } from 'assets'
import { SearchInput } from 'components/compounds/SearchInput'
import PrimaryButton from 'components/elements/PrimaryButton'
import { constants } from 'constant'
import { useOutsideClick } from 'hooks'
import { toast } from 'react-toastify'
import { handleFilterSelection } from 'utils'

function ListHeader(props) {
  const { filterOptions, ACTIVE } = constants
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { toggelModal, setSearchText } = props
  const { direction } = useSelector((state) => state.language)
  const { filter } = useSelector((state) => state.user)
  const [searchInput, setSearchInput] = useState('')
  const filtersRef = useRef()
  const [filtersMenu, setFiltersMenu] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState('')
  const [activeFilter, setActiveFilter] = useState(ACTIVE)
  const [selectedFilterMenu, setSelectedFilterMenu] = useState(false)

  const [temp1, setTemp1] = useState({
    REGISTERATION_STATUS: [],
    ACTIVE: [],
  })
  const debouncedSearchTerm = useDebounce(searchInput, 500)
  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchText(searchInput)
    }, 500)

    return () => {
      clearTimeout(handler)
    }
    // eslint-disable-next-line
  }, [searchInput])

  const setSearch = (event) => {
    setSearchInput(event.target.value)
  }

  useEffect(() => {
    const getData = async () => {
      dispatch(getFilter())
    }
    getData()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const getData = async () => {
      if (temp1.ACTIVE.length > 0 || temp1.REGISTERATION_STATUS.length > 0) {
        dispatch(getAllCustomers(t, direction, temp1))
        return
      } else if (debouncedSearchTerm) {
        dispatch(getAllCustomers(t, direction, { search: debouncedSearchTerm }))
        return
      } else {
        dispatch(getAllCustomers(t, direction))
      }
    }

    getData()
    // eslint-disable-next-line
  }, [temp1, debouncedSearchTerm])

  const onClickOutsideFiltersDiv = () => {
    setFiltersMenu(false)
    setSelectedFilterMenu(false)
  }

  useOutsideClick(filtersRef, onClickOutsideFiltersDiv)

  return (
    <div className="flex w-full flex-col ">
      <div className="flex flex-row w-full px-8 py-5 justify-between items-center ">
        <div className={`text-breadCrumb ${direction === 'ltr' ? 'font-primary-md' : 'font-primary-he-md'}`}>{t('MAIN')}</div>
        <div className="flex gap-4">
          <OutlineButton
            text={t('COPY_LINK')}
            type="submit"
            disabled={false}
            isIcon={true}
            icon={Images.Copy}
            onClick={() =>
              toast('🦄 Wow so easy!', {
                position: 'bottom-left',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              })
            }
            className="w-[157px] h-[44px] rounded-[12px] border-borderActive border-[1.5px] shadow-2xl "
            bgColor="bg-primary hover:bg-darkGray focus:bg-lightGray active:bg-lightGray"
            textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
            wid={18}
            ht={18}
          />
          <PrimaryButton
            text={t('ADD_INSTITUTE')}
            type="submit"
            disabled={false}
            isIcon={true}
            icon={Images.Plus}
            onClick={() => {
              toggelModal()
            }}
            className="w-[177px] h-[44px] rounded-[12px] shadow-2xl "
            bgColor="bg-sidebarhover hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
            textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
            wid={15}
            ht={15}
          />
        </div>
      </div>
      <div className="flex flex-row w-full px-8 py-2 justify-between items-center">
        <div className="text-white text-[24px] font-primary-bd">{t('CUTOMER_LIST')}</div>
      </div>
      <div className="flex flex-row w-full px-8 py-2 gap-4 items-center">
        <SearchInput
          radius={'rounded-[50px]'}
          placeholder={t('SEARCH')}
          className="flex flex-1"
          disableUnderline
          fullWidth
          value={searchInput}
          inputProps={{
            'aria-label': 'Search',
          }}
          onChange={(ev) => setSearch(ev)}
          icon={Images.Search}
        />
        <div ref={filtersRef} className="relative box-border">
          <div
            className={`flex flex-row w-[156px] h-[40px] items-center rounded-[12px] cursor-pointer bg-primary
            border-borderActive border-[1.5px]
            `}
            onClick={() => {
              setFiltersMenu(!filtersMenu)
              setSelectedFilterMenu(false)
            }}
          >
            <img
              src={Images.Filter}
              alt=""
              height={40}
              width={45}
              className={`p-3 ${direction === 'rtl' && 'mx-2'}  items-center `}
              onClick={() => {}}
            />
            <p className={`text-white text-center text-[14px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>
              {t('ADDING_FILTER')}
            </p>
          </div>
          {filtersMenu ? (
            <div
              className={`absolute top-11 py-3 bg-statusBg rounded-[12px] w-[156px] max-h-[200px] overflow-auto shadow-xl ${
                filtersMenu ? 'border-borderActive border-[2px]' : ''
              }`}
            >
              {filterOptions.map((opt, i) => (
                <p
                  key={i}
                  className={`px-2 py-1 text-textGrey text-[12px] ${
                    direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                  } hover:bg-checkoutHover cursor-pointer`}
                  onClick={() => {
                    if (opt === 'ACTIVE') {
                      setActiveFilter(filter?.ACTIVE)
                    } else if (opt === 'REGISTERATION_STATUS') {
                      setActiveFilter(filter?.REGISTERATION_STATUS)
                    } else {
                      setActiveFilter(ACTIVE)
                    }
                    setSelectedFilter(opt)
                    setFiltersMenu(false)
                    setSelectedFilterMenu(true)
                  }}
                >
                  {t(opt)}
                </p>
              ))}
            </div>
          ) : null}

          {/* Main Selected Filter */}
          {selectedFilterMenu ? (
            <div
              className={`absolute top-11 py-3 bg-statusBg rounded-[12px] w-[156px] max-h-[200px] overflow-auto shadow-xl ${
                selectedFilterMenu ? 'border-borderActive border-[2px]' : ''
              }`}
            >
              <div
                className="flex flex-row hover:bg-checkoutHover cursor-pointer"
                onClick={() => {
                  setSelectedFilterMenu(false)
                  setFiltersMenu(true)
                }}
              >
                <img src={direction === 'rtl' ? Images.backArrow : Images.backLessArrow} alt="" height={8} width={25} className={'p-1'} dir="ltr" />
                <p className={`py-1 text-textGrey text-[12px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} `}>
                  {t(selectedFilter)}
                </p>
              </div>
              {activeFilter.map((opt, i) => (
                <div
                  key={i}
                  className={`flex flex-row px-2 py-1  justify-between ${
                    direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                  } hover:bg-checkoutHover cursor-pointer`}
                  dir={direction}
                  onClick={() => {
                    handleFilterSelection(selectedFilter, opt, temp1, setTemp1)
                  }}
                >
                  <p
                    className={`text-[12px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} ${
                      temp1.ACTIVE.includes(opt.label) || temp1.REGISTERATION_STATUS.includes(opt.label) ? 'text-activeText' : 'text-textGrey'
                    } `}
                  >
                    {t(opt.label)}
                  </p>
                  <p
                    className={`text-[12px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} ${
                      temp1.ACTIVE.includes(opt.label) || temp1.REGISTERATION_STATUS.includes(opt.label) ? 'text-activeText' : 'text-textGrey'
                    } `}
                  >
                    {t(opt.count)}
                  </p>
                  {(temp1.ACTIVE.includes(opt) || temp1.REGISTERATION_STATUS.includes(opt)) && (
                    <img src={Images.CheckCircle} alt="" height={10} width={15} className={`items-center cursor-pointer`} onClick={() => {}} />
                  )}
                </div>
              ))}
            </div>
          ) : null}
        </div>
        {Object.keys(temp1).map((value, index) => {
          return (
            temp1[value] &&
            temp1[value].length > 0 &&
            temp1[value].map((item) => {
              return (
                <div className="flex flex-row items-center  gap-1 bg-statusBg px-3 py-1 rounded-[16px] hover:border-borderActive hover:border-[2px]">
                  <p className={`text-[14px] text-textGrey  ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>{t(item)}</p>
                  <img
                    src={Images.CloseCircle}
                    alt=""
                    height={10}
                    width={15}
                    className={`ml-1 mr-2 cursor-pointer lg:mr-0 lg:ml-0 xl:mr-0 xl:ml-0 `}
                    onClick={() => {
                      if (index === 0) {
                        const updatedItems = temp1['REGISTERATION_STATUS'].filter((i) => i !== item)
                        setTemp1({
                          ...temp1,
                          REGISTERATION_STATUS: updatedItems,
                        })
                      } else {
                        const updatedItems = temp1['ACTIVE'].filter((i) => i !== item)
                        setTemp1({
                          ...temp1,
                          ACTIVE: updatedItems,
                        })
                      }
                    }}
                  />
                </div>
              )
            })
          )
        })}
      </div>
    </div>
  )
}

export default ListHeader
