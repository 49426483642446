import MuscleInfo from 'components/programInfo/MuscleInfo'
import ProgramInfoHeader from 'components/programInfo/ProgramInfoHeader'
import ProgramStatisticExercisePlayedHistory from 'components/programInfo/ProgramStatisticExercisePlayedHistory'
import ProgramStatisticsCommentsHistory from 'components/programInfo/ProgramStatisticsCommentsHistory'
import WeeklyWorkouts from 'components/programInfo/WeeklyWorkouts'
import { useState } from 'react'
import { useSelector } from 'react-redux'

export default function ProgramInfo() {
  const { programInfo, programStatistics } = useSelector((state) => state.program)

  const [singleMuscle, setSingleMuscle] = useState(null)
  const [temp1, setTemp1] = useState({
    WORKOUT_PATTERN: [],
  })
  const [selectedWeek, setSelectedWeek] = useState()
  const [programfilterOptions, setProgramfilterOptions] = useState([])
  const [selectedExercise, setSelectedExercise] = useState(null)
  const [show, setShow] = useState(false)
  const [showComments, setShowComments] = useState(false)
  const [loader, setLoader] = useState(false)

  return (
    <>
      <div className="h-full w-full overflow-y-scroll has-scrollbar ">
        <ProgramInfoHeader
          data={programInfo}
          setTemp1={setTemp1}
          temp1={temp1}
          setSelectedWeek={setSelectedWeek}
          selectedWeek={selectedWeek}
          setProgramfilterOptions={setProgramfilterOptions}
          programfilterOptions={programfilterOptions}
          setSingleMuscle={setSingleMuscle}
          setSelectedExercise={setSelectedExercise}
        />
        <div className="px-8 py-2 h-[617px] w-full ">
          <MuscleInfo
            data={programStatistics}
            singleMuscle={singleMuscle}
            setSingleMuscle={setSingleMuscle}
            filters={temp1}
            selectedWeek={selectedWeek}
            programfilterOptions={programfilterOptions}
            selectedExercise={selectedExercise}
            setSelectedExercise={setSelectedExercise}
            setShow={setShow}
          />
        </div>
        <div className="px-8 py-2 mb-5 w-full  ">
          <WeeklyWorkouts setShow={setShow} setShowComments={setShowComments} setLoader={setLoader} />
        </div>
      </div>
      <ProgramStatisticExercisePlayedHistory show={show} setShow={setShow} />
      <ProgramStatisticsCommentsHistory show={showComments} setShow={setShowComments} loader={loader} />
    </>
  )
}
