import React from 'react'
import ReactSelect, { components } from 'react-select'
import { useTranslation } from 'react-i18next'

import SelectContainer from './styles'

function Select({ selectContainerClass, id, options, value, disabled, customOptionComponent, ...props }) {
  const { t } = useTranslation()

  // Translate the labels in the options array
  const translatedOptions = options.map((option) => ({
    ...option,
    label: id !== 'time' ? t(option.label) : option.label,
  }))
  let translatedValue
  if (id && id === 'time') {
    translatedValue = value && value?.label
  } else {
    translatedValue = value ? t(value.label) : null
  }
  const customComponents = {
    Option: customOptionComponent || components.Option,
  }
  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      display: 'flex',
      overflow: 'visible',
      // Add more styles as needed
    }),
  };
  return (
    <SelectContainer className={selectContainerClass}>
      <ReactSelect
        components={customComponents}
        styles={customStyles}
        // classNamePrefix="select"
        classNames={{
          container: () => (disabled ? 'select-disabled' : 'select-container'),
          control: () => 'select-control',
          indicatorSeparator: () => 'select-indicator-separator',
          menu: () => 'select-menu',
          menuList: () => 'select-menu-list',
          option: () => 'select-option',
          singleValue: () => 'select-single-value',
          placeholder: () => 'select-placeholder',
          dropdownIndicator: () => 'select-dropdown-indicator',
          valueContainer: () => 'select-value-container',
        }}
        options={translatedOptions}
        value={id !== 'CloseWorkout' ? { label: translatedValue, value: value ? value.value : null } : value}
        {...props}
        isDisabled={disabled}
      />
    </SelectContainer>
  )
}

export default Select
