// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/fonts/AktivGrotesk-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../assets/fonts/AktivGrotesk-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../assets/fonts/AktivGrotesk-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../assets/fonts/AktivGrotesk_Hebr_Rg.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../assets/fonts/AktivGrotesk_Hebr_Md.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../assets/fonts/AktivGrotesk_Hebr_Bd.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("../assets/fonts/Kanit-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("../assets/fonts/Kanit-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("../assets/fonts/Kanit-Black.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("../assets/fonts/Kanit-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Activ-Grotesk-Rg';
  src: local('font'), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
}
@font-face {
  font-family: 'Activ-Grotesk-Md';
  src: local('font'), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
}
@font-face {
  font-family: 'Activ-Grotesk-Bd';
  src: local('font'), url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
}

@font-face {
  font-family: 'Activ-Grotesk-Heb-Rg';
  src: local('font'), url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype');
}
@font-face {
  font-family: 'Activ-Grotesk-Heb-Md';
  src: local('font'), url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('truetype');
}
@font-face {
  font-family: 'Activ-Grotesk-Heb-Bd';
  src: local('font'), url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('truetype');
}

@font-face {
  font-family: 'kanit-Bd';
  src: local('font'), url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format('truetype');
}

@font-face {
  font-family: 'kanit-Md';
  src: local('font'), url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format('truetype');
}

@font-face {
  font-family: 'kanit-black';
  src: local('font'), url(${___CSS_LOADER_URL_REPLACEMENT_8___}) format('truetype');
}
@font-face {
  font-family: 'kanit-Rg';
  src: local('font'), url(${___CSS_LOADER_URL_REPLACEMENT_9___}) format('truetype');
}
`, "",{"version":3,"sources":["webpack://./src/styles/fonts.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,8EAAsF;AACxF;AACA;EACE,+BAA+B;EAC/B,8EAAqF;AACvF;AACA;EACE,+BAA+B;EAC/B,8EAAmF;AACrF;;AAEA;EACE,mCAAmC;EACnC,8EAAsF;AACxF;AACA;EACE,mCAAmC;EACnC,8EAAsF;AACxF;AACA;EACE,mCAAmC;EACnC,8EAAsF;AACxF;;AAEA;EACE,uBAAuB;EACvB,8EAA4E;AAC9E;;AAEA;EACE,uBAAuB;EACvB,8EAA8E;AAChF;;AAEA;EACE,0BAA0B;EAC1B,8EAA6E;AAC/E;AACA;EACE,uBAAuB;EACvB,8EAA+E;AACjF","sourcesContent":["@font-face {\n  font-family: 'Activ-Grotesk-Rg';\n  src: local('font'), url('../assets/fonts/AktivGrotesk-Regular.ttf') format('truetype');\n}\n@font-face {\n  font-family: 'Activ-Grotesk-Md';\n  src: local('font'), url('../assets/fonts/AktivGrotesk-Medium.ttf') format('truetype');\n}\n@font-face {\n  font-family: 'Activ-Grotesk-Bd';\n  src: local('font'), url('../assets/fonts/AktivGrotesk-Bold.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'Activ-Grotesk-Heb-Rg';\n  src: local('font'), url('../assets/fonts/AktivGrotesk_Hebr_Rg.ttf') format('truetype');\n}\n@font-face {\n  font-family: 'Activ-Grotesk-Heb-Md';\n  src: local('font'), url('../assets/fonts/AktivGrotesk_Hebr_Md.ttf') format('truetype');\n}\n@font-face {\n  font-family: 'Activ-Grotesk-Heb-Bd';\n  src: local('font'), url('../assets/fonts/AktivGrotesk_Hebr_Bd.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'kanit-Bd';\n  src: local('font'), url('../assets/fonts/Kanit-Bold.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'kanit-Md';\n  src: local('font'), url('../assets/fonts/Kanit-Medium.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'kanit-black';\n  src: local('font'), url('../assets/fonts/Kanit-Black.ttf') format('truetype');\n}\n@font-face {\n  font-family: 'kanit-Rg';\n  src: local('font'), url('../assets/fonts/Kanit-Regular.ttf') format('truetype');\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
