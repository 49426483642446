import React, { useState, useEffect } from 'react'
import { Images } from 'assets'

const PrimaryLoader = ({ text }) => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    let interval

    // If loading is true, reset progress to 0 and start the animation again
    setProgress(0)

    interval = setInterval(() => {
      setProgress((prevProgress) => {
        const nextProgress = prevProgress + 20
        return nextProgress < 100 ? nextProgress : 0
      })
    }, 500)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <div className="primary-loader-container">
      <div className="loader-content ">
        <div>
          <img src={Images.Logo_Dark} alt="loginBanner" className="hidden md:block w-[200px] h-[200px] rounded-[100px] object-fill" />
        </div>
        <div
          className="progress-container"
          style={{
            width: '100%',
            height: '5px',
            backgroundColor: '#e0e0e0',
            borderRadius: '4px',
            overflow: 'hidden',
            marginTop: '10px',
          }}
        >
          <div
            id="progress-bar"
            className="progress-bar"
            style={{
              height: '100%',
              backgroundColor: '#4caf50',
              width: `${progress}%`,
              transition: 'width 0.1s ease-in-out',
            }}
          />
        </div>
        {text && <p className="text-[1.2rem] py-2">{text}</p>}
      </div>
    </div>
  )
}

export default PrimaryLoader
