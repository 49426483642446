import { Images } from 'assets'
import CustomOptions from 'components/compounds/CustomOptions'
import SwitchToggle from 'components/compounds/SwitchToggle'
import { constants } from 'constant'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import MachineSelectionDropDown from './MachineSelectionDropDown'
import MainExercisesDropDownWithSearch from './MainExercisesDropDownWithSearch'
import { NotesInput } from './NotesInput'
import Tags from './Tags'
import SelectionDropDown from './ToolSelectionDropDown'

export default function AdvancedExercise({
  setResistanceName,
  resistanceName,
  setTrackingName,
  trackingName,
  setDifficultyLevel,
  difficultyLevel,
  setIsSingleBodyPart,
  isSingleBodyPart,
  setMainExercise,
  mainExercise,
  machine,
  setMachine,
  selectedTags,
  setSelectedTags,
  exerciseID,
  setPosition,
  position,
  setIncline,
  incline,
  tools,
  setTools,
  setCategory,
  category,
  setDescription,
  description,
  isCopy,
  disable,
}) {
  const { direction } = useSelector((state) => state.language)
  const { mainExercises, tags } = useSelector((state) => state.exercise)
  const { gymInstruments } = useSelector((state) => state.user)

  const { t } = useTranslation()
  return (
    <div className="overflow-y-scroll">
      <CustomOptions
        label={t('RESISTANCE')}
        name="Resistance"
        value={resistanceName}
        setValue={setResistanceName}
        t={t}
        data={constants.RESISTANCE}
        disabled={exerciseID != null && !isCopy ? true : false}
        className={'gap-1'}
      />
      {resistanceName === 'machine' && (
        <MachineSelectionDropDown
          options={gymInstruments.filter((item) => item?.isRubberBand === false)}
          className="my-2"
          title={t('MACHINE')}
          value={machine}
          setValue={setMachine}
          disable={exerciseID != null && !isCopy ? true : false}
          t={t}
          dir={direction}
          isRequired={true}
          placeHolder={t('MACHINE')}
        />
      )}
      <SelectionDropDown
        options={constants.TOOLS}
        className="my-2"
        title={t('TOOLS')}
        value={tools}
        setValue={setTools}
        disable={disable}
        t={t}
        dir={direction}
        isRequired={false}
        placeHolder={t('TOOLS')}
      />
      <CustomOptions
        label={t('TRACKING')}
        name="Tracking Type"
        value={trackingName}
        setValue={setTrackingName}
        t={t}
        data={constants.TRACKING_TYPE}
        disabled={exerciseID != null && !isCopy ? true : false}
        className={'gap-4'}
      />
      <CustomOptions
        label={t('POSITION')}
        name="Position"
        value={position}
        setValue={setPosition}
        t={t}
        data={constants.POSITION}
        disabled={disable}
        className={'gap-4'}
      />
      <CustomOptions
        label={t('INCLINE')}
        name="Incline"
        value={incline}
        setValue={setIncline}
        t={t}
        data={constants.INCLINE}
        disabled={disable}
        className={'gap-4'}
      />
      <CustomOptions
        label={t('DIFFICULTY_LEVEL')}
        name="DIfficulty level"
        value={difficultyLevel}
        setValue={setDifficultyLevel}
        t={t}
        data={constants.DIFFICULTY_LEVEL}
        disabled={disable}
        className={'gap-4'}
      />
      <div className="py-4">
        <SwitchToggle
          name={'SINGLE_BODY_PART'}
          t={t}
          handleChange={() => {
            setIsSingleBodyPart(!isSingleBodyPart)
          }}
          checked={isSingleBodyPart}
          disable={disable}
        />
      </div>
      <div className="flex flex-row gap-2">
        <div className="py-1">
          <img src={Images.Info} alt="" height={15} width={15} />
        </div>
        <p className={`flex text-[14px] text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>{t('CATEGORY')}</p>
      </div>
      <SelectionDropDown
        options={constants.CATEGORY}
        className="my-2"
        value={category}
        setValue={setCategory}
        disable={disable}
        t={t}
        dir={direction}
        isRequired={false}
        placeHolder={t('CATEGORY')}
      />
      <div className="flex flex-row gap-2">
        <div className="py-1">
          <img src={Images.Info} alt="" height={15} width={15} />
        </div>
        <p className={`flex text-[14px] text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>{t('LINKING_EXERCISE')}</p>
      </div>
      <MainExercisesDropDownWithSearch
        options={mainExercises}
        className="my-2"
        title={t('MAIN_EXERCISE')}
        value={mainExercise?.name}
        setValue={(exc) => {
          if (exc != null) {
            const foundObject = mainExercises.find((obj) => obj.exerciseName === exc)
            setMainExercise({ id: foundObject?.id, name: exc })
          } else {
            setMainExercise(null)
          }
        }}
        disable={disable}
        t={t}
        dir={direction}
        isRequired={false}
        placeHolder={t('MAIN_EXERCISE')}
      />
      <NotesInput
        name={t('NOTES')}
        type="text"
        value={description}
        setValue={setDescription}
        label={t('NOTES')}
        placeholderValue={t('NOTES')}
        required={false}
        disable={disable}
        fullWidth
        dir={direction}
      />
      <Tags
        options={tags}
        className="my-2"
        title={t('ADD_TAGS')}
        value={selectedTags}
        setValue={setSelectedTags}
        disable={disable}
        t={t}
        dir={direction}
        isRequired={false}
        placeHolder={t('ADD_TAGS')}
      />
      <div className="flex flex-row mb-7">
        <p className={`text-[12px] text-[#929292] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} px-2`}>{t('TAGS')}:</p>
        {selectedTags?.map((item) => (
          <div
            className={`bg-borderActive ${
              direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
            } text-[14px] text-white px-2 rounded-[8px] mx-1`}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  )
}
