import { Images } from "assets";
import Assets from "assets/images";
import BodyMap from "components/bodyMap";
import ActionSheet from "components/compounds/ActionSheet";
import { Loader } from "components/compounds/Loader";
import CustomToastify from "components/compounds/Toast";
import PrimaryButton from "components/elements/PrimaryButton";
import { useState } from "react";
import Confetti from "react-confetti";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  completeWorkoutByTrainee,
  getWorkoutPatternsForSwap,
  reindexWorkoutExercises,
  swapWorkoutFromTrainee,
} from "store/traineeSlice/traineeActions";
import {
  completeWorkout,
  gettingTraineeWorkoutPattern,
  reindexExercises,
  startWorkoutEnableWorkoutExercises,
  swapWorkout,
} from "store/workoutSlice/workoutActions";
import {
  useWorkoutSelector,
  workoutActions,
} from "store/workoutSlice/workoutReducer";
import { getResistanceLabel, getResistanceLabelConfetti } from "utils";
import { ProfileAvatar } from "./ProfileAvatar";
import { WorkoutSummary } from "./WorkoutSummary";
import { Exercise, MuscleSummary, RightClickMenu } from "./components";

const ActiveWorkout = ({
  isLoading,
  workoutId,
  traineeId,
  tranieeImage,
  traineeName,
  workoutName,
  muscleSummary,
  exercises,
  muscleIds,
  onRemoveWorkout,
  onRemoveExercise,
  onHistoryClick,
  onCommentsClick,
  onAddExerciseClick,
  onSwapClick,
  status,
  patternIndex,
  workoutSummary,
  isTrainee,
  refresh,
  data,
  setData,
  onClickExerciseInfo,
  coachId,
  eventId,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isMuscleOpen, setMuscleOpen] = useState(false);
  const [openNextIndex, setOpenNextIndex] = useState(null);
  const [loader, setLoader] = useState(false);
  const [activeWorkoutLoader, setActiveWorkouts] = useState(false);
  const { direction } = useSelector((state) => state.language);
  const [isSwaping, setIsSwaping] = useState(false);
  const { workoutPattern, loading } = useSelector((state) => state.workout);
  const [isWorkoutActionSheet, setWorkoutActionSheet] = useState(false);
  const [isLoadingWorkoutPatterns, setLoadingWorkoutPatterns] = useState(false);
  const [workoutPatternsForExchange, setWorkoutPatternsForExchange] = useState(
    []
  );
  const { activeWorkouts } = useWorkoutSelector();
  const [bestRecordId, setBestRecordId] = useState([]);

  const toggleMuscle = () => setMuscleOpen(!isMuscleOpen);
  const toggleWorkoutAction = () =>
    setWorkoutActionSheet(!isWorkoutActionSheet);

  const isCompleted = status === "completed";
  const { t } = useTranslation();
  const isRTL = direction === "rtl";

  const onWorkoutCompleteClick = async () => {
    setLoader(true);
    try {
      const payload = {};
      const action = () => {
        setLoader(false);
      };
      if (isTrainee) {
        await completeWorkoutByTrainee(payload, workoutId);
        refresh && refresh();
        action();
      } else {
        dispatch(completeWorkout(payload, workoutId, action, t, direction));
      }
    } catch (error) {
      console.log({ error });
      setLoader(false);
    }
  };
  const onWorkoutStartClick = async () => {
    try {
      setActiveWorkouts(true);
      const action = () => {
        setActiveWorkouts(false);
      };
      if (isTrainee) {
        await dispatch(
          startWorkoutEnableWorkoutExercises(
            workoutId,
            t,
            direction,
            true,
            eventId
          )
        );

        refresh && refresh(true);
        action();
      } else {
        await dispatch(
          startWorkoutEnableWorkoutExercises(
            workoutId,
            t,
            direction,
            false,
            eventId
          )
        );

        action();
      }
    } catch (error) {
      console.log({ error });
      setLoader(false);
      setActiveWorkouts(false);
    }
  };

  const openNextExercise = (index) => {
    if (index !== exercises.length - 1) setOpenNextIndex(index + 1);
  };
  const resetNextHandler = () => setOpenNextIndex(null);

  if (isLoading) {
    return (
      <div role="status" className="w-full space-y-2 shadow animate-pulse">
        <div className="flex w-full items-center justify-between space-x-8 rtl:space-x-reverse">
          <div className="w-16 h-5 bg-gray-400 rounded-lg dark:bg-secondaryBackground"></div>
          <div className="flex w-full justify-between space-x-2 rtl:space-x-reverse items-center">
            <div className="w-48 h-5 bg-gray-400 rounded-lg dark:bg-secondaryBackground"></div>
            <div className="w-7 h-7 bg-gray-400 rounded-full dark:bg-secondaryBackground"></div>
          </div>
        </div>
        <div className="flex w-full items-center justify-between">
          <div className="w-full h-20 bg-gray-400 rounded-lg dark:bg-secondaryBackground"></div>
        </div>
        {new Array(8).fill(0)?.map((item, index) => (
          <div className="flex w-full items-center justify-between">
            <div className="w-full h-14 bg-gray-400 rounded-lg dark:bg-secondaryBackground"></div>
          </div>
        ))}
      </div>
    );
  }
  const isDisabled = !exercises?.some(
    (item) => item?.workoutExerciseStatus === "completed"
  );
  const isAnyCompletedOrPartiallyStarted = exercises?.some(
    (item) =>
      item?.workoutExerciseStatus === "completed" ||
      item?.workoutExerciseStatus === "partial"
  );

  const handleDragStart = (event, index) => {
    event.dataTransfer.setData("text/plain", index.toString());
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = async (event, targetExerciseWorkoutId) => {
    try {
      setLoader(true);
      event.preventDefault();
      const sourceExerciseWorkoutId = parseInt(
        event.dataTransfer.getData("text/plain"),
        10
      );
      const payload = {
        sourceExerciseWorkoutId,
        targetExerciseWorkoutId,
        workoutId,
      };
      const action = () => {
        setLoader(false);
      };
      if (sourceExerciseWorkoutId === targetExerciseWorkoutId) {
        action();
      } else {
        if (isTrainee) {
          await reindexWorkoutExercises(payload);
          action();
          refresh && refresh();
          CustomToastify(
            t("EXERCISE_ACTION"),
            t("EXERCISE_REORDERED_SUCCESSFULLLY"),
            "",
            "bg-toastSuccessbg",
            direction,
            Images.toastSucess,
            true
          );
        } else {
          dispatch(reindexExercises(payload, workoutId, action, t, direction));
        }
      }
    } catch (error) {
      console.log({ error });
      setLoader(false);
    }
  };

  const getWorkoutPattern = (workoutId, traineeId, patternIndex) => {
    setIsSwaping({ workoutId, traineeId, patternIndex });
    dispatch(
      gettingTraineeWorkoutPattern(workoutId, patternIndex, t, direction)
    );
  };

  const swapWorkooutAction = () => {
    setIsSwaping(false);
  };

  const handleSwapWorkout = (workoutId, patternIndex) => {
    let payload = {
      workoutId: workoutId,
      patternIndex: patternIndex,
    };
    dispatch(swapWorkout(payload, swapWorkooutAction, t, direction));
  };

  const completedExercise = exercises?.filter(
    (exercise) => exercise?.workoutExerciseStatus === "completed"
  ).length;
  const percentage =
    completedExercise === 0
      ? "10%"
      : `${(completedExercise / exercises?.length) * 100}%`;

  const getWorkoutsForSwapping = async () => {
    try {
      setLoadingWorkoutPatterns(true);
      const filteredWorkouts = await getWorkoutPatternsForSwap(
        workoutId,
        patternIndex
      );
      const submenuOptions = filteredWorkouts.map((item, index) => ({
        id: index + 1,
        label: item?.workoutName,
        details: item,
      }));
      setWorkoutPatternsForExchange(submenuOptions);
      setLoadingWorkoutPatterns(false);
    } catch (error) {
      setLoadingWorkoutPatterns(false);
      console.log("error", error);
    }
  };
  const onSwapWorkoutInActionSheet = async (workoutId, patternIndex) => {
    const payload = {
      workoutId: workoutId,
      patternIndex: patternIndex,
    };
    try {
      setLoadingWorkoutPatterns(true);
      await swapWorkoutFromTrainee(payload);
      setLoadingWorkoutPatterns(false);
      toggleWorkoutAction();
      refresh && refresh();
      CustomToastify(
        t("WORKOUT_SWAP_SUCCESSFULLY"),
        t(""),
        "",
        "bg-toastSuccessbg",
        direction,
        Images.toastSucess,
        true
      );
    } catch (error) {
      console.log("error", error);
      setLoadingWorkoutPatterns(false);
    }
  };

  const onCloseWorkout = () => {
    if (isTrainee) {
      navigate("/trainee/dashboard");
    } else {
      dispatch(workoutActions.removeWorkout({ workoutId }));
    }
  };
  const getAllPartail = (workoutId) => {
    let isPartial = false;
    if (data) {
      data.workoutData?.exercises?.forEach((exercise) => {
        if (
          exercise.workoutExerciseStatus === "partial" ||
          exercise.workoutExerciseStatus === "completed"
        ) {
          isPartial = true;
        }
      });
    } else {
      activeWorkouts.forEach((item) => {
        const workout = item.workoutData;

        if (workout.id === workoutId) {
          workout.exercises.forEach((exercise) => {
            if (
              exercise.workoutExerciseStatus === "partial" ||
              exercise.workoutExerciseStatus === "completed"
            ) {
              isPartial = true;
            }
          });
        }
      });
    }

    return isPartial;
  };
  return (
    <>
      <div className="w-full space-y-2 pb-2">
        {isCompleted ? (
          <WorkoutSummary
            summary={workoutSummary}
            workoutId={workoutId}
            muscleIds={muscleIds}
            traineeId={traineeId}
            tranieeImage={tranieeImage}
            traineeName={traineeName}
            workoutName={workoutName}
            exercises={exercises}
            onCommentsClick={onCommentsClick}
            onCloseWorkout={onCloseWorkout}
          />
        ) : (
          <>
            {/* Header */}
            {isTrainee ? (
              <>
                <div
                  className=" sticky top-0 flex flex-col w-full bg-primary"
                  style={{ zIndex: 100 }}
                >
                  <div className="flex w-full justify-between items-center space-x-2 rtl:space-x-reverse mb-2  ">
                    <div className="w-full h-[30px] rounded-full bg-textFieldColor justify-end flex">
                      <div
                        className="h-full rounded-full bg-[#9FF976]"
                        style={{ width: percentage }}
                      ></div>
                    </div>
                    <img
                      src={Images.CloseWorkout}
                      alt="asda"
                      className="h-7  rounded-2xl cursor-pointer"
                      onClick={() => navigate("/trainee/dashboard")}
                    />
                  </div>
                  <div className="w-full flex justify-between items-center cursor-pointer pt-4">
                    <div className="flex space-x-2 rtl:space-x-reverse justify-center items-center">
                      <ProfileAvatar image={tranieeImage} name={traineeName} />
                      <h1
                        className={`text-xs text-white truncate whitespace-nowrap ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                      >
                        {workoutName}
                      </h1>
                    </div>
                    <div>
                      <img
                        src={Images.MenuDots}
                        alt=""
                        height={24}
                        width={24}
                        className="text-white cursor-pointer"
                        onClick={toggleWorkoutAction}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div
                className=" absolute top-0 flex  w-full "
                style={{ zIndex: 100 }}
              >
                <RightClickMenu
                  menu={({ onClose }) => (
                    <div className="absolute top-3 left-10 rounded-lg shadow-2xl bg-borderColor z-50 overflow-hidden">
                      {isSwaping ? (
                        <>
                          <div
                            className={`flex items-center cursor-pointer space-x-1 border-b-2 border-checkBoxGray rtl:space-x-reverse py-1 px-4 hover:bg-checkBoxGray`}
                            onClick={() => {}}
                          >
                            <h6
                              className={`text-xs text-white ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                            >
                              {t("SWAP_WORKOUT")}
                            </h6>
                          </div>
                          {loading ? (
                            <div className="flex justify-center items-center h-20">
                              <Loader
                                className=""
                                size="w-6 h-6"
                                fill="#003353"
                              />
                            </div>
                          ) : (
                            <>
                              {workoutPattern?.length < 1 ? (
                                <>
                                  <div
                                    className={`flex justify-center items-center text-white  text-xs  h-20 ${
                                      direction === "ltr"
                                        ? "font-primary-rg"
                                        : "font-primary-he-rg"
                                    }`}
                                  >
                                    {t("NO_WORKOUTS")}
                                  </div>
                                </>
                              ) : (
                                <>
                                  {workoutPattern?.map((item) => {
                                    return (
                                      <div
                                        className={`flex items-center cursor-pointer space-x-1 rtl:space-x-reverse py-2 px-4 hover:bg-checkBoxGray`}
                                        onClick={() => {
                                          handleSwapWorkout(
                                            workoutId,
                                            item?.PatternIndex
                                          );
                                          onClose();
                                        }}
                                      >
                                        <h6
                                          className={`text-xs text-white ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"} `}
                                        >
                                          {item?.workoutName}
                                        </h6>
                                      </div>
                                    );
                                  })}
                                </>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <div
                            className={`flex items-center cursor-pointer space-x-1 rtl:space-x-reverse py-1 px-4 hover:bg-checkBoxGray ${
                              isAnyCompletedOrPartiallyStarted && "opacity-50"
                            }`}
                            onClick={() => {
                              !isAnyCompletedOrPartiallyStarted &&
                                getWorkoutPattern(
                                  workoutId,
                                  traineeId,
                                  patternIndex
                                );
                            }}
                          >
                            <img
                              src={Images.Shuffle}
                              alt=""
                              height={20}
                              width={20}
                              className={`items-center cursor-pointer`}
                            />
                            <h6
                              className={`text-xs text-white ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                            >
                              {t("SWAP_WORKOUT")}
                            </h6>
                          </div>
                          <div
                            className={`flex items-center cursor-pointer space-x-1 rtl:space-x-reverse py-1 px-4 opacity-100 hover:bg-checkBoxGray ${
                              isAnyCompletedOrPartiallyStarted && "opacity-50"
                            }`}
                            onClick={() => {
                              if (!isAnyCompletedOrPartiallyStarted) {
                                onRemoveWorkout(workoutId);
                                onClose();
                              }
                            }}
                          >
                            <img
                              src={Images.Delete}
                              alt=""
                              height={20}
                              width={20}
                              className={`items-center cursor-pointer`}
                            />
                            <h6
                              className={`text-xs text-white ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                            >
                              {t("REMOVE_WORKOUT")}
                            </h6>
                          </div>
                          <div
                            className={`flex items-center cursor-pointer space-x-1 rtl:space-x-reverse py-1 px-4 text-xs text-white  leading-4 w-48 ${
                              direction === "ltr"
                                ? "font-primary-rg"
                                : "font-primary-he-rg"
                            }`}
                          >
                            {t("NOTE_EXERCISE_SWAP")}
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  setIsSwaping={setIsSwaping}
                >
                  <div
                    className="w-full flex justify-between items-center cursor-pointer pb-2 ltr:pr-2 rtl:pl-2 bg-primary "
                    style={{ minWidth: "368px", maxWidth: "368px" }}
                  >
                    <div className="flex space-x-2 rtl:space-x-reverse justify-center items-center">
                      <ProfileAvatar
                        image={tranieeImage}
                        name={traineeName}
                        size={"xlarge"}
                      />
                      <div>
                        <h1
                          className={` text-lg text-white truncate whitespace-nowrap ${
                            direction === "ltr"
                              ? "font-primary-md"
                              : "font-primary-he-md"
                          }`}
                        >
                          {traineeName}
                        </h1>
                        <h1
                          className={`text-xs text-white truncate whitespace-nowrap ${
                            direction === "ltr"
                              ? "font-primary-rg"
                              : "font-primary-he-rg"
                          }`}
                        >
                          {workoutName}
                        </h1>
                      </div>
                    </div>
                    <h6
                      className={`text-sm text-white truncate whitespace-nowrap cursor-pointer ${
                        direction === "ltr"
                          ? "font-primary-rg"
                          : "font-primary-he-rg"
                      }`}
                      onClick={() => {
                        let isAllPartial = getAllPartail(workoutId);

                        onAddExerciseClick(workoutId, traineeId, isAllPartial);
                      }}
                    >{`${t("EXERCISE")} +`}</h6>
                  </div>
                </RightClickMenu>
              </div>
            )}

            {/* Muscle Summary */}
            <div className={`${!isTrainee && "h-8"} `}></div>

            {bestRecordId?.some((x) => x.workoutId === workoutId) ? (
              <div className="sticky top-20 opacity-100 z-50 ">
                {bestRecordId
                  .filter((x) => x.workoutId === workoutId)
                  .map((bestRecord) => {
                    return (
                      <div
                        key={bestRecord.workoutId}
                        className="flex  w-full h-56 sticky top-0 justify-center items-center "
                      >
                        <Confetti width={368} height={600} opacity={0.4} />

                        <div className="flex w-full h-full flex-col space-y-2 bg-secondaryBackground rounded-lg justify-center items-center ">
                          <div
                            className={`flex w-[86px] h-[86px] rounded-full justify-center items-center ${
                              bestRecord?.isResistanceRecord
                                ? "bg-lightGreen"
                                : "bg-[#70ACBF]"
                            }`}
                          >
                            <p className="text-lg text-textFieldColor font-primary-rg ltr:font-primary-he-rg text-center">
                              {bestRecord.resistanceValue}*
                              {bestRecord.trackingValue}{" "}
                              {bestRecord.isResistanceRecord
                                ? getResistanceLabel(bestRecord?.resistanceName)
                                : getResistanceLabel(bestRecord?.trackingName)}
                            </p>
                          </div>
                          <p className="text-white font-primary-rg ltr:font-primary-he-rg text-2xl w-full text-center truncate">
                            {bestRecord?.exerciseName ?? ""}
                          </p>
                          <p className="text-2xl text-[#C2C7CF] font-primary-rg ltr:font-primary-he-rg">
                            {bestRecord.isResistanceRecord
                              ? `${t("New")} ${t("breakingRecords." + getResistanceLabelConfetti(bestRecord?.resistanceName))} ${
                                  t("Record") + "👏 🏆"
                                } `
                              : `${t("New")} ${t("breakingRecords." + getResistanceLabelConfetti(bestRecord?.trackingName))} ${
                                  t("Record") + "👏 🏆"
                                }`}
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <div className={`w-full bg-playCard rounded-lg p-4 space-y-2`}>
                <div
                  className="w-full flex items-center space-x-2 rtl:space-x-reverse cursor-pointer relative"
                  onClick={toggleMuscle}
                >
                  <img
                    src={
                      isRTL
                        ? Assets.WorkoutSidebarLeftArrow
                        : Assets.WorkoutSidebarRightArrow
                    }
                    alt="profile"
                    className={`w-2 h-3 rounded-full bg-cover text-center shadow-xl ${isMuscleOpen ? (isRTL ? "-rotate-90" : "rotate-90") : ""}`}
                  />
                  <h6
                    className={`text-sm text-white  leading-4 font-medium ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                  >{`${t("MUSCLES_SUMMARY")}`}</h6>
                </div>
                <MuscleSummary muscleSummary={muscleSummary} t={t} />

                {isMuscleOpen && (
                  <div className="flex flex-row pt-4 items-center justify-center">
                    <BodyMap
                      selectedValue={muscleIds}
                      hoverEnabled={false}
                      fillColor="#65B1F4"
                      w={310}
                      h={500}
                    />
                  </div>
                )}
              </div>
            )}

            <PrimaryButton
              text={t("START_EXERCISEING")}
              disabled={isAnyCompletedOrPartiallyStarted}
              isIcon={false}
              onClick={onWorkoutStartClick}
              className="w-full h-[56px] rounded-[12px] shadow-2xl my-1"
              bgColor="bg-prussianBlue hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed"
              textClass={`text-white ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
              Loader={activeWorkoutLoader}
            />

            {exercises?.map((item, index) => {
              let subtitle = `${item?.sets?.length || 0} ${t("SETS")} `;
              const isRubberBand =
                item?.sets && item?.sets[0]?.resistancename === "rubberband";
              if (item?.bestSet) {
                // Localize numbers and units for Hebrew
                const localizedTrackingValue =
                  item?.bestSet?.tracking_value || 0;
                const localizedResistanceValue = isRubberBand
                  ? item?.bandLevel
                  : item.bestSet.resistance_value || 0;
                const resistanceLabel = getResistanceLabel(
                  item.sets && item.sets[0]?.resistancename
                );
                // Check the language and set the subtitle accordingly
                if (direction === "rtl") {
                  subtitle = `${item?.sets?.length || 0} סטים - ${resistanceLabel} ${localizedResistanceValue} x ${localizedTrackingValue}`;
                } else {
                  subtitle = `${item?.sets?.length || 0} ${t("SETS")} ${localizedTrackingValue} x ${localizedResistanceValue} ${resistanceLabel}`;
                }
              }

              const machineName = item?.isSingleBodyPart
                ? "single"
                : item?.machineName;
              return (
                <Exercise
                  key={index}
                  indexKey={item?.index}
                  isIntensityTracking={item?.intensityTracking}
                  exerciseName={item?.exerciseName}
                  isBoostWeight={item?.boostWeight}
                  traineeId={traineeId}
                  workoutId={workoutId}
                  workoutName={workoutName}
                  exerciseId={item?.exercisesId}
                  workoutExerciseId={item?.workoutExerciseId}
                  status={item?.workoutExerciseStatus}
                  name={item?.exerciseName}
                  subtitle={subtitle}
                  machineName={machineName}
                  sets={item?.sets || []}
                  onRemoveExercise={onRemoveExercise}
                  onHistoryClick={onHistoryClick}
                  onCommentsClick={onCommentsClick}
                  onSwapClick={onSwapClick}
                  isWorkoutHasOneExercise={exercises.length === 1}
                  isFirstExercisePlayed={!isAnyCompletedOrPartiallyStarted}
                  isWorkoutSubmitting={loader}
                  advanceTechniqueValue={
                    item?.sets[0] && item?.sets[0]?.advancedTechniques
                  }
                  mobility_technique={item?.mobility_technique}
                  muscle_technique={item?.muscle_technique}
                  control_technique={item?.control_technique}
                  handleDragStart={handleDragStart}
                  handleDragOver={handleDragOver}
                  handleDrop={handleDrop}
                  openNextExercise={openNextExercise}
                  openNextIndex={openNextIndex}
                  resetNextHandler={resetNextHandler}
                  isTrainee={isTrainee}
                  refresh={refresh}
                  data={data}
                  setData={setData}
                  onClickExerciseInfo={onClickExerciseInfo}
                  setBestRecordId={setBestRecordId}
                  bestRecordId={bestRecordId}
                  hasLastComment={item?.hasLastComment}
                  url={item?.url}
                />
              );
            })}
            <PrimaryButton
              text={t("COMPLETE_WORKOUT")}
              disabled={isDisabled}
              isIcon={false}
              onClick={onWorkoutCompleteClick}
              className="w-full h-[56px] rounded-[12px] shadow-2xl my-1"
              bgColor="bg-prussianBlue hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed"
              textClass={`text-white ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
              Loader={loader}
            />
          </>
        )}
      </div>
      <ActionSheet
        workoutId={workoutId}
        workoutName={workoutName}
        options={[
          {
            id: 1,
            label: t("ADD_EXERCISE"),
            image: Images.AddCircle,
            disabled: isCompleted,
            action: () => {
              if (!isCompleted) {
                let isAllPartial = getAllPartail(workoutId);
                onAddExerciseClick(workoutId, traineeId, isAllPartial);
              }
            },
          },
          {
            id: 2,
            label: t("SWAP_WORKOUT"),
            image: Images.Shuffle,
            disabled: isAnyCompletedOrPartiallyStarted,
            action: () =>
              !isAnyCompletedOrPartiallyStarted && getWorkoutsForSwapping(),
            submenu: {
              show: true,
              loading: isLoadingWorkoutPatterns,
              suboptions: workoutPatternsForExchange,
              action: onSwapWorkoutInActionSheet,
            },
          },
          {
            id: 3,
            label: t("Cancel Workout"),
            disabled: isAnyCompletedOrPartiallyStarted,
            image: Images.CancelWorkout,
            action: () =>
              !isAnyCompletedOrPartiallyStarted && onRemoveWorkout(),
          },
        ]}
        open={isWorkoutActionSheet}
        onClose={toggleWorkoutAction}
      />
    </>
  );
};

export { ActiveWorkout };
