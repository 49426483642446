import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import makeAnimated from 'react-select/animated'
import ReactDOM from 'react-dom'

// Import icons
import Dropdown from 'components/compounds/DropDown'
import SettingsIcon from 'assets/images/SettingsIcon'
import ClockIcon from 'assets/images/ClockIcon'
// import RepeatIcon from 'assets/images/RepeatIcon'
import UserIcon from 'assets/images/UserIcon'
import DescriptionIcon from 'assets/images/DescriptionIcon'
import InfoIcon from 'assets/images/InfoIcon'

// import stopPropagation from "../../utils/helpers";
import { stopPropagation } from 'utils'

import {
  BlockEventFields,
  ButtonsContainer,
  CloseEventFields,
  DateBetweenContainer,
  DatePicker,
  DatePickerBetween,
  EndDateContainer,
  EventInfoInputContainer,
  EventTypeList,
  EventTypeListContainer,
  // OccuranceContainer,
  OpenEventFields,
  OpenEventTypeContainer,
  // RepeatOptions,
  TraineeLimitContainer,
  UpdateEventInfoBox,
} from './styles'
import Select from '../Select'
// import eventValidation from "../../utils/validations";
import { eventValidation } from 'validations/formsValidations'
import Input from '../Input'
import { constants } from 'constant'
import { useSelector } from 'react-redux'
// import { Images } from 'assets'
import moment from 'moment'
import { RiErrorWarningLine } from 'react-icons/ri'
import { MdOutlineBlock } from 'react-icons/md'
// import { useEventSelector } from 'store/eventSlice/eventReducers'
import DeleteModal from 'components/compounds/DeleteModal'
import { useDispatch } from 'react-redux'
import { cancelEvent, deleteEvent } from 'store/eventSlice/eventActions'
import { ProfileAvatar } from 'components/PlayBoard/ProfileAvatar'
import { Modal } from 'components/compounds/Modal'
import PrimaryButton from 'components/elements/PrimaryButton'
import OutlineButton from 'components/elements/OutlineButton'

const animatedComponents = makeAnimated()

const getEventType = (value) => {
  if (value === 'close') {
    return 'close-workout'
  } else if (value === 'open') {
    return 'open-workout'
  } else if (value === 'block') {
    return 'block'
  }
  return 'open'
}

function EventEditor({
  event,
  handleCancel,
  handleUpdateEvent,
  setShowUpdateInfoBox,
  showUpdateInfoBox,
  setIsEventOpen,
  eventHasValidInfo,
  handleDelete,
  currentView,
  refreshWorkouts,
  isEventOpen,
  handleCloseEventModal,

  // divheight,
}) {
  const [isValid, setValid] = useState('available')
  const { t } = useTranslation()
  const { coaches, trainees } = useSelector((state) => state.gymAdmin)
  const { user } = useSelector((state) => state.auth)
  const { TIME_OPTIONS, HOURLY_TIME_OPTIONS, WORKOUT_TYPE_OPTIONS, STANDARD_TIME } = constants
  const TRAINEE_OPTIONS = trainees.map((item) => ({ label: item?.User?.firstName, value: item?.User?.id }))
  const COACH_OPTIONS = coaches.map((item) => ({ label: item?.firstName, value: item?.Coach?.id, color: item?.Coach?.color, avatar: item?.avatar }))
  const DURATIONOPTIONS = STANDARD_TIME.map((item) => ({ label: `${item} ${t('MINUTES')}`, value: item }))
  const [deleteModal, setDeleteModal] = useState(null)
  const showBoxRef = useRef()
  const { direction } = useSelector((state) => state.language)
  //coach available hour
  const [coachAvailableHourDropDown, setCoachAvailableHourDropDown] = useState([])
  const dispatch = useDispatch()
  const validations = useMemo(() => eventValidation(t), [t])
  const [cancelModal, setCancelModal] = useState(false)
  const [selectedCoach, setSelectedCoach] = useState(null)
  // Initialize formik with info from event

  // All non-standard properties of event are stored in extendedProps
  const formik = useFormik({
    initialValues: {
      title: event?.title && event?.title !== 'null' ? event?.title : '',
      extendedProps: {
        startTime: event?.extendedProps?.startTime || '',
        endTime: event?.extendedProps?.endTime || '',
        eventType: getEventType(event?.extendedProps?.type) || (event?.allDay ? 'block' : ''),
        repeat: event?.extendedProps?.repeat || (event?.allDay ? 'allDay' : 'noRepeat'),
        weekDays: event?.extendedProps?.weekDays || [],
        startDate: event?.extendedProps?.startDate || '',
        endDate: event?.extendedProps?.endDate || '',
        coach: event?.extendedProps?.Coach?.id || '',
        description: event?.extendedProps?.description || '',
        eventDuration: event?.extendedProps?.eventDuration || 60,

        // Open event fields
        minTrainees: event?.extendedProps?.minTrainees || 2,
        maxTrainees: event?.extendedProps?.maxTrainees || 8,
        cancelationBuffer: event?.extendedProps?.cancelationBuffer || 2,
        openEventType: event?.extendedProps?.openEventType || 'turns',
        noOfTraineesInTurn: event?.extendedProps?.noOfTraineesInTurn || 2,
        workoutType: event?.extendedProps?.workoutType || 'FullBody',
        occurrences: event?.extendedProps?.occurrences || 1,

        // Close event field
        traineesForCloseWorkout: event?.extendedProps?.Trainee?.User?.id || null,

        // Block event field
        traineesForMeetingBlock: event?.extendedProps?.traineesForMeetingBlock || [],
      },
    },
    onSubmit: (values) => {
      handleUpdateEvent(event?.id, values)
      setShowUpdateInfoBox(false)
      setIsEventOpen(false)
    },
    validationSchema: validations,
    enableReinitialize: true,
  })
  const { handleChange, values, setFieldValue, errors, touched, handleBlur, resetForm } = formik
  //

  useEffect(() => {
    const coach = coaches.find((x) => x.Coach.id === event?.extendedProps?.Coach?.id) || {}
    const day = new Date(event?.extendedProps?.endDate).getDay()
    if (coach && day) {
      const dayAvailableHour = coach?.availableHour ? coach?.availableHour[day] : null
      if (dayAvailableHour) {
        const temp = dayAvailableHour.map((item) => ({ label: item?.start, value: item?.start }))
        setCoachAvailableHourDropDown(temp)
      }
    }
  }, [])
  //

  /**
   * Change handler for event type
   * Takes data from data-event_type attribute of button and set to formik
   */
  const handleChangeEventType = useCallback(
    (e) => {
      const eventType = e.target.getAttribute('data-event_type')

      setFieldValue('extendedProps', {
        ...values.extendedProps,
        eventType,
      })
    },
    // eslint-disable-next-line
    [values]
  )

  /**
   * Change handler for open event type
   * Takes data from data-value attribute of button and set to formik
   */
  const handleChangeOpenEventType = useCallback(
    (e) => {
      const eventType = e.target.getAttribute('data-value')

      setFieldValue('extendedProps', {
        ...values.extendedProps,
        openEventType: eventType,
      })
    },
    // eslint-disable-next-line
    [values]
  )

  // const repeatOptions = useMemo(() => {
  //   const clonedOptions = [...REPEAT_OPTIONS]
  //   if (values.extendedProps.eventType !== 'block') {
  //     clonedOptions.pop()
  //   }

  //   return clonedOptions
  // }, [values.extendedProps.eventType])

  // This useEffect manages clicks outside info box to close it
  // useEffect(() => {
  //   let timeoutRef = null

  //   const onOutsideClick = (e) => {
  //     // checks if clicked target is info box or not. If yes then bail otherwise close it
  //     if (!showBoxRef.current.contains(e.target)) {
  //       setShowUpdateInfoBox(false)

  //       // Delete the event on click outside if it doesn't have valid info
  //       if (!eventHasValidInfo) {
  //         handleDelete(true)
  //       }

  //       setTimeout(() => {
  //         setIsEventOpen(false)
  //       }, 100)
  //     }
  //   }

  //   // timeout added to not add click event immediately
  //   timeoutRef = setTimeout(() => {
  //     window.addEventListener('click', onOutsideClick)
  //   }, 16)

  //   // Cleanup
  //   return () => {
  //     window.removeEventListener('click', onOutsideClick)
  //     clearTimeout(timeoutRef)
  //   }
  //   // eslint-disable-next-line
  // }, [showBoxRef, eventHasValidInfo])

  useEffect(() => {
    const eventContainer = document.getElementById(`event-info-container-${event?.id}`)
    const eventRect = eventContainer?.getBoundingClientRect()

    const calendarElement = document.querySelector("tbody[role='rowgroup']")
    const calendarRect = calendarElement.getBoundingClientRect()
    const modalElement = document.querySelector('#modal-root')

    const top = eventRect?.top - (calendarRect.top - 230)

    modalElement.style.left = `${eventRect?.right + 20}px`

    // When event is going out of screen on right side, move info box to left
    if (eventRect?.right + 300 > calendarElement?.clientWidth) {
      modalElement.style.left = `${eventRect?.left - 320}px`
    }

    const diff = eventRect?.top - calendarElement?.clientHeight / 2
    if (eventRect?.top > calendarElement?.clientHeight / 2 || diff > -80) {
      modalElement.style.transform = `translateY(-${calendarRect?.bottom - 150}px)`
    } else {
      modalElement.style.top = `${top}px`
    }

    // When in day view, info box should be in center
    if (currentView === 'timeGridDay') {
      modalElement.style.left = `${eventRect?.left + eventRect?.width / 2}px`
    }

    // Cleanup
    return () => {
      modalElement.style.top = ''
      modalElement.style.left = ''
      modalElement.style.transform = ''
    }
    // eslint-disable-next-line
  }, [])

  // useEffect(() => {
  //   const childDiv = document.getElementById(`event-info-container-${event.id}`)
  //   const modal = document.querySelector(`#info-box-container-${event.id}`)

  //   childDiv.style.bottom = '500px'

  //   const childRect = childDiv.getBoundingClientRect()

  //   const childTop = childRect.top
  //   const childRight = childRect.right

  //   // Set the position of the modal based on the child div's position
  //   if (childTop >= window.innerHeight - childTop) {
  //     // Display modal at the bottom of the child div
  //     modal.style.top = childRect.bottom + 'px'
  //   } else {
  //     // Display modal at the top of the child div
  //     modal.style.top = childRect.top - modal.offsetHeight + 'px'
  //   }

  //   if (childRight >= window.innerWidth - childRight) {
  //     // Display modal to the left of the child div
  //     modal.style.left = childRect.left - modal.offsetWidth + 'px'
  //   } else {
  //     // Display modal to the right of the child div
  //     modal.style.left = childRect.right + 'px'
  //     // }
  //   }
  // }, [])

  const dateFormat = direction === 'rtl' ? 'yyyy/MM/dd' : 'dd/MM/yyyy'

  // const incrementOccurrences = () => {
  //   if (values.extendedProps.occurrences < 10) {
  //     setFieldValue('extendedProps', {
  //       ...values.extendedProps,
  //       occurrences: values.extendedProps.occurrences + 1,
  //     })
  //   }
  // }

  // const decrementOccurrences = () => {
  //   if (values.extendedProps.occurrences > 1) {
  //     setFieldValue('extendedProps', {
  //       ...values.extendedProps,
  //       occurrences: values.extendedProps.occurrences - 1,
  //     })
  //   }
  // }
  function addDurationToTime(selectedTime, duration) {
    const parts = selectedTime.split(':')
    const hours = parseInt(parts[0], 10)
    const minutes = parseInt(parts[1], 10)
    // const seconds = parseInt("00", 10);

    // Calculate the new time
    const totalMinutes = hours * 60 + minutes + duration
    const newHours = Math.floor(totalMinutes / 60)
    const newMinutes = totalMinutes % 60

    // Handle overflow of hours and minutes
    if (newHours >= 24) {
      newHours %= 24
    }
    // Format the updated time
    const newTime = `${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`
    return newTime
  }
  useEffect(() => {
    revalidateTimeAvailability()
    // eslint-disable-next-line
  }, [
    values.extendedProps.endDate,
    values.extendedProps.startTime,
    values.extendedProps.endTime,
    values.extendedProps.coach,
    values.extendedProps.eventDuration,
  ])

  const revalidateTimeAvailability = () => {
    const workoutId = event?.id
    const { startTime, endTime, endDate, coach, eventDuration } = values.extendedProps
    const newEndTime = addDurationToTime(startTime, eventDuration)
    const payload = {
      startTime,
      endTime: newEndTime,
      endDate,
      dayIndex: moment(endDate).day(),
      dayName: moment(endDate).format('dddd').toLowerCase(),
      availableHour: coaches.find((item) => item?.Coach?.id === coach)?.Coach?.availableHour,
      bookedTime: coaches.find((item) => item?.Coach?.id === coach)?.Coach?.bookedTime || [],
    }

    const selectedDayAvailability = payload?.availableHour ? payload?.availableHour[payload?.dayIndex] : null
    if (selectedDayAvailability) {
      const temp = selectedDayAvailability?.map((item) => ({ label: item?.start, value: item?.start }))
      setCoachAvailableHourDropDown(temp)
      const isStartTime = selectedDayAvailability?.find((item) => item?.start === moment(payload?.startTime, 'HH:mm').format('HH:mm'))
      const isEndTime = selectedDayAvailability?.find((item) => item?.end === moment(payload?.endTime, 'HH:mm').format('HH:mm'))

      if (isStartTime && isEndTime) {
        let conflict = false
        const start = moment(payload.startTime, 'HH:mm').format('HH:mm')
        const end = moment(payload.endTime, 'HH:mm').format('HH:mm')
        const startTimeHourValue = moment(start, 'HH:mm').hours()
        const endTimeHourValue = moment(end, 'HH:mm').hours()
        const timeDurationsForSelectedDate = payload?.bookedTime?.filter((item) => {
          const isSameDate = moment(payload.endDate).format('DD-MM-YYYY') === moment(item.date).format('DD-MM-YYYY')
          if (isSameDate) {
          }
          const isSameWorkout = item?.workoutId?.toString() === workoutId?.toString()

          return isSameWorkout ? false : isSameDate
        })
        if (!timeDurationsForSelectedDate) {
          conflict = false
        } else {
          // eslint-disable-next-line array-callback-return
          const isConflictFound = timeDurationsForSelectedDate.filter((item) => {
            const durationStartTimeHourValue = moment(item.startHour, 'HH:mm').hours()
            const durationEndTimeHourValue = moment(item.endHour, 'HH:mm').hours()
            if (
              (startTimeHourValue > durationStartTimeHourValue && startTimeHourValue < durationEndTimeHourValue) ||
              (endTimeHourValue > durationStartTimeHourValue && endTimeHourValue < durationEndTimeHourValue) ||
              (startTimeHourValue <= durationStartTimeHourValue && endTimeHourValue >= durationEndTimeHourValue)
            ) {
              return true
            }
          })

          if (isConflictFound.length > 0) {
            conflict = true
          }
        }
        if (conflict) {
          return setValid('conflict')
        }
        return setValid('available')
      }
      return setValid('unavailable')
    } else {
      return setValid('unavailable')
    }
  }
  const OptionWithImage = ({ innerProps, label, data }) => (
    <div
      {...innerProps}
      className="flex gap-x-2 py-2 px-2 text-textLightGray ltr:font-primary-rg rtl:font-primary-he-rg text-base cursor-pointer hover:bg-extralightGray "
    >
      <ProfileAvatar image={data?.avatar} name={label} color={data?.color === 'BLUE' ? '#E5A772' : data?.color} size={'small'} />

      {label}
    </div>
  )
  const FooterComponent = ({ isValid, event, setCancelModal, setDeleteModal, handleSubmit }) => {
    return (
      <div className="flex flex-row h-16 items-center px-4 justify-between xl:px-6 2xl:px-12   absolute bottom-5 w-full bg-sidebar">
        <div className="w-2/3">
          <ButtonsContainer>
            <button
              className={`${isValid === 'unavailable' ? 'bg-gray-600 opacity-50' : 'save'}`}
              type="submit"
              disabled={isValid === 'unavailable'}
              onClick={handleSubmit}
            >
              {t('SAVE')}
            </button>
            {event?.extendedProps?.status === 'approved' && (
              <button className="cancel" type="button" onClick={() => setCancelModal(true)}>
                {t('Cancel')}
              </button>
            )}
          </ButtonsContainer>
        </div>
        <div className="w-1/3">
          <ButtonsContainer>
            {
              <button className="hover:bg-red-400 ml-10" type="button" onClick={() => setDeleteModal(event.id)}>
                {t('DELETE')}
              </button>
            }
          </ButtonsContainer>
        </div>
      </div>
    )
  }

  return (
    <Modal
      className={`h-full   ${direction === 'ltr' ? 'rounded-l-3xl' : 'rounded-r-3xl'} w-[360px] lg:w-[480px] `}
      childrenClass=" "
      open={showUpdateInfoBox}
      title={`Edit Event`}
      handleClose={() => {
        setShowUpdateInfoBox(false)
        setIsEventOpen(false)
        resetForm()
      }}
      handleSubmit={() => { }}
      loader={false}
      isEdited={false}
      disabled={false}
      direction={direction}
      footer={
        <FooterComponent
          isValid={isValid}
          event={event}
          setCancelModal={setCancelModal}
          setDeleteModal={setDeleteModal}
          handleSubmit={formik.handleSubmit}
        />
      }
      padding={false}
    >
      {/* {ReactDOM.createPortal(
          <div className="fixed left-0 top-0 h-screen w-screen z-10 bg-[rgba(0,0,0,0.7)] flex justify-center items-center"> */}
      {/* <div
        className="w-full h-full py-3 relative"
        // onClick={stopPropagation}
        // onMouseDown={stopPropagation}
        ref={showBoxRef}
        // $currentView={currentView}
        // id={`info-box-container-${event.id}`}
        // className="z-90"
      > */}
      <form
        onSubmit={formik.handleSubmit}
        noValidate
        className=" w-full h-full z-50 "
        onClick={stopPropagation}
        onMouseDown={stopPropagation}
        style={{ zIndex: 99999 }}
      // ref={showBoxRef}
      // $currentView={currentView}
      // id={`info-box-container-${event.id}`}
      >
        <input
          name="title"
          type="text"
          placeholder={t('enterTitle')}
          onChange={handleChange}
          value={values.title}
          className={`title-input mt-5 ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
          onBlur={handleBlur}
        />

        {errors.title && touched?.title && (
          <span className={`error ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>{errors.title}</span>
        )}
        <EventTypeListContainer>
          <SettingsIcon />
          <EventTypeList>
            <button
              type="button"
              className={values.extendedProps.eventType === 'open-workout' ? 'active' : ''}
              data-event_type="open-workout"
              onClick={handleChangeEventType}
              // disabled={values.extendedProps.repeat === 'allDay'}
              disabled
            >
              {t('openWorkout')}
            </button>
            <button
              type="button"
              className={values.extendedProps.eventType === 'close-workout' ? 'active' : ''}
              data-event_type="close-workout"
              onClick={handleChangeEventType}
            // disabled={values.extendedProps.repeat === 'allDay'}
            // disabled
            >
              {t('closeWorkout')}
            </button>
            <button
              type="button"
              className={values.extendedProps.eventType === 'block' ? 'active' : ''}
              data-event_type="block"
              onClick={handleChangeEventType}
              disabled
            >
              {t('meetingBlock')}
            </button>
          </EventTypeList>
        </EventTypeListContainer>

        {values.extendedProps.eventType === 'open-workout' && (
          <OpenEventFields>
            <TraineeLimitContainer>
              <Input
                label={t('minTrainees')}
                type="number"
                name="extendedProps.minTrainees"
                onChange={handleChange}
                value={values.extendedProps.minTrainees}
              />
              <Input
                label={t('maxTrainees')}
                type="number"
                name="extendedProps.maxTrainees"
                onChange={handleChange}
                value={values.extendedProps.maxTrainees}
              />
            </TraineeLimitContainer>
            {errors?.extendedProps?.minTrainees && touched?.extendedProps?.minTrainees && (
              <span className="error">{errors.extendedProps.minTrainees}</span>
            )}
            {errors?.extendedProps?.maxTrainees && touched?.extendedProps?.maxTrainees && (
              <span className="error">{errors?.extendedProps?.maxTrainees}</span>
            )}
            <Input
              label={t('cancelationBuffer')}
              type="number"
              name="extendedProps.cancelationBuffer"
              onChange={handleChange}
              value={values.extendedProps.cancelationBuffer}
            />
            {errors?.extendedProps?.cancelationBuffer && touched?.extendedProps?.cancelationBuffer && (
              <span className="error">{errors?.extendedProps?.cancelationBuffer}</span>
            )}
            <OpenEventTypeContainer>
              <button
                type="button"
                data-value="turns"
                onClick={handleChangeOpenEventType}
                className={values.extendedProps.openEventType === 'turns' ? 'active' : ''}
              >
                {t('turns')}
              </button>
              <button
                type="button"
                data-value="open-gym"
                onClick={handleChangeOpenEventType}
                className={values.extendedProps.openEventType === 'open-gym' ? 'active' : ''}
              >
                {t('openGym')}
              </button>
            </OpenEventTypeContainer>
            {values.extendedProps.openEventType === 'turns' ? (
              <>
                <Input
                  label={t('noOfTraineesInTurn')}
                  type="number"
                  name="extendedProps.noOfTraineesInTurn"
                  onChange={handleChange}
                  value={values.extendedProps.noOfTraineesInTurn}
                />
                {errors?.extendedProps?.noOfTraineesInTurn && touched?.extendedProps?.noOfTraineesInTurn && (
                  <span className="error">{errors?.extendedProps?.noOfTraineesInTurn}</span>
                )}
                <Select
                  options={WORKOUT_TYPE_OPTIONS}
                  placeholder={t('workoutType')}
                  isSearchable={false}
                  value={WORKOUT_TYPE_OPTIONS.find((o) => o.value === values.extendedProps?.workoutType)}
                  onChange={(e) => {
                    setFieldValue('extendedProps', {
                      ...values.extendedProps,
                      workoutType: e.value,
                    })
                  }}
                />
                {errors?.extendedProps?.workoutType && touched?.extendedProps?.workoutType && (
                  <span className="error">{errors?.extendedProps?.workoutType}</span>
                )}
              </>
            ) : null}
            {values.extendedProps.openEventType === 'open-gym' ? (
              <p className="note">
                <InfoIcon />
                {t('openGymNote')}
              </p>
            ) : null}
          </OpenEventFields>
        )}
        {/* Open workout fields end */}

        {/* Close workout fields start */}
        {/* {values.extendedProps.eventType === 'close-workout' && (
          <CloseEventFields>
            <Select
              id="CloseWorkout"
              options={TRAINEE_OPTIONS}
              placeholder={t('selectTrainees')}
              // isMulti
              closeMenuOnSelect={false}
              components={animatedComponents}
              // value={values.extendedProps?.traineesForCloseWorkout}
              value={TRAINEE_OPTIONS.find((o) => o.value === values.extendedProps?.traineesForCloseWorkout)}
              // onChange={(e) => {
              //   setFieldValue('extendedProps', {
              //     ...values.extendedProps,
              //     traineesForCloseWorkout: e,
              //   })
              // }}
              disabled
              onChange={(e) => {
                setFieldValue('extendedProps', {
                  ...values.extendedProps,
                  traineesForCloseWorkout: e.value,
                })
              }}
            />
          </CloseEventFields>
        )} */}
        {values.extendedProps.eventType === 'close-workout' && (
          <div className="flex py-2 w-full my-5  justify-center items-start gap-x-5">
            <div className="flex flex-col items-center gap-2">
              <ProfileAvatar
                size={'xxlarge'}
                name={COACH_OPTIONS.find((o) => o.value === values.extendedProps?.coach)?.label}
                image={COACH_OPTIONS.find((o) => o.value === values.extendedProps?.coach)?.avatar}
                style={{
                  backgroundColor: COACH_OPTIONS.find((o) => o.value === values.extendedProps?.coach)?.avatar
                    ? null
                    : COACH_OPTIONS.find((o) => o.value === values.extendedProps?.coach)?.color,
                  color: '#000000',
                  fontSize: '24px',
                }}
              />
              <p className=" text-base ltr:font-primary-rg rtl:font-primary-he-rg text-[#C2C7CF]">
                {COACH_OPTIONS.find((o) => o.value === values.extendedProps?.coach)?.label}
              </p>
            </div>
            <div className="flex flex-col items-center gap-2">
              <ProfileAvatar
                size={'xxlarge'}
                name={event?.extendedProps?.Trainee?.User?.firstName}
                image={event?.extendedProps?.Trainee?.User?.avatar}
                style={{
                  backgroundColor: event?.extendedProps?.Trainee?.User?.avatar ? null : event?.extendedProps?.Coach?.color,
                  color: '#000000',
                  fontSize: '24px',
                }}
              />
              <p className=" text-base ltr:font-primary-rg rtl:font-primary-he-rg text-[#C2C7CF]">{event?.extendedProps?.Trainee?.User?.firstName}</p>
            </div>
          </div>
        )}

        {/* Close workout fields end */}

        {/* Meeting/Block fields start */}
        {values.extendedProps.eventType === 'block' && (
          <BlockEventFields>
            <Select
              options={TRAINEE_OPTIONS}
              placeholder={t('addTraineeToMeeting')}
              value={TRAINEE_OPTIONS.find((o) => o.value === values.extendedProps?.traineesForMeetingBlock)}
              onChange={(e) => {
                setFieldValue('extendedProps', {
                  ...values.extendedProps,
                  traineesForMeetingBlock: e.value,
                })
              }}
            />
          </BlockEventFields>
        )}
        {/* Meeting/Block fields end */}
        {/* //nov16 */}
        {!event?.allDay && (
          <EventInfoInputContainer>
            <ClockIcon />
            <Select
              id="time"
              options={coachAvailableHourDropDown}
              placeholder="Start"
              selectContainerClass={isValid === 'unavailable' ? 'empty' : 'start-end-time'}
              isSearchable={false}
              value={coachAvailableHourDropDown.find((o) => o?.value === values.extendedProps?.startTime)}
              onChange={(e) => {
                setFieldValue('extendedProps', {
                  ...values.extendedProps,
                  startTime: e.value,
                })
                // revalidateTimeAvailability()
              }}
            />

            <Select
              id="time"
              options={DURATIONOPTIONS}
              placeholder="Start"
              // selectContainerClass="start-end-time"
              isSearchable={false}
              value={DURATIONOPTIONS.find((x) => x.value === values?.extendedProps?.eventDuration)}
              onChange={(e) => {
                setFieldValue('extendedProps', {
                  ...values.extendedProps,
                  eventDuration: e.value,
                })
                // revalidateTimeAvailability()
              }}
            />
            {/* <Select
                    id="time"
                    options={coachAvailableHourDropDown}
                    placeholder="End"
                    selectContainerClass="start-end-time"
                    isSearchable={false}
                    value={coachAvailableHourDropDown.find((o) => o.value === values.extendedProps?.startTime)}
                    onChange={(e) => {
                      setFieldValue('extendedProps', {
                        ...values.extendedProps,
                        endTime: e.value,
                      })
                      // revalidateTimeAvailability()
                    }}
                  />
                  */}
          </EventInfoInputContainer>
        )}

        {errors.extendedProps?.startTime && touched?.extendedProps?.startTime && <span className="error">{errors.extendedProps?.startTime}</span>}
        {errors.extendedProps?.endTime && touched?.extendedProps?.endTime && <span className="error">{errors.extendedProps?.endTime}</span>}
        {/* <EventInfoInputContainer>
            <RepeatIcon />
            <Select
              options={repeatOptions}
              placeholder={t('repeat')}
              selectContainerClass="repeat-select"
              value={repeatOptions.find((o) => o.value === values.extendedProps?.repeat)}
              onChange={(e) => {
                setFieldValue('extendedProps', {
                  ...values.extendedProps,
                  repeat: e.value,
                })
              }}
            />
          </EventInfoInputContainer> */}
        {/* {values.extendedProps?.repeat === 'everyWeek' ||
            (values.extendedProps?.repeat === 'everyMonth' && (
              <>
                <RepeatOptions>
                  <p className="mx-4"></p>
                  {WEEK_DAYS.map((day) => (
                    <button
                      type="button"
                      key={day.value}
                      className={values.extendedProps?.weekDays?.includes(day.value) ? 'active' : ''}
                      onClick={() => {
                        if (values.extendedProps?.weekDays?.includes(day.value)) {
                          setFieldValue('extendedProps', {
                            ...values.extendedProps,
                            weekDays: values.extendedProps?.weekDays?.filter((d) => d !== day.value),
                          })
                        } else {
                          setFieldValue('extendedProps', {
                            ...values.extendedProps,
                            weekDays: [...values.extendedProps.weekDays, day.value],
                          })
                        }
                      }}
                    >
                      {language === 'en' ? day.label : day.label_he}
                    </button>
                  ))}
                </RepeatOptions>
                {errors.extendedProps?.weekDays && touched?.extendedProps?.weekDays && (
                  <span className="error">{errors.extendedProps?.weekDays}</span>
                )}
              </>
            ))}
          {values.extendedProps?.repeat === 'everyMonth' && (
            <div className="flex h-10 mb-4 ">
              <p className="mx-2"></p>
              <span className="flex  text-white font-primary-rg text-[14px] px-2 items-center text-center justify-center leading-[18px]">
                {t('After')}
              </span>
              <OccuranceContainer>
                <div className="flex w-52 rounded-[12px]">
                  <input
                    type="number"
                    name="number"
                    step={1}
                    id="occurrence"
                    className="flex bg-[#5a5f66] w-10 my-1 text-center"
                    value={values.extendedProps.occurrences}
                    onChange={(e) => {
                      setFieldValue('extendedProps', {
                        ...values.extendedProps,
                        occurrences: e.target.value,
                      })
                    }}
                    min={1}
                    max={10}
                  />
                  <span className="flex text-white font-primary-rg text-[16px] px-2 items-center text-center justify-center leading-5">
                    {t('occurrences')}
                  </span>
                </div>
                <div className="flex flex-col justify-between py-[2px]">
                  <div className="flex items-center justify-center cursor-pointer w-5 h-10 hover:bg-checkBoxGray rounded-[50px]">
                    <img
                      src={Images.Increment}
                      alt=""
                      height={10}
                      width={15}
                      className={`items-center cursor-pointer`}
                      onClick={incrementOccurrences}
                    />
                  </div>
                  <div className="flex items-center justify-center cursor-pointer w-5 h-10 hover:bg-checkBoxGray rounded-[50px]">
                    <img
                      src={Images.Decrement}
                      alt=""
                      height={10}
                      width={15}
                      className={`items-center cursor-pointer `}
                      onClick={decrementOccurrences}
                    />
                  </div>
                </div>
              </OccuranceContainer>
            </div>
          )} */}
        {values.extendedProps?.repeat !== 'noRepeat' ||
          (values.extendedProps?.repeat !== 'everyMonth' && (
            <>
              <EventInfoInputContainer>
                <ClockIcon />
                <EndDateContainer>
                  <DatePicker
                    clearIcon={false}
                    isOpen={false}
                    dayPlaceholder="DD"
                    monthPlaceholder="MM"
                    yearPlaceholder="YYYY"
                    value={values.extendedProps?.endDate}
                    onChange={(date) => {
                      setFieldValue('extendedProps', {
                        ...values.extendedProps,
                        endDate: date,
                      })
                      // revalidateTimeAvailability()
                    }}
                    minDate={new Date()}
                    format={dateFormat}
                  />
                </EndDateContainer>
              </EventInfoInputContainer>
              {errors.extendedProps?.endDate && touched?.extendedProps?.endDate && <span className="error">{errors.extendedProps?.endDate}</span>}
            </>
          ))}
        {values.extendedProps?.repeat === 'allDay' && (
          <>
            <EventInfoInputContainer>
              {/* <ClockIcon /> */}
              <p className="mx-[2px]"></p>
              <DateBetweenContainer>
                <span className="flex text-white font-primary-rg text-[16px] px-2 items-center text-center">{t('from')}</span>
                <DatePickerBetween
                  className="w-full"
                  clearIcon={false}
                  isOpen={false}
                  dayPlaceholder="DD"
                  monthPlaceholder="MM"
                  yearPlaceholder="YY"
                  value={values.extendedProps?.startDate}
                  onChange={(date) => {
                    setFieldValue('extendedProps', {
                      ...values.extendedProps,
                      startDate: date,
                    })
                  }}
                  minDate={new Date(event.start)}
                  format={dateFormat}
                />
                <span className="flex text-white font-primary-rg text-[16px] px-2 items-center text-center">{t('to')}-</span>
                <DatePickerBetween
                  clearIcon={false}
                  isOpen={false}
                  dayPlaceholder="DD"
                  monthPlaceholder="MM"
                  yearPlaceholder="YY"
                  value={values.extendedProps?.endDate}
                  onChange={(date) => {
                    setFieldValue('extendedProps', {
                      ...values.extendedProps,
                      endDate: date,
                    })
                  }}
                  minDate={new Date(event.start)}
                  format={dateFormat}
                />
              </DateBetweenContainer>
            </EventInfoInputContainer>
            {errors.extendedProps?.endDate && touched?.extendedProps?.endDate && <span className="error">{errors.extendedProps?.endDate}</span>}
          </>
        )}
        <EventInfoInputContainer>
          <UserIcon />
          <Select
            options={COACH_OPTIONS}
            placeholder={t('selectCoach')}
            selectContainerClass="repeat-select"
            value={COACH_OPTIONS.find((o) => o.value === values.extendedProps?.coach)}
            onChange={(e) => {
              setFieldValue('extendedProps', {
                ...values.extendedProps,
                coach: e.value,
              })
              // revalidateTimeAvailability()
            }}
            disabled={user?.role === 'coach'}
            customOptionComponent={OptionWithImage}
          />
        </EventInfoInputContainer>
        {errors.extendedProps?.coach && touched?.extendedProps?.coach && <span className="error">{errors.extendedProps?.coach}</span>}
        {isValid !== 'available' && (
          <>
            {isValid === 'unavailable' && (
              <div className="flex items-center justify-end space-x-2 pb-2">
                <h6 className="text-right text-[#FFB4AB]">{t('NOT_AVAILABLE')}</h6>
                <MdOutlineBlock fontSize={'1.3rem'} className="text-[#FFB4AB]" />
              </div>
            )}
            {isValid === 'conflict' && (
              <div className="flex items-center justify-end space-x-2 pb-2">
                <h6 className="text-right text-activeText">{t('CONFLICT')}</h6>
                <RiErrorWarningLine fontSize={'1.3rem'} className="text-activeText" />
              </div>
            )}
          </>
        )}
        <EventInfoInputContainer>
          <DescriptionIcon />
          <input
            type="text"
            className="description-input"
            placeholder={t('description')}
            name="extendedProps.description"
            onChange={handleChange}
            value={values.extendedProps?.description}
          />
        </EventInfoInputContainer>
        {/* <div className="flex flex-row justify-between">
            <ButtonsContainer>
              <button
                className={`${isValid === 'unavailable' ? 'bg-gray-600 opacity-50' : 'save'}`}
                type="submit"
                disabled={isValid === 'unavailable'}
              >
                {t('SAVE')}
              </button>
              {event?.extendedProps?.status === 'approved' && (
                <button className="cancel" type="button" onClick={() => setCancelModal(true)}>
                  {t('Cancel')}
                </button>
              )}
            </ButtonsContainer>
            <ButtonsContainer>
              {
                <button className="hover:bg-red-400 ml-10" type="button" onClick={() => setDeleteModal(event.id)}>
                  {t('DELETE')}
                </button>
              }
            </ButtonsContainer>
          </div> */}
      </form>
      {/* </div> */}
      {/* </div>,
           document.getElementById('modal-root1')
         )} */}
      <DeleteModal
        setOpenModal={setDeleteModal}
        openModal={deleteModal}
        t={t}
        direction={direction}
        des={t('DELETE_WORKOUT_DESCRIPTION')}
        title={t('DELETE_WORKOUT')}
        handleSubmit={() => {
          dispatch(deleteEvent(event?.id, t, direction, refreshWorkouts))
          setDeleteModal(null)
          setShowUpdateInfoBox(false)
        }}
        showPass={false}
      />
      {/* //cancel model*/}
      <DeleteModal
        setOpenModal={setCancelModal}
        openModal={cancelModal}
        t={t}
        direction={direction}
        des={t('Cancel_Event_Des')}
        title={t('Cancle')}
        handleSubmit={() => {
          dispatch(cancelEvent(event?.id, t, direction, refreshWorkouts))
          setCancelModal(false)
          setShowUpdateInfoBox(false)
        }}
        showPass={false}
      />
    </Modal>
  )
}

export default EventEditor
