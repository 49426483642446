import { yupResolver } from '@hookform/resolvers/yup'
import { LabelInput } from 'components/compounds/LabelInput'
import PrimaryButton from 'components/elements/PrimaryButton'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { createGymRubberBand } from 'store/userSlice/userActions'
import { rubberBandSchema } from 'validations/formsValidations'

export default function CreateRubberBand() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const methods = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(rubberBandSchema),
    defaultValues: {
      bandType: '',
      resistance: null,
    },
  })

  const { control, handleSubmit, formState } = methods
  const { errors } = formState
  const { direction } = useSelector((state) => state.language)
  const { customerDetail } = useSelector((state) => state.user)

  const onSubmit = (values) => {
    let data = {
      ...values,
      gymId: customerDetail?.id,
    }
    dispatch(createGymRubberBand(data, t, direction))
  }
  return (
    <>
      <div className="flex flex-row gap-x-2 pb-4 px-4">
        <LabelInput
          name="bandType"
          type="text"
          label={t('RUBBER_BAND_TYPE_OR_COLOR')}
          placeholderValue={t('RUBBER_BAND_TYPE_OR_COLOR')}
          control={control}
          required
          fullWidth
          error={errors?.bandType ? true : false}
          errorMessage={errors?.bandType?.message}
          dir={direction}
        />
        <LabelInput
          name="resistance"
          type="number"
          label={t('RESISTANCE')}
          control={control}
          placeholderValue={'1-10'}
          required
          fullWidth
          error={errors?.resistance ? true : false}
          errorMessage={errors?.resistance?.message}
          dir={direction}
        />

        <PrimaryButton
          text={t('ADDITION')}
          type="submit"
          disabled={false}
          isIcon={false}
          onClick={handleSubmit(onSubmit)}
          className="w-[144px] h-[44px] rounded-[12px] shadow-2xl "
          bgColor="bg-prussianBlue hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
          textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
          wid={20}
          ht={20}
        />
      </div>
    </>
  )
}
