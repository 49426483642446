import { createSlice } from '@reduxjs/toolkit'

import { useSelector } from 'react-redux'

const initialState = {
  loader: false,
  customerDetail: null,
  currentGymInstruments: [],
  coaches: [],
  trainees: [],
  currentTrainee: null,
  currentCoach: null,
  traineeFilter: [],
  exercisesInfo: [],
  bands: [],
  exerciseLoader: false,
  gymLoading: false,
}
export const gymAdminSlice = createSlice({
  name: 'gymAdmin',
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setAllTrainees: (state, action) => {
      state.trainees = action.payload
    },
    setSingleTrainees: (state, action) => {
      state.currentTrainee = action.payload
    },
    setBands: (state, action) => {
      state.bands = action.payload
    },
    setAllCoaches: (state, action) => {
      state.coaches = action.payload
    },
    setCoachDetail: (state, action) => {
      state.currentCoach = action.payload
    },
    setTraineeFilter: (state, action) => {
      state.traineeFilter = action.payload
    },
    setExerciseInfo: (state, action) => {
      state.exercisesInfo = action.payload
    },
    setExerciseLoader: (state, action) => {
      state.exerciseLoader = action.payload
    },
    setWorkoutNameInExercise: (state, action) => {
      const { ExerciseId, WorkoutId, WorkoutName } = action.payload
      const exerciseIndex = state.exercisesInfo.findIndex((exercise) => exercise.id === ExerciseId)

      if (exerciseIndex !== -1) {
        const updatedExercise = { ...state.exercisesInfo[exerciseIndex] }

        updatedExercise.workout = updatedExercise?.workout ? [...updatedExercise?.workout, { WorkoutId, WorkoutName }] : []
        const updatedExercisesInfo = [...state.exercisesInfo]
        updatedExercisesInfo[exerciseIndex] = updatedExercise
        return {
          ...state,
          exercisesInfo: updatedExercisesInfo,
        }
      }

      return state
    },
    setGymLoading: (state, action) => {
      state.gymLoading = action.payload
    },
    resetGymAdmin: (state, { payload }) => {
      return {
        ...state,
        loader: false,
        setExerciseLoader: false,
        customerDetail: null,
        currentGymInstruments: [],
        coaches: [],
        trainees: [],
        currentTrainee: null,
        currentCoach: null,
        traineeFilter: [],
        exercisesInfo: [],
        bands: [],
      }
    },
  },
})

export const useSaleSelector = () => useSelector((state) => state.gymAdminReducer)

export const gymAdminActions = gymAdminSlice.actions
export const gymAdminReducer = gymAdminSlice.reducer
