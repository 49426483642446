import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const initialState = {
  user: null,
  isLoading: false,
  isLoggedIn: false,
  accessToken: null,
  isOTPSent: false,
  isSuperAdmin: false,
  selectedGym: '',
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.isLoading = action.payload
    },
    usersSuccess: (state, action) => {
      state.user = action.payload
      state.isLoading = false
    },
    setUserInfoAction: (state, action) => {
      state.user = action.payload
    },
    setAccessTokenAction: (state, action) => {
      state.accessToken = action.payload
    },
    facebookLogin: (state, action) => {
      state.accessToken = action.payload
      state.isLoggedIn = false
    },
    logoutUserAction: (state, action) => {
      state.user = null
      state.accessToken = null
      state.isLoading = false
    },
    setOTPStatus: (state, action) => {
      state.isOTPSent = action.payload
    },
    verifySMSOTP: (state, action) => {},
    verifyEmailOTP: (state, action) => {},

    changeEmailandPassword: (state, action) => {
      state.isOTPSent = action.payload
    },
    resetOTPStatus: (state, action) => {
      state.isOTPSent = initialState.isOTPSent
    },
    removeAccessToken: (state) => {
      state.accessToken = null
    },
    addAccountList: (state, { payload }) => {
      state.tempAccountData = { ...payload }
    },
    setLanguage: (state, { payload }) => {
      state.language = payload
    },
    setSuperAdmin: (state, { payload }) => {
      return {
        ...state,
        isSuperAdmin: payload.isAdmin,
        selectedGym: payload.selectedGym,
      }
    },
    resetAuth: (state, { payload }) => {
      return {
        ...state,
        user: null,
        isLoading: false,
        isLoggedIn: false,
        accessToken: null,
        isOTPSent: false,
        isSuperAdmin: false,
      }
    },
  },
})

export const useAuthSelector = () => useSelector((state) => state.authReducer)

export const authActions = authSlice.actions
export const authReducer = authSlice.reducer
