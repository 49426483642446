import styled from 'styled-components'

const Container = styled.div`
  margin: 30px;
  padding-bottom: 30px;

  > .fc {
    width: 100%;
    height: 100%;
  }

  color: #c2c7cf;

  .fc {
    background-color: #1e2a30;
    padding: 30px;
    border-radius: 12px;
    color: #c2c7cf;
    z-index: 10 !important;

    thead {
      background-color: #28343a;
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      font-style: normal;
    }

    .fc-popover {
      background-color: #28343a;
    }

    .fc-scrollgrid {
      border: none;
    }
  }

  .customEvent {
    height: fit-content;
    overflow: hidden;
  }
`

export const Header = styled.header`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  justify-content: flex-end;
`
export const ChangeLanguage = styled.select`
  padding: 5px;
`

export default Container
