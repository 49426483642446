import { createSlice } from '@reduxjs/toolkit'

import { useSelector } from 'react-redux'

const initialState = {
  statistics: {
    loading: false,
    data: {
      trainees: {
        active: {
          currentMonth: 0,
          lastmonth: 0,
        },
        inactive: 0,
      },
      workouts: {
        currentMonth: 0,
        lastmonth: 0,
      },
      openhours: {
        availableHours: {
          currentMonth: 0,
          lastmonth: 0,
        },
        workingHours: 0,
      },
    },
  },
  eventsByDay: {
    loading: false,
    data: [
      {
        startHour: '06:00',
        workouts: [],
      },
      {
        startHour: '07:00',
        workouts: [],
      },
      {
        startHour: '08:00',
        workouts: [],
      },
      {
        startHour: '09:00',
        workouts: [],
      },
      {
        startHour: '10:00',
        workouts: [],
      },
      {
        startHour: '11:00',
        workouts: [],
      },
      {
        startHour: '12:00',
        workouts: [],
      },
      {
        startHour: '13:00',
        workouts: [],
      },
      {
        startHour: '14:00',
        workouts: [],
      },
      {
        startHour: '15:00',
        workouts: [],
      },
      {
        startHour: '16:00',
        workouts: [],
      },
      {
        startHour: '17:00',
        workouts: [],
      },
      {
        startHour: '18:00',
        workouts: [],
      },
      {
        startHour: '19:00',
        workouts: [],
      },
      {
        startHour: '20:00',
        workouts: [],
      },
      {
        startHour: '21:00',
        workouts: [],
      },
      {
        startHour: '22:00',
        workouts: [],
      },
      {
        startHour: '23:00',
        workouts: [],
      },
    ],
  },
  completedWorkoutsByTrainee: {
    loading: false,
    data: [],
  },
  consecutiveCancelledWorkoutsByTrainee: {
    loading: false,
    data: [],
  },
  completedWorkoutsByCoach: {
    loading: false,
    data: {
      labels: [],
      gymCoaches: [],
    },
    coachesFilter: [],
    filterDuration: 'last_7_days',
  },
  lastfetched: null,
}
export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setStatisticsLoader: (state, action) => {
      state.statistics.loading = action.payload
    },
    setStatistics: (state, action) => {
      state.statistics.data = action.payload
    },

    setEventsByDateLoader: (state, action) => {
      state.eventsByDay.loading = action.payload
    },
    setEventsByDay: (state, action) => {
      state.eventsByDay.data = action.payload
    },

    setCompletedWorkoutsLoader: (state, action) => {
      state.completedWorkoutsByTrainee.loading = action.payload
    },
    setCompletedWorkouts: (state, action) => {
      state.completedWorkoutsByTrainee.data = action.payload
    },

    setConsecutiveCancelledWorkoutsLoader: (state, action) => {
      state.consecutiveCancelledWorkoutsByTrainee.loading = action.payload
    },
    setConsecutiveCancelledWorkouts: (state, action) => {
      state.consecutiveCancelledWorkoutsByTrainee.data = action.payload
    },

    setWorkoutsCompletedByCoachLoader: (state, action) => {
      state.completedWorkoutsByCoach.loading = action.payload
    },
    setWorkoutsCompletedByCoach: (state, action) => {
      state.completedWorkoutsByCoach.data = action.payload
    },

    setFilterDuration: (state, action) => {
      state.completedWorkoutsByCoach.filterDuration = action.payload
    },
    setCoachesFilter: (state, action) => {
      state.completedWorkoutsByCoach.coachesFilter = [...action.payload]
    },
    addCoachToFilter: (state, action) => {
      if (state.completedWorkoutsByCoach.coachesFilter.includes(action.payload)) {
        state.completedWorkoutsByCoach.coachesFilter = [
          ...state.completedWorkoutsByCoach.coachesFilter.filter((coachId) => coachId !== action.payload),
        ]
      } else {
        state.completedWorkoutsByCoach.coachesFilter = [...state.completedWorkoutsByCoach.coachesFilter, action.payload]
      }
    },
    setLastFetched: (state, action) => {
      state.lastfetched = new Date()
    },
    reset: (state, action) => {
      return { ...initialState }
    },
  },
})

export const useDashboard = () => useSelector((state) => state.dashboard)

export const dasboardActions = dashboardSlice.actions
export const dasboardReducer = dashboardSlice.reducer
