import { Images } from 'assets'
import { useTranslation } from 'react-i18next'
import { formatDateSummary, getResistanceLabel } from 'utils'
import { ProfileAvatar } from '../ProfileAvatar'

export default function BestExercise({ data, summary }) {
  const { t } = useTranslation()

  return (
    <div className=" w-full h-full space-y-4 overflow-y-scroll no-scrollbar">
      <p className=" text-xl ltr:font-primary-rg rtl:font-primary-he-rg text-[#D9D9D9] truncate">{data?.exerciseName}</p>
      <div className="flex w-full flex-col gap-4 justify-center items-center ">
        <div className="flex gap-2 py-2 ">
          {data?.resistanceGapValue !== 0 && (
            <div
              className={`font-primary-rg text-[12px] leading-4 font-normal ${
                data?.resistanceGapValue > 0 ? 'text-[#53c22b] bg-[#0c1d1a]' : 'text-[#f54b49] bg-[#18191e]'
              }   py-[2px] px-1 rounded`}
            >
              {t(getResistanceLabel(data?.resistanceGapLable))} {data?.resistanceGapValue}
            </div>
          )}
          {data?.trackingGapValue !== 0 && (
            <div
              className={`font-primary-rg text-[12px] leading-4 font-normal ${
                data?.trackingGapValue > 0 ? 'text-[#53c22b] bg-[#0c1d1a]' : 'text-[#f54b49] bg-[#18191e]'
              }  py-[2px] px-1 rounded`}
            >
              {t(getResistanceLabel(data?.trackingGapLable))} {data?.trackingGapValue}
            </div>
          )}
          <p className=" text-xs ltr:font-primary-rg rtl:font-primary-he-rg text-white">
            {`${data?.totalSet}`} {t('SETS')}
          </p>
        </div>
        <img src={Images.Medal} alt="Achieve" className=" h-36 cursor-pointer " />
      </div>
      <div className="w-full flex flex-row gap-3 items-center">
        <img src={Images.fire} alt="Achieve" className=" h-6 w-6 cursor-pointer " />
        <p className=" text-xs ltr:font-primary-rg rtl:font-primary-he-rg text-white">{t('New achievements')}</p>
        <p className=" text-base ltr:font-primary-rg rtl:font-primary-he-rg text-white">{`${data?.resistanceGapValue} ${data?.resistanceGapLable} ${data?.trackingGapValue} ${data?.trackingGapLable}`}</p>
      </div>
      <div className="w-full flex flex-row gap-3 items-center">
        <img src={Images.Achieve} alt="Achieve" className=" h-6 w-6 cursor-pointer " />
        <p className=" text-xs ltr:font-primary-rg rtl:font-primary-he-rg text-white">{t('Improved')}</p>
        <p className=" text-base ltr:font-primary-rg rtl:font-primary-he-rg text-white">
          {`${data?.completedSet}/${data?.totalSet}`} {t('SETS')}
        </p>
      </div>
      <div className="w-full flex flex-row gap-3 items-center">
        <img src={Images.Direction} alt="Achieve" className=" h-6 w-6 cursor-pointer " />
        <p className=" text-xs ltr:font-primary-rg rtl:font-primary-he-rg text-white">{t('TECHNIQUE')}</p>
        <div className="w-full overflow-x-scroll no-scrollbar flex flex-row gap-2">
          {data?.control_technique && (
            <div className="p-2 bg-[#373C42] rounded">
              <p className=" text-xs ltr:font-primary-rg rtl:font-primary-he-rg text-white">{t('control')}</p>
            </div>
          )}
          {data?.intensityTracking && (
            <div className="p-2 bg-[#373C42] rounded">
              <p className=" text-xs ltr:font-primary-rg rtl:font-primary-he-rg text-white">{t('muscle')}</p>
            </div>
          )}
          {data?.mobility_technique && (
            <div className="p-2 bg-[#373C42] rounded">
              <p className=" text-xs ltr:font-primary-rg rtl:font-primary-he-rg text-white">{t('movement')}</p>
            </div>
          )}
        </div>
      </div>
      {data?.comments?.length > 0 && (
        <div className="flex flex-col space-y-2 py-2  max-h-28 overflow-scroll no-scrollbar">
          {data?.comments?.map((comment, index) => (
            <div className="flex flex-row gap-2 items-center cursor-pointer" key={comment?.id}>
              <ProfileAvatar image={comment?.User?.avatar} name={comment?.User?.first_name} size={'large'} />
              <div className="flex flex-col w-[87%]">
                <div className="flex flex-row items-center gap-x-2">
                  <p className="text-white text-sm ltr:font-primary-rg rtl:font-primary-he-rg">{comment?.User?.first_name}</p>

                  <p className="text-[#C2C7CF] text-xs ltr:font-primary-rg rtl:font-primary-he-rg">{formatDateSummary(comment?.createdAt, t)}</p>
                </div>
                <div className="flex flex-wrap font-primary-rg font-medium text-white text-xs leading-4">{comment?.text}</div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
