import { createSlice } from '@reduxjs/toolkit'

import { useSelector } from 'react-redux'

const initialState = {
  loading: false,
  coachDailyWorkouts: [],
  activeWorkouts: [],
  traineeExercises: [],
  comments: [],
  traineeRecomendedExercises: [],
  exerciseHistory: { exercises: [], BestRecord: {}, firstRecord: {}, NumberOfPreformances: 0 },
  workoutPattern: [],
  historyLoader: false,
}

export const workoutSlice = createSlice({
  name: 'workout',
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.loading = action.payload
    },
    setCoachWorkouts: (state, action) => {
      state.coachDailyWorkouts = action.payload
    },
    setComments: (state, action) => {
      state.comments = action.payload
    },
    setActiveWorkouts: (state, action) => {
      state.activeWorkouts = action.payload
    },
    setTraineeExercises: (state, action) => {
      state.traineeExercises = action.payload
    },
    setTraineeRecommendedExercises: (state, action) => {
      state.traineeRecomendedExercises = action.payload
    },
    setExerciseHistory: (state, action) => {
      state.exerciseHistory = action.payload
    },
    setWorkoutPattern: (state, action) => {
      state.workoutPattern = action.payload
    },
    addActiveWorkout: (state, action) => {
      state.activeWorkouts = [...state.activeWorkouts, action.payload]
    },
    updateLoadingWorkout: (state, action) => {
      let updatedState = state.activeWorkouts
      updatedState[updatedState.length - 1] = action.payload
      state.activeWorkouts = [...updatedState]
    },
    removeLastActiveWorkout: (state) => {
      state.activeWorkouts = [...state.activeWorkouts.slice(0, -1)]
    },
    resetWorkoutDetails: (state) => {
      state.coachDailyWorkouts = []
      state.activeWorkouts = []
    },
    updateCoachWorkoutStatus: (state, action) => {
      state.coachDailyWorkouts = state.coachDailyWorkouts.map((item) => {
        const updatedWorkouts = item.workouts.map((workout) => {
          if (workout?.Workout?.id === action.payload.workoutId) {
            return {
              ...workout,
              Workout: {
                ...workout.Workout,
                status: action.payload.status,
              },
            }
          }
          return workout
        })
        return {
          ...item,
          workouts: updatedWorkouts,
        }
      })
    },
    updateWorkout: (state, action) => {
      const { workoutId, workoutDetail } = action.payload
      const updatedWorkoutList = state.activeWorkouts.map((workout) => {
        if (workout.workoutData.id === workoutId) {
          return workoutDetail
        }
        return workout
      })
      state.activeWorkouts = updatedWorkoutList
    },
    updateWorkoutExercise: (state, action) => {
      const { workoutId, workoutDetail, workoutExerciseId } = action.payload
      const updatedWorkoutList = state.activeWorkouts.map((workout) => {
        let { workoutData } = workout
        if (workoutData.id === workoutId) {
          const oldindex = workoutData.exercises.findIndex((x) => x.workoutExerciseId === workoutExerciseId)
          const newIndex = workoutDetail.workoutData.exercises.findIndex((x) => x.workoutExerciseId === workoutExerciseId)
          workout.workoutData.exercises[oldindex] = {
            ...workoutDetail.workoutData.exercises[newIndex],
          }
          return workout
        }
        return workout
      })
      state.activeWorkouts = updatedWorkoutList
    },
    removeWorkout: (state, action) => {
      const { workoutId } = action.payload
      const updatedWorkoutList = state.activeWorkouts.filter((workout) => workout.workoutData.id !== workoutId)
      state.activeWorkouts = updatedWorkoutList
    },
    addWorkoutExercise: (state, action) => {
      const { workoutId, workoutDetail } = action.payload
      const updatedWorkoutList = state.activeWorkouts.map((workout) => {
        let { workoutData } = workout
        if (workout.workoutData.id === workoutId) {
          const newExercises = workoutDetail.workoutData.exercises.filter((exercise) => {
            return !workoutData.exercises.some((x) => x.workoutExerciseId === exercise.workoutExerciseId)
          })
          workout.workoutData.exercises.push(newExercises[0])
          return workout
        }
        return workout
      })
      state.activeWorkouts = updatedWorkoutList
    },
    tempUpdateWorkoutExercise: (state, action) => {
      const { workoutId, workoutExerciseId, status } = action.payload
      const updatedWorkoutList = state.activeWorkouts.map((workout) => {
        let { workoutData } = workout
        if (workoutData.id === workoutId) {
          const oldindex = workoutData.exercises.findIndex((x) => x.workoutExerciseId === workoutExerciseId)

          workout.workoutData.exercises[oldindex] = {
            ...workout.workoutData.exercises[oldindex],
            workoutExerciseStatus: status,
          }
          return workout
        }
        return workout
      })
      state.activeWorkouts = updatedWorkoutList
    },

    swapWorkoutExercise: (state, action) => {
      const { workoutId, workoutDetail, WorkoutExerciseId } = action.payload
      const updatedWorkoutList = state.activeWorkouts.map((workout) => {
        let { workoutData } = workout
        if (workout.workoutData.id === workoutId) {
          const newExercises = workoutDetail.workoutData.exercises.filter((exercise) => {
            return !workoutData.exercises.some((x) => x.workoutExerciseId === exercise.workoutExerciseId)
          })
          const exerciseIndex = workoutData.exercises.findIndex((exercise) => exercise.workoutExerciseId === WorkoutExerciseId)
          workoutData.exercises[exerciseIndex] = newExercises[0]

          return workout
        }
        return workout
      })
      state.activeWorkouts = updatedWorkoutList
    },
    setAllWorkoutExerciseActive: (state, action) => {
      let id = action.payload
      const updatedWorkoutList = state.activeWorkouts.map((workout) => {
        let { workoutData } = workout
        if (workout.workoutData.id === id) {
          workoutData.exercises.forEach((exercise) => {
            exercise.workoutExerciseStatus = 'partial'
          })

          return workout
        }
        return workout
      })
      state.activeWorkouts = updatedWorkoutList
    },
    removeWorkoutExercise: (state, action) => {
      const { workoutId, WorkoutExerciseId } = action.payload
      const updatedWorkoutList = state.activeWorkouts.map((workout) => {
        let { workoutData } = workout
        if (workout.workoutData.id === workoutId) {
          workoutData.exercises = workoutData.exercises.filter((exercise) => exercise.workoutExerciseId !== WorkoutExerciseId)
          return workout
        }
        return workout
      })
      state.activeWorkouts = updatedWorkoutList
    },
    setHitoryLoader: (state, action) => {
      state.historyLoader = action.payload
    },
  },
})

export const useWorkoutSelector = () => useSelector((state) => state.workout)

export const workoutActions = workoutSlice.actions
export const workoutReducer = workoutSlice.reducer
