import { Images } from 'assets'
import { Modal } from 'components/compounds/Modal'
import ImagePicker from 'components/elements/ImagePicker'
import GenderSelection from 'components/home/GenderSelection'
import { constants } from 'constant'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import DatePickerField from 'components/compounds/DatePickerField'
import Dropdown from 'components/compounds/DropDown'
import { LabelInput } from 'components/compounds/LabelInput'
import { Loader } from 'components/compounds/Loader'
import CustomToastify from 'components/compounds/Toast'

import { createCoachTrainee } from 'store/coachSlice/coachActions'
import TraineesFooter from 'components/trainees/TraineesFooter'

export default function CreateCoachTrainee({ methods, show, setShow, gymID, setGymID }) {
  const { loader } = useSelector((state) => state.user)
  // eslint-disable-next-line
  const [disable, setDisable] = useState(false)

  // eslint-disable-next-line
  const [openModal, setOpenModal] = useState(false)
  // eslint-disable-next-line
  const [openSuspendModal, setOpenSuspendModal] = useState(false)

  const [file, setFile] = useState(null)
  const { t } = useTranslation()
  const { reset, control, handleSubmit, formState } = methods
  const { errors } = formState

  const dispatch = useDispatch()
  const { direction } = useSelector((state) => state.language)

  const [imageURL, setImageURL] = useState(null)
  const [imageError, setImageError] = useState(false)
  const [selectedGender, setSelectedGender] = useState(constants.GENDER[0])

  const [selectedDate, setSelectedDate] = useState(null)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [activityLevel, setActivityLevel] = useState(constants.ACTIVITY_LEVEL[0])
  const [dobError, setDobError] = useState(false)

  const handleClose = () => {
    reset({
      traineeName: '',
      phoneNo: '',
      email: '',
      height: null,
      weight: null,
      purpose: '',
      comment: '',
    })
    setSelectedGender(constants.GENDER[0])

    setShow(false)
  }
  const action = () => {
    setFile(null)
    handleClose()
  }
  const toggleOpen = () => setOpenModal(true)
  const toggleSuspendOpen = () => setOpenSuspendModal(true)
  const onSubmit = async (values) => {
    if (selectedDate != null) {
      let data = {
        ...values,
        email: values.email,
        phoneNo: values.phoneNo,
        traineeName: values.traineeName,
        comment: values.comment === undefined ? '' : values.comment,
        purpose: values.purpose === undefined ? '' : values.purpose,
        height: values.height === undefined ? null : values.height,
        weight: values.weight === undefined ? null : values.weight,
        gender: selectedGender?.value,
        birthday: selectedDate ? selectedDate : null,
        activityLevel: activityLevel,
      }

      const formData = new FormData()
      if (file) {
        formData.append('file', file)
      }
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key])
      })
      dispatch(createCoachTrainee(formData, action, t, direction))
    } else {
      setDobError(true)
    }
  }

  const notify = () => {
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
  }

  return (
    <Modal
      className={`h-full w-[480px] ${direction === 'ltr' ? 'rounded-l-3xl' : 'rounded-r-3xl'}   `}
      childrenClass="overflow-auto "
      open={show}
      title={t('CREATE_A_NEW_TRAINEE')}
      handleClose={handleClose}
      handleSubmit={handleSubmit(onSubmit)}
      handleNotify={notify}
      loader={loader}
      isEdited={gymID != null ? true : false}
      toggleOpen={toggleOpen}
      toggleSuspendOpen={toggleSuspendOpen}
      disabled={disable}
      direction={direction}
      footer={<TraineesFooter handleSubmit={handleSubmit(onSubmit)} />}
    >
      {loader ? (
        <Loader className="" size="w-8 h-8" fill="#003353" />
      ) : (
        <div>
          <ImagePicker
            placeholder={t('TRAINEE_PHOTO')}
            imageURL={imageURL}
            setImageURL={setImageURL}
            imageError={imageError}
            errorMessage={t('IMAGE_REQUIRED')}
            setImageError={setImageError}
            setSelectedFile={setFile}
            selectedFile={file}
            styles={`w-[140px] h-[140px] items-center  justify-center my-4   rounded-[50%] ${disable && 'opacity-50'} ${
              !imageURL && 'border-dashed border-[1px]'
            }`}
            disabled={disable}
          />

          <LabelInput
            name="traineeName"
            type="text"
            label={t('TRAINEE_NAME')}
            placeholderValue={t('FULL_NAME')}
            control={control}
            required
            fullWidth
            error={Object.keys(errors).length > 0 ? true : false}
            errorMessage={errors?.traineeName?.message}
            dir={direction}
            disabled={disable}
            isRequired={true}
          />
          <LabelInput
            name="phoneNo"
            type="number"
            label={t('MOBILE_NO')}
            control={control}
            placeholderValue={'0520000000'}
            required
            fullWidth
            error={Object.keys(errors).length > 0 ? true : false}
            errorMessage={errors?.phoneNo?.message}
            dir={direction}
            disabled={disable}
            isRequired={true}
          />
          <LabelInput
            name="email"
            type="text"
            label={t('EMAIL_ADDRESS_AS_USERNAME')}
            placeholderValue={'aaa@gmail.com'}
            control={control}
            required
            fullWidth
            error={Object.keys(errors).length > 0 ? true : false}
            errorMessage={errors?.email?.message}
            dir={direction}
            disabled={disable}
          />
          <GenderSelection
            name="gender"
            data={constants.GENDER}
            selectedGender={selectedGender}
            setSelectedGender={setSelectedGender}
            disabled={disable}
          />
          <DatePickerField
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            showDatePicker={showDatePicker}
            setShowDatePicker={setShowDatePicker}
            required={true}
            dir={direction}
            t={t}
            error={dobError}
            setError={setDobError}
            title="DOB"
          />
          <div className="text-white text-[14px] font-primary-rg px-1">{t('ADDITIONAL')}</div>
          <div className="flex flex-row gap-4">
            <LabelInput
              name="height"
              type="number"
              label={t('HEIGHT')}
              control={control}
              placeholderValue={t('CM')}
              fullWidth
              dir={direction}
              disabled={disable}
            />
            <LabelInput
              name="weight"
              type="number"
              label={t('WEIGHT')}
              control={control}
              placeholderValue={t('POUNDS')}
              fullWidth
              dir={direction}
              disabled={disable}
            />
          </div>

          <Dropdown
            options={constants.ACTIVITY_LEVEL}
            className="my-2"
            title={t('ACTIVITY_LEVEL')}
            value={activityLevel}
            setValue={setActivityLevel}
            disable={false}
            t={t}
            dir={direction}
          />
          <LabelInput
            name="purpose"
            type="text"
            label={t('PURPOSE_OF_TRAINEE')}
            placeholderValue={t('PURPOSE_OF_TRAINEE_PROPS')}
            control={control}
            fullWidth
            dir={direction}
            disabled={disable}
          />
          <LabelInput
            name="comment"
            type="text"
            label={t('COMMENT')}
            placeholderValue={t('COMMENT_PLACEHOLDER')}
            control={control}
            fullWidth
            dir={direction}
            disabled={disable}
          />
        </div>
      )}
    </Modal>
  )
}
