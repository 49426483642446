import { Images } from 'assets'
import React from 'react'
import { useSelector } from 'react-redux'

export default function ProgramWorkoutHeader({ workoutlist, programName, t }) {
  const { currentTrainee } = useSelector((state) => state.gymAdmin)
  return (
    <div className="flex w-full h-7 justify-between items-center">
      <p className=" font-primary-rg text-xl text-textLightGray font-medium leading-6">
        {programName} - {workoutlist?.length} {t('WORKOUTS')}
      </p>

      <div className="flex gap-2 flex-row justify-center items-center">
        <div className="flex border rounded-[50px] w-7 h-7 justify-center items-center overflow-hidden">
          <img
            src={currentTrainee?.User?.avatar != null ? currentTrainee?.User?.avatar : Images.Avatar}
            alt=""
            height={28}
            width={28}
            className={`items-center cursor-pointer`}
            onClick={() => {}}
          />
        </div>
        <div className="font-primary-rg text-[#C2C7CF] text-[16px] font-medium leading-5 text-center ">{currentTrainee?.User?.firstName}</div>
      </div>
    </div>
  )
}
