import axiosInstance from 'api'
import { Images } from 'assets'
import CustomToastify from 'components/compounds/Toast'

import { coachActions } from './coachReducers'

export const getAllCoachTrainees =
  (t, direction, filter = 'all') =>
  async (dispatch) => {
    try {
      dispatch(coachActions.setLoader(true))

      await axiosInstance
        .get(`getTrainees`, { params: { query: filter } })
        .then((response) => {
          dispatch(coachActions.setAllTrainees(response.result.data))
        })
        .finally(() => {
          dispatch(coachActions.setLoader(false))
        })
    } catch (e) {
      dispatch(coachActions.setLoader(false))
      CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return console.error(e.message)
    }
  }
export const createCoachTrainee = (data, action, t, direction) => async (dispatch) => {
  try {
    dispatch(coachActions.setLoader(true))
    await axiosInstance.post(`/CreateTrainee`, data).then((response) => {
      dispatch(coachActions.setLoader(false))
      dispatch(getAllCoachTrainees(t, direction))
      CustomToastify(t('TRAINEE_CREATED_SUCCESSFULLY'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
      action && action()
    })
  } catch (e) {
    dispatch(coachActions.setLoader(false))

    if (e.code === 502) {
      CustomToastify(t('EMAIL_ALREADY_EXIST'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    } else {
      CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    }
    return console.error(e.message)
  }
}
