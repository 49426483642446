import { Images } from 'assets'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function ImprovedExercise({ data, summary }) {
  console.log({})
  const { t } = useTranslation()
  return (
    <div className=" w-full h-full space-y-2 overflow-y-scroll no-scrollbar">
      <div className="flex w-full flex-col gap-4 justify-center items-center ">
        <p className=" text-2xl ltr:font-primary-rg rtl:font-primary-he-rg text-[#D9D9D9]">{`${summary?.improvementCard?.length} / ${summary?.PerformanceCard?.totalExercises}`}</p>
        <p className=" text-base ltr:font-primary-rg rtl:font-primary-he-rg text-[#D9D9D9]">{t('Exercise Improved')}</p>
        <img src={Images.Achieve} alt="Achieve" className=" h-36 cursor-pointer " />
      </div>
      <div className="w-full  flex flex-col gap-3">
        {data?.map((item, index) => (
          <div className="flex flex-row justify-between   w-full " key={index}>
            <div className='w-[60%] overflow-ellipsis '>
            <p className=" text-sm ltr:font-primary-rg rtl:font-primary-he-rg text-white truncate">{item?.exerciseName}</p>
            </div>
            <div className="flex gap-x-2 items-center w-[35%] justify-start">
              <p className="text-sx ltr:font-primary-rg rtl:font-primary-he-rg text-[#A7ABB3]">
                ּּּּ{`${item?.numberOfSetsImproved}/${item?.numberOfSetsInTotal}`} {t('SETS')}
              </p>
              <p className="text-sx ltr:font-primary-rg rtl:font-primary-he-rg text-[#A7ABB3]">
                ּ{`${item?.bestSetResistanceValue}*${item?.bestSetTrackingValue}`} {t(item?.bestSetResistanceLable)}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
