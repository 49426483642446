import SelectionDropDownWithSearch from './ToolDropDownWithSearch'

export default function SelectionDropDown({ id, data, value, setValue, options, title, disable, t, dir, isRequired, placeHolder }) {
  return (
    <div className="flex flex-col">
      <SelectionDropDownWithSearch
        options={options}
        className="my-2"
        title={title}
        value={value}
        setValue={(tool) => {
          setValue(tool)
        }}
        disable={disable}
        t={t}
        dir={dir}
        isRequired={isRequired}
        placeHolder={placeHolder}
      />
    </div>
  )
}
