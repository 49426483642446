import ListTable from 'components/List/ListTable'
import DeleteModal from 'components/compounds/DeleteModal'
import { Loader } from 'components/compounds/Loader'
import MusclesHeader from 'components/muscles/MusclesHeader'
import { useDebounce } from 'hooks'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { DeleteGymMuscle, getAllMucle, updateGymMuscle } from 'store/userSlice/userActions'

export default function Muscles() {
  const [searchText, setSearchText] = useState()
  const { loader, GymMuslces } = useSelector((state) => state.user)
  const { direction, language } = useSelector((state) => state.language)
  const [eqID, setEqID] = useState(null)
  const [openModal, setOpenModal] = useState()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const debouncedSearchTerm = useDebounce(searchText, 500)

  useEffect(() => {
    const getGym = async () => {
      if (debouncedSearchTerm) {
        dispatch(getAllMucle(t, direction, { search: debouncedSearchTerm }))
      } else {
        dispatch(getAllMucle(t, direction))
      }
    }
    getGym()
    // eslint-disable-next-line
  }, [debouncedSearchTerm])

  const headerRows = {
    data: [
      {
        id: 'serialNo',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'text',
        disablePadding: false,
        label: '#',
        sort: true,
      },
      {
        id: language === 'en' ? 'muscleName' : 'hebrewName',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'text',
        disablePadding: false,
        label: 'MUSCLE_NAME',
        sort: true,
      },
    ],
    isDeleting: true,
    isEditable: true,
  }

  const editHandler = (data) => {
    dispatch(updateGymMuscle(data, t, direction))
  }
  const action = () => {
    setEqID(null)
    setOpenModal(false)
  }
  const deleteHandler = (id) => {
    setEqID(id)
    setOpenModal(true)
  }
  const deleteEquipment = () => {
    dispatch(DeleteGymMuscle(eqID, t, direction, action))
  }

  return (
    <div className="h-full w-full overflow-hidden">
      <MusclesHeader setSearchText={setSearchText} searchText={searchText} />
      {loader ? (
        <Loader className="" size="w-5 h-5" fill="#003353" />
      ) : (
        <>
          {GymMuslces?.length > 0 ? (
            <div className="h-[76%] w-full overflow-auto">
              <ListTable
                headerRows={headerRows}
                data={GymMuslces}
                loading={false}
                searchText={''}
                clickHandler={() => {}}
                deleteHandler={deleteHandler}
                editHandle={editHandler}
              />
            </div>
          ) : (
            <div className="flex h-[80%] justify-center items-center ">
              <p className={`text-white font-primary-rg text-[24px] text-center ltr:font-primary-rg rtl:font-primary-he-rg`}>{t('NO_DATA')}</p>
            </div>
          )}
        </>
      )}

      <DeleteModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        t={t}
        direction={direction}
        des={t('MUSCLE_DELETE_DES')}
        title={t('MUSCLE_DELETE_TITLE')}
        action={() => {}}
        handleSubmit={deleteEquipment}
        showPass={false}
      />
    </div>
  )
}
