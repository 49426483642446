import React from 'react'
import { Menu } from '@headlessui/react'

export default function ColorMenu({ label, selectedColor, setSelectedColor, translate, disabled, direction }) {
  return (
    <div className={`flex flex-row py-1 w-full ${disabled && 'opacity-50'}`}>
      {label && (
        <label
          className={`flex flex-col py-1 text-white ${
            direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
          } text-[16px] justify-center  w-36 `}
        >
          {label}
        </label>
      )}
      <div className="flex flex-row w-full h-[56px] items-center justify-between  rounded-[12px]">
        <Menu as="div" className="relative inline-block w-full h-[56px] text-left pb-4 ">
          <Menu.Button
            className={`flex w-full h-[56px] text-[10px] justify-between items-center  rounded-[12px] bg-[#efeded] bg-opacity-20 px-4 py-2 font-medium  font-primary-rg`}
          >
            <p className={`px-2 text-white font-primary-rg text-[16px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>
              {translate(selectedColor.value)}
            </p>
            <div className={`w-5 h-5  rounded-[50%] px-2`} style={{ backgroundColor: selectedColor.color }}></div>
          </Menu.Button>
        </Menu>
      </div>
    </div>
  )
}
