import { useState } from 'react'

import { Images } from 'assets'
import { Loader } from 'components/compounds/Loader'
import { Modal } from 'components/compounds/Modal'
import CustomToastify from 'components/compounds/Toast'
import OutlineButton from 'components/elements/OutlineButton'
import PrimaryButton from 'components/elements/PrimaryButton'
import { constants } from 'constant'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { addExerciseToWorkout, getExercisesInfo } from 'store/traineeSlice/traineeActions'
import { addExerciseInWorkout } from 'store/workoutSlice/workoutActions'
import { useWorkoutSelector, workoutActions } from 'store/workoutSlice/workoutReducer'
import { getBestResistanceValue, getBestTrackingValue } from 'utils'
import PlayWorkoutAddExerciseHeader from './components/PlayWorkoutAddExerciseHeader'

export default function PlayWorkoutAddExercise({ show, setShow, isTrainee, refresh }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { loading, traineeExercises } = useWorkoutSelector()
  const { direction } = useSelector((state) => state.language)
  const [selectedExerciseId, setSelectedExerciseId] = useState(null)
  const [isLoading, setloading] = useState(false)
  const { bands } = useSelector((state) => state.gymAdmin)
  const { customerDetail } = useSelector((state) => state.user)

  const gymId = customerDetail.id
  const traineeId = show?.traineeId

  const handleClose = () => {
    setSelectedExerciseId(null)
    setShow(false)
    dispatch(workoutActions.setTraineeExercises([]))
  }

  const onClickExercise = (exerciseId) => {
    if (exerciseId === selectedExerciseId) {
      setSelectedExerciseId(null)
    } else {
      setSelectedExerciseId(exerciseId)
    }
  }

  const addExercise = async (isAddToAllWorkouts) => {
    try {
      setloading(true)
      let exerciseFound = traineeExercises?.find((item) => item.id === selectedExerciseId)

      const lastExercise = await getExercisesInfo(traineeId, selectedExerciseId)

      const payload = {
        exercise: { ...exerciseFound, lastExercise },
        workoutId: show.workoutId,
        traineeId: show.traineeId,
        isAllPartial: show.isAllPartial,
        isAddToAllWorkouts,
      }
      const action = () => {
        setloading(false)
        setShow(false)
      }
      if (isTrainee) {
        const response = await addExerciseToWorkout(payload)
        action()
        refresh && refresh()
        if (response) {
        } else {
          CustomToastify(t('SOMETHING_WENT_WRONG'), t('ALREADY_HAS_EXERCISE'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
        }
      } else {
        dispatch(addExerciseInWorkout(payload, payload.workoutId, action, t, direction))
      }
    } catch (error) {
      console.log(error)
      setloading(false)
    }
  }

  const FooterComponent = () => {
    const { t } = useTranslation()
    return (
      <div className="flex flex-row h-16 items-center px-4 justify-between xl:px-6 2xl:px-12 border-t-[1px] border-borderColor absolute bottom-0 w-full bg-sidebar">
        <OutlineButton
          text={t('CLOSE')}
          type="submit"
          className="w-[80px] h-[38px] rounded-[12px] shadow-2xl"
          bgColor=" hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed rounded-[12px] cursor-pointer bg-sidebar"
          textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
          wid={20}
          ht={20}
          onClick={handleClose}
          disabled={isLoading}
        />
        <div className="flex gap-2">
          {!isLoading && (
            <OutlineButton
              text={t('ADD_FOR_ALL_WORKOUTS')}
              type="submit"
              onClick={() => addExercise(true)}
              disabled={selectedExerciseId != null ? false : true} //apply condition if some exercise is selected than make it disabled false
              className="h-[38px] rounded-[12px] shadow-2xl"
              bgColor="border-sidebarhover border-2 "
              textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
              wid={15}
              ht={15}
              Loader={isLoading}
            />
          )}
          <PrimaryButton
            text={t('ADD')}
            type="submit"
            onClick={() => addExercise(false)}
            disabled={selectedExerciseId != null ? false : true} //apply condition if some exercise is selected than make it disabled false
            className="h-[38px] rounded-[12px] shadow-2xl"
            bgColor="bg-sidebarhover hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed"
            textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
            wid={15}
            ht={15}
            Loader={isLoading}
          />
        </div>
      </div>
    )
  }

  const getResistanceValue = (data) => {
    if (data?.resistance_name === constants.RUBBER_BAND) {
      return bands[0].machineName
    } else {
      if (data?.lastExercise) {
        return getBestResistanceValue(data?.lastExercise?.Sets)
      } else {
        return 10
      }
    }
  }
  return (
    <Modal
      className={`h-full w-[595px] ${direction === 'ltr' ? 'rounded-l-3xl' : 'rounded-r-3xl'}`}
      childrenClass="overflow-auto"
      open={show}
      title={`${t('ADD_EXERCISE')}`}
      handleClose={handleClose}
      handleSubmit={() => {}}
      loader={loading}
      direction={direction}
      footer={<FooterComponent />}
    >
      <div className="h-full relative overflow-hidden">
        {/* ----------------- Search Bar --------------------- */}
        <div className="flex flex-col w-full items-center">
          <PlayWorkoutAddExerciseHeader gymId={gymId} traineeId={traineeId} />
        </div>
        {loading ? (
          <Loader className="" size="w-8 h-8" fill="#003353" />
        ) : (
          <>
            {/* ----------------- Show Exercise List --------------------- */}
            <div className="flex flex-row my-2">
              <p className="font-primary-rg font-medium text-[14px] text-white">
                {`${t('EXERCISES')} (${traineeExercises?.length}/${traineeExercises?.length})`}
              </p>
            </div>

            <div className="flex h-[83%] xl:h-[80%] flex-col gap-2 overflow-auto ">
              {traineeExercises?.map((exercise) => {
                const isExercisePerformed = Number(exercise?.exerciseCount) > 0
                return (
                  <div key={exercise.id}>
                    {selectedExerciseId !== exercise?.id && (
                      <div
                        className="flex h-[54px] rounded-lg p-4 bg-playCard justify-between cursor-pointer"
                        onClick={() => {
                          onClickExercise(exercise?.id)
                        }}
                      >
                        <p className="font-primary-rg font-medium text-[14px] text-white leading-5">{exercise?.exercise_name}</p>
                        {isExercisePerformed && (
                          <p className="font-primary-rg font-medium text-[12px] text-black rounded px-2 bg-lightGreen text-center justify-items-center">
                            {t('DONE')} {exercise?.exerciseCount}
                          </p>
                        )}
                      </div>
                    )}
                    {selectedExerciseId === exercise?.id && (
                      <div
                        className="flex flex-col bg-textFieldColor w-full rounded-lg p-4 gap-4 "
                        onClick={() => {
                          onClickExercise(exercise?.id)
                        }}
                      >
                        <div className="flex flex-row w-full justify-between cursor-pointer">
                          <p className="font-primary-rg font-medium text-[14px] text-white">{exercise?.exercise_name}</p>
                          {isExercisePerformed && (
                            <p className="font-primary-rg font-medium text-[12px] text-black rounded px-2 bg-lightGreen text-center justify-items-center">
                              {t('DONE')} {exercise?.exerciseCount}
                            </p>
                          )}
                        </div>
                        {exercise?.lastExercise && (
                          <div className="flex space-x-3 rtl:space-x-reverse justify-between">
                            <div className="flex flex-col bg-[#505C62] rounded-xl w-full px-4 py-3 space-y-1">
                              <p className="font-primary-rg text-[12px] leading-4 text-textLightGrey">{t('SETS')}</p>
                              <p className="font-primary-rg text-[12px] leading-4 text-textLightGrey">{exercise?.lastExercise?.setsCount || 3}</p>
                            </div>
                            <div className="flex flex-col bg-[#505C62] rounded-xl w-full px-4 py-3 space-y-1">
                              <p className=" font-primary-rg text-[12px] leading-4 text-textLightGrey">{exercise?.resistance_name}</p>
                              <p className=" font-primary-rg text-[12px] leading-4 text-textLightGrey">{getResistanceValue(exercise)}</p>
                            </div>
                            <div className="flex flex-col bg-[#505C62] rounded-xl w-full px-4 py-3 space-y-1">
                              <p className="font-primary-rg text-[12px] leading-4 text-textLightGrey">{exercise?.trackingname}</p>
                              <p className="font-primary-rg text-[12px] leading-4 text-textLightGrey">
                                {exercise?.lastExercise ? getBestTrackingValue(exercise?.lastExercise?.Sets) : 8}
                              </p>
                            </div>
                          </div>
                        )}

                        {/* ----------------- Divider --------------------- */}
                        <div className="flex w-full h-[1px] bg-[#505C62]"></div>
                        <div className="space-y-2">
                          <p className="font-primary-rg text-[14px] text-white leading-4">{t('EXERCISE_INFO')}</p>
                          <div className="flex justify-between w-full h-full space-x-2 rtl:space-x-reverse">
                            <div className="flex flex-col w-full rounded-lg p-2 gap-2 bg-[#2e5168]">
                              <p className="font-primary-rg text-[12px] text-white leading-4 w-full">{`${t('TRACKING_TYPE')} : ${
                                exercise?.trackingname
                              }`}</p>
                              <p className="font-primary-rg text-[12px] text-white leading-4 w-full">{`${t('RESISTANCE_TYPE')} : ${
                                exercise?.resistance_type
                              }`}</p>
                              <p className="font-primary-rg text-[12px] text-white leading-4 w-full">{`${t(
                                'MUSCLES'
                              )} : ${exercise?.ExerciseMuscles?.map((item) => item?.Muscle?.muscleName).join(', ')}`}</p>
                            </div>
                            {exercise?.lastExercise ? (
                              <div className="flex flex-col justify-between w-full h-full rounded-lg space-y-2">
                                <div className="flex bg-[#41565F] rounded-lg text-white font-thin p-2">
                                  <p className="font-primary-rg text-[12px] text-white leading-4 w-full">{`${t('LAST_PERFORMED')} : ${moment(
                                    exercise?.lastExercise?.date
                                  ).format('DD.MM.YYYY')}`}</p>
                                </div>
                                <div className="bg-[#41565F] rounded-lg p-2">
                                  <p className="font-primary-rg text-[12px] text-white leading-4 w-full">{`${t('TAGS')} : ${
                                    exercise?.tags || ''
                                  }`}</p>
                                </div>
                              </div>
                            ) : (
                              <div className="flex flex-col w-full rounded-lg p-2 gap-2 bg-[#41565F]">
                                <p className="font-primary-rg text-[12px] text-white leading-4 w-full">{`${t('TAGS')} : ${exercise?.tags || ''}`}</p>
                                <p className="font-primary-rg text-[12px] text-white leading-4 w-full">{`${t('MACHINE')} : ${
                                  exercise?.Machine ? exercise?.Machine?.machineName : ''
                                }`}</p>
                                <p className="font-primary-rg text-[12px] text-white leading-4 w-full">{`${t('BOOST_WEIGHT')} : ${
                                  exercise?.Machine?.plateWeight || ''
                                } (${exercise?.Machine ? exercise?.Machine?.boostWeight : ''})`}</p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}
