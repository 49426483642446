import { Menu, Transition } from '@headlessui/react'
import { Images } from 'assets'
import { Fragment, memo } from 'react'

const DropdownItem = ({ opt, setValue, t, dir, isSlots, isTime }) => (
  <div>
    <Menu.Item>
      {({ active }) => (
        <button
          onClick={() => setValue(opt)}
          className={`${active ? 'bg-[#444d53] text-textLightGrey' : 'text-textLightGrey'} group flex w-full ${
            dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
          } items-center px-2 py-2 text-[12px]`}
        >
          {isSlots ? opt : t(opt)} {isTime && t('MINUTS')}
        </button>
      )}
    </Menu.Item>
  </div>
)

const Dropdown = memo(({ value, setValue, options, title, disable, t, dir, className, isTime, height, isSlots }) => {
  return (
    <div
      className={`flex flex-col ${isSlots ? '' : 'h-[56px]'} rounded-[12px] mb-2 mt-1 ${className ? className : 'w-full'} ${
        disable && 'opacity-50'
      } group`}
    >
      <Menu as="div" className={`relative inline-block text-left ${isSlots ? '' : 'h-[56px]'} `}>
        <div className=" bg-textFieldColor rounded-[12px] group-hover:bg-borderColor">
          {title && (
            <div
              className={`flex flex-row  justify-start ${
                dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
              } px-2 pt-1  text-textLightGrey text-[11px] `}
            >
              {title}
            </div>
          )}
          <Menu.Button
            className={`inline-flex w-full text-[12px] text-center items-center rounded-[12px] ${
              !disable ? 'bg-textFieldColor' : 'bg-textFieldColor'
            } px-2 py-2 font-medium text-textLightGrey ${dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} ${
              height && `h-[${height}]`
            } group-hover:bg-borderColor`}
            disabled={disable}
          >
            {value && isSlots ? value : t(value)} {isTime && t('MINUTS')}
            <img
              src={Images.DROPDOWNICON}
              alt=""
              height={20}
              width={20}
              className={`${dir === 'ltr' ? 'float-right ml-auto' : 'mr-auto'} cursor-pointer `}
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute w-full z-10 mt-2  origin-top-right divide-y divide-[#374349] rounded-xl bg-textFieldColor shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none  max-h-[400px] overflow-auto ">
            {options?.map((opt) => (
              <DropdownItem key={opt} opt={opt} setValue={setValue} t={t} dir={dir} isSlots={isSlots} isTime={isTime} />
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
})

export default Dropdown