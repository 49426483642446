import { useState } from 'react'

const SearchInput = ({
  name,
  control,
  value,
  error,
  errorMessage,
  required,
  fullWidth,
  className,
  area,
  multiline,
  rows,
  onChange,
  disabled,
  label,
  type,
  icon,
  placeholder,
  dir,
  radius,
}) => {
  const [isInputFocused, setIsInputFocused] = useState(false)
  const handleInputFocus = () => {
    setIsInputFocused(true)
  }

  const handleInputBlur = () => {
    setIsInputFocused(false)
  }

  return (
    <>
      <div className={`flex flex-row  py-1 ${area && 'w-full'} `}>
        {label && (
          <label
            className={`flex flex-col py-1 text-white ${dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-[16px] justify-center  w-36  `}
          >
            {label}
          </label>
        )}
        <div
          className={`flex flex-row ${area && 'w-full h-[40px]'} items-center    ${radius} bg-textFieldColor ${
            isInputFocused && 'border-[2px] border-[#00639B]'
          }`}
          dir={dir}
        >
          {icon && <img src={icon} alt="" height={20} width={20} className={`m-3  items-center `} />}
          <input
            onChange={onChange}
            value={value}
            className={`${className} ${area ? area : 'w-[210px] h-[40px]'} ${radius} bg-textFieldColor text-textGrey text-[14px] ${
              dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
            } `}
            label={label}
            type={type ? type : 'text'}
            placeholder={placeholder ? placeholder : ''}
            required={required}
            fullWidth={fullWidth}
            disabled={disabled}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
          />
        </div>
      </div>
    </>
  )
}

export { SearchInput }
