import axiosInstance from 'api'
import { Images } from 'assets'
import CustomToastify from 'components/compounds/Toast'

import { gymAdminActions } from './gymAdminReducers'

export const getAllCoaches = (id, t, direction) => async (dispatch) => {
  try {
    if(id){
    dispatch(gymAdminActions.setLoader(true))
    await axiosInstance.get(`allCoaches/${id}`).then((response) => {
      dispatch(gymAdminActions.setLoader(false))
      dispatch(gymAdminActions.setAllCoaches(response.result))
    })
  }
  } catch (e) {
    dispatch(gymAdminActions.setLoader(false))

    return console.error(e.message)
  }
}

export const createCoach = (data, action, t, direction, id) => async (dispatch) => {
  try {
    dispatch(gymAdminActions.setLoader(true))
    await axiosInstance.post(`/CreateCoach`, data).then((response) => {
      dispatch(gymAdminActions.setLoader(false))
      dispatch(getAllCoaches(id, t, direction))
      CustomToastify(t('COACH_CREATED'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
      action && action()
    })
  } catch (e) {
    dispatch(gymAdminActions.setLoader(false))
    if (e.code === 502) {
      CustomToastify(t('EMAIL_ALREADY_EXIST'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    } else {
      CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    }
    return console.error(e.message)
  }
}
export const getAllTrainees =
  (id, t, direction, filter = 'all') =>
  async (dispatch) => {
    try {
      dispatch(gymAdminActions.setLoader(true))

      await axiosInstance
        .get(`getTrainees/${id}`, { params: { query: filter } })
        .then((response) => {
          dispatch(gymAdminActions.setAllTrainees(response.result.data))
        })
        .finally(() => {
          dispatch(gymAdminActions.setLoader(false))
        })
    } catch (e) {
      dispatch(gymAdminActions.setLoader(false))
      CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return console.error(e.message)
    }
  }
export const createTrainee = (data, action, t, direction, id, traineeName) => async (dispatch) => {
  try {
    dispatch(gymAdminActions.setLoader(true))
    await axiosInstance.post(`/CreateTrainee`, data).then((response) => {
      dispatch(gymAdminActions.setLoader(false))
      if (id) {
        dispatch(getAllTrainees(id, t, direction))
      }
      CustomToastify(t('TRAINEE_CREATED_SUCCESSFULLY'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
      action && action(response?.result?.id, traineeName)
    })
  } catch (e) {
    dispatch(gymAdminActions.setLoader(false))

    if (e.code === 502) {
      CustomToastify(t('EMAIL_ALREADY_EXIST'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    } else {
      CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    }
    return console.error(e.message)
  }
}

export const createTraineeBySignUP = (data, action, t, direction, id) => async (dispatch) => {
  try {
    dispatch(gymAdminActions.setLoader(true))
    await axiosInstance.post(`/createTraineeBySignUP`, data).then((response) => {
      dispatch(gymAdminActions.setLoader(false))
      if (id) {
        dispatch(getAllTrainees(id, t, direction))
      }
      CustomToastify(t('TRAINEE_CREATED_SUCCESSFULLY'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
      action && action(response?.result?.id)
    })
  } catch (e) {
    dispatch(gymAdminActions.setLoader(false))

    if (e.code === 502) {
      CustomToastify(t('EMAIL_ALREADY_EXIST'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    } else {
      CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    }
    return console.error(e.message)
  }
}

export const getTraineeById = (id, t, direction) => async (dispatch) => {
  try {
    dispatch(gymAdminActions.setLoader(true))
    await axiosInstance
      .get(`getTraineeById/${id}`)
      .then((response) => {
        dispatch(gymAdminActions.setSingleTrainees(response.result))
      })
      .finally(() => {
        dispatch(gymAdminActions.setLoader(false))
      })
  } catch (e) {
    dispatch(gymAdminActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}
export const updateGymTrainee = (data, action, t, direction, id) => async (dispatch) => {
  try {
    let res
    dispatch(gymAdminActions.setLoader(true))
    await axiosInstance
      .put(`UpdateTrainee`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        res = response
        dispatch(getTraineeById(id, t, direction))
      })
      .finally(() => {
        dispatch(gymAdminActions.setLoader(false))
        if (res.code === 201) {
          CustomToastify(t('TRAINEE_UPDATED'), t('SENT_VERIFICATION_EMAIL'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
        } else {
          CustomToastify(t('TRAINEE_UPDATED'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
        }
        action && action()
      })
  } catch (e) {
    dispatch(gymAdminActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}

export const DeleteTraineeByGymAdmin = (data, t, direction, action) => async (dispatch) => {
  try {
    dispatch(gymAdminActions.setLoader(true))
    await axiosInstance.post(`deleteTraineeRequest`, data).then((response) => {
      dispatch(getAllTrainees(data?.gymId, t, direction))
      dispatch(gymAdminActions.setLoader(false))
      action && action()
      CustomToastify(t('TRAINEE_ACCOUNT_DELETED'), t('DELTEION_CONFIRMATION_SENT'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
    })
  } catch (e) {
    dispatch(gymAdminActions.setLoader(false))
    CustomToastify(t('ERROR_DELETING'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}

export const getCoachById = (id, t, direction) => async (dispatch) => {
  try {
    dispatch(gymAdminActions.setLoader(true))
    await axiosInstance
      .get(`getGymCoachById/${id}`)
      .then((response) => {
        dispatch(gymAdminActions.setCoachDetail(response.result))
      })
      .finally(() => {
        dispatch(gymAdminActions.setLoader(false))
      })
  } catch (e) {
    dispatch(gymAdminActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}
export const DeleteSingleCoachByGymAdmin = (id, t, direction, action, gymId) => async (dispatch) => {
  try {
    dispatch(gymAdminActions.setLoader(true))
    await axiosInstance.post(`deleteSingleCoach/${id}`).then((response) => {
      dispatch(getAllCoaches(gymId, t, direction))
      dispatch(gymAdminActions.setLoader(false))
      action && action()
      CustomToastify(t('COACH_DELETED_SUCCESSFULLY'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
    })
  } catch (e) {
    dispatch(gymAdminActions.setLoader(false))

    CustomToastify(t('ERROR_DELETING'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)

    return console.error(e.message)
  }
}

export const SuspendTrainee = (id, t, direction, action) => async (dispatch) => {
  try {
    dispatch(gymAdminActions.setLoader(true))
    await axiosInstance.post(`SuspendTrainee/${id}`).then((response) => {
      dispatch(gymAdminActions.setLoader(false))
      CustomToastify(t('TRAINEE_SUSPENDED'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
      action && action()
    })
  } catch (e) {
    dispatch(gymAdminActions.setLoader(false))
    if (e.code === 502) {
      CustomToastify(t('UNAUTHORIZED_ACTION'), t('VERIFICATION_WRONG'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    } else {
      CustomToastify(t('ERROR_GYM_SUSPENDED'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    }
    return console.error(e.message)
  }
}
export const UnSuspendTrainee = (id, t, direction, action) => async (dispatch) => {
  try {
    dispatch(gymAdminActions.setLoader(true))
    await axiosInstance.post(`UnSuspendGym/${id}`).then((response) => {
      dispatch(gymAdminActions.setLoader(false))
      CustomToastify(t('TRAINEE_UnSUSPENDED'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
      action && action()
    })
  } catch (e) {
    dispatch(gymAdminActions.setLoader(false))
    if (e.code === 502) {
      CustomToastify(t('UNAUTHORIZED_ACTION'), t('VERIFICATION_WRONG'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    } else {
      CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    }
    return console.error(e.message)
  }
}

export const UpdateCoach = (data, t, direction, action, gymId) => async (dispatch) => {
  try {
    dispatch(gymAdminActions.setLoader(true))
    await axiosInstance.put(`/UpdateCoach`, data).then((response) => {
      dispatch(gymAdminActions.setLoader(false))
      dispatch(getAllCoaches(gymId, t, direction))
      action && action()
      if (response.code === 201) {
        CustomToastify(t('COACH_UPDATED'), t('SENT_VERIFICATION_EMAIL'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
      } else {
        CustomToastify(t('COACH_UPDATED'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
      }
    })
  } catch (e) {
    dispatch(gymAdminActions.setLoader(false))

    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)

    return console.error(e.message)
  }
}

export const getTraineeFilter = (id) => async (dispatch) => {
  try {
    axiosInstance.get(`traineeFilterByQty/${id}`).then((res) => {
      dispatch(gymAdminActions.setTraineeFilter(res.result.result))
    })
  } catch (error) {
    console.log('error', error)
  }
}

export const getExerciseInfoById = (id, t, direction) => async (dispatch) => {
  try {
    dispatch(gymAdminActions.setLoader(true))
    await axiosInstance
      .get(`getExercisesInfoById/${id}`)
      .then((response) => {
        dispatch(gymAdminActions.setExerciseInfo(response?.result?.data))
      })
      .finally(() => {})
  } catch (e) {
    dispatch(gymAdminActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}
export const getbandByGymId = (id, t, direction) => async (dispatch) => {
  try {
    dispatch(gymAdminActions.setLoader(true))
    await axiosInstance
      .get(`getbandByGymId/${id}`)
      .then((response) => {
        dispatch(gymAdminActions.setBands(response.result.machine))
      })
      .finally(() => {
        dispatch(gymAdminActions.setLoader(false))
      })
  } catch (e) {
    dispatch(gymAdminActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}
