import React, { useState } from 'react'
import { Modal } from 'components/elements/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { SuspendGym } from 'store/userSlice/userActions'

export default function SuspendModal({
  setOpenModal,
  openModal,
  notCrossIcon,
  t,
  showPass,
  ID,
  direction,
  des,
  title,
  action,
  handleSubmit,
  confirmBtnText,
}) {
  const { id } = useSelector((state) => state.auth.user)
  const dispatch = useDispatch()
  const [password, setPassword] = useState('')
  const [error, setError] = useState(false)

  const handleClose = () => {
    setError(false)
    showPass && setPassword('')
    setOpenModal(false)
  }

  const Suspend = () => {
    if (password.length > 0) {
      let data = {
        currentUserID: id,
        password: password,
      }
      dispatch(SuspendGym(ID, t, direction, action, data))
    } else {
      setError(true)
    }
  }
  return (
    <Modal open={openModal} className="p-1" handleClose={handleClose} notCrossIcon={true}>
      <div className=" p-2 w-80  bg-checkBoxGray rounded-[16px]  sm:p-3">
      <p className={`mb-4 text-[#FFF] text-[16px] ${direction === 'ltr' ? 'font-primary-md' : 'font-primary-he-md'}`}>{title}</p>
        <p className={`mb-6 text-[#FFF] text-[14px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>{des}</p>
        {showPass && (
          <div className="flex flex-col py-1 w-full">
            <div className="flex flex-row w-full h-[56px] items-center justify-between  rounded-[12px] bg-textFieldColor">
              <input
                value={password}
                className={` w-full h-[56px] rounded-[12px] bg-textFieldColor px-2 `}
                type={'password'}
                placeholder={'*******'}
                onChange={(e) => {
                  setError(false)
                  setPassword(e.target.value)
                }}
              />
            </div>
            {error && (
              <p className={`form-errors flex flex-col px-1 ${direction === 'rtl' ? 'text-right' : 'text-left'} `}>{t('PASSWORD_REQUIRED')}</p>
            )}
          </div>
        )}
        <div className="flex justify-between items-center mt-2">
          <button
            type="submit"
            className="w-full h-10  text-[10px] p-1 text-center text-white bg-[#100e0e] rounded-[12px] hover:bg-[#DE3730] focus:ring-4 focus:outline-none dark:bg-[#DE3730] dark:focus:bg-[#DE3730]"
            onClick={handleSubmit ?? Suspend}
          >
            {confirmBtnText ? confirmBtnText : t('SUSPEND_AGREEMENT')}
          </button>
        </div>
      </div>
    </Modal>
  )
}
