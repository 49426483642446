import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

export default function Options({ label, name, value, setValue, translate, data, disabled, className }) {
  const { direction } = useSelector((state) => state.language)
  const { t } = useTranslation()

  return (
    <div className={`flex  flex-col px-1 ${disabled && 'opacity-50'}`}>
      {label && (
        <label
          className={`flex flex-col py-1 text-white ${
            direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
          } text-[16px] justify-center  w-36 `}
        >
          {label}
        </label>
      )}
      <div className={`flex flex-row w-full h-[56px] items-center ${className ? className : 'gap-4'}  rounded-[12px]`}>
        {data.map((item, index) => {
          return (
            <div
              key={index}
              className={`py-2 px-4 text-[14px] text-center text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} ${
                value === item ? 'bg-borderActive' : 'border-[1px] border-borderActive'
              }  rounded-[12px] cursor-pointer ${disabled === true ? 'pointer-events-none' : ''}`}
              onClick={() => setValue(item)}
            >
              {t(item)}
            </div>
          )
        })}
      </div>
    </div>
  )
}
