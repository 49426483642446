import { Tooltip } from '@material-tailwind/react'
import { Images } from 'assets'
import { Modal } from 'components/compounds/Modal'
import ImagePicker from 'components/elements/ImagePicker'
import GenderSelection from 'components/home/GenderSelection'
import { constants } from 'constant'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import 'react-datepicker/dist/react-datepicker.css'

import { yupResolver } from '@hookform/resolvers/yup'
import DatePickerField from 'components/compounds/DatePickerField'
import DeleteModal from 'components/compounds/DeleteModal'
import Dropdown from 'components/compounds/DropDown'
import { LabelInput } from 'components/compounds/LabelInput'
import { Loader } from 'components/compounds/Loader'
import CustomToastify from 'components/compounds/Toast'
import PrimaryButton from 'components/elements/PrimaryButton'
import 'react-clock/dist/Clock.css'
import { useForm } from 'react-hook-form'
import { createCoach, DeleteSingleCoachByGymAdmin, getCoachById, UpdateCoach } from 'store/gymAdminSlice/gymAdminActions'
import { generateTimeValues } from 'utils'
import { coachSchema } from 'validations/formsValidations'

export default function CreateCoaches({ show, setShow, coachID, setCoachId }) {
  const { loader } = useSelector((state) => state.user)
  const { currentCoach, loader: gymLoader } = useSelector((state) => state.gymAdmin)
  // eslint-disable-next-line
  const [disable, setDisable] = useState(false)
  const [selectedDate, setSelectedDate] = useState(null)
  const [showDatePicker, setShowDatePicker] = useState(false)

  const [file, setFile] = useState(null)
  const { t } = useTranslation()
  const methods = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(coachSchema),
    defaultValues: {
      fullName: '',
      phoneNo: '',
      email: '',
    },
  })
  const { reset, control, handleSubmit, formState, setValue } = methods
  const { errors } = formState

  const dispatch = useDispatch()
  const { direction } = useSelector((state) => state.language)
  const { customerDetail } = useSelector((state) => state.user)

  const [imageURL, setImageURL] = useState(null)
  const [imageError, setImageError] = useState(false)
  const [selectedGender, setSelectedGender] = useState(constants.GENDER[0])
  const [openModal, setOpenModal] = useState(false)

  const handleClose = () => {
    reset({
      fullName: '',
      phoneNo: '',
      email: '',
    })
    setSelectedGender(constants.GENDER[0])
    setShow(false)
    setCoachId && setCoachId(null)
  }

  const action = () => {
    setFile(null)
    handleClose()
  }
  const onSubmit = async (values) => {
    const hasError = Object.values(timeSlots).some((day) => day.timeLines.some((line) => line.error))

    let outputData = {}
    if (hasError) {
      CustomToastify(t('TIME_SLOTS_ERROR'), t('TIME_SLOTS_CHANGE'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    } else {
      for (let day in timeSlots) {
        outputData[day] = []

        if (timeSlots[day].default) {
          for (let timeline of timeSlots[day].timeLines) {
            outputData[day].push({ start: timeline.start, end: timeline.end })
          }
        }
      }
      let data = {
        ...values,
        timeSlots: JSON.stringify(outputData),
        gender: selectedGender?.value,
        birthday: selectedDate ? selectedDate : null,
        gymId: customerDetail.id,
      }

      const formData = new FormData()
      if (file) {
        formData.append('file', file)
      }
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key])
      })
      dispatch(createCoach(formData, action, t, direction, customerDetail.id))
    }
  }
  const onUpdate = async (values) => {
    const hasError = Object.values(timeSlots).some((day) => day.timeLines.some((line) => line.error))

    let outputData = {}
    if (hasError) {
      CustomToastify(t('TIME_SLOTS_ERROR'), t('TIME_SLOTS_CHANGE'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    } else {
      for (let day in timeSlots) {
        outputData[day] = []

        if (timeSlots[day].default) {
          for (let timeline of timeSlots[day].timeLines) {
            outputData[day].push({ start: timeline.start, end: timeline.end })
          }
        }
      }
      let data = {
        ...values,
        timeSlots: JSON.stringify(outputData),
        gender: selectedGender?.value,
        birthday: selectedDate ? selectedDate : null,
        coachId: coachID,
        userId: currentCoach?.User?.id,
      }
      const formData = new FormData()
      if (file) {
        formData.append('file', file)
      }
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key])
      })
      dispatch(UpdateCoach(formData, t, direction, action, customerDetail?.id))
    }
  }

  const [timeSlots, setTimeSlots] = useState({
    sunday: { start: '06:00', end: '23:00', default: true, timeLines: [{ start: '06:00', end: '23:00', error: false }] },
    monday: { start: '06:00', end: '23:00', default: true, timeLines: [{ start: '06:00', end: '23:00', error: false }] },
    tuesday: { start: '06:00', end: '23:00', default: true, timeLines: [{ start: '06:00', end: '23:00', error: false }] },
    wednesday: { start: '06:00', end: '23:00', default: true, timeLines: [{ start: '06:00', end: '23:00', error: false }] },
    thursday: { start: '06:00', end: '23:00', default: true, timeLines: [{ start: '06:00', end: '23:00', error: false }] },
    friday: { start: '06:00', end: '16:00', default: true, timeLines: [{ start: '06:00', end: '16:00', error: false }] },
    saturday: { start: '07:00', end: '16:00', default: false, timeLines: [{ start: '07:00', end: '16:00', error: false }] },
  })

  useEffect(() => {
    if (coachID) {
      dispatch(getCoachById(coachID, t, direction))
    }
    // eslint-disable-next-line
  }, [coachID])
  useEffect(() => {
    if (currentCoach && coachID) {
      setValue('fullName', currentCoach?.User?.firstName)
      setValue('email', currentCoach?.User?.email)
      setValue('phoneNo', currentCoach?.User?.phone)
      if (currentCoach?.User.birthday) {
        const date = new Date(currentCoach?.User.birthday)
        setSelectedDate(date)
      }

      setImageURL(currentCoach?.User?.avatar)
      constants.GENDER.forEach((item) => {
        if (item.value === currentCoach?.User?.gender) {
          setSelectedGender(item)
        }
      })

      const updatedTimeSlots = { ...timeSlots }
      for (const [day, timelines] of Object.entries(currentCoach?.map)) {
        if (timelines.length > 0) {
          updatedTimeSlots[day].timeLines = timelines.map((timeline) => ({
            start: timeline.start,
            end: timeline.end,
            error: false,
          }))

          updatedTimeSlots[day].start = timelines[0].start
          updatedTimeSlots[day].end = timelines[0].end

          updatedTimeSlots[day].default = true
        } else {
          updatedTimeSlots[day].default = false
        }
      }

      setTimeSlots(updatedTimeSlots)
    }
    // eslint-disable-next-line
  }, [currentCoach])

  const notify = () => {
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
  }

  const handleDayToggle = (check, day) => {
    const deepCopyTimeSlots = JSON.parse(JSON.stringify(timeSlots))

    setTimeSlots({
      ...deepCopyTimeSlots,
      [day]: {
        ...deepCopyTimeSlots[day],
        default: check ? true : false,
      },
    })
  }
  const addTimeSlot = (index, day) => {
    const deepCopyTimeSlots = JSON.parse(JSON.stringify(timeSlots))
    setTimeSlots({
      ...deepCopyTimeSlots,
      [day]: {
        ...deepCopyTimeSlots[day],
        timeLines: [...deepCopyTimeSlots[day].timeLines, { start: deepCopyTimeSlots[day].start, end: deepCopyTimeSlots[day].end, error: true }],
      },
    })
  }
  const removeSlot = (index, slot) => {
    const deepCopyTimeSlots = JSON.parse(JSON.stringify(timeSlots))
    deepCopyTimeSlots[slot].timeLines.splice(index, 1)
    if (deepCopyTimeSlots[slot].timeLines.length === 0) {
      deepCopyTimeSlots[slot].default = false
    }
    setTimeSlots(deepCopyTimeSlots)
  }

  function checkOverlapAndSetError(timeSlots) {
    if (timeSlots?.length === 1) {
      const start = new Date(`1970-01-01T${timeSlots[0].start}`)
      const end = new Date(`1970-01-01T${timeSlots[0].end}`)

      const overlap = end <= start

      if (overlap) {
        timeSlots[0].error = true
      } else {
        timeSlots[0].error = false
      }
    } else {
      for (let i = 0; i < timeSlots.length; i++) {
        for (let j = i + 1; j < timeSlots.length; j++) {
          const start1 = new Date(`1970-01-01T${timeSlots[i].start}`)
          const end1 = new Date(`1970-01-01T${timeSlots[i].end}`)
          const start2 = new Date(`1970-01-01T${timeSlots[j].start}`)
          const end2 = new Date(`1970-01-01T${timeSlots[j].end}`)

          const mainLoopOverlap = end1 <= start1
          const innerLoopOverLap = end2 <= start2
          const overlap = start1 > end2 || start2 < end1 || mainLoopOverlap || innerLoopOverLap
          if (overlap) {
            if (mainLoopOverlap) {
              timeSlots[i].error = true
            } else if (innerLoopOverLap) {
              timeSlots[j].error = true
            } else {
              timeSlots[i].error = true
              timeSlots[j].error = true
            }
          } else {
            timeSlots[i].error = false
            timeSlots[j].error = false
          }
        }
      }
    }
  }

  const handleTimeRangeChange = (time, type, day, index) => {
    const deepCopyTimeSlots = JSON.parse(JSON.stringify(timeSlots))
    const deepCopyTimeLines = JSON.parse(JSON.stringify(timeSlots[day].timeLines))

    deepCopyTimeLines[index][type] = time
    checkOverlapAndSetError(deepCopyTimeLines)

    setTimeSlots({
      ...deepCopyTimeSlots,
      [day]: {
        ...deepCopyTimeSlots[day],
        timeLines: deepCopyTimeLines,
      },
    })
  }

  const deleteAction = () => {
    setShow(false)
    setOpenModal(false)
  }

  const deleteCoach = () => {
    dispatch(DeleteSingleCoachByGymAdmin(currentCoach?.User?.id, t, direction, deleteAction, customerDetail?.id))
  }

  const FooterComponent = () => {
    return (
      <div className="flex rounded-b-[28px] flex-row h-16 items-center px-4 justify-end xl:px-6 2xl:px-12 border-t-[1px] border-borderColor absolute bottom-0 w-full bg-sidebar">
        <PrimaryButton
          text={t('CREATE_NEW_COACH')}
          type="submit"
          disabled={gymLoader}
          isIcon={false}
          onClick={handleSubmit(onSubmit)}
          className="h-[44px] px-4 rounded-[12px] shadow-2xl"
          bgColor="bg-borderActive hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
          textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
        />
      </div>
    )
  }

  const EditFooterComponent = () => {
    return (
      <div className="flex rounded-b-[28px] flex-row gap-x-2 h-16 items-center px-4 justify-between xl:px-6 2xl:px-12 border-t-[1px] border-borderColor absolute bottom-0 w-full bg-sidebar">
        {!currentCoach?.isGymAdmin && (
          <PrimaryButton
            text={t('DELETE_A_COACH')}
            type="submit"
            disabled={gymLoader}
            isIcon={false}
            onClick={() => {
              setOpenModal(!openModal)
            }}
            className="h-[44px] rounded-[12px] shadow-2xl"
            bgColor=" hover:bg-[#2e373c] focus:bg-[#2e373c] active:bg-[#2e373c] "
            textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
          />
        )}
        <PrimaryButton
          text={t('SAVING_CHANGES')}
          type="submit"
          disabled={gymLoader}
          isIcon={false}
          onClick={handleSubmit(onUpdate)}
          className="h-[44px] px-4 rounded-[12px] shadow-2xl"
          bgColor="bg-[#2e373c] hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
          textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
        />
      </div>
    )
  }
  return (
    <>
      <Modal
        className={`h-full w-[480px] ${direction === 'ltr' ? 'rounded-l-3xl' : 'rounded-r-3xl'}   `}
        style={{ zIndex: 1000 }}
        childrenClass="overflow-auto "
        open={show}
        title={t('ADD_NEW_COACH')}
        handleClose={handleClose}
        handleSubmit={handleSubmit(onSubmit)}
        handleNotify={notify}
        loader={loader}
        disabled={disable}
        direction={direction}
        footer={coachID ? <EditFooterComponent /> : <FooterComponent />}
      >
        {gymLoader ? (
          <Loader className="" size="w-8 h-8" fill="#003353" />
        ) : (
          <div className="w-full">
            <ImagePicker
              placeholder={t('COACH_PHOTO')}
              imageURL={imageURL}
              setImageURL={setImageURL}
              imageError={imageError}
              errorMessage={t('IMAGE_REQUIRED')}
              setImageError={setImageError}
              setSelectedFile={setFile}
              selectedFile={file}
              styles={`w-[140px] h-[140px] items-center  justify-center mt-4  rounded-[50%] ${disable && 'opacity-50'} ${
                !imageURL && 'border-dashed border-[1px]'
              }`}
              disabled={disable}
            />

            <LabelInput
              name="fullName"
              type="text"
              label={t('FULL_NAME')}
              placeholderValue={t('FULL_NAME')}
              control={control}
              required
              fullWidth
              error={errors?.fullName ? true : false}
              errorMessage={errors?.fullName?.message}
              dir={direction}
              disabled={disable}
              limit={20}
            />

            <LabelInput
              name="phoneNo"
              type="number"
              label={t('MOBILE_NO')}
              control={control}
              placeholderValue={'0520000000'}
              required
              fullWidth
              error={errors?.phoneNo ? true : false}
              errorMessage={errors?.phoneNo?.message}
              dir={direction}
              disabled={disable}
            />
            <LabelInput
              name="email"
              type="text"
              label={t('EMAIL_ADDRESS_AS_USERNAME')}
              placeholderValue={'aaa@gmail.com'}
              control={control}
              required
              fullWidth
              error={errors?.email ? true : false}
              errorMessage={errors?.email?.message}
              dir={direction}
              disabled={disable}
            />
            <GenderSelection
              name="gender"
              data={constants.GENDER}
              selectedGender={selectedGender}
              setSelectedGender={setSelectedGender}
              disabled={disable}
            />
            <DatePickerField
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              showDatePicker={showDatePicker}
              setShowDatePicker={setShowDatePicker}
              required={false}
              dir={direction}
              t={t}
              title="DOB"
            />
            <div className="flex flex-row py-2">
              <div className="text-white text-[14px] font-primary-rg">{t('WORKING_DAYS_PER_WEEK')}</div>

              <Tooltip
                content={t('INSERTION_WO_TOOLTIP')}
                placement="top"
                className={`tooltip ltr:font-primary-rg rtl:font-primary-he-rg z-[99999] `}
              >
                <div>
                  <img src={Images.Info} alt="" height={35} width={35} className={` px-2 items-center cursor-pointer`} />
                </div>
              </Tooltip>
            </div>
            <div className="flex flex-col w-full mb-10 overflow-auto bg-[#162127] rounded-[12px]">
              {Object.entries(timeSlots).map(([slot, values]) => {
                return (
                  <div className="flex flex-col w-full justify-between items-center  px-2">
                    {values?.timeLines && values?.timeLines?.length > 0 && (
                      <>
                        {values?.timeLines.map((data, index) => {
                          const timeLines = generateTimeValues('06:00', slot === 'friday' || slot === 'saturday' ? '16:00' : '23:00')
                          return (
                            <div className="flex flex-row justify-between w-full items-center my-2 ">
                              {index !== 0 ? (
                                <div className="flex items-center justify-center w-32  ">
                                  <img
                                    alt="profile_img"
                                    src={Images.Close}
                                    width={30}
                                    height={30}
                                    onClick={() => {
                                      removeSlot(index, slot)
                                    }}
                                    className=" cursor-pointer"
                                  />
                                </div>
                              ) : (
                                <label key={slot} className="inline-flex px-1 items-center  w-32 ">
                                  <div>
                                    <input
                                      type="checkbox"
                                      checked={values?.default}
                                      className="custom_checkbox flex h-5 w-5 items-center  form-check-input"
                                      onChange={(e) => handleDayToggle(e.target.checked, slot)}
                                    />
                                  </div>
                                  <span className={`mx-2 text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>
                                    {t(slot)}
                                  </span>
                                </label>
                              )}
                              <div
                                className={`flex flex-col px-4  bg-[#29343a] rounded-[12px] ${data.error && 'border-[2px] border-error'}`}
                                dir="ltr"
                              >
                                <p className={`${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>{t('WORK_BTW_HOURS')}</p>

                                <div className="flex justify-between items-center">
                                  <Dropdown
                                    options={timeLines}
                                    className="w-16"
                                    h="!h-10"
                                    value={data.start}
                                    setValue={(val) => {
                                      handleTimeRangeChange(val, 'start', slot, index)
                                    }}
                                    disable={false}
                                    t={t}
                                    dir={direction}
                                    isSlots={true}
                                  />

                                  <p className={`${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>-</p>

                                  <Dropdown
                                    options={timeLines}
                                    className="w-16"
                                    value={data.end}
                                    setValue={(val) => {
                                      handleTimeRangeChange(val, 'end', slot, index)
                                    }}
                                    disable={false}
                                    t={t}
                                    dir={direction}
                                    isSlots={true}
                                  />
                                </div>
                              </div>
                              {values.timeLines.length === index + 1 && values.timeLines.length < 3 ? (
                                <div
                                  className={`flex flex-row items-center  px-2 gap-1 cursor-pointer w-20 ${data.error && 'pointer-events-none'}`}
                                  onClick={() => {
                                    addTimeSlot(index, slot)
                                  }}
                                >
                                  <span className={`${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>{t('ADD')}</span>
                                  <div>
                                    <img alt="profile_img" src={Images.Plus} width={12} height={12} className=" cursor-pointer" />
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="w-20 "
                                  onClick={() => {
                                    addTimeSlot(index, slot)
                                  }}
                                ></div>
                              )}
                            </div>
                          )
                        })}
                      </>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </Modal>
      <DeleteModal
        style={{ zIndex: 2000 }}
        setOpenModal={setOpenModal}
        openModal={openModal}
        t={t}
        ID={currentCoach?.User?.id}
        direction={direction}
        des={t('DELETE_COACH_DES')}
        title={t('DELETE_COACH')}
        action={action}
        handleSubmit={deleteCoach}
        showPass={false}
      />
    </>
  )
}
