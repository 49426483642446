// import React, { createContext, useState, useEffect } from "react";
// import { useSelector } from "react-redux";
// import io from "socket.io-client";
// export const SocketContext = createContext();
// export const SocketProvider = ({ children }) => {
//   const { user } = useSelector((state) => state.auth);
//   const [socket, setSocket] = useState(null);

//   let userId = user?.role === "gym_admin" ? user?.id.toString() : "";
//   useEffect(() => {
//     const socketUrl = process.env.REACT_APP_SOCKET_URL;

//     const socketInstance = io(socketUrl, {
//       transports: ["websocket"], // Only use WebSocket, no polling fallback
//     });
//     setSocket(socketInstance);
//     socketInstance.on("connect", () => {});

//     socketInstance.on("connect_error", (err) => {});

//     if (userId && socketInstance) {
//       socketInstance.emit("joinRoom", userId);
//     }
//     return () => {
//       socketInstance.emit("leaveRoom", userId);
//       socketInstance.removeAllListeners();
//       socketInstance.disconnect();
//     };
//   }, [userId]);
//   return <SocketContext.Provider value={{ socket }}>{children}</SocketContext.Provider>;
// };
