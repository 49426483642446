import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Images } from 'assets'
import { SearchInput } from 'components/compounds/SearchInput'
import PrimaryButton from 'components/elements/PrimaryButton'

import { getAllCoachTrainees } from 'store/coachSlice/coachActions'

export default function CoachTraineesListHeader(props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { toggelModal, setSearchText } = props
  const { direction } = useSelector((state) => state.language)
  const [searchInput, setSearchInput] = useState('')

  // eslint-disable-next-line
  const [temp1, setTemp1] = useState({
    COACHES: [],
    ACTIVE_PROGRAM: [],
    ACTIVE_TRAINEE: [],
  })

  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchText(searchInput)
    }, 500)

    return () => {
      clearTimeout(handler)
    }
    // eslint-disable-next-line
  }, [searchInput])

  const setSearch = (event) => {
    setSearchInput(event.target.value)
  }

  useEffect(() => {
    const getTrainees = async () => {
      if (temp1.COACHES.length > 0 || temp1.ACTIVE_PROGRAM.length > 0 || temp1.ACTIVE_TRAINEE.length > 0) {
      } else {
        dispatch(getAllCoachTrainees(t, direction))
      }
    }
    getTrainees()
    // eslint-disable-next-line
  }, [temp1])

  return (
    <div className="flex w-full flex-col">
      <div className="flex flex-row w-full px-8 py-4 justify-between items-center ">
        <SearchInput
          radius={'rounded-[12px]'}
          placeholder={t('SEARCH')}
          className="flex flex-1"
          disableUnderline
          fullWidth
          value={searchInput}
          inputProps={{
            'aria-label': 'Search',
          }}
          onChange={(ev) => setSearch(ev)}
          icon={Images.Search}
        />
        <div className="flex gap-4 items-center">
          <div className="">
            <img src={Images.Bell} alt="" height={40} width={40} className={` px-2 items-center`} />
          </div>
          <PrimaryButton
            text={t('ADD_A_TRAINEES')}
            type="submit"
            disabled={false}
            isIcon={true}
            icon={Images.Plus}
            onClick={() => {
              toggelModal()
            }}
            className="w-[177px] h-[44px] rounded-[12px] shadow-2xl "
            bgColor="bg-sidebarhover hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
            textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
            wid={15}
            ht={15}
          />
        </div>
      </div>
    </div>
  )
}
