import { Images } from 'assets'
import Dropdown from 'components/compounds/DropDown'
import DropDownWithSearch from 'components/compounds/DropDownWithSearch'
import { constants } from 'constant'

export default function MuscleSelectionDropDown({ id, data, value, setValue, options, title, disable, t, dir, handleRemove }) {
  return (
    <div className="flex flex-row w-full gap-x-4 ">
      <DropDownWithSearch
        id={id}
        options={[...options, { muscleName: null, id: null }]}
        data={data}
        className={`my-2 ${id !== 0 ? 'w-1/2 ' : 'w-full'}`}
        title={id === 0 ? title : null}
        value={value?.name}
        setValue={(muscle) => {
          const foundObject = options.find((obj) => obj.muscleName === muscle)
          if (foundObject) {
            setValue(
              data.map((m, i) => (i === id ? { id: foundObject?.id, name: muscle, type: m.type, bodyPartId: foundObject?.muscle_model_id } : m))
            )
          } else {
            if (muscle === null) {
              if (data.length === 1 || id === 0) {
                setValue(data.map((m, i) => (i === id ? { id: null, name: muscle, type: m.type } : m)))
              } else {
                handleRemove(id)
              }
            } else {
              setValue(data.map((m, i) => (i === id ? { id: null, name: muscle, type: m.type, new: true } : m)))
            }
          }
        }}
        disable={disable}
        t={t}
        dir={dir}
        isRequired={true}
        placeHolder={title}
      />

      {id !== 0 && (
        <div className="flex w-1/2 gap-x-4 justify-center items-center">
          <Dropdown
            options={constants.MUSCLE_TYPE}
            className="my-2 w-full "
            value={value?.type}
            setValue={(type) => {
              setValue(data.map((m, i) => (i === id ? { ...m, type } : m)))
            }}
            disable={disable}
            t={t}
            dir={dir}
            height="50px"
          />
          <div>
            <img
              src={Images.CloseCircleWhite}
              alt=""
              height={20}
              width={20}
              className={`${dir === 'ltr' ? 'float-right ml-auto' : 'mr-auto'} cursor-pointer ${disable && 'opacity-50'} `}
              onClick={() => {
                if (!disable) {
                  if (data.length === 1 || id === 0) {
                    setValue(data.map((m, i) => (i === id ? { id: null, name: null, type: m.type } : m)))
                  } else {
                    handleRemove(id)
                  }
                }
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}
