import { yupResolver } from '@hookform/resolvers/yup'
import { Loader } from 'components/compounds/Loader'
import CreateGym from 'components/home/CreateGym'
import ListHeader from 'components/List/ListHeader'
import ListTable from 'components/List/ListTable'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { gymSchema } from '../validations/formsValidations'

const Homepage = () => {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)
  const [searchText, setSearchText] = useState()
  const [gymID, setGymID] = useState(null)
  const [userID, setUserID] = useState(null)
  const { direction } = useSelector((state) => state.language)

  const { customers, loader } = useSelector((state) => state.user)
  const headerRows = {
    data: [
      {
        id: 'serialNo',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'custom',
        disablePadding: false,
        label: '#',
        sort: true,
        className: `w-10 text-white ${direction === 'rtl' ? 'text-right' : 'text-left'} `,
        CustomComponent: ({ item }) => {
          return <p className={`${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>{item}</p>
        },
      },
      {
        id: 'Gym.gymName',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'Objtext',
        disablePadding: false,
        label: 'BRNAD_NAME',
        sort: true,
        className: `w-28 text-white truncte  ${direction === 'rtl' ? 'text-right' : 'text-left'} `,
      },

      {
        id: 'isActive',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'textCustom',
        disablePadding: false,
        label: 'ACTIVE',
        sort: true,
      },
      {
        id: 'Gym.subscriptionStatus',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'ObjtextStatus',
        disablePadding: false,
        label: 'REGISTERATION_STATUS',
        sort: true,
        CustomComponent: ({ item }) => <p>{0}</p>,
      },
      {
        id: 'trainees',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'text',
        disablePadding: false,
        label: 'ACCOUNT_OF_TRAINEE',
        sort: true,
      },
      {
        id: 'trainingPerform',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'text',
        disablePadding: false,
        label: 'NO_OF_TRAINING_PERFORMED',
        sort: true,
        className: `w-32 text-white ${direction === 'rtl' ? 'text-right' : 'text-left'} `,
      },
      {
        id: 'createdAt',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'date_and_days',
        disablePadding: false,
        label: 'START_DATE',
        sort: true,
      },
      {
        id: '',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'action',
        disablePadding: false,
        label: 'LINK_TO_ENV',
        sort: true,
      },
    ],
    clickable: true,
  }

  const methods = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(gymSchema),
    defaultValues: {
      instituteName: '',
      firstName: '',
      phoneNo: '',
      email: '',
      instagramId: ' ',
    },
  })

  const toggelModal = () => {
    setShow(true)
  }
  const clickHandler = (item) => {
    setGymID(item?.Gym?.id)
    setUserID(item?.id)
    toggelModal()
  }
  const NavigateHandler = (item) => {}

  return (
    <>
      <ListHeader toggelModal={toggelModal} setSearchText={setSearchText} searchText={searchText} />
      {loader ? (
        <Loader className="" size="w-10 h-10" fill="#003353" />
      ) : (
        <>
          {customers && customers?.length > 0 ? (
            <ListTable
              headerRows={headerRows}
              data={customers}
              totalData={40}
              loading={false}
              searchText={''}
              clickHandler={clickHandler}
              deleteHandler={() => {}}
              actionHandler={NavigateHandler}
            />
          ) : (
            <div className="h-[50%] justify-center items-center ">
              <p className={`text-white font-primary-rg text-[24px] text-center ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>
                {t('NO_DATA')}
              </p>
            </div>
          )}
        </>
      )}

      {show && (
        <CreateGym
          Controller={Controller}
          methods={methods}
          show={show}
          setShow={setShow}
          gymID={gymID}
          setGymID={setGymID}
          userID={userID}
          setUserID={setUserID}
        />
      )}
    </>
  )
}

export default Homepage
