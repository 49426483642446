// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.floating-label-container {
  position: relative;
  margin-bottom: 8px;
}

.floating-label {
  color: #fff;
  font-size: 13px;

  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 14px;
  padding: 0 5px;
  background: #5a5f66;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.floating-label.rtl {
  right: 15px;
  left: auto;
}

.floating-input {
  font-size: 12px;
  display: inline-block;
  width: 100%;
  height: 48px;
  padding: 0 20px;
  background: #5a5f66;
  color: #fff;
  border: none;
  border-radius: 12px;
  box-sizing: border-box;
  padding-top: 18px;
  font-family: 'Poppins', sans-serif;

  /* &:focus {
    outline: none;
    ~ .floating-label {
      top: 6px;
      font-size: 13px;
    }
  } */
}

.floating-input:not(:placeholder-shown) ~ .floating-label {
  top: 6px;
  font-size: 13px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
`, "",{"version":3,"sources":["webpack://./src/components/calendar/Input/styles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,eAAe;;EAEf,mBAAmB;EACnB,kBAAkB;EAClB,oBAAoB;EACpB,UAAU;EACV,SAAS;EACT,cAAc;EACd,mBAAmB;EACnB,yBAAyB;EACzB,8BAA8B;EAC9B,iCAAiC;AACnC;AACA;EACE,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,sBAAsB;EACtB,iBAAiB;EACjB,kCAAkC;;EAElC;;;;;;KAMG;AACL;;AAEA;EACE,QAAQ;EACR,eAAe;AACjB;;AAEA,gCAAgC;AAChC;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA,YAAY;AACZ;EACE,0BAA0B;AAC5B","sourcesContent":[".floating-label-container {\n  position: relative;\n  margin-bottom: 8px;\n}\n\n.floating-label {\n  color: #fff;\n  font-size: 13px;\n\n  font-weight: normal;\n  position: absolute;\n  pointer-events: none;\n  left: 15px;\n  top: 14px;\n  padding: 0 5px;\n  background: #5a5f66;\n  transition: 0.2s ease all;\n  -moz-transition: 0.2s ease all;\n  -webkit-transition: 0.2s ease all;\n}\n.floating-label.rtl {\n  right: 15px;\n  left: auto;\n}\n\n.floating-input {\n  font-size: 12px;\n  display: inline-block;\n  width: 100%;\n  height: 48px;\n  padding: 0 20px;\n  background: #5a5f66;\n  color: #fff;\n  border: none;\n  border-radius: 12px;\n  box-sizing: border-box;\n  padding-top: 18px;\n  font-family: 'Poppins', sans-serif;\n\n  /* &:focus {\n    outline: none;\n    ~ .floating-label {\n      top: 6px;\n      font-size: 13px;\n    }\n  } */\n}\n\n.floating-input:not(:placeholder-shown) ~ .floating-label {\n  top: 6px;\n  font-size: 13px;\n}\n\n/* Chrome, Safari, Edge, Opera */\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n/* Firefox */\ninput[type='number'] {\n  -moz-appearance: textfield;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
