import React from 'react'
import { useTranslation } from 'react-i18next'

export default function GenderSelection({ label, data, setSelectedGender, selectedGender, disabled, direction, noPadding }) {
  const { t } = useTranslation()

  return (
    <div className={`flex flex-row  ${noPadding ? ' -my-1' : ' py-1'} w-full ${disabled && 'opacity-50'}`}>
      {label && (
        <label
          className={`flex flex-col py-1  text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
            } text-[16px] justify-center  w-36 `}
        >
          {label}
        </label>
      )}
      <div className="flex flex-row w-full h-[56px] space-x-2 rtl:space-x-reverse items-center rounded-[12px]">
        {data.map((item) => {
          return (
            <div
              className={`py-2 px-4 text-center text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} ${selectedGender.id === item.id ? 'bg-borderActive' : 'border-[1px] border-genderOutline'
                }  w-[142px]  rounded-[8px] cursor-pointer ${disabled === true ? 'pointer-events-none' : ''}`}
              onClick={() => setSelectedGender(item)}
            >
              {t(item.value)}
            </div>
          )
        })}
      </div>
    </div>
  )
}
