import ListTable from 'components/List/ListTable'
import DeleteModal from 'components/compounds/DeleteModal'
import { Loader } from 'components/compounds/Loader'
import ExercisesHeader from 'components/exercises/ExercisesHeader'
import { useDebounce } from 'hooks'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { DeleteExercise, getAllExercise } from 'store/userSlice/userActions'

export default function Exercises() {
  const [searchText, setSearchText] = useState()
  const { loader, exercises } = useSelector((state) => state.user)
  const { direction, language } = useSelector((state) => state.language)
  const [eqID, setEqID] = useState(null)
  const [openModal, setOpenModal] = useState()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const debouncedSearchTerm = useDebounce(searchText, 500)

  useEffect(() => {
    const getExercise = async () => {
      if (debouncedSearchTerm) {
        dispatch(getAllExercise(t, direction, { search: debouncedSearchTerm }))
      } else {
        dispatch(getAllExercise(t, direction))
      }
    }
    getExercise()
    // eslint-disable-next-line
  }, [debouncedSearchTerm])

  const headerRows = {
    data: [
      {
        id: 'name',

        type: 'custom',
        disablePadding: false,
        label: 'EXERCISE_NAME',
        sort: true,
        CustomComponent: (item) => {
          return <p className={`${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>{t(item.data.exerciseName)}</p>
        },
      },
      {
        id: 'primaryMucleName',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'custom',
        disablePadding: false,
        label: 'PRIMARY_MUSCLES',
        sort: true,
        CustomComponent: (item) => {
          let name = ''
          // eslint-disable-next-line array-callback-return
          item?.data?.primaryMucleName?.map((value, index) => {
            if (item?.data?.primaryMucleName?.length > 1) {
              if (index !== item?.data?.primaryMucleName?.length - 1) {
                name += language === 'en' ? value.englishName + ' | ' : value.hebrewName + ' | '
              } else {
                name += language === 'en' ? value.englishName + ' | ' : value.hebrewName
              }
            } else {
              name = language === 'en' ? value.englishName : value.hebrewName
            }
          })

          return <p className={`${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>{name}</p>
        },
      },
      {
        id: 'secondaryMucleName',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'custom',
        disablePadding: false,
        label: 'SECONDARY_MUSCLES',
        sort: true,
        CustomComponent: (item) => {
          let name = ''
          // eslint-disable-next-line array-callback-return
          item?.data?.secondaryMucleName?.map((value, index) => {
            if (item?.data?.secondaryMucleName?.length > 1) {
              if (index !== item?.data?.secondaryMucleName?.length - 1) {
                name += language === 'en' ? value.englishName + ' | ' : value.hebrewName + ' | '
              } else {
                name += language === 'en' ? value.englishName + ' | ' : value.hebrewName
              }
            } else {
              name = language === 'en' ? value.englishName : value.hebrewName
            }
          })
          return <p className={`${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>{name}</p>
        },
      },
      {
        id: 'gymSetting',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'text',
        disablePadding: false,
        label: 'EXERCISE_SETTING',
        sort: true,
      },
      {
        id: 'machine_name',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'custom',
        disablePadding: false,
        label: 'MACHINE_NAME',
        sort: true,
        CustomComponent: ({ item }) => {
          return <p className={`${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>{item === 'null' ? '-' : item}</p>
        },
      },
    ],
    isDeleting: true,
  }

  const action = () => {
    setEqID(null)
    setOpenModal(false)
  }
  const deleteHandler = (id) => {
    setEqID(id)
    setOpenModal(true)
  }
  const deleteEquipment = () => {
    dispatch(DeleteExercise(eqID, t, direction, action))
  }
  return (
    <div className="h-full w-full overflow-hidden">
      <ExercisesHeader setSearchText={setSearchText} searchText={searchText} />
      {loader ? (
        <Loader className="" size="w-5 h-5" fill="#003353" />
      ) : (
        <>
          {exercises?.length > 0 ? (
            <div className="h-[76%] xl:h-[85%] w-full overflow-auto">
              <ListTable
                headerRows={headerRows}
                data={exercises}
                loading={false}
                searchText={''}
                clickHandler={() => {}}
                deleteHandler={deleteHandler}
              />
            </div>
          ) : (
            <div className="flex h-[80%] justify-center items-center ">
              <p className={`text-white font-primary-rg text-[24px] text-center ltr:font-primary-rg rtl:font-primary-he-rg`}>{t('NO_DATA')}</p>
            </div>
          )}
        </>
      )}
      <DeleteModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        t={t}
        direction={direction}
        des={t('MUSCLE_DELETE_DES')}
        title={t('MUSCLE_DELETE_DES')}
        action={() => {}}
        handleSubmit={deleteEquipment}
        showPass={false}
      />
    </div>
  )
}
