import { yupResolver } from '@hookform/resolvers/yup'
import DeleteModal from 'components/compounds/DeleteModal'
import { Loader } from 'components/compounds/Loader'
import ListTable from 'components/List/ListTable'
import CreateMuscle from 'components/mucleLibrary/CreateMuscle'
import MuscleLibraryListHeader from 'components/mucleLibrary/MuscleLibraryListHeader'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { muscleSchema } from 'validations/formsValidations'
import { deleteGymMuscle } from 'store/muscleSlice/muscleActions'
import PopularExercises from 'components/mucleLibrary/components/PopularExercises'
export default function MuscleLibrary() {
  const [show, setShow] = useState(false)
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState()
  const [mucleID, setMucleID] = useState(null)
  const [openModal, setOpenModal] = useState()
  const { direction, language } = useSelector((state) => state.language)
  const { loader, muscles } = useSelector((state) => state?.muscle)
  const { customerDetail } = useSelector((state) => state.user)

  const { t } = useTranslation()

  const toggelModal = () => {
    setShow(true)
  }
  const headerRows = {
    data: [
      {
        id: 'muscleName',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'text',
        disablePadding: false,
        label: 'MUSCLE_NAME',
        sort: true,
        className: `w-10 text-white ${direction === 'rtl' ? 'text-right' : 'text-left'} `,
      },

      {
        id: 'exerciseCount',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'custom',
        disablePadding: false,
        label: '#EXERCISES',
        sort: true,
        CustomComponent: ({ item }) => (
          <p className={`ltr:font-primary-rg rtl:font-primary-he-rg text-white`}>
            {item} {t('EXERCISE')}
          </p>
        ),
      },
      {
        id: 'popularExercises',
        align: language === 'en' ? 'left' : 'right',
        type: 'custom',
        disablePadding: false,
        label: 'POPULAR_EXERCISES',
        sort: true,
        CustomComponent: ({ item }) => {
          return <div className="flex flex-row w-[550px]  gap-1">{item && <PopularExercises items={item} direction={direction} />}</div>
        },
      },
    ],
    clickable: true,
    isWidth: true,
    isDeleting: false,
  }

  const methods = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(muscleSchema),
    defaultValues: {
      muscleName: '',
    },
  })
  const deleteHandler = (id) => {
    setMucleID(id)

    setOpenModal(true)
  }
  const deleteMucle = () => {
    let data = {
      id: mucleID,
      gymId: customerDetail.id,
    }
    dispatch(deleteGymMuscle(data, t, direction))

    setOpenModal(false)
  }
  const clickHandler = (item) => {
    setMucleID(item?.id)
    setShow(!show)
  }

  return (
    <div className="h-full overflow-hidden">
      <MuscleLibraryListHeader toggelModal={toggelModal} setSearchText={setSearchText} searchText={searchText} data={muscles} />
      {loader ? (
        <div className="w-[100%] h-[calc(100vh-150px)]   ">
          <Loader className="" size="w-10 h-10" fill="#003353" />
        </div>
      ) : (
        <div className=" h-full xl:h-[85%] overflow-auto has-scrollbar">
          {muscles && muscles?.length > 0 ? (
            <ListTable
              headerRows={headerRows}
              data={muscles}
              totalData={40}
              loading={false}
              searchText={searchText}
              clickHandler={clickHandler}
              deleteHandler={deleteHandler}
              tableClassName={'pb-32'}
            />
          ) : (
            <div className="h-[50%] justify-center items-center ">
              <p className={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-[24px] text-center `}>
                {t('NO_DATA')}
              </p>
            </div>
          )}
        </div>
      )}
      <DeleteModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        t={t}
        direction={direction}
        des={t('MUSCLE_DELETE_DES')}
        title={t('MUSCLE_DELETE_TITLE')}
        action={() => {}}
        handleSubmit={deleteMucle}
        showPass={false}
      />
      {show && <CreateMuscle Controller={Controller} methods={methods} show={show} setShow={setShow} muscleID={mucleID} setMucleID={setMucleID} />}
    </div>
  )
}
