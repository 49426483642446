import { yupResolver } from '@hookform/resolvers/yup'
import DeleteModal from 'components/compounds/DeleteModal'
import Dropdown from 'components/compounds/DropDown'
import { LabelInput } from 'components/compounds/LabelInput'
import LanguageDropDown from 'components/compounds/LanguageDropDown'
import { Loader } from 'components/compounds/Loader'
import PrimaryButton from 'components/elements/PrimaryButton'
import { constants } from 'constant'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { authActions } from 'store/authSlice/authReducers'
import { DeleteGymByGymAdmin } from 'store/userSlice/userActions'
import { userActions } from 'store/userSlice/userReducer'
import { editUserSchema } from 'validations/formsValidations'

export default function EditCustomerDetail({ data, file, onSubmit, selectedLanguage, setSelectedLanguage, workoutTime, setWorkoutTime, role }) {
  const { t } = useTranslation()
  const { loader } = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const [openModal, setOpenModal] = useState(false)
  const toggleOpen = () => setOpenModal(true)

  const navigate = useNavigate()

  const action = () => {
    setOpenModal(false)
    navigate(`/home`)
  }
  const methods = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(editUserSchema),
    defaultValues: {
      email: '',
      phoneNo: '',
      instagramId: '',
    },
  })
  const { control, handleSubmit, formState, setValue } = methods
  const { errors } = formState
  const { direction } = useSelector((state) => state.language)
  useEffect(() => {
    if (data) {
      setValue('email', data?.User?.email)
      setValue('phoneNo', data?.User?.phone)
      setValue('instagramId', data?.instagramId)
      constants.APP_LANGUAGES_LIST.forEach((item) => {
        if (item?.code === data?.lang) {
          setSelectedLanguage(item)
        }
      })
      constants.STANDARD_TIME.forEach((item) => {
        // eslint-disable-next-line
        if (item == data?.standardTime) {
          setWorkoutTime(item)
        }
      })
    }
    function modalButton() {
      document.getElementById('deleteButton').click()
    }
    document.addEventListener('DOMContentLoaded', modalButton)
    return () => {
      document.removeEventListener('DOMContentLoaded', modalButton)
    }
    // eslint-disable-next-line
  }, [data])

  const deletionAction = async () => {
    dispatch(authActions.resetAuth())
    dispatch(userActions.resetUser())
    navigate('/login')
  }
  const deleteGym = () => {
    let values = {
      gymId: data?.id,
      userId: data?.User?.id,
    }
    dispatch(DeleteGymByGymAdmin(values, t, direction, deletionAction))
  }

  return (
    <div className="w-full max-w-[612px] h-full py-4 lg:py-6">
      {loader ? (
        <div className="flex flex-col justify-center items-center h-[400px]">
          <Loader className="" size="w-6 h-6" fill="#003353" />
        </div>
      ) : (
        <>
          <div className="flex flex-row gap-4 py-1 px-4">
            <LabelInput
              name="email"
              type="text"
              label={t('MAIL')}
              placeholderValue={t('MAIL_PLACEHOLDER')}
              control={control}
              required
              fullWidth
              error={errors?.email ? true : false}
              errorMessage={errors?.email?.message}
              dir={direction}
            />
            <LabelInput
              name="phoneNo"
              type="number"
              label={t('MOBILE_NO')}
              control={control}
              placeholderValue={t('INSTITUTE_PHONE_NUMBER')}
              required
              fullWidth
              error={errors?.phoneNo ? true : false}
              errorMessage={errors?.phoneNo?.message}
              dir={direction}
            />
          </div>
          <div className="flex  flex-row gap-x-4 pt-1 px-4">
            <LanguageDropDown
              options={constants.APP_LANGUAGES_LIST}
              className="my-2 w-1/2"
              title={t('LANGUAGE')}
              value={selectedLanguage}
              setValue={setSelectedLanguage}
              t={t}
              dir={direction}
              disable={role === 'coach' ? true : false}
            />
            <Dropdown
              options={constants.STANDARD_TIME}
              className="my-2 w-1/2"
              title={t('WORKOUT_TIME')}
              value={workoutTime}
              setValue={setWorkoutTime}
              t={t}
              dir={direction}
              isTime={true}
              disable={role === 'coach' ? true : false}
            />
          </div>

          <div className="flex flex-row w-full  px-4">
            <LabelInput
              name="instagramId"
              type="text"
              label={t('INSTAGRAM')}
              placeholderValue={t('INSTAGRAM_PLACEHOLDER')}
              control={control}
              required
              fullWidth
              error={errors?.instagramId ? true : false}
              errorMessage={errors?.instagramId?.message}
              dir={direction}
              disabled={role === 'coach' ? true : false}
              margin={'mt-1'}
            />
          </div>

          <div className="flex flex-row w-full py-1 lg:py-5 px-4 justify-between gap-4 " data-modal-toggle="deleteModal">
            <PrimaryButton
              text={t('DELETION_INSTITUTE')}
              type="submit"
              disabled={role === 'coach' ? true : false}
              isIcon={false}
              onClick={toggleOpen}
              className=" h-[44px] rounded-[12px] shadow-2xl "
              bgColor="hover:bg-textFieldColor focus:bg-textFieldColor active:bg-textFieldColor "
              textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
              wid={20}
              ht={20}
            />
            <PrimaryButton
              text={t('SAVING_CHANGES')}
              type="submit"
              disabled={false}
              isIcon={false}
              onClick={handleSubmit(onSubmit)}
              className=" h-[44px] rounded-[12px] shadow-2xl  "
              bgColor="bg-prussianBlue hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
              textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
              wid={20}
              ht={20}
            />
          </div>
        </>
      )}
      <DeleteModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        t={t}
        ID={data?.User?.id}
        direction={direction}
        des={t('PERSONAL_GYM_DELETION_CONFIRMATION')}
        title={t('DELETE_MY_ACCOUNT')}
        action={action}
        handleSubmit={deleteGym}
        showPass={false}
      />
    </div>
  )
}
