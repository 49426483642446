import ListTable from 'components/List/ListTable'
import DeleteModal from 'components/compounds/DeleteModal'
import { Loader } from 'components/compounds/Loader'
import EquipmentHeader from 'components/equipment/EquipmentHeader'

import { useDebounce } from 'hooks'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { DeleteInstrument, getAllGymsInstruments, updateGymInstruments } from 'store/userSlice/userActions'

export default function Equipments() {
  const [searchText, setSearchText] = useState()
  const { loader, gymInstruments } = useSelector((state) => state.user)
  const { direction } = useSelector((state) => state.language)
  const [eqID, setEqID] = useState(null)
  const [openModal, setOpenModal] = useState()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const debouncedSearchTerm = useDebounce(searchText, 500)

  useEffect(() => {
    const getGym = async () => {
      if (debouncedSearchTerm) {
        dispatch(getAllGymsInstruments(t, direction, { search: debouncedSearchTerm }))
      } else {
        dispatch(getAllGymsInstruments(t, direction))
      }
    }
    getGym()
    // eslint-disable-next-line
  }, [debouncedSearchTerm])
  const headerRows = {
    data: [
      {
        id: 'machineName',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'text',
        disablePadding: false,
        label: 'NAME',
        sort: true,
      },
      {
        id: 'plateWeight',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'text',
        disablePadding: false,
        label: 'PLATE',
        sort: true,
      },
      {
        id: 'boostWeight',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'text',
        disablePadding: false,
        label: 'BOOST',
        sort: true,
      },
      {
        id: 'isRubberBand',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'custom',
        disablePadding: false,
        label: 'TYPE',
        CustomComponent: ({ item }) => (
          <div className="flex">
            <div className={`flex text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-[14px] `}>
              {item === false ? 'Machine' : 'Band'}
            </div>
          </div>
        ),
      },
      {
        id: 'bandLevel',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'text',
        disablePadding: false,
        label: 'RESISTANCE',
        sort: true,
      },
    ],
    isDeleting: true,
    isEditable: true,
  }

  const editHandler = (data) => {
    dispatch(updateGymInstruments(data, t, direction))
  }
  const action = () => {
    setEqID(null)
    setOpenModal(false)
  }
  const deleteHandler = (id) => {
    setEqID(id)
    setOpenModal(true)
  }
  const deleteEquipment = () => {
    dispatch(DeleteInstrument(eqID, t, direction, action))
  }

  return (
    <>
      <EquipmentHeader setSearchText={setSearchText} searchText={searchText} />
      {loader ? (
        <Loader className="" size="w-5 h-5" fill="#003353" />
      ) : (
        <div className="flex overflow-y-auto ">
          <ListTable
            headerRows={headerRows}
            data={gymInstruments}
            loading={false}
            searchText={''}
            clickHandler={() => {}}
            deleteHandler={deleteHandler}
            editHandle={editHandler}
          />
        </div>
      )}
      <DeleteModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        t={t}
        direction={direction}
        des={t('EQUIPMENT_DELETE_DESCRIPTION')}
        title={t('EQUIPMENT_DELETE_TITLE')}
        action={() => {}}
        handleSubmit={deleteEquipment}
        showPass={false}
      />
    </>
  )
}
