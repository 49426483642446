import { Images } from 'assets'
import { ProfileAvatar } from 'components/PlayBoard/ProfileAvatar'
import { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import CreateCoaches from './CreateCoaches'
import { constants } from 'constant'

const Coach = ({ coach, open, setCoachId, setShow }) => {
  const { direction } = useSelector((state) => state.language)
  const { t } = useTranslation()
  const textColor = constants.COLOR_MAP.find((item) => item?.bgColor === coach?.Coach?.color)?.textColor || '#FFFFFF'

  return (
    <div
      className="flex justify-between space-x-2 rtl:space-x-reverse w-full h-full cursor-pointer"
      onClick={() => {
        setCoachId(coach.Coach.id)
        setShow(true)
      }}
    >
      <div className={`${!open ? 'w-full' : 'w-10/12'}  h-full flex items-center space-x-2 rtl:space-x-reverse relative`}>
        <ProfileAvatar
          image={coach?.avatar}
          name={coach.firstName}
          color={coach?.Coach?.color === 'BLUE' ? '#E5A772' : coach?.Coach?.color}
          size={'medium'}
          className={`!text-[${textColor}]`}
        />
        <div
          className={`${open && 'hidden'} absolute top-0 right-0 w-3 h-3 rounded-full shadow-2xl`}
          style={{ backgroundColor: coach?.Coach?.color }}
        ></div>
        <h6
          className={`${!open && 'hidden'} origin-left duration-200 text-[#C2C7CF] ${
            direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
          } text-xs truncate `}
        >
          {t(coach?.firstName)}
        </h6>
      </div>
      <div className={`${!open ? 'w-full hidden' : 'w-2/12'}  w-2/12 h-full flex justify-center items-center mt-2`}>
        <div
          className="w-3 h-3 rounded-full shadow-2xl"
          style={{ backgroundColor: coach?.Coach?.color === 'BLUE' ? '#E5A772' : coach?.Coach?.color }}
        ></div>
      </div>
    </div>
  )
}

export default function ShowCoaches({ open, data }) {
  const [show, setShow] = useState(false)
  const [coachID, setCoachId] = useState()
  const { direction } = useSelector((state) => state.language)
  const { t } = useTranslation()

  const handleShow = useCallback(() => setShow(true), [])

  return (
    <>
      <div className="flex w-full justify-between ">
        <p className={`text-[#C2C7CF] text-[14px] ${direction === 'ltr' ? 'font-primary-md' : 'font-primary-he-md'}`}>{t('COACHES')}</p>
        <div>
          <img
            alt="profile-img"
            src={Images.Plus}
            width={15}
            height={15}
            onClick={handleShow}
            className=" cursor-pointer"
          />
        </div>
      </div>
      <div className="flex flex-col space-y-3 overflow-auto mt-2">
        {data?.map((coach) => (
          <Coach key={coach.Coach.id} coach={coach} open={open} setCoachId={setCoachId} setShow={setShow} />
        ))}
      </div>
      {show && <CreateCoaches show={show} setShow={setShow} coachID={coachID} setCoachId={setCoachId} />}
    </>
  )
}