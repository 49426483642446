import { Images } from 'assets'
import { Loader } from 'components/compounds/Loader'
import { SearchInput } from 'components/compounds/SearchInput'
import ListTable from 'components/List/ListTable'
import { useDebounce } from 'hooks'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { DeleteSingleGymInstrument, getSingleGymInstruments, updateSingleGymInstruments } from 'store/userSlice/userActions'
import CreateInstruments from './components/CreateInstruments'
import CreateRubberBand from './components/CreateRubberBand'
export default function InstituteEquipments({ direction }) {
  const { loader, gymInstruments } = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [searchText, setSearchText] = useState('')
  const debouncedSearchTerm = useDebounce(searchText, 500)

  const { customerDetail } = useSelector((state) => state.user)

  useEffect(() => {
    const getGym = async () => {
      if (debouncedSearchTerm) {
        dispatch(getSingleGymInstruments(customerDetail?.id, t, direction, { search: debouncedSearchTerm }))
      } else {
        dispatch(getSingleGymInstruments(customerDetail?.id, t, direction))
      }
    }
    getGym()
    // eslint-disable-next-line
  }, [debouncedSearchTerm])

  const setSearch = (event) => {
    setSearchText(event.target.value)
  }
  const headerRows = {
    data: [
      {
        id: 'machineName',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'text',
        disablePadding: false,
        label: 'NAME',
        sort: true,
        isInt: false,
      },
      {
        id: 'plateWeight',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'text',
        disablePadding: false,
        label: 'PLATE',
        sort: true,
        isInt: true,
      },
      {
        id: 'boostWeight',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'text',
        disablePadding: false,
        label: 'BOOST',
        sort: true,
        isInt: true,
      },
      {
        id: 'bandLevel',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'text',
        disablePadding: false,
        label: 'RESISTANCE',
        sort: true,
        isInt: true,
      },
    ],
    isDeleting: true,
    isEditable: true,
  }

  const editHandler = (values) => {
    let data = {
      ...values,
      gymId: customerDetail?.id,
    }
    dispatch(updateSingleGymInstruments(data, t, direction))
  }
  const deleteHandler = (id) => {
    dispatch(DeleteSingleGymInstrument(id, customerDetail?.id, t, direction))
  }

  return (
    <div className="max-w-[610px] h-full py-4">
      {loader ? (
        <div className="flex flex-col justify-center items-center h-[400px]">
          <Loader className="" size="w-6 h-6" fill="#003353" />
        </div>
      ) : (
        <>
          <div className={`px-4 text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-[16px]`}>{t('INSTRUMENTS')}</div>
          <CreateInstruments />
          <div className={`px-4 text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-[16px]`}>{t('RUBBER_BAND')}</div>
          <CreateRubberBand />
          <div className="flex justify-between px-4 xl:py-5 ">
            <div className={`flex items-center text-white text-center ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-[16px]`}>
              {t('INSTITUTE_EQUIPMENTS')}
            </div>
            <SearchInput
              placeholder={t('SEARCH')}
              radius={'rounded-[50px]'}
              className="flex flex-1 px-4 "
              disableUnderline
              fullWidth
              value={searchText}
              inputProps={{
                'aria-label': 'Search',
              }}
              onChange={(ev) => setSearch(ev)}
              icon={Images.Search}
            />
          </div>
          <div className="flex h-[22vh] xl:h-[42vh] overflow-y-auto  ">
            <ListTable
              headerRows={headerRows}
              data={gymInstruments}
              loading={false}
              searchText={''}
              clickHandler={() => {}}
              deleteHandler={deleteHandler}
              editHandle={editHandler}
            />
          </div>
        </>
      )}
    </div>
  )
}
