import { Images } from 'assets'
import Avatar from 'components/compounds/Avatar'
import React from 'react'
import { useSelector } from 'react-redux'

export default function ProgramSectionHeader({ traineeInfo, handleWorkout, setData, setName, pName, t }) {
  const { direction } = useSelector((state) => state.language)
  return (
    <div className="flex w-full h-7 flex-row justify-between items-center  ">
      <div className=" w-1/3 ltr:font-primary-rg rtl:font-primary-he-rg text-textLightGray text-[20px] font-medium leading-6 text-center ">
        <input
          className={`bg-secondaryBackground text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
          type="text"
          onChange={(e) => {
            setName(e.target.value)
          }}
          value={pName}
          maxLength={20}
        />
      </div>
      <div className="flex w-1/3 gap-2 flex-row justify-center items-center ">
        <div
          className={`${
            direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
          } text-[#C2C7CF] text-[16px] font-medium leading-5 text-center `}
        >
          {traineeInfo?.User?.firstName}
        </div>

        {traineeInfo?.User?.avatar != null ? (
          <div className="flex border rounded-[50px] w-7 h-7 justify-center items-center overflow-hidden">
            <img src={traineeInfo?.User?.avatar} alt="" height={28} width={28} className={`items-center cursor-pointer`} onClick={() => {}} />
          </div>
        ) : (
          <Avatar name={traineeInfo?.User?.firstName} width={25} height={25} size={10} />
        )}
      </div>
      <div className="flex w-1/3 gap-1 flex-row justify-end items-center cursor-pointer " onClick={handleWorkout}>
        <div
          className={`${
            direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
          } text-white text-[14px] font-medium leading-[18px] text-center `}
        >
          {t('ADD_WORKOUT')}
        </div>
        <div className="flex  w-7 h-7 justify-center items-center">
          <img src={Images.AddSquare} alt="" height={24} width={24} className={`items-center cursor-pointer`} onClick={() => {}} />
        </div>
      </div>
    </div>
  )
}
