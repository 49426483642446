import React from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Images } from 'assets'

import { Fragment } from 'react'

export default function RepetitionDropDown({ value, setValue, options, title, disable, t, dir }) {
  return (
    <div className="flex flex-col w-full ">
      <Menu as="div" className="relative inline-block text-left ">
        <div className=" bg-textFieldColor rounded-[12px]">
          <Menu.Button
            className={`inline-flex w-full text-[12px] text-center items-center rounded-[12px] ${
              !disable ? 'bg-[##28343A]' : 'bg-textFieldColor'
            } px-2 py-2  text-textLightGrey ${dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
            disabled={disable}
          >
            {value && t(value.label)}

            <img src={Images.DROPDOWNICON} alt="" height={20} width={20} className={`${dir === 'ltr' ? 'float-right ml-auto' : 'mr-auto'} `} />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute w-full  origin-top-right divide-y divide-[#374349] rounded-b-md bg-borderColor focus:outline-none  ">
            {options?.map((opt) => {
              return (
                <div className="px-1 py-1 hover:bg-textFieldColor ">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          setValue(opt)
                        }}
                        className={` text-textLightGrey
                        group flex w-full ${dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} items-center rounded-md px-2 py-2 text-[12px]`}
                      >
                        {t(opt.label)}
                      </button>
                    )}
                  </Menu.Item>
                </div>
              )
            })}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
