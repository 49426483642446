import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";

import ListTableHead from "./ListTableHeader";
import RowComponent from "./components/RowComponent";

function ListTable(props) {
  const { language } = useSelector((state) => state.language);

  const {
    headerRows,
    data,
    totalData,

    searchText,
    clickHandler,
    editViewHandler,

    deleteHandler,
    copyHandler,
    selectorValues,
    selectedItemsHandlers,
    editHandle,
    actionHandler,
    tableClassName,
    padding,
    action,
    onSort,
    url,
  } = props;

  const [selected, setSelected] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  // eslint-disable-next-line
  const [page, setPage] = useState(0);
  // eslint-disable-next-line
  const [pagesFetched, setPagesFetched] = useState(0);
  // eslint-disable-next-line
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState({
    direction: "asc",
    id: null,
  });

  useEffect(() => {
    setPage(0);
    setPagesFetched(0);
  }, [searchText, rowsPerPage, order, selectorValues]);

  function handleRequestSort(event, property) {
    const id = property;
    let direction = "desc";

    if (order.id === property && order.direction === "desc") {
      direction = "asc";
    }
    setOrder({
      direction,
      id,
    });
  }

  function handleSelectAll(event) {
    if (event.target.checked) {
      setSelected(data.map((n) => n._id));
      return;
    }
    setSelected([]);
  }

  function handleDelete(n, index) {
    deleteHandler(n.id, n?.User?.id, n);
  }
  function handleCopy(n, index) {
    copyHandler(n.id);
  }
  function handleAction(n, index) {
    actionHandler(n.id);
  }
  function handleClick(item) {
    clickHandler && clickHandler(item);
  }
  function handleClickEdit(item) {
    editViewHandler(item);
  }

  function handleCheck(event, id) {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }

  const handleEdit = (n, index) => {
    var editableRows = document.querySelectorAll("tr#editable-row");
    var editableRow = editableRows[index];
    var rowColumns = editableRow.getElementsByTagName("td");

    var rowData = {};
    var headerRow = document.querySelector("thead tr");
    var headerColumns = headerRow.getElementsByTagName("td");

    for (var i = 0; i < rowColumns.length; i++) {
      var columnName = headerColumns[i].textContent;
      rowData[columnName] = rowColumns[i].textContent; // Use textContent instead of innerHTML
    }

    const updatedObj = Object.fromEntries(
      Object.entries(rowData)
        .filter(([key, value]) => key !== "" && key !== "Type")
        .map(([key, value]) => [key, value])
    );
    updatedObj.id = n?.id;

    editHandle(updatedObj);
  };

  const CustomComponent = headerRows.dropdownView;

  return (
    <div className="w-full overflow-auto">
      <table
        className={`w-full ${padding === false ? "px-0" : "px-8"} pb-12 ${tableClassName}`}
        dir={`${language === "en" ? "ltr" : "rtl"}`}
      >
        {headerRows?.noShowHeader != undefined ||
          (!headerRows?.noShowHeader && (
            <ListTableHead
              headerRows={headerRows}
              rowCount={totalData}
              numSelected={selected.length}
              order={order}
              onSort={onSort}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAll}
              onDeleteItemsClick={handleDelete}
              handleCopy={handleCopy}
              selected={selected}
              selectedItemsHandlers={selectedItemsHandlers}
            />
          ))}

        <tbody className="w-[97%]">
          {_.orderBy(
            data,
            [
              (o) => {
                return o[order.id];
              },
            ],
            [order.direction]
          ).map((n, index) => {
            const isSelected = selected.indexOf(n.id) !== -1;
            return (
              <React.Fragment key={n.id}>
                <RowComponent
                  headerRows={headerRows}
                  isSelected={isSelected}
                  handleClick={handleClick}
                  handleCheck={handleCheck}
                  handleCopy={handleCopy}
                  n={n}
                  keyIndex={index}
                  handleEdit={handleEdit}
                  onDeleteItemsClick={handleDelete}
                  handleAction={handleAction}
                  action={action}
                  setSelectedRow={setSelectedRow}
                  selectedRow={selectedRow}
                  editViewHandler={handleClickEdit}
                />
                {headerRows?.isCustomDropdown && selectedRow === n && (
                  <CustomComponent row={n} />
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ListTable;
