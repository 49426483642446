import { Images } from 'assets'

import { Loader } from 'components/compounds/Loader'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useState } from 'react'

import { MuscleSummary } from 'components/PlayBoard/components'
import { constants } from 'constant'

export default function SampleTemplates({ toggleDropdown, isOpen, direction, selectedExercises, setSelectedExercises }) {
  const { t } = useTranslation()

  const { loader } = useSelector((state) => state.gymAdmin)
  const [activeTemplate, setActiveTemplate] = useState(null)
  const { templatesList } = useSelector((state) => state?.template)
  const { exercisesInfo, bands } = useSelector((state) => state.gymAdmin)
  const toggleTemplate = (id) => {
    if (id === activeTemplate) {
      setActiveTemplate(null)
    } else {
      setActiveTemplate(id)
    }
  }

  const getSortedMuscle = (muscleSummary) => {
    const sortedMuscles = [...muscleSummary].sort((a, b) => parseFloat(b.percentage) - parseFloat(a.percentage))

    return sortedMuscles
  }

  const handleClick = async (template) => {
    const exercisesToAdd = []

    await template?.exercises?.forEach((exercise) => {
      const isAlreadyAdded = selectedExercises.some((item) => item.id === exercise.id)

      if (!isAlreadyAdded) {
        const draggedItem = exercisesInfo.find((val) => val.id === exercise.id)
        let updatedDraggedItem

        if (draggedItem?.resistancename === constants.RUBBER_BAND) {
          updatedDraggedItem = {
            ...draggedItem,
            Resistance_value: bands?.length > 0 ? (bands[0]?.bandLevel != null ? bands[0]?.bandLevel : 0) : 0,
            Tracking_value: 10,
            set: 3,
            isband: true,
          }
        } else {
          updatedDraggedItem = {
            ...draggedItem,
            Resistance_value: 10,
            Tracking_value: 10,
            set: 3,
            isband: false,
            bandColor: null,
          }
        }

        exercisesToAdd.push(updatedDraggedItem)
      }
    })

    setSelectedExercises([...selectedExercises, ...exercisesToAdd])
  }

  return (
    <>
      {loader ? (
        <div className="flex flex-col justify-center items-center h-[400px]">
          <Loader className="" size="w-6 h-6" fill="#003353" />
        </div>
      ) : (
        <div className={` flex flex-col w-full ${isOpen ? 'h-[85%]' : 'h-14'}`}>
          <div
            className={`flex items-center justify-between px-4 py-2 text-sm ${
              isOpen ? 'bg-borderColor' : 'bg-textFieldColor'
            }  text-white  w-full h-14 ${isOpen ? 'rounded-t-[12px]' : 'rounded-[12px]'}`}
            onClick={toggleDropdown}
          >
            <div className="flex">
              <img
                src={direction === 'ltr' ? (isOpen ? Images.DROPDOWNICON : Images.backArrow) : isOpen ? Images.DROPDOWNICON : Images.DropDown_Left}
                alt=""
                height={20}
                width={20}
                className={`items-center cursor-pointer`}
                onClick={() => {}}
              />
              <span className={`mx-2 ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-[16px] text-white leading-5`}>
                {t('TEMPLATES')}
              </span>
            </div>
            <div className="flex">
              <span className={`${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-[12px] font-normal text-white leading-5`}>
                {t('RESULT')} {templatesList?.length}
              </span>
            </div>
          </div>

          {isOpen && (
            <>
              {templatesList && templatesList?.length > 0 ? (
                <div className="flex flex-col  gap-4 p-2 w-full h-full overflow-y-scroll bg-textFieldColor">
                  {templatesList?.map((item) => {
                    return (
                      <div className="flex flex-col   rounded-[12px] gap-2 p-2 bg-statusBg">
                        <div className="w-full h-4 flex justify-around">
                          <div
                            className="w-full flex items-center space-x-2 rtl:space-x-reverse cursor-pointer relative"
                            onClick={() => handleClick(item)}
                          >
                            <img src={Images.Plus} alt="profile" className={`w-3 h-3  rounded-full bg-cover text-center shadow-xl `} />
                            <h6
                              className={`text-sm text-white  leading-4 font-medium ${
                                direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                              }`}
                            >
                              {item?.name}
                            </h6>
                          </div>
                          <div
                            className="w-full flex items-center justify-end space-x-2 rtl:space-x-reverse cursor-pointer relative"
                            onClick={() => toggleTemplate(item?.id)}
                          >
                            <img
                              src={activeTemplate === item.id ? Images.InfoColored : Images.InfoCircle}
                              alt="profile"
                              className={`w-4 h-4  rounded-full bg-cover text-center shadow-xl `}
                            />
                          </div>
                        </div>
                        <MuscleSummary muscleSummary={getSortedMuscle(item.percentage)} t={t} />
                        {activeTemplate === item?.id && (
                          <div className="flex w-full  rounded-lg p-1">
                            <div className="flex flex-col w-1/2  gap-y-2 relative">
                              <p className="text-white ltr:font-primary-rg rtl:font-primary-he-rg text-sm ">
                                {t('EXERCISES')} {item?.exercises?.length}
                              </p>
                              <div className="flex flex-col gap-y-2 cursor-pointer">
                                {item?.exercises?.map((exercise) => (
                                  <p className="text-white ltr:font-primary-rg rtl:font-primary-he-rg text-xs">{exercise?.exerciseName}</p>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
              ) : (
                <div className="h-[50%] justify-center items-center ">
                  <p className={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-[24px] text-center `}>
                    {t('NO_DATA')}
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  )
}
