import PrimaryButton from 'components/elements/PrimaryButton'

export default function CreateCustomerFooter({ loader, handleNotify, handleSubmit, t, direction }) {
  return (
    <div className="flex rounded-b-[28px] flex-row py-2 px-4 justify-end  xl:px-6 2xl:px-12 border-t-[1px] border-borderColor absolute bottom-0 w-full bg-sidebar">
      <PrimaryButton
        text={t('SAVE')}
        type="submit"
        disabled={loader}
        isIcon={false}
        onClick={handleSubmit}
        className="w-[144px] h-[44px] rounded-[12px] shadow-2xl"
        bgColor="bg-prussianBlue hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
        textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
      />
    </div>
  )
}
