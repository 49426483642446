import axiosInstance from 'api'

import { dasboardActions } from './dashboardReducers'

export const getStatistics = (id, isGymAdmin) => async (dispatch) => {
  try {
    dispatch(dasboardActions.setStatisticsLoader(true))
    if (isGymAdmin) {
      const response = await axiosInstance.get(`/statistics/${id}`)
      dispatch(dasboardActions.setStatistics(response.result))
    } else {
      const response = await axiosInstance.get(`/coach/statistics/${id}`)
      dispatch(dasboardActions.setStatistics(response.result))
    }
    dispatch(dasboardActions.setStatisticsLoader(false))
  } catch (e) {
    dispatch(dasboardActions.setStatisticsLoader(false))
    return console.error(e.message)
  }
}

export const getCompletedWorkoutsByTrainee = (id, isGymAdmin) => async (dispatch) => {
  try {
    dispatch(dasboardActions.setCompletedWorkoutsLoader(true))
    if (isGymAdmin) {
      const response = await axiosInstance.get(`/completedWorkoutsByTrainee/${id}`)
      dispatch(dasboardActions.setCompletedWorkouts(response.result))
    } else {
      const response = await axiosInstance.get(`/coach/completedWorkoutsByTrainee/${id}`)
      dispatch(dasboardActions.setCompletedWorkouts(response.result))
    }
    dispatch(dasboardActions.setCompletedWorkoutsLoader(false))
  } catch (e) {
    dispatch(dasboardActions.setCompletedWorkoutsLoader(false))
    return console.error(e.message)
  }
}

export const getConsecutiveCancelledWorkoutsByTrainee = (id, isGymAdmin) => async (dispatch) => {
  try {
    dispatch(dasboardActions.setConsecutiveCancelledWorkoutsLoader(true))
    if (isGymAdmin) {
      const response = await axiosInstance.get(`/cancelledWorkoutsByTrainee/${id}`)
      dispatch(dasboardActions.setConsecutiveCancelledWorkouts(response.result))
    } else {
      const response = await axiosInstance.get(`/coach/cancelledWorkoutsByTrainee/${id}`)
      dispatch(dasboardActions.setConsecutiveCancelledWorkouts(response.result))
    }
    dispatch(dasboardActions.setConsecutiveCancelledWorkoutsLoader(false))
  } catch (e) {
    dispatch(dasboardActions.setConsecutiveCancelledWorkoutsLoader(false))
    return console.error(e.message)
  }
}

export const getEventsByDay = (id, date, isGymAdmin) => async (dispatch) => {
  try {
    let payload = {}
    if (isGymAdmin) {
      payload = { gymId: id, date }
    } else {
      payload = { coachId: id, date }
    }
    dispatch(dasboardActions.setEventsByDateLoader(true))
    if (isGymAdmin) {
      const response = await axiosInstance.post(`/gymEventsByDate`, payload)
      dispatch(dasboardActions.setEventsByDay(response.result))
    } else {
      const response = await axiosInstance.post(`/coach/eventsByDate`, payload)
      dispatch(dasboardActions.setEventsByDay(response.result))
    }
    dispatch(dasboardActions.setEventsByDateLoader(false))
  } catch (e) {
    dispatch(dasboardActions.setEventsByDateLoader(false))
    return console.error(e.message)
  }
}

export const getChartData = (id, filterType, isGymAdmin) => async (dispatch) => {
  try {
    dispatch(dasboardActions.setWorkoutsCompletedByCoachLoader(true))
    if (isGymAdmin) {
      const response = await axiosInstance.get(`/completedWorkoutsByGym/${id}?type=${filterType}`)
      dispatch(dasboardActions.setWorkoutsCompletedByCoach(response.result))
      dispatch(dasboardActions.setCoachesFilter(response.result.gymCoaches.map((item) => item.id)))
    } else {
      const response = await axiosInstance.get(`/coach/completedWorkoutsByCoach/${id}?type=${filterType}`)
      dispatch(dasboardActions.setWorkoutsCompletedByCoach(response.result))
      dispatch(dasboardActions.setCoachesFilter(response.result.gymCoaches.map((item) => item.id)))
    }
    dispatch(dasboardActions.setFilterDuration(filterType))
    dispatch(dasboardActions.setWorkoutsCompletedByCoachLoader(false))
  } catch (e) {
    dispatch(dasboardActions.setWorkoutsCompletedByCoachLoader(false))
    return console.error(e.message)
  }
}
