import React, { useState, useCallback } from "react";
import { Images } from "assets";
import { Modal } from "components/elements/Modal";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CustomToastify from "components/compounds/Toast";
import { useDispatch } from "react-redux";
import { gymAdminActions } from "store/gymAdminSlice/gymAdminReducers";
import { Loader } from "components/compounds/Loader";
import { constants } from "constant";
import { formatDate } from "utils";
import { Tooltip } from "@material-tailwind/react";
import {
  ExerciseHistory,
  getLastPerformedExerciseDetail,
} from "store/workoutSlice/workoutActions";
import PlayWorkoutExerciseHistory from "components/PlayBoard/PlayWorkoutExerciseHistory";
export default function ExerciseDropDown({
  toggleDropdown,
  isOpen,
  direction,
  data,
  dragOver,
  workoutlist,
  setWorkoutlist,
  isActive,
  setActive,
  exerciseHistoryModal,
  setExerciseHistoryModal,
  setLoadingExercise,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedItemIndexes, setselectedItemIndexes] = useState([]);
  const { bands, loader, currentTrainee, exerciseLoader } = useSelector(
    (state) => state.gymAdmin
  );
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { historyLoader } = useSelector((state) => state.workout);
  const handleShowDetail = (id) => {
    const idFound = selectedItemIndexes.find((item) => item === id);
    if (idFound) {
      const filteredIndex = selectedItemIndexes.filter((item) => item !== id);
      setselectedItemIndexes(filteredIndex);
    } else {
      setselectedItemIndexes([...selectedItemIndexes, id]);
    }
  };
  const [isOpenVideo, setIsOpenVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);

  const handleShowVideo = useCallback((url) => {
    setVideoUrl(url);
    setIsOpenVideo(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpenVideo(false);
  }, []);

  const handleDragStart = (event, item) => {
    event.dataTransfer.setData("text/plain", item.id);
  };
  const handleClick = async (itemId) => {
    // eslint-disable-next-line
    const draggedItem = data.find((item) => item.id == itemId.id);

    if (draggedItem) {
      const selectedWorkout = workoutlist.find(
        (workout) => workout.id === isActive
      );
      const findWorkoutlistIndex = workoutlist.findIndex(
        (workout) => workout.id === isActive
      );

      if (selectedWorkout) {
        const isAlreadyAdded = selectedWorkout.exercises.some(
          (exercise) => exercise.id === draggedItem.id
        );
        if (!isAlreadyAdded) {
          dispatch(
            gymAdminActions.setWorkoutNameInExercise({
              WorkoutId: selectedWorkout?.id,
              ExerciseId: draggedItem?.id,
              WorkoutName: selectedWorkout?.workoutName,
            })
          );
          let updatedDraggedItem;
          if (draggedItem?.resistancename === constants.RUBBER_BAND) {
            updatedDraggedItem = {
              ...draggedItem,
              Resistance_value: bands?.length > 0 ? bands[0]?.bandLevel : null,
              Tracking_value: 10,
              set: 3,
              isband: true,
            };
          } else {
            const data = {
              traineeId: currentTrainee?.id,
              exerciseId: draggedItem?.id,
            };
            setLoadingExercise(true);
            const response = await dispatch(
              getLastPerformedExerciseDetail(data)
            );
            if (response && response?.isLastPerformedExercise) {
              updatedDraggedItem = {
                exerciseName: draggedItem.exerciseName,
                id: draggedItem.id,
                Resistance_value: response.bestSet
                  ? response.bestSet.resistance_value
                  : null,
                Tracking_value: response.bestSet
                  ? response.bestSet.tracking_value
                  : null,
                set: response.totalSets,
                isband: false,
                bandColor: null,
                Sets: response.Sets,
                intensityTracking: response.intensityTracking,
                mobility_technique: response.mobilityTechnique,
                muscle_technique: response.muscleTechnique,
                control_technique: response.controlTechnique,
                boostWeight:
                  response.Sets && response.Sets[0]
                    ? response.Sets[0].isBoost
                    : null,
                machineName: draggedItem?.machine_name || null,
                resistancetype: response?.Exercise?.resistancetype,
                resistancename: response?.Exercise?.resistancename,
                trackingname: response?.Exercise?.trackingname,
                trackingunit: response?.Exercise?.trackingunit,
                advanceTechnique: response.advanceTechnique,
              };
            } else {
              updatedDraggedItem = {
                exerciseName: draggedItem.exerciseName,
                id: draggedItem.id,
                Resistance_value: 10,
                Tracking_value: 10,
                set: 1,
                Sets: [
                  {
                    index: 0,
                    isBoost: false,
                    resistance_value: 10,
                    tracking_value: 10,
                    intensity_level: -1,
                  },
                ],
                isband: false,
                bandColor: null,
                intensityTracking: false,
                mobility_technique: false,
                muscle_technique: false,
                control_technique: false,
                boostWeight: false,
                machineName: draggedItem?.machine_name || null,
                resistancetype: draggedItem?.resistancetype,
                resistancename: draggedItem?.resistancename,
                trackingname: draggedItem?.trackingname,
                trackingunit: draggedItem?.trackingunit,
                advanceTechnique: null,
              };
            }
            setLoadingExercise(false);
          }
          const updatedExercises = [
            ...selectedWorkout.exercises,
            updatedDraggedItem,
          ];
          const updatedWorkoutlist = workoutlist.map((workout) => {
            if (workout.id === findWorkoutlistIndex) {
              return { ...workout, exercises: updatedExercises };
            }
            return workout;
          });
          setWorkoutlist(updatedWorkoutlist);
        } else {
          CustomToastify(
            t("EXERCISED_ADDED"),
            "",
            "",
            "bg-toastFaildbg",
            direction,
            Images.toastFail,
            false
          );
        }
      }
    }
  };
  const actionHistory = (data) => {
    setExerciseHistoryModal(data);
  };
  const onHistoryClick = (exerciseId, traineeId, exerciseName) => {
    const payload = {
      exerciseId: exerciseId,
      traineeId: traineeId,
      exerciseName: exerciseName,
    };
    setIsOpenModal(true);

    dispatch(ExerciseHistory(payload, t, actionHistory, direction));
  };

  return (
    <>
      <Modal open={isOpenVideo} handleClose={closeModal}>
        {videoUrl && <ReactPlayer url={videoUrl} />}
      </Modal>
      {loader ? (
        <div className="flex flex-col justify-center items-center h-[400px]">
          <Loader className="" size="w-6 h-6" fill="#003353" />
        </div>
      ) : (
        <div className={` flex flex-col w-full ${isOpen ? "h-[87%]" : "h-14"}`}>
          <div
            className={`flex items-center justify-between p-4 text-sm ${isOpen ? "bg-borderColor" : "bg-textFieldColor"}  text-white  w-full h-14 ${
              isOpen ? "rounded-t-[12px]" : "rounded-[12px]"
            }`}
            onClick={toggleDropdown}
          >
            <div className="flex">
              <img
                src={
                  direction === "ltr"
                    ? isOpen
                      ? Images.DROPDOWNICON
                      : Images.backArrow
                    : isOpen
                      ? Images.DROPDOWNICON
                      : Images.DropDown_Left
                }
                alt=""
                height={20}
                width={20}
                className={`items-center cursor-pointer`}
                onClick={() => {}}
              />
              <span
                className={`mx-2 ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"} text-[16px] text-white leading-5`}
              >
                {t("EXERCISES")}
              </span>
            </div>
            <div className="flex">
              {exerciseLoader ? (
                <Loader className="" size="w-6 h-6" fill="#003353" />
              ) : (
                <span
                  className={`${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"} text-[12px] font-normal text-white leading-5`}
                >
                  {t("RESULT")} ({data?.length})
                </span>
              )}
            </div>
          </div>

          {isOpen && (
            <div
              id="left-container"
              className="w-full h-full  p-3 gap-2  overflow-y-auto bg-textFieldColor  rounded-b-[12px] pb-16 has-scrollbar"
            >
              {data.map((item, index) => {
                return (
                  <div key={index} id={"left-container" + index}>
                    <div
                      className="flex flex-col w-full  items-center py-2 px-2  bg-statusBg my-2 rounded-[12px] overflow-x-hidden "
                      draggable={true}
                      onDragStart={(event) => handleDragStart(event, item)}
                    >
                      <div className="flex w-full  items-center py-1  ">
                        <div className="flex flex-row items-center gap-x-2 w-1/2 ">
                          <img
                            src={Images.Plus}
                            alt=""
                            height={10}
                            width={10}
                            className={`flex items-center cursor-pointer`}
                            onClick={() => handleClick(item)}
                          />
                          <Tooltip
                            content={item?.exerciseName}
                            placement="top"
                            className={`tooltip !rounded-lg ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"} `}
                          >
                            <span
                              className={`ltr:font-primary-rg rtl:font-primary-he-rg
                              } text-white  text-sm font-normal  cursor-pointer w-full truncate `}
                            >
                              {item?.exerciseName}
                            </span>
                          </Tooltip>
                        </div>
                        <div className="flex flex-row items-center gap-x-2 w-1/2 justify-end">
                          {item?.count !== 0 && (
                            <div
                              className={`flex py-1 px-2 rounded-lg bg-lightGreen`}
                            >
                              <p
                                className={`text-textFieldColor text-xs  ltr:font-primary-rg rtl:font-primary-he-rg`}
                              >
                                {t("DONE")}
                                {item?.count}{" "}
                              </p>
                            </div>
                          )}
                          <img
                            src={
                              selectedItemIndexes.includes(item.id)
                                ? Images.InfoColored
                                : Images.InfoCircle
                            }
                            alt=""
                            height={24}
                            width={24}
                            className={`items-center cursor-pointer`}
                            onClick={() => {
                              handleShowDetail(item.id);
                            }}
                          />
                          {item?.url && (
                            <img
                              src={Images.Youtube}
                              alt=""
                              height={22}
                              width={22}
                              className={`items-center cursor-pointer`}
                              onClick={() => {
                                handleShowVideo(item?.url);
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div className="flex flex-row w-full gap-x-1 px-3 overflow-x-scroll no-scrollbar">
                        {workoutlist.map((workout, index) => {
                          const containsExercise = workout?.exercises?.some(
                            (exercise) => exercise?.id === item?.id
                          );

                          if (containsExercise) {
                            return (
                              <div
                                className={`flex bg-[#9E9DDD] p-1 rounded-[7px] w-[70px] cursor-pointer ltr:font-primary-rg rtl:font-primary-he-rg
                                }`}
                                key={index}
                              >
                                <Tooltip
                                  content={workout?.workoutName}
                                  placement="top"
                                  className={`tooltip !rounded-lg ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"} `}
                                >
                                  <p className="truncate text-[#333333] text-xs">
                                    {workout?.workoutName}
                                  </p>
                                </Tooltip>
                              </div>
                            );
                          }
                          return null; // Don't render if exercise is not in this workout
                        })}
                      </div>
                      {selectedItemIndexes.includes(item.id) && (
                        <div className="flex flex-col lg:flex-row w-full bg-statusBg text-white p-4 mt-2 rounded-[12px] gap-2">
                          {/* Render the details for the selected item here */}

                          <div className="flex flex-col w-full lg:w-1/2 gap-2">
                            <div className="flex flex-row gap-2 flex-wrap bg-rose-400 items-center exerciseInfo_innerContainer ">
                              <p
                                className={`text-white text-base ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                              >
                                {t("MUSCLES")}
                              </p>

                              {item?.Muscles?.map((val, index) => (
                                <div
                                  className={`flex py-1 px-2 rounded-lg ${index < 3 ? "bg-[#70ACBF] " : "bg-lightGreen"} `}
                                >
                                  <p
                                    className={`text-textFieldColor text-xs  ltr:font-primary-rg rtl:font-primary-he-rg`}
                                  >
                                    {val?.englishName}{" "}
                                  </p>
                                </div>
                              ))}
                            </div>
                            <div className="exerciseInfo_innerContainer ">
                              <div className="flex h-full flex-col w-full gap-2 ">
                                <div className="flex flex-row justify-between overflow-hidden flex-wrap ">
                                  <div className="flex gap-1">
                                    <p
                                      className={`exerciseInfo_itemHeading ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                                    >
                                      {t("COMPLETED")}
                                    </p>
                                    <p
                                      className={`exerciseInfo_item ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                                    >
                                      {item?.count}
                                    </p>
                                  </div>
                                  {item?.lastPerformed != null && (
                                    <div
                                      className="flex gap-2 cursor-pointer"
                                      onClick={() => {
                                        onHistoryClick(
                                          item.id,
                                          currentTrainee?.id,
                                          item?.exerciseName
                                        );
                                      }}
                                    >
                                      <p
                                        className={`exerciseInfo_itemHeading ltr:font-primary-rg rtl:font-primary-he-rg border-b-[1px] border-white`}
                                      >
                                        {t("HISTORY")}
                                      </p>
                                    </div>
                                  )}
                                </div>
                                {item?.lastPerformed != null && (
                                  <div className="flex flex-row gap-2 overflow-hidden flex-wrap ">
                                    <p
                                      className={`exerciseInfo_itemHeading ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                                    >
                                      {t("LAST_PERFORMED")}
                                    </p>
                                    <p
                                      className={`exerciseInfo_item ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                                    >
                                      {formatDate(t, item?.lastPerformed)}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            {item?.note != null && (
                              <div className="exerciseInfo_innerContainer ">
                                <div className="flex h-full flex-col w-full gap-2 ">
                                  <p className="exerciseInfo_item">
                                    {t("NOTES")}
                                  </p>
                                  <p className="exerciseInfo_item !leading-5">
                                    {item?.note}
                                  </p>
                                </div>
                              </div>
                            )}

                            {item?.tags && item?.tags !== null && (
                              <div className="exerciseInfo_innerContainer h-full">
                                <div className="flex flex-row gap-2 ">
                                  <p
                                    className={`exerciseInfo_itemHeading ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                                  >
                                    {t("TAGS")}
                                  </p>

                                  <p
                                    className={`exerciseInfo_item gap-2 ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                                  >
                                    {item.tags.split(",").map((tag, index) => (
                                      <span
                                        className={`exerciseInfo_item  ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                                        key={index}
                                      >
                                        #{tag.trim()}{" "}
                                      </span>
                                    ))}
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="flex flex-col w-full lg:w-1/2 gap-2">
                            {item?.otherVariants &&
                              item?.otherVariants != null &&
                              item?.otherVariants.length > 0 && (
                                <div className="exerciseInfo_innerContainer">
                                  <div className="flex flex-col w-full gap-2 ">
                                    <div
                                      className={`${
                                        direction === "ltr"
                                          ? "font-primary-rg"
                                          : "font-primary-he-rg"
                                      } text-white text-[14px] leading-[18px]`}
                                    >
                                      {t("OTHER_VARIATIONS")}
                                    </div>
                                    {item?.otherVariants?.map(
                                      (otherVarient) => (
                                        <div
                                          className="exerciseInfo_itemContainer cursor-pointer w-full"
                                          onClick={() =>
                                            handleClick(otherVarient)
                                          }
                                        >
                                          <p
                                            className={`exerciseInfo_itemHeading truncate  ${
                                              direction === "ltr"
                                                ? "font-primary-rg"
                                                : "font-primary-he-rg"
                                            }`}
                                          >
                                            {otherVarient?.exerciseName}
                                          </p>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              )}
                            <div className="flex bg-[#41565F] px-2 pt-2 pb-6 rounded-[8px]  ">
                              {/* ------------Exercise Info ---------------- */}
                              <div className="flex  flex-col w-full gap-2 ">
                                <div className="flex flex-row justify-between  flex-wrap">
                                  <p
                                    className={` exerciseInfo_itemHeading ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                                  >
                                    {t("TRACKING_Exercise")}
                                  </p>
                                  <p
                                    className={`exerciseInfo_item ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                                  >
                                    {t(item?.trackingname)}
                                  </p>
                                </div>
                                <div className="flex flex-row justify-between flex-wrap">
                                  <p
                                    className={`exerciseInfo_itemHeading ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                                  >
                                    {t("RESISTANCE_EXERCISE")}
                                  </p>
                                  <p
                                    className={` exerciseInfo_item ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                                  >
                                    {t(item?.resistancename)}
                                  </p>
                                </div>
                                <div className="flex flex-row justify-between flex-wrap">
                                  <p
                                    className={`exerciseInfo_itemHeading ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                                  >
                                    {t("DIFFICULTY_LEVEL")}
                                  </p>
                                  <p
                                    className={` exerciseInfo_item ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                                  >
                                    {t(item?.difficultyLevel)}
                                  </p>
                                </div>
                                {item?.item?.machine_name && (
                                  <div className="flex flex-row justify-between flex-wrap">
                                    <p
                                      className={`exerciseInfo_itemHeading ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                                    >
                                      {t("MACHINE")}
                                    </p>
                                    <p
                                      className={` exerciseInfo_item ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                                    >
                                      {t(item?.machine_name)}
                                    </p>
                                  </div>
                                )}
                                {item?.tool != null && (
                                  <div className="flex flex-row justify-between flex-wrap">
                                    <p
                                      className={`exerciseInfo_itemHeading ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                                    >
                                      {t("TOOL")}
                                    </p>
                                    <p
                                      className={` exerciseInfo_item ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
                                    >
                                      {t(item?.tool)}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
      <PlayWorkoutExerciseHistory
        isopen={isOpenModal}
        show={exerciseHistoryModal}
        setShow={setIsOpenModal}
        historyLoader={historyLoader}
      />
    </>
  );
}
