import React, { useState } from 'react'
import { Modal } from 'components/elements/Modal'
import PrimaryButton from 'components/elements/PrimaryButton'
import { Images } from 'assets'

export default function DeleteModal({
  setOpenModal,
  openModal,
  notCrossIcon,
  t,
  ID,
  direction,
  des,
  title,
  action,
  handleSubmit,
  showPass,
  error,
  setError,
  password,
  setPassword,
  loading,
  primary,
  confirmText,
  cancelText,
  handleCancel,
}) {
  const [passwordShown, setPasswordShown] = useState(false)
  const toggleOpen = () => {
    setOpenModal(!openModal)
  }
  const handleClose = () => {
    setError && setError(false)
    setPassword && setPassword('')
    setOpenModal(false)
  }
  const togglePassword = () => {
    setPasswordShown(!passwordShown)
  }


  return (
    <Modal open={openModal} className="p-1" handleClose={handleClose} notCrossIcon={true} style={{ zIndex: 2000 }}>
      <div className=" p-6 w-72 md:w-80  bg-checkBoxGray rounded-[16px]  sm:p-3">
        <p className={`mb-4 text-[#FFF] text-[16px] ${direction === 'ltr' ? 'font-primary-md' : 'font-primary-he-md'}`}>{title}</p>
        <p className={`mb-6 text-[#FFF] text-[14px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>{des}</p>
        {showPass && (
          <div className="flex flex-col py-1 w-full">
            <div className="flex flex-row w-full h-[56px] items-center justify-between  rounded-[12px] bg-textFieldColor">
              <input
                value={password}
                className={` w-full h-[56px] rounded-[12px] bg-textFieldColor px-2 ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                  } `}
                type={passwordShown ? 'text' : 'password'}
                placeholder={'*******'}
                onChange={(e) => {
                  setError(false)
                  setPassword(e.target.value)
                }}
              />

              <img
                src={passwordShown ? Images.Eye : Images.EyeClosed}
                alt=""
                height={40}
                width={45}
                className={`px-3 items-center cursor-pointer z-10 `}
                onClick={togglePassword}
              />
            </div>
            {error && (
              <p
                className={`form-errors flex flex-col px-1 ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} ${direction === 'rtl' ? 'text-right' : 'text-left'
                  } `}
              >
                {t('PASSWORD_REQUIRED')}
              </p>
            )}
          </div>
        )}
        {showPass ? (
          <div className="flex justify-between items-center mt-2">
            <button
              type="submit"
              className={`w-full h-10 text-sm ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                } p-1 text-center text-white bg-[#100e0e] rounded-[12px]  ${primary
                  ? 'hover:bg-[#00639B]  '
                  : password?.trim()?.length > 0
                    ? 'hover:bg-[#DE3730] focus:ring-4 focus:outline-none dark:bg-[#DE3730] dark:focus:bg-[#DE3730]'
                    : 'hover:bg-[#100e0e] focus:ring-4 focus:outline-none dark:bg-[#100e0e] dark:focus:bg-[#100e0e]'
                }`}
              onClick={handleSubmit}
            >
              {t('DELETION_AGREEMENT')}
            </button>
          </div>
        ) : (
          <div className="flex justify-between items-center mt-2 gap-x-2">
            <button
              type="submit"
              className={`w-full h-10  text-sm ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                } p-1 text-center border-[#72777f] border-[1px] hover:bg-[#100e0e] text-white bg-checkBoxGray rounded-[12px]  focus:ring-4 focus:outline-none `}
              onClick={() => {
                handleCancel ? handleCancel() : toggleOpen()
              }}
            >
              {cancelText ? cancelText : t('No')}
            </button>
            {loading ? (
              <PrimaryButton
                text={t('LOGIN')}
                type="submit"
                disabled={false}
                isIcon={false}
                className="w-full h-10 rounded-[12px] shadow-2xl my-1"
                bgColor="bg-prussianBlue hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
                textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
                Loader={loading}
              />
            ) : (
              <button
                type="submit"
                className={`w-full h-10  text-sm p-1 ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                  } text-center text-white bg-[#100e0e] rounded-[12px] 
                
                ${primary
                    ? 'hover:bg-[#00639B] focus:ring-4 focus:outline-none dark:bg-[#00639B] dark:focus:bg-[#00639B]'
                    : 'hover:bg-[#de3730] focus:ring-4 focus:outline-none dark:bg-[#de3730] dark:focus:bg-[#de3730]'
                  }
                
                `}
                onClick={handleSubmit}
              >
                {loading ? t('Loading') : confirmText ? confirmText : t('YES_DEL')}
              </button>
            )}
          </div>
        )}
      </div>
    </Modal>
  )
}
