import { authActions } from 'store/authSlice/authReducers'
import { coachActions } from 'store/coachSlice/coachReducers'
import { dasboardActions } from 'store/dashboardSlice/dashboardReducers'
import { exerciseActions } from 'store/exerciseSlice/exerciseReducers'
import { gymAdminActions } from 'store/gymAdminSlice/gymAdminReducers'
import { languageActions } from 'store/languageSlice'
import { muscleActions } from 'store/muscleSlice/muscleReducers'
import { programActions } from 'store/programSlice/programReducers'
import { traineeActions } from 'store/traineeSlice/traineeReducer'
import { userActions } from 'store/userSlice/userReducer'
import moment from 'moment'
export const format_Date = (date) => {
  try {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [day, month, year].join('/')
  } catch (ex) {}
}

export const format_Date_to_Days = (date, t) => {
  try {
    const createdDate = new Date(date)
    const currentDate = new Date()
    const diffTime = Math.abs(currentDate.getTime() - createdDate.getTime())
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

    const formattedDate = `${createdDate.getDate().toString().padStart(2, '0')}.${(createdDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}.${createdDate.getFullYear()} (${diffDays} ${t('DAYS')})`

    return formattedDate
  } catch (ex) {}
}

export const handleFilterSelection = (filterKey, option, state, setState, type) => {
  if (state[filterKey]?.length > 0) {
    let element = null
    // eslint-disable-next-line
    if (filterKey != 'MUSCLE_TYPE') {
      element = state[filterKey].find((item) => item === option.label)
    } else {
      element = state[filterKey].find((item) => item?.label === option.label)
    }

    if (element) {
      let updatedItems = null
      // eslint-disable-next-line
      if (filterKey != 'MUSCLE_TYPE') {
        updatedItems = state[filterKey].filter((i) => i !== option.label)
        if (filterKey === 'MUSCLES') {
          let updateMuscleType = state['MUSCLE_TYPE'].filter((i) => i.label !== option.label)

          setState({
            ...state,
            [filterKey]: updatedItems,
            // eslint-disable-next-line
            ['MUSCLE_TYPE']: updateMuscleType,
          })
        } else {
          setState({
            ...state,
            [filterKey]: updatedItems,
          })
        }
      } else {
        const isPrimary = type === 'primary' ? true : false
        const newState = { ...state }
        const index = state[filterKey].findIndex((item) => item.label === option.label)
        if (isPrimary) {
          if (newState[filterKey][index].isPrimary === true) {
            newState[filterKey][index].isPrimary = false
          } else {
            newState[filterKey][index].isPrimary = true
          }
        } else {
          if (newState[filterKey][index].isAssistance === true) {
            newState[filterKey][index].isAssistance = false
          } else {
            newState[filterKey][index].isAssistance = true
          }
        }
        setState(newState)
      }
    } else {
      // eslint-disable-next-line
      if (filterKey != 'MUSCLE_TYPE') {
        setState({
          ...state,
          [filterKey]: [...state[filterKey], option.label],
        })
      } else {
        setState({
          ...state,
          [filterKey]: [
            ...state[filterKey],
            { label: option.label, isPrimary: type === 'primary' ? true : false, isAssistance: type === 'assitant' ? true : false },
          ],
        })
      }
    }
  } else {
    // eslint-disable-next-line
    if (filterKey != 'MUSCLE_TYPE') {
      setState({
        ...state,
        [filterKey]: [...state[filterKey], option.label],
      })
    } else {
      setState({
        ...state,
        [filterKey]: [
          ...state[filterKey],
          { label: option.label, isPrimary: type === 'primary' ? true : false, isAssistance: type === 'assitant' ? true : false },
        ],
      })
    }
  }
}

export const stopPropagation = (e) => {
  e.stopPropagation()
}

export const formattedDate = (date) => {
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

  // Parse the date string into a Date object
  const dt = new Date(date)

  // Get the day of the week (0: Sunday, 1: Monday, ..., 6: Saturday)
  const dayOfWeekIndex = dt.getUTCDay()

  // Get the day of the month
  const dayOfMonth = dt.getUTCDate()

  // Get the month (Note: January is represented by 0, so we need to add 1)
  const month = dt.getUTCMonth() + 1

  // Format the day of the week, day of the month, and month as "Day DD.MM"
  const formattedDate = `${daysOfWeek[dayOfWeekIndex]} ${dayOfMonth.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}`
  return formattedDate
}

export const formatDateAndTimeForComments = (inputDate) => {
  // Convert the inputDate to a Date object
  const dateObj = new Date(inputDate)

  // Format the date as "17 Jun 2023"
  const formattedDate = dateObj.toLocaleString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })

  // Format the time as "18:25"
  const formattedTime = dateObj.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
  })

  // Combine the formatted date and time with the desired separator
  const formattedDateTime = `${formattedDate} | ${formattedTime}`

  return formattedDateTime
}
export const lastPerformedDate = (inputDate) => {
  const dateTime = new Date(inputDate)

  const formattedDate = `${dateTime.getMonth() + 1}.${dateTime.getDate()}.${dateTime.getFullYear().toString().slice(-2)}`

  return formattedDate
}

export const getBestResistanceValue = (sets) => {
  let highestResistanceValue = 0

  // Iterate through the sets list to find the highest resistance value
  for (const set of sets) {
    if (set.resistance_value > highestResistanceValue) {
      highestResistanceValue = set.resistance_value
    }
  }

  return highestResistanceValue
}
export const getBestTrackingValue = (sets) => {
  let highestTrackingValue = 0

  // Iterate through the sets list to find the highest resistance value
  for (const set of sets) {
    if (set.tracking_value > highestTrackingValue) {
      highestTrackingValue = set.tracking_value
    }
  }

  return highestTrackingValue
}

export const exerciseDoneDate = (date) => {
  const options = { day: '2-digit', month: 'short' }
  const formattedDate = new Date(date).toLocaleDateString('en-US', options)
  return formattedDate
}

export const getResistanceLabel = (resistanceType) => {
  const list = { machine: 'kg', weight: 'kg', distance: 'km', incline: 'c', rubberband: 'level', repeat: 'repeat', time: 'time', kg: 'kg' }
  return list[resistanceType?.toLowerCase()] || ''
}
export const getResistanceLabelConfetti = (resistanceType) => {
  const list = { machine: 'weight', weight: 'weight', distance: 'distance', incline: 'incline', rubberband: 'weight', repeat: 'repeat', time: 'time' }
  return list[resistanceType?.toLowerCase()] || ''
}

export const formatDateOnlyMonth = (t, inputDate) => {
  // Convert the inputDate to a Date object 12 Sep

  const parsedDate = new Date(inputDate)
  const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
  const formattedDate = `${parsedDate.getUTCDate()} ${t(monthNames[parsedDate.getUTCMonth()])}`

  return formattedDate
}
export const formatDate = (t, date) => {
  // Convert the inputDate to a 09.05.2023
  const inputDate = new Date(date)

  const day = String(inputDate.getUTCDate()).padStart(2, '0')
  const month = String(inputDate.getUTCMonth() + 1).padStart(2, '0') // Months are zero-based, so add 1.
  const year = inputDate.getUTCFullYear()

  const formattedDate = `${month}.${day}.${year}`

  return formattedDate
}

export const handleLogout = async (dispatch, navigate) => {
  dispatch(authActions.resetAuth())
  dispatch(userActions.resetUser())
  dispatch(coachActions.resetCoach())
  dispatch(exerciseActions.resetExercise())
  dispatch(gymAdminActions.resetGymAdmin())
  dispatch(muscleActions.resetMuscle())
  dispatch(programActions.resetProgram())
  dispatch(traineeActions.resetTrainee())
  dispatch(languageActions.setLanguage('en'))
  dispatch(dasboardActions.reset())
  navigate('/login')
}

export const isEventDatePassed = (endDateStr, endTimeStr) => {
  // Your given end date and time

  // Parse the endDate string into a Date object
  const endDate = new Date(endDateStr)

  // Get the current date and time
  const currentDate = new Date()

  // Extract the hours and minutes from the endTime string
  const [endHour, endMinute] = endTimeStr.split(':').map(Number)

  // Set the end time to the parsed endHour and endMinute
  endDate.setHours(endHour, endMinute, 0, 0)

  // Compare the endDate with the current date and time
  if (endDate <= currentDate) {
    return true
  } else {
    return false
  }
}
export const getTranslatedDate = (date, t, moment) => {
  // Monday Oct 09
  const momentDate = moment(date)
  const day = momentDate.format('dddd') // Get the day
  const month = momentDate.format('MMM') // Get the month

  return `${t(day)} ${t(month)} ${momentDate.format('DD')}`
}
export const getCurrentTranslatedDateAndTime = (t, moment) => {
  // Monday Oct 09
  const momentDate = moment()
  const day = momentDate.format('dddd') // Get the day
  const month = momentDate.format('MMM') // Get the month

  return `${t(day)} ,${momentDate.format('DD')} ${t(month)} `
}
export const getWorkoutDateAndTime = (date, t, moment) => {
  // Monday Oct 09
  const momentDate = moment(date)
  const day = momentDate.format('dddd') // Get the day
  const month = momentDate.format('MMM').toUpperCase() // Get the month

  return `${t(day)} ,${momentDate.format('DD')} ${t(month)} `
}
export const getWorkoutDate = (date, t, moment) => {
  // Oct 09
  const momentDate = moment(date)
  const month = momentDate.format('MMM').toUpperCase() // Get the month

  return `${t(month)} ${momentDate.format('DD')}  `
}
export const getTime = (time) => {
  if (time) {
    const formattedTime = time?.split(':')
    return `${formattedTime[0]}:${formattedTime[1]}`
  } else {
    return null
  }
}
export const generateTimeValues = (startHour, endHour) => {
  const [startHours, startMinutes] = startHour.split(':').map(Number)
  const [endHours, endMinutes] = endHour.split(':').map(Number)

  const startTime = new Date(0, 0, 0, startHours, startMinutes)
  const endTime = new Date(0, 0, 0, endHours, endMinutes)
  const interval = 15 // 15-minute interval in minutes

  const timeValues = []

  let currentTime = new Date(startTime)
  while (currentTime <= endTime) {
    const hours = currentTime.getHours().toString().padStart(2, '0')
    const minutes = currentTime.getMinutes().toString().padStart(2, '0')

    const formattedTime = `${hours}:${minutes}`
    timeValues.push(formattedTime)

    currentTime.setMinutes(currentTime.getMinutes() + interval)
  }

  return timeValues
}
export const addDurationToTime = (selectedTime, duration) => {
  const parts = selectedTime.split(':')
  const hours = parseInt(parts[0], 10)
  const minutes = parseInt(parts[1], 10)
  // const seconds = parseInt("00", 10);

  // Calculate the new time
  const totalMinutes = hours * 60 + minutes + duration
  let newHours = Math.floor(totalMinutes / 60)
  const newMinutes = totalMinutes % 60

  // Handle overflow of hours and minutes
  if (newHours >= 24) {
    newHours %= 24
  }
  // Format the updated time
  const newTime = `${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`
  return newTime
}
export const roundMinutes = (startDate) => {
  const startDateMinutes = startDate.minutes()
  if (startDateMinutes >= 1 && startDateMinutes <= 15) {
    startDate.minutes(15)
  } else if (startDateMinutes >= 16 && startDateMinutes <= 30) {
    startDate.minutes(30)
  } else if (startDateMinutes >= 31 && startDateMinutes <= 45) {
    startDate.minutes(45)
  } else if (startDateMinutes >= 46) {
    startDate.add(1, 'hour').minutes(0)
  }
  return startDate
}
export const getCalendarEventsTime = (eventInfo, oldEvent) => {
  const { event: calEvent } = eventInfo
  const date = moment(calEvent.start)
  const offsetDifference = date.utcOffset()
  const hours = offsetDifference / 60

  let startDate = moment(calEvent.start)
  let endDate = moment(calEvent.end)

  const newDateStart = startDate.clone().subtract(hours, 'hours')
  startDate = newDateStart.utcOffset(0, true)

  const newDateEnd = endDate.clone().subtract(hours, 'hours')
  endDate = newDateEnd.utcOffset(0, true)
  roundMinutes(startDate)
  const changedStartTime = startDate.format('HH:mm')

  const newEndTime = addDurationToTime(changedStartTime, oldEvent?.extendedProps?.eventDuration)
  return { changedStartTime, newEndTime }
}
export const formatDateSummary = (inputDate, t) => {
  //24 may 2023 | 17:13
  const dateObject = new Date(inputDate)

  const day = dateObject.getDate()
  const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(dateObject)
  const year = dateObject.getFullYear()
  const hours = dateObject.getHours()
  const minutes = dateObject.getMinutes()

  const customFormattedDate = `${day} ${t(month.toUpperCase())} ${year} | ${hours}:${minutes < 10 ? '0' : ''}${minutes}`

  return customFormattedDate
}

// Example usage
