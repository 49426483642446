export default function SwitchToggle({ name, t, handleChange, checked, className, disable }) {
  return (
    <div className={`flex ${className && className} items-center ltr:space-x-3 ${disable && 'opacity-50'}`}>
      {name && <span className={`text-white ltr:font-primary-rg rtl:font-primary-he-rg text-[14px] rtl:pl-3 `}>{t(name)}</span>}
      <label className="switch-toggle">
        <input type="checkbox" checked={checked} onChange={handleChange} disabled={disable} />
        <span className="slider"></span>
      </label>
    </div>
  )
}
