import { useState, useEffect } from 'react'
const useDebounce = (value, delay, returnPreviousValue = false) => {
  const [debouncedValue, setDebouncedValue] = useState(value)
  const [previousValue, setPreviousValue] = useState('')
  useEffect(() => {
    const handler = setTimeout(() => {
      setPreviousValue(debouncedValue)
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
    // eslint-disable-next-line
  }, [value, delay])
  if (returnPreviousValue) {
    return [debouncedValue, previousValue]
  }
  return debouncedValue
}

export default useDebounce
