import { useTranslation } from 'react-i18next'
import { BodyMapPercentage } from './components/bodyMapPercentage'
import { useDispatch, useSelector } from 'react-redux'
import { Images } from 'assets'
import Skelton from 'components/compounds/Skelton'
import MusclePercentageInfo from './components/musclePercentageInfo'
import MuscleExercises from './components/muscleExercises'
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { getProgramMuscleExercises, getProgramSelectedExerciseAllHistory, getProgramSelectedExerciseDetail } from 'store/programSlice/programActions'

export default function MuscleInfo({
  data,
  singleMuscle,
  setSingleMuscle,
  filters,
  selectedWeek,
  programfilterOptions,
  setSelectedExercise,
  selectedExercise,
  setShow,
}) {
  const { t } = useTranslation()
  const { loader, programStatistics, programStatisticsFilters, exercisesOfMuscle, selectedExerciseDetail } = useSelector((state) => state.program)
  const { direction } = useSelector((state) => state.language)

  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = useState('')
  const programId = searchParams?.get('programId')
  const query = useParams()
  const dispatch = useDispatch()

  const getMuscleIds = (muscles) => {
    let muscleIds = []

    if (muscles?.length > 0) {
      muscleIds = muscles?.map((item) => {
        if (item?.percentages != 0) {
          return item.muscle_Modal
        }
      })
    }

    return muscleIds
  }
  const getSelectedExerciseMuscleIds = (muscles) => {
    let muscleIds = []

    if (muscles?.length > 0) {
      muscleIds = muscles?.map((item) => item?.Muscle?.master_id)
    }

    return muscleIds
  }
  const getMusclePercentages = (muscles) => {
    let muscleIdsWithPercentages = {}
    if (muscles?.length > 0) {
      muscles.forEach((item) => {
        muscleIdsWithPercentages[item.muscle_Modal] = item.percentages
      })
    }

    return muscleIdsWithPercentages
  }

  const selectMuscle = async (val) => {
    setSingleMuscle(val)
  }
  useEffect(() => {
    if (singleMuscle != null && selectedWeek) {
      let workoutPatternId = []
      if (filters.WORKOUT_PATTERN.length > 0) {
        workoutPatternId = programfilterOptions?.filter((opt) => filters.WORKOUT_PATTERN.includes(opt.label)).map((Workout) => Workout.PatternIndex)
      }
      const data = {
        patternIds: workoutPatternId,
        currentWeek: selectedWeek.value,
        programId: programId,
        traineeId: query?.id,
        totalWeeks: programStatisticsFilters?.ValidTimeOptions,
        muscleId: singleMuscle?.muscleId,
      }
      dispatch(getProgramMuscleExercises(data, t, direction))
    }
  }, [singleMuscle, filters, selectedWeek])
  const handleSelectedExercise = (val) => {
    setSelectedExercise(val)
    if (val != null) {
      setLoading(true)

      let workoutPatternId = []
      if (filters.WORKOUT_PATTERN.length > 0) {
        workoutPatternId = programfilterOptions?.filter((opt) => filters.WORKOUT_PATTERN.includes(opt.label)).map((Workout) => Workout.PatternIndex)
      }
      const data = {
        patternIds: workoutPatternId,
        currentWeek: selectedWeek.value,
        programId: programId,
        traineeId: query?.id,
        totalWeeks: programStatisticsFilters?.ValidTimeOptions,
        muscleId: singleMuscle?.muscleId,
        exerciseId: val?.id,
      }

      dispatch(
        getProgramSelectedExerciseDetail(data, t, direction, () => {
          setLoading(false)
        })
      )
    }
  }
  const handleViewAllHistory = (value) => {
    const data = {
      exerciseName: value?.exerciseName,
      exerciseId: value?.id,
      traineeId: query?.id,
      programId: programId,
    }

    setShow(data)
    dispatch(getProgramSelectedExerciseAllHistory(data, t, direction))
  }
  const getExerciseMusclesOfSelectedExercise = (muscles) => {
    let data = []
    data = muscles?.map((item) => {
      return {
        id: item?.Muscle?.id,
        muscle_Modal: item?.Muscle?.master_id,
        muscleName: item?.Muscle?.muscleName,
      }
    })
    return data
  }

  return (
    <div className="flex w-full h-full gap-x-[15px]">
      <div className="flex flex-col w-2/6 bg-playCard rounded-xl p-6 gap-4">
        {singleMuscle != null ? (
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-x-2">
              <img
                src={Images.ExitArrow}
                alt=""
                height={16}
                width={16}
                className="items-center cursor-pointer ltr:rotate-180"
                onClick={() => {
                  setSelectedExercise(null)
                  setSingleMuscle(null)
                }}
              />
              <div className="flex gap-x-1 items-center">
                <h3 className=" text-xl text-textLightGray ltr:font-primary-rg rtl:font-primary-he-rg font-medium">{t(singleMuscle?.muscleName)}</h3>
                <h5 className=" text-xs text-extralightGray ltr:font-primary-rg rtl:font-primary-he-rg font-normal">({singleMuscle.percentages}%)</h5>
              </div>
            </div>
            <h5 className=" text-xs text-extralightGray ltr:font-primary-rg rtl:font-primary-he-rg font-normal">
              {singleMuscle?.setsNumber}/{singleMuscle?.totalSetsNumber} {t('SETS')}
            </h5>
          </div>
        ) : (
          <h3 className=" text-xl text-textLightGray ltr:font-primary-rg rtl:font-primary-he-rg font-medium">{t('Program Muscles')}</h3>
        )}
        <div className="flex flex-col w-full h-full  gap-y-2 overflow-y-scroll no-scrollbar">
          {loader ? (
            <div className="w-full h-[90%]  overflow-hidden">
              <Skelton bgColor="bg-textFieldColor" />
            </div>
          ) : (
            <>
              {singleMuscle != null ? (
                <MuscleExercises
                  t={t}
                  setSelectedExercise={handleSelectedExercise}
                  selectedExercise={selectedExercise}
                  exercisesOfMuscle={exercisesOfMuscle}
                  loading={loading}
                  selectedExerciseDetail={selectedExerciseDetail}
                  handleShow={handleViewAllHistory}
                />
              ) : (
                <MusclePercentageInfo programStatistics={programStatistics} t={t} handleSelect={selectMuscle} />
              )}
            </>
          )}
        </div>
      </div>
      <div className="flex justify-center items-center w-4/6 bg-playCard rounded-xl p-6 gap-4">
        <BodyMapPercentage
          frontids={
            singleMuscle != null
              ? selectedExercise
                ? getSelectedExerciseMuscleIds(selectedExerciseDetail?.workout?.WorkoutExercises[0]?.Exercise?.ExerciseMuscles)
                : [singleMuscle?.muscle_Modal]
              : getMuscleIds(programStatistics)
          }
          backIds={
            singleMuscle != null
              ? selectedExercise
                ? getSelectedExerciseMuscleIds(selectedExerciseDetail?.workout?.WorkoutExercises[0]?.Exercise?.ExerciseMuscles)
                : [singleMuscle?.muscle_Modal]
              : getMuscleIds(programStatistics)
          }
          musclePercentages={
            singleMuscle != null ? { [singleMuscle?.muscle_Modal]: singleMuscle.percentages } : getMusclePercentages(programStatistics)
          }
          hoverable={false}
          h={600}
          w={300}
          data={
            selectedExercise != null
              ? getExerciseMusclesOfSelectedExercise(selectedExerciseDetail?.workout?.WorkoutExercises[0]?.Exercise?.ExerciseMuscles)
              : data
          }
          showModal={true}
          showPercentage={selectedExercise ? false : true}
        />
      </div>
    </div>
  )
}
