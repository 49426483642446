import { yupResolver } from '@hookform/resolvers/yup'
import _ from 'lodash'
import ListTable from 'components/List/ListTable'
import { ProfileAvatar } from 'components/PlayBoard/ProfileAvatar'
import DeleteModal from 'components/compounds/DeleteModal'
import { Loader } from 'components/compounds/Loader'
import CreateTrainee from 'components/trainees/CreateTrainee'
import TraineesListHeader from 'components/trainees/TraineesListHeader'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { DeleteTraineeByGymAdmin } from 'store/gymAdminSlice/gymAdminActions'
import { traineeActions } from 'store/traineeSlice/traineeReducer'
import { traineeSchema } from 'validations/formsValidations'

export default function Trainees() {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)
  const [searchText, setSearchText] = useState()
  const [gymID, setGymID] = useState(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { trainees, loader } = useSelector((state) => state.gymAdmin)
  const { direction } = useSelector((state) => state.language)
  const { user } = useSelector((state) => state.auth)
  const [openModal, setOpenModal] = useState(false)
  const [error, setError] = useState(false)
  const [password, setPassword] = useState('')
  const { customerDetail } = useSelector((state) => state.user)
  const [traineeList, setTraineeList] = useState(trainees)
  const headerRows = {
    data: [
      {
        id: 'User.avatar',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'custom',
        disablePadding: false,
        label: 'IMAGE',
        sort: false,
        CustomComponent: (item) => {
          const img = item?.data?.User?.avatar

          return <ProfileAvatar image={img} name={item?.data?.User?.firstName} />
        },
      },
      {
        id: 'User.firstName',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'Objtext',
        disablePadding: false,
        label: 'FULL_NAME',
        sort: false,
      },

      {
        id: 'createdAt',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'daysfromDays',
        disablePadding: false,
        label: 'WHEN_IT_CREATED',
        sort: true,
      },
      {
        id: 'programs',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'custom',
        disablePadding: false,
        label: 'ACTIVE_PROGRAM',
        sort: true,
        CustomComponent: (item) => {
          return (
            <>
              {item.data.isActiveProgram ? (
                <div className={`flex ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-base font-normal text-white`}>
                  {item.data.programs[0].program_name} ({item.data.programs[0].pattern_workouts_count})
                </div>
              ) : (
                <div className="flex h-[40px] w-[99px] rounded-[12px] justify-center items-center bg-[#00639B]">
                  <div
                    className={`${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-white text-[14px] leading-[18px] italic`}
                    onClick={(e) => {
                      e.stopPropagation()
                      dispatch(traineeActions.setProgramsEmpty())

                      navigate(`/admin/createProgram/${item?.data?.id}`, { state: { active: null, traineeName: item?.data?.User?.firstName } })
                    }}
                  >
                    {t('CREATE_NEW')}
                  </div>
                </div>
              )}
            </>
          )
        },
      },
      {
        id: 'trainingPerformed',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'text',
        disablePadding: false,
        label: 'NO_OF_TRAINING_PERFORMED',
        sort: true,
      },
      {
        id: 'User.isActive',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'custom',
        disablePadding: false,
        label: 'ISACTIVE',
        sort: true,
        CustomComponent: (item) => {
          const iActive = item?.data?.User?.isActive === true ? 'Active' : 'suspended'

          return (
            <div className={`flex ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-base font-normal text-white`}>
              {t(iActive)}
            </div>
          )
        },
      },
    ],
    clickable: true,
    isDeleting: true,
    defaultSortColumnName: 'User.firstName',
  }

  const methods = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(traineeSchema),
    defaultValues: {
      traineeName: '',
      phoneNo: '',
      email: '',
      height: null,
      weight: null,
      purpose: '',
      comment: '',
    },
  })

  useEffect(() => {
    setTraineeList(trainees)
  }, [trainees])

  const toggelModal = () => {
    setShow(true)
  }
  const clickHandler = (item) => {
    navigate(`/admin/trainees/traineeDetail/${item?.id}`)
  }
  const action = () => {
    setOpenModal(false)
    setPassword('')
    navigate(`/admin/trainees`)
  }
  const handleDelete = (item, userId) => {
    setOpenModal({ traineeId: item, userId: userId })
  }
  const deleteTrainee = () => {
    let values = {
      userId: openModal?.userId,
      traineeId: openModal?.traineeId,
      adminId: user?.id,
      password: password,
      gymId: customerDetail?.id,
    }
    dispatch(DeleteTraineeByGymAdmin(values, t, direction, action))
  }

  const onSort = (item, id, order) => {
    const newdata = _.orderBy(trainees, [id], order)
    setTraineeList(newdata)
  }
  return (
    <div className="h-full overflow-hidden">
      <TraineesListHeader toggelModal={toggelModal} setSearchText={setSearchText} searchText={searchText} />
      {loader ? (
        <Loader className="" size="w-10 h-10" fill="#003353" />
      ) : (
        <div className=" h-[76%] xl:h-[85%] overflow-auto has-scrollbar">
          {traineeList && traineeList?.length > 0 ? (
            <ListTable
              headerRows={headerRows}
              onSort={onSort}
              data={traineeList}
              totalData={40}
              loading={false}
              searchText={searchText}
              clickHandler={clickHandler}
              deleteHandler={handleDelete}
              tableClassName={'pb-32'}
            />
          ) : (
            <div className="h-[50%] justify-center items-center ">
              <p className="text-white font-primary-rg text-[24px] text-center ">{t('NO_DATA')}</p>
            </div>
          )}
        </div>
      )}
      {show && <CreateTrainee Controller={Controller} methods={methods} show={show} setShow={setShow} gymID={gymID} setGymID={setGymID} />}
      <DeleteModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        t={t}
        direction={direction}
        des={t('DELETE_TRAINEE_DESCRIPTION')}
        title={t('DELETE_TRAINEE_TITLE')}
        action={action}
        handleSubmit={deleteTrainee}
        showPass={true}
        error={error}
        setError={setError}
        password={password}
        setPassword={setPassword}
      />
    </div>
  )
}
