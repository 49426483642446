import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import './styles.css'

function Input({ label, ...props }) {
  const { i18n } = useTranslation()
  const isRTL = useMemo(() => i18n.language === 'he', [i18n.language])

  return (
    <div className="floating-label-container">
      <input {...props} className="floating-input" placeholder=" " />
      <label className={`floating-label ${isRTL ? 'rtl' : ''}`}>{label}</label>
    </div>
  )
}

export default Input
