import { useEffect, useState } from 'react'

import { Images } from 'assets'
import CustomToastify from 'components/compounds/Toast'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { constants } from 'constant'
import { useLocation } from 'react-router-dom'
import { createTemplate, updateTemplate } from 'store/templateSlice/templateActions'
import ExerciseSectionTemplate from './ExerciseSectionTemplate'
import TemplateSection from './components/templateSection'
import TemplateListHeader from './templateListHeader'
export default function CreateTemplates() {
  const [searchText, setSearchText] = useState()
  const [templateName, setTemplateName] = useState('Template 1')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { direction } = useSelector((state) => state.language)

  const [selectedExercises, setSelectedExercises] = useState([])
  const { customerDetail } = useSelector((state) => state.user)
  const { exercisesInfo } = useSelector((state) => state.gymAdmin)
  const { templatesList } = useSelector((state) => state.template)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const templateId = queryParams.get('id')
  const active = queryParams.get('active')

  useEffect(() => {
    if (templateId) {
      const template = templatesList.find((item) => item?.id === parseInt(templateId))

      setTemplateName(template?.name)
      handleAddExercises(template)
    }
    // eslint-disable-next-line
  }, [templateId])

  const handleAddExercises = async (template) => {
    const exercisesToAdd = []

    await template?.exercises?.forEach((exercise) => {
      const draggedItem = exercisesInfo.find((val) => val.id === exercise.id)
      const templateWorkoutExercise = template?.templateWorkoutExercises?.find((val) => val.exerciseId === exercise.id)

      let updatedDraggedItem

      if (draggedItem?.resistancename === constants.RUBBER_BAND) {
        updatedDraggedItem = {
          ...draggedItem,
          Resistance_value: templateWorkoutExercise?.resistanceValue,
          Tracking_value: templateWorkoutExercise?.trackingValue,
          set: templateWorkoutExercise?.set,
          isband: true,
        }
      } else {
        updatedDraggedItem = {
          ...draggedItem,
          Resistance_value: templateWorkoutExercise?.resistanceValue,
          Tracking_value: templateWorkoutExercise?.trackingValue,
          set: templateWorkoutExercise?.set,
          isband: false,
          bandColor: null,
        }
      }

      exercisesToAdd.push(updatedDraggedItem)
    })

    setSelectedExercises([...selectedExercises, ...exercisesToAdd])
  }

  const handleDragOver = (event) => {
    event.preventDefault()
  }
  const action = () => {
    navigate({ pathname: `/admin/library`, search: `?active=${active}` })
  }
  const handleSave = () => {
    if (!templateName.trim()) {
      CustomToastify(t('TEMPLATE_NAME_REQUIRED'), '', '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return
    }
    if (selectedExercises.length === 0) {
      CustomToastify(t('TEMPLATE_MUST_EXERCISE'), '', '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return
    }
    const isPropertyEmpty = (value) => value === undefined || value === ''

    // Function to check if any exercises have empty properties
    const hasEmptyProperties = (exercise) => {
      return isPropertyEmpty(exercise.set) || isPropertyEmpty(exercise.Resistance_value) || isPropertyEmpty(exercise.Tracking_value)
    }
    let flag = false
    // Iterate through workout data

    for (const exercise of selectedExercises) {
      if (hasEmptyProperties(exercise)) {
        flag = true
        break

        // You can customize the error message or take further actions here
      }
    }

    if (flag) {
      CustomToastify(t('EMPTY_FIELD'), '', '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return
    }

    const exerciseId = selectedExercises.map((item) => item?.id)
    const exerciseRecord = selectedExercises?.map((item) => {
      return { id: item?.id, Resistance_value: item?.Resistance_value, Tracking_value: item?.Tracking_value, sets: item?.set }
    })

    const data = {
      gymId: customerDetail?.id,
      createdBy: customerDetail?.User?.id,
      name: templateName,
      exerciseRecord: exerciseRecord,
      exerciseId: exerciseId,
    }

    if (templateId) {
      const payload = {
        ...data,
        templateWorkId: templateId,
      }
      dispatch(updateTemplate(payload, t, direction, customerDetail?.id, action))
    } else {
      dispatch(createTemplate(data, t, direction, customerDetail?.id, action, null, null, null))
    }
  }

  return (
    <div className="h-full overflow-hidden">
      <>
        <TemplateListHeader setSearchText={setSearchText} searchText={searchText} templateId={templateId} />
        <div className="flex w-full flex-row gap-x-4  px-8 h-[73%] xxl:h-[80%]">
          <ExerciseSectionTemplate dragOver={handleDragOver} selectedExercises={selectedExercises} setSelectedExercises={setSelectedExercises} />

          <TemplateSection
            dragOver={handleDragOver}
            templateName={templateName}
            setTemplateName={setTemplateName}
            selectedExercises={selectedExercises}
            setSelectedExercises={setSelectedExercises}
            handleSubmit={handleSave}
          />
        </div>
      </>
    </div>
  )
}
