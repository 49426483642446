import { useEffect, useState } from 'react'

import moment from 'moment'
import { Images } from 'assets'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Loader } from 'components/compounds/Loader'
import DeleteModal from 'components/compounds/DeleteModal'
import { ProfileAvatar } from 'components/PlayBoard/ProfileAvatar'
import { getTraineeDashboardStats, startWorkout } from 'store/traineeSlice/traineeActions'
import CustomToastify from 'components/compounds/Toast'
import { getTime, getWorkoutDateAndTime } from 'utils'
import PrimaryButton from 'components/elements/PrimaryButton'

const TraineeDashboard = () => {
  const { t } = useTranslation()
  const { direction } = useSelector((state) => state.language)
  const { customerDetail } = useSelector((state) => state.user)
  const [selectedWorkout, setSelectedWorkout] = useState(null)
  const [loading, setLoading] = useState(true)
  const [isStarting, setStarting] = useState(false)
  const [dashboardData, setDashboardData] = useState({
    stats: {
      workoutCompleted: 0,
      achievements: 0,
      bestRecord: null,
      workoutInARow: 0,
    },
    futureWorkouts: [],
    completedWorkouts: [],
  })
  const navigate = useNavigate()

  const fetchData = async () => {
    if (customerDetail?.User?.Trainee?.id) {
      try {
        setLoading(true)
        const data = await getTraineeDashboardStats(customerDetail?.User?.Trainee?.id)

        setDashboardData(data)
        setLoading(false)
      } catch (error) {
        console.log('error', error)
        setLoading(false)
      }
    }
  }
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [customerDetail?.User?.Trainee?.id])

  const onStartWorkout = async () => {
    try {
      setStarting(true)

      await startWorkout(selectedWorkout?.id)
      CustomToastify(t('TRAINEE_PROFILE_ACTION'), '', '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
      setStarting(false)
      navigate(`/trainee/workout/${selectedWorkout?.id}`)
    } catch (error) {
      console.log('error', error)
      CustomToastify(
        error?.code === 501 ? t('ALREADY_HAS_WORKOUT_TODAY') : t('PLEASE_TRY_AGAIN'),
        '',
        '',
        'bg-toastFaildbg',
        direction,
        Images.toastFail,
        false
      )
      setStarting(false)
    }
  }

  const startWithoutShowingModal = async (selectedWorkout, isChangeDate) => {
    try {
      setStarting(true)

      if (isChangeDate) {
        const currentDate = moment().format('DD-MM-YYYY')
        const workoutDate = moment(selectedWorkout?.Event?.date).format('DD-MM-YYYY')
        if (currentDate !== workoutDate) {
        }
      }
      await startWorkout(selectedWorkout?.id)
      CustomToastify(t('TRAINEE_PROFILE_ACTION'), '', '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
      setStarting(false)
      navigate(`/trainee/workout/${selectedWorkout?.id}`)
    } catch (error) {
      console.log('error', error)
      CustomToastify(
        error?.code === 501 ? t('ALREADY_HAS_WORKOUT_TODAY') : t('PLEASE_TRY_AGAIN'),
        '',
        '',
        'bg-toastFaildbg',
        direction,
        Images.toastFail,
        false
      )
      setStarting(false)
    }
  }

  const isSomeActive = dashboardData?.futureWorkouts?.some((item) => item?.show_in_board === true)

  return (
    <>
      {loading ? (
        <Loader className="" size="w-8 h-8" fill="#003353" />
      ) : (
        <div className=" mx-4 mt-8 mb-4 md:m-4  space-y-6 ">
          <div className="flex items-center justify-end md:justify-start space-x-3 rtl:space-x-reverse">
            <div className="relative " onClick={() => navigate('/trainee/profile')}>
              <ProfileAvatar name={customerDetail?.User?.firstName} size={'large'} image={customerDetail?.User?.avatar} />
            </div>
            <h1 className="text-white w-3/4 text-sm sm:text-md sm:w-full capitalize hidden md:block">
              {`${t('HI')} ${customerDetail?.User?.firstName}, ${t('GOOD_TO_SEE_YOU')} !`}{' '}
            </h1>
          </div>
          <div className="flex items-center  space-x-3 rtl:space-x-reverse">
            <h1 className="text-white w-3/4 text-sm sm:text-md sm:w-full capitalize block md:hidden">
              {`${t('HI')} ${customerDetail?.User?.firstName}, ${t('GOOD_TO_SEE_YOU')} !`}{' '}
            </h1>
          </div>
          <div className="space-y-2">
            <h1 className="text-white text-sm">{t('YOUR_PERFORMANCES')}</h1>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
              <div className="w-full flex items-center bg-playCard rounded-xl p-3 space-x-2 rtl:space-x-reverse text-white">
                <img alt="done-stat" src={Images.DoneStat} className="h-7 " />
                <div className="flex flex-col space-y-2 text-xs sm:text-md">
                  <h1>{dashboardData?.stats?.workoutCompleted || 0}</h1>
                  <h1>{t('DONE_WORKOUTS')}</h1>
                </div>
              </div>
              <div className="w-full flex items-center bg-playCard rounded-xl p-3 space-x-2 rtl:space-x-reverse text-white">
                <img alt="done-stat" src={Images.RecordStat} className="h-7 ltr:w-7" />
                <div className="flex flex-col space-y-2 text-xs sm:text-md">
                  <h1>{dashboardData?.stats?.workoutInARow || 0}</h1>
                  <h1>{t('WORKOUTS_IN_ROW')}</h1>
                </div>
              </div>
              <div className="w-full flex items-center bg-playCard rounded-xl p-3 space-x-2 rtl:space-x-reverse text-white">
                <img alt="done-stat" src={Images.BestStat} className="h-7" />
                <div className="flex flex-col space-y-2 text-xs sm:text-md">
                  <h1>{dashboardData?.stats?.bestRecord?.value || 0}</h1>
                  <h1>{t(dashboardData?.stats?.bestRecord?.label)}</h1>
                </div>
              </div>
              <div className="w-full flex items-center bg-playCard rounded-xl p-3 space-x-2 rtl:space-x-reverse text-white">
                <img alt="done-stat" src={Images.StarStat} className="h-7" />
                <div className="flex flex-col space-y-2 text-xs sm:text-md">
                  <h1>{dashboardData?.stats?.achievements || 0}</h1>
                  <h1>{t('ACHIEVEMENTS')}</h1>
                </div>
              </div>
            </div>
          </div>

          <div className="space-y-2 flex flex-col w-full">
            <h1 className="text-white text-sm">{t('YOUR_NEXT_WORKOUTS')}</h1>
            {dashboardData?.futureWorkouts?.length === 0 ? (
              <div className="w-[100%] h-56 flex flex-col justify-center items-center ">
                <img src={Images.EmptyWorkout} alt="" height={100} width={100} className="items-center cursor-pointer" />
                <p className={`${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-[white] text-[24px] leading-7`}>
                  {0 + ' ' + t('NO_OF_TRAINING_PERFORMED')}
                </p>
              </div>
            ) : (
              <>
                {isStarting ? (
                  <div className="flex w-full h-28  justify-center items-center">
                    <div className="spinner-7"></div>
                  </div>
                ) : (
                  <div className=" flex w-[100%] gap-2 overflow-x-scroll has-scrollbar pb-2 ">
                    {dashboardData?.futureWorkouts?.map((item) => {
                      const isOnlineOrOpenGym = ['online', 'openGym'].includes(item.workoutType)
                      let dateValue = ''
                      if (!isOnlineOrOpenGym) {
                        dateValue = `${getTime(item?.Event?.startHour)} • ${getWorkoutDateAndTime(item?.Event?.date, t, moment)}`
                      }

                      const isOpen = item?.show_in_board
                      const currentDate = moment().format('DD-MM-YYYY')
                      const workoutDate = moment(item?.Event?.date).format('DD-MM-YYYY')
                      const isToday = currentDate === workoutDate

                      return (
                        <div
                          key={item.id}
                          className={`${
                            isToday && item?.workoutType === 'personal' ? 'bg-[#003546]' : 'bg-playCard'
                          } min-w-fit md:w-64  min-h-48 rounded-lg shadow-2xl px-4 py-3 flex flex-col`}
                        >
                          <div className="flex flex-row w-full justify-between space-x-4 rtl:space-x-reverse ">
                            <h1 className="text-white text-xl ltr:font-primary-rg rtl:font-primary-he-rg ">{` ${
                              t(item?.workoutType).charAt(0).toUpperCase() + t(item?.workoutType).slice(1)
                            }`}</h1>
                            {isToday && item?.workoutType === 'personal' && (
                              <div className=" h-5 rounded-[50px] py-[2px] px-2 bg-textLightGrey text-center text-xs">{t('Today')}</div>
                            )}
                          </div>
                          <div className=" flex flex-row w-full  space-x-2 rtl:space-x-reverse pt-3 ">
                            <img alt="aquare" src={Images.ThreeSquares} width={16} height={16} />
                            <h1 className="text-white text-xs ltr:font-primary-rg rtl:font-primary-he-rg ">{` 
                         ${item?.workoutName}
                        `}</h1>
                          </div>
                          {item?.workoutType === 'personal' ? (
                            <>
                              <div className=" flex flex-row w-full  space-x-2 rtl:space-x-reverse pt-2 ">
                                <div>
                                  <img alt="calendar" src={Images.CalendarMark} width={16} height={16} />
                                </div>
                                <h1 className="text-white text-xs ltr:font-primary-rg rtl:font-primary-he-rg capitalize ">{` 
                         ${dateValue}
                        `}</h1>
                              </div>
                              <div className=" flex flex-row w-full  space-x-2 rtl:space-x-reverse pt-2 ">
                                <img alt="calendar" src={Images.Restart} width={16} height={16} />
                                <h1 className="text-white text-xs ltr:font-primary-rg rtl:font-primary-he-rg capitalize ">{` 
                         ${t('repeat every')} : ${moment(item?.Event?.date).format('dddd')}
                        `}</h1>
                              </div>
                            </>
                          ) : (
                            <div className=" w-36 md:w-56 h-12"></div>
                          )}
                          <PrimaryButton
                            text={!isOpen ? t('ENTER') : t('Continue')}
                            type="submit"
                            disabled={isSomeActive && !isOpen ? true : false}
                            isIcon={false}
                            onClick={() => {
                              if (isOpen) {
                                navigate(`/trainee/workout/${item?.id}`)
                              } else {
                                if (isOnlineOrOpenGym || isToday) {
                                  if (isOnlineOrOpenGym) {
                                    startWithoutShowingModal(item, true)
                                  } else {
                                    startWithoutShowingModal(item, false)
                                  }
                                } else {
                                  setSelectedWorkout(item)
                                }
                              }
                            }}
                            className={`h-[38px] w-full px-4 rounded-[12px] shadow-2xl mt-4 cursor-pointer ${
                              isSomeActive && !isOpen && ' pointer-events-none'
                            }`}
                            bgColor="bg-borderActive hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
                            textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
                          />
                        </div>
                      )
                    })}
                  </div>
                )}
              </>
            )}
          </div>

          <div className="space-y-2">
            <h1 className="text-white text-sm">{t('YOUR_COMPLETED_WORKOUT')}</h1>
            {dashboardData?.completedWorkouts?.length === 0 ? (
              <div className="w-[100%] h-56 flex flex-col justify-center items-center ">
                <img src={Images.EmptyWorkout} alt="" height={100} width={100} className="items-center cursor-pointer" />
                <p className={`${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-[white] text-[24px] leading-7`}>
                  {0 + ' ' + t('NO_OF_TRAINING_PERFORMED')}
                </p>
              </div>
            ) : (
              <>
                <div className="space-y-2">
                  {dashboardData?.completedWorkouts?.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className="bg-playCard w-full rounded-lg shadow-2xl px-4 py-3 flex justify-between items-center cursor-pointer"
                        onClick={() => navigate(`/trainee/workout/${item.id}`)}
                      >
                        <div className="flex items-center space-x-2 rtl:space-x-reverse">
                          <ProfileAvatar name={item?.Coach?.User?.firstName} image={item?.Coach?.User?.avatar} size={'small'} />
                          <h1 className="text-white text-xs">{`${t(item?.workoutType)} - ${item?.program?.programName}`}</h1>
                        </div>
                        <h1 className="text-textGrey text-xs cursor-pointer">{`${getTime(item?.Event?.startHour)} • ${getWorkoutDateAndTime(
                          item?.workout_timeStamp,
                          t,
                          moment
                        )}`}</h1>
                      </div>
                    )
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <DeleteModal
        primary
        title={t('START_WRKOUT_NOW')}
        des={`${t('WORKOUT_SCHEDULE_FOR')} ${moment(selectedWorkout?.Event?.date).format('MMM DD')}, ${t('ACTIVATION_WORKOUT')}`}
        t={t}
        loading={isStarting}
        openModal={selectedWorkout}
        setOpenModal={setSelectedWorkout}
        handleSubmit={onStartWorkout}
        confirmText={t('Continue')}
        cancelText={t('Cancel')}
      />
    </>
  )
}

export default TraineeDashboard
