import facebookLogo from "./facebookLogo.png";
import googleLogo from "./googleLogo.png";
import banner from "./loginBanner.png";
import CalendarIcon from "./CalendarIcon.png";
import ClipboardIcon from "./ClipboardIcon.png";
import UserIcon from "./UserIcon.png";
import Widget from "./Widget.png";
import leftArrow from "./leftArrow.png";
import rightArrow from "./rightArrow.png";
import Plus from "./Plus.png";
import Close from "./Close.png";
import Camera from "./Camera.png";
import Copy from "./Copy.png";
import Magnifer from "./Magnifer.png";
import Settings from "./Settings.png";
import Translate from "./Translate.png";
import Mark from "./Mark.png";
import Dumbbell from "./Dumbbell.png";
import toastFail from "./toastFail.png";
import toastSucess from "./toastSucess.png";
import Search from "./Search.png";
import Filter from "./Filter.png";
import backArrow from "./backArrow.png";
import backLessArrow from "./backLessArrow.png";
import CheckCircle from "./CheckCircle.png";
import CloseCircle from "./CloseCircle.png";
import Download from "./Download.png";
import User from "./User.png";
import Permissions from "./Permissions.png";
import Avatar from "./Avatar.png";
import Check from "./Check.png";
import Delete from "./delete.png";
import EditSave from "./editSave.png";
import Bell from "./Bell.png";
// import Calendar from './Calendar.png'
import Info from "./Info.png";
import DROPDOWNICON from "./dropDownIcon.png";
import Eye from "./Eye.png";
import EyeClosed from "./EyeClosed.png";
import Retry from "./Retry.png";
import Equipment from "./Equipment.png";
import CloseCircleWhite from "./CloseCircleWhite.png";
import InfoCircleColor from "./InfoCircleColor.png";
import Decrement from "./decrement.png";
import Increment from "./increment.png";
import DropDown_Left from "./dropDown_Left.png";
import InfoCircle from "./InfoCircle.png";
import AddSquare from "./AddSquare.png";
import Dragging from "./dragging.png";
import Remove from "./Remove.png";
import InfoColored from "./InfoColored.png";
import ArrowUp from "./ArrowUp.png";
import EmptyWorkout from "./EmptyWorkout.png";
import AddCircle from "./AddCircle.png";
import Minus from "./minus.png";
import EmptyState from "./EmptyState.png";
import WorkoutSidebarLeftArrow from "./left-arrow.png";
import WorkoutSidebarRightArrow from "./right-arrow.png";
import LeftArrow from "./left_arrow.png";
import RightArrow from "./right_arrow.png";
import Clock_loader from "./clock_loader.png";
import Chat from "./chat.png";
import History from "./history.png";
import Cup from "./Cup.png";
import CommentSend from "./CommentSend.png";
import CommentSend_ltr from "./CommentSend_ltr.png";
import Shuffle from "./Shuffle.png";
import Pen from "./Pen.png";
import Trophy from "./trophy.png";
import InfoBlue from "./InfoBlue.png";
import library from "./library.png";
import logout from "./logout.png";
import playBoard from "./playBoard.png";
import NavCalendar from "./NavCalendar.png";
import NavUser from "./NavUser.png";
import Monitor from "./monitor.png";
import EnglishFlag from "./englishFlag.png";
import IsraelFlag from "./israelFlag.png";
import EditProfile from "./edit-profile.png";
import EditIcon from "./edit.png";
import StarStat from "./star_stat.png";
import BestStat from "./best_stat.png";
import DoneStat from "./done_stat.png";
import RecordStat from "./record_stat.png";
import ProfileBg from "./profile_bg.png";
import PlayWorkoutLeftWaarow from "./play-left-arrow.png";
import MenuIcon from "./menu.png";
import CloseWorkout from "./closeWorkout.png";
import MenuDots from "./MenuDots.png";
import CancelWorkout from "./cancelWorkout.png";
import MobileProfile from "./mobileProfile.png";
import UserPlus from "./UserPlus.png";
import DumbellPlus from "./DumbellPlus.png";
import IncreasedIcon from "./increased.png";
import DecreasedIcon from "./decreased.png";
import CupBest from "./cup_best.png";
import DropdownArrow from "./dropdown_arrow.png";
import Refresh from "./refresh.png";
import ExitArrow from "./exit-arrow.png";
import AscSort from "./asc_sort.png";
import DescSort from "./desc_sort.png";
import CoachableLogo from "./CoachAble.png";
import CalendarMark from "./Calendar_Mark.png";
import Restart from "./Restart.png";
import ThreeSquares from "./Three_Squares.png";
import Logo_Dark from "./Logo-Dark1.png";
import ActiveWeek from "./activeWeek.png";
import Achieve from "./achieve.png";
import Medal from "./Medal.png";
import fire from "./fire.png";
import Direction from "./direction.png";
import Heart from "./heart.png";
import Youtube from "./youtube.png";

const Assets = {
  DumbellPlus,
  UserPlus,
  Refresh,
  DropdownArrow,
  CupBest,
  LeftArrow,
  RightArrow,
  IncreasedIcon,
  DecreasedIcon,
  facebookLogo,
  googleLogo,
  banner,
  CalendarIcon,
  ClipboardIcon,
  UserIcon,
  Widget,
  leftArrow,
  rightArrow,
  Plus,
  Close,
  Camera,
  Copy,
  Magnifer,
  Settings,
  Translate,
  Mark,
  Dumbbell,
  toastFail,
  toastSucess,
  Search,
  Filter,
  backArrow,
  backLessArrow,
  CheckCircle,
  CloseCircle,
  Download,
  User,
  Permissions,
  Avatar,
  Check,
  Delete,
  EditSave,
  Bell,
  Info,
  DROPDOWNICON,
  Eye,
  EyeClosed,
  Retry,
  Equipment,
  CloseCircleWhite,
  InfoCircleColor,
  Decrement,
  Increment,
  DropDown_Left,
  InfoCircle,
  AddSquare,
  Dragging,
  Remove,
  InfoColored,
  ArrowUp,
  EmptyWorkout,
  AddCircle,
  Minus,
  EmptyState,
  WorkoutSidebarLeftArrow,
  WorkoutSidebarRightArrow,
  Clock_loader,
  Chat,
  History,
  Cup,
  CommentSend,
  CommentSend_ltr,
  Shuffle,
  Pen,
  Trophy,
  InfoBlue,
  library,
  logout,
  playBoard,
  NavCalendar,
  NavUser,
  Monitor,
  EnglishFlag,
  IsraelFlag,
  EditProfile,
  StarStat,
  BestStat,
  DoneStat,
  RecordStat,
  ProfileBg,
  EditIcon,
  PlayWorkoutLeftWaarow,
  MenuIcon,
  CloseWorkout,
  MenuDots,
  CancelWorkout,
  MobileProfile,
  ExitArrow,
  AscSort,
  DescSort,
  CoachableLogo,
  CalendarMark,
  Restart,
  ThreeSquares,
  Logo_Dark,
  ActiveWeek,
  Achieve,
  Medal,
  fire,
  Direction,
  Heart,
  Youtube,
};

export default Assets;
