import OutlineButton from 'components/elements/OutlineButton'
import PrimaryButton from 'components/elements/PrimaryButton'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

export default function ExerciseFooter({ handleSubmit, handleCheck, id, active, setActive, disable }) {
  const { direction } = useSelector((state) => state.language)
  const { t } = useTranslation()
  return (
    <div
      className="flex flex-row gap-4 h-16 items-center px-4 justify-end xl:px-6 2xl:px-12 border-t-[1px] border-borderColor ios-position w-[360px] lg:w-[480px] bg-sidebar"
      style={{ zIndex: 12 }}
    >
      {active === 1 && (
        <PrimaryButton
          text={t('NEXT')}
          type="submit"
          disabled={false}
          isIcon={false}
          onClick={handleCheck}
          className="w-[100px] h-[44px] px-4 rounded-[12px] shadow-2xl"
          bgColor="bg-prussianBlue hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
          textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
        />
      )}
      {active === 2 && (
        <>
          <OutlineButton
            text={t('PREVIOUS')}
            type="submit"
            disabled={false}
            isIcon={false}
            onClick={() => {
              setActive(1)
            }}
            className="w-[100px] h-[44px] rounded-[12px] border-borderActive border-[1.5px] shadow-2xl "
            bgColor="bg-sidebar hover:bg-darkGray focus:bg-lightGray active:bg-lightGray"
            textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
          />
          <PrimaryButton
            text={t('SAVE_EXERCISE')}
            type="submit"
            disabled={disable}
            isIcon={false}
            onClick={handleSubmit}
            className="w-[144px] h-[44px] px-4 rounded-[12px] shadow-2xl"
            bgColor="bg-prussianBlue hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
            textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
          />
        </>
      )}
    </div>
  )
}
