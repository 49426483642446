import React from 'react'

export default function CustomOptions({ label, name, value, setValue, t, data, disabled, className }) {
  return (
    <div className="flex flex-row w-full items-center !gap-x-4 py-2">
      <label className={`flex    text-white ltr:font-primary-rg rtl:font-primary-he-rg text-[14px] items-baseline ${disabled && 'opacity-50 '}`}>
        {label}
      </label>
      <div className={`flex flex-row gap-2 rounded-[12px] ${disabled && 'opacity-50'} overflow-x-auto no-scrollbar `}>
        {data.map((item) => {
          return (
            <div
              className={`py-2 px-2 lg:px-4  text-white text-sm ${
                value === item ? 'bg-secondaryBackground border-[2px] border-borderActive' : 'border-[1px] border-[#222e34]'
              } rounded-[14px] ${disabled ? ' pointer-events-none' : ' cursor-pointer'} ltr:font-primary-rg rtl:font-primary-he-rg
                }`}
              onClick={() => setValue(item)}
            >
              {t(item)}
            </div>
          )
        })}
      </div>
    </div>
  )
}
