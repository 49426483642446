import OutlineButton from 'components/elements/OutlineButton'
import PrimaryButton from 'components/elements/PrimaryButton'
import { constants } from 'constant'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getTime } from 'utils'

export default function WorkoutTimeline({ setStage2, stage2, modifiedState, setModifiedState, handleSubmit }) {
  const { WEEK_DAYS } = constants
  const { language, direction } = useSelector((state) => state.language)
  const { loader } = useSelector((state) => state.program)
  const [availableDays, setAvailableDays] = useState([])
  const [isValid, setIsValid] = useState(false)
  const [availableHourofSelectDay, setAvailableHourofSelectDay] = useState([])
  const { t } = useTranslation()
  let activeWorkout = null
  let activeWorkoutName = null

  for (const key in modifiedState) {
    const workout = modifiedState[key]?.find((item) => item.isActive === true)
    if (workout) {
      activeWorkout = workout
      activeWorkoutName = key
      break
    }
  }

  useEffect(() => {
    if (activeWorkout != null) {
      const workingHours = activeWorkout?.availableHours
      if (workingHours) {
        let availableDay = WEEK_DAYS?.filter((day) => {
          const dayValue = day.day
          return Object?.values(workingHours)?.some((hour) => hour[0]?.value === dayValue)
        })
        setAvailableDays(availableDay)
      }
    }
    // eslint-disable-next-line
  }, [activeWorkout, modifiedState])
  useEffect(() => {
    let slotNotExist = false

    for (const key in modifiedState) {
      const workouts = modifiedState[key]

      for (const workout of workouts) {
        if (workout.type !== 'online' && workout.type !== 'openGym' && (workout.dayAndTime.day === null || workout.dayAndTime.time === null)) {
          slotNotExist = true
          break
        }
      }

      if (slotNotExist) {
        break
      }
    }
    setIsValid(slotNotExist)
  }, [modifiedState])

  const handleTimeline = (value) => {
    const updatedModifiedState = { ...modifiedState }
    const availableHoursForSelectedDay = Object?.values(activeWorkout?.availableHours)
      .flatMap((hour) => hour)
      ?.sort((a, b) => a?.start - b?.start)
      .filter((hour) => hour.value === value?.day)
      .map((hour) => ({ hour: hour.start, value: hour.start }))

    setAvailableHourofSelectDay(availableHoursForSelectedDay)

    for (const key in updatedModifiedState) {
      const workouts = updatedModifiedState[key]
      for (const workout of workouts) {
        if (workout.isActive) {
          workout.dayAndTime.day = value?.day
          workout.dayAndTime.time = null
          break
        }
      }
    }

    setModifiedState(updatedModifiedState)
  }
  const handleTimelinesTime = (time) => {
    const updatedModifiedState = { ...modifiedState }

    for (const key in updatedModifiedState) {
      const workouts = updatedModifiedState[key]
      for (const workout of workouts) {
        if (workout.isActive) {
          workout.dayAndTime.time = time?.value
          break
        }
      }
    }

    setModifiedState(updatedModifiedState)
  }

  const [disabledDays, setDisabledDays] = useState([])

  useEffect(() => {
    const updatedDisabledDays = []
    for (const key in modifiedState) {
      const workouts = modifiedState[key]
      for (const workout of workouts) {
        if (workout.dayAndTime.day !== null) {
          updatedDisabledDays.push(workout.dayAndTime.day)
        }
      }
    }
    setDisabledDays(updatedDisabledDays)
  }, [modifiedState])

  return (
    <div className={`flex flex-col justify-between relative w-[45%]  bg-secondaryBackground rounded-[12px]  py-[16px] px-[24px] min-h-full `}>
      {activeWorkout ? (
        <div>
          <div className="flex flex-row w-full h-6 gap-4 ">
            <p className={`${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-xl text-textLightGray`}>
              {activeWorkoutName} - {t('DAY_AND_TIME')}
            </p>
          </div>
          <div className="flex w-full gap-2 pt-4 ">
            {availableDays &&
              availableDays.map((day) => (
                <button
                  type="button"
                  key={day.value}
                  className={` rounded-xl h-16 w-[59px] border-[1.5px]  text-white ${
                    direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                  } ${
                    activeWorkout?.dayAndTime.day === day?.day
                      ? 'bg-[#203f52] border-[#65b1f4]'
                      : 'border-[#074264] disabled:border-[grey] disabled:opacity-10 disabled:pointer-events-none'
                  }`}
                  onClick={() => {
                    handleTimeline(day)
                  }}
                  disabled={disabledDays.includes(day.day)}
                >
                  {language === 'en' ? day.label : day.label_he}
                </button>
              ))}
          </div>
          {activeWorkout?.dayAndTime.day != null && (
            <div className="flex flex-col mt-4   items-center gap-2 overflow-scroll h-[calc(100vh-370px)] xl:h-[calc(100vh-420px)]">
              {availableHourofSelectDay &&
                availableHourofSelectDay?.map((time) => {
                  return (
                    <div
                      className={`flex w-full justify-center py-3 xl:py-4  rounded-xl ${
                        direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                      } text-white text-xs cursor-pointer hover:bg-[#203f52] hover:isolate hover:border-[1.5px] hover:border-[#65B1F4] ${
                        activeWorkout?.dayAndTime.time === time?.value ? 'bg-[#203f52] isolate border-[1.5px] border-[#65B1F4]' : 'bg-textFieldColor'
                      } `}
                      onClick={() => {
                        handleTimelinesTime(time)
                      }}
                    >
                      {getTime(time.value)}
                    </div>
                  )
                })}
            </div>
          )}
        </div>
      ) : (
        <div></div>
      )}
      <div className={`absolute bottom-0 ${direction === 'ltr' ? 'right-6' : 'left-6'} flex flex-row gap-4 py-2 justify-end  w-full`}>
        <OutlineButton
          text={t('PREV')}
          type="submit"
          disabled={false}
          isIcon={false}
          onClick={() => {
            setStage2(!stage2)
          }}
          className="w-[85px] h-[44px] rounded-[12px] border-borderActive border-[1.5px] shadow-2xl "
          bgColor="hover:bg-darkGray focus:bg-lightGray active:bg-lightGray"
          textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
        />
        <PrimaryButton
          text={t('SAVE_PROGRAM')}
          type="submit"
          disabled={loader || isValid ? true : false}
          isIcon={false}
          onClick={handleSubmit}
          className="h-[44px] w-[144px] px-4 rounded-[12px] shadow-2xl"
          bgColor="bg-prussianBlue hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
          textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
          Loader={loader}
        />
      </div>
    </div>
  )
}
