import { Images } from "assets";
import i18n from "i18n";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { handleLogout } from "utils";

const routes = [
  { title: "CUSTOMERS", src: Images.Widget, url: "/home" },
  { title: "LIBRARY", src: Images.library, url: "/library" },
];

const Layout = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();

  const { language, direction } = useSelector((state) => state.language);
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  // eslint-disable-next-line
  const [active, setActive] = useState(0);

  useEffect(() => {}, [active]);
  const activeTab = async (URL) => {
    navigate(URL);
  };

  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);

  useEffect(() => {
    i18n.changeLanguage(language);
    document.body.setAttribute("dir", language === "en" ? "ltr" : "rtl");
    i18n.reloadResources();
    // eslint-disable-next-line
  }, []);

  if (user?.role !== "admin") {
    return <Navigate to="/login" />;
  }
  if (direction === "rtl") {
    document.documentElement.style.setProperty("--position-left", "0px");
    document.documentElement.style.setProperty("--position-right", "auto");
  } else {
    document.documentElement.style.setProperty("--position-left", "auto");
    document.documentElement.style.setProperty("--position-right", "0px");
  }

  return (
    <div className="flex w-screen h-screen bg-primary relative">
      {isSidebarOpen ? (
        <div
          className={`block sm:hidden transition-all ${isSidebarOpen ? "block" : "-translate-x-full"} absolute h-full w-56 ${
            language === "he" ? "top-0 right-0" : "top-0 left-0"
          } bg-checkBoxGray z-50 shadow-2xl px-4 py-6 flex flex-col justify-between`}
        >
          <div className="space-y-6">
            <div className="flex justify-between">
              <h1 className="leading-4 text-[1.125rem] text-textLightGray duration-200">
                MY TRAINING APP
              </h1>
              <RxCross2
                className="text-textLightGray cursor-pointer"
                fontSize={"1.2rem"}
                onClick={toggleSidebar}
              />
            </div>

            <ul>
              {routes.map((route, index) => (
                <li
                  key={index}
                  className={`flex mt-2 p-2 cursor-pointer rounded-md hover:bg-sidebarhover hover:rounded-md text-gray-300 text-sm items-center space-x-4 rtl:space-x-reverse ${
                    route.url === location.pathname && "bg-sidebarhover"
                  } `}
                  onClick={() => {
                    activeTab(route.url);
                    toggleSidebar();
                  }}
                >
                  <img alt="new-profile" src={route.src} className="h-6 w-6" />
                  {open && (
                    <span
                      className={`${!open && "hidden"} origin-left duration-200`}
                    >
                      {t(route.title)}
                    </span>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="space-y-2">
            <div
              className={`flex justify-center w-full cursor-pointer text-gray-300 text-sm items-center space-x-2 rtl:space-x-reverse duration-200 ease-in-out rounded-lg p-2`}
              onClick={() => {
                handleLogout(dispatch, navigate);
              }}
            >
              <img src={Images.logout} className="h-4 w-4" alt="" />
              <span
                className={`duration-200 text-[#C2C7CF] ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg "}   text-gray-300`}
              >
                {t("LOGOUT")}
              </span>
            </div>
            <div className={`flex justify-center`}>
              <span
                className={`${!open && "hidden"} duration-200 text-[#C2C7CF] ${
                  direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"
                }   text-[#C2C7CF] text-[10px]`}
              >
                {t("POWEREDBY")}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`block sm:hidden absolute ${language === "he" ? "top-6 left-4" : "top-6 right-4"}`}
        >
          <img
            src={Images.MenuIcon}
            alt=""
            height={24}
            width={24}
            className={`items-center cursor-pointer`}
            onClick={toggleSidebar}
          />
        </div>
      )}
      <div
        className={`hidden sm:flex ${
          open ? "w-52" : "w-20"
        } bg-sidebar h-screen p-5 pt-10 relative duration-300 z-10 space-y-6 flex-col justify-between`}
      >
        <div>
          <img
            src={Images.rightArrow}
            alt="new-profile"
            className={`absolute cursor-pointer ${language === "he" ? "-left-3" : "-right-3"} top-14 w-6 border-dark-purple border-2 rounded-full ${
              open ? "rotate-180 rtl:rotate-0" : "rotate-0 rtl:rotate-180"
            }`}
            onClick={() => setOpen(!open)}
          />
          <div className="flex items-center justify-center ">
            {open ? (
              <h1
                className={`font-kanit-bold  leading-4 text-[15px] font-black text-textLightGray duration-200 mb-1`}
              >
                MY TRAINING APP
              </h1>
            ) : (
              <h1
                className={`font-kanit-bold leading-4 text-[1.125rem] font-black text-textLightGray duration-200 mb-1`}
              >
                MT
              </h1>
            )}
          </div>
          <ul className="pt-4">
            {routes.map((route, index) => (
              <li
                key={index}
                className={`flex mt-2 p-2 cursor-pointer rounded-xl hover:bg-primary hover:rounded-md text-gray-300 text-sm items-center space-x-4 rtl:space-x-reverse ${
                  route.url === location.pathname && "bg-sidebarhover"
                } `}
                onClick={() => activeTab(route.url)}
              >
                <img alt="new-profile" src={route.src} className="h-6 w-6" />
                {open && (
                  <span
                    className={`${!open && "hidden"} origin-left duration-200`}
                  >
                    {t(route.title)}
                  </span>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div
          className={`hidden sm:flex flex-col items-center cursor-pointer z-50 overflow-hidden justify-center duration-200 ease-in-out space-y-2`}
        >
          <div
            className={`flex justify-center w-full cursor-pointer rounded-lg text-gray-300 text-sm items-center space-x-2 rtl:space-x-reverse duration-200 ease-in-out py-2`}
            onClick={() => handleLogout(dispatch, navigate)}
          >
            <img src={Images.logout} className="h-6 w-6" alt="" />
            {open && (
              <span
                className={`duration-200 text-[#C2C7CF] ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"} text-gray-300`}
              >
                {t("LOGOUT")}
              </span>
            )}
          </div>
          <div className={`flex`}>
            <span
              className={`${!open && "hidden"} duration-200 text-[#C2C7CF] ${
                direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"
              }   text-[#C2C7CF] text-[10px]`}
            >
              {t("POWEREDBY")}
            </span>
          </div>
        </div>
      </div>
      <div className="w-full overflow-auto">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
