import { Combobox, Transition } from '@headlessui/react'
import { Images } from 'assets'
import { Fragment, useState } from 'react'

export default function DropDownWithSearch({ id, value, data, setValue, options, className, title, disable, t, dir, isRequired, placeHolder }) {
  // eslint-disable-next-line
  const [isInputFocused, setIsInputFocused] = useState(false)
  const [query, setQuery] = useState('')
  const [customInputValue, setCustomInputValue] = useState('')

  // eslint-disable-next-line
  const [isOpen, setIsOpen] = useState(false)

  const handleInputBlur = () => {
    setIsOpen(false)
  }

  const filteredOption =
    query === ''
      ? options
      : options.filter((person) => person.muscleName?.toLowerCase().replace(/\s+/g, '').includes(query?.toLowerCase().replace(/\s+/g, '')))

  return (
    <div className={`flex flex-col  h-[56px] rounded-[12px] mb-2 mt-1  ${className ? className : 'w-full'} ${disable && 'opacity-50'} `}>
      <Combobox
        value={value}
        onChange={(selectedValue) => {
          setValue(selectedValue || customInputValue)
          setIsOpen(false)
        }}
        allowCustomValue
      >
        <div className="bg-textFieldColor rounded-[12px]">
          <div className={`bg-textFieldColor   z-10 ${isInputFocused ? 'border-2 border-[#006684] rounded-t-[12px]' : 'rounded-[12px]'}`}>
            {title && (
              <div
                className={`flex flex-row  justify-start ${
                  dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                } px-2 text-textLightGrey text-[11px] `}
              >
                {title}
                {isRequired && <span className="text-[red] px-1">*</span>}
              </div>
            )}
            <div className={`flex flex-row cursor-default overflow-hidden rounded-[12px] bg-textFieldColor ${!title && 'h-[56px]'} `}>
              {!disable ? ( // Render the input if disable is false
                <>
                  <Combobox.Input
                    className={`inline-flex w-full text-[12px] items-center bg-textFieldColor px-2 py-2 font-medium text-textLightGrey ${
                      dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                    } ${!title && 'h-[56px]'}`}
                    displayValue={(person) => person}
                    onChange={(event) => {
                      setQuery(event.target.value)
                      setCustomInputValue(event.target.value)
                      if (query.length > 0) {
                        setIsOpen(true)
                      }
                    }}
                    onBlur={handleInputBlur}
                    placeholder={placeHolder}
                  />
                  <div
                    className={`inline-flex w-20 text-[12px] text-center items-center rounded-[12px] ${
                      !disable ? 'bg-textFieldColor' : 'bg-textFieldColor'
                    } px-2 py-2 font-medium text-textLightGrey ${dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
                  >
                    <img
                      src={Images.DROPDOWNICON}
                      alt=""
                      height={20}
                      width={20}
                      className={`${dir === 'ltr' ? 'float-right ml-auto' : 'mr-auto'} `}
                      onClick={() => {
                        setIsOpen(!isOpen)
                      }}
                    />
                  </div>
                </>
              ) : (
                // Render a read-only input if disable is true
                <input
                  className={`inline-flex w-full text-[12px] items-center bg-textFieldColor px-2 py-2 font-medium text-textLightGrey ${
                    dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                  } ${!title && 'h-[56px]'} cursor-not-allowed`}
                  readOnly
                  value={value}
                />
              )}
            </div>
          </div>
          <Transition
            as={Fragment}
            show={isOpen}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options
              className={`relative z-50 w-full ${
                options?.length > 3 ? 'h-44' : ' h-28'
              } overflow-y-auto  origin-top-right divide-y divide-[#374349] rounded-b-[12px] bg-textFieldColor  ring-black ring-opacity-5 focus:outline-none `}
            >
              {filteredOption.map((option, index) => {
                return (
                  <div key={index}>
                    <Combobox.Option
                      className={({ active }) =>
                        `select-none py-2 px-2 ${active ? 'bg-borderActive text-white' : 'text-gray-900'} cursor-pointer hover:bg-[#374349]`
                      }
                      value={option?.muscleName}
                    >
                      <span className={`${dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-white text-[14px] ]`}>
                        {option?.muscleName}
                      </span>
                    </Combobox.Option>
                  </div>
                )
              })}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  )
}
