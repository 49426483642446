import Assets from 'assets/images'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const EmptyWorkoutsContainer = ({ workouts }) => {
  const { t } = useTranslation()
  const { direction } = useSelector((state) => state.language)
  const coachHasStartedSomeWorkout = false
  const coachHasCompletedAllWorkouts = false

  return (
    <div className="w-full h-full bg-secondaryBackground rounded-lg flex justify-center items-center space-y-4 flex-col text-center">
      <img src={Assets.EmptyState} alt="empty-state" className="bg-cover w-28 h-28" />
      <div className="space-y-2">
        {coachHasStartedSomeWorkout ? (
          <>
            {coachHasCompletedAllWorkouts ? (
              <h6 className={`text-2xl text-white font-medium leading-7 ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>
                {t('WELL_DONE')}
              </h6>
            ) : (
              <h6 className={`text-md text-white font-medium leading-7 ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>
                {t('BREAK_TIME')}
              </h6>
            )}
          </>
        ) : (
          <h6 className={`text-xl text-white font-medium leading-7 ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>
            {t('BIT_EMPTY')}
          </h6>
        )}
        {coachHasCompletedAllWorkouts ? (
          <h6 className={`text-xs ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>{t('COMPLETED_ALL_DAY')}</h6>
        ) : (
          <h6 className={`text-xs ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>{t('ADD_WORKOUT_TO_GET_STARTED')}</h6>
        )}
      </div>
    </div>
  )
}

export { EmptyWorkoutsContainer }
