import axiosInstance from 'api'
import { Images } from 'assets'
import CustomToastify from 'components/compounds/Toast'

import { muscleActions } from './muscleReducers'

export const getAllMuscles =
  (id, t, direction, filter = 'all') =>
  async (dispatch) => {
    try {
      dispatch(muscleActions.setLoader(true))

      await axiosInstance
        .get(`getAllGymMuscles/${id}`, { params: { query: filter } })
        .then((response) => {
          dispatch(muscleActions.setAllMuscles(response.result.data))
        })
        .finally(() => {
          dispatch(muscleActions.setLoader(false))
        })
    } catch (e) {
      dispatch(muscleActions.setLoader(false))
      CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return console.error(e.message)
    }
  }
export const createMuscle = (data, action, t, direction, id) => async (dispatch) => {
  try {
    dispatch(muscleActions.setLoader(true))
    await axiosInstance.post(`/CreateMuscle`, data).then((response) => {
      dispatch(muscleActions.setLoader(false))
      dispatch(getAllMuscles(id, t, direction))
      CustomToastify(t('MUSCLE_CREATED'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
      action && action()
    })
  } catch (e) {
    dispatch(muscleActions.setLoader(false))
    if (e.code === 502) {
      CustomToastify(t('MUSCLE_EXIST'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    } else {
      CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    }
    return console.error(e.message)
  }
}
export const updateMuscle = (data, action, t, direction, id) => async (dispatch) => {
  try {
    dispatch(muscleActions.setLoader(true))
    await axiosInstance.put(`/UpdateMuscle`, data).then((response) => {
      dispatch(muscleActions.setLoader(false))
      dispatch(getAllMuscles(id, t, direction))
      CustomToastify(t('MUSCLE_CREATED'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
      action && action()
    })
  } catch (e) {
    dispatch(muscleActions.setLoader(false))
    if (e.code === 502) {
      CustomToastify(t('MUSCLE_EXIST'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    } else {
      CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    }
    return console.error(e.message)
  }
}

export const getMuscleById = (id, t, direction) => async (dispatch) => {
  try {
    dispatch(muscleActions.setLoader(true))
    try {
      const response = await axiosInstance.get(`getMuscleById/${id}`)
      const bodyPartsList = await axiosInstance.get(`getAllMuscleModelist`)
      dispatch(muscleActions.setSingleMuscles(response.result))
      dispatch(muscleActions.setBodyPartList(bodyPartsList.result))
    } finally {
      dispatch(muscleActions.setLoader(false))
    }
  } catch (e) {
    dispatch(muscleActions.setLoader(false))

    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}

export const deleteGymMuscle =
  ({ id, gymId }, t, direction) =>
  async (dispatch) => {
    try {
      dispatch(muscleActions.setLoader(true))
      await axiosInstance
        .delete(`deleteMuscle?id=${id}&gymId=${gymId}`)
        .then((response) => {
          dispatch(muscleActions.removeMuscle(id))

          CustomToastify(t('MUSCLE_DELETED_SUCCESSFULLY'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
        })
        .finally(() => {
          dispatch(muscleActions.setLoader(false))
        })
    } catch (e) {
      dispatch(muscleActions.setLoader(false))
      CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return console.error(e.message)
    }
  }
