import React, { useEffect, useRef, useState } from 'react'
import { Images } from 'assets'
import { Loader } from 'components/compounds/Loader'
import imageCompression from 'browser-image-compression'
import { useSelector } from 'react-redux'

export default function ImagePicker({
  label,
  placeholder,
  imageURL,
  setImageURL,
  errorMessage,
  imageError,
  setImageError,
  setSelectedFile,
  selectedFile,
  styles,
  disabled,
}) {
  const [isLoading, setIsLoading] = useState(false)
  const { direction } = useSelector((state) => state.language)
  const inputRef = useRef()
  useEffect(() => {
    loadImageUrl()
    // eslint-disable-next-line
  }, [selectedFile])

  const onImageChange = async (event) => {
    setIsLoading(true)
    const options = {
      maxSizeMB: 2,
      useWebWorker: true,
    }
    let compressedFile
    try {
      compressedFile = await imageCompression(event.target.files[0], options)
    } catch (error) {
      console.log('Error in upload >> ', error)
    }

    setSelectedFile(compressedFile)
  }

  const loadImageUrl = () => {
    if (selectedFile) {
      const reader = new FileReader()
      reader.onload = (event) => {
        setImageURL(event.target.result)

        setImageError(false)
        setIsLoading(false)
      }
      reader.readAsDataURL(selectedFile)
    }
  }

  return (
    <>
      <div className="flex flex-row  w-full justify-center ">
        {label && (
          <label
            className={`flex flex-col py-1 text-white ${
              direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
            } text-[16px] justify-center  w-36 `}
          >
            {label}
          </label>
        )}
        <input
          ref={inputRef}
          type={'file'}
          accept="image/*;capture=camera"
          onChange={onImageChange}
          style={{ display: 'none' }}
          disabled={disabled}
        />

        <div
          className={`flex flex-col  ${!imageURL && 'cursor-pointer'} ${styles} `}
          onClick={() => {
            if (!imageURL) {
              inputRef.current.click()
            }
          }}
        >
          {isLoading ? (
            <Loader className="" size="w-6 h-6" fill="#003353" />
          ) : (
            <>
              {imageURL ? (
                <div className={`relative ${styles ? styles : ' w-[140px] h-[140px]'}`}>
                  <input
                    ref={inputRef}
                    type={'file'}
                    accept="image/*"
                    onChange={onImageChange}
                    style={{ display: 'none' }}
                    capture="environment"
                    disabled={disabled}
                  />
                  &nbsp;
                  <img
                    src={imageURL}
                    alt=""
                    className={` -mt-6 items-center`}
                    style={{ width: '100%', height: '100%', borderRadius: '100%', objectFit: 'cover' }}
                  />
                  <div className="w-[32px] h-[32px]  rounded-[50%] z-40 bg-statusBg absolute right-0 bottom-0 p-1">
                    <img
                      src={Images.Camera}
                      alt=""
                      height={70}
                      width={70}
                      className={`pt-[2px] cursor-pointer`}
                      onClick={() => {
                        inputRef.current.click()
                      }}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <input
                    ref={inputRef}
                    type={'file'}
                    accept="image/*;capture=camera"
                    onChange={onImageChange}
                    capture="environment"
                    style={{ display: 'none' }}
                    disabled={disabled}
                  />
                  &nbsp;
                  <div>
                    <img src={Images.Camera} alt="" height={70} width={70} className={`px-3 items-center cursor-pointer`} />
                    <p
                      className={`text-white  text-[11px] text-center pb-4 pt-1 cursor-pointer  ${
                        direction === 'ltr' ? 'font-primary-bd' : 'font-primary-he-bd'
                      }`}
                    >
                      {placeholder}
                    </p>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      {imageError && <p className={`form-errors flex flex-col text-right  pr-28`}>{errorMessage}</p>}
    </>
  )
}
