import { Images } from 'assets'
import { Modal } from 'components/compounds/Modal'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Loader } from 'components/compounds/Loader'
import CustomToastify from 'components/compounds/Toast'

import MuscleFooter from './MuscleFooter'
import { getMuscleById } from 'store/muscleSlice/muscleActions'

import BodyMap from 'components/bodyMap'

export default function CreateMuscle({ methods, show, setShow, muscleID, setMucleID }) {
  const { loader, muscles, currentMuscle, bodyPartList } = useSelector((state) => state?.muscle)
  // eslint-disable-next-line
  const [disable, setDisable] = useState(false)
  const [bodyPart, setBodyPart] = useState('')

  // eslint-disable-next-line
  const [openModal, setOpenModal] = useState(false)

  const { t, i18n } = useTranslation()
  // eslint-disable-next-line
  const { reset, control, handleSubmit, formState, setValue } = methods

  const dispatch = useDispatch()
  const { direction } = useSelector((state) => state.language)

  const handleClose = () => {
    reset({
      muscleName: '',
    })
    setMucleID(null)
    setShow(false)
  }

  const toggleOpen = () => setOpenModal(true)

  const onSubmit = async (values) => {
    handleClose()
  }
  useEffect(() => {
    if (muscleID) {
      dispatch(getMuscleById(muscleID, t, direction))
    }
    // eslint-disable-next-line
  }, [muscleID])
  useEffect(() => {
    if (muscles && muscleID) {
      setValue('muscleName', currentMuscle?.muscleName)
      if (currentMuscle?.muscle_model_id) {
        setBodyPart(currentMuscle?.muscle_model_id)
      }
    }
    // eslint-disable-next-line
  }, [currentMuscle])

  const notify = () => {
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
  }

  const currentBodyPart = useMemo(() => {
    const part = bodyPartList?.find((item) => item.id === bodyPart)
    return part ? (i18n.language === 'en' ? part.name : part.hebrewName) : ''
  }, [bodyPartList, bodyPart, i18n.language])

  return (
    <Modal
      className={`h-full w-[480px] ${direction === 'ltr' ? 'rounded-l-3xl' : 'rounded-r-3xl'}   `}
      childrenClass="overflow-auto "
      open={show}
      title={muscleID ? currentBodyPart : t('ADD_A_NEW_MUSCLE')}
      handleClose={handleClose}
      handleSubmit={handleSubmit(onSubmit)}
      handleNotify={notify}
      loader={loader}
      isEdited={muscleID != null ? true : false}
      toggleOpen={toggleOpen}
      disabled={disable}
      direction={direction}
      footer={<MuscleFooter handleSubmit={handleSubmit(onSubmit)} id={muscleID} />}
    >
      {loader ? (
        <Loader className="" size="w-8 h-8" fill="#003353" />
      ) : (
        <>
          <div className="flex flex-row py-4 items-center justify-center">
            <BodyMap selectedValue={bodyPart} setSelectedValue={setBodyPart} hoverEnabled={false} w={400} h={600} />
          </div>
        </>
      )}
    </Modal>
  )
}
