import { combineReducers } from 'redux'

import { authReducer } from '../authSlice/authReducers'
import { languageReducer } from '../languageSlice'
import { userReducer } from '../userSlice/userReducer'
import { gymAdminReducer } from 'store/gymAdminSlice/gymAdminReducers'
import { coachReducer } from 'store/coachSlice/coachReducers'
import { muscleReducer } from 'store/muscleSlice/muscleReducers'
import { exerciseReducer } from 'store/exerciseSlice/exerciseReducers'
import { programReducer } from 'store/programSlice/programReducers'
import { traineeReducer } from 'store/traineeSlice/traineeReducer'
import { eventReducer } from 'store/eventSlice/eventReducers'
import { workoutReducer } from 'store/workoutSlice/workoutReducer'
import { templateReducer } from 'store/templateSlice/templateReducers'
import { dasboardReducer } from 'store/dashboardSlice/dashboardReducers'

const rootReducer = combineReducers({
  auth: authReducer,
  language: languageReducer,
  user: userReducer,
  gymAdmin: gymAdminReducer,
  coach: coachReducer,
  muscle: muscleReducer,
  exercise: exerciseReducer,
  program: programReducer,
  trainee: traineeReducer,
  event: eventReducer,
  workout: workoutReducer,
  template: templateReducer,
  dashboard: dasboardReducer,
})

export { rootReducer }
