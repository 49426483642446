import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import ExerciseLibrary from './exerciseLibrary'
import MuscleLibrary from './muscleLibrary'
import Templates from './templates'

const Menus = ['EXERCISES', 'MUSCLES', 'TEMPLATES']

const MenuComponent = {
  EXERCISES: ExerciseLibrary,
  MUSCLES: MuscleLibrary,
  TEMPLATES: Templates,
}

export default function AdminLibrary() {
  const [active, setActive] = useState(Menus[0])
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const curractive = queryParams.get('active')
    if (curractive) {
      setActive(curractive)
    }
  }, [location.search])

  const ActiveComponent = MenuComponent[active]

  return (
    <div className="flex flex-col w-full ">
      <div className="flex gap-1 pt-5 text-white ltr:font-primary-rg rtl:font-primary-he-rg px-8">
        <p
          className="text-white text-[14px] ltr:font-primary-md rtl:font-primary-he-md hover:border-white hover:border-b-2 cursor-pointer"
          onClick={() => navigate('/admin/dashboard')}
        >
          {t('MAIN')}
        </p>
        <p>/</p>
        <p
          className="text-white text-[14px] ltr:font-primary-md rtl:font-primary-he-md hover:border-white hover:border-b-2 cursor-pointer"
          onClick={() => navigate('/admin/library')}
        >
          {t('LIBRARY')}
        </p>
        <p>/</p>
        <p className="text-breadCrumb ltr:font-primary-md rtl:font-primary-he-md text-[14px]">{t(active)}</p>
      </div>
      <div className="flex w-full self-center flex-row pt-7 border-b-[1px] border-borderColor px-8">
        {Menus.map((item) => (
          <div
            key={item}
            className={`text-white text-[14px] ltr:font-primary-rg rtl:font-primary-he-rg text-center py-2 cursor-pointer px-10 ${item === active ? ' border-b-2 border-white z-10' : ''}`}
            onClick={() => setActive(item)}
          >
            {t(item)}
          </div>
        ))}
      </div>
      {ActiveComponent && <ActiveComponent active={active} />}
    </div>
  )
}