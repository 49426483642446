import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import ExerciseDropDown from './components/exerciseDropDown'
import SampleTemplates from './components/SampleTemplates'

export default function ExerciseSectionTemplate({ dragOver, selectedExercises, setSelectedExercises }) {
  const [isOpen, setIsOpen] = useState(true)
  const [isTemplateOpen, setIsTemplateOpen] = useState(false)
  const { direction } = useSelector((state) => state.language)
  const { exercisesInfo } = useSelector((state) => state.gymAdmin)

  const toggleDropdown = () => {
    setIsTemplateOpen(false)
    setIsOpen(!isOpen)
  }
  const toggleDropdownTemplate = () => {
    setIsOpen(false)
    setIsTemplateOpen(!isTemplateOpen)
  }
  return (
    <div className={`flex flex-col w-[45%] h-full  bg-secondaryBackground rounded-[12px]  py-[16px] px-[24px] gap-y-2`}>
      <ExerciseDropDown
        toggleDropdown={toggleDropdown}
        isOpen={isOpen}
        direction={direction}
        data={exercisesInfo}
        dragOver={dragOver}
        selectedExercises={selectedExercises}
        setSelectedExercises={setSelectedExercises}
      />
      <SampleTemplates
        toggleDropdown={toggleDropdownTemplate}
        isOpen={isTemplateOpen}
        direction={direction}
        selectedExercises={selectedExercises}
        setSelectedExercises={setSelectedExercises}
      />
    </div>
  )
}
