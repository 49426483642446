import SwitchToggle from 'components/compounds/SwitchToggle'
import PrimaryButton from 'components/elements/PrimaryButton'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UnSuspendGym } from 'store/userSlice/userActions'

export default function EditCustomerFooter({ loader, handleNotify, handleSubmit, t, toggleOpen, toggleSuspendOpen, disabled, direction }) {
  const [checked, setChecked] = useState(false)
  const dispatch = useDispatch()
  const { currGymDetail } = useSelector((state) => state.user)
  useEffect(() => {
    if (currGymDetail?.subscriptionStatus === 'Suspended' && currGymDetail?.User?.isActive === false) {
      setChecked(true)
    } else {
      setChecked(false)
    }
  }, [currGymDetail])
  const handleChange = (event) => {
    if (event.target.checked === true) {
      toggleSuspendOpen()
    } else {
      dispatch(UnSuspendGym(currGymDetail?.User?.id, t, direction))
    }
  }
  return (
    <div className="flex rounded-b-[28px] flex-row py-2 px-4 justify-between xl:px-6 2xl:px-12 border-t-[1px] border-borderColor absolute bottom-0 w-full bg-sidebar">
      <PrimaryButton
        text={t('DELETE')}
        type="submit"
        disabled={false}
        isIcon={false}
        onClick={toggleOpen}
        className="w-[144px] h-[44px] rounded-[12px] shadow-2xl"
        bgColor="bg-sidebar hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
        textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
        wid={20}
        ht={20}
      />
      <div className="flex self-center w-[1px] h-6 bg-[#ccc]"></div>
      <div className="p-4">
        <SwitchToggle name="SUSPENDED" t={t} handleChange={handleChange} checked={checked} />
      </div>

      <PrimaryButton
        text={t('SAVING_CHANGES')}
        type="submit"
        disabled={disabled}
        isIcon={false}
        onClick={handleSubmit}
        className=" rounded-[12px] shadow-2xl"
        bgColor="bg-prussianBlue hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
        textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
      />
    </div>
  )
}
