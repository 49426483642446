import { constants } from 'constant'
import { useSelector } from 'react-redux'
import { getBestResistanceValue, getBestTrackingValue, lastPerformedDate } from 'utils'

export default function RecommendedExercise({ recommendedExercise, selectedExercise, handleSelectExercise, t, direction }) {
  const { bands } = useSelector((state) => state.gymAdmin)
  const getResistanceValue = (data) => {
    if (data?.resistance_name === constants.RUBBER_BAND) {
      return bands[0].machineName
    } else {
      if (data?.lastExercise) {
        return getBestResistanceValue(data?.lastExercise?.Sets)
      } else {
        return 10
      }
    }
  }
  return (
    <div className="flex flex-col gap-2">
      <p className=" font-primary-rg text-[14px] pt-4 leading-5 text-white font-normal">{t('RECOMMENDED_EXERCISES')}</p>
      {/* ---------------- Selected Exercise--------------------- */}
      {selectedExercise && recommendedExercise?.some((exercise) => exercise?.id === selectedExercise?.id) && (
        <div className="flex flex-col bg-borderColor w-full rounded-lg p-4 gap-4">
          <div className="flex flex-row w-full justify-between">
            <p className=" font-primary-rg font-medium text-[14px] text-white">{selectedExercise?.exercise_name}</p>
            {/* eslint-disable-next-line */}
            {selectedExercise?.exerciseCount != 0 && (
              <p className=" font-primary-rg font-medium text-[12px] text-black rounded px-[7px] bg-lightGreen">
                {t('DONE')} {selectedExercise?.exerciseCount}
              </p>
            )}
          </div>

          <div className="flex space-x-3 rtl:space-x-reverse justify-between">
            <div className="flex flex-col bg-[#505C62] rounded-xl w-full px-4 py-3 space-y-1">
              <p className="font-primary-rg text-[12px] leading-4 text-textLightGrey">{t('SETS')}</p>
              <p className="font-primary-rg text-[12px] leading-4 text-textLightGrey">{selectedExercise?.lastExercise?.setsCount || 3}</p>
            </div>
            <div className="flex flex-col bg-[#505C62] rounded-xl w-full px-4 py-3 space-y-1">
              <p className=" font-primary-rg text-[12px] leading-4 text-textLightGrey">{selectedExercise?.resistance_name}</p>
              <p className=" font-primary-rg text-[12px] leading-4 text-textLightGrey">{getResistanceValue(selectedExercise)}</p>
            </div>
            <div className="flex flex-col bg-[#505C62] rounded-xl w-full px-4 py-3 space-y-1">
              <p className="font-primary-rg text-[12px] leading-4 text-textLightGrey">{selectedExercise?.trackingname}</p>
              <p className="font-primary-rg text-[12px] leading-4 text-textLightGrey">
                {' '}
                {selectedExercise?.lastExercise ? getBestTrackingValue(selectedExercise?.lastExercise?.Sets) : 8}
              </p>
            </div>
          </div>

          {/* ----------------- Divider --------------------- */}
          <div className="flex w-full h-[1px] bg-[#505C62]"></div>
          <div className="flex flex-col gap-2">
            <p className=" font-primary-rg text-[14px] text-white leading-4">{t('EXERCISE_INFO')}</p>
            <div className="flex w-full flex-row gap-2 ">
              <div className="flex flex-col w-1/2  rounded-lg p-2 gap-2 bg-[#2e5168]">
                <div className="flex justify-between gap-2">
                  <p className=" font-primary-rg text-[12px] text-white leading-4">{t('TRACKING_TYPE')}</p>
                  <p className=" font-primary-rg text-[12px] text-white leading-4">{selectedExercise?.trackingname}</p>
                </div>
                <div className="flex justify-between gap-2">
                  <p className=" font-primary-rg text-[12px] text-white leading-4">{t('RESISTANCE_TYPE')}</p>
                  <p className=" font-primary-rg text-[12px] text-white leading-4">{selectedExercise?.resistance_type}</p>
                </div>
                <div className="flex justify-between gap-2">
                  <p className=" font-primary-rg text-[12px] text-white leading-4">{t('MUSCLES')}</p>
                  <div className="flex flex-row flex-wrap">
                    {selectedExercise?.ExerciseMuscles?.map((item) => (
                      <p className=" font-primary-rg text-[12px] text-white leading-4">
                        {direction === 'rtl' ? item?.Muscle?.hebrewName : item?.Muscle?.muscleName},
                      </p>
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-1/2  rounded-lg p-2 gap-2 ">
                {selectedExercise?.lastExercise && (
                  <div className="flex bg-[#41565F] rounded-lg gap-2 text-[12px] font-primary-rg text-white font-thin p-2">
                    {' '}
                    {t('LAST_PERFORMED')}: {lastPerformedDate(selectedExercise?.lastExercise?.date)}
                  </div>
                )}
                {selectedExercise?.tags && (
                  <div className="flex flex-row bg-[#41565F] rounded-lg gap-2 p-2 flex-wrap">
                    <p className="text-[12px] font-primary-rg text-white font-thin">{t('TAGS')}:</p>

                    <p className="text-[12px] font-primary-rg text-white font-thin">{selectedExercise?.tags}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-row flex-wrap gap-2">
        {recommendedExercise?.map((item) => (
          <div
            className="flex flex-row bg-playCard rounded-lg p-4  gap-2 items-center cursor-pointer"
            onClick={() => {
              handleSelectExercise(item)
            }}
          >
            <p className=" font-primary-rg font-medium text-[14px] text-white">{item?.exercise_name}</p>
            {/* eslint-disable-next-line */}
            {item?.exerciseCount != 0 && (
              <p className=" font-primary-rg font-medium text-[12px] text-black rounded px-[7px] bg-lightGreen">
                {t('DONE')} {item?.exerciseCount}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
