import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { languageActions } from 'store/languageSlice/index'
import { useTranslation } from 'react-i18next'
import { Images } from 'assets'
import { constants } from 'constant'

const LANGUAGES = constants.APP_LANGUAGES_LIST

const LanguageSelector = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false)
  const { t } = useTranslation()
  const auth = useSelector((state) => state?.auth)
  const role = auth?.user?.role

  const hideSelector = !role ? false : role !== 'admin'
  return (
    <>
      {!hideSelector && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            right: 0,
            zIndex: 9999,
            display: 'flex',
            flexDirection: 'row',
            background: 'red',
            height: '0px',
          }}
        >
          <div
            style={{
              transition: '0.1s ease-in-out',
            }}
            onClick={() => setDrawerOpen(false)}
          />

          <div
            onClick={() => setDrawerOpen(true)}
            style={{
              width: isDrawerOpen ? 0 : '60px',
              visibility: isDrawerOpen ? 'hidden' : 'visible',
              height: '50px',
              background: 'white',
              boxShadow: 20,
              cursor: 'pointer',

              borderTopLeftRadius: '20px',
              borderBottomLeftRadius: '20px',
              marginTop: '88vh',
              border: '1px solid lightgrey',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',

              transition: '0.15s all ease-in-out',

              color: 'green',
              zIndex: 99,
              overflow: 'hidden',

              '&:hover': {
                width: '75px',
              },
            }}
          >
            <img src={Images.Translate} alt="" height={45} width={45} />
          </div>

          <div
            style={{
              height: '100vh',
              width: isDrawerOpen ? '300px' : '0px',
              maxWidth: '100vw',
              background: '#0A151C',
              overflow: 'hidden',
              boxShadow: 20,
              zIndex: 101,
              transition: '0.25s all ease-in-out',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: 5,
              }}
            >
              <p className=" text-white font-primary-rg text-[20px]">{t('SELECT_LANGUAGE')}</p>
              <img className="mr-2 cursor-pointer " src={Images.Close} onClick={() => setDrawerOpen(false)} alt="" height={30} width={30} />
            </div>
            <Languages setDrawerOpen={setDrawerOpen} />
          </div>
        </div>
      )}
    </>
  )
}

const Languages = ({ setDrawerOpen }) => {
  const { language } = useSelector((state) => state.language)

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        padding: 5,
      }}
    >
      <Language language={LANGUAGES[0]} currentLanguage={language} setDrawerOpen={setDrawerOpen} />
      <Language language={LANGUAGES[1]} currentLanguage={language} setDrawerOpen={setDrawerOpen} />
    </div>
  )
}

const Language = ({ language: { code, name, flag }, currentLanguage, setDrawerOpen }) => {
  const dispatch = useDispatch()
  const { i18n } = useTranslation()

  useEffect(() => {
    persistLocaleCookie(currentLanguage)
    document.body.setAttribute('dir', currentLanguage === 'en' ? 'ltr' : 'rtl')
    // eslint-disable-next-line
  }, [currentLanguage])

  function persistLocaleCookie(code) {
    i18n.changeLanguage(code)
    document.body.setAttribute('dir', code === 'en' ? 'ltr' : 'rtl')
    i18n.reloadResources()
  }

  return (
    <div
      style={{
        display: 'flex',
        // justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        padding: '8px 12px',
        marginTop: '4px',
        marginBottom: '4px',
        background: '#fafafa',
        borderRadius: 3,
        cursor: 'pointer',

        justifyContent: 'space-between',
        transition: '0.25s all ease-in-out',
        '&:hover': {
          background: '#f0f0f0',
        },
      }}
      onClick={() => {
        if (code !== currentLanguage) {
          dispatch(languageActions.setLanguage(code))
          i18n.changeLanguage(code)
          i18n.reloadResources()
          persistLocaleCookie(code)
          setDrawerOpen(false)
        }
      }}
    >
      <div className="flex flex-row">
        <p style={{ fontSize: 16, marginRight: 2 }}>{flag}</p>
        <p className=" text-black font-spectral-regular text-[16px] px-2">{name}</p>
      </div>
      {code === currentLanguage && <img className="bg-red mr-2" src={Images.Mark} alt="" height={10} width={10} />}
    </div>
  )
}

export default LanguageSelector
