import { Images } from 'assets'
import { constants } from 'constant'
import { useOutsideClick, useDebounce } from 'hooks'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getExerciseFilter } from 'store/exerciseSlice/exerciseActions'
import { getExercisesForTrainnee } from 'store/workoutSlice/workoutActions'
import { SearchInput } from 'components/compounds/SearchInput'
import { handleFilterSelection } from 'utils'

export default function PlayWorkoutAddExerciseHeader(props) {
  const { exercisefilterOptions } = constants

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { gymId, traineeId } = props
  const { direction, language } = useSelector((state) => state.language)
  const { customerDetail } = useSelector((state) => state.user)
  const { exerciseFilters } = useSelector((state) => state.exercise)

  const filtersRef = useRef()
  const [filtersMenu, setFiltersMenu] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState('')
  const [activeFilter, setActiveFilter] = useState()
  const [selectedFilterMenu, setSelectedFilterMenu] = useState(false)
  const [isMuscles, setIsMuscle] = useState(false)
  const [searchText, setSearchText] = useState('')
  const debouncedSearchTerm = useDebounce(searchText, 500)
  const [temp1, setTemp1] = useState({
    MUSCLES: [],
    RESISTANCE: [],
    TRACKING: [],
    MACHINE: [],
    MAIN_EXERCISE: [],
    MUSCLE_TYPE: [],
    TAGS: [],
  })

  useEffect(() => {
    const getData = async () => {
      dispatch(getExerciseFilter(customerDetail?.id))
    }
    getData()
    // eslint-disable-next-line
  }, [])

  const action = () => {}
  useEffect(() => {
    const getTraineesExercises = async () => {
      if (
        temp1.MUSCLES.length > 0 ||
        temp1.RESISTANCE.length > 0 ||
        temp1.TRACKING.length > 0 ||
        temp1.MACHINE.length > 0 ||
        temp1.TAGS.length > 0 ||
        temp1.MAIN_EXERCISE.length > 0
      ) {
        if (temp1.MAIN_EXERCISE.length > 0) {
          const exerciseIds = exerciseFilters?.MAIN_EXERCISE.filter((exercise) => temp1.MAIN_EXERCISE.includes(exercise.label)).map(
            (exercise) => exercise.id
          )

          let data = {
            MUSCLES: temp1.MUSCLES,
            RESISTANCE: temp1.RESISTANCE,
            TRACKING: temp1.TRACKING,
            MACHINE: temp1.MACHINE,
            MAIN_EXERCISE: exerciseIds,
            MUSCLE_TYPE: temp1.MUSCLE_TYPE,
            TAGS: temp1.TAGS,
          }

          dispatch(getExercisesForTrainnee(gymId, traineeId, null, action, t, direction, { search: debouncedSearchTerm, filter: data }))
          return
        } else {
          dispatch(getExercisesForTrainnee(gymId, traineeId, null, action, t, direction, { search: debouncedSearchTerm, filter: temp1 }))
        }
      } else if (debouncedSearchTerm) {
        dispatch(getExercisesForTrainnee(gymId, traineeId, null, action, t, direction, { search: debouncedSearchTerm, filter: temp1 }))
      } else {
        dispatch(getExercisesForTrainnee(gymId, traineeId, null, action, t, direction))
      }
    }
    getTraineesExercises()
    // eslint-disable-next-line
  }, [temp1, debouncedSearchTerm])

  const onClickOutsideFiltersDiv = () => {
    setFiltersMenu(false)
    setSelectedFilterMenu(false)
    setIsMuscle(false)
  }

  useOutsideClick(filtersRef, onClickOutsideFiltersDiv)

  return (
    <div className="flex w-full flex-col">
      <div className="flex flex-col w-full pt-6 pb-2   self-center gap-2">
        <SearchInput
          radius={'rounded-[50px]'}
          placeholder={t('SEARCH_EXERCISE')}
          className="flex flex-1 w-full"
          area="w-full h-[35px]"
          disableUnderline
          fullWidth
          value={searchText}
          inputProps={{
            'aria-label': 'Search',
          }}
          onChange={(event) => setSearchText(event.target.value)}
          icon={Images.Search}
        />
        <div ref={filtersRef} className="relative box-border">
          <div
            className={`flex flex-row w-[156px] h-[40px] items-center rounded-[12px] cursor-pointer bg-primary
            border-borderActive border-[1.5px]
            }`}
            onClick={() => {
              setFiltersMenu(!filtersMenu)
              setSelectedFilterMenu(false)
            }}
          >
            <img src={Images.Filter} alt="" height={40} width={45} className={`p-3 items-center `} onClick={() => {}} />
            <p className={`text-textGrey text-center text-[14px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>
              {t('ADDING_FILTER')}
            </p>
          </div>
          {filtersMenu ? (
            <div
              className={`absolute top-11 bg-statusBg rounded-[12px] w-[156px] max-h-[200px] overflow-auto shadow-xl ${
                filtersMenu ? 'border-borderActive border-[2px]' : ''
              }`}
            >
              <p
                className={`px-2 pb-1 text-textGrey text-[12px] ${
                  direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                } bg-checkoutHover cursor-none`}
              >
                {t('FILTER_BY')}
              </p>

              {exercisefilterOptions.map((opt, i) => (
                <p
                  key={i}
                  className={`px-2 py-1 text-textGrey text-[12px] ${
                    direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                  } hover:bg-checkoutHover cursor-pointer`}
                  onClick={() => {
                    if (opt === 'MUSCLES') {
                      setActiveFilter(exerciseFilters?.MUSCLES)
                    } else if (opt === 'RESISTANCE') {
                      setActiveFilter(exerciseFilters?.RESISTANCE)
                    } else if (opt === 'TRACKING') {
                      setActiveFilter(exerciseFilters?.TRACKING)
                    } else if (opt === 'MACHINE') {
                      setActiveFilter(exerciseFilters?.MACHINE)
                    } else if (opt === 'MAIN_EXERCISE') {
                      setActiveFilter(exerciseFilters?.MAIN_EXERCISE)
                    } else if (opt === 'TAGS') {
                      setActiveFilter(exerciseFilters?.TAGS)
                    }
                    setSelectedFilter(opt)
                    setFiltersMenu(false)
                    setSelectedFilterMenu(true)
                  }}
                >
                  {t(opt)}
                </p>
              ))}
            </div>
          ) : null}

          {/* Main Selected Filter */}
          {selectedFilterMenu ? (
            <div
              className={`absolute top-11 py-3 bg-statusBg rounded-[12px] w-[156px] max-h-[200px] overflow-auto shadow-xl ${
                selectedFilterMenu ? 'border-borderActive border-[2px]' : ''
              }`}
            >
              <div
                className="flex flex-row hover:bg-checkoutHover cursor-pointer"
                onClick={() => {
                  setSelectedFilterMenu(false)
                  setFiltersMenu(true)
                }}
              >
                <img src={direction === 'rtl' ? Images.backArrow : Images.backLessArrow} alt="" height={8} width={25} className={'p-1'} dir="ltr" />
                <p className={`py-1 text-textGrey text-[12px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} `}>
                  {t(selectedFilter)}
                </p>
              </div>
              {activeFilter.map((opt, i) => (
                <div
                  key={i}
                  className={`flex flex-row px-2 py-1  justify-between ${
                    direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                  } hover:bg-checkoutHover cursor-pointer `}
                  dir={direction}
                  onClick={() => {
                    if (selectedFilter === 'MUSCLES') {
                      if (!temp1['MUSCLES'].includes(opt.label)) {
                        setSelectedFilterMenu(false)
                        setIsMuscle(opt)
                      }
                    }
                    handleFilterSelection(selectedFilter, opt, temp1, setTemp1)
                  }}
                >
                  <p
                    className={`text-[12px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} ${
                      temp1.MUSCLES.includes(opt.label) ||
                      temp1.RESISTANCE.includes(opt.label) ||
                      temp1.TRACKING.includes(opt.label) ||
                      temp1.MACHINE.includes(opt.label) ||
                      temp1.TAGS.includes(opt.label) ||
                      temp1.MAIN_EXERCISE.includes(opt.label)
                        ? 'text-activeText'
                        : 'text-textGrey'
                    } `}
                  >
                    {language === 'he' && opt.label_he ? opt.label_he : t(opt.label)}
                  </p>
                  <p
                    className={`text-[12px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} ${
                      temp1.MUSCLES.includes(opt.label) ||
                      temp1.RESISTANCE.includes(opt.label) ||
                      temp1.TRACKING.includes(opt.label) ||
                      temp1.MACHINE.includes(opt.label) ||
                      temp1.TAGS.includes(opt.label) ||
                      temp1.MAIN_EXERCISE.includes(opt.label)
                        ? 'text-activeText'
                        : 'text-textGrey'
                    } `}
                  >
                    {t(opt.count)}
                  </p>
                </div>
              ))}
            </div>
          ) : null}
          {!selectedFilterMenu && isMuscles ? (
            <div
              className={`absolute top-11 py-3 bg-statusBg rounded-[12px] w-[156px] max-h-[200px] overflow-auto shadow-xl ${
                selectedFilterMenu ? 'border-borderActive border-[2px]' : ''
              }`}
            >
              <div
                className="flex flex-row hover:bg-checkoutHover cursor-pointer"
                onClick={() => {
                  setSelectedFilterMenu(true)
                  setIsMuscle(false)
                }}
              >
                <img src={direction === 'rtl' ? Images.backArrow : Images.backLessArrow} alt="" height={8} width={25} className={'p-1'} dir="ltr" />
                <p className={`py-1 text-textGrey text-[12px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} `}>
                  {t(isMuscles?.label)}
                </p>
              </div>

              <div className="flex flex-col ">
                <div
                  className={`flex flex-row px-2 py-1  justify-between ${
                    direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                  } hover:bg-checkoutHover cursor-pointer `}
                  dir={direction}
                  onClick={() => {
                    handleFilterSelection('MUSCLE_TYPE', isMuscles, temp1, setTemp1, 'primary')
                  }}
                >
                  <p
                    className={`text-[12px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} ${
                      temp1.MUSCLE_TYPE.some((obj) => obj.label === isMuscles.label && obj.isPrimary === true) ? 'text-activeText' : 'text-textGrey'
                    } `}
                  >
                    {t('Primary')}
                  </p>
                  <p
                    className={`text-[12px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} ${
                      temp1.MUSCLE_TYPE.some((obj) => obj.label === isMuscles.label && obj.isPrimary === true) ? 'text-activeText' : 'text-textGrey'
                    } `}
                  >
                    {isMuscles.primary}
                  </p>
                </div>
                <div
                  className={`flex flex-row px-2 py-1  justify-between ${
                    direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                  } hover:bg-checkoutHover cursor-pointer `}
                  dir={direction}
                  onClick={() => {
                    handleFilterSelection('MUSCLE_TYPE', isMuscles, temp1, setTemp1, 'assitant')
                  }}
                >
                  <p
                    className={`text-[12px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} ${
                      temp1.MUSCLE_TYPE.some((obj) => obj.label === isMuscles.label && obj.isAssistance === true)
                        ? 'text-activeText'
                        : 'text-textGrey'
                    } `}
                  >
                    {t('Assistent')}
                  </p>
                  <p
                    className={`text-[12px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} ${
                      temp1.MUSCLE_TYPE.some((obj) => obj.label === isMuscles.label && obj.isAssistance === true)
                        ? 'text-activeText'
                        : 'text-textGrey'
                    } `}
                  >
                    {isMuscles.isAssistance}
                  </p>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="flex w-full flex-row  gap-x-2 overflow-x-scroll  items-center  no-scrollbar  ">
          {Object.keys(temp1).map((value, index) => {
            if (value !== 'MUSCLE_TYPE') {
              return (
                temp1[value] &&
                temp1[value].length > 0 &&
                temp1[value].map((item) => {
                  return (
                    <div className="flex flex-row items-center gap-1 justify-center   bg-statusBg px-5 py-1 rounded-[16px] hover:border-borderActive hover:border-[2px]">
                      <div>
                        <p className={`text-[14px] text-textGrey  ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>{`${t(
                          item
                        )}`}</p>

                        <img
                          src={Images.CloseCircle}
                          alt=""
                          height={10}
                          width={15}
                          className={`ml-1 mr-2 cursor-pointer lg:mr-0 lg:ml-0 xl:mr-0 xl:ml-0 `}
                          onClick={() => {
                            if (index === 0) {
                              const updatedItems = temp1['MUSCLES'].filter((i) => i !== item)
                              setTemp1({
                                ...temp1,
                                MUSCLES: updatedItems,
                              })
                            } else if (index === 1) {
                              const updatedItems = temp1['RESISTANCE'].filter((i) => i !== item)
                              setTemp1({
                                ...temp1,
                                RESISTANCE: updatedItems,
                              })
                            } else if (index === 2) {
                              const updatedItems = temp1['TRACKING'].filter((i) => i !== item)
                              setTemp1({
                                ...temp1,
                                TRACKING: updatedItems,
                              })
                            } else if (index === 3) {
                              const updatedItems = temp1['MACHINE'].filter((i) => i !== item)
                              setTemp1({
                                ...temp1,
                                MACHINE: updatedItems,
                              })
                            } else if (index === 4) {
                              const updatedItems = temp1['MAIN_EXERCISE'].filter((i) => i !== item)
                              setTemp1({
                                ...temp1,
                                MAIN_EXERCISE: updatedItems,
                              })
                            } else if (index === 6) {
                              const updatedItems = temp1['TAGS'].filter((i) => i !== item)
                              setTemp1({
                                ...temp1,
                                TAGS: updatedItems,
                              })
                            }
                          }}
                        />
                      </div>
                    </div>
                  )
                })
              )
            }
            return null
          })}
        </div>
      </div>
    </div>
  )
}
