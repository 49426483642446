import MachineDropDownWithSearch from './MachineDropDownWithSearch'

export default function MachineSelectionDropDown({ id, data, value, setValue, options, title, disable, t, dir }) {
  return (
    <div className="flex flex-col">
      <MachineDropDownWithSearch
        options={options}
        className="my-2"
        title={title}
        value={value?.name}
        setValue={(machine) => {
          const foundObject = options.find((obj) => obj.machineName === machine)
          setValue({ id: foundObject?.id, name: machine })
        }}
        disable={disable}
        t={t}
        dir={dir}
        isRequired={true}
        placeHolder={title}
      />
    </div>
  )
}
