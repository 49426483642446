import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Exercises from './exercises'
import Muscles from './muscles'
import Equipments from './equipments'
import { useNavigate } from 'react-router-dom'

const Menus = ['EXERCISES', 'MUSCLES', 'EQUIPMENT']

export default function Library() {
  const [active, setActive] = useState(Menus[0])
  const { t } = useTranslation()
  const navigation = useNavigate()

  return (
    <div className="flex flex-col w-full h-full py-4 px-8">
      <div className={`flex gap-1 pt-5 text-white ltr:font-primary-rg rtl:font-primary-he-rg}`}>
        <p
          className={`text-white text-md cursor-pointer hover:border-white hover:border-b-2 ltr:font-primary-md rtl:font-primary-he-md`}
          onClick={() => {
            navigation('/admin/dashboard')
          }}
        >
          {t('MAIN')}
        </p>
        <p>/</p> <p className={`text-breadCrumb ltr:font-primary-md rtl:font-primary-he-md} text-md`}>{t('LIBRARY')}</p>
      </div>
      <div className="flex w-full self-center flex-row mt-6 border-b-[1px] border-borderColor">
        {Menus?.map((item, index) => (
          <div
            className={`text-white text-[14px]
            ltr:font-primary-rg rtl:font-primary-he-rg
             text-center py-2 cursor-pointer px-8 ${item === active ? ' border-b-2 border-white z-10' : ''} `}
            onClick={() => setActive(item)}
          >
            {t(item)}
          </div>
        ))}
      </div>
      {active === 'EXERCISES' && <Exercises />}
      {active === 'MUSCLES' && <Muscles />}
      {active === 'EQUIPMENT' && <Equipments />}
    </div>
  )
}
