import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const initialState = {
  loader: false,
  programs: [],
  workouts: [],
};

export const traineeSlice = createSlice({
  name: "trainee",
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setProgram: (state, action) => {
      state.programs = action.payload;
    },
    setWorkouts: (state, action) => {
      state.workouts = action.payload;
    },
    setActiveProgram: (state, action) => {
      const { id } = action.payload;
      console.log("id", id);
      const programIndex = state.programs.findIndex(
        (program) => program.id === id
      );
      if (programIndex !== -1) {
        const newPrograms = [...state.programs];
        newPrograms[programIndex].status = "active";
        state.programs = newPrograms;
      }
    },
    setRemainingProgram: (state, action) => {
      const id = action.payload;
      const updatedPrograms = state.programs.filter(
        (program) => program.id !== id
      );
      state.programs = updatedPrograms;
    },
    setProgramsEmpty: (state, action) => {
      state.programs = [];
    },

    resetTrainee: (state, { payload }) => {
      return {
        ...state,
        loader: false,
        programs: [],
        workouts: [],
      };
    },
  },
});

export const useTraineeSelector = (state) => state.trainee;

export const traineeActions = traineeSlice.actions;
export const traineeReducer = traineeSlice.reducer;
