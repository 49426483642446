import PrimaryButton from 'components/elements/PrimaryButton'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

export default function MuscleFooter({ handleSubmit, id }) {
  const { direction } = useSelector((state) => state.language)
  const { t } = useTranslation()
  return (
    <div className="flex flex-row gap-4 py-2 px-4 justify-end xl:px-6 2xl:px-12 border-t-[1px] border-borderColor absolute bottom-0 w-full bg-sidebar">
      <PrimaryButton
        text={t('BACK')}
        type="submit"
        disabled={false}
        isIcon={false}
        onClick={handleSubmit}
        className="h-[44px] px-4 rounded-[12px] shadow-2xl"
        bgColor="bg-prussianBlue hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
        textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
      />
    </div>
  )
}
