import React from 'react'

function Avatar({ name, width, height, size }) {
  // Generate random background color
  const getRandomColor = () => {
    const colors = ['#65CCF3']
    return colors[Math.floor(Math.random() * colors.length)]
  }

  // Get initials from name
  const getInitials = (name) => {
    const names = name?.split(' ')
    return names
      ?.map((name) => name?.charAt(0))
      .join('')
      .toUpperCase()
  }

  // Generate avatar style
  const avatarStyle = {
    backgroundColor: getRandomColor(),
    width: width,
    height: height,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: size,
    fontWeight: 'bold',
    color: '#ffffff',
    padding: 2,
  }

  return <div style={avatarStyle}>{getInitials(name)}</div>
}

export default Avatar
