import React from 'react'

import { AllBodyMapContainer, FrontBackContainer } from './styles'
import { ReactComponent as SVGBodyMapFront } from '../../../assets/images/bodyMapSvg.svg'
import { ReactComponent as SVGBodyMapBack } from '../../../assets/images/bodyMapBackSvg.svg'

const BodyMuscleModel = ({ frontids, backIds, hoverable = true, h, w }) => {
  return (
    <AllBodyMapContainer>
      <FrontBackContainer $selectedValue={frontids} $hoverEnabled={hoverable} $h={h} $w={w}>
        <SVGBodyMapFront />
      </FrontBackContainer>
      <FrontBackContainer $selectedValue={backIds} $hoverEnabled={hoverable} $h={h} $w={w}>
        <SVGBodyMapBack />
      </FrontBackContainer>
    </AllBodyMapContainer>
  )
}

export { BodyMuscleModel }
