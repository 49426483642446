import axiosInstance from 'api'
import { Images } from 'assets'
import CustomToastify from 'components/compounds/Toast'

import { templateActions } from './templateReducers'

export const getAllTemplate =
  (id, traineeId, t, direction, filter = 'all') =>
  async (dispatch) => {
    try {
      dispatch(templateActions.setLoader(true))
      await axiosInstance.get(`/allTemplateWorkout`, { params: { query: filter, gymId: id, traineeId: traineeId } }).then((response) => {
        dispatch(templateActions.settemplatesList(response?.result.data))
        dispatch(templateActions.setLoader(false))
      })
    } catch (e) {
      dispatch(templateActions.setLoader(false))

      CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)

      return console.error(e.message)
    }
  }
export const createTemplate =
  (data, t, direction, id, action, traineeId, workoutId, filter = 'all') =>
  async (dispatch) => {
    try {
      dispatch(templateActions.setLoader(true))
      await axiosInstance.post(`/createWorkoutTemplate`, data).then((response) => {
        dispatch(getAllTemplate(id, traineeId, t, direction))
        CustomToastify(t('TEMPLATE_CREATED_SUCCESSFULLY'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
        action && action(workoutId)
      })
    } catch (e) {
      dispatch(templateActions.setLoader(false))
      if (e.code === 501) {
        CustomToastify(t('TEMPLATE_NAME_EXIST'), t('TEMPLATE_CHANGE_DESCRIPTION'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      } else {
        CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      }

      return console.error(e.message)
    }
  }
export const deleteTemplate = (id, t, direction) => async (dispatch) => {
  try {
    dispatch(templateActions.setLoader(true))
    await axiosInstance
      .delete(`removeTemplateWorkout?id=${id}`)
      .then((response) => {
        dispatch(templateActions.removeTemplate(id))
        CustomToastify(t('TEMPLATE_DELETED_SUCCESSFULLY'), '', '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
      })
      .finally(() => {
        dispatch(templateActions.setLoader(false))
      })
  } catch (e) {
    dispatch(templateActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}

export const updateTemplate = (data, t, direction, id, action) => async (dispatch) => {
  try {
    dispatch(templateActions.setLoader(true))
    await axiosInstance.put(`/updateTemplateWorkout`, data).then((response) => {
      dispatch(templateActions.setLoader(false))
      dispatch(getAllTemplate(id, t, direction))
      CustomToastify(t('EXERCISE_UPDATED'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
      action && action()
    })
  } catch (e) {
    dispatch(templateActions.setLoader(false))

    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
  }
}

export const getTemplateFilter = (id) => async (dispatch) => {
  try {

    axiosInstance.get(`templateFilter/${id}`).then((res) => {

      dispatch(templateActions.setTemplateFilter(res.result.result))
    }).catch((error)=>{

    })
  } catch (error) {
    console.log('error', error)
  }
}
