import { createSlice } from '@reduxjs/toolkit'

import { useSelector } from 'react-redux'

const initialState = {
  loader: false,
  exercises: [],
  currentExercise: null,
  mainExercises: [],
  tags: [],
  exerciseFilters: [],
  exercisesName: [],
}
export const exerciseSlice = createSlice({
  name: 'exercise',
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setAllExercise: (state, action) => {
      state.exercises = action.payload
    },
    setSingleExercise: (state, action) => {
      state.currentExercise = action.payload
    },
    setAllMainExercises: (state, action) => {
      state.mainExercises = action.payload
    },
    setAllTags: (state, action) => {
      state.tags = action.payload
    },
    setCurrentExercise: (state, action) => {
      state.currentExercise = action.payload
    },

    removeExercise: (state, action) => {
      return {
        ...state,
        exercises: state.exercises.filter((exercise) => exercise.id !== action.payload),
      }
    },
    setExerciseFilters: (state, action) => {
      state.exerciseFilters = action.payload
    },
    setAllExerciseName: (state, action) => {
      state.exercisesName = action.payload
    },
    resetExercise: (state, { payload }) => {
      return {
        ...state,
        loader: false,
        exercises: [],
        currentExercise: null,
        mainExercises: [],
        tags: [],
        exerciseFilters: [],
      }
    },
  },
})

export const useSaleSelector = () => useSelector((state) => state.exerciseReducer)

export const exerciseActions = exerciseSlice.actions
export const exerciseReducer = exerciseSlice.reducer
