import { Images } from 'assets'
import { useState } from 'react'

import { Loader } from 'components/compounds/Loader'
import CustomToastify from 'components/compounds/Toast'
import { constants } from 'constant'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
export default function ExerciseDropDown({ toggleDropdown, isOpen, direction, data, dragOver, selectedExercises, setSelectedExercises }) {
  const { t } = useTranslation()

  const [selectedItemIndexes, setselectedItemIndexes] = useState([])
  const { exercisesInfo, bands, exerciseLoader } = useSelector((state) => state.gymAdmin)

  const handleShowDetail = (id) => {
    const idFound = selectedItemIndexes.find((item) => item === id)
    if (idFound) {
      const filteredIndex = selectedItemIndexes.filter((item) => item !== id)
      setselectedItemIndexes(filteredIndex)
    } else {
      setselectedItemIndexes([...selectedItemIndexes, id])
    }
  }

  const handleDragStart = (event, item) => {
    event.dataTransfer.setData('text/plain', item.id)
  }
  const handleClick = (item) => {
    // eslint-disable-next-line
    const draggedItem = exercisesInfo.find((val) => val.id == item.id)

    if (draggedItem) {
      const isAlreadyAdded = selectedExercises.some((exercise) => exercise.id === draggedItem.id)
      if (!isAlreadyAdded) {
        let updatedDraggedItem
        if (draggedItem?.resistancename === constants.RUBBER_BAND) {
          updatedDraggedItem = {
            ...draggedItem,
            Resistance_value: bands?.length > 0 ? (bands[0]?.bandLevel != null ? bands[0]?.bandLevel : 0) : 0,
            Tracking_value: 10,
            set: 3,
            isband: true,
          }
        } else {
          updatedDraggedItem = {
            ...draggedItem,
            Resistance_value: 10,
            Tracking_value: 10,
            set: 3,
            isband: false,
            bandColor: null,
          }
        }
        setSelectedExercises([...selectedExercises, updatedDraggedItem])
      } else {
        CustomToastify(t('EXERCISED_ADDED'), '', '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      }
    }
  }

  return (
    <div className={` flex flex-col w-full ${isOpen ? 'h-[90%]' : 'h-14'}`}>
      <div
        className={`flex items-center justify-between px-4 py-2 text-sm ${isOpen ? 'bg-borderColor' : 'bg-textFieldColor'}  text-white  w-full h-14 ${
          isOpen ? 'rounded-t-[12px]' : 'rounded-[12px]'
        }`}
        onClick={toggleDropdown}
      >
        <div className="flex">
          <img
            src={direction === 'ltr' ? (isOpen ? Images.DROPDOWNICON : Images.backArrow) : isOpen ? Images.DROPDOWNICON : Images.DropDown_Left}
            alt=""
            height={20}
            width={20}
            className={`items-center cursor-pointer`}
            onClick={() => {}}
          />
          <span className={`mx-2 ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-[16px] text-white leading-5`}>
            {t('EXERCISES')}
          </span>
        </div>
        <div className="flex">
          <span className={`${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-[12px] font-normal text-white leading-5`}>
            {t('RESULT')} {data?.length}
          </span>
        </div>
      </div>
      {exerciseLoader ? (
        <div className="flex flex-col justify-center items-center h-[400px]">
          <Loader className="" size="w-6 h-6" fill="#003353" />
        </div>
      ) : (
        <>
          {isOpen && (
            <div id="left-container" className="w-full h-full  p-4 gap-2  overflow-y-auto bg-textFieldColor  rounded-b-[12px] pb-16">
              {data.map((item, index) => (
                <div key={index} id={'left-container' + index}>
                  <div
                    className="flex justify-between items-center py-2 px-4 h-12 bg-statusBg my-2 rounded-[12px] "
                    draggable={true}
                    onDragStart={(event) => handleDragStart(event, item)}
                  >
                    <div className="flex flex-row items-center gap-2">
                      <img
                        src={Images.Plus}
                        alt=""
                        height={10}
                        width={10}
                        className={`flex justify-end  items-center cursor-pointer`}
                        onClick={() => handleClick(item)}
                      />

                      <p
                        className={`${
                          direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                        } text-white leading-[18px] text-[14px] font-bold cursor-pointer`}
                      >
                        {item?.exerciseName}
                      </p>
                    </div>
                    <div className="flex flex-row items-center gap-2">
                      <div className="flex flex-row gap-1 ">
                        {selectedExercises.map((exercise, index) => {
                          if (exercise?.id === item?.id) {
                            return <div className={`flex  rounded-[50%] h-3 w-3 bg-green-400`} key={index}></div>
                          }
                          return null // Don't render if exercise is not in this workout
                        })}
                      </div>

                      <img
                        src={selectedItemIndexes.find((item) => item === item.id) ? Images.InfoColored : Images.InfoCircle}
                        alt=""
                        height={24}
                        width={24}
                        className={`items-center cursor-pointer`}
                        onClick={() => {
                          handleShowDetail(item.id)
                        }}
                      />
                    </div>
                  </div>
                  {selectedItemIndexes.includes(item.id) && (
                    <div className="flex flex-row bg-statusBg text-white p-4 mt-2 rounded-[12px] gap-2">
                      {/* Render the details for the selected item here */}
                      <div className="exerciseInfo_container">
                        <div className="flex h-full bg-[#35586f] px-2 pt-2 pb-6 rounded-[8px] w-44">
                          {/* ------------Exercise Info ---------------- */}
                          <div className="flex h-full flex-col w-full gap-2 ">
                            <div
                              className={`${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-white text-[14px] leading-[18px]`}
                            >
                              {t('EXERCISE_INFO')}
                            </div>
                            <div className="flex flex-row gap-2 ">
                              <p className={` exerciseInfo_itemHeading ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>
                                {t('TRACKING_Exercise')}
                              </p>
                              <p className={`exerciseInfo_item ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>
                                {t(item?.trackingname)}
                              </p>
                            </div>
                            <div className="flex flex-row gap-2">
                              <p className={`exerciseInfo_itemHeading ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>
                                {t('RESISTANCE_EXERCISE')}
                              </p>
                              <p className={` exerciseInfo_item ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>
                                {t(item?.resistancename)}
                              </p>
                            </div>
                            <div className="flex flex-row gap-2">
                              <p className={`exerciseInfo_itemHeading ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>
                                {t('MUSCLES')}
                              </p>
                              <div className="flex flex-row gap-2 flex-wrap">
                                {item?.Muscles?.map((val) => (
                                  <p className={`exerciseInfo_item ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>
                                    {val?.englishName}{' '}
                                  </p>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                        {item?.tags && item?.tags !== null && (
                          <div className="exerciseInfo_innerContainer h-full">
                            <div className="flex flex-row gap-2 ">
                              <p className={`exerciseInfo_itemHeading ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>
                                {t('TAGS')}
                              </p>

                              <p className={`exerciseInfo_item ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>
                                {item.tags.split(',').map((tag, index) => (
                                  <span className={`exerciseInfo_item ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`} key={index}>
                                    #{tag.trim()}{' '}
                                  </span>
                                ))}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="exerciseInfo_container">
                        <div className="exerciseInfo_innerContainer h-full">
                          <div className="flex h-full flex-col w-full gap-2 ">
                            <div className="flex flex-row gap-2 ">
                              <p className={`exerciseInfo_itemHeading ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>
                                {t('COMPLETED')}
                              </p>
                              <p className={`exerciseInfo_item ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>15</p>
                            </div>
                            <div className="flex flex-row gap-2 ">
                              <p className={`exerciseInfo_itemHeading ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>
                                {t('LAST_TIME')} -
                              </p>
                              <p className={`exerciseInfo_item ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>16.05.23</p>
                            </div>
                          </div>
                        </div>
                        {item?.otherVarients && item?.otherVarients != null && item?.otherVarients.length > 0 && (
                          <div className="exerciseInfo_innerContainer">
                            <div className="flex flex-col w-full gap-2 ">
                              <div
                                className={`${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-white text-[14px] leading-[18px]`}
                              >
                                {t('OTHER_VARIATIONS')}
                              </div>
                              {item?.otherVarients?.map((otherVarient) => (
                                <div className="exerciseInfo_itemContainer cursor-pointer" onClick={() => handleClick(otherVarient)}>
                                  <p className={`exerciseInfo_itemHeading ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>
                                    {otherVarient?.exerciseName}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  )
}
