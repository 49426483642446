import React from 'react'

export default function Skelton({ bgColor }) {
  return (
    <div role="status" class="w-full space-y-3  shadow animate-pulse ">
      <div class="flex w-full items-center justify-between gap-4">
        <div class={`w-[65px] h-[21px] bg-gray-400 rounded-[8px] dark:${bgColor}`}></div>
        <div className="flex w-full justify-between items-center ">
          <div class={`w-[185px] h-[21px] bg-gray-500 rounded-[8px] dark:${bgColor}`}></div>
          <div class={`w-[32px] h-[32px] bg-gray-500 rounded-[100%] dark:${bgColor}`}></div>
        </div>
      </div>
      <div class="flex w-full items-center justify-between">
        <div class={`w-full h-[98px] bg-gray-500 rounded-[8px] dark:${bgColor}`}></div>
      </div>
      <div class="flex w-full items-center justify-between">
        <div class={`w-full h-[52px] bg-gray-500 rounded-[8px] dark:${bgColor}`}></div>
      </div>
      <div class="flex w-full items-center justify-between">
        <div class={`w-full h-[52px] bg-gray-500 rounded-[8px] dark:${bgColor}`}></div>
      </div>
      <div class="flex w-full items-center justify-between">
        <div class={`w-full h-[52px] bg-gray-500 rounded-[8px] dark:${bgColor}`}></div>
      </div>
      <div class="flex w-full items-center justify-between">
        <div class={`w-full h-[52px] bg-gray-500 rounded-[8px] dark:${bgColor}`}></div>
      </div>
      <div class="flex w-full items-center justify-between">
        <div class={`w-full h-[52px] bg-gray-500 rounded-[8px] dark:${bgColor}`}></div>
      </div>
      <div class="flex w-full items-center justify-between">
        <div class={`w-full h-[52px] bg-gray-500 rounded-[8px] dark:${bgColor}`}></div>
      </div>
      <div class="flex w-full items-center justify-between">
        <div class={`w-full h-[52px] bg-gray-500 rounded-[8px] dark:${bgColor}`}></div>
      </div>
    </div>
  )
}
