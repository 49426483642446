import styled from 'styled-components'

export const AllBodyMapContainer = styled.div`
  padding: 10px;
  position: relative;

  background-color: ${(props) => (props.bgColor ? `${props.bgColor}` : '#323e44')};
  border-radius: 10px;
`

export const RotateIconContainer = styled.button`
  position: absolute;
  top: 0px;
  background: #142026;

  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
`

export const FrontBackContainer = styled.div`
  position: relative;
  perspective: 1000px;
  transition: transform 0.6s ease;
  transform-style: preserve-3d;
  width: 300px;
  height: 600px;

  width: ${(props) => (props.w ? `${props.w}px` : '200px')};
  height: ${(props) => (props.h ? `${props.h}px` : '200px')};

  transform: ${(props) => (props?.$isFront ? 'rotateY(0deg)' : 'rotateY(180deg)')};

  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    &:nth-child(2) {
      transform: rotateY(180deg);
    }

    g {
      cursor: ${(props) => (props?.$hoverEnabled ? 'pointer' : '')};

      &.hoverable-muscle {
        /* path {
          fill: #ffdad6;
        } */
      }

      &.body-map__model,
      &.body-map__model:hover {
        path {
          fill: #000;
        }
      }

      ${(props) =>
        props.$hoverEnabled &&
        `
          &:hover {
            path {
              fill: ${props.fillColor || '#fe5b7f'};
            }
          }
      `}

      ${(props) =>
        Array.isArray(props?.$selectedValue)
          ? props?.$selectedValue.map(
              (value) => `
                &[id='${value}'] {
                  path {
                    fill: ${props.fillColor || '#fe5b7f'};
                  }
                }
              `
            )
          : `&[id='${props?.$selectedValue}'] { path {
          fill: ${props.fillColor || '#fe5b7f'};
        } }`}
    }
  }
`
