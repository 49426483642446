import { createSlice } from '@reduxjs/toolkit'

import { useSelector } from 'react-redux'

const initialState = {
  loader: false,
  muscles: [],
  currentMuscle: null,
  bodyPartList: [],
}
export const muscleSlice = createSlice({
  name: 'muscle',
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setAllMuscles: (state, action) => {
      state.muscles = action.payload
    },
    setSingleMuscles: (state, action) => {
      state.currentMuscle = action.payload
    },
    setBodyPartList: (state, action) => {
      state.bodyPartList = action.payload
    },
    removeMuscle: (state, action) => {
      return {
        ...state,
        muscles: state.muscles.filter((muscle) => muscle.id !== action.payload),
      }
    },
    resetMuscle: (state, { payload }) => {
      return {
        ...state,
        loader: false,
        muscles: [],
        currentMuscle: null,
        bodyPartList: [],
      }
    },
  },
})

export const useSaleSelector = () => useSelector((state) => state.muscleReducer)

export const muscleActions = muscleSlice.actions
export const muscleReducer = muscleSlice.reducer
