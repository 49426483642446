import { useEffect, useRef, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const LabelInputFocused = ({
  name,
  control,
  error,
  errorMessage,
  required,
  fullWidth,
  className,
  multiline,
  rows,
  onChange,
  disabled,
  label,
  type,
  icon,
  placeholderValue,
  isTypePassword,
  setPasswordShown,
  passwordShown,
  dir,
  isRequired,
}) => {
  const { t } = useTranslation()

  const passwordRef = useRef(null)
  const togglePassword = () => {
    setIsInputFocused(true)

    setPasswordShown(!passwordShown)
    if (passwordRef.current) {
      setIsInputFocused(true)
      passwordRef.current.focus()
    }
  }
  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur()

    e.stopPropagation()
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  const [isInputFocused, setIsInputFocused] = useState(false)

  const handleInputFocus = () => {
    setIsInputFocused(true)
  }

  const handleInputBlur = () => {
    setIsInputFocused(false)
  }
  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (passwordRef.current && !passwordRef.current.contains(event.target)) {
        setIsInputFocused(false)
      }
    }

    document.addEventListener('click', handleDocumentClick)

    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])
  return (
    <>
      <div
        className={`flex flex-col  h-[56px] w-full my-[0.40rem] rounded-[12px] ${isInputFocused && !error ? 'border-2 border-[#006684]' : ''} ${
          isInputFocused && error ? 'border-2 border-error' : ''
        }  `}
      >
        <label
          className={`flex  font-primary-rg rounded-t-[12px]  text-[12px] bg-textFieldColor  px-4 ${
            dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
          }  ${isInputFocused && !error ? 'text-[#65B1F4] pt-1' : ''} ${isInputFocused && error ? 'text-error pt-[1px]' : ''}   `}
        >
          {isInputFocused ? (
            <>
              {label}
              {isRequired && <span className="text-[red] px-1">*</span>}
            </>
          ) : (
            <></>
          )}
        </label>

        <div
          className={`flex flex-row w-full h-full  items-center justify-between 
          ${isInputFocused ? 'rounded-b-[12px]' : 'rounded-[12px]'}
           bg-textFieldColor overflow-hidden`}
        >
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <input
                {...field}
                onChange={(event) => {
                  onChange ? onChange(event, field) : field.onChange(event)
                }}
                value={field?.value?.label ? field.value.label : field.value}
                className={`${className} w-full bg-textFieldColor text-white px-4 text-[14px] ${
                  dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                }  `}
                label={label}
                type={type ? type : 'text'}
                placeholder={placeholderValue && !isInputFocused ? placeholderValue : ''}
                required={required}
                fullWidth={fullWidth}
                disabled={disabled}
                onWheel={numberInputOnWheelPreventChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                ref={type === 'password' ? passwordRef : null}
              />
            )}
          />
          {icon && (
            <>
              <img src={icon} alt="" height={40} width={45} className={`px-3 items-center cursor-pointer z-10 `} onClick={togglePassword} />
            </>
          )}
        </div>
      </div>
      {error && (
        <p
          className={`form-errors ${dir === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} flex flex-col px-1 ${
            dir === 'rtl' ? 'text-right' : 'text-left'
          } `}
        >
          {t(errorMessage)}
        </p>
      )}
    </>
  )
}

export { LabelInputFocused }
