import { Images } from 'assets'
import { ProfileAvatar } from 'components/PlayBoard/ProfileAvatar'
import { BodyMuscleModel } from 'components/PlayBoard/components'
import DeleteModal from 'components/compounds/DeleteModal'
import { Loader } from 'components/compounds/Loader'
import OutlineButton from 'components/elements/OutlineButton'
import PrimaryButton from 'components/elements/PrimaryButton'
import { MuscleSummaryTemplate } from 'components/templates/components/MuscleSummaryTemplate'
import TemplatesLibraryListHeader from 'components/templates/templatesLibraryListHeader'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { deleteTemplate } from 'store/templateSlice/templateActions'
import { formatDateOnlyMonth } from 'utils'
export default function Templates({ active }) {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState()
  const [activeTemplate, setActiveTemplate] = useState([])
  const [deleteModal, setDeleteModal] = useState(null)
  const { direction } = useSelector((state) => state.language)
  const { loader, templatesList } = useSelector((state) => state?.template)
  const navigate = useNavigate()

  const { t } = useTranslation()
  const toggleTemplate = (id) => {
    if (activeTemplate.includes(id)) {
      setActiveTemplate(activeTemplate.filter((item) => item !== id))
    } else {
      setActiveTemplate([...activeTemplate, id])
    }
  }

  const getMuscleIds = (muscle) => {
    const muscleIds = muscle.map((item) => item.muscle_model_id)

    return muscleIds
  }

  const getSortedMuscle = (muscleSummary) => {
    const sortedMuscles = [...muscleSummary].sort((a, b) => parseFloat(b.percentage) - parseFloat(a.percentage))

    return sortedMuscles
  }
  const [screenSize, setScreenSize] = useState({ width: window.innerWidth, height: window.innerHeight })

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight })
    }

    window.addEventListener('resize', handleResize)

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const getDimensions = (item) => {
    const isLargeScreenHeight = screenSize.height > 700 // Adjust the breakpoint as needed
    const isLargeScreenWidth = screenSize.width > 1300

    return {
      h: isLargeScreenHeight ? 400 : 300,
      w: isLargeScreenWidth ? 400 : 250,
    }
  }

  return (
    <>
      <div className="h-full w-full overflow-hidden">
        <TemplatesLibraryListHeader setSearchText={setSearchText} searchText={searchText} active={active} />
        {loader ? (
          <div className="w-[100%] h-[calc(100vh-150px)]   ">
            <Loader className="" size="w-10 h-10" fill="#003353" />
          </div>
        ) : (
          <div className=" h-full w-full overflow-y-scroll px-8 py-3  ">
            {templatesList && templatesList?.length > 0 ? (
              <div className="flex flex-col gap-y-4 w-full h-full overflow-y-scroll has-scrollbar">
                {/* eslint-disable-next-line */}
                {templatesList?.map((item) => {
                  if (item?.percentage?.length > 0) {
                    return (
                      <div className={`flex flex-col  bg-secondaryBackground rounded-[15px] gap-4 p-4  `}>
                        <div className="w-full h-6 flex justify-around">
                          <div
                            className="w-full flex items-center space-x-2 rtl:space-x-reverse cursor-pointer relative"
                            onClick={() => toggleTemplate(item?.id)}
                          >
                            <img
                              src={activeTemplate?.includes(item?.id) ? Images.WorkoutSidebarLeftArrow : Images.WorkoutSidebarRightArrow}
                              alt="profile"
                              className={`w-2 h-3 rounded-full bg-cover text-center shadow-xl ${
                                activeTemplate?.includes(item?.id) ? (direction === 'rtl' ? '-rotate-90' : '-rotate-90') : ''
                              }`}
                            />
                            <h6
                              className={`text-sm text-white  leading-4 font-medium ${
                                direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                              }`}
                            >
                              {item?.name}
                            </h6>
                          </div>
                          <div
                            className="w-full flex items-center space-x-2 rtl:space-x-reverse cursor-pointer relative  justify-end"
                            onClick={() => toggleTemplate(item?.id)}
                          >
                            <ProfileAvatar image={item?.avatar} name={item?.userName} />
                            <h6
                              className={`text-sm text-white  leading-4 font-medium ${
                                direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                              }`}
                            >
                              {item?.userName} ,
                            </h6>
                            <h6
                              className={`text-sm text-white  leading-4 font-medium ${
                                direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                              }`}
                            >
                              {formatDateOnlyMonth(t, item?.createdAt)}
                            </h6>
                          </div>
                        </div>
                        <MuscleSummaryTemplate muscleSummary={getSortedMuscle(item.percentage)} t={t} />
                        {activeTemplate?.includes(item?.id) && (
                          <div className="flex w-full justify-between gap-4 bg-borderColor rounded-lg p-6">
                            <div className="flex w-1/2 justify-center items-center">
                              <BodyMuscleModel
                                frontids={getMuscleIds(item?.percentage)}
                                backIds={getMuscleIds(item?.percentage)}
                                hoverable={false}
                                {...getDimensions(item)}
                              />
                            </div>
                            <div className="flex flex-col w-1/2  gap-y-2 relative">
                              <div className="flex gap-x-2 items-center">
                                <p className="text-white ltr:font-primary-rg rtl:font-primary-he-rg text-xl ">{t('EXERCISES')}</p>
                                <p className="text-white ltr:font-primary-rg rtl:font-primary-he-rg text-base ">({item?.exercises?.length})</p>
                              </div>
                              <div className="flex flex-col  h-full ">
                                <div className="flex flex-col  overflow-y-scroll gap-y-1 ">
                                  {item?.exercises?.map((exercise) => (
                                    <p className="text-white ltr:font-primary-rg rtl:font-primary-he-rg text-base">{exercise?.exerciseName}</p>
                                  ))}
                                </div>
                              </div>
                              <div className="   w-full flex  justify-between">
                                <OutlineButton
                                  text={t('EDIT')}
                                  type="submit"
                                  disabled={false}
                                  isIcon={false}
                                  onClick={() => {
                                    navigate({
                                      pathname: '/admin/createTemplate',
                                      search: `?id=${item?.id}&active=${active}`,
                                    })
                                  }}
                                  className="w-[70px] h-[44px] rounded-[14px] border-borderActive border-[1.5px] shadow-2xl "
                                  bgColor="hover:bg-darkGray focus:bg-lightGray active:bg-lightGray"
                                  textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
                                />
                                <PrimaryButton
                                  text={t('DELETE')}
                                  type="submit"
                                  disabled={false}
                                  isIcon={false}
                                  onClick={() => setDeleteModal(item?.id)}
                                  className="h-[44px] px-4 rounded-[12px] shadow-2xl"
                                  bgColor=" hover:bg-[red] focus:bg-[red] active:bg-[red] "
                                  textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )
                  }
                })}
              </div>
            ) : (
              <div className="h-[50%] justify-center items-center ">
                <p className={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-[24px] text-center `}>
                  {t('NO_DATA')}
                </p>
              </div>
            )}
          </div>
        )}
      </div>
      <DeleteModal
        setOpenModal={setDeleteModal}
        openModal={deleteModal}
        t={t}
        direction={direction}
        des={t('DELETE_TEMPLATE_DESCRIPTION')}
        title={t('DELETE_TEMPLATE')}
        handleSubmit={() => {
          dispatch(deleteTemplate(deleteModal, t, direction))
          setDeleteModal(null)
        }}
        showPass={false}
      />
    </>
  )
}
