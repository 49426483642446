import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { emailVerify } from 'store/authSlice/authActions'
const Verification = () => {
  const dispatch = useDispatch()
  const { direction } = useSelector((state) => state.language)
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()

  const action = () => {
    navigate(`/login`)
  }
  const onVerify = () => {
    let verificationId = {
      id,
    }
    dispatch(emailVerify(verificationId, direction, t, action))
  }
  return (
    <div className="flex items-center justify-center min-h-screen p-5 bg-sidebar min-w-screen text-gray-300 ">
      <div className="max-w-xl p-8 text-center text-gray-800 bg-primary shadow-xl lg:max-w-3xl rounded-3xl lg:p-12">
        <h3 className="text-2xl text-gray-300">{t('THANKS_SIGNIN')}</h3>
        <div className="flex justify-center">
          <svg xmlns="http://www.w3.org/2000/svg" className="w-24 h-24 text-green-400" fill="none" viewBox="0 0 24 24" stroke="#003353">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
              d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76"
            />
          </svg>
        </div>

        <p className="text-gray-300 py-2">{t('VERIFIED')}</p>
        <div className="mt-4">
          <button className="px-2 py-2 bg-prussianBlue rounded font-primary-rg text-gray-300" onClick={onVerify}>
            {t('CLICK_TO_VERIFY_EMAIL')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Verification
