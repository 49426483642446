import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { EventContainer } from './styles'

import 'react-calendar/dist/Calendar.css'
import 'react-date-picker/dist/DatePicker.css'
import EventEditor from '../EventEditor'
import { useSelector } from 'react-redux'

/**
 * This component represents UI for event
 * Customizes default UI of event provided by fullcalendar
 */
function CalendarEvent({
  event,
  setEvents,
  isEventOpen,
  refreshWorkouts,
  setIsEventOpen,
  showUpdateInfoBox,
  setShowUpdateInfoBox,
  toggleInfoBox,
  handleDelete,
  setCurrEvent,
  handleUpdateEvent,
  currentView,
  divheight,
  customView,
}) {
  const { direction } = useSelector((state) => state.language)
  const { t } = useTranslation()

  /**
   * Click handler for delete button
   * Confirms user for deletion of event, removes on confirmation
   */

  const EventView = customView
  /**
   * This function toggles info box
   */

  /**
   * calculate if event has valid info or not
   * TODO: Add more checks here
   */
  const eventHasValidInfo = useMemo(() => {
    return Boolean(event.title)
  }, [event])

  /**
   * When event does not have valid info, show info box by default
   */
  useEffect(() => {
    if (!eventHasValidInfo) {
      setShowUpdateInfoBox(true)
      setIsEventOpen(true)
    }
    // eslint-disable-next-line
  }, [])

  /**
   * This function handles cancel button click
   * When event does not have valid info, it deletes the event
   * Otherwise it closes the info box
   */

  // useEffect(() => {
  //   if (showUpdateInfoBox) {
  //     const boxElement = document.querySelector(`#info-box-container-${event.id}`)
  //     const calendarElement = document.querySelector('.fc')

  //     if (boxElement && calendarElement) {
  //       const boxRect = boxElement.getBoundingClientRect()
  //       const calendarRect = calendarElement.getBoundingClientRect()

  //       if (boxRect.right + 20 > calendarRect.right) {
  //         boxElement.style.right = '105%'
  //       }

  //       if (boxRect.bottom + 20 > calendarRect.bottom) {
  //         const bottomDiffPercent = calendarRect.bottom - boxRect.bottom

  //         boxElement.style.transform = bottomDiffPercent > 100 || bottomDiffPercent < -100 ? `translateY(-90%)` : `translateY(${bottomDiffPercent}%)`
  //       }
  //     }
  //   }
  // }, [showUpdateInfoBox, event.id])

  return (
    <>
      <EventContainer onClick={() => toggleInfoBox(event)} id={`event-info-container-${event.id}`}>
        <div className="relative event-smal">
          {customView ? (
            <EventView event={event?.extendedProps} title={event?.extendedProps?.Trainee?.User?.firstName} />
          ) : (
            <p className={`${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>{event.title || t('noTitle')}</p>
          )}
        </div>
      </EventContainer>
    </>
  )
}

export default CalendarEvent
