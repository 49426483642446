import { Images } from 'assets'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import {
  getCommentsSelectedExercise,
  getProgramSelectedExerciseAllHistory,
  getProgramSelectedExerciseDetailWeeklyView,
  getProgramStatisticsWeeklyWorkouts,
} from 'store/programSlice/programActions'
import WeeklyWorkoutCard from './components/WeeklyWorkoutCard'
import { Loader } from 'components/compounds/Loader'

export default function WeeklyWorkouts({ setShow, setShowComments, setLoader }) {
  const { t } = useTranslation()
  const { weeklyWorkoutLoader, programStatisticsFilters, programWeeklyStatistics, selectedExerciseDetailWeeklyView } = useSelector(
    (state) => state.program
  )
  const [searchParams] = useSearchParams()
  const { programId } = Object.fromEntries(searchParams)
  const { direction } = useSelector((state) => state.language)
  const query = useParams()
  const dispatch = useDispatch()

  const [availableWeeks, setAvailableWeeks] = useState([])
  const [programfilterOptions, setProgramfilterOptions] = useState([])
  const [selectedWeek, setSelectedWeek] = useState(null)
  const [selectedExercise, setSelectedExercise] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (programStatisticsFilters && programStatisticsFilters?.ValidTimeOptions?.length > 0) {
      assignInitialFilters()
    }
  }, [programStatisticsFilters])
  const assignInitialFilters = () => {
    let weeks = programStatisticsFilters?.ValidTimeOptions?.map((item) => {
      return {
        id: item + 1,
        label: `Week-${item + 1}`,
        value: item,
      }
    })

    setAvailableWeeks(weeks)
    const keyOneData = programStatisticsFilters.ValidWorkoutPatterns[programStatisticsFilters?.currentWeek]
    const modifiedData = keyOneData.map((item) => {
      return { ...item, label: item.workoutName }
    })
    setProgramfilterOptions(modifiedData)
    const currWeek = weeks.find((week) => week.value === programStatisticsFilters?.currentWeek)
    setSelectedWeek(currWeek)
  }
  useEffect(() => {
    if (selectedWeek != null) {
      const data = {
        currentWeek: selectedWeek.value,
        programId: programId,
        traineeId: query?.id,
      }
      dispatch(getProgramStatisticsWeeklyWorkouts(data, t, direction))
    }
  }, [selectedWeek])
  const changeWeek = async (command) => {
    if (command === 'Next' && selectedWeek?.id !== availableWeeks?.length) {
      const currWeek = availableWeeks[selectedWeek?.value + 1]
      setSelectedWeek(currWeek)
    } else if (command === 'Prev' && selectedWeek?.id !== 1) {
      const currWeek = availableWeeks[selectedWeek?.value - 1]
      setSelectedWeek(currWeek)
    }
  }
  const handleSelectedExercise = (val) => {
    setSelectedExercise(val)
    if (val != null) {
      setLoading(true)

      const data = {
        currentWeek: selectedWeek.value,
        programId: programId,
        traineeId: query?.id,
        exerciseId: val?.exercise_id,
        workoutId: val?.workout_id,
      }

      dispatch(
        getProgramSelectedExerciseDetailWeeklyView(data, t, direction, () => {
          setLoading(false)
        })
      )
    }
  }
  const action = () => {
    setLoader(false)
  }
  const handleViewAllHistory = (value) => {
    const data = {
      exerciseName: value?.exerciseName,
      exerciseId: value?.exercise_id,
      traineeId: query?.id,
      programId: programId,
    }
    setShow(data)

    dispatch(getProgramSelectedExerciseAllHistory(data, t, direction))
  }
  const handleViewAllComments = (value) => {
    const data = {
      exerciseName: value?.exerciseName,
      exerciseId: value?.exercise_id,
      traineeId: query?.id,
      programId: programId,
    }
    setShowComments(data)
    setLoader(true)
    dispatch(getCommentsSelectedExercise(data, t, direction, action))
  }
  return (
    <div className="flex w-full  gap-x-[15px] flex-col overflow-hidden ">
      {/*------------------- header --------------------- */}
      <div className="w-full h-10  flex justify-between">
        <p className="text-textLightGray ltr:font-primary-md rtl:font-primary-he-md text-xl">{t('WEEKLY_WORKOUTS')}</p>
        <div className="  flex gap-x-3">
          <p className="text-textLightGray ltr:font-primary-md rtl:font-primary-he-md text-xl">{selectedWeek?.label}</p>
          <div
            className={`${
              selectedWeek?.id === 1 ? ' bg-extralightGray cursor-not-allowed ' : 'bg-activebtn'
            }  w-6 h-6 rounded-full items-center flex justify-center ltr:pr-[3px] rtl:pl-[3px]`}
            onClick={() => changeWeek('Prev')}
          >
            <img
              src={Images.ActiveWeek}
              alt=""
              height={9}
              width={9}
              className={`items-center ${selectedWeek?.id === 1 ? ' cursor-not-allowed ' : ' cursor-pointer'} rtl:rotate-180`}
            />
          </div>

          <div
            className={`${
              selectedWeek?.id === availableWeeks?.length ? ' bg-extralightGray cursor-not-allowed' : 'bg-activebtn cursor-pointer'
            } w-6 h-6 rounded-full items-center flex justify-center ltr:pl-[3px] rtl:pr-[3px] `}
            onClick={() => changeWeek('Next')}
          >
            <img
              src={Images.ActiveWeek}
              alt=""
              height={9}
              width={9}
              className={`items-center ${selectedWeek?.id === availableWeeks?.length ? ' cursor-not-allowed' : ' cursor-pointer'} ltr:rotate-180`}
            />
          </div>
        </div>
      </div>
      {/*------------------- body workouts --------------------- */}
      {weeklyWorkoutLoader ? (
        <div className="flex w-full h-[600px] justify-center items-center ">
          <Loader className="" size="w-10 h-10" fill="#003353" />
        </div>
      ) : (
        <div className="flex  overflow-x-auto gap-2 overflow-y-hidden">
          {programWeeklyStatistics?.length > 0 &&
            programWeeklyStatistics.map((workout) => (
              <WeeklyWorkoutCard
                workout={workout}
                t={t}
                setSelectedExercise={handleSelectedExercise}
                selectedExercise={selectedExercise}
                loading={loading}
                exerciseDetail={selectedExerciseDetailWeeklyView}
                handleShow={handleViewAllHistory}
                handleShowComment={handleViewAllComments}
              />
            ))}
        </div>
      )}
    </div>
  )
}
