import React from 'react'

const ProfileAvatar = ({ name, image, size, color, className, style }) => {
  let sizeClasses = ''

  if (size === 'large') {
    sizeClasses = 'h-10 w-10'
  } else if (size === 'small') {
    sizeClasses = 'h-6 w-6'
  } else if (size === 'xsmall') {
    sizeClasses = 'h-5 w-5'
  } else if (size === 'xlarge') {
    sizeClasses = 'h-11 w-11'
  } else if (size === 'xmlarge') {
    sizeClasses = 'h-12 w-12'
  } else if (size === 'xxlarge') {
    sizeClasses = 'h-16 w-16'
  } else if (size === 'xxxlarge') {
    sizeClasses = 'h-20 w-20'
  } else if (size === 'branding') {
    sizeClasses = 'h-[150px] w-[150px]'
  } else {
    sizeClasses = 'h-8 w-8'
  }

  const getProfileName = () => {
    if (!name) return ''
    const words = name.split(' ')
    const characterNames = words.map((word) => word.slice(0, 1))
    const combination = characterNames.join('')
    if (words.length === 1) {
      return combination.slice(0, 2).toUpperCase()
    } else {
      return combination.slice(0, 2).toUpperCase()
    }
  }
  if (!image) {
    return (
      <div
        className={`rounded-full flex justify-center items-center text-xs ${sizeClasses} ${className}`}
        style={{ backgroundColor: color ? color : '#E5A772', ...style }}
      >
        {getProfileName()}
      </div>
    )
  }
  return (
    <img src={image} alt="profile" className={`rounded-full object-cover text-center shadow-2xl ${sizeClasses} ${className}`} style={{ ...style }} />
  )
}

export { ProfileAvatar }
