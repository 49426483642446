import { Images } from 'assets'
import CustomToastify from 'components/compounds/Toast'
import OutlineButton from 'components/elements/OutlineButton'
import PrimaryButton from 'components/elements/PrimaryButton'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getTraineeById, getbandByGymId } from 'store/gymAdminSlice/gymAdminActions'

import { constants } from 'constant'

export default function TemplateSection({ dragOver, templateName, setTemplateName, selectedExercises, setSelectedExercises, handleSubmit }) {
  const { id } = useParams()
  const { t } = useTranslation()
  const { direction } = useSelector((state) => state.language)
  const { exercisesInfo, bands } = useSelector((state) => state.gymAdmin)

  const { customerDetail } = useSelector((state) => state.user)
  const { loader } = useSelector((state) => state.template)

  const [isDrag, setIsDrag] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    const handleDragEnd = () => {
      const draggingElements = document.querySelectorAll('.dragging')
      draggingElements.forEach((element) => element.classList.remove('dragging'))
    }

    document.addEventListener('dragend', handleDragEnd)

    return () => {
      document.removeEventListener('dragend', handleDragEnd)
    }
  }, [])

  useEffect(() => {
    if (id) {
      dispatch(getTraineeById(id, t, direction))
      dispatch(getbandByGymId(customerDetail?.id, t, direction))
    }
    // eslint-disable-next-line
  }, [id])

  const checkDropEvent = (event, dropIndexID) => {
    if (isDrag) return

    if (!event.target.id || event.target.id === 'right-container') {
      handleDrop(event)
      return
    }
  }
  //Drop from exercise section to program section
  const handleDrop = (event, isResort = false) => {
    event.preventDefault()
    const itemId = event.dataTransfer.getData('text/plain')
    // eslint-disable-next-line
    const draggedItem = exercisesInfo.find((item) => item.id == itemId)
    if (draggedItem) {
      const isAlreadyAdded = selectedExercises.some((exercise) => exercise.id === draggedItem.id)
      if (!isAlreadyAdded) {
        let updatedDraggedItem
        if (draggedItem?.resistancename === constants.RUBBER_BAND) {
          updatedDraggedItem = {
            ...draggedItem,
            Resistance_value: bands?.length > 0 ? (bands[0]?.bandLevel != null ? bands[0]?.bandLevel : 0) : 0,
            Tracking_value: 10,
            set: 3,
            isband: true,
          }
        } else {
          updatedDraggedItem = {
            ...draggedItem,
            Resistance_value: 10,
            Tracking_value: 10,
            set: 3,
            isband: false,
            bandColor: null,
          }
        }

        if (!isResort) {
          setSelectedExercises([...selectedExercises, updatedDraggedItem])
          return
        }
      } else {
        CustomToastify(t('EXERCISED_ADDED'), '', '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      }
    }
  }

  const handleExerciseDragStart = (event, exerciseIndex) => {
    setIsDrag(true)
    event.dataTransfer.effectAllowed = 'move'
    event.dataTransfer.setData('text/plain', JSON.stringify({ exerciseIndex }))
    event.currentTarget.classList.add('dragging')
  }

  const handleExerciseDragOver = (event) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }

  const handleExerciseDrop = (event, destinationIndex) => {
    if (!isDrag) return
    setIsDrag(false)
    event.currentTarget.classList.remove('dragging')
    const source = JSON.parse(event.dataTransfer.getData('text/plain'))

    const { exerciseIndex: sourceIndex } = source

    const sourceExercise = selectedExercises[sourceIndex]

    selectedExercises.splice(sourceIndex, 1) // Remove exercise from source position

    selectedExercises.splice(destinationIndex, 0, sourceExercise) // Insert exercise at destination position
  }

  const removeExercise = (exerciseID) => {
    const updatedExercises = selectedExercises.filter((exercise) => exercise?.id !== exerciseID)

    setSelectedExercises(updatedExercises)
  }

  const handleResetExercises = () => {
    setSelectedExercises([])
  }

  const updateData = (value, exerciseId, field) => {
    const updatedExercises = selectedExercises.map((exercise) => {
      if (exercise.id === exerciseId) {
        return { ...exercise, [field]: value }
      }
      return exercise
    })

    setSelectedExercises(updatedExercises)
  }

  return (
    <div className="flex h-full flex-col w-[55%] bg-secondaryBackground rounded-[12px] py-[16px] px-[24px] relative">
      {/* ---------------------- Header -------------------------  */}
      <div className="flex w-full h-7 flex-row justify-center items-center  gap-x-3">
        <div className=" w-24 font-primary-rg text-textLightGray text-[20px]  text-center border-b-[1px] border-[grey] ">
          <input
            className={`bg-secondaryBackground w-24 text-white text-base ltr:font-primary-rg rtl:font-primary-he-rg`}
            type="text"
            onChange={(e) => {
              setTemplateName(e.target.value)
            }}
            value={templateName}
            maxLength={20}
          />
        </div>
        <img src={Images.DROPDOWNICON} alt="" height={10} width={15} className={`items-center cursor-pointer`} />
      </div>

      <div className="flex flex-col  w-full h-[85%] overflow-y-scroll" onDragOver={dragOver} onDrop={(event) => checkDropEvent(event)}>
        <div className="flex flex-col gap-2  h-full overflow-y-scroll" id="right-container">
          {selectedExercises?.length > 0 ? (
            <>
              {selectedExercises?.map((item, index) => {
                return (
                  <div
                    className="flex h-14 w-full justify-between items-center py-2 px-2 my-2 rounded-[12px] cursor-pointer "
                    key={index}
                    id={'right-container' + index}
                    draggable
                    onDragStart={(event) => handleExerciseDragStart(event, index)}
                    onDragOver={(event) => handleExerciseDragOver(event)} //item
                    onDrop={(event) => {
                      if (isDrag) {
                        handleExerciseDrop(event, index)
                      }
                    }}
                  >
                    <div className="flex gap-1 items-center">
                      <img src={Images.Dragging} alt="" height={2} width={24} className="items-center cursor-pointer" />
                      <p
                        className={`ltr:font-primary-rg rtl:font-primary-he-rg
                            } text-white leading-[18px] text-[14px] font-normal  w-44 px-1`}
                      >
                        {item?.exerciseName}
                      </p>
                    </div>
                    <div className="flex flex-row gap-[10px] justify-center items-center">
                      <div
                        className={`flex flex-col justify-center items-center w-[76px] h-14 bg-textFieldColor rounded-[12px]
                        ${item?.Resistance_value !== null && item?.Resistance_value.length !== 0 ? 'border-0 ' : 'border-[2px] border-error ]'}`}
                      >
                        <p
                          className={`${
                            direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                          } font-normal text-[12px] text-textLightGrey leading-4 px-1  ${
                            item?.Resistance_value !== null && item?.Resistance_value.length !== 0 ? ' text-white' : ' text-error'
                          }`}
                        >
                          {item?.resistancename}
                        </p>
                        <p
                          className={`${
                            direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                          } font-normal text-[16px] text-textLightGrey leading-5`}
                        >
                          <input
                            className={`bg-textFieldColor text-white w-[70px] text-center ${
                              direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                            }`}
                            type="number"
                            step="any"
                            onChange={(e) => {
                              updateData(e.target.value, item.id, 'Resistance_value')
                            }}
                            value={item?.Resistance_value}
                            disabled={false}
                          />
                        </p>
                      </div>

                      <div
                        className={`flex flex-col justify-center items-center w-[76px] h-14 bg-textFieldColor rounded-[12px] 
                      
                      ${item?.Tracking_value !== null && item?.Tracking_value.length !== 0 ? 'border-0' : 'border-[2px] border-error'}`}
                      >
                        <p
                          className={`${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-[12px] text-textLightGrey leading-4 ${
                            item?.Tracking_value !== null && item?.Tracking_value.length !== 0 ? ' text-white' : ' text-error'
                          }`}
                        >
                          {item?.trackingname}
                        </p>
                        <p className={`${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-[16px] text-textLightGrey leading-5`}>
                          <input
                            className="bg-textFieldColor text-white w-[70px] text-center"
                            type="number"
                            step="any"
                            onChange={(e) => {
                              updateData(e.target.value, item.id, 'Tracking_value')
                            }}
                            value={item?.Tracking_value}
                            disabled={false}
                          />
                        </p>
                      </div>
                      <div
                        className={`flex flex-col justify-center items-center w-[76px] h-14 bg-textFieldColor rounded-[12px] 
                       ${item?.set !== null && item?.set.length !== 0 ? 'border-0' : 'border-[2px] border-error'}`}
                      >
                        <p
                          className={`${
                            direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                          } font-normal text-[12px] text-textLightGrey leading-4 ${
                            item?.set !== null && item?.set.length !== 0 ? ' text-white' : ' text-error'
                          }`}
                        >
                          {t('SETS')}
                        </p>
                        <p
                          className={`${
                            direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                          } font-normal text-[16px]  text-textLightGrey leading-5`}
                        >
                          <input
                            className={`w-[70px] bg-textFieldColor text-white text-center ${
                              direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                            }`}
                            type="number"
                            onChange={(e) => {
                              if (e.target.value.length <= 2) {
                                updateData(e.target.value, item.id, 'set')
                              }
                            }}
                            value={item?.set}
                            disabled={false}
                            maxLength={2}
                          />
                        </p>
                      </div>

                      <div>
                        <img
                          src={Images.Remove}
                          alt=""
                          height={2}
                          width={24}
                          className="items-center cursor-pointer"
                          onClick={() => {
                            removeExercise(item?.id)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )
              })}
            </>
          ) : (
            <div
              className={`w-full flex flex-col rounded-[12px] mt-2 px-4 py-2 justify-center items-center bg-statusBg  ${
                selectedExercises.length > 3 ? ' h-[300px] xl:h-[600px]' : 'h-[400px] xl:h-[600px]'
              } max-h-[400px] xl:max-h-[600px] `}
            >
              <img src={Images.EmptyWorkout} alt="" height={100} width={100} className="items-center cursor-pointer" />
              <p className={`${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-[white] text-[24px] leading-7`}>
                {t('TEMPLATE_IS_EMPTY')}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className={` absolute flex bottom-2 ${direction === 'ltr' ? 'right-2' : 'left-2'} gap-4  `}>
        <OutlineButton
          text={t('RESET_CHANGES')}
          type="submit"
          disabled={false}
          isIcon={false}
          onClick={handleResetExercises}
          className="w-[144px] h-[44px] rounded-[12px] border-borderActive border-[1.5px] shadow-2xl "
          bgColor="hover:bg-darkGray focus:bg-lightGray active:bg-lightGray"
          textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
        />
        <PrimaryButton
          text={t('SAVE')}
          type="submit"
          disabled={loader}
          isIcon={false}
          onClick={handleSubmit}
          className={`h-[44px] ${loader && 'w-[100px]'} px-4 rounded-[12px] shadow-2xl`}
          bgColor="bg-prussianBlue hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
          textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
          Loader={loader}
        />
      </div>
    </div>
  )
}
