import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import ExerciseDropDown from './components/exerciseDropDown'
import TemplateDropDown from './components/templateDropDown'

export default function ExerciseSection({ dragOver, workoutlist, setWorkoutlist, isActive, setActive, setLoadingExercise }) {
  const [isOpen, setIsOpen] = useState(true)
  const [isTemplateOpen, setIsTemplateOpen] = useState(false)
  const { direction } = useSelector((state) => state.language)
  const { exercisesInfo } = useSelector((state) => state.gymAdmin)
  const [exerciseHistoryModal, setExerciseHistoryModal] = useState(false)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
    setIsTemplateOpen(isOpen)
  }
  const toggleDropdownTemplate = () => {
    setIsTemplateOpen(!isTemplateOpen)
    setIsOpen(isTemplateOpen)
  }
  return (
    <div className={`flex flex-col w-[45%] h-full  bg-secondaryBackground rounded-[12px]  py-[16px] px-[24px] gap-y-2`}>
      <ExerciseDropDown
        toggleDropdown={toggleDropdown}
        isOpen={isOpen}
        direction={direction}
        data={exercisesInfo}
        dragOver={dragOver}
        workoutlist={workoutlist}
        setWorkoutlist={setWorkoutlist}
        isActive={isActive}
        setActive={setActive}
        exerciseHistoryModal={exerciseHistoryModal}
        setExerciseHistoryModal={setExerciseHistoryModal}
        setLoadingExercise={setLoadingExercise}
      />
      <TemplateDropDown
        toggleDropdown={toggleDropdownTemplate}
        isOpen={isTemplateOpen}
        direction={direction}
        data={exercisesInfo}
        dragOver={dragOver}
        workoutlist={workoutlist}
        setWorkoutlist={setWorkoutlist}
        isActive={isActive}
        setActive={setActive}
      />
    </div>
  )
}
