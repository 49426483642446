import ImagePicker from 'components/elements/ImagePicker'
import GenderSelection from 'components/home/GenderSelection'
import { constants } from 'constant'

import DatePickerField from 'components/compounds/DatePickerField'
import Dropdown from 'components/compounds/DropDown'
import { LabelInput } from 'components/compounds/LabelInput'
import { Loader } from 'components/compounds/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { traineeSchema } from 'validations/formsValidations'

import PrimaryButton from 'components/elements/PrimaryButton'
import { deleteTrainee, getTraineeById, updateTrainee } from 'store/traineeSlice/traineeActions'
import moment from 'moment'
import CustomToastify from 'components/compounds/Toast'
import { Images } from 'assets'
import { getCustomerById } from 'store/userSlice/userActions'
import { useNavigate } from 'react-router-dom'
import OutlineButton from 'components/elements/OutlineButton'

const TraineeProfile = () => {
  const { user } = useSelector((state) => state.auth)
  const [isLoading, setLoading] = useState(true)
  const [isSubmitting, setSubmitting] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const methods = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(traineeSchema),
    defaultValues: {
      traineeName: '',
      phoneNo: '',
      email: '',
      height: null,
      weight: null,
      purpose: '',
      comment: '',
      birthday: '',
    },
  })

  // eslint-disable-next-line
  const [disable, setDisable] = useState(false)

  const [file, setFile] = useState(null)
  const { t } = useTranslation()
  const { control, handleSubmit, formState, setValue } = methods
  const { errors } = formState

  const { direction } = useSelector((state) => state.language)
  const [imageURL, setImageURL] = useState(null)
  const [imageError, setImageError] = useState(false)
  const [selectedGender, setSelectedGender] = useState(constants.GENDER[0])
  const { customerDetail } = useSelector((state) => state.user)

  const [selectedDate, setSelectedDate] = useState(null)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [activityLevel, setActivityLevel] = useState(constants.ACTIVITY_LEVEL[0])
  const [dobError, setDobError] = useState(false)

  const getProfileDetails = async () => {
    setLoading(true)
    try {
      const traineeDetail = await getTraineeById(customerDetail?.User?.Trainee?.id)
      setValue('traineeName', traineeDetail?.User?.firstName)
      setValue('phoneNo', traineeDetail?.User?.phone)
      setValue('email', traineeDetail?.User?.email)
      setValue('height', traineeDetail?.height)
      setValue('weight', traineeDetail?.weight)
      setValue('purpose', traineeDetail?.Goal)
      setValue('comment', traineeDetail?.Notes)
      setActivityLevel(traineeDetail?.activityLevel)
      setSelectedGender(constants.GENDER.find((item) => item.value === traineeDetail?.User?.gender))
      traineeDetail?.User?.birthday && setSelectedDate(moment(traineeDetail?.User?.birthday, 'YYYY-MM-DD').toDate())
      setImageURL(traineeDetail?.User?.avatar)
      setLoading(false)
    } catch (error) {
      console.log('error', error)
      setLoading(false)
    }
  }
  useEffect(() => {
    getProfileDetails()
    // eslint-disable-next-line
  }, [])

  const deleteAccount = async () => {
    try {
      setSubmitting(true)
      await deleteTrainee(customerDetail?.User?.id)
      CustomToastify(t('DELETE_ACCOUNT'), '', '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
      setSubmitting(false)
    } catch (error) {
      setSubmitting(false)
      alert(error)
      CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    }
  }

  const onSubmit = async (values) => {
    try {
      if (!selectedDate) {
        return
      }
      setSubmitting(true)
      let data = {
        ...values,
        gender: selectedGender?.value,
        birthday: selectedDate ? selectedDate : null,
        activityLevel: activityLevel,
        userId: customerDetail?.User?.id,
        traineeId: customerDetail?.User?.Trainee?.id,
      }
      const formData = new FormData()
      if (file) {
        formData.append('file', file)
      }
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key])
      })
      await updateTrainee(formData)
      CustomToastify(t('PROFILE_UPDATED_SUCCESSFULLY'), '', '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
      dispatch(getCustomerById(user.gym_Id, user.id, user?.role, t, direction))
      navigate('/trainee/dashboard')
      setSubmitting(false)
    } catch (error) {
      console.log('error', error)
      CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      setSubmitting(false)
    }
  }

  return (
    <>
      {isLoading ? (
        <Loader size="w-8 h-8" fill="#003353" />
      ) : (
        <div className=" text-white">
          <div className="space-y-2">
            <div className="py-4 space-y-2 relative mb-10 w-full ">
              <div
                className="absolute inset-0 bg-center bg-cover rounded-md"
                style={{
                  backgroundImage: `url('${Images.ProfileBg}')`, // Replace with your image URL
                }}
              ></div>
              <div className="absolute top-5 left-5  block sm:hidden z-10" onClick={() => navigate('/trainee/dashboard')}>
                <img src={Images.WorkoutSidebarLeftArrow} alt="" height={10} width={10} className={` cursor-pointer`} />
              </div>
              <div className="relative pt-10">
                <ImagePicker
                  placeholder={t('TRAINEE_PHOTO')}
                  imageURL={imageURL}
                  setImageURL={setImageURL}
                  imageError={imageError}
                  errorMessage={t('IMAGE_REQUIRED')}
                  setImageError={setImageError}
                  setSelectedFile={setFile}
                  selectedFile={file}
                  styles={`w-[140px] h-[140px] items-center justify-center my-4  rounded-[50%] ${disable && 'opacity-50'} ${
                    !imageURL && 'border-dashed border-[1px] z-50'
                  }`}
                  disabled={disable}
                />
                <h1 className="text-center text-[#FFF] ltr:font-primary-rg rtl:font-primary-he-rg text-xl font-medium leading-6 capitalize">
                  {customerDetail?.User?.firstName}
                </h1>
              </div>
            </div>
            <div className="mx-6 text-[#FFF]">
              <h1 className=" text-white ltr:font-primary-rg rtl:font-primary-he-rg text-sm font-normal leading-5 capitalize">
                {t('PERSONAL_INFO')}
              </h1>
              <LabelInput
                name="traineeName"
                type="text"
                label={t('TRAINEE_NAME')}
                placeholderValue={t('FULL_NAME')}
                control={control}
                required
                fullWidth
                error={Object.keys(errors).length > 0 ? true : false}
                errorMessage={errors?.traineeName?.message}
                dir={direction}
                disabled={disable}
                isRequired={true}
                margin={'mt-2'}
                limit={20}
              />
              <LabelInput
                name="phoneNo"
                type="number"
                label={t('MOBILE_NO')}
                control={control}
                placeholderValue={'0520000000'}
                required
                fullWidth
                error={Object.keys(errors).length > 0 ? true : false}
                errorMessage={errors?.phoneNo?.message}
                dir={direction}
                disabled={disable}
                isRequired={true}
                margin={'mt-2'}
              />
              <LabelInput
                name="email"
                type="text"
                label={t('EMAIL_ADDRESS_AS_USERNAME')}
                placeholderValue={'aaa@gmail.com'}
                control={control}
                required
                fullWidth
                error={Object.keys(errors).length > 0 ? true : false}
                errorMessage={errors?.email?.message}
                dir={direction}
                margin={'mt-2'}
              />
              <div className={`mt-2   ${!selectedDate ? ' border-error border-2 rounded-[12px]' : ''}`}>
                <DatePickerField
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  showDatePicker={showDatePicker}
                  setShowDatePicker={setShowDatePicker}
                  required={true}
                  dir={direction}
                  t={t}
                  error={dobError}
                  setError={setDobError}
                  title="DOB"
                />
              </div>
              <GenderSelection
                name="gender"
                data={constants.GENDER}
                selectedGender={selectedGender}
                setSelectedGender={setSelectedGender}
                disabled={disable}
                noPadding={true}
              />
              <div className="flex flex-row gap-x-2">
                <LabelInput
                  name="height"
                  type="number"
                  label={t('HEIGHT')}
                  control={control}
                  placeholderValue={t('CM')}
                  fullWidth
                  dir={direction}
                  disabled={disable}
                  margin={'mt-2'}
                />
                <LabelInput
                  name="weight"
                  type="number"
                  label={t('WEIGHT')}
                  control={control}
                  placeholderValue={t('POUNDS')}
                  fullWidth
                  dir={direction}
                  disabled={disable}
                  margin={'mt-2'}
                />
              </div>
              <div className="pt-2">
                <Dropdown
                  options={constants.ACTIVITY_LEVEL}
                  className="my-2"
                  title={t('ACTIVITY_LEVEL')}
                  value={activityLevel}
                  setValue={setActivityLevel}
                  disable={false}
                  t={t}
                  dir={direction}
                />
              </div>
              <LabelInput
                name="purpose"
                type="text"
                label={t('PURPOSE_OF_TRAINEE')}
                placeholderValue={t('PURPOSE_OF_TRAINEE_PROPS')}
                control={control}
                fullWidth
                dir={direction}
                disabled={disable}
                margin={'mt-1'}
              />
              <LabelInput
                name="comment"
                type="text"
                label={t('COMMENT')}
                placeholderValue={t('COMMENT_PLACEHOLDER')}
                control={control}
                fullWidth
                dir={direction}
                disabled={disable}
                margin={'my-3'}
              />

              <div className="flex mb-2 space-x-2 rtl:space-x-reverse">
                <PrimaryButton
                  text={t('SAVING_CHANGES')}
                  type="submit"
                  disabled={false}
                  isIcon={false}
                  Loader={isSubmitting}
                  onClick={handleSubmit(onSubmit)}
                  className="h-[44px] px-4 rounded-[12px] shadow-2xl"
                  bgColor="bg-[#00639B] hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
                  textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
                />
                <OutlineButton
                  text={t('CHANGE_PASSWORD')}
                  type="submit"
                  disabled={false}
                  isIcon={false}
                  Loader={isSubmitting}
                  onClick={() => navigate('/trainee/changePassword')}
                  className="h-[44px] px-4 rounded-[12px] shadow-2xl"
                  bgColor="cursor-pointer border-borderActive border-[1.5px] px-[16px] py-[10px] hover:bg-[#404b51]"
                  textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
                />
                {!isSubmitting && (
                  <>
                    {!customerDetail.User.isActive ? (
                      <OutlineButton
                        text={t('DELETE_ACCOUNT')}
                        type="submit"
                        disabled={false}
                        isIcon={false}
                        Loader={isSubmitting}
                        onClick={deleteAccount}
                        className="h-[44px] px-4 rounded-[12px] shadow-2xl"
                        bgColor="hover:bg-darkGray focus:bg-lightGray active:bg-lightGray "
                        textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
                      />
                    ) : (
                      <div className="h-[44px] flex items-center justify-center font-bold text-borderActive text-sm md:text-l">
                        {t('ACCOUNT_DELETED')}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default TraineeProfile
