import { Tooltip } from "@material-tailwind/react";
import { Images } from "assets";
import { Loader } from "components/compounds/Loader";
import OutlineButton from "components/elements/OutlineButton";
import PrimaryButton from "components/elements/PrimaryButton";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  WorkoutSummaryExerciseHistory,
  musclesDetail,
  getWorkoutComments,
} from "store/workoutSlice/workoutActions";
import { getResistanceLabel, getWorkoutDate } from "utils";
import AchievementModal from "./AchievementModal";
import MusclesDetail from "./MusclesDetail";
import { ProfileAvatar } from "./ProfileAvatar";
import WorkoutSummaryMuscleModal from "./WorkoutSummaryMuscleModal";
import CommentsHistory from "./components/CommentsHistory";

const WorkoutSummary = ({
  workoutId,
  traineeId,
  summary,
  muscleIds,
  tranieeImage,
  traineeName,
  workoutName,
  exercises,
  onCloseWorkout,
  hideCancelButton,
  nextWorkoutList,
  handleNextWorkout,
  showNext,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { direction } = useSelector((state) => state.language);

  const [showExercises, setShowExercises] = useState(false);
  const [show, setShow] = useState(false);
  const [showMuscleModal, setShowMuscleModal] = useState(false);
  const [muscleDetail, setMuscleDetail] = useState(null);
  const [loader, setLoader] = useState(false);
  const [exerciseDetail, setExerciseDetail] = useState(null);
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [showComments, setShowComments] = useState(false);
  const [commentList, setCommentList] = useState();
  // Assuming you receive this data from the backend
  const performanceType = {
    improvementCard: summary?.improvementCard,
    achievementCard: summary?.achievementCard,
    bestExerciseCard: summary?.bestExerciseCard,
  };
  console.log({ performanceType, summary });
  const getMuscleDetail = async () => {
    const muscleIds = summary?.Workout_Muscles?.map((item) => item.id).join(
      ","
    );
    let data = {
      muscleId: muscleIds,
      workoutId: workoutId,
      traineeId: traineeId,
    };

    const response = await dispatch(musclesDetail(data));

    setMuscleDetail(response);
  };
  useEffect(() => {
    if (summary) {
      getMuscleDetail();
    }
  }, []);

  // Get the available card types
  let availableCardTypes;
  if (performanceType) {
    availableCardTypes = Object.keys(performanceType)?.filter(
      (cardType) =>
        performanceType[cardType] &&
        (Array.isArray(performanceType[cardType])
          ? performanceType[cardType].length > 0
          : Object.keys(performanceType[cardType]).length > 0)
    );
  }

  const getMusclePercentages = (muscles) => {
    let muscleIdsWithPercentages = {};
    if (muscles?.length > 0) {
      muscles.forEach((item) => {
        if (item) {
          muscleIdsWithPercentages[item.muscle_model_id] = item.percentage;
        }
      });
    }

    return muscleIdsWithPercentages;
  };
  const getMuscleIds = (muscles) => {
    let muscleIds = [];

    if (muscles?.length > 0) {
      muscleIds = muscles.map((item) => {
        return item ? item.muscle_model_id : null;
      });
    }
    return muscleIds;
  };
  const actionHistory = () => {
    setLoader(false);
  };
  const toggleAchievements = async (key) => {
    const index = availableCardTypes?.indexOf(key);
    const data = {
      data: performanceType[key],
      key: key,
      index: index,
    };

    setShow(data);
  };

  const onHistoryClick = async (exerciseId, workoutExerciseId) => {
    setSelectedExercise(workoutExerciseId);
    setExerciseDetail(null);
    const payload = {
      exerciseId: exerciseId,
      workoutExerciseId: workoutExerciseId,
    };
    setLoader(true);
    const response = await dispatch(
      WorkoutSummaryExerciseHistory(payload, t, actionHistory, direction)
    );
    setExerciseDetail(response);
  };

  const totalTechniqueImprovements = exercises.filter(
    (exercise) =>
      exercise.mobility_technique ||
      exercise.muscle_technique ||
      exercise.control_technique
  )?.length;
  const durationsInMinute = Math.floor(
    moment.duration(summary?.PerformanceCard?.duration, "seconds").asMinutes()
  );
  const isWithInRange = durationsInMinute >= 55 && durationsInMinute <= 65;
  const momentDate = moment(summary?.PerformanceCard?.timestamp);
  const isSameDate =
    momentDate.format("DD-MM-YYYY") === moment().format("DD-MM-YYYY");

  const day = momentDate.format("DD"); // Get the day
  const month = momentDate.format("MMM").toUpperCase();
  const dateFormat = `${t(month)} ${day}`;
  const handleNext = () => {
    const index = nextWorkoutList.indexOf(workoutId);

    const nextWorkoutIndex = (index + 1) % nextWorkoutList.length;

    const nextWorkoutId = nextWorkoutList[nextWorkoutIndex];
    handleNextWorkout(nextWorkoutId);
  };

  const improvementBgColor = (value) => {
    if (value > 0) {
      return "text-[#53C22B] bg-[#0c1d1a]";
    } else if (value < 0) {
      return "text-[#F54B49] bg-[#18191e]";
    } else if (value === 0) {
      return "text-extralightGray  bg-transparent";
    }
  };

  return (
    <>
      <div className="space-y-2 ">
        {/* --------progress bar-------- */}
        <div className="flex w-full justify-between items-center space-x-2 rtl:space-x-reverse  ">
          <div className="w-full h-6 rounded-full bg-textFieldColor justify-end flex">
            <div
              className=" h-6 rounded-full bg-[#9FF976]"
              style={{
                width: `${(summary?.PerformanceCard?.completedExercises / summary?.PerformanceCard?.totalExercises) * 100}%`,
              }}
            ></div>
          </div>
          <img
            src={Images.CloseWorkout}
            alt="asda"
            className="h-7  rounded-2xl cursor-pointer"
            onClick={onCloseWorkout}
          />
        </div>
        {/* --------Header-------- */}
        <div className="flex w-full justify-between py-2  space-x-2 rtl:space-x-reverse">
          <div className="flex flex-row  gap-x-1">
            <ProfileAvatar
              image={summary?.avatar}
              name={summary?.workoutName}
              size={"large"}
              color={"#E5A772"}
              className={"text-3xl"}
            />
            <div className="flex flex-col space-y-[2px] ">
              <h1
                className={` ltr:font-primary-md rtl:font-primary-he-md leading-5 text-base font-medium text-white `}
              >
                {`${t("SmmaryNum")}${summary?.workoutNumber} `}
              </h1>
              <p
                className={` ltr:font-primary-rg rtl:font-primary-he-rg leading-4 text-sm font-normal text-extralightGray `}
              >{`${summary?.workoutName}`}</p>
              <p
                className={` ltr:font-primary-rg rtl:font-primary-he-rg leading-4 text-sm font-normal text-extralightGray `}
              >{`${summary?.traineeName}`}</p>
            </div>
          </div>
          <div className="ltr:font-primary-rg rtl:font-primary-he-rg leading-4 text-base font-normal text-extralightGray">
            {getWorkoutDate(summary?.date, t, moment)}
          </div>
        </div>
        {/* --------Achievements-------- */}
        {summary?.improvementCard?.length != 0 && (
          <div
            className="flex flex-col w-full h-28 bg-secondaryBackground p-4 gap-2 items-center justify-center rounded-lg cursor-pointer"
            onClick={() => toggleAchievements("improvementCard")}
          >
            <img
              src={Images.Achieve}
              alt="Achieve"
              className=" h-12 cursor-pointer "
            />
            <div className="flex flex-row items-center space-x-1 rtl:space-x-reverse">
              <p className="ltr:font-primary-rg rtl:font-primary-he-rg leading-4 text-base font-normal text-white">
                {t("CongratsOnImpExer")}
              </p>
              <p className="ltr:font-primary-md rtl:font-primary-he-md leading-6 text-xl font-bold  text-white">{`${summary?.MainCards?.numberOfImproments}`}</p>
              <p className="ltr:font-primary-rg rtl:font-primary-he-rg leading-4 text-base font-normal text-white">
                {t("Exercises!")}
              </p>
            </div>
          </div>
        )}
        <div className="flex flex-row space-x-1 rtl:space-x-reverse justify-between w-full">
          {summary?.bestExerciseCard &&
            typeof summary?.bestExerciseCard === "object" &&
            Object.keys(summary?.bestExerciseCard).length !== 0 && (
              <div
                className={`flex flex-col ${Object.keys(summary?.achievementCard).length === 0 ? "w-full" : "w-1/2"} h-[148px] bg-secondaryBackground gap-1 p-4 items-center justify-center rounded-lg cursor-pointer`}
                onClick={() => toggleAchievements("bestExerciseCard")}
              >
                <img
                  src={Images.Medal}
                  alt="Achieve"
                  className="h-14 cursor-pointer"
                />
                <p className="ltr:font-primary-rg rtl:font-primary-he-rg text-xs font-normal text-white">
                  {t("Best Exercise")}
                </p>
                <p className="ltr:font-primary-md rtl:font-primary-he-md text-base font-normal text-center text-white w-full truncate">
                  {summary?.MainCards?.bestExerciseName}
                </p>
              </div>
            )}
          {Array.isArray(summary?.achievementCard) &&
            summary?.achievementCard.length !== 0 && (
              <div
                className={`flex flex-col ${Object.keys(summary?.bestExerciseCard).length === 0 ? "w-full" : "w-1/2"} h-[148px] bg-secondaryBackground p-4 gap-1 items-center justify-center rounded-lg cursor-pointer`}
                onClick={() => toggleAchievements("achievementCard")}
              >
                <img
                  src={Images.fire}
                  alt="Achieve"
                  className="h-14 cursor-pointer"
                />
                <div className="flex flex-row items-center space-x-1 rtl:space-x-reverse">
                  <p className="ltr:font-primary-rg rtl:font-primary-he-rg text-xs font-normal text-white">
                    {t("newRecord")}
                  </p>
                  <p className="ltr:font-primary-md rtl:font-primary-he-md leading-6 text-xl font-bold text-white">
                    {`${summary?.MainCards?.bestRecordResistanceValue}`}
                  </p>
                  <p className="ltr:font-primary-rg rtl:font-primary-he-rg text-xs font-normal text-white">
                    {`${summary?.MainCards?.bestRecordResistanceUnit}`}
                  </p>
                </div>
                <p className="ltr:font-primary-md rtl:font-primary-he-md text-base font-normal text-white text-center w-full truncate">
                  {`${summary?.MainCards?.bestRecordExerciseName}`}
                </p>
              </div>
            )}
        </div>

        {availableCardTypes && availableCardTypes?.length === 0 && (
          <div className="flex flex-row w-full h-28 bg-[#FFE9C5] border-2 border-[#FFB130] px-3 py-2 gap-2  rounded-lg cursor-pointer">
            <img
              src={Images.Heart}
              alt="Achieve"
              className=" w-6 h-6 cursor-pointer "
            />
            <div className="flex flex-col  space-x-1 rtl:space-x-reverse">
              <p className="ltr:font-primary-rg rtl:font-primary-he-rg  text-base font-normal text-[#263238]">
                {t("Proud of you for your persistence!")}
              </p>
              <p className="ltr:font-primary-rg rtl:font-primary-he-rg leading-6 text-base font-bold  text-[#546E7A]">
                {t(
                  "You completed another workout and for that you deserve “Well done”👋"
                )}
              </p>
            </div>
          </div>
        )}
        {/* --------Muscle Modal-------- */}
        <div className="ltr:font-primary-rg rtl:font-primary-he-rg leading-4 text-base font-normal text-white ">
          {" "}
          {t("Workout Muscles")}
        </div>
        <div className="flex justify-center items-center w-full bg-playCard rounded-xl p-6 gap-4">
          <WorkoutSummaryMuscleModal
            frontids={getMuscleIds(summary?.Workout_Muscles)}
            backIds={getMuscleIds(summary?.Workout_Muscles)}
            musclePercentages={getMusclePercentages(summary?.Workout_Muscles)}
            hoverEnabled={false}
            h={500}
            w={300}
            data={summary?.Workout_Muscles}
            showModal={true}
            showPercentage={true}
            bgColor="#1E2A30"
            selectedValue={showMuscleModal}
            setSelectedValue={setShowMuscleModal}
          />
        </div>

        {/* --------Workout detail-------- */}
        <div className="flex w-full h-11 rounded-lg items-center justify-between px-2 gap-[10px] bg-secondaryBackground">
          <p className=" ltr:font-primary-rg rtl:font-primary-he-rg text-sm text-monitorSecondary">
            {" "}
            {isSameDate && t("Today")} {dateFormat}
          </p>
          <p className=" ltr:font-primary-rg rtl:font-primary-he-rg text-xs text-monitorSecondary text-center">
            {t("EXERCISES")}{" "}
            {`${summary?.PerformanceCard?.completedExercises} / ${summary?.PerformanceCard?.totalExercises} `}{" "}
            . {t("SETS")}{" "}
            {`${summary?.PerformanceCard?.completedSets} / ${summary?.PerformanceCard?.totalSets} `}
          </p>

          <h6
            className={`${
              isWithInRange
                ? "text-[#53C22B] bg-[#0c1d1a]"
                : "text-[#F54B49] bg-[#18191e]"
            } ltr:font-primary-rg rtl:font-primary-he-rg text-xs p-1 rounded 
                        `}
          >
            {`${t("min")} ${durationsInMinute}`}
          </h6>
        </div>
        {/* --------View All exrecises-------- */}
        {!showExercises ? (
          <OutlineButton
            text={t("View all exercises")}
            type="submit"
            disabled={false}
            isIcon={false}
            onClick={() => {
              setShowExercises(!showExercises);
            }}
            className="w-full h-14 rounded-[12px] border-borderActive border-[1.5px] shadow-2xl "
            bgColor="hover:bg-darkGray focus:bg-lightGray active:bg-lightGray"
            textClass={`text-white ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
          />
        ) : (
          <div className="space-y-2 ">
            {summary?.exerciseLists?.map((item, index) => {
              const isTrackingPositive = item.trackingGapValue > 0;
              const isResistancePositive = item.resistanceGapValue > 0;

              return (
                <div
                  className={`flex flex-col  w-full h-auto  rounded-lg  cursor-pointer gap-y-2 `}
                  key={index}
                >
                  <div
                    className="flex w-full h-12 items-center bg-playCard justify-between  rounded-lg p-3 cursor-pointer"
                    onClick={() => {
                      onHistoryClick(item?.exerciseId, item?.workoutExerciseId);
                    }}
                  >
                    <Tooltip
                      content={item?.exerciseName}
                      placement="top"
                      className={`tooltip !rounded-lg ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}  `}
                    >
                      <h6
                        className={`text-sm w-32 truncate ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"} `}
                      >
                        {item?.exerciseName}
                      </h6>
                    </Tooltip>

                    <div className="flex justify-end w-1/2">
                      {item.isCanceled ? (
                        <h6
                          className={`text-sm ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"} text-[#8C9198]`}
                        >
                          {t("Cancelled")}
                        </h6>
                      ) : (
                        <div className="flex gap-x-2 rtl:space-x-reverse items-center text-xs justify-end">
                          <h6
                            className={`${improvementBgColor(item.resistanceGapValue)} py-1 px-2 rounded ${
                              direction === "ltr"
                                ? "font-primary-rg"
                                : "font-primary-he-rg"
                            }`}
                          >
                            {t(getResistanceLabel(item?.resistanceGapLable))}
                            {`${isResistancePositive ? "+" : " "}${item.resistanceGapValue}`}
                          </h6>
                          <h6
                            className={`${improvementBgColor(item.trackingGapValue)} py-1 px-2 rounded ${
                              direction === "ltr"
                                ? "font-primary-rg"
                                : "font-primary-he-rg"
                            }`}
                          >
                            {` ${t(getResistanceLabel(item?.trackingGapLable))}${isTrackingPositive ? "+" : " "}${item.trackingGapValue}`}
                          </h6>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={`flex ${
                      selectedExercise &&
                      item?.workoutExerciseId === selectedExercise
                        ? "max-h-96"
                        : "hidden"
                    } bg-[#28343A] rounded-lg`}
                  >
                    {loader &&
                    selectedExercise &&
                    item?.workoutExerciseId === selectedExercise ? (
                      <div className="flex h-80 w-full justify-center items-center ease-in-out duration-500">
                        <Loader className="" size="w-6 h-6" fill="#003353" />
                      </div>
                    ) : (
                      <>
                        {selectedExercise &&
                          item?.workoutExerciseId === selectedExercise && (
                            <div className="flex flex-col gap-2 w-full justify-between py-2 px-4 rounded-lg ease-in-out duration-300 max-h-96 ">
                              {exerciseDetail?.Sets[0]?.advancedTechniques !=
                                null && (
                                <div className="flex gap-x-1">
                                  <p className="text-extralightGray font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-xs">
                                    {t("ADVANCE_TECHNIQUE")} :{" "}
                                  </p>
                                  <p className="text-extralightGray font-bold ltr:font-primary-bd rtl:font-primary-he-bd text-xs">
                                    {" "}
                                    {t(
                                      exerciseDetail?.Sets[0]
                                        ?.advancedTechniques
                                    )}
                                  </p>
                                </div>
                              )}
                              {(exerciseDetail?.control_technique ||
                                exerciseDetail?.mobility_technique ||
                                exerciseDetail?.muscle_technique) && (
                                <div className="flex w-full gap-x-1">
                                  <p className="text-extralightGray font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-xs w-1/4">
                                    {t("Improved")} :{" "}
                                  </p>
                                  <div className="flex flex-row flex-wrap  gap-2 items-center w-3/4">
                                    {exerciseDetail?.control_technique && (
                                      <div className="flex px-1 py-[2px] rounded ltr:font-primary-rg rtl:font-primary-he-rg bg-[#373C42] text-white text-[10.52px] text-center">
                                        {t("control")}
                                      </div>
                                    )}

                                    {exerciseDetail?.mobility_technique && (
                                      <div className="flex px-1 py-[2px] rounded ltr:font-primary-rg rtl:font-primary-he-rg bg-[#373C42] text-white text-[10.52px] text-center">
                                        {t("mobility")}
                                      </div>
                                    )}
                                    {exerciseDetail?.muscle_technique && (
                                      <div className="flex px-1 py-[2px] rounded ltr:font-primary-rg rtl:font-primary-he-rg bg-[#373C42] text-white text-[10.52px] text-center">
                                        {t("muscle")}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                              {/* extra_set_count */}
                              {exerciseDetail?.extra_set_count !== 0 && (
                                <div className="flex gap-x-1">
                                  <p className="text-extralightGray font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-xs">
                                    {t("SETS")} :{" "}
                                  </p>
                                  <p className="text-extralightGray font-bold ltr:font-primary-bd rtl:font-primary-he-bd text-xs">
                                    {exerciseDetail?.extra_set_count > 0
                                      ? "+"
                                      : ""}
                                    {t(exerciseDetail?.extra_set_count) +
                                      " ( " +
                                      t("comparewithLastTime") +  
                                      " ) "}
                                  </p>
                                </div>
                              )}

                              <div className="flex flex-col w-full ">
                                <table className="w-full">
                                  <thead className="w-full">
                                    <tr>
                                      <th
                                        className="text-[#DEE3EB] font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-sm"
                                        align={
                                          direction === "ltr" ? "left" : "right"
                                        }
                                      >
                                        #
                                      </th>
                                      <th
                                        className="text-[#DEE3EB] font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-sm"
                                        align={"center"}
                                      >
                                        {t(
                                          exerciseDetail?.Exercise
                                            ?.resistancename
                                        )}
                                      </th>
                                      <th
                                        className="text-[#DEE3EB] font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-sm"
                                        align={"center"}
                                      >
                                        {t(
                                          exerciseDetail?.Exercise?.trackingname
                                        )}
                                      </th>
                                      {exerciseDetail?.intensityTracking && (
                                        <th
                                          className="text-[#DEE3EB] font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-sm"
                                          align={"center"}
                                        >
                                          RPE
                                        </th>
                                      )}
                                      {exerciseDetail?.Exercise
                                        ?.resistancename === "machine" && (
                                        <th
                                          className="text-[#DEE3EB] font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-sm"
                                          align={
                                            direction === "ltr"
                                              ? "left"
                                              : "right"
                                          }
                                        >
                                          {t("BOOST")}
                                        </th>
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {exerciseDetail?.Sets?.sort((a,b) => (a.index || 0) - (b.index || 0)).map((item, index) => (
                                      <tr
                                        key={index}
                                        className={`h-2 ${item?.status === "canceled" && "canceled-row text-red-500 "}`}
                                      >
                                        <td
                                          className={` font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-sm !p-0 ${
                                            item?.status === "canceled"
                                              ? " text-extralightGray "
                                              : "text-[#DEE3EB]"
                                          }`}
                                          align={
                                            direction === "ltr"
                                              ? "left"
                                              : "right"
                                          }
                                        >
                                          {t("SETS")} {index + 1}
                                        </td>
                                        <td
                                          className={` font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-base !p-0 ${
                                            item?.status === "canceled"
                                              ? " text-extralightGray "
                                              : "text-[#DEE3EB]"
                                          }`}
                                          align={"center"}
                                        >
                                          {item?.resistance_value}
                                        </td>
                                        <td
                                          className={` font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-base !p-0 ${
                                            item?.status === "canceled"
                                              ? " text-extralightGray "
                                              : "text-[#DEE3EB]"
                                          }`}
                                          align={"center"}
                                        >
                                          {item?.tracking_value}
                                        </td>
                                        {exerciseDetail?.intensityTracking && (
                                          <td
                                            className={` font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-base !p-0 ${
                                              item?.status === "canceled"
                                                ? " text-extralightGray "
                                                : "text-[#DEE3EB]"
                                            }`}
                                            align={"center"}
                                          >
                                            {item?.intensity_level}
                                          </td>
                                        )}
                                        {exerciseDetail?.Exercise
                                          ?.resistancename === "machine" && (
                                          <td
                                            className="!p-0"
                                            align={
                                              direction === "ltr"
                                                ? "left"
                                                : "right"
                                            }
                                          >
                                            <label className="switch-toggle-summary">
                                              <input
                                                type="checkbox"
                                                checked={item?.isBoost}
                                                disabled={true}
                                              />
                                              <span className="switch"></span>
                                            </label>
                                          </td>
                                        )}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                      </>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <OutlineButton
          text={t("Comments")}
          type="submit"
          disabled={false}
          isIcon={true}
          onClick={async () => {
            setShowComments(!showComments);
            dispatch(getWorkoutComments(workoutId));
          }}
          icon={Images.Chat}
          wid={17}
          ht={17}
          className="w-full h-14 rounded-[12px] border-borderActive border-[1.5px] shadow-2xl "
          bgColor=" hover:bg-darkGray focus:bg-lightGray active:bg-lightGray"
          textClass={`text-white ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
        />
        {showNext && showNext === true && (
          <PrimaryButton
            text={t("NEXT")}
            type="submit"
            disabled={false}
            isIcon={false}
            onClick={() => {
              handleNext();
            }}
            className="h-14 px-4 rounded-[12px] shadow-2xl"
            bgColor="bg-borderActive hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
            textClass={`text-white ${direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"}`}
          />
        )}
      </div>

      <AchievementModal
        show={show}
        setShow={setShow}
        availableCardTypes={availableCardTypes}
        performanceType={performanceType}
        summary={summary}
      />
      {showMuscleModal && (
        <MusclesDetail
          show={showMuscleModal}
          setShow={setShowMuscleModal}
          data={muscleDetail}
          muscleData={summary?.Workout_Muscles}
        />
      )}
      {showComments && (
        <CommentsHistory
          show={showComments}
          setShow={setShowComments}
          workoutId={workoutId}
          setCommentList={setCommentList}
          commentList={setCommentList}
        />
      )}
    </>
  );
};

export { WorkoutSummary };
