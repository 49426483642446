import { yupResolver } from '@hookform/resolvers/yup'
import { Images } from 'assets'
import CoachTraineesListHeader from 'components/coachTrainees/CoachTraineesListHeader'
import CreateCoachTrainee from 'components/coachTrainees/CreateCoachTrainee'
import { Loader } from 'components/compounds/Loader'
import ListTable from 'components/List/ListTable'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { traineeSchema } from 'validations/formsValidations'

export default function CoachTrainees() {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)
  const [searchText, setSearchText] = useState()
  const [gymID, setGymID] = useState(null)
  const navigate = useNavigate()

  const { trainees, loader } = useSelector((state) => state.coach)

  const headerRows = {
    data: [
      {
        id: 'User.avatar',
        align: 'center',
        type: 'custom',
        disablePadding: false,
        label: 'IMAGE',
        sort: true,
        CustomComponent: (item) => {
          const img = item?.data?.User?.avatar
          return <div className="h-10 w-10  rounded-[50%] p-1">{img != null ? <img src={img} alt="" /> : <img src={Images.Camera} alt="" />}</div>
        },
      },
      {
        id: 'User.firstName',
        align: 'center',
        type: 'Objtext',
        disablePadding: false,
        label: 'FULL_NAME',
        sort: true,
      },

      {
        id: 'CoachName',
        align: 'center',
        type: 'custom',
        disablePadding: false,
        label: 'COACH_NAME',
        sort: true,
        CustomComponent: ({ item }) => <div className="h-10 w-10  rounded-[50%] p-1">-</div>,
      },
      {
        id: 'createdAt',
        align: 'center',
        type: 'daysfromDays',
        disablePadding: false,
        label: 'WHEN_IT_CREATED',
        sort: true,
      },
      {
        id: 'Active_Program',
        align: 'center',
        type: 'custom',
        disablePadding: false,
        label: 'ACTIVE_PROGRAM',
        sort: true,
        CustomComponent: ({ item }) => <p>{0}</p>,
      },
      {
        id: 'trainingPerform',
        align: 'center',
        type: 'custom',
        disablePadding: false,
        label: 'NO_OF_TRAINING_PERFORMED',
        sort: true,
        CustomComponent: ({ item }) => <p>{0}</p>,
      },
      {
        id: 'User.isActive',
        align: 'center',
        type: 'Objtext',
        disablePadding: false,
        label: 'ACTIVE_PROGRAM',
        sort: true,
      },
    ],
    clickable: true,
  }

  const methods = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(traineeSchema),
    defaultValues: {
      traineeName: '',
      phoneNo: '',
      email: '',
      height: null,
      weight: null,
      purpose: '',
      comment: '',
    },
  })

  const toggelModal = () => {
    setShow(true)
  }
  const clickHandler = (item) => {
    navigate(`/traineeDetail/${item?.id}`)
  }

  return (
    <>
      <CoachTraineesListHeader toggelModal={toggelModal} setSearchText={setSearchText} searchText={searchText} />
      {loader ? (
        <Loader className="" size="w-10 h-10" fill="#003353" />
      ) : (
        <>
          {trainees && trainees?.length > 0 ? (
            <ListTable
              headerRows={headerRows}
              data={trainees}
              totalData={40}
              loading={false}
              searchText={searchText}
              clickHandler={clickHandler}
              deleteHandler={() => {}}
            />
          ) : (
            <div className="h-[50%] justify-center items-center ">
              <p className="text-white font-primary-rg text-[24px] text-center ">{t('NO_DATA')}</p>
            </div>
          )}
        </>
      )}
      {show && <CreateCoachTrainee Controller={Controller} methods={methods} show={show} setShow={setShow} gymID={gymID} setGymID={setGymID} />}
    </>
  )
}
