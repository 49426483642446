import axiosInstance from 'api'
import { Images } from 'assets'
import CustomToastify from 'components/compounds/Toast'

import { userActions } from './userReducer'

export const registerAccount = (data, action, t, direction) => async (dispatch) => {
  try {
    dispatch(userActions.setLoader(true))
    await axiosInstance
      .post(`register`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        CustomToastify(t('CUSTOMER_ADDED'), t('SENT_VERIFICATION_EMAIL'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)

        dispatch(getCustomers(action, t, direction))
      })
  } catch (e) {
    if (e.code === 502) {
      CustomToastify(t('EMAIL_ALREADY_EXIST'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    } else {
      CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    }
    return console.error(e.message)
  }finally{
    dispatch(userActions.setLoader(false))
  }
  
}

export const getCustomers =
  (action, t, direction, filter = 'all') =>
  async (dispatch) => {
    try {
      dispatch(userActions.setLoader(true))

      await axiosInstance
        .get(`getCoach`, { params: { query: filter } })
        .then((response) => {
          dispatch(userActions.setCustomers(response.result.data))
        })
        .finally(() => {
          action && action()
          dispatch(userActions.setLoader(false))
        })
    } catch (e) {
      dispatch(userActions.setLoader(false))
      CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return console.error(e.message)
    }
  }
export const getAllCustomers =
  (t, direction, filter = 'all') =>
  async (dispatch) => {
    try {
      dispatch(userActions.setLoader(true))

      await axiosInstance
        .get(`getCoach`, { params: { query: filter } })
        .then((response) => {
          dispatch(userActions.setCustomers(response.result.data))
        })
        .finally(() => {
          dispatch(userActions.setLoader(false))
        })
    } catch (e) {
      dispatch(userActions.setLoader(false))
      CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return console.error(e.message)
    }
  }
export const getCustomerById = (id, userId, role, t, direction) => async (dispatch) => {
  try {
    dispatch(userActions.setLoader(true))
    let queryParams = {}
    if (userId) {
      queryParams = { query: role, userId }
    } else {
      queryParams = { query: role }
    }

    await axiosInstance
      .get(`getGymCustomerById/${id}`, { params: queryParams })
      .then((response) => {
        let gym = response.result.Gym
        let user = response.result
        delete user.Gym
        gym = { ...gym, User: user }

        dispatch(userActions.setCustomersDetail(gym))
      })
      .finally(() => {
        dispatch(userActions.setLoader(false))
      })
  } catch (e) {
    dispatch(userActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}
export const getGymDetailById = (id, role, t, direction) => async (dispatch) => {
  try {
    dispatch(userActions.setLoader(true))
    await axiosInstance
      .get(`getGymCustomerById/${id}`, { params: { query: role } })
      .then((response) => {
        let gym = response.result.Gym
        let user = response.result
        delete user.Gym
        gym = { ...gym, User: user }

        dispatch(userActions.setGymDetail(gym))
      })
      .finally(() => {
        dispatch(userActions.setLoader(false))
      })
  } catch (e) {
    dispatch(userActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}
export const updateGymCustomer = (data, action, t, direction, userId, gymID) => async (dispatch) => {
  try {
    let res
    dispatch(userActions.setLoader(true))
    await axiosInstance
      .put(`UpdateGym`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        res = response
        dispatch(getAllCustomers(t, direction))
        gymID && dispatch(getCustomerById(gymID, userId, 'gym_admin', t, direction))
      })
      .finally(() => {
        dispatch(userActions.setLoader(false))

        if (res !== undefined) {
          if (res.code === 201) {
            CustomToastify(t('GYM_CUSTOMER_UPDATED'), t('SENT_VERIFICATION_EMAIL'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
          } else {
            CustomToastify(t('GYM_CUSTOMER_UPDATED'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
          }
        }
        action && action()
      })
  } catch (e) {
    dispatch(userActions.setLoader(false))

    if (e.code === 501) {
      CustomToastify(t('EMAIL_ALREADY_EXIST'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    } else {
      CustomToastify(t('SOMETHING_WENT_WRONG'), '', t('RETRY'), 'bg-toastFaildbg', direction, Images.toastFail, false)
    }

    return console.error(e.message)
  }
}
export const uploadLibraryFile = (id, file, t, direction) => async (dispatch) => {
  try {
    dispatch(userActions.setLoader(true))
    await axiosInstance.post(`/addDefaultLibrary/${id}`, file).then((response) => {
      dispatch(userActions.setLoader(false))
      CustomToastify(t('FILE_UPLOADED'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
    })
  } catch (e) {
    dispatch(userActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}
export const getAllGymsInstruments =
  (t, direction, filter = 'all') =>
  async (dispatch) => {
    try {
      dispatch(userActions.setLoader(true))
      await axiosInstance.get(`/getGymAllInstruments`, { params: { query: filter } }).then((response) => {
        dispatch(userActions.setLoader(false))
        dispatch(userActions.setGymInstruments(response?.result))
      })
    } catch (e) {
      dispatch(userActions.setLoader(false))
      CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return console.error(e.message)
    }
  }
export const getSingleGymInstruments =
  (id, t, direction, filter = 'all') =>
  async (dispatch) => {
    try {
      if (filter === 'all') {
        dispatch(userActions.setLoader(true))
      }
      await axiosInstance.get(`/getSingleGymInstruments/${id}`, { params: { query: filter } }).then((response) => {
        dispatch(userActions.setLoader(false))
        dispatch(userActions.setGymInstruments(response?.result))
      })
    } catch (e) {
      dispatch(userActions.setLoader(false))
      CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return console.error(e.message)
    }
  }
export const createGymInstruments = (data, t, direction) => async (dispatch) => {
  try {
    dispatch(userActions.setLoader(true))
    await axiosInstance.post(`/CreateInstrumentMachine`, data).then((response) => {
      dispatch(getSingleGymInstruments(data?.gymId, t, direction))
      dispatch(userActions.setLoader(false))
      CustomToastify(t('MACHINE_CREATED_SUCCESSFULLY'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
    })
  } catch (e) {
    dispatch(userActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}
export const createGymRubberBand = (data, t, direction) => async (dispatch) => {
  try {
    dispatch(userActions.setLoader(true))
    await axiosInstance.post(`/CreateGymRubberband`, data).then((response) => {
      dispatch(getSingleGymInstruments(data?.gymId, t, direction))
      dispatch(userActions.setLoader(false))
      CustomToastify(t('RUBBERBAND_CREATED_SUCCESSFULLY'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
    })
  } catch (e) {
    dispatch(userActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}
export const updateGymInstruments = (data, t, direction) => async (dispatch) => {
  try {
    dispatch(userActions.setLoader(true))
    await axiosInstance.put(`UpdateMasterInstrument`, data).then((response) => {
      dispatch(getAllGymsInstruments(t, direction))
      dispatch(userActions.setLoader(false))
      CustomToastify(t('INSTRMENTS_UPDATED_SUCCESSFULLY'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
    })
  } catch (e) {
    dispatch(userActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}
export const updateSingleGymInstruments = (data, t, direction) => async (dispatch) => {
  try {
    dispatch(userActions.setLoader(true))
    await axiosInstance.put(`UpdateGymInstrument`, data).then((response) => {
      dispatch(getSingleGymInstruments(data?.gymId, t, direction))
      dispatch(userActions.setLoader(false))
      CustomToastify(t('INSTRMENTS_UPDATED_SUCCESSFULLY'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
    })
  } catch (e) {
    dispatch(userActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}

export const DeleteGym = (id, t, direction, action, data) => async (dispatch) => {
  try {
    dispatch(userActions.setLoader(true))
    await axiosInstance.post(`deleteGym/${id}`, data).then((response) => {
      dispatch(getAllCustomers(t, direction))
      dispatch(userActions.setLoader(false))
      CustomToastify(t('CUSTOMER_DELETED'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
      action && action()
    })
  } catch (e) {
    dispatch(userActions.setLoader(false))
    if (e.code === 502) {
      CustomToastify(t('UNAUTHORIZED_ACTION'), t('VERIFICATION_WRONG'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    } else {
      CustomToastify(t('ERROR_DELETING'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    }

    return console.error(e.message)
  }
}
export const SuspendGym = (id, t, direction, action, data) => async (dispatch) => {
  try {
    dispatch(userActions.setLoader(true))
    await axiosInstance.post(`SuspendGym/${id}`, data).then((response) => {
      dispatch(getAllCustomers(t, direction))
      dispatch(userActions.setLoader(false))
      CustomToastify(t('INSTITUTE_SUSPENDED'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
      action && action()
    })
  } catch (e) {
    dispatch(userActions.setLoader(false))
    if (e.code === 502) {
      CustomToastify(t('UNAUTHORIZED_ACTION'), t('VERIFICATION_WRONG'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    } else {
      CustomToastify(t('ERROR_GYM_SUSPENDED'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    }
    return console.error(e.message)
  }
}
export const UnSuspendGym = (id, t, direction, action, data) => async (dispatch) => {
  try {
    dispatch(userActions.setLoader(true))
    await axiosInstance.post(`UnSuspendGym/${id}`, data).then((response) => {
      dispatch(getCustomerById(id, t, direction))
      dispatch(userActions.setLoader(false))
      CustomToastify(t('INSTITUTE_ACTIVATED'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
      action && action()
    })
  } catch (e) {
    dispatch(userActions.setLoader(false))
    if (e.code === 502) {
      CustomToastify(t('UNAUTHORIZED_ACTION'), t('VERIFICATION_WRONG'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    } else {
      CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    }
    return console.error(e.message)
  }
}

export const DeleteInstrument = (id, t, direction, action) => async (dispatch) => {
  try {
    dispatch(userActions.setLoader(true))
    await axiosInstance.post(`deleteInstrument/${id}`).then((response) => {
      dispatch(getAllGymsInstruments(t, direction))
      dispatch(userActions.setLoader(false))
      CustomToastify(t('EQUIPEMENT_DELETED'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
      action && action()
    })
  } catch (e) {
    dispatch(userActions.setLoader(false))
    if (e.code === 502) {
      CustomToastify(t('UNAUTHORIZED_ACTION'), t('VERIFICATION_WRONG'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    } else {
      CustomToastify(t('ERROR_DELETING'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    }
    return console.error(e.message)
  }
}
export const DeleteSingleGymInstrument = (id, gymId, t, direction) => async (dispatch) => {
  try {
    dispatch(userActions.setLoader(true))
    await axiosInstance.post(`deleteInstrument/${id}`).then((response) => {
      dispatch(getSingleGymInstruments(gymId, t, direction))
      dispatch(userActions.setLoader(false))
      CustomToastify(t('EQUIPEMENT_DELETED'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
    })
  } catch (e) {
    dispatch(userActions.setLoader(false))
    if (e.code === 502) {
      CustomToastify(t('UNAUTHORIZED_ACTION'), t('VERIFICATION_WRONG'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    } else {
      CustomToastify(t('ERROR_DELETING'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    }
    return console.error(e.message)
  }
}
export const DeleteGymByGymAdmin = (data, t, direction, action) => async (dispatch) => {
  try {
    dispatch(userActions.setLoader(true))
    await axiosInstance.post(`deleteGymRequest`, data).then((response) => {
      dispatch(userActions.setLoader(false))
      action && action()
      CustomToastify(t('ACCOUNT_DELETION_REQUEST'), t('DELTEION_CONFIRMATION_SENT'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
    })
  } catch (e) {
    dispatch(userActions.setLoader(false))
    CustomToastify(t('ERROR_DELETING'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}

export const getFilter = () => async (dispatch) => {
  try {
    axiosInstance.get('filterByQty').then((res) => {
      dispatch(userActions.setFilter(res.result.result))
    })
  } catch (error) {
    console.log('error', error)
  }
}

export const getAllMucle =
  (t, direction, filter = 'all') =>
  async (dispatch) => {
    try {
      dispatch(userActions.setLoader(true))
      await axiosInstance.get(`/getGymAllMuscle`, { params: { query: filter } }).then((response) => {
        dispatch(userActions.setLoader(false))

        dispatch(userActions.setGymMuscle(response?.result))
      })
    } catch (e) {
      dispatch(userActions.setLoader(false))
      CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return console.error(e.message)
    }
  }

export const updateGymMuscle = (data, t, direction) => async (dispatch) => {
  try {
    dispatch(userActions.setLoader(true))
    await axiosInstance.put(`UpdateGymMucles`, data).then((response) => {
      dispatch(getAllMucle(t, direction))
      dispatch(userActions.setLoader(false))
      CustomToastify(t('INSTRMENTS_UPDATED_SUCCESSFULLY'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
    })
  } catch (e) {
    dispatch(userActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}
export const DeleteGymMuscle = (id, t, direction, action) => async (dispatch) => {
  try {
    dispatch(userActions.setLoader(true))
    await axiosInstance.post(`deleteGymMucle/${id}`).then((response) => {
      dispatch(getAllMucle(t, direction))

      CustomToastify(t('EQUIPEMENT_DELETED'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
      action && action()
    })
  } catch (e) {
    dispatch(userActions.setLoader(false))
    if (e.code === 502) {
      CustomToastify(t('UNAUTHORIZED_ACTION'), t('VERIFICATION_WRONG'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    } else {
      CustomToastify(t('ERROR_DELETING'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    }
    return console.error(e.message)
  }
}
export const getAllExercise =
  (t, direction, filter = 'all') =>
  async (dispatch) => {
    try {
      dispatch(userActions.setLoader(true))
      await axiosInstance.get(`/getExercises`, { params: { query: filter } }).then((response) => {
        dispatch(userActions.setLoader(false))
        dispatch(userActions.setExercise(response?.result.data))
      })
    } catch (e) {
      dispatch(userActions.setLoader(false))
      CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return console.error(e.message)
    }
  }

export const DeleteExercise = (id, t, direction, action) => async (dispatch) => {
  try {
    dispatch(userActions.setLoader(true))
    await axiosInstance.post(`deleteGymExercise/${id}`).then((response) => {
      dispatch(getAllExercise(t, direction))
      dispatch(userActions.setLoader(false))
      CustomToastify(t('EQUIPEMENT_DELETED'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
      action && action()
    })
  } catch (e) {
    dispatch(userActions.setLoader(false))
    if (e.code === 502) {
      CustomToastify(t('UNAUTHORIZED_ACTION'), t('VERIFICATION_WRONG'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    } else {
      CustomToastify(t('ERROR_DELETING'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    }
    return console.error(e.message)
  }
}

export const uploadExerciseFile = (file, t, direction) => async (dispatch) => {
  try {
    dispatch(userActions.setLoader(true))
    await axiosInstance.post(`/createMasterExercise`, file).then(async (response) => {
      await axiosInstance.get(`/getExercises`, { params: { query: 'all' } }).then((res) => {
        dispatch(userActions.setLoader(false))
        dispatch(userActions.setExercise(res?.result.data))
      })

      CustomToastify(t('FILE_UPLOADED'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
    })
  } catch (e) {
    dispatch(userActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}

export const uploadMachineFile = (file, t, direction) => async (dispatch) => {
  try {
    dispatch(userActions.setLoader(true))
    await axiosInstance.post(`/createMasterMachine`, file).then(async (response) => {
      await axiosInstance.get(`/getGymAllInstruments`, { params: { query: 'all' } }).then((res) => {
        dispatch(userActions.setGymInstruments(res?.result))
        dispatch(userActions.setLoader(false))
      })

      CustomToastify(t('FILE_UPLOADED'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
    })
  } catch (e) {
    dispatch(userActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}

export const uploadMuscleFile = (file, t, direction) => async (dispatch) => {
  try {
    dispatch(userActions.setLoader(true))
    await axiosInstance.post(`/createMasterMuscle`, file).then(async (response) => {
      await axiosInstance.get(`/getGymAllMuscle`, { params: { query: 'all' } }).then((res) => {
        dispatch(userActions.setGymMuscle(res?.result))
      })
      dispatch(userActions.setLoader(false))
      CustomToastify(t('FILE_UPLOADED'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
    })
  } catch (e) {
    dispatch(userActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}
