import { createSlice } from '@reduxjs/toolkit'

import { useSelector } from 'react-redux'

const initialState = {
  loader: false,
  templatesList: [],
  templateFilter: [],
}
export const templateSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload
    },

    settemplatesList: (state, action) => {
      state.templatesList = action.payload
    },
    removeTemplate: (state, action) => {
      return {
        ...state,
        templatesList: state.templatesList.filter((template) => template.id !== action.payload),
      }
    },
    setTemplateFilter: (state, action) => {
      state.templateFilter = action.payload
    },
    resetTemplates: (state, { payload }) => {
      return {
        ...state,
        loader: false,
        templatesList: [],
        templateFilter:[]
      }
    },
  },
})

export const useSaleSelector = () => useSelector((state) => state.templateReducer)

export const templateActions = templateSlice.actions
export const templateReducer = templateSlice.reducer
