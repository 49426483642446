import { yupResolver } from '@hookform/resolvers/yup'
import DatePickerField from 'components/compounds/DatePickerField'
import DeleteModal from 'components/compounds/DeleteModal'
import Dropdown from 'components/compounds/DropDown'
import { LabelInput } from 'components/compounds/LabelInput'
import { Loader } from 'components/compounds/Loader'
import SuspendModal from 'components/compounds/SuspendModal'
import SwitchToggle from 'components/compounds/SwitchToggle'
import PrimaryButton from 'components/elements/PrimaryButton'
import { constants } from 'constant'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { DeleteTraineeByGymAdmin, SuspendTrainee, UnSuspendTrainee } from 'store/gymAdminSlice/gymAdminActions'
import { editCustomerSchema } from 'validations/formsValidations'

export default function PersonalInfo({
  data,

  onSubmit,
  selectedDate,
  setSelectedDate,
  activityLevel,
  setActivityLevel,
  selectedGender,
  setSelectedGender,
}) {
  const { t } = useTranslation()
  const { loader } = useSelector((state) => state.user)
  const { user } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const [openModal, setOpenModal] = useState(false)
  const toggleOpen = () => setOpenModal(true)
  const navigate = useNavigate()
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [password, setPassword] = useState('')
  const [error, setError] = useState(false)

  const [disable, setDisable] = useState(false)
  const [openSuspendModal, setOpenSuspendModal] = useState(false)

  const [checked, setChecked] = useState(false)
  const { customerDetail } = useSelector((state) => state.user)
  const action = () => {
    setOpenModal(false)
    navigate(`/home`)
  }
  const methods = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(editCustomerSchema),
    defaultValues: {
      userName: '',
      traineeName: '',
      phoneNo: '',
      email: '',
      height: null,
      weight: null,
      purpose: '',
      comment: '',
    },
  })
  const { control, handleSubmit, formState, setValue } = methods
  const { errors } = formState
  const { direction } = useSelector((state) => state.language)
  useEffect(() => {
    if (data) {
      if (data?.User?.isActive === false) {
        setChecked(true)
        setDisable(true)
      } else {
        setChecked(false)
        setDisable(false)
      }
      setValue('email', data?.User?.email)
      setValue('phoneNo', data?.User?.phone)
      setValue('traineeName', data?.User?.firstName)
      setValue('height', data?.height)
      setValue('weight', data?.weight)
      setValue('purpose', data?.Goal)
      setValue('comment', data?.Notes)
      setValue('userName', data?.User?.firstName)
      const date = new Date(data?.User.birthday)
      setSelectedDate(date)
      constants.GENDER.forEach((item) => {
        if (item.value === data?.User?.gender) {
          setSelectedGender(item)
        }
      })
      constants.ACTIVITY_LEVEL.forEach((item) => {
        if (item === data?.activityLevel) {
          setActivityLevel(item)
        }
      })
    }

    function modalButton() {
      document.getElementById('deleteButton').click()
    }
    document.addEventListener('DOMContentLoaded', modalButton)
    return () => {
      document.removeEventListener('DOMContentLoaded', modalButton)
    }
    // eslint-disable-next-line
  }, [data])

  const deletionAction = async () => {
    navigate('/admin/trainees')
  }
  const deleteTrainee = () => {
    let values = {
      userId: data?.User?.id,
      traineeId: data?.id,
      adminId: user?.id,
      password: password,
      gymId: customerDetail?.id,
    }
    dispatch(DeleteTraineeByGymAdmin(values, t, direction, deletionAction))
  }

  const actionSuspend = () => {
    setChecked(!checked)
    setDisable(!disable)
    setOpenSuspendModal(false)
  }
  const handleInactive = () => {
    if (openSuspendModal?.isChecked === true) {
      dispatch(SuspendTrainee(data?.User?.id, t, direction, actionSuspend))
    } else {
      dispatch(UnSuspendTrainee(data?.User?.id, t, direction, actionSuspend))
    }
  }
  const handleSuspend = (event) => {
    if (event.target.checked === true) {
      setOpenSuspendModal({ isChecked: event.target.checked })
    } else {
      dispatch(UnSuspendTrainee(data?.User?.id, t, direction, actionSuspend))
    }
  }

  return (
    <div className="w-[612px]  h-full py-2">
      {loader ? (
        <div className="flex flex-col justify-center items-center h-[400px]">
          <Loader className="" size="w-6 h-6" fill="#003353" />
        </div>
      ) : (
        <>
          <div className="flex flex-row gap-4 pt-4 ">
            <LabelInput
              name="userName"
              type="text"
              label={t('USERNAME')}
              control={control}
              required
              fullWidth
              error={errors?.userName ? true : false}
              errorMessage={errors?.userName?.message}
              dir={direction}
              disabled={disable}
              limit={20}
            />
            <LabelInput
              name="phoneNo"
              type="number"
              label={t('MOBILE_NO')}
              control={control}
              placeholderValue={t('INSTITUTE_PHONE_NUMBER')}
              required
              fullWidth
              error={errors?.phoneNo ? true : false}
              errorMessage={errors?.phoneNo?.message}
              dir={direction}
              disabled={disable}
            />
          </div>

          <div className="flex flex-row w-full justify-between  gap-4 ">
            <div className="flex  w-1/2 py-3">
              <DatePickerField
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                showDatePicker={showDatePicker}
                setShowDatePicker={setShowDatePicker}
                required={true}
                dir={direction}
                t={t}
                disabled={disable}
                title="DOB"
              />
            </div>
            <div className="flex w-1/2">
              <LabelInput
                name="email"
                type="text"
                label={t('MAIL')}
                placeholderValue={t('EMAIL_ADDRESS_AS_USERNAME')}
                control={control}
                required
                fullWidth
                error={errors?.email ? true : false}
                errorMessage={errors?.email?.message}
                dir={direction}
                disabled={disable}
              />
            </div>
          </div>
          <div className="flex flex-row justify-between ">
            <div className="flex flex-row w-[50%]">
              <label
                className={`flex flex-col  text-white ${
                  direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                } text-[16px] justify-center  w-20 ${disable && 'opacity-50'}`}
              >
                {t('GENDER')}
              </label>
              <div className={`flex flex-row w-full h-[56px] items-center justify-between  rounded-[12px] ${disable && 'opacity-50'}`}>
                {constants.GENDER.map((item,index) => {
                  return (
                    <div
                      key={index}
                      className={`py-2 px-2 lg:px-4 text-center text-white ${
                        selectedGender.id === item.id ? 'bg-borderActive' : 'border-[1px] border-borderActive'
                      } rounded-[12px] ${disable ? ' pointer-events-none' : ' cursor-pointer'} ${
                        direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
                      } }`}
                      onClick={() => setSelectedGender(item)}
                    >
                      {t(item.value)}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="flex w-full ">
            <div className="flex w-full h-[1px] bg-[#374349]"></div>
          </div>
          <div className="flex flex-row gap-4 ">
            <div className="w-[75%]">
              <LabelInput
                name="purpose"
                type="text"
                label={t('PURPOSE_OF_TRAINEE')}
                placeholderValue={t('PURPOSE_OF_TRAINEE_PROPS')}
                control={control}
                fullWidth
                dir={direction}
                disabled={disable}
              />
            </div>

            <Dropdown
              options={constants.ACTIVITY_LEVEL}
              className="py-3 w-[25%]"
              title={t('ACTIVITY_LEVEL')}
              value={activityLevel}
              setValue={setActivityLevel}
              disable={disable}
              t={t}
              dir={direction}
            />
          </div>
          <div className="flex flex-row pb-4 ">
            <LabelInput
              name="comment"
              type="text"
              label={t('COMMENT')}
              placeholderValue={t('COMMENT_PLACEHOLDER')}
              control={control}
              fullWidth
              dir={direction}
              disabled={disable}
            />
          </div>

          <div className="flex flex-row w-full py-1 lg:pb-5  justify-between gap-4 " data-modal-toggle="deleteModal">
            <div className="flex flex-row gap-4">
              <PrimaryButton
                text={t('DELETE_A_TRAINEE')}
                type="submit"
                disabled={disable}
                isIcon={false}
                onClick={toggleOpen}
                className=" h-[44px] rounded-[12px] shadow-2xl "
                bgColor="hover:bg-textFieldColor focus:bg-textFieldColor active:bg-textFieldColor "
                textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
                wid={20}
                ht={20}
              />
              <div className="flex self-center w-[1px] h-6 bg-[#ccc]"></div>
              <div className="flex px-4 justify-center">
                <SwitchToggle name="TRAINEE_SUSPEND" t={t} handleChange={handleSuspend} checked={checked} />
              </div>
            </div>
            <PrimaryButton
              text={t('SAVING_CHANGES')}
              type="submit"
              disabled={disable}
              isIcon={false}
              onClick={handleSubmit(onSubmit)}
              className=" h-[44px] rounded-[12px] shadow-2xl  "
              bgColor="bg-prussianBlue hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
              textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
              wid={20}
              ht={20}
            />
          </div>
        </>
      )}
      <DeleteModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        t={t}
        direction={direction}
        des={t('DELETE_TRAINEE_DESCRIPTION')}
        title={t('DELETE_TRAINEE_TITLE')}
        action={action}
        handleSubmit={deleteTrainee}
        showPass={true}
        error={error}
        setError={setError}
        password={password}
        setPassword={setPassword}
      />
      <SuspendModal
        setOpenModal={setOpenSuspendModal}
        openModal={openSuspendModal}
        t={t}
        showPass={false}
        direction={direction}
        des={t('SUSPEND_DESC')}
        title={t('SUSPEND_TITLE')}
        handleSubmit={handleInactive}
        confirmBtnText={t('SUSPEND_TRAINEE_AGREEMENT')}
      />
    </div>
  )
}
