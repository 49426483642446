import { Images } from 'assets'
import { Loader } from 'components/compounds/Loader'
import ListTable from 'components/List/ListTable'
import { ProfileAvatar } from 'components/PlayBoard/ProfileAvatar'
import { useSelector } from 'react-redux'

export default function CoachesDetail({ data, show, setShow, coachID, setCoachID }) {
  const { loader } = useSelector((state) => state.user)

  const { direction } = useSelector((state) => state.language)

  const headerRows = {
    data: [
      {
        id: 'avatar',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'custom',
        disablePadding: false,
        label: 'IMAGE',
        sort: true,
        CustomComponent: (item) => {
          const img = item?.data?.avatar
          return <ProfileAvatar image={img} name={item?.data?.firstName} />
        },
      },
      {
        id: 'firstName',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'text',
        disablePadding: false,
        label: 'FULL_NAME',
        sort: true,
      },
      {
        id: 'createdAt',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'daysfromDays',
        disablePadding: false,
        label: 'WHEN_IT_CREATED',
        sort: true,
      },
      {
        id: 'totalWorkouts',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'text',
        disablePadding: false,
        label: 'TRAINING_COMPLETED',
        sort: true,
      },
      {
        id: 'totalTrainees',
        align: direction === 'rtl' ? 'right' : 'left',
        type: 'text',
        disablePadding: false,
        label: 'TOTAL_TRAIN',
        sort: true,
      },
    ],
    clickable: true,
  }

  const clickHandler = (item) => {
    setCoachID(item?.Coach?.id)
    setShow(!show)
  }
  return (
    <>
      <div className="flex flex-col w-[75%] xl:w-[60%] pb-10 pt-4 lg:pt-6 ">
        {loader ? (
          <div className="flex flex-col justify-center items-center h-[400px]">
            <Loader className="" size="w-6 h-6" fill="#003353" />
          </div>
        ) : (
          <>
            <ListTable
              headerRows={headerRows}
              data={data}
              totalData={40}
              loading={false}
              searchText={''}
              clickHandler={clickHandler}
              deleteHandler={() => {}}
            />
          </>
        )}
      </div>
    </>
  )
}
