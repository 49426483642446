import React from 'react'
import { getResistanceLabel, getTime, getWorkoutDateAndTime } from 'utils'
import moment from 'moment'
import { Images } from 'assets'
import { Tooltip } from '@material-tailwind/react'
import { constants } from 'constant'
import PrimaryButton from 'components/elements/PrimaryButton'
import { Loader } from 'components/compounds/Loader'
import { useSelector } from 'react-redux'

export default function WeeklyWorkoutCard({
  workout,
  t,
  setSelectedExercise,
  selectedExercise,
  loading,
  exerciseDetail,
  handleShow,
  handleShowComment,
}) {
  const { direction } = useSelector((state) => state.language)
  return (
    <div key={workout?.workoutId} className="flex-shrink-0 h-fit  w-[372px] bg-playCard rounded-xl p-4 ">
      {/* -----------------Header---------------- */}
      <div className="flex justify-between w-full items-start">
        <p className=" text-start text-xl text-white ltr:font-primary-rg rtl:font-primary-he-rg truncate "> {workout?.workoutName}</p>
        <div className="flex gap-x-4 ">
          <h1 className="text-textGrey text-xs cursor-pointer">{`${getTime(workout?.workoutStartTime)} • ${getWorkoutDateAndTime(
            workout?.workoutEndDateAndTime,
            t,
            moment
          )}`}</h1>
          <p className=" text-xs text-white ltr:font-primary-rg rtl:font-primary-he-rg">{t(workout?.workoutType)}</p>
        </div>
      </div>
      {/* -----------------Body---------------- */}

      <div className="flex flex-col w-full max-h-[530px] py-5 gap-y-2 overflow-y-scroll no-scrollbar">
        {workout?.exercises?.length > 0 ? (
          <>
            {workout?.exercises?.map((item) => {
              const improvment = constants.IMPROVEMENT_CHIPS.find((val) => val?.status === item?.exerciseChip)

              return (
                <div
                  className={`flex w-full flex-col ${
                    selectedExercise && item?.workout_id === selectedExercise?.workout_id && item?.exercise_id === selectedExercise?.exercise_id
                      ? '  max-h-96 ease-in-out duration-500'
                      : ' max-h-12 ease-in-out duration-300'
                  } bg-tableRow rounded-lg  cursor-pointer `}
                  key={item.id}
                >
                  <div className="flex w-full h-12  justify-between py-2 px-4 rounded-lg ">
                    <div className="flex gap-x-1 items-center w-2/3">
                      {/* eslint-disable-next-line */}
                      {item?.exerciseChip != 'Cancelled' ? (
                        <img
                          src={Images.backArrow}
                          alt=""
                          height={16}
                          width={16}
                          className={`items-center cursor-pointer ${
                            selectedExercise &&
                            item?.workout_id === selectedExercise?.workout_id &&
                            item?.exercise_id === selectedExercise?.exercise_id
                              ? ' rotate-90'
                              : 'rtl:rotate-180'
                          }  `}
                          onClick={() => {
                            if (
                              selectedExercise != null &&
                              item?.workout_id === selectedExercise?.workout_id &&
                              item?.exercise_id === selectedExercise?.exercise_id
                            ) {
                              setSelectedExercise(null)
                            } else {
                              setSelectedExercise(item)
                            }
                          }}
                        />
                      ) : (
                        <div className="h-4 w-4"></div>
                      )}
                      <Tooltip
                        content={`${item?.exerciseName}`}
                        placement="top"
                        className={`tooltip ltr:font-primary-rg rtl:font-primary-he-rg z-[99999] `}
                      >
                        <h5 className=" text-sm text-white ltr:font-primary-rg rtl:font-primary-he-rg font-normal truncate">{item?.exerciseName}</h5>
                      </Tooltip>
                    </div>
                    <div className="flex ltr:w-1/4 rtl:w-1/7">
                      <h5
                        className={` text-center self-center items-center px-2  py-1   rounded text-xs  ltr:font-primary-rg rtl:font-primary-he-rg `}
                        style={{ backgroundColor: improvment?.bgColor, color: improvment?.textColor }}
                      >
                        {t(item?.exerciseChip)}
                      </h5>
                    </div>
                  </div>
                  {loading && item?.workout_id === selectedExercise?.workout_id && item?.exercise_id === selectedExercise?.exercise_id ? (
                    <div className="flex h-80 w-full justify-center items-center">
                      <Loader className="" size="w-6 h-6" fill="#003353" />
                    </div>
                  ) : (
                    <>
                      {selectedExercise &&
                        item?.workout_id === selectedExercise?.workout_id &&
                        item?.exercise_id === selectedExercise?.exercise_id && (
                          <div className="flex flex-col gap-2 w-full justify-between py-2 px-4 rounded-lg ease-in-out duration-300 max-h-96 ">
                            {exerciseDetail?.workout?.WorkoutExercises[0]?.Sets?.advancedTechniques != null && (
                              <div className="flex gap-x-1">
                                <p className="text-extralightGray font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-xs">
                                  {t('ADVANCE_TECHNIQUE')} :{' '}
                                </p>
                                <p className="text-extralightGray font-bold ltr:font-primary-bd rtl:font-primary-he-bd text-xs">
                                  {' '}
                                  {t(exerciseDetail?.Sets?.advancedTechniques)}
                                </p>
                              </div>
                            )}
                            {(exerciseDetail?.workout?.WorkoutExercises[0]?.controlTechnique ||
                              exerciseDetail?.workout?.WorkoutExercises[0]?.mobilityTechnique ||
                              exerciseDetail?.workout?.WorkoutExercises[0]?.muscleTechnique) && (
                              <div className="flex w-full gap-x-1">
                                <p className="text-extralightGray font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-xs w-1/4">
                                  {t('Improved')} :{' '}
                                </p>
                                <div className="flex flex-row flex-wrap  gap-2 items-center w-3/4">
                                  {exerciseDetail?.workout?.WorkoutExercises[0]?.controlTechnique && (
                                    <div className="flex px-1 py-[2px] rounded ltr:font-primary-rg rtl:font-primary-he-rg bg-[#373C42] text-white text-[10.52px] text-center">
                                      {t('control')}
                                    </div>
                                  )}

                                  {exerciseDetail?.workout?.WorkoutExercises[0]?.mobilityTechnique && (
                                    <div className="flex px-1 py-[2px] rounded ltr:font-primary-rg rtl:font-primary-he-rg bg-[#373C42] text-white text-[10.52px] text-center">
                                      {t('mobility')}
                                    </div>
                                  )}
                                  {exerciseDetail?.workout?.WorkoutExercises[0]?.muscleTechnique && (
                                    <div className="flex px-1 py-[2px] rounded ltr:font-primary-rg rtl:font-primary-he-rg bg-[#373C42] text-white text-[10.52px] text-center">
                                      {t('muscle')}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}

                            <div className="flex gap-x-1">
                           

                            {exerciseDetail?.workout?.WorkoutExercises[0]?.extraSetCount>0&&
                          
                            <>
                              <p className="text-extralightGray font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-sm">
                                {t('Extra Sets') }
                              </p>
                              <p className="text-extralightGray font-bold ltr:font-primary-rg rtl:font-primary-he-rg text-sm">
                                {' '}
                               
                                 { exerciseDetail?.workout?.WorkoutExercises[0]?.extraSetCount}
                                  
                                {t('SETS')}
                              </p>
                              </>                
            }
             {exerciseDetail?.cancelledSets>0&&
                          
                          <>
                            <p className="text-extralightGray font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-sm">
                              {t('cancel') }
                            </p>
                            <p className="text-extralightGray font-bold ltr:font-primary-rg rtl:font-primary-he-rg text-sm">
                              {' '}
                             
                               { exerciseDetail?.cancelledSets}
                                
                              {t('SETS')}
                            </p>
                            </>                
          }
                              
                              <div className="flex gap-1 justify-end items-center">
                                {exerciseDetail?.bestSet?.resistanceImproved !== 0 && (
                                  <div
                                    className={`font-primary-rg text-[12px] leading-4 font-normal ${
                                      exerciseDetail?.bestSet?.resistanceImproved > 0 ? 'text-[#53c22b] bg-[#0c1d1a]' : 'text-[#f54b49] bg-[#18191e]'
                                    }   py-[2px] px-1 rounded`}
                                  >
                                    {getResistanceLabel(exerciseDetail?.workout?.WorkoutExercises[0]?.Exercise?.resistancename)}{' '}
                                    {exerciseDetail?.bestSet?.resistanceImproved}
                                  </div>
                                )}
                                {exerciseDetail?.bestSet?.trackingImproved !== 0 && (
                                  <div
                                    className={`font-primary-rg text-[12px] leading-4 font-normal ${
                                      exerciseDetail?.bestSet?.trackingImproved > 0 ? 'text-[#53c22b] bg-[#0c1d1a]' : 'text-[#f54b49] bg-[#18191e]'
                                    }  py-[2px] px-1 rounded`}
                                  >
                                    {getResistanceLabel(exerciseDetail?.workout?.WorkoutExercises[0]?.Exercise?.trackingname)}
                                    {exerciseDetail?.bestSet?.trackingImproved}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="flex flex-col w-full ">
                              <table className="w-full">
                                <thead className="w-full">
                                  <tr>
                                    <th
                                      className="text-white font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-sm"
                                      align={direction === 'ltr' ? 'left' : 'right'}
                                    >
                                      #
                                    </th>
                                    <th className="text-white font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-sm" align={'center'}>
                                      {exerciseDetail?.workout?.WorkoutExercises[0]?.Exercise?.resistancename}
                                    </th>
                                    <th className="text-white font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-sm" align={'center'}>
                                      {exerciseDetail?.workout?.WorkoutExercises[0]?.Exercise?.trackingname}
                                    </th>
                                    {exerciseDetail?.workout?.WorkoutExercises[0]?.intensityTracking && (
                                      <th className="text-white font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-sm" align={'center'}>
                                        RPE
                                      </th>
                                    )}
                                    {exerciseDetail?.workout?.WorkoutExercises[0]?.Exercise?.resistancename === 'machine' && (
                                      <th
                                        className="text-white font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-sm"
                                        align={direction === 'ltr' ? 'left' : 'right'}
                                      >
                                        Boost
                                      </th>
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {exerciseDetail?.workout?.WorkoutExercises[0]?.Sets.map((item, index) => (
                                    <tr key={index} className={`h-2 ${item?.status === 'canceled' && 'canceled-row text-red-500 '}`}>
                                      <td
                                        className={` font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-sm !p-0 ${
                                          item?.status === 'canceled' ? ' text-extralightGray ' : 'text-white'
                                        }`}
                                        align={direction === 'ltr' ? 'left' : 'right'}
                                      >
                                        {t('SETS')} {index + 1}
                                      </td>
                                      <td
                                        className={` font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-base !p-0 ${
                                          item?.status === 'canceled' ? ' text-extralightGray ' : 'text-white'
                                        }`}
                                        align={'center'}
                                      >
                                        {item?.resistance_value}
                                      </td>
                                      <td
                                        className={` font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-base !p-0 ${
                                          item?.status === 'canceled' ? ' text-extralightGray ' : 'text-white'
                                        }`}
                                        align={'center'}
                                      >
                                        {item?.tracking_value}
                                      </td>
                                      {exerciseDetail?.workout?.WorkoutExercises[0]?.intensityTracking && (
                                        <td
                                          className={` font-normal ltr:font-primary-rg rtl:font-primary-he-rg text-base !p-0 ${
                                            item?.status === 'canceled' ? ' text-extralightGray ' : 'text-white'
                                          }`}
                                          align={'center'}
                                        >
                                          {item?.intensity_level}
                                        </td>
                                      )}
                                      {exerciseDetail?.workout?.WorkoutExercises[0]?.Exercise?.resistancename === 'machine' && (
                                        <td className="!p-0" align={direction === 'ltr' ? 'left' : 'right'}>
                                          <label className="switch-toggle-summary">
                                            <input type="checkbox" checked={item?.isBoost} disabled={true} />
                                            <span className="switch"></span>
                                          </label>
                                        </td>
                                      )}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>

                              <PrimaryButton
                                text={t('VIEW_ALL')}
                                type="submit"
                                disabled={false}
                                isIcon={false}
                                onClick={() => {
                                  handleShow(selectedExercise)
                                }}
                                className="h-[44px] w-full p-4 mt-4 rounded-[12px] shadow-2xl"
                                bgColor="bg-borderActive hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
                                textClass={`text-white ltr:font-primary-rg rtl:font-primary-he-rg}`}
                              />
                              <PrimaryButton
                                text={t('View Comments')}
                                type="submit"
                                disabled={false}
                                isIcon={false}
                                onClick={() => {
                                  handleShowComment(selectedExercise)
                                }}
                                className="h-[44px] w-full p-4 my-4 rounded-[12px] shadow-2xl"
                                bgColor="border-borderActive border-2 hover:border-prussianBlueHover focus:border-prussianBlueFocused active:border-prussianBluePressed "
                                textClass={`text-white ltr:font-primary-rg rtl:font-primary-he-rg}`}
                              />
                            </div>
                          </div>
                        )}
                    </>
                  )}
                </div>
              )
            })}
          </>
        ) : (
          <div className={`w-full h-full flex flex-col rounded-[12px] mt-2 px-4 py-2 justify-center items-center   `}>
            <img src={Images.EmptyWorkout} alt="" height={100} width={100} className="items-center cursor-pointer" />
            <p className={`ltr:font-primary-rg rtl:font-primary-he-rg text-[white] text-[24px] leading-7`}>{t('NO_DATA')}</p>
          </div>
        )}
      </div>
    </div>
  )
}
