import { Images } from 'assets'
import { Loader } from 'components/compounds/Loader'
import { Modal } from 'components/compounds/Modal'
import OutlineButton from 'components/elements/OutlineButton'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { exerciseDoneDate, getResistanceLabel } from 'utils'

export default function PlayWorkoutExerciseHistory({ isopen, setIsOpen, show, setShow, exerciseName, historyLoader }) {
  const { t } = useTranslation()
  const { direction } = useSelector((state) => state.language)
  const { exerciseHistory } = useSelector((state) => state.workout)
  const { exercises, BestRecord, firstRecord, NumberOfPreformances } = exerciseHistory

  const handleClose = () => {
    setShow && setShow(false)
    setIsOpen && setIsOpen(false)
  }

  const FooterComponent = (direction) => {
    return (
      <div className="flex flex-row h-14 items-center px-4 justify-end xl:px-6 2xl:px-12 border-t-[1px] border-borderColor  bg-sidebar ios-position w-[360px] lg:w-[595px]">
        <OutlineButton
          text={t('CLOSE')}
          type="submit"
          className=" w-[80px] h-[38px] rounded-[12px] shadow-2xl"
          bgColor=" hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed rounded-[12px] cursor-pointer bg-sidebar"
          textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
          wid={20}
          ht={20}
          onClick={handleClose}
        />
      </div>
    )
  }

  return (
    <Modal
      className={`h-full  ${direction === 'ltr' ? 'rounded-l-3xl' : 'rounded-r-3xl'} w-[360px] lg:w-[595px]`}
      childrenClass="overflow-hidden"
      open={isopen}
      title={`${t('HISTORY')} ${show?.exerciseName ? ': ' + show?.exerciseName : ''}`}
      handleClose={handleClose}
      handleSubmit={() => {}}
      loader={historyLoader}
      isEdited={false}
      disabled={false}
      direction={direction}
      footer={<FooterComponent direction={direction} />}
      padding={false}
    >
      {historyLoader ? (
        <Loader className="" size="w-8 h-8" fill="#003353" />
      ) : (
        <div className=" h-full overflow-hidden relative">
          {BestRecord?.bestExercise === null ? (
            <div className="w-full h-full flex  items-center justify-center">{t('NO_DATA')}</div>
          ) : (
            <>
              {/* ---------------- Performance Bar --------------------- */}
              <div className="flex flex-row w-full justify-between items-center h-[60.12px]">
                <img src={Images.Cup} alt="Tropy" height={60} width={62} className="items-center cursor-pointer" />
                {BestRecord && (
                  <div className="flex flex-col px-2">
                    <div className="flex flex-row items-center gap-1">
                      {/* ---------------------Best performance value--------------  */}
                      <p className="font-primary-rg font-normal text-[14px] leading-5">
                        {getResistanceLabel(BestRecord?.bestExercise?.Exercise?.resistancename)}
                      </p>
                      <p className="font-primary-rg font-normal text-[20px] leading-7">{BestRecord.bestSet?.resistance_value}</p>
                      <p className="font-primary-rg font-normal text-[14px] leading-5">x</p>
                      <p className="font-primary-rg font-normal text-[20px] leading-7">{BestRecord.bestSet?.tracking_value}</p>
                    </div>
                    <p className="font-primary-rg font-normal text-[14px] leading-5">{t('BEST_PERFORMANCE')}</p>
                  </div>
                )}
                <div className="flex flex-col">
                  <div className="flex flex-row items-center">
                    {/* ---------------------Best performance value--------------  */}
                    <p className="font-primary-rg font-normal text-[14px] leading-5">
                      {' '}
                      {NumberOfPreformances} {t('TIMES')}
                    </p>
                  </div>
                  <p className="font-primary-rg font-normal text-[14px] leading-5">{t('PERFORMED')}</p>
                </div>
              </div>
              {/* ---------------- Exercise Working History --------------------- */}
              <div className="flex flex-col h-full  ">
                {exercises?.length > 1 && (
                  <div className={`flex flex-col h-8/12 w-full gap-1 overflow-y-auto ${exercises?.length > 10 && 'pb-16'} `}>
                    {exercises?.map((item, index) => (
                      <>
                        <div className="flex flex-row   items-center justify-between " key={index}>
                          <div className="flex flex-row gap-4  items-center w-2/12">
                            <div className="h-2 w-2 bg-textFieldColor rounded"></div>

                            <div className="flex flex-col">
                              <p className="font-primary-rg text-[16px] leading-5 font-medium"># {exercises?.length - index}</p>
                              <p className="font-primary-rg text-[12px] leading-4 font-normal">{exerciseDoneDate(item?.updatedAt)}</p>
                            </div>
                          </div>
                          <div className="flex gap-x-6 gap-y-3 w-7/12 flex-wrap justify-start ">
                            {item?.Sets?.length > 0 &&
                              item?.Sets?.map((set, index) => (
                                <div className="flex flex-col " key={index}>
                                  <p className="font-primary-rg text-[12px] leading-4 font-normal text-[#C2C7CF]">
                                    {t('SET')} {set?.index + 1}
                                  </p>
                                  <p className="font-primary-rg text-[12px] leading-4 font-normal text-[#C2C7CF]">{`
                                ${getResistanceLabel(item?.Exercise?.resistancename)}
                               ${set?.resistance_value} x ${set?.tracking_value}`}</p>
                                </div>
                              ))}
                          </div>
                          <div className="flex gap-1 w-3/12 justify-end items-center">
                            {item?.resistanceImproved !== 0 && (
                              <div
                                className={`font-primary-rg text-[12px] leading-4 font-normal ${
                                  item?.resistanceImproved > 0 ? 'text-[#53c22b] bg-[#0c1d1a]' : 'text-[#f54b49] bg-[#18191e]'
                                }   py-[2px] px-1 rounded`}
                              >
                                {getResistanceLabel(item?.Exercise?.resistancename)} {item?.resistanceImproved}
                              </div>
                            )}
                            {item?.trackingImproved !== 0 && (
                              <div
                                className={`font-primary-rg text-[12px] leading-4 font-normal ${
                                  item?.trackingImproved > 0 ? 'text-[#53c22b] bg-[#0c1d1a]' : 'text-[#f54b49] bg-[#18191e]'
                                }  py-[2px] px-1 rounded`}
                              >
                                {getResistanceLabel(item?.Exercise?.trackingname)} {item?.trackingImproved}
                              </div>
                            )}
                          </div>
                        </div>

                        {exercises?.length - 2 !== index && (
                          <div className=" mx-1 -my-3 h-7 w-[1px] bg-textFieldColor rounded z-10">
                            <p className="h-7 w-[2px] bg-textFieldColor rounded z-10"></p>
                          </div>
                        )}
                      </>
                    ))}
                  </div>
                )}
                {/* ---------------- Footer 1st index --------------------- */}
                <div
                  className={`flex w-full h-4/12 bg-sidebar flex-row  items-center justify-between py-2 ${
                    exercises?.length > 1 ? 'border-t-[1px] border-[#1f2f37] ' : ''
                  } sticky bottom-0 z-1 shadow-lg `}
                  key={firstRecord.id}
                >
                  <div className="flex flex-row gap-4 items-center w-2/12">
                    <div className="h-2 w-2 bg-textFieldColor rounded"></div>
                    <div className="flex flex-col">
                      <p className="font-primary-rg text-[16px] leading-5 font-medium text-[#C2C7CF]">{t('FIRST_TIME')}</p>
                      <p className="font-primary-rg text-[12px] leading-4 font-normal  text-[#C2C7CF]">{exerciseDoneDate(firstRecord?.updatedAt)}</p>
                    </div>
                  </div>
                  <div className="flex px-4 gap-x-6 gap-y-3 w-7/12 flex-wrap justify-start">
                    {firstRecord &&
                      firstRecord?.Sets?.map((set, index) => (
                        <div className="flex flex-col" key={index}>
                          <p className="font-primary-rg text-[12px] leading-4 font-normal text-[#C2C7CF]">
                            {t('SETS')} {set?.index + 1}
                          </p>
                          <p className="font-primary-rg text-[12px] leading-4 font-normal text-[#C2C7CF]">{`${getResistanceLabel(
                            firstRecord?.Exercise?.resistancename
                          )} ${set?.resistance_value} x ${set?.tracking_value}`}</p>
                        </div>
                      ))}
                  </div>
                  <div className="flex gap-1 w-3/12 justify-end items-center">
                    {firstRecord?.resistanceImproved !== 0 && (
                      <div
                        className={`font-primary-rg text-[12px] leading-4 font-normal ${
                          firstRecord?.resistanceImproved > 0 ? 'text-[#53c22b] bg-[#0c1d1a]' : 'text-[#f54b49] bg-[#18191e]'
                        }   py-[2px] px-1 rounded`}
                      >
                        {getResistanceLabel(firstRecord?.Exercise?.resistancename)} {firstRecord?.resistanceImproved}
                      </div>
                    )}
                    {firstRecord?.trackingImproved !== 0 && (
                      <div
                        className={`font-primary-rg text-[12px] leading-4 font-normal ${
                          firstRecord?.trackingImproved > 0 ? 'text-[#53c22b] bg-[#0c1d1a]' : 'text-[#f54b49] bg-[#18191e]'
                        }  py-[2px] px-1 rounded`}
                      >
                        {getResistanceLabel(firstRecord?.Exercise?.trackingname)}
                        {firstRecord?.trackingImproved}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </Modal>
  )
}
