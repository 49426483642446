import { createSlice } from '@reduxjs/toolkit'

import { useSelector } from 'react-redux'

const initialState = {
  customers: null,
  loader: false,
  customerDetail: null,
  currGymDetail: null,
  gymInstruments: [],
  GymMuslces: [],
  filter: [],
  exercises: [],
}
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setCustomers: (state, action) => {
      state.customers = action.payload
    },
    setCustomersDetail: (state, action) => {
      state.customerDetail = action.payload
    },
    setGymDetail: (state, action) => {
      state.currGymDetail = action.payload
    },
    setGymInstruments: (state, action) => {
      state.gymInstruments = action.payload
    },
    setGymMuscle: (state, action) => {
      state.GymMuslces = action.payload
    },
    setExercise: (state, action) => {
      state.exercises = action.payload
    },
    resetUser: (state, { payload }) => {
      return {
        ...state,
        customers: null,
        loader: false,
        customerDetail: null,
        gymInstruments: [],
      }
    },
    setFilter: (state, action) => {
      state.filter = action.payload
    },
  },
})

export const useSaleSelector = () => useSelector((state) => state.userReducer)

export const userActions = userSlice.actions
export const userReducer = userSlice.reducer
