import axiosInstance from 'api'
import { Images } from 'assets'
import CustomToastify from 'components/compounds/Toast'

import { exerciseActions } from './exerciseReducers'

export const getAllMainExercises = (id, t, direction) => async (dispatch) => {
  try {
    await axiosInstance
      .get(`getAllGymMainExercises/${id}`)
      .then((response) => {
        dispatch(exerciseActions.setAllMainExercises(response.result.data))
      })
      .finally(() => {
        dispatch(exerciseActions.setLoader(false))
      })
  } catch (e) {
    dispatch(exerciseActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}
export const getGymAllExerciseNames = (id, t, direction) => async (dispatch) => {
  try {
    await axiosInstance
      .get(`gymAllExerciseNames/${id}`)
      .then((response) => {
        dispatch(exerciseActions.setAllExerciseName(response.result.data))
      })
      .finally(() => {
        dispatch(exerciseActions.setLoader(false))
      })
  } catch (e) {
    dispatch(exerciseActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}
export const getAllTags = (id, t, direction) => async (dispatch) => {
  try {
    await axiosInstance
      .get(`getAllTags/${id}`)
      .then((response) => {
        let data = response.result.data
        const tagsList = data.reduce((arr, obj) => {
          const tags = obj.tags.split(',')
          return arr.concat(tags)
        }, [])

        const uniqueTags = [...new Set(tagsList)].flatMap((tag) => tag.split(' '))

        dispatch(exerciseActions.setAllTags(uniqueTags))
      })
      .finally(() => {
        dispatch(exerciseActions.setLoader(false))
      })
  } catch (e) {
    dispatch(exerciseActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}

export const getAllGymExercise =
  (id, t, direction, filter = 'all') =>
  async (dispatch) => {
    try {
      dispatch(exerciseActions.setLoader(true))
      await axiosInstance.get(`/getAllGymExercises`, { params: { query: filter, gymId: id } }).then((response) => {
        dispatch(exerciseActions.setLoader(false))

        dispatch(exerciseActions.setAllExercise(response?.result.data))
      })
    } catch (e) {
      dispatch(exerciseActions.setLoader(false))
      CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
      return console.error(e.message)
    }
  }
export const getExerciseById = (id, gymId, t, direction) => async (dispatch) => {
  try {
    dispatch(exerciseActions.setLoader(true))
    dispatch(exerciseActions.setCurrentExercise(null))
    await axiosInstance
      .get(`getExerciseById/${id}`, { params: { gymId: gymId } })
      .then((response) => {
        let result = response?.result

        let formatedMuscles
        if (result?.muscles != null) {
          formatedMuscles = result?.muscles.map((val) => {
            return {
              id: val?.Muscle?.id,
              name: val?.Muscle?.muscleName,
              type: val?.isAssistent === true ? 'Assistent' : 'Primary',
              bodyPartId: val?.Muscle?.muscle_model_id,
            }
          })
        } else {
          formatedMuscles = null
        }
        let data = {
          exerciseId: result?.exerciseId,
          exerciseName: result?.exerciseName,
          gym_Id: result?.gym_Id,
          machine: { id: result?.machineId, name: result?.machine_name },
          muscles: formatedMuscles,
          trackingname: result?.trackingname,
          resistancename: result?.resistancename,
          tags: result?.tags?.split(','),
          difficultyLevel: result?.difficultyLevel,
          isSingleBodyPart: result?.isSingleBodyPart,
          position: result.position,
          incline: result.incline,
          tool: result.tool,
          category: result?.category,
          description: result?.description,
          mainExercise: result?.mainExercise != null ? { id: result?.mainExercise?.id, name: result?.mainExercise?.exerciseName } : null,
        }

        dispatch(exerciseActions.setCurrentExercise(data))
      })
      .finally(() => {
        dispatch(exerciseActions.setLoader(false))
      })
  } catch (e) {
    dispatch(exerciseActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}
export const createExercise = (data, t, direction, id, action) => async (dispatch) => {
  try {
    dispatch(exerciseActions.setLoader(true))
    await axiosInstance.post(`/CreateExercise`, data).then((response) => {
      dispatch(exerciseActions.setLoader(false))
      dispatch(getAllGymExercise(id, t, direction))
      CustomToastify(t('MUSCLE_CREATED'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
      action && action()
    })
  } catch (e) {
    dispatch(exerciseActions.setLoader(false))

    if (e.code === 501) {
      CustomToastify(t('Exercise_EXIST'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    } else if (e.code === 502) {
      CustomToastify(t('Exercise_EXIST'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    } else {
      CustomToastify(t('ALREADY_SUBEXERCISE'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    }
    return console.error(e.message)
  }
}
export const updateExercise = (data, t, direction, id, action) => async (dispatch) => {
  try {
    dispatch(exerciseActions.setLoader(true))
    await axiosInstance.put(`/UpdateExercise`, data).then((response) => {
      dispatch(exerciseActions.setLoader(false))
      dispatch(getAllGymExercise(id, t, direction))
      CustomToastify(t('EXERCISE_UPDATED'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
      action && action()
    })
  } catch (e) {
    dispatch(exerciseActions.setLoader(false))
    if (e.code === 502) {
      CustomToastify(t('ALREADY_SUBEXERCISE'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    } else if (e.code === 501) {
      CustomToastify(t('Exercise_EXIST'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    } else {
      CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    }
  }
}

export const deleteGymExercise = (id, t, direction) => async (dispatch) => {
  try {
    dispatch(exerciseActions.setLoader(true))
    await axiosInstance
      .delete(`deleteExercise?id=${id}`)
      .then((response) => {
        dispatch(exerciseActions.removeExercise(id))
        CustomToastify(t('EXERCISE_DELETED_SUCCESSFULLY'), t('CONGRATULATIONS'), '', 'bg-toastSuccessbg', direction, Images.toastSucess, true)
      })
      .finally(() => {
        dispatch(exerciseActions.setLoader(false))
      })
  } catch (e) {
    dispatch(exerciseActions.setLoader(false))
    CustomToastify(t('SOMETHING_WENT_WRONG'), t('PLEASE_TRY_AGAIN'), '', 'bg-toastFaildbg', direction, Images.toastFail, false)
    return console.error(e.message)
  }
}

export const getExerciseFilter = (id) => async (dispatch) => {
  try {
    axiosInstance.get(`exerciseFilterByQty/${id}`).then((res) => {
      dispatch(exerciseActions.setExerciseFilters(res.result.result))
    })
  } catch (error) {
    console.log('error', error)
  }
}
