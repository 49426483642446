import OutlineButton from 'components/elements/OutlineButton'
import PrimaryButton from 'components/elements/PrimaryButton'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

export default function TraineesFooter({ handleSubmit, handleSubmitAndCreateProgram }) {
  const { t } = useTranslation()
  const { direction } = useSelector((state) => state.language)

  return (
    <div className="flex rounded-b-[28px] flex-row gap-x-2 h-16 items-center px-4 justify-end xl:px-6 2xl:px-12 border-t-[1px] border-borderColor absolute bottom-0 w-full bg-sidebar">
      <OutlineButton
        text={t('SAVE')}
        type="submit"
        disabled={false}
        isIcon={false}
        onClick={handleSubmit}
        className="h-[44px] rounded-[12px] border-borderActive border-[1.5px] shadow-2xl flex-wrap "
        bgColor="bg-sidebar hover:bg-darkGray focus:bg-lightGray active:bg-lightGray"
        textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
      />
      <PrimaryButton
        text={t('SAVING_AND_CREATING_TRAINEEE')}
        type="submit"
        disabled={false}
        isIcon={false}
        onClick={handleSubmitAndCreateProgram}
        className="h-[44px] px-4 rounded-[12px] shadow-2xl flex-wrap"
        bgColor="bg-borderActive hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
        textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
      />
    </div>
  )
}
