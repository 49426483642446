import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import en from './locales/translation/en.json'
import he from './locales/translation/he.json'

i18n

  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: false,
    // fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      he: {
        translation: he,
      },
      en: {
        translation: en,
      },
    },
  })

export default i18n
