import React, { useRef, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useOnClickOutside } from 'hooks'

const Modal = ({ open, handleClose, children, title, className, notCrossIcon, style, childrenClass }) => {
  const modalContainerRef = useRef()
  useOnClickOutside(modalContainerRef, () => {
    handleClose()
  })
  useEffect(() => {
    // 27 is the key code for escape
    function handleKeyDown(event) {
      if (event.keyCode === 27) {
        handleClose()
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
    // eslint-disable-next-line
  }, [])
  return (
    <>
      {open && (
        <>
          {ReactDOM.createPortal(
            <div
              className="w-screen h-screen z-50 right-0 top-0 fixed shadow flex justify-center items-center m-0"
              style={{
                backgroundColor: 'rgba(20, 32, 38, 0.70)',
                margin: '0px',
                zIndex: 2000,
              }}
            >
              <div className={`relative bg-background rounded-[16px] shadow-md bg-checkBoxGray   ${className}`} ref={modalContainerRef} style={style}>
                <div className={`flex flex-col items-center justify-center w-full h-full ${childrenClass}`}>{children}</div>
                {notCrossIcon ? null : (
                  <div className="absolute right-5 top-5 cursor-pointer" onClick={handleClose}>
                    <svg aria-hidden="true" className="w-5 h-5" fill="grey" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                )}
              </div>
            </div>,
            document.getElementById('modal-root1')
          )}
        </>
      )}
    </>
  )
}
export { Modal }
