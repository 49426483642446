import React, { useState } from 'react'
import { useSelector } from 'react-redux'

const MuscleSummaryTemplate = ({ muscleSummary, t }) => {
  const [showMusclesToolTip, setShowMusclesToolTip] = useState(false)
  const { direction } = useSelector((state) => state.language)
  const toggleMuscleToolTip = () => setShowMusclesToolTip(!showMusclesToolTip)
  const isMoreThanSevenMuscles = muscleSummary?.length > 7
  const isSevenMuscles = muscleSummary?.length === 7 || muscleSummary?.length === 6
  const isFiveMuscles = muscleSummary?.length === 5 || muscleSummary?.length === 4
  const isThreeMuscles = muscleSummary?.length === 3
  const isTwoMuscles = muscleSummary?.length === 2
  const isNoMuscles = muscleSummary?.length === 0
  const isOneMuscles = muscleSummary?.length === 1

  const musclePercentageSum = (muscles) => {
    let sum = 0
    muscles.forEach((item) => (sum = sum + parseInt(item?.percentage)))

    return sum
  }

  if (isNoMuscles) {
    return (
      <div className="flex w-full h-[42px]">
        <div className={`w-full h-full flex flex-col justify-center px-2 bg-[#70ACBF] rounded-lg`}>
          <p className={`text-[#001f2a] text-xs font-bold truncate ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>
            {t('NO_MUSCLE_TO_SHOW')}
          </p>
        </div>
      </div>
    )
  }
  if (isFiveMuscles) {
    return (
      <div className="flex w-full h-[42px]">
        <div className="flex h-full w-1/2 flex-row">
          <div
            className={`w-1/2 h-full flex flex-row items-center gap-x-1 justify-center px-2 bg-[#70ACBF] rounded-l-lg rounded-r-none rtl:rounded-l-none rtl:rounded-r-lg`}
          >
            <p className={`text-[#001f2a] text-xs font-bold truncate ${direction === 'ltr' ? 'font-primary-md' : 'font-primary-he-md'}`}>
              {muscleSummary[0]?.muscleName}
            </p>
            <p
              className={`text-[#001f2a] text-xs ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
            >{`${muscleSummary[0]?.percentage}%`}</p>
          </div>
          <div className={`w-1/2 h-full flex flex-row font-bold items-center gap-x-1 justify-center px-2 bg-[#E5A772]`}>
            <p className={`text-[#001f2a] text-xs  truncate ${direction === 'ltr' ? 'font-primary-md' : 'font-primary-he-md'}`}>
              {muscleSummary[1]?.muscleName}
            </p>
            <p
              className={`text-[#001f2a] text-xs ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
            >{`${muscleSummary[1]?.percentage}%`}</p>
          </div>
        </div>
        <div className="flex h-full w-1/2 flex-row">
          {muscleSummary.slice(2).map((item, index) => {
            return (
              <div
                className={`w-1/3 h-full flex flex-row justify-center items-center gap-x-1 px-2 bg-[#8C9198] border-x-[1px] border-[#72777F] ${
                  index === muscleSummary.length - 3 && 'rounded-l-none rounded-r-lg rtl:rounded-l-lg rtl:rounded-r-none'
                }   cursor-pointer relative`}
              >
                <p className={`text-[#001f2a] font-bold text-xs ltr:font-primary-md rtl:font-primary-he-md `}>{item?.muscleName}</p>
                <p className={`text-[#001f2a] text-xs ltr:font-primary-md rtl:font-primary-he-md`}>{item?.percentage}%</p>
              </div>
            )
          })}
        </div>
      </div>
    )
  } else if (isMoreThanSevenMuscles) {
    return (
      <div className="flex w-full h-[42px] flex-row rounded-lg  relative">
        <div className="w-10/12 h-full flex flex-row">
          {muscleSummary?.slice(0, 6).map((item, index) => (
            <div
              key={index}
              className={`w-1/6 h-full flex flex-row justify-center items-center px-[5px] gap-x-1 ${
                index === 0
                  ? 'bg-[#70ACBF] rounded-r-none rounded-l-lg rtl:rounded-r-lg rtl:rounded-l-none'
                  : index === 1
                  ? 'bg-[#E5A772]'
                  : 'bg-[#8C9198] border-x-[1px] border-[#72777F]'
              } cursor-pointer relative`}
            >
              <p className="flex text-[#001f2a] font-bold text-xs justify-start truncate ltr:font-primary-md rtl:font-primary-he-md">
                {item?.muscleName}
              </p>
              <p className="text-[#001f2a] text-xs ltr:font-primary-md rtl:font-primary-he-md">{item?.percentage}%</p>
            </div>
          ))}
        </div>
        <div
          className="w-2/12 h-full flex flex-col items-center justify-center bg-[#8C9198] rounded-l-none rounded-r-lg rtl:rounded-l-lg rtl:rounded-r-none cursor-pointer relative"
          onMouseEnter={toggleMuscleToolTip}
          onMouseLeave={toggleMuscleToolTip}
        >
          <p className={`text-[#001f2a] font-bold text-xs ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} truncate`}>
            {`${t('MUSCLES')} ${Math.max(muscleSummary?.length - 7, 0)}+`}
          </p>
          <p className={`text-[#001f2a] text-xs ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>
            {`${musclePercentageSum(muscleSummary.slice(6))}%`}
          </p>
          {showMusclesToolTip && (
            <div className="absolute top-12 left-1/2 transform -translate-x-1/2 p-2 bg-templateText text-black rounded-lg z-50">
              <div className="tooltip-pointer-bottom absolute w-0 h-0 border-transparent border-4 pointer-events-none" />
              {muscleSummary.slice(6).map((item, index) => (
                <h6 key={index} className={`text-xs whitespace-nowrap ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>
                  {`${item?.percentage}% ${item.muscleName} `}
                </h6>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  } else if (isSevenMuscles) {
    return (
      <div className="flex w-full h-[42px] flex-row justify-between rounded-lg overflow-x-hidden">
        {muscleSummary.map((item, index) => {
          return (
            <div
              className={` w-1/6 h-full flex flex-row justify-center items-center px-2 gap-x-1  ${
                index === 0 ? 'bg-[#70ACBF]' : index === 1 ? 'bg-[#E5A772]' : 'bg-[#8C9198] border-x-[1px] border-[#72777F]'
              }  cursor-pointer relative`}
            >
              <p className={`flex text-[#001f2a] font-bold text-xs justify-center  truncate ltr:font-primary-md rtl:font-primary-he-md `}>
                {item?.muscleName}
              </p>
              <p className={`text-[#001f2a] text-xs ltr:font-primary-md rtl:font-primary-he-md`}>{item?.percentage}%</p>
            </div>
          )
        })}
      </div>
    )
  } else if (isThreeMuscles) {
    return (
      <div className="flex w-full h-[42px]">
        <div
          className={`w-1/3 h-full flex flex-col justify-center px-2 bg-[#70ACBF] rounded-l-lg rounded-r-none rtl:rounded-l-none rtl:rounded-r-lg`}
        >
          <p className={`text-[#001f2a] font-bold text-xs ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} truncate`}>
            {muscleSummary[0]?.muscleName}
          </p>
          <p
            className={`text-[#001f2a] text-xs ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
          >{`${muscleSummary[0]?.percentage}%`}</p>
        </div>
        <div className={`w-1/3 h-full flex flex-col justify-center px-2 bg-[#E5A772]`}>
          <p className={`text-[#001f2a] font-bold text-xs ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} truncate`}>
            {muscleSummary[1]?.muscleName}
          </p>
          <p
            className={`text-[#001f2a] text-xs ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
          >{`${muscleSummary[1]?.percentage}%`}</p>
        </div>
        <div
          className={`w-1/3 h-full flex font-bold flex-col justify-center px-2 bg-[#8C9198] rounded-l-none rounded-r-lg rtl:rounded-l-lg rtl:rounded-r-none`}
        >
          <p className={`text-[#001f2a] text-xs ${direction === 'ltr' ? 'font-primary-md' : 'font-primary-he-md'} truncate`}>
            {muscleSummary[2]?.muscleName}
          </p>
          <p
            className={`text-[#001f2a] text-xs ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
          >{`${muscleSummary[2]?.percentage}%`}</p>
        </div>
      </div>
    )
  } else if (isOneMuscles) {
    return (
      <div className="flex w-full h-[42px]">
        <div
          className={`w-full h-full flex flex-col justify-center px-2 bg-[#70ACBF] rounded-l-lg rounded-r-none rtl:rounded-l-none rtl:rounded-r-lg`}
        >
          <p className={`text-[#001f2a] font-bold text-xs ${direction === 'ltr' ? 'font-primary-md' : 'font-primary-he-md'} truncate`}>
            {muscleSummary[0]?.muscleName}
          </p>
          <p
            className={`text-[#001f2a] text-xs ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
          >{`${muscleSummary[0]?.percentage}%`}</p>
        </div>
      </div>
    )
  } else if (isTwoMuscles) {
    return (
      <div className="flex w-full h-[42px]">
        <div
          className={`w-1/2 h-full flex flex-col justify-center px-2 bg-[#70ACBF] rounded-l-lg rounded-r-none rtl:rounded-l-none rtl:rounded-r-lg`}
        >
          <p className={`text-[#001f2a] font-bold text-xs ${direction === 'ltr' ? 'font-primary-md' : 'font-primary-he-md'} truncate`}>
            {muscleSummary[0]?.muscleName}
          </p>
          <p
            className={`text-[#001f2a] text-xs ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
          >{`${muscleSummary[0]?.percentage}%`}</p>
        </div>
        <div
          className={`w-1/2 h-full flex flex-col justify-center px-2 bg-[#E5A772] rounded-l-none rounded-r-lg rtl:rounded-l-lg rtl:rounded-r-none`}
        >
          <p className={`text-[#001f2a] font-bold text-xs ${direction === 'ltr' ? 'font-primary-md' : 'font-primary-he-md'} truncate`}>
            {muscleSummary[1]?.muscleName}
          </p>
          <p
            className={`text-[#001f2a] text-xs ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
          >{`${muscleSummary[1]?.percentage}%`}</p>
        </div>
      </div>
    )
  } else {
    return (
      <div className="flex w-full h-[42px]">
        {muscleSummary?.length > 0 && (
          <div className={`w-full h-full flex flex-col justify-center px-2 bg-[#70ACBF] rounded-lg`}>
            <p className={`text-[#001f2a] font-bold text-xs ${direction === 'ltr' ? 'font-primary-md' : 'font-primary-he-md'} truncate`}>
              {muscleSummary[0]?.muscleName}
            </p>
            <p
              className={`text-[#001f2a] text-xs ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
            >{`${muscleSummary[0]?.percentage}%`}</p>
          </div>
        )}
      </div>
    )
  }
}

export { MuscleSummaryTemplate }
