import { Images } from 'assets'
import { Loader } from 'components/compounds/Loader'
import { Modal } from 'components/compounds/Modal'
import OutlineButton from 'components/elements/OutlineButton'
import PrimaryButton from 'components/elements/PrimaryButton'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ImprovedExercise from './components/ImprovedExercise'
import BestExercise from './components/BestExercise'
import BestRecord from './components/BestRecord'

export default function AchievementModal({ show, setShow, availableCardTypes, performanceType, summary }) {
  const { t } = useTranslation()
  const { direction } = useSelector((state) => state.language)

  const handleClose = () => {
    setShow && setShow(false)
  }
  const handleNext = async () => {
    const nextCardIndex = (show?.index + 1) % availableCardTypes.length

    const nextKey = availableCardTypes[nextCardIndex]

    const data = {
      data: performanceType[nextKey],
      key: nextKey,
      index: nextCardIndex,
    }

    setShow(data)
  }
  const handlePre = async () => {
    const prevCardIndex = (show?.index - 1 + availableCardTypes.length) % availableCardTypes.length

    const prevKey = availableCardTypes[prevCardIndex]

    const data = {
      data: performanceType[prevKey],
      key: prevKey,
      index: prevCardIndex,
    }

    setShow(data)
  }

  const FooterComponent = (direction) => {
    return (
      <div className="flex flex-row h-16 justify-between items-center px-4  xl:px-6 2xl:px-12 border-t-[1px] border-borderColor  bg-sidebar ios-position w-[400px] xl:w-[595px]">
        <PrimaryButton
          text={t('NEXT')}
          type="submit"
          disabled={false}
          isIcon={false}
          onClick={() => handleNext()}
          className="h-12 px-4 rounded-[12px] shadow-2xl"
          bgColor="bg-borderActive hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
          textClass={`text-white ltr:font-primary-rg rtl:font-primary-he-rg`}
        />
        <PrimaryButton
          text={t('PREVIOUS')}
          type="submit"
          disabled={false}
          isIcon={false}
          onClick={() => handlePre()}
          className=" h-12 px-4 rounded-[12px] shadow-2xl"
          textClass={`text-white ltr:font-primary-rg rtl:font-primary-he-rg`}
        />
      </div>
    )
  }

  return (
    <Modal
      className={`h-full  ${direction === 'ltr' ? 'rounded-l-3xl' : 'rounded-r-3xl'}  w-[400px] xl:w-[595px]`}
      childrenClass="overflow-hidden"
      open={show}
      title={`${show?.key === 'improvementCard' ? t('Improved') : show?.key === 'achievementCard' ? t('New achievements') : t('Best Exercise')} `}
      handleClose={handleClose}
      handleSubmit={() => {}}
      loader={false}
      isEdited={false}
      disabled={false}
      direction={direction}
      footer={availableCardTypes.length != 1 ? <FooterComponent direction={direction} /> : null}
      padding={false}
    >
      <div className=" h-full overflow-hidden relative p-4">
        {show?.key === 'improvementCard' && <ImprovedExercise data={show?.data} summary={summary} />}

        {show?.key === 'achievementCard' && <BestRecord data={show?.data} summary={summary} />}
        {show?.key === 'bestExerciseCard' && <BestExercise data={show?.data} summary={summary} />}
      </div>
    </Modal>
  )
}
