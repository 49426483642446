import { Images } from 'assets'
import { ProfileAvatar } from 'components/PlayBoard/ProfileAvatar'

import { SearchInput } from 'components/compounds/SearchInput'
import SwitchToggle from 'components/compounds/SwitchToggle'
import PrimaryButton from 'components/elements/PrimaryButton'
import { constants } from 'constant'
import { useDebounce, useOutsideClick } from 'hooks'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { MdCancel } from 'react-icons/md'
import { AiOutlineCheck } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

export default function CalendarListHeader(props) {
  const { CALENDAR_FILTER } = constants
  const [filterDate, setFilterDate] = useState(CALENDAR_FILTER[1])
  const [checked, setChecked] = useState(false)

  const { t } = useTranslation()
  const { toggelModal, handleChange } = props
  const { direction } = useSelector((state) => state.language)
  const { coaches } = useSelector((state) => state.gymAdmin)
  // eslint-disable-next-line
  const [searchInput, setSearchInput] = useState('')
  const filtersRef = useRef()
  const [filtersMenu, setFiltersMenu] = useState(false)
  // eslint-disable-next-line
  const [selectedFilterMenu, setSelectedFilterMenu] = useState(false)
  const debouncedSearchTerm = useDebounce(searchInput, 500)

  const handleChecked = () => {
    setChecked(!checked)
  }
  // eslint-disable-next-line
  const [temp1, setTemp1] = useState({
    COACHES: [],
    ACTIVE_PROGRAM: [],
    ACTIVE_TRAINEE: [],
  })

  // const setSearch = (event) => {
  //   setSearchInput(event.target.value)
  // }

  useEffect(() => {
    const getTrainees = async () => {
      if (temp1.COACHES.length > 0 || temp1.ACTIVE_PROGRAM.length > 0 || temp1.ACTIVE_TRAINEE.length > 0) {
        // dispatch(getAllTrainees(t, direction ,temp1))
      } else if (debouncedSearchTerm) {
        // dispatch(getAllGymExercise(customerDetail.id, t, direction, { search: debouncedSearchTerm }))
      } else {
        // dispatch(getAllGymExercise(customerDetail.id, t, direction))
      }
    }
    getTrainees()
  }, [temp1, debouncedSearchTerm])

  const onClickOutsideFiltersDiv = () => {
    setFiltersMenu(false)
    setSelectedFilterMenu(false)
  }
  const navigation = useNavigate()

  useOutsideClick(filtersRef, onClickOutsideFiltersDiv)
  const handleChangeView = (item) => {
    setFilterDate(item)
    if (item === 'day') {
      handleChange('timeGridDay')
    } else if (item === 'week') {
      handleChange('timeGridWeek')
    } else {
      handleChange('dayGridMonth')
    }
  }

  return (
    <div className="flex w-full flex-col">
      <div className="flex flex-row w-full px-8 py-4 justify-between items-center ">
        <div className="flex gap-1 text-white">
          <p
            className={`text-white cursor-pointer  hover:border-white hover:border-b-2 text-[14px] ${
              direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
            }`}
            onClick={() => {
              navigation('/admin/dashboard')
            }}
          >
            {' '}
            {t('MAIN')}
          </p>
          <p>/</p> <p className={`text-breadCrumb ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} text-[14px] `}>{t('CALENDER')}</p>
        </div>
      </div>
      <div className="flex flex-row w-full px-8 py-1 justify-between items-center">
        <div className={`text-white text-[24px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>{t('CALENDER')}</div>

        <div className={`flex  flex-col px-1 `}>
          <div className={`flex flex-row w-full h-[56px] items-center gap-1  rounded-[12px]`}>
            {CALENDAR_FILTER.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`py-2 px-4 text-[14px] text-center text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'} ${
                    filterDate === item ? 'bg-borderActive' : 'border-[1px] border-borderActive'
                  }  rounded-[12px] cursor-pointer `}
                  onClick={() => handleChangeView(item)}
                >
                  {t(item)}
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="flex flex-row w-full px-8 py-1 justify-between items-center">
        <div className="flex flex-row gap-4 items-center">
          <SearchInput
            radius={'rounded-[50px]'}
            placeholder={t('SEARCH')}
            className="flex flex-1"
            disableUnderline
            fullWidth
            value={props.setSearchText}
            inputProps={{
              'aria-label': 'Search',
            }}
            onChange={(ev) => props.searchText(ev.target.value)}
            icon={Images.Search}
          />
          <div ref={filtersRef} className="relative box-border">
            <div
              className={`flex flex-row w-[156px] h-[40px] items-center rounded-[12px] cursor-pointer bg-primary
            border-borderActive border-[1.5px]
            }`}
              onClick={() => {
                setFiltersMenu(!filtersMenu)
              }}
            >
              <img src={Images.Filter} alt="" height={40} width={45} className={`p-3 items-center `} onClick={() => {}} />
              <p className={`text-textGrey text-center text-[14px] ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>
                {t('ADDING_FILTER')}
              </p>
            </div>
            {filtersMenu ? (
              <div
                className={`absolute top-11 z-50 bg-statusBg rounded-[12px] w-[250px] max-h-[200px] overflow-auto shadow-xl p-1 ${
                  filtersMenu ? 'border-borderActive border-[2px]' : ''
                }`}
              >
                {coaches.map((opt, i) => {
                  const textColor = constants.COLOR_MAP.find((item) => item?.bgColor === opt.Coach.color)?.textColor || '#FFFFFF'
                  return (
                    <div
                      key={i}
                      className={`flex justify-between items-center space-x-4 rtl:space-x-reverse p-2 cursor-pointer ${
                        props.selectedCoach && props.selectedCoach.id === opt?.id && ''
                      }`}
                      onClick={() => {
                        if (props.selectedCoach && props.selectedCoach.id === opt?.id) {
                          props.setSelectedCoach(null)
                        } else {
                          props.setSelectedCoach(opt)
                        }
                      }}
                    >
                      <div className="flex space-x-2 items-center">
                        <ProfileAvatar
                          size={'small'}
                          name={opt?.firstName}
                          image={opt?.avatar}
                          style={{
                            backgroundColor: opt.Coach.color,
                            color: textColor,
                          }}
                        />
                        <h1 className="text-white text-xs truncate">{opt?.firstName}</h1>
                      </div>
                      {props.selectedCoach && props.selectedCoach.id === opt?.id && (
                        <div
                          className="w-3 h-3 rounded-full shadow-2xl"
                          style={{ backgroundColor: opt.Coach.color === 'BLUE' ? '#E5A772' : opt.Coach.color }}
                        ></div>
                      )}
                    </div>
                  )
                })}
              </div>
            ) : null}
          </div>
          {props.selectedCoach && (
            <div
              className="flex flex-row items-center gap-1 px-3 py-1 rounded-[16px] hover:border-borderActive hover:border-[2px]"
              style={{
                color: constants.COLOR_MAP.find((item) => item?.bgColor === props.selectedCoach.Coach.color)?.textColor || '#FFFFFF',
                backgroundColor: props.selectedCoach.Coach.color,
              }}
            >
              <p className={`text-sm  ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>{props.selectedCoach?.firstName}</p>
              <MdCancel
                onClick={() => {
                  props.setSelectedCoach(null)
                }}
                fontSize={'1.1rem'}
                color={constants.COLOR_MAP.find((item) => item?.bgColor === props.selectedCoach.Coach.color)?.textColor || '#FFFFFF'}
              />
            </div>
          )}
        </div>
        <div className="p-4">
          <SwitchToggle name="HANDLE_REPLACEMENT" t={t} handleChange={handleChecked} checked={checked} />
        </div>
      </div>
    </div>
  )
}
