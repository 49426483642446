import { Images } from 'assets'
import { SearchInput } from 'components/compounds/SearchInput'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { uploadMachineFile } from 'store/userSlice/userActions'

export default function EquipmentHeader({ setSearchText, searchText }) {
  const [searchInput, setSearchInput] = useState('')
  const { direction } = useSelector((state) => state.language)

  const inputRef = useRef()
  // eslint-disable-next-line
  const [uploadFile, setUploadedFile] = useState(null)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchText(searchInput)
    }, 500)

    return () => {
      clearTimeout(handler)
    }
    // eslint-disable-next-line
  }, [searchInput])

  const setSearch = (event) => {
    setSearchInput(event.target.value)
  }
  const onFileUpload = async (event) => {
    setUploadedFile(event.target.files[0])
    const formData = new FormData()
    formData.append('file', event.target.files[0])
    dispatch(uploadMachineFile(formData, t, direction))
  }
  return (
    <div className="flex w-full flex-col ">
      <div className={`flex flex-row w-full px-8 pt-4 justify-between items-center `}>
        <div className="text-white ltr:font-primary-rg rtl:font-primary-he-rg text-[24px]">{t('EQUIPMENT')} </div>
        <div
          className={` text-sm lg:text-sm px-2 py-2 lg:px-4 flex items-center justify-center bg-borderActive cursor-pointer rounded-[12px]`}
          style={{ alignSelf: 'center' }}
          onClick={() => inputRef.current.click()}
        >
          <input
            ref={inputRef}
            type={'file'}
            accept=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onChange={onFileUpload}
            style={{ display: 'none' }}
          />
          &nbsp;
          <div className="flex items-center gap-2">
            <p
              className={`flex  text-white font-primary-rg text-[12px] text-center ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
            >
              {' '}
              {t('UPLOAD_FILE')}
            </p>
            <img src={Images.Plus} alt="" height={10} width={10} />
          </div>
        </div>
      </div>
      <div className="flex flex-row w-full px-8 pt-4 gap-4 items-center">
        <SearchInput
          radius={'rounded-[50px]'}
          placeholder={t('SEARCH')}
          className="flex flex-1"
          disableUnderline
          fullWidth
          value={searchInput}
          inputProps={{
            'aria-label': 'Search',
          }}
          onChange={(ev) => setSearch(ev)}
          icon={Images.Search}
        />
      </div>
    </div>
  )
}
