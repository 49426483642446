import { Images } from 'assets'
import { ProfileAvatar } from 'components/PlayBoard/ProfileAvatar'
import ShowCoaches from 'components/coaches/ShowCoaches'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { getAllCoaches } from 'store/gymAdminSlice/gymAdminActions'
import { getCustomerById } from 'store/userSlice/userActions'
import { handleLogout } from 'utils'

import i18n from 'i18n'

const Menus = [
  { title: 'MAIN', src: Images.Widget, url: '/admin/dashboard' },

  { title: 'WORKOUTSPANNEL', src: Images.playBoard, url: '/admin/playBoard' },
  { title: 'WORKOUTSMONITOR', src: Images.Monitor, url: '/admin/workoutMonitor' },
  { title: 'CALENDER', src: Images.NavCalendar, url: '/admin/adminCalendar' },
  { title: 'TRAINEES', src: Images.NavUser, url: '/admin/trainees' },
  { title: 'LIBRARY', src: Images.library, url: '/admin/library' },
]

const AdminLayout = (props) => {
  const { user, isSuperAdmin, selectedGym } = useSelector((state) => state.auth)

  const { customerDetail } = useSelector((state) => state.user)
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { language, direction } = useSelector((state) => state.language)
  const { coaches } = useSelector((state) => state.gymAdmin)

  const { t } = useTranslation()
  const [open, setOpen] = useState(true)
  // eslint-disable-next-line
  const [active, setActive] = useState(0)

  useEffect(() => {}, [active])
  const activeTab = async (index, URL) => {
    if(!customerDetail || !customerDetail.id) return
    navigate(URL)
  }

  useEffect(() => {
    i18n.changeLanguage(language)
    document.body.setAttribute('dir', language === 'en' ? 'ltr' : 'rtl')
    i18n.reloadResources()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isSuperAdmin) {
      dispatch(getCustomerById(selectedGym, null, 'gym_admin', t, direction))
      dispatch(getAllCoaches(selectedGym, t, direction))
    } else {
      dispatch(getCustomerById(user?.gym_Id, user?.id, user?.role, t, direction))
      dispatch(getAllCoaches(user?.gym_Id, t, direction))
    }
    // eslint-disable-next-line
  }, [isSuperAdmin, selectedGym])

  const roles = ['gym_admin', 'admin', 'coach']
  if (!roles.includes(user?.role)) {
    return <Navigate to="/login" />
  }
  if (direction === 'rtl') {
    document.documentElement.style.setProperty('--position-left', '0px')
    document.documentElement.style.setProperty('--position-right', 'auto')
  } else {
    document.documentElement.style.setProperty('--position-left', 'auto')
    document.documentElement.style.setProperty('--position-right', '0px')
  }

  return (
    <div className="flex w-screen h-screen  overflow-hidden relative bg-primary ">
      <div className={` ${open ? 'w-64' : 'w-20 '} h-screen bg-sidebar relative duration-300  `}>
        <img
          src={Images.rightArrow}
          className={`absolute cursor-pointer ${language === 'he' ? '-left-4' : '-right-4'}  top-16 w-8 border-dark-purple
       rounded-full   ${!open && 'rotate-180'} `}
          style={{ zIndex: 3000 }}
          onClick={() => setOpen(!open)}
          alt=""
        />

        <div className="flex flex-col space-y-2 py-2  items-center pt-3 ">
          <div
            onClick={() => {
              navigate('/admin/gymprofile')
            }}
          >
            <ProfileAvatar
              image={customerDetail?.logo}
              name={customerDetail?.gymName}
              size={open ? 'xxxlarge' : 'xlarge'}
              color={customerDetail?.User?.Coach?.color === 'BLUE' ? '#E5A772' : customerDetail?.User?.Coach?.color}
              className={open && '!text-3xl'}
            />
          </div>
          <h1 className={`${!open && 'hidden'} font-secondary-bd leading-4 text-[1.125rem] font-black text-[#C2C7CF] duration-200 mb-1 `}>
            {customerDetail?.gymName}
          </h1>
          <div className={`flex justify-center mt-2`}>
            <span
              className={`${!open && 'hidden'} duration-200 text-[#C2C7CF] ${
                direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'
              }   text-[#C2C7CF] text-[10px] px-3`}
            >
              {t('POWEREDBY')}
            </span>
          </div>
        </div>
        <div className="w-full h-[1px] bg-[#32445F] mb-2 "></div>

        <ul className="mt-2 px-5 ">
          {Menus.map((Menu, index) => {
            if (!(user?.role === 'coach' && Menu.title === 'WORKOUTSMONITOR')) {
              return (
                <li
                  key={index}
                  className={`flex  ${
                    open ? 'p-2' : 'items-center justify-center '
                  } ${(customerDetail && customerDetail.id) ? 'cursor-pointer' : 'cursor-wait'} rounded-xl hover:bg-primary hover:transition hover:duration-300 hover:ease-in-out hover:rounded-[12px] h-[56px] text-[#C2C7CF] text-sm items-center gap-x-4 
            ${location.pathname.includes(Menu.url) ? 'bg-sidebarhover' : ''} `}
                  onClick={() => {
                    activeTab(index, Menu.url)
                  }}
                >
                  <img src={Menu.src} width={20} height={20} alt="" />
                  <span className={`${!open && 'hidden'} origin-left duration-200 ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}>
                    {t(Menu.title)}
                  </span>
                </li>
              )
            }
            return null
          })}
        </ul>
        {user?.role !== 'coach' && (
          <div style={{ height: 'calc(100vh - 70vh)', overflowX: 'auto', scrollbarColor: '#000', paddingBottom: '5px' }} className="px-5 ">
            <div className="w-ful h-[1px] bg-[#32445F] my-2"></div>
            <ShowCoaches open={open} data={coaches} />
          </div>
        )}

        <div
          className={`flex flex-col   w-full  absolute  bottom-0  cursor-pointer z-50 overflow-hidden items-center justify-center duration-200 ease-in-out ${
            open && ' rounded-t-lg h-16 xl:h-16'
          }  p-2`}
        >
          <div className={`flex  w-full cursor-pointer  ${open ? ` justify-between flex-row ` : ' justify-center items-center flex-col'}  `}>
            <div
              className={`flex items-center space-x-2 rtl:space-x-reverse ${open && ` rounded-[12px] `} ${
                open && location.pathname.includes('/admin/gymprofile') && ' '
              } `}
            >
              {user?.role === 'coach' ? (
                <ProfileAvatar
                  image={user?.role === 'coach' ? customerDetail?.User?.avatar : customerDetail?.logo}
                  name={user?.role === 'coach' ? customerDetail?.User?.firstName : customerDetail?.gymName}
                  size={'large'}
                  color={customerDetail?.User?.Coach?.color === 'BLUE' ? '#E5A772' : customerDetail?.User?.Coach?.color}
                />
              ) : (
                <ProfileAvatar
                  image={user?.avatar}
                  name={customerDetail?.gymName}
                  size={'large'}
                  color={customerDetail?.User?.Coach?.color === 'BLUE' ? '#E5A772' : customerDetail?.User?.Coach?.color}
                />
              )}

              <div className={`flex flex-col justify-center ${!open && 'hidden'} `}>
                <span className={` origin-left duration-200 text-[14px] text-[#C2C7CF]`}>{user?.firstName}</span>
              </div>
            </div>
            <div
              className={`flex  cursor-pointer h-[3rem] text-gray-300 text-sm items-center  duration-200 ease-in-out `}
              onClick={() => {
                handleLogout(dispatch, navigate)
              }}
            >
              <img src={Images.logout} width={20} height={20} alt="" className=" ltr:rotate-180" />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full overflow-auto">
        <Outlet />
      </div>
    </div>
  )
}

export default AdminLayout
