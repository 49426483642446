import { Images } from "assets";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { getCustomerById } from "store/userSlice/userActions";
import i18n from "i18n";

const CoachDashboardLayout = (props) => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const { language, direction } = useSelector((state) => state.language);

  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  // eslint-disable-next-line
  const [active, setActive] = useState(0);

  const Menus = [
    { title: "MAIN", src: Images.Widget, url: "/coach/dashboard" },
    { title: "TRAINEES", src: Images.UserIcon, url: "/coach/trainees" },
    { title: "CALENDER", src: Images.CalendarIcon, url: "/coach/Calendar" },
    {
      title: "EXERCISE_LIBRARY",
      src: Images.ClipboardIcon,
      url: "/coach/exerciseLibrary",
    },
    {
      title: "WORKOUT_PANEL",
      src: Images.ClipboardIcon,
      url: "/coach/workoutPanel",
    },
  ];

  useEffect(() => {}, [active]);

  useEffect(() => {
    dispatch(getCustomerById(user.gym_Id, user.id, "coach", t, direction));
    // eslint-disable-next-line
  }, []);

  const activeTab = async (index, URL) => {
    navigate(URL);
  };
  useEffect(() => {
    i18n.changeLanguage(language);
    document.body.setAttribute("dir", language === "en" ? "ltr" : "rtl");
    i18n.reloadResources();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="flex w-screen h-screen bg-primary">
        <div
          className={` ${open ? "w-64" : "w-20 "} h-screen p-5  pt-16 relative duration-300 z-10`}
        >
          <img
            src={Images.rightArrow}
            alt="new-profile"
            className={`absolute cursor-pointer ${language === "he" ? "-left-3" : "-right-3"}  top-16 w-6 border-dark-purple
           border-2 rounded-full  ${!open && "rotate-180"}`}
            onClick={() => setOpen(!open)}
          />
          <div className="flex gap-x-4 items-center mx-4">
            {open ? (
              <h1
                className={`font-kanit-bold  leading-4 text-[1.125rem] font-black text-textLightGray duration-200 mb-1 `}
              >
                MY TRAINING APP
              </h1>
            ) : (
              <h1
                className={`font-kanit-bold leading-4 text-[1.125rem] font-black text-textLightGray duration-200 mb-1`}
              >
                MT
              </h1>
            )}
          </div>
          <div
            className={`flex flex-row gap-x-4 mt-2  ${open ? "bg-[#222c33] rounded-[12px]" : "items-center justify-center"} `}
          >
            <div>
              <img
                alt="new-profile"
                src={Images.Avatar}
                width={open ? 50 : 100}
                height={open ? 50 : 100}
                className={`${!open && "ml-4 mt-2"}`}
              />
            </div>
            <div className="flex flex-col justify-center">
              <span
                className={`${!open && "hidden"} origin-left duration-200 text-[14px] text-white`}
              >
                {user?.firstName}
              </span>
            </div>
          </div>
          <ul className="pt-4">
            {Menus.map((Menu, index) => (
              <li
                key={index}
                className={`flex mt-2 p-2 cursor-pointer rounded-md hover:bg-sidebarhover hover:rounded-md h-[3.4rem] text-gray-300 text-sm items-center gap-x-4 
              ${Menu.url === location.pathname ? "bg-sidebarhover" : ""} `}
                onClick={() => {
                  activeTab(index, Menu.url);
                }}
              >
                <img alt="new-profile" src={Menu.src} width={20} height={20} />
                <span
                  className={`${!open && "hidden"} origin-left duration-200`}
                >
                  {t(Menu.title)}
                </span>
              </li>
            ))}
          </ul>
        </div>
        <div className="w-full overflow-auto">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default CoachDashboardLayout;
