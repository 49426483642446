import { yupResolver } from '@hookform/resolvers/yup'
import { LabelInput } from 'components/compounds/LabelInput'
import PrimaryButton from 'components/elements/PrimaryButton'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { createGymInstruments } from 'store/userSlice/userActions'
import { instrumentSchema } from 'validations/formsValidations'

export default function CreateInstruments() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const methods = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(instrumentSchema),
    defaultValues: {
      machine: '',
      plate: null,
      boost: null,
    },
  })
  const { control, handleSubmit, formState } = methods
  const { errors } = formState
  const { direction } = useSelector((state) => state.language)
  const { customerDetail } = useSelector((state) => state.user)

  const onSubmit = (values) => {
    let data = {
      ...values,
      gymId: customerDetail?.id,
    }

    dispatch(createGymInstruments(data, t, direction))
  }
  return (
    <div className="flex flex-row gap-x-2 pb-4 px-4">
      <LabelInput
        name="machine"
        type="text"
        label={t('MACHINE')}
        placeholderValue={t('MACHINE')}
        control={control}
        required
        fullWidth
        error={errors?.machine ? true : false}
        errorMessage={errors?.machine?.message}
        dir={direction}
      />
      <LabelInput
        name="plate"
        type="number"
        label={t('PLATE')}
        control={control}
        placeholderValue={t('PLATE_IN_KG')}
        required
        fullWidth
        error={errors?.plate ? true : false}
        errorMessage={errors?.plate?.message}
        dir={direction}
      />
      <LabelInput
        name="boost"
        type="number"
        label={t('BOOST')}
        control={control}
        placeholderValue={t('BOOST_IN_KG')}
        required
        fullWidth
        error={Object.keys(errors).length > 0 ? true : false}
        errorMessage={errors?.boost?.message}
        dir={direction}
      />

      <PrimaryButton
        text={t('ADDITION')}
        type="submit"
        disabled={false}
        isIcon={false}
        onClick={handleSubmit(onSubmit)}
        className="w-[144px] h-[44px] rounded-[12px] shadow-2xl "
        bgColor="bg-prussianBlue hover:bg-prussianBlueHover focus:bg-prussianBlueFocused active:bg-prussianBluePressed "
        textClass={`text-white ${direction === 'ltr' ? 'font-primary-rg' : 'font-primary-he-rg'}`}
        wid={20}
        ht={20}
      />

    </div>
  )
}
